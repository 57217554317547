import styled from 'styled-components';
import { DateTime } from '@src-v2/components/time';
import { dateFormats } from '@src-v2/data/datetime';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import ServerTableInfiniteScroll from '@src/blocks/ServerTableInfiniteScroll';
import { ConsumableLink } from '@src/components/ConsumableLink';
import { Number } from '@src/components/Number';
import { SecurityExpertise } from '@src/components/SecurityExpertise';
import { StyledHeader } from '@src/components/Table';

const Repositories = ({ developerKey, className }) => {
  const { application } = useInject();
  return (
    <DeveloperRepositoryTable
      className={className}
      tableScope={`developers/${developerKey}/repositories`}
      headers={[
        { name: 'Name', weight: 2 },
        {
          name: 'Permission',
          weight: 1,
          hide: !application.isFeatureEnabled(FeatureFlag.BranchProtection),
        },
        { name: 'Last Contribution', weight: 1 },
        { name: 'Security Contributions', weight: 2 },
        { name: 'Commits', weight: 1 },
        { name: 'Authored Pull Requests', weight: 1 },
      ]}
      rowProvider={relatedEntityProfile => {
        const { repository } = relatedEntityProfile.profile;
        const repositoryDisplayName = repository.referenceName
          ? `${repository.name} (${repository.referenceName})`
          : repository.name;
        const developerRepositoryProfile = relatedEntityProfile.relatedProfile;

        return {
          key: repository.key,
          cells: [
            {
              content: (
                <ConsumableLink consumable={{ ...repository, name: repositoryDisplayName }} />
              ),
            },
            {
              content: (
                <>
                  {developerRepositoryProfile.accessRole === null
                    ? ''
                    : developerRepositoryProfile.accessRole}
                </>
              ),
            },
            {
              content:
                developerRepositoryProfile.lastActivity !== null ? (
                  <DateTime
                    date={developerRepositoryProfile.lastActivity}
                    format={dateFormats.longDate}
                  />
                ) : (
                  ''
                ),
            },
            {
              content: developerRepositoryProfile.isSecurityChampion && (
                <SecurityExpertise
                  securityExpertise={developerRepositoryProfile.securityExpertise}
                />
              ),
            },
            {
              content: <Number value={developerRepositoryProfile.commitCount} />,
            },
            {
              content: <Number value={developerRepositoryProfile.authoredPullRequestCount} />,
            },
          ],
        };
      }}
    />
  );
};

const DeveloperRepositoryTable = styled(ServerTableInfiniteScroll)`
  ${StyledHeader}:not(:first-child) {
    padding-left: 0;
  }
`;

export default Repositories;
