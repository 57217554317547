import { Heading, Paragraph, Strong } from '@src-v2/components/typography';
import { CommitCodeReference } from '@src-v2/containers/commit/common-componnets';

export const ServerlessFunctionMaterialChange = ({ commitSha, repository, serverlessFunction }) => {
  return (
    <Heading>
      <CommitCodeReference
        repository={repository}
        commitSha={commitSha}
        relativeFilePath={serverlessFunction.codeReference.relativeFilePath}>
        Function key: <Strong>{serverlessFunction.functionKey}</Strong>
      </CommitCodeReference>
      {serverlessFunction.provider && (
        <Paragraph>
          Provider: <Strong>{serverlessFunction.provider}</Strong>
        </Paragraph>
      )}
      {serverlessFunction.serviceKey && (
        <Paragraph>
          Service key: <Strong>{serverlessFunction.serviceKey}</Strong>
        </Paragraph>
      )}
    </Heading>
  );
};
