import _ from 'lodash';

export function toArray<T = any>(value: T | T[]): T[] {
  return Array.isArray(value) ? value : typeof value === 'undefined' ? [] : [value];
}

export function extractBy<
  TObject extends object,
  TKey extends keyof TObject,
  TValue = TObject[TKey],
>(item: TObject, iteratee: TKey | ((item: TObject) => any)): TValue {
  return typeof iteratee === 'function' ? iteratee(item) : _.get(item, iteratee);
}

export function mapFromArray<TObject extends object = any, TKey extends keyof TObject = any>(
  array: TObject[],
  keyMapper: TKey,
  valueMapper: (item: TObject) => any = _.identity
) {
  return new Map(array.map(item => [extractBy(item, keyMapper), extractBy(item, valueMapper)]));
}

export function toggleValues<T>(array: T[], ...values: T[]) {
  return _.xor(array, values);
}
