import _ from 'lodash';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Divider } from '@src-v2/components/divider';
import { Dropdown } from '@src-v2/components/dropdown';
import { CheckboxToggle, Combobox } from '@src-v2/components/forms';
import { MultiSelect } from '@src-v2/components/forms/multi-select';
import { InfoTooltip } from '@src-v2/components/tooltips/icon-tooltips';
import { Paragraph } from '@src-v2/components/typography';
import {
  DropdownItemWithTooltip,
  MultiAssetsCollection,
  ProviderChipWithTooltip,
} from '@src-v2/containers/applications/multi-assets-collection';
import { authenticationTypesSteps } from '@src-v2/containers/roles/role-form-content';
import { SearchCombobox } from '@src-v2/containers/search-combobox';
import { PersistentSearchCombobox, useSelectTypeahead } from '@src-v2/containers/select-input';
import { defaultItemToString, useInject } from '@src-v2/hooks';
import { AccessType } from '@src-v2/types/enums/access-type';
import { dataAttr } from '@src-v2/utils/dom-utils';
import {
  globalReadPermission,
  globalWritePermission,
  permissionEquals,
  roleHasPermission,
} from '@src/blocks/RolesPage/roleUtils';
import { ErrorMessage } from '@src/components/ErrorMessage';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { VerticalStack } from '@src/components/VerticalStack';
import { FormGroup, Input, Label } from '@src/components/reactstrap-polyfill';
import { FontBody, FontBodyBold } from '@src/style/common';
import { RoleOptions } from './RuleOptions';

const inputWidth = '300px';
const nameMaxLength = 32;
const descriptionMaxLength = 90;
const minPageSize = 200;

export const RoleEditModal = ({
  isEdit,
  onSave,
  onCancel,
  role,
  modificationHandlers,
  supportedPermissionByResource,
  assetConfigurations,
  applicationGroupAssetConfigurations,
  serverOptions,
}) => {
  const { applicationProfiles, userGroups, session } = useInject();
  const [serverError, setServerError] = useState(null);

  const handleApiiroGroupSelectTypeahead = useCallback(async params => {
    return await userGroups.getApiiroUserGroups({ limit: minPageSize, ...params });
  }, []);

  const [apiiroGroupsOptionsInput, apiiroGroupsOptions, apiiroGroupsLoading] = useSelectTypeahead(
    handleApiiroGroupSelectTypeahead
  );

  const toggleGlobalScope = useCallback(
    () =>
      modificationHandlers({
        ...role,
        userScope: { isGlobal: !role.userScope.isGlobal },
      }),
    [role, modificationHandlers]
  );

  const toggleSelection = useCallback(
    (keys, type) =>
      modificationHandlers({
        ...role,
        userScope: { ...role.userScope, [type]: keys },
      }),
    [role, modificationHandlers]
  );

  const changeIdentifier = useCallback(
    (selectedItems = []) => {
      modificationHandlers({
        ...role,
        identifiers: selectedItems.map(item => item.label),
      });
    },
    [role, modificationHandlers]
  );

  const changeApiiroGroups = useCallback(
    (selectedItems = []) => {
      modificationHandlers({
        ...role,
        apiiroGroupKeys: selectedItems.map(item => item.key),
      });
    },
    [role, modificationHandlers]
  );

  const changeNameDescription = useCallback(
    (text, type) => {
      modificationHandlers({
        ...role,
        [type]: text,
      });
    },
    [role, modificationHandlers]
  );

  const togglePermission = permission => {
    let updatedPermissions;
    if (permissionEquals(permission, globalWritePermission)) {
      updatedPermissions = roleHasPermission(role, permission) ? [] : [permission];
    } else if (permissionEquals(permission, globalReadPermission)) {
      updatedPermissions = role.permissions.filter(
        existingPermission => AccessType[existingPermission.accessType] === AccessType.Write
      );
      !roleHasPermission(role, permission) && updatedPermissions.push(permission);
    } else {
      updatedPermissions = _.xorWith(role.permissions, [permission], permissionEquals);
    }
    modificationHandlers({
      ...role,
      permissions: updatedPermissions,
    });
  };

  const applicationsItems = modifiedOptions(convertAssetCollectionOption, assetConfigurations);
  const connectorsItems = modifiedOptions(convertServerOption, serverOptions);
  const applicationGroupItems = modifiedOptions(
    convertConsumable,
    applicationGroupAssetConfigurations
  );

  const handleSubmit = useCallback(async () => {
    setServerError(null);
    try {
      await onSave();
    } catch (error) {
      if (error.response.status === 400) {
        setServerError({ message: error.response.data });
      } else {
        setServerError(error);
      }
    }
    return true;
  }, [onSave]);

  if (!role) {
    return <div />;
  }

  return (
    <RoleEditModalWrapper
      title={isEdit ? 'Edit role' : 'Create role'}
      submitText={isEdit ? 'Save' : 'Create'}
      onSubmit={handleSubmit}
      onClose={onCancel}>
      <form>
        <StyledFormGroup>
          <HorizontalStack spacing="1.5">
            <VerticalStack>
              <StyledLabel>Role name</StyledLabel>
              <StyledPermissionName
                autoComplete="off"
                value={role.name}
                name="name"
                id="name"
                placeholder="Role name e.g. Admin"
                onChange={event => changeNameDescription(event.target.value, 'name')}
                maxLength={nameMaxLength}
              />
            </VerticalStack>
            <VerticalStack>
              <StyledLabel>Description</StyledLabel>
              <StyledDescription
                autoComplete="off"
                value={role.description}
                name="description"
                id="description"
                placeholder="Provide a brief description of the role"
                onChange={event => changeNameDescription(event.target.value, 'description')}
                maxLength={descriptionMaxLength}
              />
            </VerticalStack>
          </HorizontalStack>
        </StyledFormGroup>
        <FormGroup>
          <RoleAssignmentContainer>
            <RoleAssignmentSection>
              <StyledLabel>Assign role</StyledLabel>
              <RoleAssignmentDescription>
                <strong>Tip:</strong> Paste a list of group names separated by a space ( ), comma
                (,), semicolon (;) or a new line.
              </RoleAssignmentDescription>
            </RoleAssignmentSection>
            <AssignRoleInputControl
              title="Assign role to IDP groups"
              selectedItems={role.identifiers ?? []}
              tooltipContent={authenticationTypesSteps[session.authType]?.map((tooltip, index) => (
                <Paragraph key={index}>{tooltip}</Paragraph>
              ))}
              onSelect={changeIdentifier}
              creatable
            />
            <AssignRoleInputControl
              items={apiiroGroupsOptions?.items ?? []}
              selectedItems={role.apiiroGroups ?? []}
              title="Assign role to Apiiro groups"
              tooltipContent="Assign a role to a user group defined in Apiiro"
              onSelect={changeApiiroGroups}
              onInput={apiiroGroupsOptionsInput}
              isLoading={apiiroGroupsLoading}
            />
          </RoleAssignmentContainer>
        </FormGroup>
        <StyledDivider />
        <FormGroup>
          <StyledLabel>Permissions</StyledLabel>
          <StyledFormText>Select the actions that users with this role can perform</StyledFormText>
          <RoleOptions
            role={role}
            supportedPermissionByResource={supportedPermissionByResource}
            togglePermission={togglePermission}
          />
        </FormGroup>
        <StyledDivider />
        <FormGroup>
          <StyledScopeLabelText>Scope</StyledScopeLabelText>
          <StyledScopeDescription>
            <CheckboxToggle onChange={toggleGlobalScope} checked={!role.userScope.isGlobal} />
            Limit users access to specific assets
          </StyledScopeDescription>
          {!role.userScope.isGlobal && (
            <AssetSelectionsContainer>
              <AssetContainer>
                <label>Application Groups</label>
                <SearchCombobox
                  as={MultiAssetsCollection}
                  items={applicationGroupItems}
                  value={role.extendedUserScope?.applicationGroups}
                  dropdownItem={DropdownItemWithTooltip}
                  placeholder="Add Groups"
                  onSelect={event =>
                    toggleSelection(
                      event.selectedItems.map(item => item.key),
                      'applicationGroupKeys'
                    )
                  }
                />
              </AssetContainer>
              <AssetContainer>
                <label>Applications</label>
                <SearchCombobox
                  as={MultiAssetsCollection}
                  items={applicationsItems}
                  value={role.extendedUserScope?.assetCollections}
                  dropdownItem={DropdownItemWithTooltip}
                  placeholder="Add Applications"
                  onSelect={event =>
                    toggleSelection(
                      event.selectedItems.map(item => item.key),
                      'assesCollectionKeys'
                    )
                  }
                />
              </AssetContainer>
              <AssetContainer>
                <AssetContainerLabel>
                  Repositories{' '}
                  <InfoTooltip content="Showing first 100 repositories. Begin typing" />
                </AssetContainerLabel>
                <PersistentSearchCombobox
                  as={MultiAssetsCollection}
                  value={role.extendedUserScope?.providerRepositories}
                  placeholder="Add Repositories"
                  chip={ProviderChipWithTooltip}
                  dropdownItem={DropdownItemWithTooltip}
                  itemToString={item => item?.name}
                  searchMethod={applicationProfiles.getProviderRepositories}
                  onSelect={event =>
                    toggleSelection(
                      event.selectedItems.map(item => item.key),
                      'providerRepositoryKeys'
                    )
                  }
                />
              </AssetContainer>
              <AssetContainer>
                <label>Connectors</label>
                <SearchCombobox
                  as={MultiAssetsCollection}
                  items={connectorsItems}
                  value={role.extendedUserScope?.servers}
                  dropdownItem={DropdownItemWithTooltip}
                  placeholder="Add connectors"
                  chip={ProviderChipWithTooltip}
                  onSelect={event =>
                    toggleSelection(
                      event.selectedItems.map(item => item.key),
                      'serverKeys'
                    )
                  }
                />
              </AssetContainer>
            </AssetSelectionsContainer>
          )}
        </FormGroup>
      </form>
      {!_.isNil(serverError) && <ErrorMessage message={serverError.message} />}
    </RoleEditModalWrapper>
  );
};

const modifiedOptions = (convertOption, options = []) =>
  _.orderBy(options, 'isMonitored', 'desc').map(convertOption);

const convertConsumable = option => ({
  key: option.key,
  value: option.key,
  name: option.name,
  label: option.name,
  providerGroup: option.server?.providerGroup,
});

const convertAssetCollectionOption = configuration => ({
  key: configuration.key,
  value: configuration.key,
  name: configuration.name,
  label: configuration.name,
});

const convertServerOption = server => ({
  key: server.url,
  value: server.url,
  name: server.url,
  label: server.url,
  providerGroup: server.providerGroup,
});

const AssetSelectionsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 2rem;

  ${MultiAssetsCollection.SearchCombobox},
  ${MultiAssetsCollection.ChipsContainer} {
    width: 57rem;
  }
`;

const RoleEditModalWrapper = styled(ConfirmationModal)`
  width: 252rem;
`;

const AssetContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  ${Dropdown.List} {
    max-width: 80rem;
  }
`;

const AssetContainerLabel = styled.label`
  display: flex;
`;

const StyledInput = styled(Input)`
  ::placeholder {
    color: var(--color-blue-gray-45);
  }

  &:disabled {
    background: var(--color-blue-gray-15);
  }
`;

const StyledPermissionName = styled(StyledInput)`
  width: ${inputWidth};
`;

const StyledDescription = styled(StyledInput)`
  width: 594px;
`;

const StyledFormText = styled.small`
  ${FontBody};
  display: block;
  margin-bottom: 18px;
`;

const StyledLabel = styled(Label)`
  ${FontBodyBold};
  margin-bottom: 0;
`;

const StyledFormGroup = styled(Label)`
  margin-bottom: 30px;
`;

const StyledDivider = styled(Divider)`
  margin-top: 18px;
  margin-bottom: 6rem;
`;

styled(VerticalStack)`
  margin-right: 6rem;
`;

const StyledScopeDescription = styled.label`
  ${FontBody};
  width: fit-content;
  display: flex;
  align-items: center;
  margin-bottom: 8rem;
  line-height: 3.5rem;
  column-gap: 3rem;
  cursor: pointer;
`;

const StyledScopeLabelText = styled.div`
  ${FontBodyBold};
  margin-right: 6px;
  margin-bottom: 2rem;
`;

const RoleAssignmentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const RoleAssignmentSection = styled(RoleAssignmentContainer)`
  margin-right: 6rem;
  gap: 0;

  ${Combobox} {
    width: 100%;
    max-width: 100%;
    border-radius: 2rem;
    margin-bottom: 2rem;
  }
`;

const ClearRoleAssignmentsButton = styled.span`
  font-size: var(--font-size-s);
  color: var(--color-blue-gray-50);
  cursor: pointer;

  &:hover {
    color: var(--color-blue-gray-60);
  }

  &[data-disabled] {
    pointer-events: none;
  }
`;

const RoleAssignmentDescription = styled.p`
  font-size: var(--font-size-s);
  color: var(--color-blue-gray-60);
`;

const RoleAssignmentHeader = styled.span`
  display: inline-flex;
  justify-content: space-between;
`;

const SmallTitle = styled.span`
  font-size: var(--font-size-s);
  margin-bottom: 1rem;
`;

const AssignRoleInputControl = ({
  title,
  onSelect,
  tooltipContent,
  items = [],
  selectedItems: defaultSelected = [],
  creatable = false,
  ...props
}) => {
  const extraProps = creatable ? { creatable } : { items, ...props };
  const [selectedItems, setSelectedItems] = useState(defaultSelected);

  const handleSelect = event => {
    setSelectedItems(event.selectedItems);
    onSelect?.(event.selectedItems);
  };

  const handleClear = () => {
    setSelectedItems([]);
    onSelect?.([]);
  };

  return (
    <RoleAssignmentSection {...props}>
      <RoleAssignmentHeader>
        <SmallTitle>
          {title} ({selectedItems.length})
          {Boolean(tooltipContent) && <InfoTooltip content={tooltipContent} />}
        </SmallTitle>
        <ClearRoleAssignmentsButton
          onClick={handleClear}
          data-disabled={dataAttr(selectedItems.length === 0)}>
          Clear all
        </ClearRoleAssignmentsButton>
      </RoleAssignmentHeader>
      <SearchCombobox
        as={MultiSelect}
        placeholder="Type a group name..."
        itemToString={defaultItemToString}
        selectedItems={selectedItems?.map(item =>
          typeof item === 'string' ? { label: item, value: item } : item
        )}
        onSelect={handleSelect}
        {...extraProps}
      />
    </RoleAssignmentSection>
  );
};
