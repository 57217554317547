import { useMemo } from 'react';
import { ListPropertiesPanelSection } from '@src/cluster-map-work/components/layout/properties-panel';

export default function ({ clusterResource, highlightsList, maxListItems = 10 }) {
  if (
    clusterResource.resourceType === 'ClusterInternalTrafficGateway' ||
    clusterResource.resourceType === 'ClusterExternalTrafficGateway'
  ) {
    return (
      <GatewayRoutesList
        clusterGatewayResource={clusterResource}
        highlightsList={highlightsList}
        maxListItems={maxListItems}
      />
    );
  }

  return [];
}

function GatewayRoutesList({ clusterGatewayResource, highlightsList, maxListItems }) {
  const portsList = useMemo(
    () =>
      clusterGatewayResource.gatewayRoutes.map((route, index) => {
        const addressAndPort = (address, ports) =>
          address ? `${address}:${ports}` : `Port ${ports}`;

        return {
          description: `${addressAndPort(
            route.frontAddress,
            route.frontPorts
          )} \u{2192} ${addressAndPort(route.targetAddress, route.targetPorts)}`,
          index: index.toString(),
        };
      }),
    [clusterGatewayResource]
  );

  const highlightedPorts = useMemo(
    () =>
      highlightsList
        .filter(highlight => highlight.propertyName === 'GatewayRoutes')
        .map(highlight => portsList.find(port => port.index === highlight.propertyInternalIdRef)),
    [highlightsList, portsList]
  );

  if (!portsList.length) {
    return null;
  }

  return (
    <ListPropertiesPanelSection
      title="Gateway Routes"
      key="gatewayRoutes"
      list={portsList}
      itemToString={port => port.description}
      maxListItems={maxListItems}
      highlightedItems={highlightedPorts}
    />
  );
}
