import styled from 'styled-components';
import { InputControl, TextareaControl } from '@src-v2/components/forms/form-controls';
import { Field, Label } from '@src-v2/components/forms/modal-form-layout';
import { Modal } from '@src-v2/components/modals';
import { Heading, Paragraph } from '@src-v2/components/typography';
import { SupportModal } from '@src-v2/containers/modals/support-modal';

export function ReportDataIssueModal({ row, headers, ...props }) {
  const defaultDescription = row.cells
    .map((cell, index) => cell.description && `${headers?.[index]?.name}: ${cell.description}`)
    .filter(Boolean)
    .join('\n');

  return (
    <ModalWrapper
      {...props}
      title="Report a data issue"
      defaultValues={{ description: defaultDescription }}>
      <Field>
        <Label required>Subject</Label>
        <InputControl
          name="subject"
          placeholder="False positive finding, missing or incorrect information"
          rules={{ required: true }}
        />
      </Field>

      <Field>
        <Label required>Comment</Label>
        <TextareaControl
          name="comment"
          placeholder="How can we do better?"
          rules={{ required: true }}
          rows={4}
        />
      </Field>

      <Field>
        <Label required>The following data will be investigated by our team</Label>
        <TextareaControl name="description" rules={{ required: true }} rows={4} />
      </Field>

      <Paragraph>Our support team will reach out via email.</Paragraph>
    </ModalWrapper>
  );
}

const ModalWrapper = styled(SupportModal)`
  border-radius: 3rem;

  ${Modal.Header} {
    padding: 8rem 8rem 0;
    border: none;
  }

  ${Modal.Content} {
    padding-top: 0;
  }

  ${Modal.Footer} {
    padding: 0 8rem 6rem;
    border: none;
  }

  ${Heading} {
    font-size: var(--font-size-m);
    font-weight: 600;
  }

  ${Paragraph} {
    font-weight: 300;
  }
`;
