import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { Number } from '@src/components/Number';
import { HorizontalStack } from './HorizontalStack';

export const Tiles = ({ className, small, title, single, plural, tilesKeys, renderFunction }) => (
  <HorizontalStack align="left" wrapItems className={className}>
    {!tilesKeys ? null : small && tilesKeys.length > 2 ? (
      <PopoverTip
        linkText={<Number value={tilesKeys.length} one={single} other={plural} />}
        title={title}>
        <HorizontalStack align="left" wrapItems>
          {renderFunction(tilesKeys)}
        </HorizontalStack>
      </PopoverTip>
    ) : (
      renderFunction(tilesKeys)
    )}
  </HorizontalStack>
);
