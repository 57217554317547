import _ from 'lodash';
import { Divider } from '@src-v2/components/divider';
import { getBusinessImpactFactors } from '@src/services/businessImpactService';
import { HorizontalStack } from './HorizontalStack';
import RiskIcon from './RiskIcon';
import { VerticalStack } from './VerticalStack';

const BusinessImpactBadge = ({ profile }) => {
  const { factorsByRiskLevel, nonRiskFactors } = getBusinessImpactFactors(profile);
  const riskyFactorsCount = _.sumBy(Object.values(factorsByRiskLevel), 'length');
  const businessImpactFactorsCount = nonRiskFactors.length + riskyFactorsCount;

  return businessImpactFactorsCount === 0 ? (
    <span>No business impact factors found</span>
  ) : (
    <VerticalStack>
      {Object.entries(factorsByRiskLevel).map(([level, factors], index) =>
        factors.map((factor, factor_index) => (
          <HorizontalStack key={`${index}_${factor_index}`}>
            <RiskIcon riskLevel={level} />
            <span>{factor}</span>
          </HorizontalStack>
        ))
      )}
      {!_.isEmpty(nonRiskFactors) && riskyFactorsCount > 0 && <Divider />}
      {nonRiskFactors.map((factor, index) => (
        <span key={index}>{factor}</span>
      ))}
    </VerticalStack>
  );
};

export default BusinessImpactBadge;
