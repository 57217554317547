import sum from 'lodash/sum';
import values from 'lodash/values';

export const businessImpactLevelToName = {
  High: 'HBI',
  Medium: 'MBI',
  Low: 'LBI',
};

export const getBusinessImpactFactors = profile => {
  const factorsByRiskLevel = { High: [], Medium: [], Low: [], None: [] };

  const configuredLevel = profile.configuredBusinessImpact;
  if (configuredLevel !== 'None') {
    factorsByRiskLevel[configuredLevel].push(`Business Impact is ${configuredLevel}`);
  }

  Object.entries(profile.businessImpactToKeywords).forEach(([level, keywords]) =>
    keywords.forEach(word => factorsByRiskLevel[level].push(`Data includes ${word}`))
  );

  const nonRiskFactors = [];
  if (profile.isInternetExposed) {
    nonRiskFactors.push('Internet exposed');
  }

  return { factorsByRiskLevel, nonRiskFactors };
};

export const getBusinessImpactFactorsCount = profile => {
  const { factorsByRiskLevel, nonRiskFactors } = getBusinessImpactFactors(profile);
  return nonRiskFactors.length + sum(values(factorsByRiskLevel).map(_ => _.length));
};
