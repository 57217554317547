import { connect } from 'react-redux';
import { select } from '@src/store';
import { ElementTimeline } from './view';

const mapState = select(
  ({
    elementTimeline: { entityProfile, hasUnprocessedCommits },
    developerProfiles: { developerProfileByIdentityKey },
  }) => ({
    entityProfile,
    developerProfileByIdentityKey,
    hasUnprocessedCommits,
    isLoading: state => state.loading.effects.elementTimeline.fetchData,
  })
);

const mapDispatch = ({ elementTimeline: { fetchData, clearData } }) => ({
  fetchData,
  clearData,
});

export default connect(mapState, mapDispatch)(ElementTimeline);
