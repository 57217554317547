import { formatDistanceToNow } from 'date-fns';
import _ from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { getRiskColor } from '@src-v2/data/risk-data';
import { abbreviate } from '@src-v2/utils/number-utils';
import {
  ProfileWidget,
  ProfileWidgetLabel,
} from '@src/blocks/ConsumableInfiniteScroll/blocks/ConsumableProfileWidget';
import { CircleMeter } from '@src/components/CircleMeter';

const RiskyChangesWidget = ({ riskyChanges }) => {
  const activeSinceDistanceToNow = useMemo(
    () => _.capitalize(formatDistanceToNow(new Date(riskyChanges.activeSince))),
    [riskyChanges.activeSince]
  );

  return (
    <Tooltip content={activeSinceDistanceToNow}>
      <ProfileWidget>
        <RiskyChangesMeter riskyChanges={riskyChanges} />
        <ProfileWidgetLabel>Risky Changes</ProfileWidgetLabel>
      </ProfileWidget>
    </Tooltip>
  );
};

const RiskyChangesMeter = ({ riskyChanges: { riskLevel, commitCount, riskyCommitCount } }) => (
  <RiskMeter>
    <CircleMeter
      color={getRiskColor({ riskLevel: _.camelCase(riskLevel) })}
      percentile={commitCount ? riskyCommitCount / commitCount : 0}
    />
    <RiskMeterText>
      {abbreviate(riskyCommitCount)} out
      <br />
      of {abbreviate(commitCount)}
    </RiskMeterText>
  </RiskMeter>
);

export default RiskyChangesWidget;

const RiskMeter = styled.div`
  display: flex;
  gap: 8px;

  // TODO change to CircleMeter when moving to src-v2 (after LIM-2624)
  > svg {
    flex: 0 0 auto;
  }
`;

const RiskMeterText = styled.span`
  min-width: 10.5rem;
  padding: 4px 0;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`;
