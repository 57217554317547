import _ from 'lodash';

export const rbacTypeToEntity = {
  MethodAuthorization: 'Method annotation',
  SecurityConfiguration: 'Security configuration',
  Swagger: 'Swagger definition',
};

export const TRIGGER_TYPE_TO_INVENTORY_ROUTE = {
  Api: ['Apis', 'Apis'],
  Issue: ['AdditionalElements', 'TrackedIssues'],
  UserStory: ['AdditionalElements', 'RiskyIssues'],
  DataModel: ['DataModels', 'DataModels'],
  DataAccessObject: ['DataModels', 'DataAccessObjects'],
  Secret: ['AdditionalElements', 'Secrets'],
  SensitiveData: ['DataModels', 'SensitiveData'],
  Dependency: ['AdditionalElements', 'Dependencies'],
  Dockerfile: ['AdditionalElements', 'Dockerfiles'],
  Framework: ['AdditionalElements', 'FrameworkUsages'],
  FrameworkUsage: ['AdditionalElements', 'FrameworkUsages'],
  LicenseWithDependencies: ['AdditionalElements', 'LicensesWithDependencies'],
  RbacRole: ['AdditionalElements', 'RbacRoles'],
  TerraformResource: ['AdditionalElements', 'TerraformResourcesSummaries'],
  Gql: ['DataModels', 'GqlTypes'],
  GqlObject: ['DataModels', 'GqlTypes'],
  GqlOperation: ['Apis', 'GqlOperations'],
  CodeFinding: ['AdditionalElements', 'CodeFindings'],
  CicdPipelineDeclarationCodeFindings: [
    'AdditionalElements',
    'CicdPipelineDeclarationCodeFindings',
  ],
  ServerlessFunction: ['AdditionalElements', 'ServerlessFunctions'],
  PipelineConfigurationFile: ['AdditionalElements', 'PipelineConfigurationFile'],
  CicdPipelineDeclaration: ['AdditionalElements', 'CicdPipelinesDeclarations'],
  PipelineDependencyDeclaration: ['AdditionalElements', 'CicdPipelineDependencyDeclarations'],
  ProtobufMessage: ['DataModels', 'ProtobufMessages'],
  ProtobufService: ['Apis', 'ProtobufServices'],
  ExternalGeneralFinding: ['AdditionalElements', 'ExternalGeneralFindings'],
  KubernetesService: ['AdditionalElements', 'KubernetesServices'],
  KubernetesDeployment: ['AdditionalElements', 'KubernetesDeployments'],
};

export const TRIGGER_TYPE_TO_ELEMENT_NAME_GETTER = {
  Api: { one: 'API', many: 'APIs' },
  Issue: { one: 'risky issue', many: 'risky issues' },
  UserStory: { one: 'feature request', many: 'feature requests' },
  DataModel: { one: 'data model', many: 'data models' },
  Secret: { one: 'secret', many: 'secrets' },
  Dependency: { one: 'dependency', many: 'dependencies and sub dependencies' },
  Activity: { one: 'repository', many: 'repositories' },
  UserFacing: { one: 'repository', many: 'repositories' },
  Framework: { one: 'technology', many: 'technologies' },
  LicenseWithDependencies: { one: 'license', many: 'licenses' },
  MissingSASTScan: { one: 'repository', many: 'repositories' },
  RbacRole: { one: 'RBAC Role', many: 'RBAC Roles' },
  ElementMissing: { one: 'repository', many: 'repositories' },
  TerraformResource: { one: 'terraform resource', many: 'terraform resources' },
  GqlObject: { one: 'GraphQL object', many: 'GraphQL objects' },
  ServerlessFunction: { one: 'Serverless function', many: 'Serverless functions' },
  SensitiveData: { one: 'Field', many: 'Fields' },
  CodeFinding: { one: 'Finding', many: 'Findings' },
};

const getApisSortFunction = apis => _.orderBy(apis, _ => _.codeReference.relativeFilePath);
const getDataModelsSortFunction = dataModels =>
  _.orderBy(dataModels, _ => _.codeReference.relativeFilePath);
const getSecretsSortFunction = secrets => _.orderBy(secrets, _ => _.relativeFilePath);
const getDependencySortFunction = dependencies =>
  _.orderBy(dependencies, ['isSubDependency', 'name']);

const riskChangeToSortOrder = {
  New: 1,
  Existing: 2,
  Resolved: 3,
};

const riskChangeSorter = trigger => riskChangeToSortOrder[trigger.riskChange] ?? 0;

export const TRIGGER_TYPE_TO_ELEMENTS_SORTER = {
  Api: getApisSortFunction,
  DataModel: getDataModelsSortFunction,
  Secret: getSecretsSortFunction,
  Dependency: getDependencySortFunction,
};

export const getTriggerType = trigger => trigger?.elementType ?? trigger?.type;

export const getTriggersElementsSorter = trigger =>
  _.isEmpty(trigger.riskChange)
    ? TRIGGER_TYPE_TO_ELEMENTS_SORTER[getTriggerType(trigger)] ?? _.identity
    : elements => _.orderBy(elements, riskChangeSorter);

export const getTriggerContext = (trigger, current, previous) =>
  trigger.riskChange === 'Resolved' ? previous : current;
