import _ from 'lodash';
import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { qs } from '@src-v2/utils/history-utils';
import { safeParse } from '@src-v2/utils/json-utils';

export function useLegacyQueryParams() {
  // TODO remove legacyQueryParse support when dropping old code
  const { search } = useLocation();
  return useMemo(() => _.mapValues(qs.parse(search), value => safeParse(value)), [search]);
}
