import { isObservable } from 'mobx';
import { useCallback } from 'react';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { VendorCircle } from '@src-v2/components/circles';
import { SuccessToast } from '@src-v2/components/toastify';
import { ExternalLink } from '@src-v2/components/typography';
import { ActionModal } from '@src-v2/containers/modals/action-modal';
import { MessagePreview } from '@src-v2/containers/modals/messaging/message-preview';
import {
  ChannelSelect,
  CustomContentField,
} from '@src-v2/containers/modals/messaging/send-message-inputs';
import { getActionTextConfig } from '@src-v2/data/actions-data';
import { useInject } from '@src-v2/hooks';
import { humanize } from '@src-v2/utils/string-utils';

export function SendMessageModal({
  messageType,
  riskData,
  previewTags,
  allowCustomChannel,
  children,
  onClose,
  ...props
}) {
  const { messaging, risks, toaster } = useInject();
  const trackAnalytics = useTrackAnalytics();

  const handleSubmit = useCallback(
    ({ channel, customContent }) =>
      messaging
        .sendMessage({
          messageType,
          message: { channel, customContent },
          riskData,
        })
        .then(response => {
          trackAnalytics(AnalyticsEventName.ActionInvoked, {
            [AnalyticsDataField.ActionType]: `Send ${humanize(messageType, true)} message`,
          });

          if (isObservable(riskData) && response) {
            risks.modifyActionTimelineItem(riskData, response);
          }
          const { entityName } = getActionTextConfig(riskData.provider);

          toaster.success(
            <SuccessToast>
              A {entityName} was created successfully!{' '}
              {response.externalLink && (
                <ExternalLink href={response.externalLink}>
                  View {humanize(entityName)}
                </ExternalLink>
              )}
            </SuccessToast>
          );

          return response;
        })
        .then(onClose),
    [messaging, riskData, messageType]
  );

  return (
    <ActionModal
      {...props}
      title={
        <>
          <VendorCircle name={messageType} size="xlarge" />
          Send a {humanize(messageType, true)} Message
        </>
      }
      confirmOnClose
      submitText="Send"
      onClose={onClose}
      onSubmit={handleSubmit}>
      <MessagePreview messageType={messageType} riskData={riskData} tags={previewTags} />
      <ChannelSelect messageType={messageType} riskData={riskData} creatable={allowCustomChannel} />
      <CustomContentField />
    </ActionModal>
  );
}
