import { connect } from 'react-redux';
import { select } from '@src/store';
import RiskRuleTrigger from './view';

const mapState = select(
  ({
    riskRuleTrigger: { element },
    riskRuleTriggers: { ruleTriggers },
    developerProfiles: { developerProfileByIdentityKey, isFetchingData: isLoading },
  }) => ({
    element,
    ruleTriggers,
    developerProfileByIdentityKey,
    isLoading,
  })
);

const mapDispatch = ({ riskRuleTrigger: { fetchData, clearData } }) => ({
  fetchData,
  clearData,
});

export default connect(mapState, mapDispatch)(RiskRuleTrigger);
