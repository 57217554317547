import styled from 'styled-components';
import { Divider } from '@src-v2/components/divider';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { ContributorAvatar } from '@src-v2/containers/contributors/contributor-avatar';
import { generateCommitReferenceUrl } from '@src-v2/data/connectors';
import { getProviderDisplayName } from '@src-v2/data/providers';
import { Ellipsis, EllipsisDiv, FontBodySmall } from '@src/style/common';
import { HorizontalStack } from './HorizontalStack';
import ServerIcon from './ServerIcon';
import { VerticalStack } from './VerticalStack';
import ViewCode from './ViewCode';

const StyledPopoverTip = styled(PopoverTip)`
  padding: 0;
  text-decoration: underline;
`;

const StyledDeveloperRole = styled.span`
  ${FontBodySmall};
  opacity: 0.7;
  display: inline-flex;
`;

const StyledCommitMessageBody = styled.div`
  ${FontBodySmall};
  ${Ellipsis};
  opacity: 0.7;
`;

const developerDisplay = (developerProfile, role) => (
  <HorizontalStack key={developerProfile.key}>
    <ContributorAvatar
      avatarUrl={developerProfile.avatarUrl}
      name={developerProfile.displayName}
      size="small"
    />
    <EllipsisDiv>{developerProfile.displayName}</EllipsisDiv>
    <StyledDeveloperRole>{`-  ${role}`}</StyledDeveloperRole>
  </HorizontalStack>
);

const repositoryDisplay = repositoryProfile => (
  <HorizontalStack key={repositoryProfile.key}>
    <ServerIcon small provider={repositoryProfile.provider} />
    <EllipsisDiv>{repositoryProfile.repository.name}</EllipsisDiv>
    <StyledDeveloperRole>- Repository</StyledDeveloperRole>
  </HorizontalStack>
);

const InvolvedDevelopers = (authorProfile, committerProfile) => [
  authorProfile && developerDisplay(authorProfile, 'Author'),
  committerProfile &&
    authorProfile &&
    committerProfile.key !== authorProfile.key &&
    developerDisplay(committerProfile, 'Committer'),
];

const InvolvedRepositories = repositoryProfiles =>
  repositoryProfiles.map(repositoryProfile => repositoryDisplay(repositoryProfile));

const StyledDivider = styled(Divider)`
  opacity: 0.7;
  margin-bottom: 3rem;
  margin-top: 6rem !important;
`;

export const formatCommitMessage = message =>
  message
    .replace(/\n\n/gm, '\n')
    .trim('\\n')
    .split('\\n')
    .map((line, index) => <span key={index}>{line}</span>);

const getTitle = (commitSha, repository) => {
  return (
    <HorizontalStack spread>
      {`Commit ${commitSha}`}
      <ViewCode
        url={generateCommitReferenceUrl(repository, commitSha)}
        tip={`View commit in ${getProviderDisplayName(repository.server.provider)}`}
        inline
      />
    </HorizontalStack>
  );
};

export const Commit = ({
  committerProfile,
  authorProfile,
  commitMessage,
  repository,
  repositoryProfiles,
  commitSha,
}) => (
  <CommitBase
    authorProfile={authorProfile}
    committerProfile={committerProfile}
    repositoryProfiles={repositoryProfiles}
    linkText={commitSha}
    title={getTitle(commitSha, repository)}
    message={commitMessage}
    type="Commit"
  />
);

export const PerforceCommit = ({
  committerProfile,
  authorProfile,
  message,
  repositoryProfiles,
}) => {
  const changeNumber = getPerforceChangeNumber(message);
  const changeMessage = getPerforceChangeMessage(message);
  return (
    <CommitBase
      authorProfile={authorProfile}
      committerProfile={committerProfile}
      repositoryProfiles={repositoryProfiles}
      linkText={changeNumber}
      title={changeNumber}
      message={changeMessage}
      type="Change"
    />
  );
};

const CommitBase = ({
  committerProfile,
  authorProfile,
  repositoryProfiles,
  linkText,
  title,
  message,
  type,
}) => (
  <StyledPopoverTip linkText={linkText} title={title}>
    <VerticalStack>
      {InvolvedDevelopers(authorProfile, committerProfile)}
      {repositoryProfiles && InvolvedRepositories(repositoryProfiles)}
      <StyledDivider />
      {Boolean(message) && (
        <>
          <div>{type} message:</div>
          <StyledCommitMessageBody>{formatCommitMessage(message)}</StyledCommitMessageBody>
        </>
      )}
    </VerticalStack>
  </StyledPopoverTip>
);

export const getPerforceChangeNumber = message => {
  if (message.indexOf('change = ') >= 0) {
    const startIndex = message.indexOf('change = ') + 9;
    return message.substr(startIndex, message.lastIndexOf(']') - startIndex);
  }
  return message;
};

export const getPerforceChangeMessage = message => {
  if (message.indexOf('[git-p4') >= 0) {
    return message.substring(0, message.indexOf('[git-p4') - 1);
  }
  return message;
};
