import { Link } from 'react-router-dom';
import styled from 'styled-components';

const isClickable = props => !props.disabled && (props.onClick || props.href);

export const Anchor = styled(Link)`
  display: ${props => (props.flex ? 'flex' : 'unset')};
  cursor: ${props => (isClickable(props) ? 'pointer' : 'inherit')};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};

  &:hover {
    > * {
      opacity: ${props => (props.hover ? 0.7 : 1)};
    }

    opacity: ${props => (props.hover ? 0.7 : 1)};
    text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  }
`;
