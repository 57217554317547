import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Avatar } from '@src-v2/components/avatar';
import { SvgIcon } from '@src-v2/components/icons';
import { Tooltip as TooltipV2 } from '@src-v2/components/tooltips/tooltip';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import ioc from '@src-v2/ioc';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { primaryPaneLevel } from '@src/blocks/Pane/model';
import { ShowOnClusterClusterMap } from '@src/cluster-map-work/containers/panes/ShowOnClusterClusterMap';
import { ActionsMenu } from '@src/components/ActionsMenu';
import { HorizontalStack } from '@src/components/HorizontalStack';
import ViewCode from '@src/components/ViewCode';

const INVENTORY_EXPLORE_ACTIONS = [
  {
    name: 'view_code',
    shouldShow: props =>
      props.codeReference && props.codeReference.relativeFilePath && props.repository,
    nameGetter: () => 'View code',
    iconGetter: () => <SvgIcon name="Code" />,
    linkTargetGetter: ({ repository, codeReference }) =>
      generateCodeReferenceUrl(repository, {
        ...codeReference,
        relativeFilePath: codeReference.relativeFilePath ?? codeReference.filePath,
      }),
  },

  {
    name: 'view_on_cluster',
    shouldShow: props =>
      Boolean(props.annotatedRepositoryAndModuleReferences?.repositoryAndModuleReferences?.length),
    shouldShowAsync: async props => {
      const enrichedObject = {
        repositoryAndModuleReferences:
          props.annotatedRepositoryAndModuleReferences.repositoryAndModuleReferences,
      };

      await ioc.clusters.enrichWithClusterConnection([enrichedObject]);

      return enrichedObject.clusterMapLinksList.map(linkList => linkList.nodeLinks).flat().length;
    },
    nameGetter: () => 'Show on cluster',
    iconGetter: () => <SvgIcon name="Cluster" />,
    onExecute: (props, actionEnv) =>
      actionEnv.openPane(
        <ShowOnClusterClusterMap
          annotatedRepositoryAndModuleReferences={[props.annotatedRepositoryAndModuleReferences]}
          title={props.entityTitle}
        />
      ),
  },

  {
    name: 'view_repository_profile',
    shouldShow: props =>
      props.repository &&
      props.profile &&
      !props.profile.repository &&
      !props.profile.isModuleBased,
    nameGetter: props => `View "${props.repository.name}" profile`,
    iconGetter: () => <SvgIcon name="Repository" />,
    linkTargetGetter: props => `/${props.repository.type.toLowerCase()}/${props.repository.key}`,
  },
];

export const ExploreActionsMenu = props => (
  <ActionsMenu
    label="Explore"
    defaultPaneOpenOptions={{ level: primaryPaneLevel, ...props.defaultPaneOptions }}
    actions={INVENTORY_EXPLORE_ACTIONS}
    {...props}
  />
);

export const getViewCodeColumn = (codeReference, repository, withText) =>
  codeReference && repository ? (
    <ViewCode
      url={generateCodeReferenceUrl(repository, {
        ...codeReference,
        relativeFilePath: codeReference.relativeFilePath ?? codeReference.filePath,
      })}
      withText={withText}
    />
  ) : null;

export const RenderAvatarContributors = ({
  modifiedDeveloperProfile,
  introducedDeveloperProfile,
  codeOwnerEntity,
}) => {
  const [contributors, setContributors] = useState([]);

  const pushContributor = useCallback((profile, getLabel) => {
    if (!profile) {
      setContributors([]);
      return;
    }
    const current = [...contributors];

    if (contributors.length > 0 && contributors[0].profile.key === profile.key) {
      current[0].labels.push(getLabel(profile.displayName));
    } else {
      current.push({ profile, labels: [getLabel(profile.displayName)] });
    }
    setContributors(current);
  }, []);

  useEffect(() => {
    if (modifiedDeveloperProfile?.key === introducedDeveloperProfile?.key) {
      pushContributor(
        modifiedDeveloperProfile,
        displayName => `Introduced and last modified by ${displayName}`
      );
    } else {
      pushContributor(introducedDeveloperProfile, displayName => `Introduced by ${displayName}`);
      pushContributor(modifiedDeveloperProfile, displayName => `Last modified by ${displayName}`);
    }
    if (codeOwnerEntity) {
      pushContributor(
        codeOwnerEntity.profile,
        displayName => `${displayName} is a main contributor for this context`
      );
    }
  }, [codeOwnerEntity, pushContributor, modifiedDeveloperProfile, introducedDeveloperProfile]);

  return (
    <HorizontalStack spacing="-0.5">
      {_.map(contributors, contributor => (
        <TooltipV2
          key={contributor.profile.key}
          content={contributor.labels?.map((label, index) => (
            <p key={index}>{label}</p>
          ))}>
          <ContributorAvatar
            {...contributor.profile}
            size="large"
            identityKey={contributor.profile.key}
            username={contributor.profile.displayName}
            data-clickable={dataAttr(Boolean(contributor.profile.representativeIdentityKeySha))}
            onClick={() =>
              contributor.profile.representativeIdentityKeySha
                ? (window.location.href = `/users/contributors/${contributor.profile.representativeIdentityKeySha}`)
                : null
            }
          />
        </TooltipV2>
      ))}
    </HorizontalStack>
  );
};

const ContributorAvatar = styled(Avatar)`
  display: flex;

  &:hover {
    &[data-clickable] {
      cursor: pointer;
      opacity: 0.5;
    }
  }
`;
