import { useEffect, useMemo } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { Gutters } from '@src-v2/components/layout';
import { ProfilesProvider } from '@src-v2/components/profiles-inject-context';
import { InventoryV2 } from '@src-v2/containers/inventory/inventory-v2';
import { MultiBranchPage } from '@src-v2/containers/pages/multi-branch-page';
import NewRepositoryPage from '@src-v2/containers/pages/repository-page';
import SpacetimeGraphPage from '@src-v2/containers/pages/spacetime-graph-page';
import { RepositoryOverview } from '@src-v2/containers/profiles/profile-overview';
import { useInject } from '@src-v2/hooks';
import { RisksTabSwitch } from '@src/blocks/RiskPosture/risks-tab-switch';
import TimelineInfiniteScroll from '@src/blocks/TimelineInfiniteScroll';
import { ForeignEntityBackConnections } from '@src/components/ForeignEntityBackConnections';
import { PageLoading } from '@src/components/PageLoading';
import profilesService from '@src/services/profilesService';

export default function RepositoryPage({
  fetchData,
  clearData,
  hasFetchedData,
  repositoryProfile,
  repositoryKey,
}) {
  const {
    path: basePath,
    url: baseUrl,
    params: { key },
  } = useRouteMatch();
  const { profileType, profileTypePlural } = profilesService.getProfileDefinition('repositories');

  const { foreignEntities, repositoryProfiles } = useInject();

  const repoLinkTarget = useMemo(() => foreignEntities.createRepositoryLinkTarget(key), [key]);

  useEffect(() => {
    fetchData({ key, invalidateCache: false });
    return clearData;
  }, [fetchData, key, clearData]);

  if (!hasFetchedData) {
    return <PageLoading />;
  }

  return (
    <ProfilesProvider value={repositoryProfiles}>
      <Switch>
        <Route path={`${basePath}/profile/:providerRepositoryKey/multi-branch`} exact>
          <MultiBranchPage />
        </Route>
        <Route>
          <NewRepositoryPage>
            <Switch>
              <Route path={`${basePath}/profile`}>
                <RepositoryOverview repositoryProfile={repositoryProfile} />
              </Route>
              <Route path={`${basePath}/risk`}>
                <Gutters>
                  <RisksTabSwitch profile={repositoryProfile} profileType="RepositoryProfile" />
                </Gutters>
              </Route>
              <Route path={`${basePath}/timeline`}>
                <Gutters>
                  <TimelineInfiniteScroll entityKey={repositoryKey} table={profileTypePlural} />
                </Gutters>
              </Route>
              <Route path={`${basePath}/inventory`}>
                <Gutters>
                  <InventoryV2 profile={repositoryProfile} profileType={profileType} />
                </Gutters>
              </Route>
              <Route path={`${basePath}/graph`}>
                <AsyncBoundary>
                  <SpacetimeGraphPage />
                </AsyncBoundary>
              </Route>
              <Route path={`${basePath}/connections`}>
                <Gutters>
                  <ConnectionsTabs
                    linkTarget={repoLinkTarget}
                    linkTargetDescription={repositoryProfile?.name}
                    profile={repositoryProfile}
                  />
                </Gutters>
              </Route>
              <Redirect to={`${escapeInvalidUrl(baseUrl)}/profile`} />
            </Switch>
          </NewRepositoryPage>
        </Route>
      </Switch>
    </ProfilesProvider>
  );
}

// this is a patch that should be fixed by using a guid with paths instead of the repository url
function escapeInvalidUrl(url) {
  return url.replace(/[()]/g, '\\$&');
}

const ConnectionsTabs = styled(ForeignEntityBackConnections)`
  margin: 4rem 0 6rem;
`;
