import { useEffect } from 'react';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { SimpleSelect } from '@src-v2/containers/simple-select';
import ElementTimeline from '@src/blocks/ElementTimeline';
import { HorizontalStack } from '@src/components/HorizontalStack';

const LoadingAnimation = styled(LogoSpinner)`
  margin: 9rem auto 0;
`;

const RepositorySelectionSection = styled(HorizontalStack)`
  margin-bottom: 6rem;
`;

export const ElementTimelineSelector = ({
  repositoryKeys,
  entityType,
  entityId,
  fetchData,
  clearData,
  setSelectedRepositoryProfile,
  isLoadingPage,
  isLoadingRepository,
  repositoryProfiles,
  selectedRepositoryProfile,
}) => {
  useEffect(() => {
    fetchData({ repositoryKeys });
    return clearData;
  }, [fetchData, clearData, repositoryKeys]);

  if (isLoadingPage) {
    return <LoadingAnimation />;
  }

  return (
    <>
      {repositoryProfiles.length > 1 && (
        <RepositorySelectionSection alignItems="baseline">
          <span>Repository:</span>
          <SimpleSelect
            title="Select repository"
            options={repositoryProfiles}
            identity={repositoryProfile => repositoryProfile.name}
            onSelect={setSelectedRepositoryProfile}
          />
        </RepositorySelectionSection>
      )}
      {isLoadingRepository ? (
        <LoadingAnimation />
      ) : (
        <ElementTimeline
          repositoryProfile={selectedRepositoryProfile}
          entityType={entityType}
          entityId={entityId}
        />
      )}
    </>
  );
};
