import styled from 'styled-components';
import * as utils from '@src-v2/utils/number-utils';

const StyledSpan = styled.span`
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
`;

export const Number = ({
  value,
  shouldAbbreviate,
  one,
  other,
  className,
  underline,
  showNumber = true,
}) => (
  <StyledSpan underline={underline} className={className}>
    {showNumber && (shouldAbbreviate ? utils.abbreviate(value) : utils.formatNumber(value))}
    {one && other && ` ${utils.pluralFormat(value, one, other)}`}
  </StyledSpan>
);
