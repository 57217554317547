import _ from 'lodash';
import styled from 'styled-components';
import { SvgIcon } from '@src-v2/components/icons';
import { DateTime } from '@src-v2/components/time';
import { dateFormats } from '@src-v2/data/datetime';

export const ValidityIcon = styled(SvgIcon)`
  &[data-type='Invalid'],
  &[data-type='Revoked'] {
    color: var(--color-green-45);
  }

  &[data-type='NoValidator'],
  &[data-type='Unknown'] {
    color: var(--color-blue-gray-40);
  }

  &[data-type='Valid'] {
    color: var(--color-red-45);
  }
`;

export const getValidityMapper = item => {
  let icon;
  switch (item.validity) {
    case 'Revoked':
      icon = 'CloseRoundedOutline';
      break;
    case 'Valid':
      icon = 'Valid';
      break;
    case 'Invalid':
      icon = 'Block';
      break;
    case 'NoValidator':
      return { icon: 'Block', content: 'No validator' };
    default:
      return { icon: 'Help', content: 'Validity unknown' };
  }

  return {
    icon,
    content: <ValidityStatusTooltipContent item={item} />,
  };
};

const ValidityStatusTooltipContent = ({ item }) => (
  <>
    <p>{item.validity === 'Valid' ? 'Valid secret' : 'This secret is no longer valid'}</p>
    {!_.isNil(item.firstFoundInvalidOrRevoked) && (
      <p>
        First checked as {item.validity.toLowerCase()}:{' '}
        <HighlightedText>
          <DateTime date={item.firstFoundInvalidOrRevoked} format={dateFormats.usDateTime} />
        </HighlightedText>
      </p>
    )}
    {!_.isNil(item.lastFoundValid) && (
      <p>
        Last checked as valid:{' '}
        <HighlightedText>
          <DateTime date={item.lastFoundValid} format={dateFormats.usDateTime} />
        </HighlightedText>
      </p>
    )}
  </>
);

const HighlightedText = styled.span`
  font-weight: 500;
`;
