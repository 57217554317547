import _ from 'lodash';
import styled from 'styled-components';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';
import { TriggerBoxSmallTitle } from '@src/blocks/RiskPosture/blocks/TriggersDisplay/Common';
import { LegacyPaneStylingContainer } from '@src/blocks/RiskPosture/legacy-pane-styling-container';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import { ElementPane } from '@src/components/ElementPane';
import { Link } from '@src/components/Link';
import { Number } from '@src/components/Number';
import ServerIcon from '@src/components/ServerIcon';
import { Table } from '@src/components/Table';
import TooltippedEllipsis from '@src/components/TooltippedEllipsis';
import { dispatch } from '@src/store';
import { FontBody, FontBodyLargeHeader } from '@src/style/common';

export const openRiskyLicenseDependenciesPane = ({
  ruleKey,
  profile,
  trigger,
  profileType,
  onClose,
  relevantPath,
  messageContent,
  externalRiskTriggerPane,
  level = secondaryPaneLevel,
}) => {
  const relevantRepositories =
    trigger.repositoryKeys?.reduce(
      (obj, key) => ({ ...obj, [key]: profile.repositoryByKey[key] }),
      {}
    ) ?? {};

  dispatch.pane.openPane({
    level,
    onClose,
    relevantPath,
    id: trigger.key,
    content: (
      <RiskRuleTrigger
        profile={profile}
        ruleKey={ruleKey}
        trigger={trigger}
        externalRiskTriggerPane={externalRiskTriggerPane}
        getPane={({ element, ruleTriggers }) => (
          <RiskyLicensePane
            license={element}
            profile={profile}
            profileType={profileType}
            ruleTriggers={ruleTriggers}
            messageContent={messageContent}
            trigger={trigger}
            relevantRepositories={relevantRepositories}
          />
        )}
      />
    ),
  });
};

const RiskyLicensePane = ({
  trigger,
  license,
  profile,
  profileType,
  ruleTriggers,
  messageContent,
  relevantRepositories,
}) => {
  const title = (
    <InsightsPaneTitle
      trigger={trigger}
      element={license}
      title={<PaneHeader license={license} />}
      profile={profile}
      profileType={profileType}
      ruleTriggers={ruleTriggers}
      messageContent={messageContent}
    />
  );

  const body = <PaneContent license={license} repositories={relevantRepositories} />;

  return <ElementPane element={license} title={title} profileBody={body} />;
};

export function LegacyRiskyLicensePane({ license }) {
  return (
    <LicenseStylingContainer>
      <PaneContent license={license} repositories={{}} />
    </LicenseStylingContainer>
  );
}

const PaneHeader = ({ license }) => (
  <HeaderContainer>
    {license.displayName}
    <TriggerBoxSmallTitle>
      Used in{' '}
      <Number
        value={Object.keys(license.dependencyNames).length}
        one="dependency"
        other="dependencies"
      />
    </TriggerBoxSmallTitle>
  </HeaderContainer>
);

const PaneContent = ({ license: { dependencies }, repositories }) => {
  return (
    <BodyContainer>
      <UsageHeader>License Usage</UsageHeader>
      <UsageSubheader>This license is used by the following dependencies</UsageSubheader>
      <Table
        medium
        headers={[
          { name: 'Dependency', requiresNonEmpty: true, weight: 3 },
          { name: 'Version', weight: 1 },
          { name: 'Repository', weight: 2, hide: !Object.keys(repositories).length },
        ]}
        rows={_.map(dependencies, dependencyItem => ({
          key: dependencyItem.entityId,
          cells: [
            { content: dependencyItem.name },
            { content: dependencyItem.version },
            {
              content: Object.keys(repositories).length && (
                <RepositoriesCell repositories={[repositories[dependencyItem.repositoryKey]]} />
              ),
            },
          ],
        }))}
      />
    </BodyContainer>
  );
};

const RepositoriesCell = ({ repositories }) => (
  <Cell>
    {repositories.map(repository => {
      return (
        <RepositorySubRow key={repository.key}>
          <ServerIcon provider={repository.server.provider} />
          <RepositoryLink url={`/profiles/repositories/${repository.key}`}>
            <TooltippedEllipsis text={repository.name} />
          </RepositoryLink>
        </RepositorySubRow>
      );
    })}
  </Cell>
);
const HeaderContainer = styled.div``;

const BodyContainer = styled.div`
  padding: 9rem 0;
`;

const UsageHeader = styled.div`
  ${FontBodyLargeHeader};
  margin-bottom: 6px;
  font-weight: 500;
`;

const UsageSubheader = styled.div`
  ${FontBody};
`;

const Cell = styled.div`
  overflow: hidden;
`;

const RepositorySubRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;

  &:not(:last-child) {
    margin-bottom: 3px;
  }
`;

const RepositoryLink = styled(Link)`
  flex-grow: 1;
  font-weight: 500;
`;

const LicenseStylingContainer = styled(LegacyPaneStylingContainer)`
  ${BodyContainer} {
    padding: 0;
  }

  ${UsageHeader} {
    font-size: var(--font-size-s);
    font-weight: 300;
    line-height: 1.5;
    text-decoration: underline;
    margin-bottom: 0;
  }

  ${UsageSubheader} {
    font-size: var(--font-size-s);
    margin-bottom: 2rem;
  }
`;
