import isNil from 'lodash/isNil';
import { DateTime } from '@src-v2/components/time';
import { dateFormats } from '@src-v2/data/datetime';
import { StyledRiskIcon } from '@src/blocks/RiskPosture/blocks/styles';
import { TooltipHeader, TooltipSubtext } from '@src/components/Tooltip';

const getRiskTip = triggerRiskOverride => {
  if (isNil(triggerRiskOverride)) {
    return;
  }

  let changeType, changeComment;
  if (triggerRiskOverride.isAccepted) {
    changeType = 'Accepted';
    changeComment = triggerRiskOverride.acceptReasonText;
  } else if (triggerRiskOverride.isIgnored) {
    changeType = 'Ignored';
    changeComment = triggerRiskOverride.ignoreReasonText;
  } else {
    changeType = 'Changed';
  }
  return (
    <div>
      <TooltipHeader>
        {`${changeType} by ${triggerRiskOverride.lastChangedBy}`}
        {triggerRiskOverride.lastChangedAt && (
          <span>
            &nbsp;on{' '}
            <DateTime date={triggerRiskOverride.lastChangedAt} format={dateFormats.longDate} />
          </span>
        )}
      </TooltipHeader>
      {changeComment && <TooltipSubtext>"{changeComment}"</TooltipSubtext>}
    </div>
  );
};

const TriggerStatus = ({ riskLevel, triggerRiskOverride }) => {
  const riskTip = getRiskTip(triggerRiskOverride);
  return <StyledRiskIcon tip={riskTip} riskLevel={riskLevel} />;
};

export default TriggerStatus;
