import { InputControl, TextareaControl } from '@src-v2/components/forms/form-controls';
import { Field, Label } from '@src-v2/components/forms/modal-form-layout';
import { SupportModal } from '@src-v2/containers/modals/support-modal';

export function FeedbackModal({ featureName, ...props }) {
  const modalTitle = ['Provide Feedback'];
  const defaultValues = { type: 'Feedback' };
  if (featureName) {
    modalTitle.push(`about our ${featureName}`);
    defaultValues.subject = `Feedback regarding '${featureName}' beta feature`;
  }
  return (
    <SupportModal
      {...props}
      defaultValues={defaultValues}
      title={modalTitle.filter(Boolean).join(' ')}>
      <Field>
        <Label required>Subject</Label>
        <InputControl
          name="subject"
          placeholder="Let us know what it's about"
          rules={{ required: true }}
        />
      </Field>

      <Field>
        <Label required>Description</Label>
        <TextareaControl
          name="description"
          placeholder="Let us know what's on your mind"
          rules={{ required: true }}
          rows={5}
        />
      </Field>
    </SupportModal>
  );
}
