import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { Collapsible } from '@src-v2/components/collapsible';
import { SvgIcon } from '@src-v2/components/icons';
import { Popover } from '@src-v2/components/tooltips/popover';
import {
  NavigationItem,
  NavigationSubItem,
  NewTag,
} from '@src-v2/containers/sidebar/navigation-items';
import { useInject } from '@src-v2/hooks';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { classNames } from '@src-v2/utils/style-utils';

export const NavigationGroup = observer(
  ({ item, isDocked, isOpen, onToggle, className, ...props }) => {
    const { pathname } = useLocation();
    const isActive = useMemo(
      () =>
        matchPath(
          pathname,
          item.items?.flatMap(navItem => navItem.route)
        ),
      [item, pathname]
    );

    return (
      <NavigationPopover
        maxHeight="100%"
        content={<NavigationSubItems items={item.items} title={item.title} parentItem={item} />}
        onShow={() => Boolean(isDocked)}>
        <NavigationItem
          {...props}
          className={classNames(className, { isActive })}
          data-docked={dataAttr(isDocked)}>
          <SvgIcon name="Dots" />
          <Collapsible
            open={isOpen && !isDocked}
            title={
              <NavigationItem.Label {...item} isActive={isActive}>
                {item.new && <NewTag>New</NewTag>}
              </NavigationItem.Label>
            }
            onToggle={onToggle}>
            <NavigationSubItems parentItem={item} items={item.items} />
          </Collapsible>
        </NavigationItem>
      </NavigationPopover>
    );
  }
);

const NavigationSubItems = observer(({ parentItem, items, title, ...props }) => {
  const { application, rbac } = useInject();
  return (
    <>
      {title && <PopoverMenuTitle>{title.toUpperCase()}</PopoverMenuTitle>}
      {items.map(
        ({ condition, ...item }, index) =>
          (condition?.(application, rbac) ?? true) && (
            <NavigationSubItem
              key={index}
              {...props}
              {...item}
              parentItem={parentItem}
              isNew={item.new}
            />
          )
      )}
    </>
  );
});

export const NavigationLink = observer(({ item, isDocked, className, ...props }) => {
  const { pathname } = useLocation();
  const isActive = useMemo(() => matchPath(pathname, item.route), [item.route, pathname]);

  // TODO: this will be removed once feature data-new-sidebar flag is removed, and routes will be rewritten
  const isFirstLevelRoute = useMemo(() => {
    if (pathname.includes('risks') || pathname.includes('organization')) {
      if (pathname.split('/').length > 2) {
        return false;
      }
    }
    return true;
  }, [pathname]);

  return (
    <NavigationItem
      {...props}
      className={classNames(className, { isActive: isActive && isFirstLevelRoute })}
      data-docked={dataAttr(isDocked)}>
      <NavigationItem.Label {...item} isActive={isActive} isDocked={isDocked}>
        {item.new && <NewTag>New</NewTag>}
      </NavigationItem.Label>
    </NavigationItem>
  );
});

const NavigationPopover = styled(props => {
  return (
    <Popover
      {...props}
      delay={[0, null]}
      hideOnClick={false}
      placement="right-start"
      popperOptions={{
        modifiers: [{ name: 'offset', options: { offset: [-16, 8] } }],
      }}
    />
  );
})`
  border-radius: 3rem;
  margin-left: 2rem;

  ${Popover.Content} {
    min-width: 40rem;
    border-radius: 3rem;
    background-color: var(--color-blue-gray-70);
  }

  [data-popper-arrow]:before {
    background-color: var(--color-blue-gray-70);
  }

  > [data-docked] {
    ${NavigationItem.Label} {
      width: 0;
      overflow: hidden;
      transition: width 200ms;
    }
  }
`;

const PopoverMenuTitle = styled.div`
  font-size: var(--font-size-xs);
  color: var(--color-blue-gray-55);
  font-weight: 700;
  padding-left: 4rem;
`;
