import styled from 'styled-components';
import { DeleteButton } from '@src-v2/components/buttons';
import { SvgIcon } from '@src-v2/components/icons';
import { Link } from '@src/components/Link';
import { FontBodySmall } from '@src/style/common';

export const StyledOption = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 9rem;
  padding: 1.5rem;
  border-radius: 4rem;
  background-color: var(--color-blue-gray-20);
`;

StyledOption.InputRow = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 2rem;
`;

StyledOption.ErrorLine = styled.div`
  color: var(--color-failure);
  margin-left: 1.5rem;
`;

export const StyledProperties = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${DeleteButton} {
    width: 4.5rem;
    height: 4.5rem;
  }
`;

export const OptionText = styled.span`
  display: inline-block;

  :first-child {
    padding-left: 2rem;
  }

  :last-child {
    padding-right: 2rem;
  }
`;

export const Conjunction = styled.div`
  display: flex;
  flex-direction: column;
  height: 6rem;
`;

export const DisabledAddIcon = styled(props => <SvgIcon {...props} name="Plus" />)`
  opacity: 0.3;
`;

export const Modifier = styled(Link)`
  ${FontBodySmall};
`;
