import map from 'lodash/map';
import { humanize } from '@src-v2/utils/string-utils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { TriggerInfoContainer } from './Common';

export const getRbacRoleTriggerDisplay = ({ trigger }) => [
  {
    weight: 3,
    content: (
      <TriggerInfoContainer
        insights={trigger.insights}
        title={
          <HorizontalStack withSeparator>
            {map(trigger.roleTypes, roleType => (
              <div key={roleType}>{humanize(roleType)}</div>
            ))}
          </HorizontalStack>
        }>
        <div>{trigger.displayName}</div>
      </TriggerInfoContainer>
    ),
  },
];

export const getRbacRoleMessageFormat = element =>
  `${element.roleTypes.join('|')} - ${element.elementKey}`;
