import _ from 'lodash';
import { ExternalLink, Heading, Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  MaterialChangesContainer,
} from '@src-v2/containers/commit/common-componnets';
import { getCommitCodeReferenceUrl } from '@src-v2/containers/commit/material-changes-utils';
import { humanize } from '@src-v2/utils/string-utils';

export const TerraformMisconfigurationsMaterialChange = ({
  commitSha,
  repository,
  materialChange,
}) => {
  const groupedMisconfigurations = _.groupBy(
    materialChange.newMisconfigurationsDescriptions,
    _ => _.description
  );
  const aggregatedMisconfigurationsByDisplay = Object.keys(groupedMisconfigurations).map(key => ({
    description: key,
    lineInFile: groupedMisconfigurations[key][0].lineInFile,
    checkCategory: groupedMisconfigurations[key][0].checkCategories[0],
    fields: groupedMisconfigurations[key].flatMap(
      misconfiguration => misconfiguration.misconfiguredProperties
    ),
  }));
  const aggregatedMisconfigurationsByCategory = _.groupBy(
    aggregatedMisconfigurationsByDisplay,
    misconfiguration => misconfiguration.checkCategory
  );

  return (
    <MaterialChangesContainer>
      <Heading>
        New misconfigurations introduced in resource
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={materialChange.resourceSummary.path}>
          {`${materialChange.resourceSummary.typeDescription} (${materialChange.resourceSummary.key})`}
        </CommitCodeReference>
        <Paragraph> under module</Paragraph>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={materialChange.modulePath}>
          {materialChange.modulePath}
        </CommitCodeReference>
      </Heading>
      {Object.keys(aggregatedMisconfigurationsByCategory).map(checkCategory => (
        <>
          <Heading>
            <Strong>{checkCategory !== 'undefined' ? humanize(checkCategory) : 'General'}</Strong>{' '}
            misconfigurations:
          </Heading>

          {aggregatedMisconfigurationsByCategory[checkCategory].map(misconfiguration => (
            <>
              {_.isNil(commitSha) ? (
                <ExternalLink
                  href={getCommitCodeReferenceUrl(
                    repository,
                    materialChange.resourcesSummary.path,
                    misconfiguration.lineInFile || 0
                  )}>
                  {misconfiguration.description}
                </ExternalLink>
              ) : (
                <CommitCodeReference
                  repository={repository}
                  commitSha={commitSha}
                  relativeFilePath={materialChange.resourceSummary.path}>
                  {misconfiguration.description}
                </CommitCodeReference>
              )}
              {misconfiguration.fields?.length > 0 && (
                <>Relevant resource fields: {misconfiguration.fields.join(', ')}</>
              )}
            </>
          ))}
        </>
      ))}
    </MaterialChangesContainer>
  );
};
