import _ from 'lodash';
import { useMemo, useState } from 'react';
import stringSimilarity from 'string-similarity';
import { defaultItemToString } from '@src-v2/hooks/use-downshift';

export function useSimpleSearch(items, itemToString = defaultItemToString, initialSearchTerm = '') {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const inputItems = useMemo(
    () => items.filter(createItemFilter(searchTerm, itemToString)),
    [items, itemToString, searchTerm]
  );
  return { inputItems, searchTerm, setSearchTerm };
}

export function useGroupSearch(
  items,
  itemToString = defaultItemToString,
  initialSearchTerm = '',
  disabled = false
) {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const inputItems = useMemo(() => {
    if (disabled) {
      return items;
    }
    const itemFilter = createItemFilter(searchTerm, itemToString);
    return items.reduce((matchItems, item) => {
      if (item?.options) {
        const options = item.options.filter(itemFilter);
        if (options.length) {
          matchItems.push({ ...item, options });
        }
      } else if (itemFilter(item)) {
        matchItems.push(item);
      }
      return matchItems;
    }, []);
  }, [items, itemToString, searchTerm]);
  return { inputItems, searchTerm, setSearchTerm };
}

export function useSpecialQuestionnaireSearch(
  items,
  itemToString = defaultItemToString,
  initialSearchTerm = ''
) {
  const [searchTerm, setSearchTerm] = useState(initialSearchTerm);
  const inputItems = useMemo(() => {
    const normalized = searchTerm.trim().toLowerCase();
    if (_.isEmpty(normalized)) {
      return items;
    }

    if (normalized.indexOf(' ') === -1) {
      const itemFilter = createItemFilter(searchTerm, itemToString);
      return items.filter(itemFilter);
    }

    return _.orderBy(
      items
        .map(item => ({
          item,
          relevance: stringSimilarity.compareTwoStrings(
            normalized,
            itemToString(item).toLowerCase()
          ),
        }))
        .filter(item => item.relevance > 0.2),
      'relevance',
      'desc'
    )
      .slice(0, 5)
      .map(item => item.item);
  }, [items, itemToString, searchTerm]);
  return { inputItems, searchTerm, setSearchTerm };
}

function createItemFilter(searchTerm, itemToString) {
  const normalized = searchTerm.trim().toLowerCase();
  return item => itemToString(item).toLowerCase().includes(normalized);
}
