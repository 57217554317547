import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useCallback, useState } from 'react';
import styled from 'styled-components';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import GitReferencesAutoComplete from '@src/blocks/GitReferencesAutoComplete';
import { ConsumablesSelector } from '@src/components/ConsumablesSelector';
import { ErrorMessage } from '@src/components/ErrorMessage';
import { StyledRemainingChars } from '@src/components/Forms/RemainingChars';
import { Col, FormGroup, Input, Label } from '@src/components/reactstrap-polyfill';
import { isValidGitSha } from '@src/utils/gitShaUtils';

const StyledInput = styled(Input)`
  ::placeholder {
    color: var(--color-blue-gray-45);
  }

  &:disabled {
    background: var(--color-blue-gray-15);
  }
`;

const Row = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
`;

const nameMaxLength = 32;

const ReleaseSide = ({
  title,
  release,
  isEdit,
  side,
  isFieldInvalid,
  modificationHandlers,
  sideErrorStatus,
  searchCommitish,
}) => (
  <Col>
    <FormGroup>
      <Label htmlFor={side}>{title}</Label>
      <GitReferencesAutoComplete
        id={side}
        name={side}
        repositoryKey={release.providerRepositoryKey}
        gitReference={isEdit ? release[side] : null}
        invalid={
          isFieldInvalid(release[side].identifier) ||
          release.status === sideErrorStatus ||
          release.baseline.commitSha === release.candidate.commitSha ||
          (release[side].commitSha && !isValidGitSha(release[side].commitSha))
        }
        disabled={isEmpty(release.providerRepositoryKey)}
        searchCommitish={searchCommitish}
        onChange={value =>
          modificationHandlers({
            ...release,
            [side]: {
              ...release[side],
              identifier: value?.value.name,
              commitSha: value?.value.headSha,
              refType: value?.value.refType,
            },
          })
        }
      />
    </FormGroup>
  </Col>
);

export const ReleaseEditModal = ({ isEdit, onSave, onCancel, initialValue }) => {
  const [release, setRelease] = useState(initialValue);
  const [serverError, setServerError] = useState(null);

  const handleOnSubmit = useCallback(async () => {
    setServerError(null);

    try {
      await onSave(release);
      return true;
    } catch (error) {
      setServerError(error);
      return false;
    }
  }, [release]);

  const isFieldInvalid = value => isEmpty(value);

  return (
    <ConfirmationModal
      title={isEdit ? 'Edit Release Risk Assessment' : 'Create Release Risk Assessment'}
      submitText={isEdit ? 'Save' : 'Generate'}
      onSubmit={handleOnSubmit}
      onClose={onCancel}
      disabledSubmitButton={
        !release.name ||
        !release.baseline.identifier ||
        !release.candidate.identifier ||
        !release.providerRepositoryKey ||
        release.baseline.commitSha === release.candidate.commitSha ||
        !isValidGitSha(release.baseline.commitSha) ||
        !isValidGitSha(release.candidate.commitSha)
      }>
      <FormGroup>
        <Label htmlFor="name">Release Name</Label>
        <StyledInput
          autoComplete="off"
          value={release.name}
          invalid={isFieldInvalid(release.name)}
          type="text"
          name="name"
          id="name"
          placeholder="e.g. Release 1.0"
          onChange={event => setRelease(release => ({ ...release, name: event.target.value }))}
          maxLength={nameMaxLength}
        />
      </FormGroup>
      <StyledRemainingChars>{`${release.name.length}/${nameMaxLength}`}</StyledRemainingChars>
      <FormGroup>
        <Label htmlFor="providerRepositoryKey">Repository</Label>

        <ConsumablesSelector
          onKeyDown={event => isEmpty(release.providerRepositoryKey) && event.stopPropagation()}
          selectedConsumable={null}
          tableScope="providerRepositories"
          placeholder="Search repositories"
          onChange={value =>
            setRelease({
              ...release,
              providerRepositoryKey: value.value,
              baseline: {
                identifier: '',
                commitSha: '',
                refType: 'branch',
              },
              candidate: {
                identifier: '',
                commitSha: '',
                refType: 'branch',
              },
            })
          }
          searchParams={{ hidePerforce: true }}
          components={{
            DropdownIndicator: () => null,
          }}
        />
      </FormGroup>

      <Row>
        <ReleaseSide
          autoComplete="off"
          title="Baseline"
          release={release}
          isEdit={isEdit}
          side="baseline"
          isFieldInvalid={isFieldInvalid}
          modificationHandlers={setRelease}
          sideErrorStatus="ErrorBaselineNotFound"
        />
        <ReleaseSide
          autoComplete="off"
          title="Candidate"
          release={release}
          isEdit={isEdit}
          side="candidate"
          isFieldInvalid={isFieldInvalid}
          modificationHandlers={setRelease}
          sideErrorStatus="ErrorCandidateNotFound"
        />
      </Row>
      {!isNil(serverError) && <ErrorMessage message={serverError.message} />}
      {release.baseline.commitSha && release.baseline.commitSha === release.candidate.commitSha && (
        <ErrorMessage message="Please change baseline or candidate (both are pointing to the same commit)" />
      )}
    </ConfirmationModal>
  );
};
