import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';
import { LegacyPaneStylingContainer } from '@src/blocks/RiskPosture/legacy-pane-styling-container';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import { Highlights } from '@src/components/CodeAggregation/Insights/Highlights';
import { StyledInsightsTitle } from '@src/components/CodeAggregation/Insights/InsightStyles';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { ElementPane } from '@src/components/ElementPane';
import { Number } from '@src/components/Number';
import { VerticalStack } from '@src/components/VerticalStack';
import { dispatch } from '@src/store';
import { getStyledSortedList } from './Common';

const getInsightsBody = (dataModel, highlights) => (
  <>
    <Highlights highlights={highlights} />
    <VerticalStack>
      <StyledInsightsTitle>Fields</StyledInsightsTitle>
      {getStyledSortedList(dataModel.fieldNames)}
    </VerticalStack>
  </>
);

const getHighlights = dataModel => {
  const highlights = [];
  highlights.push(<Number one="field" other="fields" value={dataModel.fieldNames.length} />);
  return highlights;
};

const getDataModelPane = ({
  trigger,
  dataModel,
  repository,
  profile,
  profileType,
  ruleTriggers,
  messageContent,
}) => {
  const highlights = getHighlights(dataModel);

  return (
    <ElementPane
      element={dataModel}
      repository={repository}
      title={
        <InsightsPaneTitle
          trigger={trigger}
          element={dataModel}
          title={`${dataModel.codeReference.name} Data Model`}
          codeReference={dataModel.codeReference}
          repository={repository}
          profile={profile}
          profileType={profileType}
          ruleTriggers={ruleTriggers}
          messageContent={messageContent}
        />
      }
      profileBody={getInsightsBody(dataModel, highlights)}
    />
  );
};

export function LegacyDataModelPane({ dataModel }) {
  return (
    <LegacyPaneStylingContainer>
      {getInsightsBody(dataModel, getHighlights(dataModel))}
    </LegacyPaneStylingContainer>
  );
}

export const openDataModelPaneWithRiskActions = ({
  ruleKey,
  trigger,
  profile,
  profileType,
  onClose,
  relevantPath,
  messageContent,
  externalRiskTriggerPane,
  level = secondaryPaneLevel,
}) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  dispatch.pane.openPane({
    level,
    onClose,
    relevantPath,
    id: trigger.key,
    content: (
      <RiskRuleTrigger
        profile={profile}
        ruleKey={ruleKey}
        trigger={trigger}
        externalRiskTriggerPane={externalRiskTriggerPane}
        getPane={({ element, ruleTriggers }) =>
          getDataModelPane({
            trigger,
            dataModel: element,
            repository,
            profile,
            profileType,
            ruleTriggers,
            messageContent,
          })
        }
      />
    ),
  });
};
