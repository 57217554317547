import _ from 'lodash';
import filtersService from '@src/services/filtersService';

export const getDataModelsFilterOptions = () => [
  {
    name: 'Components',
    filterOptions: [
      {
        name: 'Sensitive',
        displayName: 'Sensitive',
      },
    ],
  },
];

const filterDataModelToCondition = {
  Sensitive: dataModel => dataModel.isSensitive,
};

export const filterDataModels = (allItems, searchTerm, filters, filterOperator) => {
  const filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, item => [
    item.codeReference.name,
  ]);

  const filterConditions = filters.booleanFilters
    .map(filter => filterDataModelToCondition[filter])
    .filter(Boolean);

  return _.isEmpty(filterConditions)
    ? filtered
    : filtered.filter(dataModel =>
        filterOperator(filterConditions, condition => condition(dataModel))
      );
};
