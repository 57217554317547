import _ from 'lodash';
import { useEffect } from 'react';
import styled from 'styled-components';
import { Chip } from '@src-v2/components/chips';
import { ClampText } from '@src-v2/components/clamp-text';
import { SelectV2 } from '@src-v2/components/select/select-v2';
import CreateProcess from './blocks/CreateProcess';

const processToOption = process => ({
  label: process.name,
  value: process.key,
});

const ProcessTagSelector = ({
  onSelect,
  processes,
  selectedProcessTag,
  setSelectedProcess,
  error,
  loading,
  fetchProcessTypes,
  createProcessTag,
  getFilteredProcesses,
  processTypes,
  newProcess,
  setNewProcess,
  setEditMode,
  clearData,
  isMulti = false,
}) => {
  useEffect(() => {
    setSelectedProcess(
      !processes ? null : isMulti ? processes.map(processToOption) : processToOption(processes)
    );
    return clearData;
  }, [clearData, isMulti, processes, setSelectedProcess]);

  const onChange = selectedProcess => {
    setSelectedProcess(selectedProcess);
    onSelect(selectedProcess);
  };

  if (!newProcess) {
    return (
      <ProcessSelect
        creatable
        multiple={isMulti}
        placeholder="Select a process..."
        value={selectedProcessTag}
        searchMethod={getFilteredProcesses}
        onCreateOption={setNewProcess}
        option={({ data }) => <ClampText>{data.label}</ClampText>}
        onChange={onChange}
      />
    );
  }

  return (
    <CreateProcess
      fetchProcessTypes={fetchProcessTypes}
      newProcess={newProcess}
      processTypes={processTypes}
      error={error}
      loading={loading}
      createProcessTag={async newProcess => {
        const createdProcess = await createProcessTag(newProcess);
        if (_.isNil(createdProcess)) {
          return;
        }

        setNewProcess(null);
        setEditMode(false);
        const createdValue = {
          label: createdProcess.name,
          value: createdProcess.key,
        };
        const newSelection = isMulti ? [...(selectedProcessTag ?? []), createdValue] : createdValue;
        setSelectedProcess(newSelection);
        onSelect(newSelection);
      }}
      onCancel={() => {
        setNewProcess(null);
        setEditMode(false);
      }}
    />
  );
};

export default ProcessTagSelector;

const ProcessSelect = styled(SelectV2)`
  ${Chip} {
    font-size: var(--font-size-s);
  }
`;
