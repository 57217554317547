import map from 'lodash/map';
import { getViewCodeColumn } from '@src/components/CodeAggregation/Common';
import { Table } from '@src/components/Table';
import { getModule } from '@src/utils/moduleFinder';
import { getMethodName } from '@src/utils/stringDisplayUtils';

const ApiStorageBucketAccesses = ({ profile, repository, accessingMethods }) => (
  <Table
    medium
    items={accessingMethods}
    headers={[
      { name: 'Module', requiresNonEmpty: true, weight: 1.5 },
      { name: 'Class', requiresNonEmpty: true, weight: 1 },
      { name: 'Method', requiresNonEmpty: true, weight: 1 },
      { name: 'Provider', requiresNonEmpty: true, weight: 1 },
      { name: 'Provider Method', requiresNonEmpty: true, weight: 1 },
    ]}
    rows={map(accessingMethods, accessingMethod => {
      const { codeReference } = accessingMethod;
      const module = getModule(profile, codeReference.relativeFilePath);
      return {
        key: accessingMethod,
        cells: [
          {
            content: module ? module.root : '',
          },
          {
            content: codeReference ? codeReference.name : '',
          },
          {
            content: getMethodName(accessingMethod.neighborMethodFullName, false),
          },
          {
            content: accessingMethod.framework,
          },
          {
            content: getMethodName(accessingMethod.externalMethodCall, false),
            menu: [getViewCodeColumn(codeReference, repository)],
          },
        ],
      };
    })}
  />
);

export default ApiStorageBucketAccesses;
