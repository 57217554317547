import DependencyPane from '@src/blocks/DependencyPane';
import { primaryPaneLevel, secondaryPaneLevel } from '@src/blocks/Pane/model';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import { dispatch } from '@src/store';

export const openDependencyPaneWithRiskActions = ({
  ruleKey,
  profile,
  trigger,
  profileType,
  onClose,
  relevantPath,
  messageContent,
  externalRiskTriggerPane,
  level = secondaryPaneLevel,
}) =>
  dispatch.pane.openPane({
    level,
    onClose,
    relevantPath,
    id: trigger.key,
    content: (
      <RiskRuleTrigger
        profile={profile}
        ruleKey={ruleKey}
        trigger={trigger}
        externalRiskTriggerPane={externalRiskTriggerPane}
        getPane={({ element, ruleTriggers }) => (
          <DependencyPane
            trigger={trigger}
            profile={profile}
            dependency={element}
            ruleTriggers={ruleTriggers}
            profileType={profileType}
            messageContent={messageContent}
          />
        )}
      />
    ),
  });

export const openDependencyPane = ({ profile, dependency, profileType }) =>
  dispatch.pane.openPane({
    id: dependency.entityId,
    level: primaryPaneLevel,
    content: <DependencyPane profile={profile} dependency={dependency} profileType={profileType} />,
  });
