import { observer } from 'mobx-react';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { ProfileCard } from '@src-v2/components/cards/profile-card';
import { Paragraph, Subtitle, Title } from '@src-v2/components/typography';
import {
  ProfileWidget,
  ProfileWidgetLabel,
} from '@src/blocks/ConsumableInfiniteScroll/blocks/ConsumableProfileWidget';
import { Number } from '@src/components/Number';

export const ClusterCard = observer(
  ({
    profile,
    profile: { name, clusterType, clusterStats, profileCalculationComplete, accountId },
  }) => {
    return (
      <ProfileCard to={`/inventory/clusters/${profile.key}`}>
        <Header>
          <Description>
            <Title>{name}</Title>
            <Subtitle>
              {clusterType}
              {accountId && ` | Account ID: ${accountId}`}
            </Subtitle>
          </Description>
        </Header>
        {profileCalculationComplete ? (
          <Widgets>
            <ClusterStatWidget statName="Namespaces" statValue={clusterStats.namespaceCount} />
            <ClusterStatWidget statName="Containers" statValue={clusterStats.containerCount} />
            <ClusterStatWidget statName="Gateways" statValue={clusterStats.gatewayCount} />
            <ClusterStatWidget
              statName="Exposed Containers"
              statValue={clusterStats.externallyAccessibleContainers}
            />
          </Widgets>
        ) : (
          <CalculatingCluster>
            <LogoSpinner />
            <Paragraph>Discovering cluster...</Paragraph>
          </CalculatingCluster>
        )}
      </ProfileCard>
    );
  }
);

function ClusterStatWidget({ statName, statValue }) {
  return (
    <ProfileWidget>
      <CounterLabel>
        <Number value={statValue} />
      </CounterLabel>
      <ProfileWidgetLabel>{statName}</ProfileWidgetLabel>
    </ProfileWidget>
  );
}

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  line-height: 1;
`;

const Description = styled.div``;

const Widgets = styled.div`
  height: 18rem;
  display: flex;
  gap: 12rem;
`;

const CalculatingCluster = styled.div`
  align-self: center;
  text-align: center;
  height: 18rem;

  ${Paragraph} {
    margin-top: 3rem;
  }
`;

const CounterLabel = styled.span`
  align-self: center;
  line-height: 1;
  text-align: center;
  font-size: 10rem;
`;
