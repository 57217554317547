import { Link } from 'react-router-dom';
import { StubAny } from '@src-v2/types/stub-any';
import { StyledProps } from '@src-v2/types/styled';

export const ContributorLink = ({
  className,
  developerProfile,
  children,
}: {
  developerProfile: StubAny;
} & StyledProps) => (
  <Link
    to={`/users/contributors/${developerProfile.representativeIdentityKeySha}`}
    className={className}>
    {children || developerProfile.displayName}
  </Link>
);
