import { resourceTypes } from '@src-v2/data/rbac-types';
import { AccessType } from '@src-v2/types/enums/access-type';

export class RBAC {
  #session;

  constructor({ session }) {
    this.#session = session;
  }

  get hasGlobalScopeAccess() {
    return this.#session.data?.consoleUser?.hasGlobalScope;
  }

  canEdit(...resources) {
    return this.#hasAccessType(AccessType.Write, resources);
  }

  canView(...resources) {
    return this.#hasAccessType(AccessType.Read, resources);
  }

  hasPermission(permission) {
    return this.#hasAccessType(AccessType[permission.accessType], [permission.resourceName]);
  }

  getAccessType(resources) {
    const resourceToAccessType = resource => {
      if (!this.#session.permissions?.length) {
        return AccessType.None;
      }
      const resourcePermission = this.#session.permissions.find(
        permission => permission.resourceName === resource
      );
      return AccessType[resourcePermission?.accessType] ?? AccessType.None;
    };
    return resources.concat(resourceTypes.Global).map(resourceToAccessType).sort().pop();
  }

  #hasAccessType(accessType, resources) {
    return this.getAccessType(resources) >= accessType;
  }
}
