import { HorizontalStack } from '@src/components/HorizontalStack';
import { openFrameworkPane } from '@src/components/Panes/FrameworkPane';
import { EllipsisDiv } from '@src/style/common';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getFrameworkTriggerDisplay = ({ trigger, profile }) => {
  return [
    {
      weight: 3,
      content: (
        <PaneLocationLink
          id={trigger.key}
          onClick={args =>
            openFrameworkPane({
              ...args,
              trigger,
              profile,
            })
          }>
          <TriggerInfoContainer
            title={
              <HorizontalStack withSeparator>
                <span>{trigger.technology}</span>
                <span>{trigger.framework}</span>
              </HorizontalStack>
            }
            insights={trigger.insights}>
            <EllipsisDiv>{trigger.displayName}</EllipsisDiv>
          </TriggerInfoContainer>
        </PaneLocationLink>
      ),
    },
  ];
};

export const getFrameworkMessageFormat = element => element.displayName;
