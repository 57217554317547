import { observer } from 'mobx-react';
import { forwardRef, useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { IconButton } from '@src-v2/components/buttons';
import { Dropdown } from '@src-v2/components/dropdown';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { InfiniteScroll } from '@src-v2/components/infinite-scroll';
import { Marker } from '@src-v2/components/marker';
import { Popover, hideOnItemClickPlugin } from '@src-v2/components/tooltips/popover';
import { TippyAttributes } from '@src-v2/components/tooltips/tippy';
import { InputAttributes } from '@src-v2/data/element-attributes';
import { useForwardRef, useGroupProperties } from '@src-v2/hooks';
import {
  creatableSymbol,
  defaultItemToString,
  useExtendedCombobox,
} from '@src-v2/hooks/use-downshift';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { classNames } from '@src-v2/utils/style-utils';
import { Input } from './input';

export const Combobox = styled(
  observer(
    forwardRef(
      (
        {
          // controller props
          error,
          // component props
          markTerm,
          isLoading,
          emptyState,
          dropdownItem,
          dropdownGroup,
          emptyFallback,
          className,
          style,
          children,
          multiple,
          creatable,
          expandable,
          expandIcon = 'PlusSmall',
          searchState,
          startExpanded,
          showIconAtRight,
          // downshift props
          items,
          itemsFilter,
          getDropdownProps,
          renderItemContent,
          value: defaultSelectedItem,
          onInput: onInputValueChange,
          onSelect: onSelectedItemChange,
          itemToString = defaultItemToString,
          popover: ItemsPopover = Combobox.Popover,
          ...props
        },
        ref
      ) => {
        const inputRef = useForwardRef(ref);
        const [inputProps, popoverAttributes, comboboxOptions] = useGroupProperties(
          props,
          InputAttributes,
          TippyAttributes
        );
        const {
          itemsGrouped,
          getItemProps,
          getInputProps,
          getMenuProps,
          openMenu,
          ...downshiftProps
        } = useExtendedCombobox({
          items,
          itemsFilter,
          itemToString,
          defaultSelectedItem,
          getDropdownProps,
          onInputValueChange,
          onSelectedItemChange,
          ref: inputRef,
          ...comboboxOptions,
        });
        const inputIcon = inputProps.icon ?? props.selectedItems?.icon;

        const handleExpand = useCallback(() => {
          if (downshiftProps.isOpen) {
            downshiftProps.closeMenu();
          } else {
            openMenu();
            inputRef.current?.focus();
          }
        }, [inputRef, openMenu, downshiftProps.isOpen, downshiftProps.closeMenu]);

        useEffect(() => {
          if (startExpanded) {
            openMenu();
            inputRef.current?.focus();
          }
        }, []);

        const itemFactory = (
          <ItemsFactory
            items={itemsGrouped}
            truncateOptions={props.truncateOptions}
            markTerm={markTerm}
            getItemProps={getItemProps}
            dropdownItem={dropdownItem}
            dropdownGroup={dropdownGroup}
            renderItemContent={renderItemContent}
          />
        );

        const popoverContent = downshiftProps.isOpen ? (
          searchState ? (
            itemsGrouped.length || !emptyState ? (
              itemFactory
            ) : (
              <EmptyItem>No Options</EmptyItem>
            )
          ) : isLoading ? (
            <LogoSpinner />
          ) : itemsGrouped.length || !emptyState ? (
            itemFactory
          ) : (
            <EmptyItem>No Options</EmptyItem>
          )
        ) : null;

        return (
          <ItemsPopover
            {...getMenuProps()}
            {...popoverAttributes}
            creatable={creatable}
            visible={downshiftProps.isOpen}
            searchState={searchState}
            content={popoverContent}>
            <Dropdown
              data-invalid={dataAttr(error)}
              className={classNames(className, {
                invalid: inputRef.current?.checkValidity() === false,
              })}>
              <Combobox.InputContainer
                data-has-icon={dataAttr(Boolean(inputIcon))}
                data-icon-at-right={dataAttr(showIconAtRight)}
                data-readonly={dataAttr(props.readOnly)}
                data-disabled={dataAttr(props.disabled)}>
                {typeof children === 'function' ? children(downshiftProps) : children}
                {expandable && (
                  <Combobox.ExpandIcon
                    data-disabled={dataAttr(props.disabled)}
                    name={expandIcon}
                    onClick={props.disabled ? null : handleExpand}
                  />
                )}
                {inputIcon && <Combobox.InputIcon>{inputIcon}</Combobox.InputIcon>}
                <Input {...getInputProps(inputProps)} data-invalid={dataAttr(error)} />
                {emptyState && multiple && (
                  <Chevron name="Chevron" size="xsmall" onClick={handleExpand} />
                )}
              </Combobox.InputContainer>
            </Dropdown>
          </ItemsPopover>
        );
      }
    )
  )
)`
  width: fit-content;

  ${Dropdown.List} {
    top: calc(100% + 1rem);
    right: 0;
    padding: 4rem;

    ${LogoSpinner} {
      height: 6rem;
      width: 6rem;
    }
  }

  ${Dropdown.Title} {
    padding: 0 2rem;
  }
`;

Combobox.InputIcon = styled.div`
  position: absolute;
  left: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: var(--color-blue-gray-50);
`;

const MAX_RENDERED_ITEMS = 100;

function ItemsFactory({
  items,
  truncateOptions,
  markTerm,
  getItemProps,
  renderItemContent,
  dropdownItem: DropdownItem = DefaultDropdownItem,
  dropdownGroup: DropdownGroup = Dropdown.Group,
}) {
  if (!renderItemContent) {
    renderItemContent = ({ item }) =>
      markTerm ? <Marker text={item.label} term={markTerm} /> : item.label;
  }

  const createItem = useCallback(
    item => (
      <DropdownItem
        {...getItemProps(item)}
        key={item.value?.key ?? item.label}
        item={item}
        creatable={Boolean(item.value?.[creatableSymbol])}>
        {renderItemContent({ item })}
      </DropdownItem>
    ),
    [markTerm, getItemProps]
  );

  // This is a temporary solution to avoid crashing over rendering of many Ks of items until we implement some client
  // side pagination or lazy rendering
  const itemsForRendering = truncateOptions ? items.slice(0, MAX_RENDERED_ITEMS) : items;
  return itemsForRendering.map(item =>
    item.options ? (
      <DropdownGroup key={item.label} title={item.label}>
        {item.options.map(createItem)}
      </DropdownGroup>
    ) : (
      createItem(item)
    )
  );
}

const DefaultDropdownItem = forwardRef(({ item, creatable, children, ...props }, ref) => (
  <Dropdown.Item ref={ref} {...props}>
    {creatable ? <>Add {children}</> : children}
  </Dropdown.Item>
));

const EmptyItem = styled.li`
  color: var(--color-blue-gray-45);
`;

Combobox.InputContainer = styled.div`
  position: relative;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: flex-start;
  gap: 2rem;
  flex-wrap: wrap;

  &[data-has-icon] > ${Input} {
    padding-left: 10rem;
  }

  &[data-icon-at-right] > ${Input} {
    padding-right: 10rem;
    padding-left: unset;
  }

  &[data-icon-at-right] > ${Combobox.InputIcon} {
    left: unset;
    right: 3rem;
  }

  &:hover,
  > ${Input}:hover {
    cursor: pointer;
    transition: none;
  }
}

&[data-readonly] {
  > ${Input}:focus {
    border-color: var(--color-blue-gray-45);
  }

  &:hover,
  > ${Input}:hover {
    cursor: pointer;
    transition: none;
  }
}

&[data-disabled] {
  pointer-events: none;

  ${Combobox.InputIcon} {
    color: var(--color-blue-gray-35);
  }
}

> ${Input} {
  &::placeholder {
    font-size: var(--font-size-s);
  }
}
`;

Combobox.ExpandIcon = styled(SvgIcon)`
  color: var(--color-white);
  border-radius: 100vmax;
  background-color: var(--color-blue-gray-70);

  ${Combobox.InputContainer} > &[data-disabled] {
    background-color: var(--color-blue-gray-30);
  }
`;

Combobox.Popover = styled(
  forwardRef(({ creatable, searchState, placement = 'bottom-start', ...props }, ref) => (
    <Popover
      noArrow
      ref={ref}
      data-creatable={dataAttr(creatable)}
      searchState={searchState}
      contentAs={InfiniteScrollDropdownList}
      placement={placement}
      popperOptions={{
        modifiers: [
          { name: 'offset', options: { offset: [0, -4] } },
          {
            name: 'matchParentWidth',
            enabled: true,
            phase: 'beforeWrite',
            requires: ['computeStyles'],
            fn: ({ state }) => {
              state.styles.popper.width = `${state.rects?.reference?.width ?? 0}px`;
            },
            effect: ({ state }) => {
              state.elements.popper.style.width = `${
                state.elements?.reference?.clientWidth ?? 0
              }px`;
            },
          },
        ],
      }}
      plugins={[hideOnItemClickPlugin]}
      {...props}
    />
  ))
)`
  box-shadow: none;
  user-select: none;

  &[data-placement='top-start'] {
    bottom: 2rem;
  }

  &[data-placement='bottom-start'] {
    top: 2rem;
  }

  ${Popover.Content} {
    width: 100%;
    min-width: 40rem;
    max-width: 35vw;
    border-radius: 3rem;
  }

  ${Dropdown.Item}, ${EmptyItem} {
    font-size: var(--font-size-s);

    > ${BaseIcon} {
      color: var(--color-blue-gray-50);
    }

    &:hover > ${BaseIcon} {
      color: var(--color-blue-gray-60);
    }
  }
`;

function InfiniteScrollDropdownList({ searchState, ...props }) {
  const dropdownRef = useRef(null);

  useEffect(() => {
    if (
      searchState &&
      !searchState.loading &&
      searchState.hasMore &&
      dropdownRef.current?.scrollHeight <= dropdownRef.current?.offsetHeight
    ) {
      searchState.loadMore();
    }
  }, [dropdownRef.current?.scrollHeight, dropdownRef.current?.offsetHeight, searchState]);

  return (
    <InfiniteScroll
      ref={dropdownRef}
      as={Dropdown.List}
      scrollParent={document.querySelector('[data-tippy-root] ul')}
      searchState={searchState}
      {...props}
    />
  );
}

const Chevron = styled(IconButton)`
  position: absolute;
  top: 0.75rem;
  right: -6rem;
  transition: all 400ms;
  transform: rotate(90deg);

  [aria-expanded='true'] & {
    transform: rotate(-90deg);
  }
`;
