import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { VerticalStack } from '@src/components/VerticalStack';
import { TimelineEvent } from './TimelineEvent';

const StyledMaterialChange = styled(HorizontalStack)`
  color: var(--default-text-color);
  padding-bottom: 3rem;
`;

const StyledVerticalStack = styled(VerticalStack)`
  font-style: italic;
  padding: 0 0 0 12rem;
`;

const StyledSpan = styled.span`
  font-style: italic;
  padding: 0 0 0 9rem;
`;

const materialChangesBody = (labels, apisGroupModificationInfo) => (
  <>
    <StyledMaterialChange>
      <VerticalStack>
        {apisGroupModificationInfo.addedExposedRoutesWithControls &&
        !isEmpty(Object.keys(apisGroupModificationInfo.addedExposedRoutesWithControls))
          ? displayRoutesWithPropertiesArray(
              apisGroupModificationInfo.addedExposedRoutesWithControls,
              'New routes exposed via gateway',
              'is protected with'
            )
          : ''}
        {apisGroupModificationInfo.addedSecurityControlsForUnchangedRoutes &&
        !isEmpty(Object.keys(apisGroupModificationInfo.addedSecurityControlsForUnchangedRoutes))
          ? displayRoutesWithPropertiesArray(
              apisGroupModificationInfo.addedSecurityControlsForUnchangedRoutes,
              'Added security controls:',
              'added'
            )
          : ''}
        {apisGroupModificationInfo.removedSecurityControlsForUnchangedRoutes &&
        !isEmpty(Object.keys(apisGroupModificationInfo.removedSecurityControlsForUnchangedRoutes))
          ? displayRoutesWithPropertiesArray(
              apisGroupModificationInfo.removedSecurityControlsForUnchangedRoutes,
              'Removed security controls:',
              'removed'
            )
          : ''}
      </VerticalStack>
    </StyledMaterialChange>
  </>
);
const displayRoutesWithPropertiesArray = (addedExposedRoutesWithControls, title, description) => (
  <VerticalStack>
    <div>{title}</div>
    <StyledVerticalStack>
      {Object.keys(addedExposedRoutesWithControls).map(route => (
        <div key={route}>
          <HorizontalStack>
            <span>route</span>
            <Bold>{route}</Bold>
            {!isEmpty(addedExposedRoutesWithControls[route]) ? (
              <StyledSpan>{`${description}: ${addedExposedRoutesWithControls[route].join(
                '; '
              )}`}</StyledSpan>
            ) : (
              ''
            )}
          </HorizontalStack>
        </div>
      ))}
    </StyledVerticalStack>
  </VerticalStack>
);

export const TimelineApiGateway = ({
  isLast,
  isFirst,
  index,
  apiGatewayTimelineEvent,
  previousTimelineEvent,
}) => {
  const title = `API Gateway ${apiGatewayTimelineEvent.apisGroupModificationInfo.apiGatewayServerUrl} Configuration Changed`;

  return (
    <TimelineEvent
      currentTimelineEvent={apiGatewayTimelineEvent}
      previousTimelineEvent={previousTimelineEvent}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      titleContent={title}
      body={materialChangesBody(
        apiGatewayTimelineEvent.materialChangesLabels,
        apiGatewayTimelineEvent.apisGroupModificationInfo
      )}
      exploreUrl={apiGatewayTimelineEvent.apisGroupModificationInfo.apiGatewayServerUrl}
      testMarker="timeline-api"
    />
  );
};
