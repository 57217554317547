import { Paragraph, Strong } from '@src-v2/components/typography';
import { CommitCodeReference } from '@src-v2/containers/commit/common-componnets';

export const ManagedSemgrepMaterialChange = ({
  isAdded,
  commitSha,
  repository,
  lineNumber,
  filePath,
  entityDisplayName,
}) => (
  <>
    <Paragraph>
      Managed Semgrep detected {isAdded ? 'a new' : 'a resolved'} code finding at path
    </Paragraph>
    <CommitCodeReference
      repository={repository}
      commitSha={commitSha}
      lineNumber={lineNumber}
      relativeFilePath={filePath}>
      <Strong>{entityDisplayName}</Strong>
    </CommitCodeReference>
  </>
);
