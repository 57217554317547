import styled from 'styled-components';

export const CircleMeter = ({
  strokeWidth = 4,
  percentile = 0,
  fraction = 0,
  color = '#999',
  size = 40,
  children,
  ...props
}) => {
  const center = size / 2;
  const radius = center - strokeWidth / 2;
  const circumference = 2 * Math.PI * radius;
  return (
    <svg {...props} width={size} height={size} viewBox={`0 0 ${size} ${size}`}>
      <g transform={`rotate(-90 ${center} ${center})`}>
        <Circle
          cx={center}
          cy={center}
          r={radius}
          strokeWidth={strokeWidth}
          stroke="var(--color-blue-gray-20)"
        />
        <Circle
          cx={center}
          cy={center}
          r={radius}
          stroke={color}
          strokeWidth={strokeWidth}
          strokeDasharray={circumference}
          strokeDashoffset={circumference * (1 - percentile)}
        />
      </g>
      <foreignObject
        x={strokeWidth}
        y={strokeWidth}
        width={size - strokeWidth * 2}
        height={size - strokeWidth * 2}>
        <CircleMeter.Content>
          {children ?? `${(percentile * 100).toFixed(fraction)}%`}
        </CircleMeter.Content>
      </foreignObject>
    </svg>
  );
};

const Circle = styled.circle`
  fill: transparent;
`;

CircleMeter.Content = styled.div`
  display: flex;
  height: 100%;
  font-size: 12px;
  font-weight: 600;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
`;
