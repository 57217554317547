import _ from 'lodash';
import { useCallback } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { ProcessTagFormEditor } from '@src-v2/containers/process-tags/process-tag-form-editor';
import { useInject, useSuspense } from '@src-v2/hooks';

export const ProcessTagEditModal = props => {
  const { key } = useParams();
  const history = useHistory();

  const { processes } = useInject();
  const processTagsList = useSuspense(processes.listProcessTags);

  const defaultValues = key ? _.find(processTagsList, { key }) : {};

  const handleClose = useCallback(
    () => history.push('/settings/access-permissions/process-tags'),
    [history]
  );

  const handleSuccess = useCallback(async tag => {
    await processes.upsertProcessTag(tag);
    handleClose();
  }, []);

  return !key || defaultValues ? (
    <ConfirmationModal
      {...props}
      title={defaultValues.key ? `Edit ${defaultValues.name}` : 'Create New Process'}
      submitText="Save"
      defaultValues={defaultValues}
      onSubmit={handleSuccess}
      onClose={handleClose}>
      <ProcessTagFormEditor />
    </ConfirmationModal>
  ) : (
    <Redirect to="/settings/access-permissions/process-tags" />
  );
};
