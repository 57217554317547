import { connect } from 'react-redux';
import { select } from '@src/store';
import RepositoryPage from './view';

const mapState = select(
  ({ repositoryPage: { repositoryProfile, repositoryKey, hasFetchedData, moduleKey } }) => ({
    repositoryProfile,
    repositoryKey,
    hasFetchedData,
    moduleKey,
  })
);

const mapDispatch = ({ repositoryPage: { fetchData, clearData } }) => ({
  fetchData,
  clearData,
});

export default connect(mapState, mapDispatch)(RepositoryPage);
