import keyBy from 'lodash/keyBy';
import ioc from '@src-v2/ioc';

export default {
  state: {},
  reducers: {
    setRules: (state, rules) => ({
      ...state,
      rulesByKey: keyBy(rules, rule => rule.key),
    }),
    setOptions: (state, options) => ({
      ...state,
      options,
    }),
  },
  selectors: slice => ({
    rulesByKey: () => slice(models => models.rulesByKey),
    options: () => slice(models => models.options),
  }),
  effects: () => ({
    async fetchRules(invalidateCache) {
      if (invalidateCache) {
        ioc.asyncCache.invalidate(ioc.governance.getRules);
      }

      const rules = await ioc.asyncCache.suspend(ioc.governance.getRules);
      this.setRules(rules);
    },
    async fetchOptions(invalidateCache) {
      if (invalidateCache) {
        ioc.asyncCache.invalidate(ioc.governance.getRuleOptions);
      }
      const options = await ioc.asyncCache.suspend(ioc.governance.getRuleOptions);
      this.setOptions(options);
      return options;
    },
    async fetchData({ invalidateCache = false } = {}) {
      await Promise.all([this.fetchRules(invalidateCache), this.fetchOptions(invalidateCache)]);
    },
  }),
};
