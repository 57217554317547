import styled from 'styled-components';
import { Link } from '@src/components/Link';
import { FontBodyBold, FontBodySpan } from '@src/style/common';
import DetailsPaneHeader from './DetailsPaneHeader';
import { getUrl } from './TriggerDisplayUtils';

const EmptyStateBox = styled.div`
  height: 480px;
  display: flex;
  text-align: center;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  background-color: var(--color-white);
`;

const StyledComingSoon = styled.span`
  ${FontBodyBold};
`;

const EmptyState = ({ ruleTriggers, urlPrefix }) => {
  const url = getUrl(ruleTriggers, urlPrefix);
  return (
    <>
      <DetailsPaneHeader ruleTriggers={ruleTriggers} />
      <EmptyStateBox>
        <StyledComingSoon>Coming soon...</StyledComingSoon>
        {url && (
          <FontBodySpan>
            Meanwhile, you can view details in the{' '}
            <Link url={url} underline>
              inventory
            </Link>{' '}
          </FontBodySpan>
        )}
      </EmptyStateBox>
    </>
  );
};

export default EmptyState;
