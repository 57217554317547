export default {
  state: {},
  reducers: {
    setDisableRefreshOnPushNotification: state => ({
      ...state,
      disableRefreshOnPushNotification: true,
    }),
    unsetDisableRefreshOnPushNotification: state => ({
      ...state,
      disableRefreshOnPushNotification: false,
    }),
  },
  effects: () => ({
    disableNotifications() {
      this.setDisableRefreshOnPushNotification();
    },
    enableNotifications() {
      this.unsetDisableRefreshOnPushNotification();
    },
  }),
};
