import { observer } from 'mobx-react';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { FiltersMenu } from '@src-v2/components/filters/menu-control/filters-menu';
import { SearchFilterInput } from '@src-v2/components/forms/search-input';
import { Gutters } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { SplitScreen } from '@src-v2/components/layout/split-screen';
import { Heading, Paragraph } from '@src-v2/components/typography';
import { DependenciesTable } from '@src-v2/containers/spacetime-graph/dependencies-table';
import { SpacetimeGraph } from '@src-v2/containers/spacetime-graph/spacetime-graph';
import { Legend } from '@src-v2/containers/spacetime-graph/spacetime-graph-legend';
import { ZoomControls } from '@src-v2/containers/spacetime-graph/zoom-controls';
import { useDimensionsObserver, useInject, useSuspense, useToggle } from '@src-v2/hooks';
import { useFilters } from '@src-v2/hooks/use-filters';
import { usePanAndZoom } from '@src-v2/hooks/use-pan-and-zoom';
import { BetaBadge } from '@src/components/BetaBadge';

export default observer(() => {
  const { graph } = useInject();
  const {
    params: { profileType, key },
  } = useRouteMatch('/profiles/:profileType/:key');

  const [asideOpen, toggleAside] = useToggle(false);
  const { activeFilters, updateFilters } = useFilters();
  const [currentDependencies, setCurrentDependencies] = useState(null);
  const [currentRepository, setCurrentRepository] = useState(null);
  const [filteredDependencies, setFilteredDependencies] = useState(currentDependencies);
  const [dependenciesFilters, setDependenciesFilters] = useState({
    searchTerm: '',
    hasFindings: false,
  });

  const graphData = useSuspense(
    profileType === 'applications' ? graph.getApplicationGraph : graph.getRepositoryGraph,
    { key }
  );

  const [usedData, setUsedData] = useState(graphData);

  const mainRef = useRef();
  const { width, height } = useDimensionsObserver(mainRef);
  const [graphProps, modifyScale] = usePanAndZoom({
    x: width / 2,
    y: height / 2,
    minScale: 0.05,
    maxScale: 5,
  });

  const handleDependencyFilterChange = useCallback(() => {
    let filtered = currentDependencies?.filter(dependency =>
      String(dependency.name).toLowerCase().includes(dependenciesFilters.searchTerm.toLowerCase())
    );
    filtered = dependenciesFilters.hasFindings
      ? filtered.filter(item => item.dependencyFindings.length > 0)
      : filtered;

    return setFilteredDependencies(filtered);
  }, [currentDependencies, dependenciesFilters, setFilteredDependencies]);

  useEffect(() => {
    const { RiskFactors: riskFactors = [] } = activeFilters;
    setUsedData(
      riskFactors.length
        ? graphData.filter(node =>
            riskFactors.some(filterName => clientFilters[filterName]?.(node, graphData))
          )
        : graphData
    );
  }, [graphData, activeFilters?.RiskFactors]);

  useEffect(() => {
    const searchTerm = activeFilters.searchTerm?.toLowerCase().trim();
    usedData.setHighlights(node =>
      searchTerm ? node.name.toLowerCase().includes(searchTerm) : false
    );
  }, [usedData, activeFilters?.searchTerm]);

  useEffect(() => {
    handleDependencyFilterChange();
  }, [dependenciesFilters]);

  const [handleIncrease, handleDecrease] = useMemo(
    () => [() => modifyScale(1.25), () => modifyScale(0.8)],
    [modifyScale]
  );

  return (
    <Page title="Application Graph">
      <SplitContainer asideOpen={asideOpen}>
        <SplitScreen.Main ref={mainRef}>
          <ActionsContainer>
            <Gutters>
              <Actions onMouseDown={stopPropagation}>
                <ZoomControls onIncrease={handleIncrease} onDecrease={handleDecrease} />
                <SearchFilterInput placeholder="Search" defaultValue={activeFilters?.searchTerm} />
                <FiltersMenu
                  data={filtersMenu}
                  activeValues={activeFilters}
                  onChange={updateFilters}
                  optionsLimit={10}
                />
              </Actions>
            </Gutters>
          </ActionsContainer>
          <Legend graphData={graphData} />
          <SpacetimeGraph
            {...graphProps}
            data={usedData}
            width={width}
            height={height}
            onNodeClick={useCallback(
              ({ node, repository }) => {
                if (!node.expanded) {
                  setCurrentRepository(repository);
                  setFilteredDependencies(node.dependencyDeclarations);
                  setCurrentDependencies(node.dependencyDeclarations);
                  toggleAside(true);
                }
              },
              [setCurrentRepository, setFilteredDependencies, setCurrentDependencies, toggleAside]
            )}
          />
        </SplitScreen.Main>
        <SplitScreen.Aside
          onToggle={() => {
            setCurrentDependencies(null);
            setFilteredDependencies(null);
            toggleAside();
          }}>
          <PaneHeader>
            <Heading>
              Application Graph <BetaBadge featureName="Application Graph" />
            </Heading>
          </PaneHeader>
          {!currentDependencies ? (
            <PaneContent>
              <Heading>Continuous Threat Model</Heading>
              <Paragraph>
                The Application Graph allows developers and security engineers to conduct a
                continuous Threat Model without investing manual labor in visualizing the
                application architecture across all the code changes.
              </Paragraph>
            </PaneContent>
          ) : (
            <DependenciesTable
              currentRepository={currentRepository}
              dependenciesFilters={dependenciesFilters}
              filteredDependencies={filteredDependencies}
              setDependenciesFilters={setDependenciesFilters}
            />
          )}
        </SplitScreen.Aside>
      </SplitContainer>
    </Page>
  );
});

const filtersMenu = [
  {
    key: 'RiskFactors',
    type: 'checkbox',
    title: 'Risk Factors',
    options: [
      { title: 'API exposes PII', value: 'API exposes PII' },
      { title: 'API exposes PHI', value: 'API exposes PHI' },
      { title: 'API exposes PCI', value: 'API exposes PCI' },
    ],
  },
];

const clientFilters = {
  'API exposes PII': (node, graph) =>
    node.type === 'SensitiveData' &&
    node.data?.IsPii &&
    graph
      .getNodeEdges(node)
      .some(
        edge =>
          graph.getNodeById(edge.source === node.id ? edge.target : edge.source).type === 'Api'
      ),
  'API exposes PHI': (node, graph) =>
    node.type === 'SensitiveData' &&
    node.data?.IsPhi &&
    graph
      .getNodeEdges(node)
      .some(
        edge =>
          graph.getNodeById(edge.source === node.id ? edge.target : edge.source).type === 'Api'
      ),
  'API exposes PCI': (node, graph) =>
    node.type === 'SensitiveData' &&
    node.data?.IsPayment &&
    graph
      .getNodeEdges(node)
      .some(
        edge =>
          graph.getNodeById(edge.source === node.id ? edge.target : edge.source).type === 'Api'
      ),
};

const SplitContainer = styled(SplitScreen)`
  height: calc(100vh - 35rem);
`;

const ActionsContainer = styled.div`
  position: absolute;
  top: 5rem;
  width: 100%;
  z-index: 10;
`;

const Actions = styled.div`
  display: flex;
  gap: 4rem;

  ${ZoomControls} {
    flex: 0 0 auto;
  }

  ${FiltersMenu.Button} {
    flex: 0 0 auto;
    border: 0.25rem solid var(--color-blue-gray-40);
    background-color: var(--color-blue-15);

    &:hover {
      color: var(--color-blue-gray-65);
      border-color: var(--color-blue-gray-65);
      background-color: var(--color-blue-25);
    }
  }
`;

const PaneHeader = styled.header`
  height: 20rem;
  padding: 5rem;
  line-height: 1;
  background-color: var(--color-blue-gray-15);

  ${Heading} {
    display: flex;
    align-items: center;
    font-size: var(--font-size-xl);
    gap: 2rem;
  }

  ${Paragraph} {
    color: var(--color-blue-gray-50);
    font-size: var(--font-size-xs);
  }
`;

const PaneContent = styled.div`
  padding: 5rem;

  ${Heading} {
    margin-bottom: 1rem;
    color: var(--color-blue-gray-50);
    font-size: var(--font-size-m);
    font-weight: 500;

    &:not(:first-child) {
      margin-top: 5rem;
    }
  }
`;

function stopPropagation(event) {
  event.stopPropagation();
}
