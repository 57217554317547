import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';

export const InternalFrameworkDefinition = ({ definition }) => (
  <HorizontalStack withSeparator key={definition.key}>
    <span>{definition.frameworkGroup}</span>
    <span>{definition.frameworkDescription}</span>
    <PopoverTip
      title="Dependencies"
      linkText={
        <span>
          Includes{' '}
          <Number value={definition.dependencies.length} one="dependency" other="dependencies" />
        </span>
      }>
      {definition.dependencies.map(dependency => (
        <div key={dependency}>{dependency}</div>
      ))}
    </PopoverTip>
  </HorizontalStack>
);
