import * as d3 from 'd3';
import uniq from 'lodash/uniq';
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import * as vendorIcons from '@src-v2/assets/vendors';
import { UnderlineButton } from '@src-v2/components/buttons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Paragraph, Strong } from '@src-v2/components/typography';
import { useResizeObserver } from '@src-v2/hooks';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { addInterpunctSeparator } from '@src-v2/utils/string-utils';

export const elementTypeToProperties = {
  API: { displayName: 'API', spreadOrder: 50 },
  PII: { displayName: 'PII Field', spreadOrder: 25 },
  MongoDB: { displayName: 'Mongo DB', spreadOrder: 0 },
  Nginx: { displayName: 'NGINX', spreadOrder: 100 },

  Databases: { color: '#F3ECFE', spreadOrder: 0 },
  Storage: { color: '#E6D8FD', spreadOrder: 0 },
  'Data streams': { color: '#D1C5FC', spreadOrder: 20 },
  Compute: { color: '#B29EFA', spreadOrder: 40 },
  'Containers orchestration': { color: '#A977F8', spreadOrder: 40 },
  'Application management': { color: '#9377F8', spreadOrder: 40 },
  Serverless: { color: '#7451F6', spreadOrder: 40 },
  'Cloud communication': { color: '#562AF4', spreadOrder: 80 },
  'Network security': { color: '#670CE8', spreadOrder: 100 },
  'Network structure': { color: '#570AC2', spreadOrder: 100 },
  'API gateway': { color: '#420795', spreadOrder: 100 },
  'Key management system': { color: '#573882', spreadOrder: 150 },
  IAM: { color: '#20044E', spreadOrder: 150 },
  'Artifacts registries': { color: '#170333', spreadOrder: 150 },
  Other: { color: '#F4F4F4', spreadOrder: 500 },
};

const getDimensions = element => {
  const style = getComputedStyle(element);
  const width = parseInt(style.getPropertyValue('width'));
  const height = parseInt(style.getPropertyValue('height'));
  const minWidth = parseInt(style.getPropertyValue('min-width'));
  const minHeight = parseInt(style.getPropertyValue('min-height'));
  return { width, height, minWidth, minHeight };
};

const resize = container => {
  const { width, height, minWidth, minHeight } = getDimensions(container.node());

  container
    .select('svg')
    .attr('width', width)
    .attr('height', height)
    .attr('viewBox', `0 0 ${minWidth || width} ${minHeight || height}`)
    .attr('perserveAspectRatio', 'xMinYMid');
};

const appendElement = (selector, nodeType) => {
  if (vendorIcons[nodeType]) {
    selector
      .append('g')
      .style('transform', 'translate(-12px,-12px)')
      .append('svg:image')
      .attr('xlink:href', vendorIcons[nodeType]);
  } else if (nodeType === 'API') {
    selector
      .append('circle')
      .attr('r', 8)
      .attr('stroke', 'var(--color-blue-gray-65)')
      .attr('fill', 'white');

    selector
      .append('circle')
      .attr('r', 3)
      .attr('fill', d => (d?.tags?.includes('Sensitive') ? 'red' : 'var(--color-blue-gray-65)'));
  } else {
    selector
      .append('circle')
      .attr('r', 10)
      .attr('height', 14)
      .attr('stroke', 'var(--color-blue-gray-65)')
      .attr('fill', elementTypeToProperties[nodeType]?.color ?? 'white');

    selector
      .filter(d => d?.violating)
      .append('circle')
      .attr('r', 13)
      .attr('height', 14)
      .attr('stroke', '#ff0a37')
      .attr('stroke-width', 2)
      .attr('fill', 'none');
  }
};

const appendText = (selector, text) =>
  selector
    .append('text')
    .attr('x', 20)
    .text(text)
    .style('font-size', '16px')
    .attr('alignment-baseline', 'middle');

const appendLegend = (selector, nodeTypes) => {
  let legend = selector.append('g').attr('transform', 'translate(100, 30)');
  nodeTypes.forEach(nodeType => {
    appendElement(legend, nodeType);
    appendText(legend, elementTypeToProperties[nodeType]?.displayName ?? nodeType);
    legend = legend.append('g').attr('transform', 'translate(0, 40)');
  });
};

export const ArchitectureGraph = ({
  nodes,
  edges,
  showLegend,
  enableTooltipAction,
  onNodeClick,
  onTooltipClick,
  className,
}) => {
  const [tooltipOptions, setTooltipOptions] = useState({});
  const idRef = useRef(null);

  useResizeObserver(idRef, () => resize(d3.select(idRef.current)));

  useEffect(() => {
    const refCurrent = idRef.current;
    const container = d3.select(idRef.current);
    const { width, height, minWidth, minHeight } = getDimensions(idRef.current);
    const radius = Math.min(width, height) / 2.15;
    const nodeKeys = nodes.reduce((obj, node) => {
      obj[node.id] = true;
      return obj;
    }, {});

    const edgesWithNodes = edges.filter(e => nodeKeys[e.source] && nodeKeys[e.target]);
    const nodeTypes = uniq(nodes.map(node => node.type)).sort();

    nodes.forEach(node => {
      node.spreadOrder = node.spreadOrder || elementTypeToProperties[node.type]?.spreadOrder || 0;
    });

    const spreadOrders = uniq(nodes.map(node => node.spreadOrder)).sort();
    const maxSpreadOrder = spreadOrders.length - 1;
    const spreadOrderMapping = spreadOrders.reduce((obj, spreadOrder, index) => {
      obj[spreadOrder] = index;
      return obj;
    }, {});

    const svg = container.append('svg').call(() => resize(container));

    if (showLegend) {
      appendLegend(svg, nodeTypes);
    }

    const linkData = svg
      .append('g')
      .attr('class', 'edges')
      .selectAll('line')
      .data(edgesWithNodes)
      .enter()
      .append('line')
      .style('stroke', 'var(--color-blue-gray-45)');

    let nodeData = svg
      .append('g')
      .attr('class', 'nodes')
      .style('cursor', 'pointer')
      .selectAll('g')
      .data(nodes)
      .enter()
      .append('g')
      .attr('type', d => d.type)
      .on('mouseover', (event, item) => {
        const { currentTarget: triggerTarget } = event;
        setTooltipOptions({
          triggerTarget,
          getReferenceClientRect: () => triggerTarget.getClientRects()[0],
          interactive: true,
          content: (
            <>
              <Paragraph>
                {addInterpunctSeparator(
                  item.type,
                  item.violating && pluralFormat(item.violating, 'misconfiguration', null, true)
                )}
              </Paragraph>
              {enableTooltipAction?.(item) ? (
                <TooltipUnderlineButton onClick={event => onTooltipClick(event, item)}>
                  <Strong>{item.name || item.id}</Strong>
                </TooltipUnderlineButton>
              ) : (
                <Strong>{item.name || item.id}</Strong>
              )}
            </>
          ),
        });
      });

    if (onNodeClick) {
      nodeData = nodeData.on('click', onNodeClick);
    }

    nodeTypes.forEach(nodeType =>
      appendElement(
        nodeData.filter(d => d.type === nodeType),
        nodeType
      )
    );

    d3.forceSimulation(nodes)
      .force(
        'link',
        d3
          .forceLink()
          .id(d => d.id)
          .links(edgesWithNodes)
          .strength(0)
      )
      .force('collision', d3.forceCollide().radius(30))
      .force('charge', d3.forceManyBody().strength(-10))
      .force('center', d3.forceCenter((minWidth || width) / 2, (minHeight || height) / 2))
      .force(
        'r',
        d3.forceRadial(
          d => (maxSpreadOrder ? (spreadOrderMapping[d.spreadOrder] * radius) / maxSpreadOrder : 0),
          width / 2,
          height / 2
        )
      )
      .on('tick', () => {
        linkData
          .attr('x1', d => d.source.x)
          .attr('y1', d => d.source.y)
          .attr('x2', d => d.target.x)
          .attr('y2', d => d.target.y);

        nodeData.attr('transform', d => `translate(${d.x},${d.y})`);
      });

    return () => refCurrent?.removeChild(refCurrent.querySelector('svg'));
  }, [nodes, edges, showLegend, onNodeClick]);

  return (
    <div ref={idRef} className={className}>
      <Tooltip {...tooltipOptions} popperOptions={{ strategy: 'fixed' }} appendTo={document.body} />
    </div>
  );
};

const TooltipUnderlineButton = styled(UnderlineButton)`
  &,
  &:hover {
    color: var(--color-white);
  }
`;
