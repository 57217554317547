import styled from 'styled-components';
import { ActivityIndicator } from '@src-v2/components/activity-indicator';
import { Paragraph, Strong } from '@src-v2/components/typography';

export const RepositoryInfoTooltip = styled(({ item, ...props }) => (
  <div {...props}>
    <Paragraph>
      Connection: <Strong>{item.serverUrl}</Strong>
    </Paragraph>
    {item.projectId && item.server?.provider !== 'Github' && item.provider !== 'Github' && (
      <Paragraph>
        Repository group: <Strong>{item.projectId}</Strong>
      </Paragraph>
    )}
    <Paragraph>
      Repository: <Strong>{item.name ?? item.displayName}</Strong>
      <ActivityIndicator active={item.isMonitored} />
    </Paragraph>
    <Paragraph>
      Branch: <Strong>{item.referenceName ?? item.defaultBranch}</Strong>
    </Paragraph>
  </div>
))`
  ${Paragraph} {
    display: flex;
    align-items: center;
    padding: 0.5rem;
    font-weight: 300;
    margin-bottom: 0;
    gap: 1rem;

    ${Strong} {
      font-weight: 500;
    }

    ${ActivityIndicator} {
      height: 1rem;
      width: 1rem;
      border: none;
      margin-top: 0.5rem;
    }
  }
`;
