import styled from 'styled-components';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';
import { LegacyPaneStylingContainer } from '@src/blocks/RiskPosture/legacy-pane-styling-container';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import Bold from '@src/components/Bold';
import { Highlights } from '@src/components/CodeAggregation/Insights/Highlights';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import {
  CodeFindingDescription,
  CustomVerticalStack,
} from '@src/components/CodeFindingDescription';
import { ElementPane } from '@src/components/ElementPane';
import { Link } from '@src/components/Link';
import { StyledItem } from '@src/components/TitledList';
import TooltippedEllipsis from '@src/components/TooltippedEllipsis';
import { dispatch } from '@src/store';

const getHighlights = finding => [
  <TooltippedEllipsis
    text={finding.filePath ? `${finding.filePath}:${finding.lineNumber}` : finding.modulePath}
    tip={finding.filePath ? `${finding.filePath}:${finding.lineNumber}` : finding.modulePath}
  />,
  <span>
    {finding.provider} found {finding.type}
  </span>,
  <>
    {finding.reportUrl && (
      <span>
        <CustomLink url={finding.reportUrl} external>
          View report in {finding.provider}
        </CustomLink>
      </span>
    )}
  </>,
  <>
    {finding.url && (
      <span>
        <CustomLink url={finding.url} external>
          View finding in {finding.provider}
        </CustomLink>
      </span>
    )}
  </>,
];

const getInsightsBody = (finding, highlights, repository) => (
  <>
    <Highlights highlights={highlights} />
    <CodeFindingDescription finding={finding} repository={repository} />
  </>
);

const getFindingPane = ({
  trigger,
  finding,
  repository,
  profile,
  profileType,
  codeReference,
  ruleTriggers,
  messageContent,
}) => {
  const highlights = getHighlights(finding);

  return (
    <ElementPane
      element={finding}
      repository={repository}
      title={
        <InsightsPaneTitle
          trigger={trigger}
          element={finding}
          title={finding.filePath ?? finding.modulePath}
          codeReference={codeReference}
          repository={repository}
          profile={profile}
          profileType={profileType}
          ruleTriggers={ruleTriggers}
          messageContent={messageContent}
        />
      }
      profileBody={getInsightsBody(finding, highlights, repository)}
    />
  );
};

export function LegacyFindingPane({ finding, repository }) {
  return (
    <FindingStylingContainer>
      {getInsightsBody(finding, getHighlights(finding), repository)}
    </FindingStylingContainer>
  );
}

const FindingStylingContainer = styled(LegacyPaneStylingContainer)`
  ${CustomVerticalStack} {
    margin-top: 4rem;

    ${Bold} {
      font-weight: 300;
      text-decoration: underline;
    }
  }

  ${StyledItem} {
    white-space: break-spaces;
    font-size: var(--font-size-m);
    line-height: 6rem;
    font-weight: 700;
  }
`;
export const openFindingPaneWithRiskActions = ({
  ruleKey,
  profile,
  trigger,
  profileType,
  onClose,
  relevantPath,
  messageContent,
  externalRiskTriggerPane,
  level = secondaryPaneLevel,
}) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  dispatch.pane.openPane({
    onClose,
    relevantPath,
    id: trigger.key,
    level,
    content: (
      <RiskRuleTrigger
        trigger={trigger}
        profile={profile}
        externalRiskTriggerPane={externalRiskTriggerPane}
        ruleKey={ruleKey}
        getPane={({ element, ruleTriggers }) =>
          getFindingPane({
            trigger,
            finding: element,
            repository,
            profile,
            profileType,
            codeReference: element.codeReference,
            ruleTriggers,
            messageContent,
          })
        }
      />
    ),
  });
};

const CustomLink = styled(Link)`
  color: var(--color-blue-65);

  &:hover {
    text-decoration: underline;
    color: var(--color-blue-70);
  }
`;
