import _ from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { components as ReactSelectComponents } from 'react-select';
import AsyncSelect from 'react-select/async';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { SvgIcon } from '@src-v2/components/icons';
import {
  ReactSelectThemeLightClass,
  ReactSelectThemeLightStyle,
} from './ReactSelect/ReactSelectThemeLight';

const Loading = styled(LogoSpinner)`
  height: 7rem;
`;

const StyledPlaceholder = styled.span`
  font-weight: 300;
  color: var(--color-blue-gray-45);
`;

const SpecialGroup = styled.div`
  border-bottom: 0.25rem solid var(--color-blue-gray-35);
`;

const StyledAsyncSelect = styled(AsyncSelect)`
  ${ReactSelectThemeLightStyle};
`;

const DropdownIndicator = styled(SvgIcon)`
  transform: rotate(90deg);
  color: var(--color-blue-gray-50);
  cursor: pointer;
`;

const isSpecialOption = (allOption, specialOptions, newOption) =>
  (!_.isNil(allOption) && allOption === newOption?.value) ||
  (!_.isNil(specialOptions) && specialOptions.some(option => option.value === newOption?.value));

const getInitialSelected = (selectedItem, allOption, specialOptions, name, itemToOption) => {
  let option;
  if (_.isEmpty(selectedItem)) {
    return _.isNil(allOption) ? null : getAllOption(allOption, name);
  }
  if (allOption === selectedItem) {
    return getAllOption(allOption, name);
  }
  if (!_.isNil((option = specialOptions?.find(option => option.value === selectedItem)))) {
    return option;
  }
  return _.isArray(selectedItem) ? selectedItem.map(itemToOption) : itemToOption(selectedItem);
};

const createOption = (WrappingElement, createItem, props) => (
  <WrappingElement {...props}>{createItem(props)}</WrappingElement>
);

const getAllOption = (allOption, name) => ({
  label: `All ${_.upperFirst(name)}`,
  value: allOption,
});

export const Selector = ({
  isMulti,
  onChange,
  onChangeTransformer,
  allOption,
  specialOptions,
  initialSelectedItem,
  searchFunc,
  createItem,
  itemToOption,
  name,
  components,
  ...props
}) => {
  const [selectedItem, setSelectedItem] = useState(
    getInitialSelected(initialSelectedItem, allOption, specialOptions, name, itemToOption)
  );

  const [remount, setRemount] = useState(false);

  const onSelectionChange = useCallback(
    newValue => {
      setSelectedItem(newValue);
      onChange(
        _.isArray(newValue)
          ? newValue.map(option => {
              if (!_.isNil(onChangeTransformer)) {
                return onChangeTransformer(option);
              }
              return option.value;
            })
          : newValue
      );
    },
    [onChange]
  );

  // Force the select box to reload data when the search function changes, otherwise it will show old results
  useEffect(() => setRemount(true), [searchFunc]);
  useEffect(() => {
    if (remount) {
      setRemount(false);
    }
  }, [remount]);

  if (remount) {
    return null;
  }

  return (
    <StyledAsyncSelect
      classNamePrefix={ReactSelectThemeLightClass}
      placeholder={<StyledPlaceholder>Select {_.upperFirst(name)}</StyledPlaceholder>}
      defaultOptions
      isMulti={!isSpecialOption(allOption, specialOptions, selectedItem) && isMulti}
      onChange={(selectedOption, { option: newOption, action }) => {
        if (
          action !== 'remove-value' &&
          _.isNil(newOption) &&
          selectedOption?.value === selectedItem?.value
        ) {
          return;
        }

        newOption ??= selectedOption;

        if (isSpecialOption(allOption, specialOptions, newOption) || _.isNil(selectedOption)) {
          onSelectionChange(newOption ?? getAllOption(allOption, name));
          return;
        }

        if (isMulti) {
          if (!_.isArray(selectedOption)) {
            selectedOption = [selectedOption];
          } else {
            selectedOption = selectedOption?.map(option => option);
          }
        }
        onSelectionChange(selectedOption);
      }}
      loadOptions={async inputValue => {
        const options = [];
        let specialOptionsArray = [];
        if (!_.isNil(allOption)) {
          specialOptionsArray.push(getAllOption(allOption, name));
        }

        if (!_.isNil(specialOptions)) {
          specialOptionsArray = [...specialOptionsArray, ...specialOptions];
        }

        options.push({ label: 'specialOptions', options: specialOptionsArray });
        options.push({
          label: 'options',
          options: _.sortBy(
            (await searchFunc(inputValue)).map(itemToOption),
            option => option.name
          ),
        });

        return options;
      }}
      value={selectedItem}
      components={{
        LoadingIndicator: () => <Loading />,
        IndicatorSeparator: () => null,
        DropdownIndicator: () => <DropdownIndicator name="Chevron" />,
        GroupHeading: () => null,
        Group: componentProps =>
          componentProps.data.label === 'specialOptions' ? (
            <SpecialGroup>
              <ReactSelectComponents.Group {...componentProps} />
            </SpecialGroup>
          ) : (
            <ReactSelectComponents.Group {...componentProps} />
          ),
        Option: componentProps =>
          createOption(ReactSelectComponents.Option, createItem, componentProps),
        SingleValue: componentProps =>
          createOption(ReactSelectComponents.SingleValue, createItem, componentProps),
        MultiValueLabel: componentProps =>
          createOption(ReactSelectComponents.MultiValueLabel, createItem, componentProps),
        ...components,
      }}
      {...props}
    />
  );
};
