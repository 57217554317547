import styled from 'styled-components';
import { Modal } from '@src-v2/components/modals';
import { Heading, Paragraph, Title } from '@src-v2/components/typography';
import { ActionModal } from '@src-v2/containers/modals/action-modal';

export const OverrideModal = styled(ActionModal)`
  width: 90rem;

  ${Modal.Header} ${Heading} {
    font-size: var(--font-size-xxl);
  }

  ${Modal.Content} {
    padding-top: 4rem;

    ${Paragraph} {
      padding: 0 3rem;
      text-align: center;
    }
  }
`;

export const OverrideModalContainer = styled(ActionModal)`
  width: 135rem;

  ${Modal.Header} {
    align-items: flex-start;
  }

  ${Modal.Content} {
    display: flex;
    align-items: center;
  }

  ${Title} {
    font-size: var(--font-size-l);
    font-weight: 500;
    margin-bottom: 1rem;
  }

  ${Paragraph} {
    font-weight: 300;
    font-size: var(--font-size-m);
    text-align: center;
  }
`;

export const OverrideModalTitleContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
  padding: 2rem;
  margin-bottom: 2rem;
  gap: 1rem;

  [data-name='Arrow'] {
    color: var(--color-blue-gray-50);
  }

  [data-name='Risk'] {
    height: 8rem;
    width: 8rem;
  }
`;

export const OverrideModalContentContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  margin-top: 2rem;
  gap: 4rem;
`;
