import { ReportPreviewData } from '@src-v2/containers/pages/reporting/reporting-landing-page/types';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';

export const reportsPreviewData: ReportPreviewData[] = [
  {
    reportName: 'application',
    title: 'Application',
    description:
      'A comprehensive view of the application risk landscape, with insights into open and closed risks.',
    isNew: false,
    thumbnailSrc: '../assets/thumbnails/application.png',
    screenshotSrc: '/images/screenshots/application.png',
  },
  {
    reportName: 'risk',
    title: 'Risk',
    description:
      'A detailed chronicle of risk trends, with an overview that highlights how your risk landscape has evolved.',
    isNew: false,
    thumbnailSrc: '/images/thumbnails/risk.png',
    screenshotSrc: '/images/screenshots/risk.png',
  },
  {
    reportName: 'teams',
    title: 'Teams',
    description:
      'A comprehensive view of the teams risk landscape, with insights into open and closed risks.',
    isNew: true,
    thumbnailSrc: '/images/thumbnails/teams.png',
    screenshotSrc: '/images/screenshots/teams.png',
    featureFlag: FeatureFlag.ReportingTeams,
  },
  {
    reportName: 'sca',
    title: 'SCA',
    description:
      'Comprehensive analysis of vulnerabilities: trends, resolutions, and response efficiency.',
    isNew: true,
    thumbnailSrc: '/images/thumbnails/cve-risk-insights.png',
    screenshotSrc: '/images/screenshots/cve-risk-insights.png',
  },
  {
    reportName: 'secrets',
    title: 'Secrets',
    description:
      'A detailed overview of the secrets management security posture, highlighting key metrics and trends.',
    isNew: true,
    thumbnailSrc: '/images/thumbnails/secrets.png',
    screenshotSrc: '/images/screenshots/secrets.png',
  },
  {
    reportName: 'api',
    title: 'API security',
    description:
      'A detailed overview of the API management security posture, highlighting key metrics and trends.',
    isNew: true,
    thumbnailSrc: '/images/thumbnails/api.png',
    screenshotSrc: '/images/screenshots/api.png',
    featureFlag: FeatureFlag.ReportingAPI,
  },
  {
    reportName: 'sscs',
    title: 'SSCS',
    description:
      'A detailed overview of the SSCS management security posture, highlighting key metrics and trends.',
    isNew: true,
    thumbnailSrc: '/images/thumbnails/sscs.png',
    screenshotSrc: '/images/screenshots/sscs.png',
    featureFlag: FeatureFlag.ReportingSSCS,
  },
  {
    reportName: 'sast',
    title: 'SAST',
    description:
      'A detailed overview of the SAST management security posture, highlighting key metrics and trends.',
    isNew: true,
    thumbnailSrc: '/images/thumbnails/sast.png',
    screenshotSrc: '/images/screenshots/sast.png',
    featureFlag: FeatureFlag.ReportingSAST,
  },
  {
    reportName: 'business',
    title: 'ASPM business outcome',
    description:
      'A concise summary of how increased Apiiro usage boosts efficiency, decreases risk and MTTR, and maintains or enhances development velocity.',
    isNew: true,
    thumbnailSrc: '/images/thumbnails/business.png',
    screenshotSrc: '/images/screenshots/business.png',
    featureFlag: FeatureFlag.ReportingBusiness,
  },
  {
    reportName: 'licensing',
    title: 'Apiiro Licensing',
    description: 'Active developers metrics and trends for licensing transparency.',
    isNew: true,
    thumbnailSrc: '/images/thumbnails/business.png',
    screenshotSrc: '/images/screenshots/business.png',
    featureFlag: FeatureFlag.ReportingLicense,
  },
];
