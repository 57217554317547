import debounce from 'lodash/debounce';

export const defaultWaitTimeMS = 500;

export function createDebouncedAsyncCall(asyncCall, waitTimeMS = defaultWaitTimeMS) {
  const debouncedCall = debounce(async (resolve, reject, ...args) => {
    try {
      resolve(await asyncCall(...args));
    } catch (error) {
      reject(error);
    }
  }, waitTimeMS);

  return (...args) => new Promise((resolve, reject) => debouncedCall(resolve, reject, ...args));
}
