import { css } from 'styled-components';

export default css`
  width: ${props =>
    36 +
    props.value.label?.length * 7 +
    (props.value.provider || props.value.icon || props.value.risk ? 30 : 0) +
    (props.value.tip ? 30 : 0) +
    (props.isMulti && !props.isDisabled ? 30 : 0)}px;

  min-width: 70px;

  && > * {
    min-height: 0;

    > * {
      line-height: 1.4;
      padding: 0;
    }
  }

  && > .select__control {
    background-color: ${props => props.selectBackgroundColor ?? 'var(--color-white)'};
    padding: 0 3rem;
    border: none;
    border-radius: 3rem;
  }

  .select__menu-list {
    border-radius: 4px;
  }

  &.select--is-disabled {
    .select__multi-value__remove {
      display: none;
    }
  }
`;
