import _ from 'lodash';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Dropdown } from '@src-v2/components/dropdown';
import { SvgIcon, VendorIcon } from '@src-v2/components/icons';
import { Paragraph } from '@src-v2/components/typography';
import {
  AzureDevopsBulkModal,
  GithubBulkModal,
  JiraBulkModal,
} from '@src-v2/containers/modals/issues/providers-bulk-issue-modals';
import {
  AzureDevopsModal,
  GithubModal,
  JiraModal,
  LinkExistingJiraTicketModal,
} from '@src-v2/containers/modals/issues/providers-issue-modals';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';

export function TicketingActionItems({ data, setModal, closeModal }) {
  const {
    application: { integrations, isFeatureEnabled },
  } = useInject();

  const {
    handleConnectTicketingSystem,
    handleCreateJiraIssue,
    handleCreateGithubIssue,
    handleCreateAzureDevopsIssue,
    handleLinkToExistingTicket,
  } = useTicketingActionItems(data, setModal, closeModal);

  return (
    <>
      {!integrations.connectedToGithub &&
        !integrations.connectedToJira &&
        !integrations.connectedToAzureDevops && (
          <Dropdown.Item onClick={handleConnectTicketingSystem}>
            <SvgIcon name="Ticketing" />
            Create a Ticket
          </Dropdown.Item>
        )}

      {integrations.connectedToJira && (
        <Dropdown.Item onClick={handleCreateJiraIssue}>
          <VendorIcon name="Jira" />
          Create a Jira Ticket
        </Dropdown.Item>
      )}
      {integrations.connectedToGithub && (
        <Dropdown.Item onClick={handleCreateGithubIssue}>
          <VendorIcon name="Github" />
          Create a GitHub Issue
        </Dropdown.Item>
      )}
      {integrations.connectedToAzureDevops && (
        <Dropdown.Item onClick={handleCreateAzureDevopsIssue}>
          <VendorIcon name="AzureDevops" />
          Create an AzureDevops Work Item
        </Dropdown.Item>
      )}
      {integrations.connectedToJira && isFeatureEnabled(FeatureFlag.Jira2WayIntegration) && (
        <Dropdown.Item onClick={handleLinkToExistingTicket}>
          <SvgIcon name="IconLink" />
          Link existing Jira ticket
        </Dropdown.Item>
      )}
    </>
  );
}

export function useTicketingActionItems(data, setModal, closeModal) {
  const trackAnalytics = useTrackAnalytics();

  const history = useHistory();

  return useMemo(() => {
    const openBulkModal = data.selection?.length > 1;
    const riskData = _.first(data.selection) ?? data;

    return {
      handleConnectTicketingSystem() {
        setModal(
          <ConfirmationModal
            title="Add a Ticketing system connector"
            submitText="Continue"
            onClose={closeModal}
            onSubmit={() => history.push('/connectors/connect#ticketingSystems')}>
            <>
              <Paragraph>
                Opening a ticket requires at least one ticketing system connector.
              </Paragraph>
              <Paragraph>
                Click 'Continue' to view your connectors page and complete this process.
              </Paragraph>
            </>
          </ConfirmationModal>
        );
      },
      handleCreateJiraIssue() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Create Jira ticket',
        });
        setModal(
          openBulkModal ? (
            <JiraBulkModal dataModel={data} onClose={closeModal} />
          ) : (
            <JiraModal riskData={riskData} onClose={closeModal} />
          )
        );
      },
      handleCreateGithubIssue() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Create Github issue',
        });
        setModal(
          openBulkModal ? (
            <GithubBulkModal dataModel={data} onClose={closeModal} />
          ) : (
            <GithubModal riskData={riskData} onClose={closeModal} />
          )
        );
      },
      handleCreateAzureDevopsIssue() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Create AzureDevops work item',
        });
        setModal(
          openBulkModal ? (
            <AzureDevopsBulkModal dataModel={data} onClose={closeModal} />
          ) : (
            <AzureDevopsModal riskData={riskData} onClose={closeModal} />
          )
        );
      },
      handleLinkToExistingTicket() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Link existing Jira ticket',
        });

        setModal(<LinkExistingJiraTicketModal riskData={riskData} onClose={closeModal} />);
      },
    };
  }, [setModal, closeModal, data, trackAnalytics]);
}
