import _ from 'lodash';
import { Collapsible } from '@src-v2/components/collapsible';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Heading, Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';
import { pluralFormat } from '@src-v2/utils/number-utils';

const ExitPointKinds = {
  LOG: {
    enumValue: 'LOG',
    display: 'logs',
    actionDescription: 'Written to logs',
  },
};

export const SensitiveDataExitPointMaterialChange = ({
  materialChange,
  name,
  repository,
  commitSha,
  relativeFilePath,
}) => {
  const exitPoints = _.sortBy(materialChange.exitPoints, ['kind']);
  const exitPointsByMember = _.groupBy(exitPoints, 'member');
  return (
    <>
      <ExitPointsHeader
        exitPointsByMember={exitPointsByMember}
        exitPoints={exitPoints}
        dataOfInterestFamilyType={materialChange.associatedSensitiveDataTypeDescription}
        relativeFilePath={relativeFilePath}
        showName
        name={name}
      />
      <ExitPoints
        exitPointsByMember={exitPointsByMember}
        repository={repository}
        commitSha={commitSha}
      />
    </>
  );
};

const ExitPointsHeader = ({
  exitPoints,
  exitPointsByMember,
  dataOfInterestFamilyType,
  name,
  relativeFilePath,
  showName,
}) => {
  const allUniqueKindDisplays = () => [
    ...new Set(exitPoints.map(exitPoint => ExitPointKinds[exitPoint.kind]?.display)),
  ];
  const exitPointsCount = Object.keys(exitPointsByMember).length;
  return (
    <Heading>
      <Paragraph>
        {pluralFormat(exitPointsCount, `${dataOfInterestFamilyType} field`, null, true)}
      </Paragraph>
      {showName && (
        <Paragraph>
          of data model{' '}
          <Tooltip content={relativeFilePath}>
            <Strong>{name}</Strong>
          </Tooltip>
        </Paragraph>
      )}
      <Paragraph>
        {pluralFormat(exitPointsCount, 'is', 'are')} written to {allUniqueKindDisplays().join(', ')}
      </Paragraph>
    </Heading>
  );
};

const ExitPoints = ({ exitPointsByMember, repository, commitSha }) => (
  <>
    {Object.values(exitPointsByMember).map((exitPoints, member) => (
      <Collapsible
        key={member}
        title={
          <MaterialChangesRow>
            <Paragraph>{member}</Paragraph>
            <Paragraph> {pluralFormat(exitPoints.length, 'Time', null, true)}</Paragraph>
          </MaterialChangesRow>
        }>
        {exitPoints.map((exitPoint, index) => (
          <ExitPointDescription
            exitPoint={exitPoint}
            index={index}
            repository={repository}
            commitSha={commitSha}
          />
        ))}
      </Collapsible>
    ))}
  </>
);

const ExitPointDescription = ({ exitPoint, repository, commitSha }) => {
  const { codeReference } = exitPoint;
  const [classReference, methodName] = codeReference.name.split('#');
  const className = classReference?.split('.').pop() ?? '';

  return (
    <MaterialChangesRow>
      <Paragraph>{`${
        ExitPointKinds[exitPoint.kind]?.actionDescription ?? 'Written'
      } in`}</Paragraph>
      <CommitCodeReference
        underline={false}
        repository={repository}
        commitSha={commitSha}
        relativeFilePath={codeReference.relativeFilePath ?? ''}>
        <Paragraph>{methodName ? `${className}.${methodName}` : className}</Paragraph>
      </CommitCodeReference>
    </MaterialChangesRow>
  );
};
