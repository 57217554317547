import { useCallback } from 'react';
import styled from 'styled-components';
import { UpgradeRequestModal } from '@src-v2/components/marketing/upgrade-request-modal';
import { useToggle } from '@src-v2/hooks';

export const UpgradeButton = styled(({ title, description, onClick, ...props }) => {
  const [isOpen, toggleOpen] = useToggle();

  const handleClick = useCallback(
    event => {
      onClick?.(event);
      toggleOpen();
    },
    [onClick, toggleOpen]
  );

  return (
    <>
      <span {...props} onClick={handleClick} />
      {isOpen && (
        <UpgradeRequestModal title={title} description={description} onClose={toggleOpen} />
      )}
    </>
  );
})`
  padding: 2rem 4rem;
  color: var(--color-purple-60);
  font-size: var(--font-size-l);
  font-weight: 600;
  background-color: var(--color-purple-10);
  border-radius: 1rem;
  cursor: pointer;
`;

export const UpgradeButtonV2 = styled(UpgradeButton)`
  padding: 1rem 3rem;
  color: var(--color-white);
  font-size: var(--font-size-xs);
  align-self: center;
  background-color: var(--color-purple-50);
  border-radius: 100vmax;

  &:hover {
    background-color: var(--color-purple-60);
  }
`;
