import _ from 'lodash';
import filtersService from '@src/services/filtersService';

export const getIssuesFilterOptions = ({ elements }) => {
  const labels = _.uniq(_.map(elements, 'labels').flat());
  const components = _.uniq(_.map(elements, 'components').flat());
  const processTags = _.uniqBy(_.map(elements, 'processTags').flat(), 'key');
  const types = _.uniq(_.map(elements, 'type').flat());

  return [
    {
      name: 'ProcessTags',
      sortOrder: 1,
      displayName: 'Process',
      filterOptions: processTags.map(processTag => ({
        name: processTag.key,
        displayName: `${processTag.name} (${processTag.processName})`,
      })),
    },
    {
      name: 'IssueLabels',
      sortOrder: 2,
      displayName: 'Labels',
      filterOptions: filtersService.getListFilterOptions(labels),
    },
    {
      name: 'IssueComponents',
      sortOrder: 3,
      displayName: 'Components',
      filterOptions: filtersService.getListFilterOptions(components),
    },
    {
      name: 'IssueTypes',
      sortOrder: 4,
      displayName: 'Types',
      filterOptions: filtersService.getListFilterOptions(types),
    },
  ];
};

export const filterIssues = (allItems, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, item => [item.title]);

  const labelFilters = filters.listFilters?.IssueLabels;
  if (!_.isEmpty(labelFilters)) {
    filtered = filtered.filter(issue =>
      filterOperator(labelFilters.map(label => _.includes(issue.labels, label)))
    );
  }

  const componentFilters = filters.listFilters?.IssueComponents;
  if (!_.isEmpty(componentFilters)) {
    filtered = filtered.filter(issue =>
      filterOperator(componentFilters.map(component => _.includes(issue.components, component)))
    );
  }

  const processTagsFilters = filters.listFilters?.ProcessTags;
  if (!_.isEmpty(processTagsFilters)) {
    filtered = filtered.filter(issue =>
      filterOperator(
        processTagsFilters.map(processTag =>
          _.some(issue.processTags, tag => tag.key === processTag.key)
        )
      )
    );
  }

  const issueTypesFilters = filters.listFilters?.IssueTypes;
  if (!_.isEmpty(issueTypesFilters)) {
    filtered = filtered.filter(issue =>
      filterOperator(issueTypesFilters.map(type => issue.type === type))
    );
  }

  return filtered;
};
