import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { HighlightsCard } from '@src-v2/containers/overview/highlights-card';
import { HighlightsContent } from '@src-v2/containers/overview/highlights-content';
import { useInject, useSuspense } from '@src-v2/hooks';
import { makeUrl } from '@src-v2/utils/history-utils';

export const OrganizationAttackSurface = observer(() => {
  const { organization } = useInject();
  const { highlights } = useSuspense(organization.getAttackSurfaceHighlights);

  const hasApplications = useMemo(
    () =>
      highlights.some(
        ({ dataType, attackSurface }) => dataType === 'applications' && attackSurface.total
      ),
    [highlights]
  );
  const linkTo = hasApplications ? '/profiles/applications' : '/profiles/repositories';

  return highlights.map(({ dataType, ...highlight }) => {
    switch (dataType) {
      case 'applications':
        return (
          <HighlightsCard
            key={dataType}
            {...highlight}
            title="Apps"
            elementType="Applications"
            callToAction="View applications"
            to={
              highlight.attackSurface.total
                ? makeUrl(linkTo, {
                    fl: { RiskLevel: { values: ['Critical', 'High', 'Medium', 'Low'] } },
                  })
                : '/profiles/applications/create'
            }>
            {!hasApplications && (
              <HighlightsContent title="Define Applications" callToAction="Create an Application">
                Collect Repositories & issue projects into a unified risk profile, based on
                developer behavior
              </HighlightsContent>
            )}
          </HighlightsCard>
        );

      case 'apis':
        return (
          <HighlightsCard
            key={dataType}
            {...highlight}
            title="APIs"
            callToAction="View applications"
            to={makeUrl(linkTo, {
              fl: {
                operator: 'or',
                Components: { values: ['DependenciesWithVulnerabilities'] },
                Exposure: { values: ['HasSensitiveApi'] },
                ProfileInsights: { values: ['Internet exposed'] },
                Controls: {
                  values: ['HasApiMissingAuthorization', 'HasApiMissingInputValidation'],
                },
                RiskFactors: {
                  values: ['PII Exposed by an API', 'Payment Data Exposed by an API'],
                },
              },
            })}
          />
        );

      case 'sensitiveData':
        return (
          <HighlightsCard
            key={dataType}
            {...highlight}
            title="Data"
            elementType="Fields"
            callToAction="View applications"
            to={makeUrl(linkTo, {
              fl: {
                operator: 'or',
                Data: { values: ['HasPiiData', 'HasPaymentsData'] },
                RiskFactors: {
                  values: [
                    'PII Data Written to Logs',
                    'Payments Data Written to Logs',
                    'Payment Data Exposed by an API',
                    'PII Exposed by an API',
                  ],
                },
              },
            })}
          />
        );

      case 'iac':
        return (
          <HighlightsCard
            key={dataType}
            {...highlight}
            title="Cloud infra"
            elementType="Resources"
            callToAction="View applications"
            to={makeUrl(linkTo, {
              fl: {
                operator: 'or',
                RiskFactors: {
                  values: [
                    'Cloud Network Structure Misconfigurations',
                    'Cloud IAM & Least Privilege Misconfigurations',
                    'Cloud Database Misconfigurations',
                  ],
                },
              },
            })}
          />
        );

      default:
        console.warn(`unknown attack surface highlight dataType "${dataType}"`);
        return null;
    }
  });
});
