import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { StyledRemainingChars } from '@src/components/Forms/RemainingChars';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Input } from '@src/components/reactstrap-polyfill';

const maxLength = 100;

export const RuleNameStyle = styled(Input)`
  align-items: center;
  border: none;
  border-radius: 18px;
  display: flex;
  min-height: 9rem;
  padding: 0 3rem;
  background-color: var(--color-blue-gray-20);
  width: 680px;
  flex: 1;

  :focus {
    border: 1px solid var(--color-green-50);
    box-shadow: none;
  }

  ::placeholder {
    color: var(--color-blue-gray-45);
  }
`;

const StyledTitle = styled.span`
  white-space: nowrap;
`;

const ErrorFeedback = styled.div`
  display: none;
  color: var(--color-failure);
  font-size: var(--font-size-xs);

  .was-validated :invalid ~ &,
  .is-invalid ~ & {
    display: block;
  }
`;

export function RuleHeader({
  rule,
  nameExists,
  ruleTitleName,
  ruleNameExample,
  onRuleNameChange,
  isNew,
}) {
  return (
    <HorizontalStack withSeparator>
      <StyledTitle>{`${isNew ? 'New' : 'Edit'} ${ruleTitleName}`}</StyledTitle>
      <HorizontalStack>
        <RuleNameStyle
          disabled={false}
          type="text"
          name="ruleName"
          autoComplete="off"
          placeholder={`${_.startCase(ruleTitleName)} name ${
            ruleNameExample ? `(e.g ${ruleNameExample})` : ''
          }`}
          autoFocus
          value={rule.name}
          invalid={(isEmpty(rule.name) && !isNew) || nameExists}
          onChange={event => onRuleNameChange(event.target.value)}
          maxLength={maxLength}
          required
        />
        {nameExists && <ErrorFeedback>Name already exists</ErrorFeedback>}
        <StyledRemainingChars>{`${rule.name?.length ?? 0}/${maxLength}`}</StyledRemainingChars>
      </HorizontalStack>
    </HorizontalStack>
  );
}
