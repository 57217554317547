import { forwardRef } from 'react';
import styled from 'styled-components';
import { Chip } from '@src-v2/components/chips';
import { Combobox, Input } from '@src-v2/components/forms';
import { MultiSelect } from '@src-v2/components/forms/multi-select';
import { BaseIcon, VendorIcon } from '@src-v2/components/icons';
import { SelectV2 } from '@src-v2/components/select/select-v2';
import { Heading } from '@src-v2/components/typography';

export const AdvancedDefinitionsTabs = ({ selectedTab, setSelectedTab, tabs }) => {
  return (
    <TabsContainer>
      {tabs.map(({ value, label }) => (
        <Label key={value} selected={selectedTab === value} onClick={() => setSelectedTab(value)}>
          {label}
        </Label>
      ))}
    </TabsContainer>
  );
};

const TabsContainer = styled.div`
  display: flex;
  gap: 8rem;
  margin-bottom: 8rem;
`;

const Label = styled.label`
  height: 7rem;
  font-size: var(--font-size-m);
  font-weight: 600;
  color: ${props => (props.selected ? 'var(--color-blue-gray-70)' : 'var(--color-blue-gray-50)')};
  border-bottom: ${props => (props.selected ? '0.5rem solid var(--color-black)' : 'none')};
  cursor: pointer;

  &:hover {
    color: ${props => (props.selected ? 'var(--color-blue-gray-70)' : 'var(--color-blue-gray-55)')};
  }
`;

export const AdvancedContainer = styled.div`
  display: ${props => (props.selected ? 'block' : 'none')};

  ${Heading} {
    display: flex;
    align-items: center;
    font-size: 1em;
    font-weight: 400;
    margin-bottom: 1rem;
    gap: 1rem;

    &:not(:first-child) {
      margin-top: 6rem;
    }
  }

  ${Input} {
    font-size: var(--font-size-s);
    font-weight: 400;
  }

  ${SelectV2.Container}, ${Combobox} ${Input},
  ${Input} {
    width: 70rem;
  }

  > ${MultiSelect.Combobox} {
    min-width: 70rem;
    max-width: 70rem;

    ${Input} {
      width: 59rem;
      min-width: 59rem;
    }
  }
`;

export const InternetFacingField = styled.label`
  width: fit-content;
  display: flex;
  align-items: center;
  font-weight: 600;
  gap: 4rem;
`;

export const ProviderChip = styled(
  forwardRef(({ selectedItem, children, ...props }, ref) => {
    const providerGroup = selectedItem.providerGroup ?? selectedItem?.server?.providerGroup;

    return (
      <Chip ref={ref} {...props}>
        {providerGroup && <VendorIcon name={providerGroup} />}
        {children}
      </Chip>
    );
  })
)`
  ${Chip.Content} {
    gap: 1rem;
  }

  ${BaseIcon} {
    padding: 1rem;
  }
`;
