import { observer } from 'mobx-react';
import { useCallback } from 'react';
import { useParams, useRouteMatch } from 'react-router-dom';
import { Page } from '@src-v2/components/layout/page';
import { StickyHeader } from '@src-v2/components/layout/sticky-header';
import { usePaneState } from '@src-v2/components/panes/pane-context-provider';
import { HeaderRiskDetails } from '@src-v2/components/risk/risk-details';
import {
  FlatLearningButton,
  LearningPane,
  LearningStatistics,
} from '@src-v2/containers/learning-stats';
import { useInject } from '@src-v2/hooks';
import { useSuspense } from '@src-v2/hooks/react-helpers/use-suspense';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';

export default observer(({ children }) => {
  const { key } = useParams();
  const { application, repositoryProfiles } = useInject();

  const profile = useSuspense(repositoryProfiles.getProfile, { key });
  const { pushPane } = usePaneState();

  const handleOpenPane = useCallback(() => {
    pushPane(
      <LearningPane>
        <LearningStatistics
          profileKey={key}
          dataFetcher={repositoryProfiles.getLearningStatistics}
        />
      </LearningPane>
    );
  }, []);

  return (
    <Page title={profile.name}>
      <StickyHeader
        title={
          profile.provider === 'Perforce' ? profile.name : `${profile.name} (${profile.mainBranch})`
        }
        navigation={[
          { label: 'Profile', to: 'profile' },
          { label: 'Risk', to: 'risk' },
          { label: 'Timeline', to: 'timeline' },
          { label: 'Inventory', to: 'inventory' },
          application.isFeatureEnabled(FeatureFlag.SpacetimeGraph) && {
            label: 'Graph',
            to: 'graph',
          },
          {
            label: 'Connections',
            to: 'connections',
          },
        ].filter(Boolean)}
        minimized={Boolean(useRouteMatch('/profiles/repositories/:key/graph'))}>
        <FlatLearningButton onClick={handleOpenPane}>Learning statistics</FlatLearningButton>
        <HeaderRiskDetails profile={profile} />
      </StickyHeader>

      {children}
    </Page>
  );
});
