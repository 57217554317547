import { useLayoutEffect } from 'react';

export function useDetectEscapePress(handler, condition = true) {
  useLayoutEffect(() => {
    function listener(event) {
      if (event.key === 'Escape') {
        handler(event);
      }
    }

    if (typeof handler === 'function' && condition) {
      document.addEventListener('keydown', listener, true);
      return () => document.removeEventListener('keydown', listener, true);
    }
  }, [handler, condition]);
}
