import _ from 'lodash';
import ioc from '@src-v2/ioc';
import { demoService } from '@src/services/demoService';
import profilesService from '@src/services/profilesService';

const clearData = () => ({
  ruleTriggers: null,
  ruleKey: null,
});

export default {
  state: clearData(),
  reducers: {
    clearData,
    setRuleTriggers: (state, ruleTriggers) => ({
      ...state,
      ruleTriggers,
    }),
    setRuleKey: (state, ruleKey) => ({
      ...state,
      ruleKey,
    }),
  },
  selectors: slice => ({
    ruleTriggers: () => slice(state => state?.ruleTriggers),
    ruleKey: () => slice(state => state?.ruleKey),
  }),
  effects: () => ({
    fetchData({ profileTriggers, ruleKey, profile }, state) {
      this.setRuleKey(ruleKey);
      this.updateRuleTriggers({ profileTriggers, ruleKey, profile }, state);
    },
    updateRuleTriggers({ profileTriggers, ruleKey }, state) {
      const processedProfileTriggers = _.keyBy(
        profilesService.processProfileRiskTriggers(
          profileTriggers,
          state.governanceRules.rulesByKey
        ),
        'ruleKey'
      );

      if (ioc.application.isDemo) {
        demoService.enrichRiskTriggers(processedProfileTriggers, ruleKey);
      }

      this.setRuleTriggers(
        processedProfileTriggers[ruleKey ?? state.riskRuleTriggers.ruleKey] ?? {}
      );
    },
  }),
};
