import { connect } from 'react-redux';
import { select } from '@src/store';
import ReleasePage from './view';

const mapState = select(
  ({
    releasePage: {
      releaseKey,
      release,
      materialChanges,
      isMonitored,
      repository,
      repositoryProfile,
    },
    developerProfiles: { developerProfileByIdentityKey },
    governanceRules: { rulesByKey: governanceRulesByKey },
  }) => ({
    releaseKey,
    release,
    materialChanges,
    isMonitored,
    repository,
    developerProfileByIdentityKey,
    repositoryProfile,
    governanceRulesByKey,
  })
);

const mapDispatch = ({ releasePage: { fetchData, clearData } }) => ({
  fetchData,
  clearData,
});

export default connect(mapState, mapDispatch)(ReleasePage);
