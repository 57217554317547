import _ from 'lodash';
import styled from 'styled-components';
import { Checkbox } from '@src-v2/components/forms/checkbox';
import { AccessType } from '@src-v2/types/enums/access-type';
import {
  globalReadPermission,
  globalWritePermission,
  permissionEquals,
  roleHasPermission,
} from '@src/blocks/RolesPage/roleUtils';

export const RoleOptions = ({
  role,
  supportedPermissionByResource: { Global: global, ...supportedPermissionByResource },
  togglePermission,
}) => {
  return (
    <>
      {global && (
        <GlobalPermission>
          <RoleOption
            role={role}
            isDisabled={isPermissionDisabled(role, global[0])}
            permission={global[0]}
            resourceType="Global"
            togglePermission={togglePermission}
          />
        </GlobalPermission>
      )}
      <Container>
        {_.map(supportedPermissionByResource, (permissions, resourceType) => (
          <div key={resourceType}>
            {_.orderBy(permissions, [permission => AccessType[permission.accessType]]).map(
              (permission, index) => {
                const isDisabled = isPermissionDisabled(role, permission);
                return (
                  <RoleOption
                    key={index}
                    role={role}
                    isDisabled={isDisabled}
                    permission={permission}
                    resourceType={resourceType}
                    togglePermission={togglePermission}
                  />
                );
              }
            )}
          </div>
        ))}
      </Container>
    </>
  );
};

const RoleOption = styled(
  ({ isDisabled, togglePermission, permission, role, resourceType, ...props }) => {
    return (
      <label {...props}>
        <Checkbox
          disabled={isDisabled}
          onChange={() => togglePermission(permission)}
          checked={
            isDisabled ||
            role.permissions.some(existingPermissions =>
              permissionEquals(permission, existingPermissions)
            )
          }
        />
        <Content>
          <span>{resourceType}</span>
          <StyledOptionName>{permission.description}</StyledOptionName>
        </Content>
      </label>
    );
  }
)`
  position: relative;
  display: flex;
  cursor: pointer;
  gap: 2rem;

  ${Checkbox} {
    position: relative;
    top: 1.25rem;
  }
`;

const GlobalPermission = styled.div`
  padding-bottom: 4rem;
  border-bottom: 0.25rem solid var(--color-blue-gray-25);
  margin-bottom: 4rem;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-gap: 3rem;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledOptionName = styled.span`
  font-size: var(--font-size-s);
  color: var(--color-blue-gray-50);
`;

const isPermissionDisabled = (role, permission) => {
  const hasGlobalWrite = roleHasPermission(role, globalWritePermission);
  const hasGlobalRead = roleHasPermission(role, globalReadPermission);
  if (permissionEquals(permission, globalWritePermission)) {
    return false;
  }

  if (
    permissionEquals(permission, globalReadPermission) ||
    AccessType[permission.accessType] === AccessType.Write
  ) {
    return hasGlobalWrite;
  }

  if (AccessType[role.accessType] === AccessType.Write) {
    return hasGlobalWrite;
  }

  return hasGlobalWrite || hasGlobalRead;
};
