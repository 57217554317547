import { useEffect } from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@src-v2/components/icons';
import { Gutters, StickyHeader } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { DateTime, DistanceTime, TimeTooltip } from '@src-v2/components/time';
import { dateFormats } from '@src-v2/data/datetime';
import Bold from '@src/components/Bold';
import Collapsible from '@src/components/Collapsible';
import { Commit } from '@src/components/Commit';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Link } from '@src/components/Link';
import { Number } from '@src/components/Number';
import { PageLoading } from '@src/components/PageLoading';
import RiskBadge from '@src/components/RiskBadge';
import { TitledList } from '@src/components/TitledList';
import { Tooltip } from '@src/components/Tooltip';
import { FontBodySmall } from '@src/style/common';

const StyledHorizontalStack = styled(HorizontalStack)`
  color: var(--default-text-color);
  flex-wrap: wrap;

  & > * {
    margin-bottom: 3rem;
  }
`;

const StyledTitleList = styled(TitledList)`
  margin-top: 12rem;
`;

const StyledClass = styled.div`
  ${FontBodySmall};
  padding-left: 18px;
`;

const StyledLink = styled(Link)`
  width: 9rem;
`;

export default function BuildPage({
  fetchData,
  clearData,
  buildData,
  buildJobId,
  hasFetchedData,
  commitMaterialChanges,
  hasFetchedCommitData,
  developerProfileByIdentityKey,
  repositoryProfilesByKey,
}) {
  useEffect(() => {
    fetchData({ buildJobId });
    return clearData;
  }, [fetchData, buildJobId, clearData]);

  if (!hasFetchedData || !hasFetchedCommitData) {
    return <PageLoading />;
  }
  const { repositoryKey } = buildData;
  const repositoryProfile = repositoryProfilesByKey[repositoryKey];
  const developerProfile =
    developerProfileByIdentityKey[commitMaterialChanges.commitData.authorIdentityKey];
  const committerProfile =
    developerProfileByIdentityKey[commitMaterialChanges.commitData.committerIdentityKey];

  return (
    <Page>
      <StickyHeader
        title={
          <>
            Build {buildData.name || buildData.repositoryKeys[0]} has suspicious code changes{' '}
            <RiskBadge riskLevel={buildData.materialChangesRiskLevel} />
          </>
        }>
        {buildData.buildPageUrl && (
          <StyledHorizontalStack align="right">
            <StyledLink
              openInNewTab
              external
              tip="View external build page"
              url={buildData.buildPageUrl}
              inline>
              <SvgIcon name="External" />
            </StyledLink>
            <span>view build</span>
          </StyledHorizontalStack>
        )}
        <StyledHorizontalStack align="right">
          <TimeTooltip date={buildData.timestamp}>
            <DistanceTime date={buildData.timestamp} addSuffix strict />
          </TimeTooltip>
        </StyledHorizontalStack>
      </StickyHeader>

      <Gutters>
        <StyledTitleList
          title="Build info"
          list={[
            <HorizontalStack>
              <span>Triggered by commit</span>
              <Commit
                commitSha={commitMaterialChanges.commitData.shortSha}
                repository={repositoryProfile.repository}
                commitMessage={commitMaterialChanges.commitData.message}
                authorProfile={developerProfile}
                committerProfile={committerProfile}
                repositoryProfiles={[]}
              />
            </HorizontalStack>,
            <span>
              Build healthcheck request received on{' '}
              <DateTime
                date={commitMaterialChanges.commitData.timestamp}
                format={dateFormats.longDate}
              />
            </span>,
            <HorizontalStack>
              <span>Built binary: </span>
              <Bold>{buildData.fileName}</Bold>
            </HorizontalStack>,
          ]}
        />
        <StyledTitleList
          title={
            <Number
              value={buildData.suspiciousMethodSymbols.length}
              one="Suspicious method from built binary was not found in the commit"
              other="Suspicious methods from built binary were not found in the commit"
            />
          }
          list={buildData.suspiciousMethodSymbols.map(method => (
            <Tooltip tip={method.methodFullName} key={method.methodFullName}>
              <Collapsible title={method.methodName}>
                <StyledClass>Class: {method.className}</StyledClass>
              </Collapsible>
            </Tooltip>
          ))}
        />
      </Gutters>
    </Page>
  );
}
