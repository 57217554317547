export default {
  state: {
    dropdownOpen: false,
    modalOpen: false,
    customFilterToDeleteKey: null,
    customFilterToDeleteName: null,
    searchTerm: '',
  },
  selectors: slice => ({
    dropdownOpen: () => slice(state => state?.dropdownOpen),
    searchTerm: () => slice(state => state?.searchTerm),
  }),
  reducers: {
    setDropdownOpen: (state, dropdownOpen) => ({
      ...state,
      dropdownOpen,
    }),
    setSearchTerm: (state, searchTerm) => ({
      ...state,
      searchTerm,
    }),
  },
};
