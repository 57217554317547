import _ from 'lodash';
import { computed, makeObservable, observable, reaction, runInAction } from 'mobx';
import { nodeTypeToTitle } from '@src-v2/containers/spacetime-graph/spacetime-graph-legend';
import { ForceNode } from '@src-v2/models/force-graph/force-node';
import { NodeCollection } from '@src-v2/models/force-graph/node-collection';
import { modify } from '@src-v2/utils/mobx-utils';

export class ForceGraph {
  static create = (...args) => new this(...args);

  static propsByType = {
    // DataStore: { centerX: 800, centerY: 400 },
    // Ui: { centerX: -800, centerY: -400 },
  };

  #raw;
  #root;
  #edges;
  #edgesMap;
  #nodesMap;

  initialized = false;

  constructor(graphData) {
    makeObservable(this, {
      initialized: observable,
      dependencies: computed,
      highlights: computed,
      nodes: computed,
      edges: computed,
    });

    this.setData(graphData);
    this.disposer = reaction(
      () => this.dependencies,
      () => this.#simulateCollection(this.#root)
    );
  }

  setData({ nodes, edges }) {
    modify(this, 'initialized', false);
    this.#raw = { nodes, edges };
    this.#nodesMap = new Map(
      nodes.map(node => [
        node.id,
        ForceNode.create(
          node.type !== 'SensitiveData'
            ? node
            : {
                ...node,
                type: node.data?.IsPayment
                  ? 'Pci'
                  : node.data?.IsPii
                    ? 'Pii'
                    : node.data?.IsPhi
                      ? 'Phi'
                      : node.type,
              },
          this
        ),
      ])
    );
    this.#edges = edges.filter(
      // temporary patch, should be removed when data can be trusted
      edge => this.#nodesMap.has(edge.source) && this.#nodesMap.has(edge.target)
    );
    this.#edgesMap = new Map(
      Object.entries(
        this.#edges.reduce((map, edge) => {
          map[edge.source] ??= [];
          map[edge.target] ??= [];
          map[edge.source].push(edge);
          map[edge.target].push(edge);
          return map;
        }, {})
      )
    );
    this.#root = NodeCollection.create(
      this.#transformNodes(Array.from(this.#nodesMap.values())),
      this
    );
    this.#simulateCollection(this.#root, { maxTicks: 800, linkStrength: 0.4 })
      .then(() => modify(this, 'initialized', true))
      .catch(error => console.error(error));
  }

  getNodeById(nodeId) {
    return this.#nodesMap.get(nodeId);
  }

  getNodeEdges(node) {
    return this.#edgesMap.get(node.id) ?? [];
  }

  isLinked(node) {
    return this.#edgesMap.has(node.id);
  }

  filter(callback) {
    return ForceGraph.create(this.#getRelatedNodes(this.#raw.nodes.filter(callback)));
  }

  setHighlights(callback) {
    for (const node of this.#nodesMap.values()) {
      node.setHighlight(callback(node));
    }
  }

  #getRelatedNodes(nodes) {
    const relatedMap = new Map(nodes.map(node => [node.id, node]));
    const rawNodesMap = new Map(this.#raw.nodes.map(node => [node.id, node]));
    const extractRelated = node => {
      for (const { source, target } of this.getNodeEdges(node)) {
        const relatedNode = rawNodesMap.get(source === node.id ? target : source);
        if (!relatedMap.has(relatedNode.id)) {
          relatedMap.set(relatedNode.id, relatedNode);
          extractRelated(relatedNode);
        }
      }
    };

    nodes.forEach(extractRelated);

    return {
      nodes: Array.from(relatedMap.values()),
      edges: this.#raw.edges.filter(
        edge => relatedMap.has(edge.source) && relatedMap.has(edge.target)
      ),
    };
  }

  get nodes() {
    return this.initialized ? this.#root.nodes : [];
  }

  get edges() {
    const uniqueEdges = this.initialized
      ? _.uniqWith(
          this.#edges.map(({ source, target, ...edge }) => ({
            ...edge,
            source: this.#getClosestVisibleSimulationByNodeId(source),
            target: this.#getClosestVisibleSimulationByNodeId(target),
          })),
          _.isEqual
        )
      : [];

    return _.filter(uniqueEdges, ({ source, target }) => source.id !== target.id);
  }

  get nodeTypes() {
    const nodeTypes = new Set();
    for (const node of this.#nodesMap.values()) {
      nodeTypes.add(node.type);
    }
    return Array.from(nodeTypes).sort();
  }

  get edgeTypes() {
    const edgeTypes = new Set();
    for (const edge of this.#edges) {
      edgeTypes.add(edge.type);
    }
    return Array.from(edgeTypes).sort();
  }

  #getClosestVisibleSimulationByNodeId(nodeId) {
    const node = this.#nodesMap.get(nodeId);
    const simulation = { id: node.id, x: 0, y: 0 };
    for (const ancestor of node.ancestors.reverse()) {
      ForceGraph.mergeSimulations(simulation, ancestor.simulation);
      if (!ancestor.expanded) {
        simulation.id = ancestor.id;
        return simulation;
      }
    }
    return ForceGraph.mergeSimulations(simulation, node.simulation);
  }

  get highlights() {
    const nodes = Array.from(this.#nodesMap.values());
    const highlights = nodes.filter(node => node.highlight);
    const highlightsGroups = _.groupBy(highlights, 'parentNode.id');

    highlights.forEach(function addParent(node) {
      if (node.parentNode) {
        highlights.push(node.parentNode);
        addParent(node.parentNode);
      }
    });

    for (const { source, target } of this.edges) {
      const sourceNode = this.#nodesMap.get(source.id);
      const targetNode = this.#nodesMap.get(target.id);
      if (sourceNode.highlight ^ targetNode.highlight) {
        const { parentNode } = sourceNode.highlight ? sourceNode : targetNode;
        if (highlightsGroups[parentNode?.id]?.length === 1) {
          highlights.push(sourceNode.highlight ? targetNode : sourceNode);
        }
      }
    }

    return highlights.concat(
      nodes.filter(
        node =>
          node.parentNode?.highlight &&
          !highlightsGroups[node.parentNode.id] &&
          !highlights.includes(node)
      )
    );
  }

  #transformNodes(nodes) {
    const rootNodes = [];
    const grouped = _.groupBy(nodes, 'data.Module');
    for (const [entry, nodes] of Object.entries(grouped)) {
      if (this.#nodesMap.has(entry)) {
        const parentNode = this.#nodesMap.get(entry);
        Object.assign(parentNode, {
          children: NodeCollection.create(
            this.#groupSimilar(nodes).map(child => Object.assign(child, { parentNode })),
            this
          ),
        });
      } else {
        rootNodes.push(...nodes);
      }
    }
    return this.#groupSimilar(rootNodes);
  }

  #groupSimilar(nodes, minNodes = 8, nestedAttempt = false) {
    const nodeGroups = _.groupBy(nodes, node => this.#getNodeGroup(node));

    const possibleElementsGroups = Object.entries(
      _.groupBy(nodeGroups.elements ?? [], node => node.data?.RelativeFilePath)
    );
    const possibleUnlinkedGroups = Object.entries(
      _.groupBy(nodeGroups.unlinked ?? [], node => node.data?.Language ?? node.type)
    );

    const possibleGroups = _.concat(possibleElementsGroups, possibleUnlinkedGroups);

    const rootNodes = nodeGroups.rootNodes ?? [];

    const validGroups = possibleGroups.filter(([, children]) => children.length > 1);
    if (validGroups.length < 2 && (nestedAttempt || (validGroups[0]?.[1].length ?? 0) < minNodes)) {
      return nodes;
    }

    for (const [entry, children] of possibleGroups) {
      if (children.length === 1) {
        rootNodes.push(...children);
        continue;
      }
      const {
        length: count,
        0: { type },
      } = children;
      const id = crypto.randomUUID();
      const parentNode = ForceNode.create(
        {
          id,
          name: `${count} ${nodeTypeToTitle[type] ?? type}s - ${entry}`,
          type: 'Group',
          subType: type,
          data: {
            RelativeFilePath: ForceGraph.aggregateNodeData(children, 'RelativeFilePath'),
            RepositoryKey: ForceGraph.aggregateNodeData(children, 'RepositoryKey'),
          },
        },
        this
      );
      Object.assign(parentNode, {
        children: NodeCollection.create(
          (nestedAttempt ? children : this.#groupSimilar(children, minNodes, true)).map(child =>
            Object.assign(child, { parentNode })
          ),
          this
        ),
      });
      this.#nodesMap.set(id, parentNode);
      rootNodes.push(parentNode);
    }

    return rootNodes;
  }

  static aggregateNodeData(nodes, dataName) {
    const data = _.uniq(_.map(nodes, `data.${dataName}`));
    return data.length === 1 ? data[0] : null;
  }

  #getNodeGroup(node) {
    if (['Api', 'Pii', 'Phi', 'Pci'].includes(node.type)) {
      return 'elements';
    }

    if (!this.isLinked(node)) {
      return 'unlinked';
    }

    return 'rootNodes';
  }

  get dependencies() {
    const dependencies = new WeakMap();
    const relations = [{ parent: rootNode, collection: this.#root }].concat(this.#root.descendants);

    for (const { parent, collection } of relations) {
      if (!dependencies.has(parent)) {
        dependencies.set(parent, new Set());
      }
      dependencies.get(parent).add(collection);
    }

    return {
      has: key => dependencies.has(key),
      get: key => Array.from(dependencies.get(key)),
    };
  }

  static mergeSimulations(target, source) {
    return Object.assign(target, {
      ...source,
      x: target.x + source.x,
      y: target.y + source.y,
    });
  }

  async #simulateCollection(collection, options) {
    if (this.dependencies.has(collection)) {
      await Promise.all(
        this.dependencies
          .get(collection)
          .map(dependency => this.#simulateCollection(dependency, options))
      );
    }
    await this.#runSimulation(collection, options);
  }

  async #runSimulation(collection, options) {
    const { data } = await runForceSimulation(
      collection.nodes.map(node => ({
        id: node.id,
        ...ForceGraph.propsByType[node.type],
        ...((node.selected || node.synced) && node.simulation),
        radius: node.children && node.selected ? node.children.radius + 10 : 0,
      })),
      this.#edges
        .filter(
          edge =>
            collection.nodes.some(node => node.id === edge.source) &&
            collection.nodes.some(node => node.id === edge.target)
        )
        .map(edge => ({ source: edge.source, target: edge.target })),
      options
    );
    runInAction(() => {
      for (const { id, ...simulation } of data.nodes) {
        this.#nodesMap.get(id).updateSimulation(simulation);
      }
    });
  }
}

const rootNode = new (class RootNode {})();

function runForceSimulation(nodes, edges = [], options = {}) {
  const worker = new Worker(new URL('./simulation-worker.js', import.meta.url));
  return new Promise((resolve, reject) => {
    worker.addEventListener('messageerror', reject);
    worker.addEventListener('message', resolve);
    worker.postMessage({ ...options, nodes, edges });
  }).finally(() => worker.terminate());
}
