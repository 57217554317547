import { CommitCodeReference } from '@src-v2/containers/commit/common-componnets';

export const TerraformResourceMaterialChange = ({ commitSha, repository, materialChange }) => {
  const resource = materialChange.resourceSummary;
  return (
    <>
      resource
      <CommitCodeReference
        repository={repository}
        commitSha={commitSha}
        relativeFilePath={resource.path}>
        {`${resource.typeDescription} (${resource.key})`}
      </CommitCodeReference>
      {`of type ${resource.iacCategory} was ${materialChange.changeType} to module`}
      <CommitCodeReference
        repository={repository}
        commitSha={commitSha}
        relativeFilePath={resource.modulePath}>
        {resource.modulePath === '/' ? 'root' : resource.modulePath}
      </CommitCodeReference>
    </>
  );
};
