import { connect } from 'react-redux';
import { select } from '@src/store';
import { InsightsPaneTitle } from './view';

const mapState = select(
  ({
    insightsPaneTitle: { introducedDeveloperProfile, modifiedDeveloperProfile, codeOwnerEntity },
  }) => ({
    introducedDeveloperProfile,
    modifiedDeveloperProfile,
    codeOwnerEntity,
  })
);

const mapDispatch = ({ insightsPaneTitle: { fetchData } }) => ({
  fetchData,
});

export default connect(mapState, mapDispatch)(InsightsPaneTitle);
