import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { Redirect, useHistory, useParams } from 'react-router-dom';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Paragraph } from '@src-v2/components/typography';
import { useInject, useSuspense } from '@src-v2/hooks';

export const ProcessTagDeleteModal = () => {
  const history = useHistory();

  const { key } = useParams();
  const { processes } = useInject();
  const processTagsList = useSuspense(processes.listProcessTags);

  const tagToDelete = useMemo(() => _.find(processTagsList, { key }), [key, processTagsList]);

  const closeModal = useCallback(
    () => history.push('/settings/access-permissions/process-tags'),
    [history]
  );

  const handleDelete = useCallback(async () => {
    await processes.deleteProcessTag(tagToDelete);
    closeModal();
  }, [tagToDelete, closeModal]);

  return tagToDelete ? (
    <ConfirmationModal
      title={`Delete ${tagToDelete?.name}?`}
      submitText="Delete"
      submitStatus="failure"
      onSubmit={handleDelete}
      onClose={closeModal}>
      <Paragraph>Are you sure you want to delete this process?</Paragraph>
      <Paragraph>This might effect rules that are part of this process.</Paragraph>
    </ConfirmationModal>
  ) : (
    <Redirect to="/settings/access-permissions/process-tags" />
  );
};
