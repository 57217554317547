import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDataTableContext } from '@src-v2/containers/data-table/data-table';
import { useDimensionsObserver, useResizeObserver } from '@src-v2/hooks';
import { useDrag, useDrop } from '@src-v2/hooks/drag-and-drop';
import { dataAttr } from '@src-v2/utils/dom-utils';

export const useColumnDrag = ({ item, type, onDrop, canDrop }) => {
  const [dragRef, dragState] = useDrag({ item, type });
  const [dropRef, dropState] = useDrop({ type, onDrop, canDrop });
  return { dragRef, dropRef, dragState, dropState };
};

export const usePinnedColumnRef = ({ index }) => {
  const { dataModel } = useDataTableContext();
  const pinnedColumnRef = useRef();
  const elementDimensions = useDimensionsObserver(pinnedColumnRef);

  useEffect(() => {
    if (elementDimensions.width) {
      const { right, left } = pinnedColumnRef.current?.parentElement.getBoundingClientRect();
      const newWidth = right - left;
      const columnWidthIndex = index + 1;
      const recordedWidth = dataModel.columnsWidth[columnWidthIndex].width;
      if (Math.abs(newWidth - recordedWidth) > 1) {
        const widths = _.cloneDeep(dataModel.columnsWidth);
        widths[columnWidthIndex].width = newWidth;
        dataModel.setColumnsWidth(widths);
      }
    }
  }, [elementDimensions, index]);

  return { pinnedColumnRef };
};

export const usePinnedStyle = ({ index, pinned }) => {
  const { dataModel } = useDataTableContext();
  const isLastPinned =
    _.findLastIndex(dataModel.columns, column => Boolean(column.pinned)) === index;

  const left = useMemo(
    () =>
      dataModel.pinned
        ? calculateLeft({ columnsWidth: dataModel.columnsWidth, index, pinned })
        : 'auto',
    [dataModel.pinned, dataModel.columnsWidth, index, pinned]
  );

  const pinnedColumnDataAttr = useMemo(
    () => dataAttr(pinned, isLastPinned ? 'last' : ''),
    [dataModel.pinned, pinned, isLastPinned]
  );

  return { left, pinnedColumnDataAttr };
};

export const useTableScrolling = ({ dataModel }) => {
  const scrollContainerRef = useRef();
  const [scrolledPosition, setScrolledPosition] = useState('none');

  useEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = 0;
    }
  }, [dataModel?.searchState?.items]);

  const handleScrollPositionChange = useCallback(() => {
    if (!dataModel.isPinFeatureEnabled) {
      return null;
    }

    const scrolledMaxRight =
      Math.floor(
        scrollContainerRef.current?.scrollWidth - scrollContainerRef.current?.scrollLeft
      ) <= scrollContainerRef.current?.offsetWidth;

    if (scrollContainerRef.current?.scrollLeft === 0 && scrolledMaxRight) {
      dataModel.setIsPinned(false);
      setScrolledPosition('none');
    } else if (scrollContainerRef.current?.scrollLeft > 0 && scrolledMaxRight) {
      dataModel.setIsPinned(true);
      setScrolledPosition('left');
    } else if (scrollContainerRef.current?.scrollLeft === 0 && !scrolledMaxRight) {
      dataModel.setIsPinned(true);
      setScrolledPosition('right');
    } else {
      dataModel.setIsPinned(true);
      setScrolledPosition('both');
    }
  }, [scrollContainerRef.current, dataModel.columnsWidth]);

  useEffect(() => {
    handleScrollPositionChange();
  }, [scrollContainerRef.current, dataModel.columnsWidth]);

  useResizeObserver(scrollContainerRef, () => {
    handleScrollPositionChange();
  });

  return {
    scrollContainerRef,
    scrolledPosition,
    handleScrollPositionChange,
  };
};

const calculateLeft = ({ columnsWidth, index, pinned }) => {
  if (!pinned) {
    return 'auto';
  }
  return _.sum(columnsWidth.map(column => column.width).slice(0, index + 1));
};
