import styled from 'styled-components';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Modal } from '@src-v2/components/modals';
import { Heading } from '@src-v2/components/typography';
import { customScrollbar } from '@src-v2/style/mixins';
import ConsumablesList from '@src/components/ConsumablesList';

export const MonitorAllModal = ({ data, ...props }) => (
  <ModalContainer {...props} title="Monitor all repositories and projects" submitText="Monitor All">
    {data?.repositories?.length > 0 && (
      <ListContainer>
        <Heading>Unmonitored Repositories:</Heading>
        <ConsumablesList consumables={data.repositories} isRepository />
      </ListContainer>
    )}
    {data?.projects?.length > 0 && (
      <ListContainer>
        <Heading>Unmonitored Projects</Heading>
        <ConsumablesList consumables={data.projects} />
      </ListContainer>
    )}
  </ModalContainer>
);

const ModalContainer = styled(ConfirmationModal)`
  ${Modal.Content} {
    max-height: 65vh;
    ${customScrollbar};
  }
`;

const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
`;
