import _ from 'lodash';
import { isIgnoredLanguage } from '@src-v2/data/languages';
import { getRiskColor } from '@src-v2/data/risk-data';
import BusinessImpactBadge from '@src/components/BusinessImpactBadge';
import { Languages } from '@src/components/Languages';
import { Number } from '@src/components/Number';
import { RiskFactors } from '@src/components/RiskFactors';
import { ApisBreakdown } from '@src/components/Statistics/ApisBreakdown';
import { PiisBreakdown } from '@src/components/Statistics/PiisBreakdown';
import { SecretsBreakdown } from '@src/components/Statistics/SecretsBreakdown';
import TechnologiesBreakdown from '@src/components/TechnologiesBreakdown';
import { getBusinessImpactFactorsCount } from '@src/services/businessImpactService';

export const enrichProfileStatisticsUrls = (statistics, profilePageUrl) =>
  statistics.forEach(statistic => {
    if (_.isNil(statistic.url) && !_.isNil(statistic.inventoryPath)) {
      statistic.url = `${profilePageUrl}/inventory/${statistic.inventoryPath}`;
    }

    if (statistic.timelineLink) {
      statistic.url = `${profilePageUrl}/timeline`;
    }

    if (statistic.riskLink) {
      statistic.url = `${profilePageUrl}/risk`;
    }
  });

export const getLanguagesStatistics = profile => ({
  name: 'Languages',
  value: profile.languagePercentages.filter(language => !isIgnoredLanguage(language.key)).length,
  popover: <Languages languagePercentages={profile.languagePercentages} />,
});

export const getTechnologiesStatistics = profile => ({
  name: 'Technologies',
  value: _.sumBy(_.values(profile.frameworksByType), 'length'),
  popover: (
    <TechnologiesBreakdown
      technologiesByComponentType={profile.technologiesByComponentType}
      frameworksByType={profile.frameworksByType}
    />
  ),
  inventoryPath: 'components/technologies',
});

export const getApisStatistics = profile => ({
  name: 'APIs',
  value: profile.statistics.apiCount,
  popover: <ApisBreakdown profileStatistics={profile.statistics} />,
  inventoryPath: 'components/apis',
  popoverTitle: <Number value={profile.statistics.apiCount} one="API" other="APIs" />,
});

export const getPiisStatistics = profile => ({
  name: 'PII',
  value: profile.statistics.piisCount,
  popover: <PiisBreakdown profileStatistics={profile.statistics} />,
  inventoryPath: 'data/pii',
  popoverTitle: (
    <span>
      <Number value={profile.statistics.piisCount} /> PII
    </span>
  ),
});

export const getSecretsStatistics = profile => ({
  name: 'Secrets',
  value: profile.statistics.secretsCount,
  popover: <SecretsBreakdown profileStatistics={profile.statistics} />,
  inventoryPath: 'data/secrets',
  popoverTitle: <Number value={profile.statistics.secretsCount} one="Secret" other="Secrets" />,
});

const getRiskFactorsStatistic = profile => {
  const hasRisk = profile.risk.combinedRiskLevel !== 'None';
  return {
    name: 'Application Components',
    value: _.sumBy(profile?.risk.riskFactors, 'instances'),
    ...(hasRisk
      ? {
          popover: <RiskFactors riskDescriptor={{ ...profile.risk, consumableProfile: profile }} />,
          textColor: 'var(--color-white)',
          backgroundColor: getRiskColor({ riskLevel: _.camelCase(profile.risk.combinedRiskLevel) }),
        }
      : {}),
    riskLink: true,
  };
};

const getBusinessImpactFactorsStatistics = profile => {
  const hasBusinessImpact = profile.businessImpact && profile.businessImpact !== 'None';
  return {
    name: 'Business Impact Factors',
    value: hasBusinessImpact ? getBusinessImpactFactorsCount(profile) : 0,
    popover: <BusinessImpactBadge profile={profile} />,
    ...(hasBusinessImpact
      ? {
          color: 'var(--color-blue-gray-65)',
          textColor: getRiskColor({ riskLevel: _.camelCase(profile.businessImpact) }),
          borderColor: getRiskColor({ riskLevel: _.camelCase(profile.businessImpact) }),
          backgroundColor: 'var(--color-white)',
        }
      : {}),
  };
};

export const enrichProfileStatisticsRiskAndBusinessImpact = (statistics, profile) => {
  if (!_.isNil(statistics)) {
    statistics.push(getRiskFactorsStatistic(profile), getBusinessImpactFactorsStatistics(profile));
  }
};
