import { computed, makeObservable, observable } from 'mobx';
import { modify } from '@src-v2/utils/mobx-utils';

export class ForceNode {
  static create = (...args) => new this(...args);
  static defaultSize = 60;

  simulation = { x: 0, y: 0, radius: this.constructor.defaultSize / 2 };
  lastInteraction = 0;
  highlight = false;
  selected = false;
  synced = false;
  parentNode;
  children;

  #rootGraph;

  constructor(initialData, rootGraph) {
    this.#rootGraph = rootGraph;
    Object.assign(this, initialData);

    makeObservable(this, {
      simulation: observable,
      lastInteraction: observable,
      highlight: observable,
      selected: observable,
      synced: observable,
      dimensions: computed,
      expanded: computed,
    });
  }

  get radius() {
    return this.expanded ? this.children.radius : this.constructor.defaultSize / 2;
  }

  get dimensions() {
    return this.expanded
      ? this.children.dimensions
      : { width: this.constructor.defaultSize, height: this.constructor.defaultSize };
  }

  get expanded() {
    return this.children && this.selected && this.synced;
  }

  get ancestors() {
    let node = this;
    const ancestors = [];
    while (node.parentNode) {
      ancestors.push(node.parentNode);
      node = node.parentNode;
    }
    return ancestors;
  }

  updateSimulation(simulation) {
    modify(this.simulation, simulation);
    modify(this, 'synced', true);
  }

  toggleSelect() {
    modify(this, { selected: !this.selected, synced: this.selected });

    if (this.children && !this.selected) {
      this.children.nodes.forEach(node => node.selected && node.toggleSelect());
    }
  }

  positionOnTop() {
    modify(this, 'lastInteraction', Date.now());
  }

  setHighlight(value) {
    modify(this, 'highlight', Boolean(value));
  }
}
