import { forwardRef } from 'react';
import styled from 'styled-components';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { Ellipsis } from '@src/style/common';
import { Anchor } from './Anchor';
import { HorizontalStack } from './HorizontalStack';
import { Tooltip } from './Tooltip';

const StyledTooltip = styled(Tooltip)`
  ${props =>
    props.$ellipsis &&
    `
      ${Ellipsis};
      display: block;
    `}

  ${BaseIcon} {
    margin-right: 6px;
  }
`;

const externalLinkRegexp = /^(https?:)?\/\//;

export const Link = forwardRef(
  (
    {
      url,
      external,
      openInNewTab,
      className,
      onClick,
      tip,
      disabled,
      underline,
      flex,
      ellipsis,
      children,
      id,
      testMarker,
      testValue,
      hover = true,
      preventDefault = true,
      stopPropagation = true,
    },
    ref
  ) => {
    const attributes = { onClick, disabled, id };

    if (url) {
      if (externalLinkRegexp.test(url)) {
        attributes.as = 'a';
        attributes.href = url;
      } else {
        attributes.to = url;
      }
      if (external || openInNewTab) {
        attributes.target = '_blank';
        attributes.rel = 'noopener noreferrer';
      }
    } else {
      attributes.as = 'span';
    }

    if (testMarker) {
      attributes['data-test-marker'] = testMarker;
    }

    if (testValue) {
      attributes['data-value'] = testValue;
    }

    return (
      <StyledTooltip
        $ellipsis={ellipsis}
        tip={
          tip ? (
            <HorizontalStack>
              {external && <SvgIcon name="External" />}
              {tip}
            </HorizontalStack>
          ) : null
        }
        className={className}>
        <Anchor
          ref={ref}
          {...attributes}
          underline={underline && 'true'}
          flex={flex && 'true'}
          hover={hover && 'true'}
          onClick={event => {
            if (disabled) {
              return;
            }

            if (onClick) {
              onClick(event);
            }

            if (stopPropagation) {
              event.stopPropagation();
            }

            if (!url && preventDefault) {
              event.preventDefault();
            }
          }}>
          {children || url}
        </Anchor>
      </StyledTooltip>
    );
  }
);
