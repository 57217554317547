import _ from 'lodash';
import filtersService from '@src/services/filtersService';

export const getSnykVulnerabilitiesFilterOptions = ({ elements }) => {
  const titles = _.uniq(_.map(elements, 'displayName'));
  const dependencies = _.uniq(_.map(elements, 'packageName'));
  const exploitMaturities = _.uniq(_.map(elements, 'exploitMaturity'));

  return [
    {
      name: 'Fixable',
      sortOrder: 1,
      filterOptions: [
        {
          name: 'FixableYes',
          displayName: 'Yes',
        },
        {
          name: 'FixableNo',
          displayName: 'No',
        },
      ],
    },
    {
      name: 'Related Dependency',
      sortOrder: 2,
      filterOptions: [
        {
          name: 'DirectDependency',
          displayName: 'Direct Dependency',
        },
        {
          name: 'SubDependencyOnly',
          displayName: 'Sub Dependency Only',
        },
      ],
    },
    {
      name: 'VulnerabilityExploitMaturity',
      displayName: 'Exploit Maturity',
      sortOrder: 3,
      filterOptions: filtersService.getListFilterOptions(exploitMaturities),
    },
    {
      name: 'VulnerabilityDependency',
      displayName: 'Dependency Name',
      sortOrder: 4,
      filterOptions: filtersService.getListFilterOptions(dependencies),
    },
    {
      name: 'VulnerabilityTitles',
      displayName: 'Vulnerability Title',
      sortOrder: 5,
      filterOptions: filtersService.getListFilterOptions(titles),
    },
  ];
};

export const filterSnykVulnerabilitiesToCondition = {
  FixableYes: snykVulnerability => snykVulnerability.isFixable,
  FixableNo: snykVulnerability => !snykVulnerability.isFixable,
  DirectDependency: snykVulnerability =>
    Object.values(snykVulnerability.isSubDependencyOnlyByRepositoryKey).some(item => !item),
  SubDependencyOnly: snykVulnerability =>
    Object.values(snykVulnerability.isSubDependencyOnlyByRepositoryKey).some(Boolean),
};

export const filterSnykVulnerabilities = (allItems, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, item => [
    item.packageName,
  ]);

  const filterConditions = filters.booleanFilters
    .map(filter => filterSnykVulnerabilitiesToCondition[filter])
    .filter(Boolean);
  if (!_.isEmpty(filterConditions)) {
    filtered = filtered.filter(snykVulnerability =>
      filterOperator(filterConditions, condition => condition(snykVulnerability))
    );
  }

  const maturityFilters = filters.listFilters?.VulnerabilityExploitMaturity;
  if (!_.isEmpty(maturityFilters)) {
    filtered = filtered.filter(vulnerability =>
      filterOperator(
        maturityFilters.map(maturity => _.includes(vulnerability.exploitMaturity, maturity))
      )
    );
  }

  const dependencyFilters = filters.listFilters?.VulnerabilityDependency;
  if (!_.isEmpty(dependencyFilters)) {
    filtered = filtered.filter(vulnerability =>
      filterOperator(dependencyFilters.map(title => _.includes(vulnerability.packageName, title)))
    );
  }

  const titleFilters = filters.listFilters?.VulnerabilityTitles;
  if (!_.isEmpty(titleFilters)) {
    filtered = filtered.filter(vulnerability =>
      filterOperator(titleFilters.map(title => _.includes(vulnerability.displayName, title)))
    );
  }

  return filtered;
};
