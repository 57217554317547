import { useMemo } from 'react';
import { ListPropertiesPanelSection } from '@src/cluster-map-work/components/layout/properties-panel';

export default function ({ clusterResource, highlightsList, maxListItems = 10 }) {
  if (clusterResource.resourceType === 'ClusterIngress') {
    return (
      <>
        <IngressRoutesList
          clusterIngressResource={clusterResource}
          highlightsList={highlightsList}
          maxListItems={maxListItems}
        />
        <IngressExternalAddressesList
          clusterIngressResource={clusterResource}
          highlightsList={highlightsList}
          maxListItems={maxListItems}
        />
      </>
    );
  }

  return [];
}

function IngressRoutesList({ clusterIngressResource, highlightsList, maxListItems }) {
  const routesList = useMemo(
    () =>
      clusterIngressResource.ingressRoutes.map((route, index) => ({
        description: `${route.source} \u{2192} ${route.target}`,
        index: index.toString(),
      })),
    [clusterIngressResource]
  );

  const highlightedRoutes = useMemo(
    () =>
      highlightsList
        .filter(highlight => highlight.propertyName === 'IngressRoutes')
        .map(highlight =>
          routesList.find(route => route.index === highlight.propertyInternalIdRef)
        ),
    [highlightsList, routesList]
  );

  if (!routesList.length) {
    return null;
  }

  return (
    <ListPropertiesPanelSection
      title="Ingress Routes"
      key="ingressRoutes"
      list={routesList}
      itemToString={route => route.description}
      maxListItems={maxListItems}
      highlightedItems={highlightedRoutes}
    />
  );
}

function IngressExternalAddressesList({ clusterIngressResource, highlightsList, maxListItems }) {
  const externalAddressList = useMemo(
    () =>
      clusterIngressResource.externalAddresses.map((address, index) => ({
        description: address,
        index: index.toString(),
      })),
    [clusterIngressResource]
  );

  const highlightedAddresses = useMemo(
    () =>
      highlightsList
        .filter(highlight => highlight.propertyName === 'ExternalAddresses')
        .map(highlight =>
          externalAddressList.find(address => address.index === highlight.propertyInternalIdRef)
        ),
    [highlightsList, externalAddressList]
  );

  if (!externalAddressList.length) {
    return null;
  }

  return (
    <ListPropertiesPanelSection
      title="External Addresses"
      key="ingressExternalAddresses"
      list={externalAddressList}
      itemToString={addr => addr.description}
      maxListItems={maxListItems}
      highlightedItems={highlightedAddresses}
    />
  );
}
