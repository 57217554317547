import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useCallback } from 'react';
import ReactSelectWrapper from '@src/components/ReactSelect/ReactSelectWrapper';

const convertAdditionalPropertiesOption = option => ({
  value: option.key,
  label: option.displayName,
});

const AdditionalPropertiesInput = ({ selectedType, property, disabled, setProperty, index }) => {
  const { additionalProperties } = selectedType;
  const convertOptions = useCallback(options => {
    return options.map(option => convertAdditionalPropertiesOption(option));
  }, []);
  const onChange = useCallback(
    newSelection =>
      setProperty({
        propertyIndex: index,
        type: property.type,
        values: isArray(newSelection) ? map(newSelection, _ => _.value) : [newSelection.value],
      }),
    [setProperty, property, index]
  );
  const { options } = find(additionalProperties, ['key', property.type]) ?? {};
  if (isEmpty(options)) {
    return 'No options have been identified';
  }
  const currentValues = map(property.values, propertyValue =>
    convertAdditionalPropertiesOption(find(options, ['key', propertyValue]) || options[0])
  );
  return (
    <ReactSelectWrapper
      key={index}
      isMulti={false}
      isDisabled={disabled}
      value={currentValues}
      options={convertOptions(options)}
      onChange={onChange}
    />
  );
};

export default AdditionalPropertiesInput;
