import rulesService from './rulesService';

const workflowPeriodNone = 'None';

const workflowPeriodOptions = {
  weekly: 'Weekly',
  monthly: 'Monthly',
  quarterly: 'Quarterly',
};

const workflowNotificationEffectOptions = {
  immediate: 'Immediate',
  periodic: 'Periodic',
};

const shouldResetWorkflowNotificationEffect = rule =>
  rule.given[0].type !== 'Commit' || rule.given[0].subType === 'Any';

const resetWorkflowAdditionalProperties = workflow => ({
  ...workflow,
  workflowNotificationEffect: workflowNotificationEffectOptions.immediate,
  workflowPeriod: workflowPeriodNone,
});

const setOptionWorkflowAdditionalProperties = workflow =>
  shouldResetWorkflowNotificationEffect(workflow)
    ? resetWorkflowAdditionalProperties(workflow)
    : workflow;

const newRule = options => setOptionWorkflowAdditionalProperties(rulesService.newRule(options));

const setOption = (rule, modifications, options) =>
  setOptionWorkflowAdditionalProperties(rulesService.setOption(rule, modifications, options));

const periodicSupportedGivenType = ['MaterialChange', 'Server'];

const isPeriodicWorkflowSupported = workflow =>
  periodicSupportedGivenType.includes(workflow.given[0].type);

const getPeriodicDisableReason = (workflow, isLimited) => {
  const [workflowGiven] = workflow.given;
  if (workflowGiven.type === 'MaterialChange' && isLimited) {
    return 'Upgrade to collect changes over time and trigger periodic workflows';
  }
  if (workflowGiven.type === 'MaterialChange' && workflowGiven.subType === 'Any') {
    return 'Select repositories, projects or applications to define a periodic workflow';
  }
  if (workflowGiven.type !== 'MaterialChange') {
    return 'Coming soon...';
  }
};

export const workflowsService = {
  workflowPeriodNone,
  workflowPeriodOptions,
  workflowNotificationEffectOptions,
  newRule,
  setOption,
  isPeriodicWorkflowSupported,
  getPeriodicDisableReason,
};
