import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import ReactSelectWrapper from '@src/components/ReactSelect/ReactSelectWrapper';

const convertStorageBucketOption = bucket => ({ value: bucket.key, label: bucket.key });

const StorageBucketInput = ({
  propertySettings,
  selectedValue,
  property,
  disabled,
  setProperty,
  index,
}) => {
  const buckets = selectedValue[propertySettings.optionsKey];
  if (isEmpty(selectedValue.buckets) || isEmpty(buckets)) {
    return 'No storage buckets have been identified';
  }
  const currentValue = find(buckets, ['key', property.values[0]]) || buckets[0];
  return (
    <ReactSelectWrapper
      key={index}
      isMulti={false}
      isDisabled={disabled}
      value={convertStorageBucketOption(currentValue)}
      options={buckets.map(module => convertStorageBucketOption(module))}
      onChange={newSelection =>
        setProperty({
          propertyIndex: index,
          type: property.type,
          values: [newSelection.value],
        })
      }
    />
  );
};

export default StorageBucketInput;
