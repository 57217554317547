import { useMemo } from 'react';
import { BadgeColors } from '@src-v2/components/badges';
import { useOverviewFilters } from '@src-v2/components/overview/use-overview-filters';
import { useInject, useSuspense } from '@src-v2/hooks';
import { abbreviate } from '@src-v2/utils/number-utils';

type RiskyScoreTrendData = {
  date: Date;
  count: number;
};

export function useRiskyScoreTrendData(): {
  data: RiskyScoreTrendData[];
  percentageChange: number;
} {
  const { overview } = useInject();
  const { activeFilters: filters = {} } = useOverviewFilters();
  const riskScoreResults = useSuspense(overview.getRiskScoreTrend, {
    filters,
  });

  return useMemo(() => {
    const data = riskScoreResults
      .map(item => ({
        date: new Date(item.date),
        count: item.count,
      }))
      .sort((a, b) => a.date.getTime() - b.date.getTime());

    let percentageChange: number;
    if (data.length < 2) {
      percentageChange = 0;
    } else {
      const firstValue = data[0].count;
      const lastValue = data[data.length - 1].count;
      if (firstValue === 0) {
        percentageChange = lastValue > 0 ? 100 : 0;
      } else {
        percentageChange = ((lastValue - firstValue) / firstValue) * 100;
      }
    }

    return { data, percentageChange };
  }, [riskScoreResults]);
}

export function useRiskyTrendStyle(percentageChange: number): {
  badgeIcon: string;
  trendColor: BadgeColors;
} {
  return useMemo(() => {
    const abbreviatedPercentageChange = parseFloat(abbreviate(percentageChange, 1));

    const trendColor: BadgeColors =
      abbreviatedPercentageChange > 0
        ? BadgeColors.Red
        : abbreviatedPercentageChange < 0
          ? BadgeColors.Green
          : BadgeColors.Blue;

    const badgeIcon =
      abbreviatedPercentageChange > 0
        ? 'TrendUp'
        : abbreviatedPercentageChange < 0
          ? 'TrendDown'
          : 'Minus';

    return { badgeIcon, trendColor };
  }, [percentageChange]);
}
