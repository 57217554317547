import _ from 'lodash';

export class Clusters {
  #client;
  #asyncCache;

  constructor({ apiClient, asyncCache }) {
    this.#client = apiClient;
    this.#asyncCache = asyncCache;
  }

  getClusterList() {
    return this.#client.get('clusters/search');
  }

  getClusterMap({ clusterKey, ns, nodeTypes }) {
    return this.#client.get(`clusters/${clusterKey}/map`, {
      params: { ns, nodeTypes },
    });
  }

  getClusterNamespaces({ clusterKey }) {
    return this.#client.get(`clusters/${clusterKey}/namespaces`);
  }

  getClusterInfo({ clusterKey }) {
    return this.#client.get(`clusters/${clusterKey}/general-info`);
  }

  getClusterOverlayInfo({ clusterKey, resourceName, overlays }) {
    const resourceComposedName = encodeURIComponent(this.resourceNameToString(resourceName));
    return this.#client.get(`clusters/${clusterKey}/resource-overlays/${resourceComposedName}`, {
      params: { overlayNames: overlays },
    });
  }

  getRiskSummaryOverlay({ clusterKey }) {
    return this.#client.get(`clusters/${clusterKey}/cluster-overlays`, {
      params: { overlayNames: ['risk-triggers-summary'] },
    });
  }

  searchGraphNodes({ clusterKey, searchTerm }, options) {
    return this.#client.get(`clusters/${clusterKey}/search-graph-nodes`, {
      ...options,
      params: { searchTerm },
    });
  }

  resourceNameToString(resourceName) {
    return `${resourceName.ns}::${resourceName.typeName}::${resourceName.name}`;
  }

  async getClusterConnection({ repositoryAndModuleReferences = [] }) {
    const uniqueProfileKeys = _.uniq(
      repositoryAndModuleReferences.map(ref => ref.repositoryProfileKey)
    );
    const allProfiles = await Promise.all(
      uniqueProfileKeys.map(repositoryProfileKey =>
        this.#asyncCache.suspend(this.getRepositoryProfile, { repositoryProfileKey })
      )
    );
    const repositoryProfilesMap = _.keyBy(allProfiles, 'key');

    return repositoryAndModuleReferences
      .map(repositoryAndModule => {
        const enrichedObjectRepositoryProfile =
          repositoryProfilesMap[repositoryAndModule.repositoryProfileKey];
        if (!enrichedObjectRepositoryProfile || !repositoryAndModule.moduleRoot) {
          return null;
        }

        const enrichedObjectModule = enrichedObjectRepositoryProfile.modules.find(
          module => module.root === repositoryAndModule.moduleRoot
        );

        return enrichedObjectModule?.clusterMapLinks;
      })
      .concat(allProfiles.map(profile => profile.clusterMapLinks))
      .filter(Boolean);
  }

  async enrichWithClusterConnection(
    enrichedObjects,
    repositoryAndModuleReferencesGetter = obj => obj.repositoryAndModuleReferences
  ) {
    for (const enrichedObject of enrichedObjects) {
      enrichedObject.clusterMapLinksList = await this.#asyncCache.suspend(
        this.getClusterConnection,
        { repositoryAndModuleReferences: repositoryAndModuleReferencesGetter(enrichedObject) }
      );
    }
  }

  async getRepositoryProfile({ repositoryProfileKey }) {
    return await this.#client.get(`repositories/${repositoryProfileKey}/profile`, {});
  }
}
