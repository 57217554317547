import _ from 'lodash';
import { useMemo } from 'react';
import styled, { css } from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { IconButton } from '@src-v2/components/buttons';
import { BaseIcon } from '@src-v2/components/icons';
import { ScrollToTop } from '@src-v2/components/scroll-to-top';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ReportDataIssueModal } from '@src-v2/containers/modals/report-data-issue-modal';
import { useInject, useToggle } from '@src-v2/hooks';
import { Ellipsis, FontBodyBold } from '@src/style/common';
import { HorizontalStack } from './HorizontalStack';
import TooltippedEllipsis from './TooltippedEllipsis';

const EmptyMessage = styled.div`
  padding: 20rem;
  margin-top: 8rem;
  font-weight: 300;
  text-align: center;
`;

export const StyledRow = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 4rem;
  background-color: ${props => (props.$cellsBackground ? 'transparent' : 'var(--color-white)')};
  cursor: ${props => (props.onClick ? 'pointer' : 'unset')};

  ${BaseIcon} {
    color: var(--color-blue-gray-50);

    &:hover {
      color: var(--color-blue-gray-60);
    }
  }
`;

const StyledRowContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
`;

export const StyledTable = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin-top: ${props => (props.small || props.noPadding ? 0 : '8rem')};
`;

const CellStyle = css`
  display: flex;
  width: ${props =>
    props.fixedWidth
      ? `${props.fixedWidth}px`
      : `calc((100% - ${props.totalFixedWidth}px) * ${
          props.totalWeight ? props.weight / props.totalWeight : 1 / props.columnCount
        })`};
  min-height: ${props => (props.small ? 'unset' : '26rem')};
  border-bottom: ${props => (props.small ? '' : '0.25rem solid var(--color-blue-gray-35)')};
`;

export const StyledCell = styled.div`
  ${CellStyle};
  padding-top: 1rem;
  padding-bottom: 1rem;
  cursor: ${props => (props.onClick ? 'pointer' : 'unset')};

  :not(:first-child) {
    padding-left: 1rem;
  }

  :not(:last-child) {
    padding-right: 1rem;
  }
`;

export const StyledCellContents = styled.div`
  display: flex;
  width: 100%;
  padding: 2rem;
  margin: 0 1rem;
  align-items: center;
  justify-content: ${props =>
    props.align === 'right' ? 'flex-end' : props.align === 'center' ? 'center' : 'unset'};
  ${props => !props.allowOverflow && Ellipsis};
`;

export const StyledHeader = styled.div`
  ${CellStyle};
  ${FontBodyBold};
  min-height: ${props => (props.small ? 'unset' : '10rem')};
  flex-flow: ${props => (props.align === 'right' ? 'row-reverse' : 'row')};
  ${props => props.fontWeight === 'thin' && 'font-weight: 300'};
  ${props => props.align === 'center' && 'justify-content: center'};
  padding: ${props => (props.small ? '2rem' : '3rem')};
  background-color: ${props => (props.$headerBackground ? 'var(--color-white)' : 'transparent')};
  box-sizing: border-box;
`;

export const StyledCellContentWrapper = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: ${props => (props.small ? '12rem' : '100%')};
  background-color: ${props =>
    props.$cellsBackground ? 'var(--color-white)' : 'var(--color-white)'};
  box-shadow: ${props => (props.$cellsBackground ? 'var(--elevation-1)' : 'none')};
`;

export const RowMenu = styled(HorizontalStack)`
  right: 0;
  height: 100%;
  position: absolute;

  && > * {
    margin-right: 0;
  }
`;

const StyledRowActions = styled(HorizontalStack)`
  display: none;
  height: 100%;
  padding: 6px;
  justify-content: center;
  background-color: var(--color-white);
  gap: 2rem;

  ${StyledRow}:hover & {
    display: flex;
  }
`;

const RowLoading = styled(LogoSpinner)`
  margin: 8rem auto 0;
`;

const Row = ({
  row,
  indicesToRemove,
  small,
  gridColor,
  headers,
  headersTotalWeight,
  headersTotalFixedWidth,
  onRowClick,
  cellsBackground,
  showReportButton,
}) => {
  const { session } = useInject();
  const [showReportDataModal, reportDataModalToggle] = useToggle();
  const [hovered, toggleHover] = useToggle();
  const cells = useMemo(
    () =>
      row.cells.map((cell, index) => {
        if (
          showReportButton &&
          row.cells.length - 1 === index &&
          session.data.jiraServiceDeskEnabled
        ) {
          const menu = cell.menu ?? [];
          return {
            ...cell,
            menu: [
              <Tooltip content="Report data issue">
                <IconButton
                  name="Report"
                  onClick={event => {
                    event.stopPropagation();
                    reportDataModalToggle();
                  }}
                />
              </Tooltip>,
              ...menu,
            ],
          };
        }
        return cell;
      }),
    [row]
  );

  return (
    <>
      <StyledRow
        $cellsBackground={cellsBackground}
        onClick={onRowClick}
        onMouseEnter={() => toggleHover(true)}
        onMouseLeave={() => toggleHover(false)}>
        <StyledRowContainer>
          {cells.map(
            (cell, index) =>
              !indicesToRemove.includes(index) && (
                <StyledCell
                  key={row.key + index}
                  data-test-marker="table-cell"
                  onClick={cell.onClick}
                  clickable={Boolean(cell.onClick)}
                  small={small}
                  gridColor={gridColor}
                  columnCount={headers.length}
                  weight={headers[index].weight || 1}
                  totalWeight={headersTotalWeight}
                  fixedWidth={headers[index].fixedWidth}
                  totalFixedWidth={headersTotalFixedWidth}>
                  <StyledCellContentWrapper small={small} $cellsBackground={cellsBackground}>
                    {cell.getSideElement && cell.getSideElement(hovered)}
                    <StyledCellContents
                      allowOverflow={cell.allowOverflow}
                      align={cell.align ?? headers[index].align}>
                      {cell.content || (
                        <HorizontalStack>
                          <TooltippedEllipsis text={cell.text} />
                        </HorizontalStack>
                      )}
                      <RowMenu onClick={event => event.stopPropagation()}>
                        {cell.menu ? (
                          <StyledRowActions spacing={0}>
                            {cell.menu.map((menuItem, index) => (
                              <div key={index}>{menuItem}</div>
                            ))}
                          </StyledRowActions>
                        ) : null}
                        {cell.end}
                      </RowMenu>
                    </StyledCellContents>
                  </StyledCellContentWrapper>
                </StyledCell>
              )
          )}
        </StyledRowContainer>
      </StyledRow>
      {showReportDataModal && (
        <ReportDataIssueModal onClose={reportDataModalToggle} row={row} headers={headers} />
      )}
    </>
  );
};

export function Table({
  headers,
  rows,
  small,
  gridColor,
  className,
  tableIsEmpty,
  emptyMessage = 'No results found',
  getRowOnClick,
  noTopPadding = false,
  cellsBackground,
  headerBackground = true,
  showLoadingIcon = false,
  isLoading,
  showReportButton,
}) {
  const indicesToRemove = headers
    .map((header, index) => (header.requiresNonEmpty ? index : null))
    .filter(
      index =>
        !_.isNil(index) && rows.every(row => !row.cells[index].content && !row.cells[index].text)
    )
    .concat(headers.map((header, index) => (header.hide ? index : null)).filter(Boolean));

  const headersTotalWeight = _.sumBy(
    headers.filter((header, index) => !indicesToRemove.includes(index) && header.weight),
    header => header.weight
  );
  const headersTotalFixedWidth = _.sumBy(
    headers.filter((header, index) => !indicesToRemove.includes(index) && header.fixedWidth),
    header => header.fixedWidth
  );

  const isTableEmpty = tableIsEmpty !== undefined ? tableIsEmpty : _.isEmpty(rows);

  return isTableEmpty && emptyMessage ? (
    isLoading ? (
      showLoadingIcon ? (
        <RowLoading />
      ) : null
    ) : (
      <EmptyMessage>{emptyMessage}</EmptyMessage>
    )
  ) : (
    <>
      <StyledTable className={className} small={small} noPadding={noTopPadding}>
        {headers.some(header => header.name) &&
          headers.map(
            (header, index) =>
              !indicesToRemove.includes(index) && (
                <StyledHeader
                  key={header.name || `tableHeader${index}`}
                  small={small}
                  align={header.align}
                  columnCount={headers.length}
                  weight={headers[index].weight || 1}
                  totalWeight={headersTotalWeight}
                  fixedWidth={headers[index].fixedWidth}
                  totalFixedWidth={headersTotalFixedWidth}
                  fontWeight={header.fontWeight}
                  $headerBackground={headerBackground}>
                  <TooltippedEllipsis
                    tip={header.tip || header.name}
                    text={header.name}
                    key={header.name}
                  />
                </StyledHeader>
              )
          )}
        {rows.map((row, index) => (
          <Row
            key={row.key}
            row={row}
            indicesToRemove={indicesToRemove}
            small={small}
            gridColor={gridColor}
            headers={headers}
            headersTotalWeight={headersTotalWeight}
            headersTotalFixedWidth={headersTotalFixedWidth}
            onRowClick={getRowOnClick && getRowOnClick({ row, index })}
            cellsBackground={cellsBackground}
            showReportButton={showReportButton}
          />
        ))}
      </StyledTable>
      <ScrollToTop />
    </>
  );
}
