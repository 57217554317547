import { observer } from 'mobx-react';
import { matchPath, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { sticky } from 'tippy.js';
import { Collapsible } from '@src-v2/components/collapsible';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { NavLink } from '@src-v2/components/typography';
import { useInject, useSessionStorage } from '@src-v2/hooks';

const OptionContainer = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  height: 9rem;
  align-items: center;
  font-weight: 400;
  border-radius: 3rem;
  transition: all 150ms;
  overflow: hidden;
  cursor: pointer;
  gap: 2rem;

  &:hover {
    background-color: var(--color-blue-gray-75);
  }

  ${BaseIcon} {
    margin-left: 1.5rem;
    flex-shrink: 0;
  }
`;

const NavigationTitle = styled.div`
  width: 20rem;
  display: flex;
  align-items: center;
  transition: width 500ms;
  gap: 2rem;
`;

export const NavigationItem = styled.div`
  position: relative;
  display: flex;
  margin: 3rem 0 3rem 4rem;
  padding-right: 2rem;
  color: var(--color-blue-gray-10);
  align-items: center;
  transition: margin 500ms;

  > ${BaseIcon} {
    position: absolute;
    right: 5rem;
    width: 3rem;
    height: 3rem;
    transition: opacity 200ms;
    opacity: 0;
    z-index: 1;
  }

  ${OptionContainer}:hover {
    color: var(--color-blue-gray-10);
  }

  ${Collapsible} {
    width: 100%;

    &[data-empty] ${Collapsible.Chevron} {
      visibility: hidden;
    }
  }

  ${Collapsible.Head} {
    position: relative;

    &:hover ${Collapsible.Chevron} {
      color: var(--color-blue-gray-50);
    }
  }

  ${Collapsible.Title} {
    font: inherit;
    flex-grow: 1;
  }

  ${Collapsible.Chevron} {
    position: absolute;
    right: 2rem;
    color: var(--color-blue-gray-40);
    pointer-events: none;
  }

  ${Collapsible.Body} {
    padding-left: 7rem;
  }

  &.is-active {
    ${OptionContainer} {
      color: var(--color-green-45);
      background-color: var(--color-blue-gray-75);
    }

    ${Collapsible.Head}:hover ${Collapsible.Chevron},
    ${Collapsible.Chevron} {
      color: var(--color-green-45);
    }

    ${Collapsible}[data-open]:not([data-empty]) {
      ${OptionContainer} {
        color: var(--color-blue-gray-10);
        background-color: transparent;
      }

      ${Collapsible.Chevron} {
        color: var(--color-blue-gray-10);
      }
    }
  }

  &[data-docked] {
    margin-left: 7rem;
    transition: margin 500ms;

    ${OptionContainer} {
      width: 9rem;

      ${NavigationTitle} {
        width: 0;
        overflow: hidden;
      }
    }
  }
`;

NavigationItem.Label = styled(
  observer(({ title, icon, route, isActive, isDocked, children, className, style }) => {
    const to = Array.isArray(route) ? route[0] : route;
    if (isActive && typeof isActive !== 'function') {
      isActive = () => isActive;
    }
    const markerTitle = title.split(' ').join('');

    return (
      <OptionContainer
        data-test-marker={
          to ? `sidebar-option-${markerTitle}` : `sidebar-collapsible-${markerTitle}`
        }
        className={className}
        style={style}
        as={to ? NavLink : 'div'}
        to={to}
        isActive={to && isActive}>
        <Tooltip content={title} disabled={!isDocked} placement="right">
          <SvgIcon name={icon} />
        </Tooltip>
        <NavigationTitle>
          {title}
          {children}
        </NavigationTitle>
      </OptionContainer>
    );
  })
)``;

export const NavigationSubItem = observer(
  ({ parentItem, title, tooltip, route, exact, strict, isNew, ...props }) => {
    const location = useLocation();
    const { application } = useInject();
    const [dismissTooltip, setDismissTooltip] = useSessionStorage(`sidebar-tooltip`, false);

    const to = Array.isArray(route) ? route[0] : route;
    const tooltipContent = tooltip?.(application) ?? false;
    const isActive = (match, location) =>
      match || matchPath(location.pathname, { path: route, exact, strict });
    const markerTitle = title.replace(/\s/g, '');

    return (
      <SubOptionContainer {...props}>
        <Tooltip
          interactive
          plugins={[sticky]}
          content={
            <TooltipContainer>
              {tooltipContent}
              <SvgIcon
                name="Close"
                onClick={() => {
                  setDismissTooltip(true);
                }}
              />
            </TooltipContainer>
          }
          visible={tooltipContent && !isActive(null, location) && !dismissTooltip}
          placement="right"
          sticky>
          <NavLink
            data-test-marker={`sidebar-option-${markerTitle}`}
            to={to}
            isActive={isActive}
            exact={exact}
            strict={strict}>
            {title}
            {isNew && <NewTag>New</NewTag>}
          </NavLink>
        </Tooltip>
      </SubOptionContainer>
    );
  }
);

const SubOptionContainer = styled.div`
  position: sticky;
  bottom: 0;
  margin-top: 1rem;
  transition: transform 400ms;

  ${Collapsible}:not([data-open]) & {
    transform: translateY(-100%);

    ${NavLink}.active {
      background-color: var(--color-blue-gray-75);
    }
  }

  ${NavLink} {
    display: flex;
    gap: 2rem;
    align-items: center;
    padding: 0 4rem;
    line-height: 9rem;
    border-radius: 3rem;
    transition: all 150ms;
    font-size: var(--font-size-s);
    font-weight: 300;
    color: var(--color-blue-gray-35);
    background-color: var(--color-blue-gray-70);

    &:hover {
      color: var(--color-blue-gray-10);
      background-color: var(--color-blue-gray-75);
    }

    &.active {
      color: var(--color-green-45);
      background-color: var(--color-blue-gray-75);
      font-weight: 400;
    }
  }
`;

export const NewTag = styled.div`
  line-height: 5rem;
  font-size: var(--font-size-xs);
  color: var(--color-green-45);
  padding: 0.25rem 1rem;
  border: 0.25rem solid var(--color-green-45);
  border-radius: 2rem;
  background-color: transparent;
`;

const TooltipContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
`;
