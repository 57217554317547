import _ from 'lodash';
import { observer } from 'mobx-react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AvatarStack } from '@src-v2/components/avatar';
import { TextButton } from '@src-v2/components/button-v2';
import { Circle } from '@src-v2/components/circles';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { Heading1 } from '@src-v2/components/typography';
import { useSuspense } from '@src-v2/hooks';
import { preventDefault } from '@src-v2/utils/dom-utils';
import { abbreviate } from '@src-v2/utils/number-utils';

export const ContributorsContent = observer(({ profileType = null, dataFetcher }) => {
  const { key } = useParams();
  const { total, linkTo, recentlyActive, recentlyJoined, securityRelated } = useSuspense(
    dataFetcher,
    { profileType, key }
  );

  return (
    <>
      <CardLink to={linkTo}>
        <Heading1>{_.isFinite(total) ? abbreviate(total) : total ?? 0} Contributors</Heading1>
        <CallToAction>
          <TextButton underline showArrow size="xsmall" onClick={preventDefault}>
            View all contributors
          </TextButton>
        </CallToAction>
      </CardLink>

      <Details>
        <AvatarDetails contributorsDetail={recentlyActive}>Active contributors</AvatarDetails>
        <AvatarDetails contributorsDetail={recentlyJoined}>
          Recently joined &middot; last 90d
        </AvatarDetails>
        <AvatarDetails contributorsDetail={securityRelated}>Security contributors</AvatarDetails>
      </Details>
    </>
  );
});

function AvatarDetails({ contributorsDetail, children }) {
  return (
    <AvatarLink to={contributorsDetail.linkTo}>
      <AvatarStack identities={contributorsDetail.samples}>
        {contributorsDetail.count > contributorsDetail.samples?.length && (
          <AdditionalItemsCircle>
            <SvgIcon name="Dots" />
          </AdditionalItemsCircle>
        )}
      </AvatarStack>
      <Label>
        {abbreviate(contributorsDetail.count)} {children}
      </Label>
    </AvatarLink>
  );
}

const CardLink = styled(Link)`
  flex-grow: 1;
`;

const Details = styled.div`
  text-align: right;
`;

const AvatarLink = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &:not(:last-of-type) {
    margin-bottom: 4rem;
  }
`;

const AdditionalItemsCircle = styled(Circle)`
  background-color: var(--color-blue-gray-70);
  color: var(--color-white);
  border: 0.4rem solid var(--color-white);

  ${BaseIcon} {
    transform: rotate(90deg);
  }
`;

const Label = styled.p`
  margin-top: 1rem;
  line-height: 6rem;
  white-space: nowrap;

  ${AvatarLink}:hover & {
    text-decoration: underline;
  }
`;

const CallToAction = styled.div`
  display: none;
  height: 4rem;
  margin-top: 5rem;

  ${CardLink}:hover & {
    display: inline-block;
  }
`;
