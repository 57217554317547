import _ from 'lodash';
import styled from 'styled-components';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { getRiskColor } from '@src-v2/data/risk-data';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';

export const RiskIndicator = styled.div`
  display: flex;
  width: 2rem;
  padding: 0 1rem;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  background-color: ${getRiskColor};
  transition: width 100ms ease-out;

  &[data-selected] {
    width: 12rem;
    --icon-scale: 1;
  }

  ${BaseIcon} {
    width: 7.5rem;
    height: 7.5rem;
    transform: scale(var(--icon-scale, 0));
    transition: transform 100ms ease-out;

    + ${BaseIcon} {
      margin-left: -5rem;
    }
  }
`;

export default function OpenPaneIndicator({ riskLevel, paneLevel, selected }) {
  return (
    <RiskIndicator data-selected={dataAttr(selected)} riskLevel={_.camelCase(riskLevel)}>
      <SvgIcon name="Chevron" />
      {paneLevel === secondaryPaneLevel && <SvgIcon name="Chevron" />}
    </RiskIndicator>
  );
}
