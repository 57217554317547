import { connect } from 'react-redux';
import { select } from '@src/store';
import { ElementTimelineSelector } from './view';

const mapState = select(
  ({ elementTimelineSelector: { repositoryProfiles, selectedRepositoryProfile } }) => ({
    repositoryProfiles,
    selectedRepositoryProfile,
    isLoadingPage: state => state.loading.effects.elementTimelineSelector.fetchData,
    isLoadingRepository: state =>
      state.loading.effects.elementTimelineSelector.setSelectedRepositoryProfile,
  })
);

const mapDispatch = ({
  elementTimelineSelector: { fetchData, clearData, setSelectedRepositoryProfile },
}) => ({
  fetchData,
  clearData,
  setSelectedRepositoryProfile,
});

export default connect(mapState, mapDispatch)(ElementTimelineSelector);
