import { TicketingProviders } from '@src-v2/data/ticketing-issues-provider';

export const bulkActionModes = {
  AGGREGATED: 'AGGREGATED',
  SEPARATE: 'SEPARATE',
};

export function getActionTextConfig(provider) {
  return Object.keys(TicketingProviders).includes(provider)
    ? {
        entityName: 'issue',
        actionName: 'create',
      }
    : {
        entityName: 'message',
        actionName: 'send',
      };
}
