import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { openExternalGeneralFindingPane } from '@src/components/Panes/ExternalGeneralFindingPane';
import { EllipsisDiv } from '@src/style/common';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getExternalGeneralFindingTriggerDisplay = ({ trigger, ...options }) => [
  {
    weight: 3,
    content: (
      <PaneLocationLink
        id={trigger.key}
        onClick={args => openExternalGeneralFindingPane({ ...args, trigger, ...options })}>
        <TriggerInfoContainer
          id={trigger.key}
          title={addInterpunctSeparator(trigger.sourceSystemId, trigger.type)}
          insights={trigger.insights}>
          <HorizontalStack withSeparator>
            <EllipsisDiv>{trigger.description}</EllipsisDiv>
          </HorizontalStack>
        </TriggerInfoContainer>
      </PaneLocationLink>
    ),
  },
];

export const getExternalGeneralFindingMessageFormat = element => element.description;
