export const resourceTypes = {
  Connectors: 'Connectors',
  Global: 'Global',
  Governance: 'Governance',
  GovernancePolicies: 'GovernancePolicies',
  GovernanceDefinition: 'GovernanceDefinition',
  Products: 'Products',
  Risk: 'Risk',
  Roles: 'Roles',
  Workflows: 'Workflows',
  Teams: 'Teams',
  // not yet implemented
  CustomFilters: 'CustomFilters',
  Definitions: 'Definitions',
  MaterialChanges: 'MaterialChanges',
  Releases: 'Releases',
  Questionnaires: 'Questionnaires',
  ExplorerFavorites: 'ExplorerFavorites',
  GitLabResources: 'GitLabResources',
  NetworkBrokerConfigurations: 'NetworkBrokerConfigurations',
  RiskLevel: 'RiskLevel',
  RiskStatus: 'RiskStatus',
  RiskMiscellaneous: 'RiskMiscellaneous',
  ManualFindings: 'ManualFindings',
  JiraCloudApplication: 'JiraCloudApplication',
  AccessTokens: 'AccessTokens',
};
