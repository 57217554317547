import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { ProcessTag } from '@src-v2/components/tags';
import { Paragraph } from '@src-v2/components/typography';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { csvFromArray, csvMapper } from '@src-v2/utils/csv-utils';
import { downloadFile } from '@src-v2/utils/dom-utils';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { primaryPaneLevel } from '@src/blocks/Pane/model';
import { riskPanePrefix } from '@src/blocks/RiskPosture/blocks/TriggersDisplay/Common';
import { ActionResults } from '@src/blocks/RiskRuleTriggers/blocks/ActionResults';
import { isAssetCollectionProfile } from '@src/components/CodeAggregation/InventoryUtils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { StyledRow, Table } from '@src/components/Table';
import { Tooltip } from '@src/components/Tooltip';
import { Ellipsis } from '@src/style/common';
import { TRIGGER_TYPE_TO_ELEMENT_NAME_GETTER } from './DetailsPane/TriggerDataUtils';
import OpenPaneIndicator from './OpenPaneIndicator';
import { RiskSource } from './RiskSource';
import { StyledRiskIcon } from './styles';
import { isIgnored } from './utils';

export const StyledManageRisk = styled.div`
  margin-bottom: 10rem;

  ${StyledRow} {
    padding: 0 2rem;
  }

  > ${Paragraph} {
    margin: 4rem 2rem;

    &:first-child {
      display: flex;
      height: 9rem;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

export const StyledRuleName = styled.div`
  line-height: 6rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const StyledRule = styled(HorizontalStack)`
  width: 100%;
  opacity: ${props => (props.ignored ? 0.5 : '1')};
`;

export const StylePullRequest = styled.span`
  opacity: 0.7;
`;

const TagsDiv = styled(HorizontalStack)`
  ${Ellipsis};
  height: 30px;
`;

const StyledTooltip = styled(Tooltip)`
  height: inherit;
`;

const ignoredTypeToString = posture => {
  for (const when of posture.rule.when) {
    const whenType = when.type;
    if (whenType === 'InputValidation') {
      return 'API level input validation';
    }
    if (whenType === 'Authorization') {
      return 'API level authorization';
    }
  }
};

const ignoredTypeToUsage = (posture, statistics) => {
  for (const when of posture.rule.when) {
    const whenType = when.type;
    if (whenType === 'InputValidation') {
      return `${statistics.missingValidationApisCount}/${statistics.validationApplicableApisCount}`;
    }
    if (whenType === 'Authorization') {
      return `${statistics.missingAuthorizationApisCount}/${statistics.authorizationApplicableApisCount}`;
    }
  }
};

export const ManageRiskTable = ({
  profile,
  description = '',
  profileRiskTriggers,
  demoPullRequestBased,
  onRiskDetailsClickCallback,
  primaryPaneId,
  profileType,
  isRelease,
  tableTitle,
}) => {
  const { url: basePath } = useRouteMatch();
  const ruleRouteMatch = useRouteMatch(`${basePath}/${riskPanePrefix}/:ruleKey`);
  const { application, toaster } = useInject();
  const extendedRiskEnabled = application.isFeatureEnabled(FeatureFlag.ExtendedRiskPage);

  useEffect(() => {
    if (ruleRouteMatch?.params.ruleKey) {
      const ruleTriggers = profileRiskTriggers.find(
        triggers => triggers.ruleKey === ruleRouteMatch?.params.ruleKey
      );
      if (_.isNil(ruleTriggers) || _.isEmpty(ruleTriggers.triggers)) {
        toaster.success("Congrats! This risk doesn't exist anymore.");
      } else {
        onRiskDetailsClickCallback?.(ruleTriggers, basePath);
      }
    }
  }, [basePath, ruleRouteMatch?.params.ruleKey, onRiskDetailsClickCallback, profileRiskTriggers]);

  const shouldDisplayEmptyTriggers = isRelease || extendedRiskEnabled;

  const filteredRiskTriggers = useMemo(
    () =>
      profileRiskTriggers.filter(
        ruleTriggers => shouldDisplayEmptyTriggers || ruleTriggers.count > 0
      ),
    [profileRiskTriggers, shouldDisplayEmptyTriggers]
  );

  const downloadCsvFunction = useCallback(() => {
    if (application.isDemo) {
      return;
    }

    const downloadCsv = {
      title: `${profile.name} compliance report`,
      profile,
      columnMap: {
        Rule: 'rule.name',
        Count: 'count',
        Link: trigger =>
          `=HYPERLINK("${window.location.href}/${riskPanePrefix}/${trigger.ruleKey}", "View in Apiiro")`,
      },
    };

    const filename = _.kebabCase(downloadCsv.title.toLowerCase());
    const csvArray = csvMapper(profileRiskTriggers, downloadCsv.columnMap);
    csvArray.unshift([
      `${downloadCsv.title} (${new Date().toLocaleDateString()})`,
      `=HYPERLINK("${window.location.href}", "For the most updated version click here")`,
    ]);
    downloadFile(`${filename}.csv`, csvFromArray(csvArray), 'text/csv');
  }, [profile, profileRiskTriggers]);
  return (
    <StyledManageRisk>
      <Paragraph>
        {description}
        {extendedRiskEnabled && <Button onClick={downloadCsvFunction}>Export</Button>}
      </Paragraph>
      <Table
        noTopPadding
        small
        cellsBackground
        headerBackground={false}
        headers={[
          { name: tableTitle ?? 'Findings', weight: 8, fontWeight: 'thin' },
          {
            name: '% Components',
            fixedWidth: 95,
            align: 'center',
            fontWeight: 'thin',
            hide: isRelease,
          },
          {
            name: demoPullRequestBased ? 'Pull Request' : 'Source',
            weight: 1.5,
            align: 'center',
            fontWeight: 'thin',
            hide: isRelease,
          },
          { name: 'Process', weight: 3, align: 'center', fontWeight: 'thin', hide: isRelease },
          { name: 'New', fixedWidth: 80, align: 'center', fontWeight: 'thin', hide: !isRelease },
          {
            name: 'Existing',
            fixedWidth: 80,
            align: 'center',
            fontWeight: 'thin',
            hide: !isRelease,
          },
          {
            name: 'Resolved',
            fixedWidth: 80,
            align: 'center',
            fontWeight: 'thin',
            hide: !isRelease,
          },
          { name: 'Risk', fixedWidth: 54, align: 'center', fontWeight: 'thin' },
        ]}
        rows={filteredRiskTriggers.map(ruleTriggers => {
          const ignored = isIgnored(ruleTriggers);
          const ignoredMessage = ignored
            ? `Auto-ignored due to low usage of ${ignoredTypeToString(
                ruleTriggers
              )} (${ignoredTypeToUsage(ruleTriggers, profile.statistics)})`
            : '';

          const ruleName = ruleTriggers.rule.name ?? `Rule ${ruleTriggers.rule.ordinalId}`;
          const {
            riskyViolations,
            attackSurfaceCount,
            riskyPercentage,
            newTriggers,
            existingTriggers,
            resolvedTriggers,
          } = ruleTriggers;
          const [firstTrigger] = ruleTriggers.triggers;
          let attackSurfaceDisplayName =
            firstTrigger &&
            TRIGGER_TYPE_TO_ELEMENT_NAME_GETTER[firstTrigger.elementType ?? firstTrigger.type];
          if (
            attackSurfaceDisplayName?.one === 'repository' &&
            !isAssetCollectionProfile(profileType)
          ) {
            attackSurfaceDisplayName = null;
          }

          const triggerTakenActions = ruleTriggers.triggers.reduce(
            (result, trigger) => ({
              sentMessages: [...result.sentMessages, ...(trigger.sentMessages ?? [])],
              createdIssues: [...result.createdIssues, ...(trigger.createdIssues ?? [])],
            }),
            {
              sentMessages: [],
              createdIssues: [],
            }
          );
          const riskLevel =
            ruleTriggers.count === 0 ? 'Compliant' : ruleTriggers.ruleRiskLevel ?? 'None';

          return {
            key: ruleTriggers.rule.ordinalId,
            cells: [
              {
                content: (
                  <StyledRule ignored={ignored} riskLevel={ruleTriggers.riskLevel}>
                    <StyledRuleName>{ruleName}</StyledRuleName>
                  </StyledRule>
                ),
                end: (
                  <ActionsResultsPopover
                    actionsTaken={triggerTakenActions}
                    riskLevel={riskLevel}
                    pluralAttackSurfaceFormat={count =>
                      pluralFormat(
                        count,
                        attackSurfaceDisplayName?.one ?? 'risk',
                        attackSurfaceDisplayName?.many ?? 'risks'
                      )
                    }
                  />
                ),
                getSideElement: hovered => (
                  <OpenPaneIndicator
                    paneLevel={primaryPaneLevel}
                    riskLevel={riskLevel}
                    selected={hovered || primaryPaneId === ruleTriggers.rule.ordinalId}
                  />
                ),
                onClick:
                  ruleTriggers.count > 0 && onRiskDetailsClickCallback
                    ? event => {
                        event.stopPropagation();
                        onRiskDetailsClickCallback(ruleTriggers, basePath);
                      }
                    : null,
              },
              {
                content: riskyPercentage && attackSurfaceDisplayName && (
                  <Tooltip
                    tip={
                      <span>
                        {riskyViolations}/
                        <Number
                          value={attackSurfaceCount}
                          one={`${attackSurfaceDisplayName?.one} is at risk`}
                          other={`${attackSurfaceDisplayName?.many} are at risk`}
                        />
                      </span>
                    }>
                    {' '}
                    {riskyPercentage}%
                  </Tooltip>
                ),
              },
              {
                content: demoPullRequestBased ? (
                  <StylePullRequest>{`#${ruleTriggers.pullRequest}`}</StylePullRequest>
                ) : (
                  <RiskSource
                    rule={ruleTriggers.rule}
                    profile={profile}
                    triggers={ruleTriggers.triggers}
                  />
                ),
                align: 'left',
              },
              {
                content: !_.isEmpty(ruleTriggers.rule.processTags) && (
                  <TagsDiv spacing={0.4}>
                    <ProcessTag>{ruleTriggers.rule.processTags[0].name}</ProcessTag>
                    {ruleTriggers.rule.processTags.length > 1 && (
                      <StyledTooltip
                        tip={ruleTriggers.rule.processTags.splice(1).map(tag => (
                          <div key={tag.name}>{tag.name}</div>
                        ))}>
                        <ProcessTag>+{ruleTriggers.rule.processTags.length - 1}</ProcessTag>
                      </StyledTooltip>
                    )}
                  </TagsDiv>
                ),
              },
              { text: newTriggers },
              { text: existingTriggers },
              { text: resolvedTriggers },
              {
                content: (
                  <StyledRiskIcon riskLevel={_.capitalize(riskLevel)} tip={ignoredMessage} />
                ),
              },
            ],
          };
        })}
      />
    </StyledManageRisk>
  );
};

const ActionsResultsPopover = styled(ActionResults)`
  padding: 0 3rem;
`;
