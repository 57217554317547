import { cloneElement } from 'react';
import { VerticalStack } from '@src/components/VerticalStack';
import { StyledInsightsTitle } from './InsightStyles';

export const Highlights = ({ highlights }) =>
  highlights.length > 0 && (
    <VerticalStack>
      <StyledInsightsTitle>Highlights</StyledInsightsTitle>
      <VerticalStack>
        {highlights.map((highlight, index) => cloneElement(highlight, { key: index }))}
      </VerticalStack>
    </VerticalStack>
  );
