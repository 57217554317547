import { useCallback, useEffect } from 'react';
import { Redirect, Route, Switch, useHistory, useRouteMatch } from 'react-router-dom';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Gutters } from '@src-v2/components/layout';
import { ProfilesProvider } from '@src-v2/components/profiles-inject-context';
import { FORM_SECTIONS } from '@src-v2/containers/applications/application-form';
import { InventoryV2 } from '@src-v2/containers/inventory/inventory-v2';
import NewApplicationPage from '@src-v2/containers/pages/application-page';
import SpacetimeGraphPage from '@src-v2/containers/pages/spacetime-graph-page';
import { ApplicationOverview } from '@src-v2/containers/profiles/profile-overview';
import { useInject, useToggle } from '@src-v2/hooks';
import { MonitorAllBanner } from '@src/blocks/AssetCollectionPage/blocks/MonitorAllBanner';
import { RisksTabSwitch } from '@src/blocks/RiskPosture/risks-tab-switch';
import TimelineInfiniteScroll from '@src/blocks/TimelineInfiniteScroll';
import { PageLoading } from '@src/components/PageLoading';
import profilesService from '@src/services/profilesService';

const AssetCollectionPage = ({
  fetchData,
  clearData,
  hasFetchedData,
  unmonitored,
  assetCollectionProfile,
  monitorAllAssetCollectionConsumables,
  getOrganizationProfileAsync,
}) => {
  const {
    path: basePath,
    url: baseUrl,
    params: { key },
  } = useRouteMatch();
  const { applicationProfiles, asyncCache, profiles, applicationProfilesV2 } = useInject();
  const [showDeleteModal, toggleDeleteModal] = useToggle();
  const history = useHistory();

  const { profileType, profileTypePlural } =
    profilesService.getProfileDefinition('assetCollections');

  useEffect(() => {
    fetchData({ key, invalidateCache: false });
    return clearData;
  }, [fetchData, key, clearData]);

  const deleteAssetCollection = useCallback(async () => {
    await applicationProfiles.delete(key);
    asyncCache.invalidateAll(profiles.searchProfiles);
    history.push('/profiles/applications');
    await getOrganizationProfileAsync(true);
  }, [key]);

  const refetchData = useCallback(() => fetchData({ key, invalidateCache: true }), [key]);

  const onEditProductClick = useCallback(() => {
    history.push(`/profiles/applications/${key}/edit`, {
      activeSection: assetCollectionProfile?.isModuleBased
        ? FORM_SECTIONS.MODULES
        : FORM_SECTIONS.MULTIPLE,
      configuration: assetCollectionProfile?.configuration,
    });
  }, [assetCollectionProfile, key]);

  if (!hasFetchedData) {
    return <PageLoading />;
  }

  return (
    <ProfilesProvider value={applicationProfilesV2}>
      {showDeleteModal && (
        <ConfirmationModal
          title={`Delete ${assetCollectionProfile?.name}?`}
          submitStatus="failure"
          submitText="Delete"
          onClose={toggleDeleteModal}
          onSubmit={deleteAssetCollection}>
          Are you sure you want to delete this application?
        </ConfirmationModal>
      )}

      <NewApplicationPage onEdit={onEditProductClick} onRemove={toggleDeleteModal}>
        <Switch>
          <Route path={`${basePath}/profile`}>
            <Gutters>
              <MonitorAllBanner
                monitorAll={() => monitorAllAssetCollectionConsumables(key).then(refetchData)}
                profileKey={key}
                unmonitored={unmonitored}
              />
            </Gutters>
            <ApplicationOverview />
          </Route>
          <Route path={`${basePath}/risk`}>
            <Gutters>
              <RisksTabSwitch profile={assetCollectionProfile} />
            </Gutters>
          </Route>
          <Route path={`${basePath}/timeline`}>
            <Gutters>
              <TimelineInfiniteScroll entityKey={key} table={profileTypePlural} />
            </Gutters>
          </Route>
          <Route path={`${basePath}/inventory`}>
            <Gutters>
              <InventoryV2 profile={assetCollectionProfile} profileType={profileType} />
            </Gutters>
          </Route>
          <Route path={`${basePath}/graph`}>
            <AsyncBoundary>
              <SpacetimeGraphPage />
            </AsyncBoundary>
          </Route>
          <Redirect to={`${baseUrl}/profile`} />
        </Switch>
      </NewApplicationPage>
    </ProfilesProvider>
  );
};
export default AssetCollectionPage;
