import { useMemo } from 'react';
import styled from 'styled-components';
import { SimpleTable } from '@src-v2/components/simple-table';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { getLanguageDisplayName, isIgnoredLanguage } from '@src-v2/data/languages';
import {
  ProfileWidget,
  ProfileWidgetLabel,
} from '@src/blocks/ConsumableInfiniteScroll/blocks/ConsumableProfileWidget';
import { LanguageStack } from '@src/components/LanguageIcon';

const TopLanguagesWidget = ({ languagePercentages, maxSize = 3, isModuleBased = false }) => {
  const remainingLanguagesCount = useMemo(
    () => languagePercentages.length - maxSize,
    [languagePercentages]
  );

  const languagesList = useMemo(
    () =>
      languagePercentages
        ?.filter(({ key }) => !isIgnoredLanguage(key))
        .slice(0, maxSize)
        .map(({ key, value }) => [`${value}%`, getLanguageDisplayName(key)]),
    [languagePercentages]
  );

  return (
    <Tooltip
      content={
        isModuleBased ? (
          'Not calculated for monorepo applications'
        ) : languagesList.length ? (
          <>
            <SimpleTable items={languagesList} />
            {remainingLanguagesCount > 0 && (
              <Paragraph>and {remainingLanguagesCount} more</Paragraph>
            )}
          </>
        ) : (
          'No languages identified'
        )
      }>
      <ProfileWidget>
        <LanguageStack languages={languagePercentages} />
        <ProfileWidgetLabel>Top Languages</ProfileWidgetLabel>
      </ProfileWidget>
    </Tooltip>
  );
};

const Paragraph = styled.div`
  margin-top: 4px;
  font-weight: 200;
`;

export default TopLanguagesWidget;
