import { connect } from 'react-redux';
import { select } from '@src/store';
import ServerTableInfiniteScroll from './view';

const mapState = select(
  ({
    serverTableInfiniteScroll: { currentTableScope, itemsCount, items },
    tableSearch: { filters, searchTerm },
  }) => ({
    currentTableScope,
    itemsCount,
    items,
    filters,
    searchTerm,
  })
);

const mapDispatch = ({
  serverTableInfiniteScroll: { fetchData, fetchPage, onFilterChange, clearData },
  tableSearch: { initializeFromQueryString },
}) => ({
  fetchData,
  fetchPage,
  onFilterChange,
  clearData,
  initializeFromQueryString,
});

export default connect(mapState, mapDispatch)(ServerTableInfiniteScroll);
