import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { EllipsisDiv } from '@src/style/common';
import { getTrimmedPath } from '@src/utils/stringDisplayUtils';
import { TriggerInfoContainer } from './Common';

export const getProtobufMessageTriggerDisplay = ({ trigger }) => [
  {
    weight: 3,
    content: (
      <TriggerInfoContainer
        id={trigger.key}
        title={getTrimmedPath(trigger.codeReference.relativeFilePath)}
        insights={trigger.insights}>
        <HorizontalStack withSeparator>
          <EllipsisDiv>{trigger.displayName}</EllipsisDiv>
          <Number value={trigger.count} one="field" other="fields" />
        </HorizontalStack>
      </TriggerInfoContainer>
    ),
  },
];

export const getProtobufMessageMessageFormat = element => element.codeReference.relativeFilePath;
