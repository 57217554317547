import { connect } from 'react-redux';
import { select } from '@src/store';
import GitReferencesAutoComplete from './view';

const mapState = select(() => ({}));

const mapDispatch = ({ gitReferencesAutoComplete: { searchCommitish } }) => ({
  searchCommitish,
});

export default connect(mapState, mapDispatch)(GitReferencesAutoComplete);
