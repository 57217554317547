import Consumables from './Consumables';

const Projects = ({ projects, serverIconOnly, noLink }) => (
  <Consumables
    consumables={projects}
    single="project"
    plural="projects"
    serverIconOnly={serverIconOnly}
    noLink={noLink}
  />
);

export default Projects;
