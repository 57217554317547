import { animated } from '@react-spring/web';
import { format } from 'date-fns';
import styled from 'styled-components';
import { UpgradeButtonV2 } from '@src-v2/components/marketing/upgrade-button';
import { useInject } from '@src-v2/hooks';

export const TrialBanner = styled(
  ({ label = 'Upgrade', noIntroText, onClick, children, ...props }) => {
    const { subscription } = useInject();

    return (
      <animated.div {...props}>
        <TrialBanner.Content>
          {!noIntroText && `Trial - Expires on ${format(subscription.expirationDate, 'PP')}`}
          {children}
        </TrialBanner.Content>
        <TrialBanner.Actions>
          <UpgradeButtonV2 onClick={onClick}>{label}</UpgradeButtonV2>
        </TrialBanner.Actions>
      </animated.div>
    );
  }
)`
  display: flex;
  padding: 2rem 4rem;
  align-items: center;
  justify-content: space-between;
  border-radius: 3rem;
  gap: 6rem;
  background-color: var(--color-purple-25);
  z-index: 9999999999;
`;

TrialBanner.Actions = styled.div``;

TrialBanner.Content = styled.div`
  color: var(--color-purple-60);
  font-weight: 600;
`;
