import apiService from '@src/services/apiService';

const clearData = () => ({
  release: null,
  materialChanges: null,
  isMonitored: false,
  repository: null,
  repositoryProfile: null,
});

export default {
  state: clearData(),
  reducers: {
    clearData,
    setBulkState: (
      state,
      { release, materialChanges, isMonitored, repository, repositoryProfile }
    ) => ({
      ...state,
      release,
      materialChanges,
      isMonitored,
      repository,
      repositoryProfile,
    }),
  },
  selectors: slice => ({
    releaseKey: () => (rootState, props) => props.match.params.key,
    release: () => slice(state => state.release),
    materialChanges: () => slice(state => state.materialChanges),
    isMonitored: () => slice(state => state.isMonitored),
    repositoryProfile: () => slice(state => state.repositoryProfile),
    repository: () => slice(state => state.repository),
  }),
  effects: dispatch => ({
    async fetchData(releaseKey) {
      const [releaseWithMaterialChanges] = await Promise.all([
        apiService.get(`/api/releases/${releaseKey}`, { clearCacheEntry: true }),
        dispatch.governanceRules.fetchRules({ invalidateCache: false }),
      ]);
      const { release, materialChanges, isMonitored } = releaseWithMaterialChanges.data;

      let repository, repositoryProfile;
      if (isMonitored) {
        [repositoryProfile] = await Promise.all([
          dispatch.repositoryProfiles.getRepositoryProfileAsync({ key: release.repositoryKey }),
          materialChanges &&
            materialChanges.commitData &&
            dispatch.developerProfiles.getDeveloperByIdentityKeyAsync({
              key: materialChanges.commitData.authorIdentityKey,
            }),
        ]);
        repository = repositoryProfile.repository;
      } else {
        repository = (await apiService.get(`/api/repositories/${release.repositoryKey}`)).data;
      }

      this.setBulkState({ release, materialChanges, isMonitored, repository, repositoryProfile });
    },
  }),
};
