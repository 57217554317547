import { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Avatar } from '@src-v2/components/avatar';
import { Gutters, StickyHeader } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import TimelineInfiniteScroll from '@src/blocks/TimelineInfiniteScroll';
import Frameworks from '@src/components/Frameworks';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { PageLoading } from '@src/components/PageLoading';
import Profile from '@src/components/RiskProfiles/Profile';
import { getLanguagesStatistics } from '@src/components/RiskProfiles/ProfileStatistics';
import { VerticalStack } from '@src/components/VerticalStack';
import Projects from './blocks/Projects';
import Repositories from './blocks/Repositories';

export default function DeveloperPage({ hasFetchedData, fetchData, clearData, profileGetter }) {
  const {
    path: basePath,
    params: { key },
  } = useRouteMatch();
  const profile = profileGetter(key);

  useEffect(() => {
    fetchData({ key, invalidateCache: false });
    return clearData;
  }, [fetchData, key, clearData]);

  if (!hasFetchedData) {
    return <PageLoading />;
  }

  if (!profile) {
    return <Redirect to="/users/contributors" />;
  }

  const developerPageUrl = `/users/contributors/${key}`;
  return (
    <Page title={profile.displayName}>
      <StickyHeader
        title={
          <HorizontalStack>
            <Avatar size="xxlarge" username={profile.displayName} active={profile.isActive} />
            {profile.displayName}
          </HorizontalStack>
        }
        navigation={[
          { label: 'Profile', to: 'profile' },
          { label: 'Timeline', to: 'timeline' },
          { label: 'Projects', to: 'projects' },
          { label: 'Repositories', to: 'repositories' },
        ]}
      />

      <Gutters>
        <Switch>
          <Route path={`${basePath}/profile`}>
            <Profile
              profile={profile}
              statistics={[
                {
                  name: 'Identities',
                  value: profile.developerIdentitiesDisplayNames.length,
                  popover: (
                    <VerticalStack>
                      {profile.developerIdentitiesDisplayNames?.map(displayName => (
                        <div key={displayName}>{displayName}</div>
                      ))}
                    </VerticalStack>
                  ),
                },
                {
                  name: 'Repositories',
                  url: `${developerPageUrl}/repositories`,
                  value: profile.repositoryCount,
                },
                {
                  name: 'Commits',
                  url: `${developerPageUrl}/timeline`,
                  value: profile.commitCount,
                },
                {
                  name: 'Pull Requests',
                  value: profile.authoredPullRequestCount,
                },
                getLanguagesStatistics(profile),
                {
                  name: 'Technologies',
                  value: profile.codeFrameworks.length,
                  popover: <Frameworks inline codeFrameworks={profile.codeFrameworks} />,
                },
              ]}
            />
          </Route>

          <Route path={`${basePath}/timeline`}>
            <TimelineInfiniteScroll entityKey={key} table="developers" hideDeveloper />
          </Route>

          <Route path={`${basePath}/projects`}>
            <Projects developerKey={key} />
          </Route>

          <Route path={`${basePath}/repositories`}>
            <Repositories developerKey={key} />
          </Route>

          <Redirect to={`${basePath}/profile`} />
        </Switch>
      </Gutters>
    </Page>
  );
}
