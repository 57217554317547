import _ from 'lodash';
import groupBy from 'lodash/groupBy';
import map from 'lodash/map';
import styled from 'styled-components';
import { BaseIcon } from '@src-v2/components/icons';
import { ExternalLink } from '@src-v2/components/typography';
import { RuntimeFindingCardFromSummaries } from '@src-v2/containers/entity-pane/api/main-tab/runtime-findings-card';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import { useInject, useSuspense } from '@src-v2/hooks';
import ioc from '@src-v2/ioc';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { primaryPaneLevel, secondaryPaneLevel } from '@src/blocks/Pane/model';
import { LegacyPaneStylingContainer } from '@src/blocks/RiskPosture/legacy-pane-styling-container';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import {
  ApiGatewayReferenceDescription,
  StyledCollapsibleBody,
} from '@src/components/ApiGatewayReferenceDescription';
import { ApiSecurityHint } from '@src/components/ApiSecurityHint';
import Bold from '@src/components/Bold';
import ApiDataModels from '@src/components/CodeAggregation/Apis/ApiDataModels';
import ApiStorageBucketAccesses from '@src/components/CodeAggregation/Apis/ApiStorageBucketAccesses';
import { Highlights } from '@src/components/CodeAggregation/Insights/Highlights';
import {
  StyledDescription,
  StyledFilterButtons,
  StyledInsightsSubTitle,
  StyledInsightsTitle,
  StyledTableContent,
} from '@src/components/CodeAggregation/Insights/InsightStyles';
import {
  getRepositoryForInventoryPropertyFromProfileByKey,
  isAssetCollectionProfile,
} from '@src/components/CodeAggregation/InventoryUtils';
import { CustomVerticalStack } from '@src/components/CodeFindingDescription';
import { CollapsibleContainer } from '@src/components/Collapsible';
import { ElementPane } from '@src/components/ElementPane';
import HighlightedCode from '@src/components/HighlightedCode';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { InputValidationDescriptionBody } from '@src/components/InputValidationDescription';
import { StyledWrappedTitledList } from '@src/components/MaterialChange/Styles';
import { Number } from '@src/components/Number';
import { StyledItem, Titled } from '@src/components/TitledList';
import { VerticalStack } from '@src/components/VerticalStack';
import { dispatch } from '@src/store';
import { enrichApiControls, getApiClassification, getApiText } from '@src/utils/apiUtils';
import { apiClassificationDescription } from '@src/utils/definitionsToRuleTransformer';
import {
  FindingsByProviderDescription,
  StyledInsightsTitleBar,
} from '../FindingsByProviderDescription';

const StyledVerticalStack = styled(VerticalStack)`
  padding-bottom: 30px;
`;
styled(VerticalStack)`
  padding: 18px;
`;
const HighlightInsight = styled.li`
  margin-left: 6rem;
`;

const SecurityHintContainer = styled.div`
  display: flex;
`;

const StyledExternalLink = styled(ExternalLink)`
  width: fit-content;
  font-size: var(--font-size-s);
`;

const getSensitiveDataNumber = (dataModels, keyWord) => (
  <span>
    {keyWord}&nbsp;sensitive data&nbsp;
    <Number one="field" other="fields" value={dataModels.length} />
  </span>
);

const getVulnerabilitiesSummary = relatedCodeFindingsByProvider => (
  <span>
    Found{' '}
    <Number
      one="vulnerability"
      other="vulnerabilities"
      value={Object.values(relatedCodeFindingsByProvider).reduce(
        (sum, findingsByProvider) => sum + findingsByProvider.length,
        0
      )}
    />{' '}
    in latest security scans
  </span>
);

const getSecurityPolicies = securityPolicies => (
  <HorizontalStack>
    <span>Declared security policies:</span>
    <HorizontalStack withSeparator>
      {map(securityPolicies, policy => (
        <div key={policy}>{policy}</div>
      ))}
    </HorizontalStack>
  </HorizontalStack>
);

const getAuthorizationRoles = authorizationRoles => (
  <StyledWrappedTitledList title="Detected authorization roles:" list={authorizationRoles} />
);

const classifiedAsString = apiClassification => (
  <span>{`Classified as an ${apiClassificationDescription(apiClassification)} API`}</span>
);

const INSIGHT_HINT_GENERATORS = {
  'Missing Authorization': (profile, profileType, api) =>
    getApiSecurityHint(profile, profileType, api, 'Authorization'),
};

export const getHighlights = (
  api,
  inputValidationViolations,
  apiClassification,
  missingAuthorization,
  profile,
  profileType,
  hideHint
) => {
  const {
    isUserFacing,
    isSensitive,
    exposedSensitiveData,
    involvedSensitiveData,
    relatedCodeFindingsByProvider,
    apiGatewayReference,
    readsFromStorageBucket,
    writesToStorageBucket,
    // Demo parameters
    accessesDatabase,
    exposedPayment,
    involvedPayment,
    storageBucketUnencrypted,
    writesPiiToStorageBucket,
    authorizationRoles,
    securityPolicies,
  } = api;

  const highlights = [];
  if (apiClassification && apiClassification !== 'unknown') {
    highlights.push(classifiedAsString(apiClassification));
  }

  if (isUserFacing) {
    highlights.push(<span>Predicted as a user facing API</span>);
  }

  if (isSensitive) {
    highlights.push(<span>Predicted as a sensitive API</span>);
  }

  // Start demo section
  if (!_.isEmpty(exposedPayment)) {
    highlights.push(getSensitiveDataNumber(exposedPayment, 'Exposes'));
  }

  if (!_.isEmpty(involvedPayment)) {
    highlights.push(getSensitiveDataNumber(involvedPayment, 'Involves'));
  }
  // End demo section

  if (!_.isEmpty(exposedSensitiveData)) {
    highlights.push(getSensitiveDataNumber(exposedSensitiveData, 'Exposes'));
  }

  if (!_.isEmpty(involvedSensitiveData)) {
    highlights.push(getSensitiveDataNumber(involvedSensitiveData, 'Involves'));
  }

  if (!_.isEmpty(relatedCodeFindingsByProvider)) {
    highlights.push(getVulnerabilitiesSummary(relatedCodeFindingsByProvider));
  }

  if (!_.isEmpty(authorizationRoles)) {
    highlights.push(getAuthorizationRoles(authorizationRoles));
  }

  if (!_.isEmpty(securityPolicies)) {
    highlights.push(getSecurityPolicies(securityPolicies));
  }

  if (inputValidationViolations) {
    highlights.push(
      <SecurityHintContainer>
        <>Missing input validation</>
        &nbsp;
        {!hideHint && getApiSecurityHint(profile, profileType, api, 'InputValidation')}
      </SecurityHintContainer>
    );
  }

  if (apiGatewayReference) {
    highlights.push(<span>Behind API Gateway</span>);
  }

  // Start demo section
  if (accessesDatabase) {
    highlights.push(<span>Interacts with a Database that contains PII</span>);
  }
  // End demo section

  if (!_.isEmpty(readsFromStorageBucket) || !_.isEmpty(writesToStorageBucket)) {
    let item = <span>Accessing storage buckets</span>;

    // Start demo section
    if (writesPiiToStorageBucket || storageBucketUnencrypted) {
      item = (
        <ul>
          {item}
          {writesPiiToStorageBucket && (
            <HighlightInsight>Writes PII to storage bucket</HighlightInsight>
          )}
          {storageBucketUnencrypted && (
            <HighlightInsight>Storage bucket is not encrypted</HighlightInsight>
          )}
        </ul>
      );
    }
    // End demo section

    highlights.push(item);
  }

  return highlights;
};

const getInsightsBasedHighlights = (api, profile, profileType) => {
  return api.insights.map(insight =>
    INSIGHT_HINT_GENERATORS[insight.badge] ? (
      <SecurityHintContainer>
        <>{insight.badge}</>
        &nbsp;
        {INSIGHT_HINT_GENERATORS[insight.badge](profile, profileType, api)}
      </SecurityHintContainer>
    ) : (
      <span>{insight.badge}</span>
    )
  );
};

const getApiSecurityHint = (profile, profileType, api, violationType) => (
  <ApiSecurityHint
    profile={profile}
    profileType={profileType}
    apiKey={
      isAssetCollectionProfile(profileType)
        ? `${api.repositoryKeys[0]}_${api.entityId}`
        : api.entityId
    }
    violationType={violationType}
  />
);

const getInsightsBody = (
  profile,
  profileType,
  repository,
  api,
  inputValidationViolations,
  highlights
) => {
  const {
    exposedSensitiveDataReferences,
    involvedSensitiveDataReferences,
    readsFromStorageBucket,
    writesToStorageBucket,
    codeReference,
    methodName,
    accessesDatabase,
    relatedCodeFindingsByProvider,
    apiGatewayReference,
    registrationCodeReference,
    apiFramework,
    apiControlsBasicInfo,
    apiMiddlewareMethods,
  } = api;

  const hasRelatedPii =
    !_.isEmpty(exposedSensitiveDataReferences) || !_.isEmpty(involvedSensitiveDataReferences);
  const isAccessingStorageBuckets =
    !_.isEmpty(readsFromStorageBucket) || !_.isEmpty(writesToStorageBucket);

  const exposedFilterLabel = 'Exposes Sensitive Data';
  const involvedFilterLabel = 'Involves Sensitive Data';

  const getSensitiveDataByFilterLabel = labels =>
    _.includes(labels, involvedFilterLabel)
      ? involvedSensitiveDataReferences
      : exposedSensitiveDataReferences;

  const readsFilterLabel = 'Reads from Storage Buckets';
  const writesFilterLabel = 'Writes to Storage Buckets';

  const getStorageAccessesByFilterLabel = labels =>
    _.includes(labels, readsFilterLabel) ? readsFromStorageBucket : writesToStorageBucket;
  const { methodSignature } = codeReference;
  return (
    <StyledVerticalStack>
      <Highlights highlights={highlights} />
      {(methodSignature || methodName) && (
        <VerticalStack>
          <StyledInsightsTitle>API declaration</StyledInsightsTitle>
          <HighlightedCode code={methodSignature || methodName} language="java" />
          {registrationCodeReference && registrationCodeReference.relativeFilePath && (
            <>
              <HorizontalStack>
                Registered at:
                <StyledExternalLink
                  href={generateCodeReferenceUrl(repository, registrationCodeReference)}>
                  {registrationCodeReference.relativeFilePath}
                </StyledExternalLink>
              </HorizontalStack>
            </>
          )}
          {apiFramework && <>Framework: {apiFramework}</>}
        </VerticalStack>
      )}
      {inputValidationViolations && (
        <VerticalStack>
          <StyledInsightsTitle>Missing Input Validation</StyledInsightsTitle>
          <InputValidationDescriptionBody repository={repository} {...inputValidationViolations} />
        </VerticalStack>
      )}
      {hasRelatedPii && (
        <>
          <VerticalStack>
            <StyledInsightsTitle>Related Data Models</StyledInsightsTitle>
            <StyledDescription>
              This API either directly exposes or indirectly involves the following data models with
              sensitive data:
            </StyledDescription>
          </VerticalStack>
          <StyledTableContent>
            <StyledFilterButtons
              filterToCountMapping={[
                { name: exposedFilterLabel, count: exposedSensitiveDataReferences?.length },
                { name: involvedFilterLabel, count: involvedSensitiveDataReferences?.length },
              ]}>
              {selectedGroup => (
                <ApiDataModels
                  sensitiveDataReferences={getSensitiveDataByFilterLabel(selectedGroup)}
                  repository={repository}
                  profile={profile}
                />
              )}
            </StyledFilterButtons>
          </StyledTableContent>
        </>
      )}
      {Object.entries(relatedCodeFindingsByProvider).map(([provider, findings]) => (
        <FindingsByProviderDescription
          key={provider}
          provider={provider}
          findings={findings}
          repository={repository}
        />
      ))}
      {apiGatewayReference && (
        <ApiGatewayReferenceDescription apiGatewayReference={apiGatewayReference} />
      )}
      {
        // Start demo section
        accessesDatabase && (
          <VerticalStack>
            <StyledInsightsTitle>Database Access</StyledInsightsTitle>
            <StyledDescription>
              This API interacts with a Database that contains PII
            </StyledDescription>
          </VerticalStack>
        )
        // End demo section
      }
      {isAccessingStorageBuckets && (
        <>
          <VerticalStack>
            <StyledInsightsTitle>Cloud Storage Buckets Access</StyledInsightsTitle>
            <StyledDescription>
              This API interacts with cloud storage buckets using the following method calls:
            </StyledDescription>
          </VerticalStack>
          <StyledTableContent>
            <StyledFilterButtons
              filterToCountMapping={[
                { name: readsFilterLabel, count: readsFromStorageBucket.length },
                { name: writesFilterLabel, count: writesToStorageBucket.length },
              ]}>
              {selectedGroup => (
                <ApiStorageBucketAccesses
                  accessingMethods={getStorageAccessesByFilterLabel(selectedGroup)}
                  repository={repository}
                  profile={profile}
                />
              )}
            </StyledFilterButtons>
          </StyledTableContent>
        </>
      )}
      {ioc.application.isFeatureEnabled(FeatureFlag.NewApiControls) &&
        Boolean(apiControlsBasicInfo?.length) && (
          <SecurityControlsDisplay info={apiControlsBasicInfo} repository={repository} />
        )}
      {ioc.application.isFeatureEnabled(FeatureFlag.NewApiControls) &&
        Boolean(apiMiddlewareMethods?.length) && (
          <MiddlewaresDisplay middlewareSequence={apiMiddlewareMethods} repository={repository} />
        )}
    </StyledVerticalStack>
  );
};

function SecurityControlsDisplay({ info, repository }) {
  const infoByType = Object.entries(groupBy(info, 'type')).map(([type, items]) => (
    <VerticalStack>
      <StyledInsightsSubTitle>
        {`Detected `}
        <strong>{type}</strong>
        {` controls:`}
      </StyledInsightsSubTitle>
      {items.map((controlBasicInfo, i) => (
        <div key={`securityControl${i}`}>
          <HorizontalStack>
            <span>{controlBasicInfo.description && `• ${controlBasicInfo.description}.`}</span>
            {controlBasicInfo.codeReference && (
              <StyledExternalLink
                href={generateCodeReferenceUrl(repository, controlBasicInfo.codeReference)}>
                View control in code
              </StyledExternalLink>
            )}
          </HorizontalStack>
        </div>
      ))}
    </VerticalStack>
  ));

  return (
    <VerticalStack>
      <StyledInsightsTitle>Security controls</StyledInsightsTitle>
      {infoByType}
    </VerticalStack>
  );
}

function MiddlewaresDisplay({ middlewareSequence, repository }) {
  const middlewaresTable = (
    <VerticalStack>
      <StyledInsightsSubTitle>
        {`Detected `}
        {middlewareSequence.length}
        {` middlewares:`}
      </StyledInsightsSubTitle>
      {middlewareSequence.map((middleware, i) => (
        <div key={`middleware${i}`}>
          <HorizontalStack>
            <span>
              {middleware?.methodId && `• ${middleware.codeReference?.name ?? middleware.methodId}`}
            </span>
            {middleware.codeReference && (
              <StyledExternalLink
                href={generateCodeReferenceUrl(repository, middleware.codeReference)}>
                View middleware in code
              </StyledExternalLink>
            )}
          </HorizontalStack>
        </div>
      ))}
    </VerticalStack>
  );

  return (
    <VerticalStack>
      <StyledInsightsTitle>Middlewares</StyledInsightsTitle>
      {middlewaresTable}
    </VerticalStack>
  );
}

const getApiPane = ({
  trigger,
  api,
  profile,
  profileType,
  ruleTriggers,
  repository,
  messageContent,
}) => {
  enrichApiControls(api, profile, repository);
  const missingAuthorization = api.isAuthorizationRelevant && api.hasAuthorizationViolation;
  const inputValidationViolation = api.isValidationRelevant ? api.inputValidationViolation : null;

  const title = (
    <InsightsPaneTitle
      trigger={trigger}
      element={api}
      title={getApiText(api.httpMethod, api.httpRoute, api.methodName)}
      codeReference={api.codeReference}
      repository={repository}
      profile={profile}
      profileType={profileType}
      ruleTriggers={ruleTriggers}
      messageContent={messageContent}
    />
  );

  const highlights = getHighlights(
    api,
    inputValidationViolation,
    getApiClassification(api),
    missingAuthorization,
    profile,
    profileType,
    false
  ).concat(getInsightsBasedHighlights(api, profile, profileType));

  const body = getInsightsBody(
    profile,
    profileType,
    repository,
    api,
    inputValidationViolation,
    highlights
  );
  return <ElementPane element={api} repository={repository} title={title} profileBody={body} />;
};

export function LegacyApiPane({ api, profile }) {
  enrichApiControls(api, profile, profile.repository);
  const missingAuthorization = api.isAuthorizationRelevant && api.hasAuthorizationViolation;
  const inputValidationViolation = api.isValidationRelevant ? api.inputValidationViolation : null;

  const highlights = getHighlights(
    api,
    inputValidationViolation,
    getApiClassification(api),
    missingAuthorization,
    profile,
    profile.profileType,
    false
  ).concat(getInsightsBasedHighlights(api, profile, profile.profileType));

  return (
    <ApiStylingContainer>
      {getInsightsBody(
        profile,
        profile.profileType,
        profile.repository,
        api,
        inputValidationViolation,
        highlights
      )}
    </ApiStylingContainer>
  );
}

export function LegacyRuntimeFindingsCards({ api, risk, ...props }) {
  const { inventory } = useInject();

  const enrichedApiElement = useSuspense(inventory.getApiElement, {
    profileKey: risk.relatedEntity.key,
    elementKey: api.entityId,
  });

  return (
    <>
      {enrichedApiElement.apiFindingsSummaries?.map(summary => (
        <RuntimeFindingCardFromSummaries
          {...props}
          key={summary.provider}
          findingsSummary={summary}
        />
      ))}
    </>
  );
}

const ApiStylingContainer = styled(LegacyPaneStylingContainer)`
    & > ${VerticalStack} {
        gap: 4rem;
    }

    ${StyledInsightsTitleBar} {
        margin-top: 0;

        & + div:not(${Titled}) {
            margin-bottom: 4rem;
        }
    }

    ${Titled} ${StyledItem} {
        font-weight: 400;

        & > ${VerticalStack} > ${VerticalStack} {
            gap: 4rem;
        }
    }

    ${CollapsibleContainer} ${StyledCollapsibleBody} {
        margin-top: 0;
        margin-left: 4rem;
        font-size: var(--font-size-s);
        line-height: 1.5;
    {
    }
    }

    ${Bold},
    ${StyledInsightsSubTitle},
    ${CollapsibleContainer} > ${HorizontalStack},
    ${StyledInsightsTitleBar} > ${HorizontalStack} {
        font-size: var(--font-size-s);
        font-weight: 300;
        text-decoration: underline;
        line-height: 1.5;
    }

    ${CollapsibleContainer} ${StyledCollapsibleBody} ${VerticalStack} ${StyledInsightsSubTitle} {
        margin-top: 2rem;
    }

    ${CustomVerticalStack} {
        margin-top: 0;
    }

    ${VerticalStack} {
        font-size: var(--font-size-s);
    }

    a > ${StyledInsightsSubTitle} ${HorizontalStack} {
        gap: 0.5rem;

        ${BaseIcon} {
            margin: 0 0 0 1rem;
        }
    }
`;

export const openApiPaneWithRiskActions = ({
  ruleKey,
  trigger,
  profile,
  profileType,
  onClose,
  relevantPath,
  messageContent,
  externalRiskTriggerPane,
  level = secondaryPaneLevel,
}) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  dispatch.pane.openPane({
    level,
    onClose,
    relevantPath,
    id: trigger.key,
    content: (
      <RiskRuleTrigger
        profile={profile}
        ruleKey={ruleKey}
        trigger={trigger}
        externalRiskTriggerPane={externalRiskTriggerPane}
        getPane={({ element, ruleTriggers }) =>
          getApiPane({
            api: element,
            profile,
            profileType,
            ruleTriggers,
            repository,
            messageContent,
            trigger,
          })
        }
      />
    ),
  });
};

export const openApiPane = ({ api, profile, profileType, repository }) =>
  dispatch.pane.openPane({
    id: api.triggerKey,
    level: primaryPaneLevel,
    content: getApiPane({
      api,
      profile,
      profileType,
      repository,
    }),
  });
