import styled from 'styled-components';
import { CircleButton } from '@src-v2/components/button-v2';
import { SvgIcon } from '@src-v2/components/icons';
import { PaneStickyHeader } from '@src-v2/components/panes/pane-sticky-header';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { useDetectScrolling } from '@src-v2/hooks/dom-events/use-detect-scrolling';
import { dataAttr } from '@src-v2/utils/dom-utils';
import {
  getChartNodePresentationConfig,
  resourceRiskColor,
} from '@src/cluster-map-work/components/charts/chart-view-presentation-config';
import {
  PropertiesPanel,
  PropertiesScrollableRegion,
} from '@src/cluster-map-work/components/layout/properties-panel';
import { clusterChartViewPresentationConfig } from './cluster-object-visuals';
import propertyProviders from './property-providers';

export function ClusterResourceInfoPanel({
  clusterKey,
  clusterResource,
  clusterResourceRisk,
  clusterProfile,
  onToggleSearchTerm,
  highlightsList,
  onHandleClose,
}) {
  let propertiesContent;
  let objectName, objectType, objectTypeColor, objectDescription;
  let objectTypeFgColor;
  const [scrolled] = useDetectScrolling();
  if (clusterResource) {
    const presConf = getChartNodePresentationConfig(
      clusterChartViewPresentationConfig,
      clusterResource.resourceType
    );

    objectName = clusterResource.name.name;
    objectType = presConf.friendlyName;

    const riskColor = resourceRiskColor(clusterResourceRisk);
    if (riskColor) {
      objectTypeColor = riskColor;
      objectTypeFgColor = 'var(--color-white)';
    }

    objectDescription =
      (clusterResource.name.ns && `in '${clusterResource.name.ns}'`) || `No namespace`;

    propertiesContent = propertyProviders.flatMap(provider =>
      provider({ clusterKey, clusterResource, clusterProfile, onToggleSearchTerm, highlightsList })
    );
  } else if (clusterProfile) {
    objectName = clusterProfile.name;
    objectType = 'Cluster';
    objectDescription = 'No namespace';

    propertiesContent = <ClusterPropertiesPanelContent clusterProfile={clusterProfile} />;
  }

  return (
    <StyledPropertiesPanel>
      <CircleButtonClusterPaneContainer>
        <CircleButton size="medium" variant={Variant.TERTIARY} onClick={onHandleClose}>
          <SvgIcon name="Close" />
        </CircleButton>
      </CircleButtonClusterPaneContainer>
      <PaneStickyHeader scrolled={scrolled}>
        <PropertiesPanel.TitleBar>
          <PropertiesPanel.TitleBar.ObjectName
            hint={objectName}
            data-highlighted={dataAttr(
              Boolean(highlightsList.find(highlight => highlight.propertyName === 'Name'))
            )}>
            {objectName}
          </PropertiesPanel.TitleBar.ObjectName>
          <PropertiesPanel.TitleBar.ObjectType
            tagColor={objectTypeColor}
            tagForegroundColor={objectTypeFgColor}>
            {objectType}
          </PropertiesPanel.TitleBar.ObjectType>
          <PropertiesPanel.TitleBar.ObjectDescription hint={objectDescription}>
            {objectDescription}
          </PropertiesPanel.TitleBar.ObjectDescription>
          <PropertiesPanel.TitleBar.Separator />
        </PropertiesPanel.TitleBar>
      </PaneStickyHeader>
      <PropertiesScrollableRegion>{propertiesContent}</PropertiesScrollableRegion>
    </StyledPropertiesPanel>
  );
}

function ClusterPropertiesPanelContent({ clusterProfile }) {
  return (
    <>
      <PropertiesPanel.Section>
        <PropertiesPanel.Section.Header>Security Profile</PropertiesPanel.Section.Header>
        <PropertiesPanel.Section.Item>
          {clusterProfile?.clusterStats?.namespaceCount} namespaces
        </PropertiesPanel.Section.Item>
        <PropertiesPanel.Section.Item>
          {clusterProfile?.clusterStats?.containerCount} containers
        </PropertiesPanel.Section.Item>
        <PropertiesPanel.Section.Item>
          {clusterProfile?.clusterStats?.gatewayCount} gateways
        </PropertiesPanel.Section.Item>
        <PropertiesPanel.Section.Item>
          {clusterProfile?.clusterStats?.externallyAccessibleContainers} externally accessible
          containers
        </PropertiesPanel.Section.Item>
      </PropertiesPanel.Section>
    </>
  );
}

const StyledPropertiesPanel = styled(PropertiesPanel)`
  height: 100%;
  width: 100%;
`;

const CircleButtonClusterPaneContainer = styled.div`
  margin: 2rem 4rem 0;
`;
