import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Heading, Paragraph } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  HighlightedCode,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';

export const ApiAuthorizationRoleMaterialChange = ({
  commitSha,
  repository,
  relativeFilePath,
  materialChange,
}) => {
  const { methodSignature, apiName, methodName } = materialChange.codeReference;
  return (
    <>
      <Heading>
        <Paragraph>API authorization roles are:</Paragraph>
        {materialChange.roles.map(role => (
          <Paragraph>{role} </Paragraph>
        ))}
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}>
          {apiName ?? methodName}
        </CommitCodeReference>
      </Heading>
      <MaterialChangesRow>
        <Tooltip content={<HighlightedCode language="java">{methodSignature}</HighlightedCode>}>
          <Paragraph> {apiName ?? methodName} </Paragraph>
        </Tooltip>
      </MaterialChangesRow>
    </>
  );
};
