import styled from 'styled-components';
import { Tooltip as TooltipV2 } from '@src-v2/components/tooltips/tooltip';
import { FontBodyBold, FontBodySmall } from '@src/style/common';
import { VerticalStack } from './VerticalStack';

export const Tooltip = ({ tip, isLimited, className, children, ...props }) =>
  tip ? (
    <TooltipV2 isLimited={isLimited} content={<VerticalStack>{tip}</VerticalStack>} {...props}>
      <StyledDiv className={className}>{children}</StyledDiv>
    </TooltipV2>
  ) : (
    <span className={className}>{children}</span>
  );

const StyledDiv = styled.div`
  display: inline-block;
`;

export const TooltipHeader = styled.div`
  ${FontBodyBold};
  color: inherit;
`;

export const TooltipSubtext = styled.div`
  ${FontBodySmall};
  color: inherit;
  font-style: italic;
`;
