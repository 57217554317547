import _ from 'lodash';
import { useEffect } from 'react';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { LearningButton } from '@src-v2/containers/learning-stats';
import { ElementTimelineItem } from './components/ElementTimelineItem';

export const ElementTimeline = ({
  entityType,
  entityId,
  fetchData,
  clearData,
  isLoading,
  entityProfile,
  repositoryProfile,
  developerProfileByIdentityKey,
  hasUnprocessedCommits,
}) => {
  useEffect(() => {
    fetchData({ repositoryKey: repositoryProfile?.key, entityType, entityId });
    return clearData;
  }, [fetchData, clearData, repositoryProfile, entityType, entityId]);

  if (isLoading) {
    return (
      <LoadingContainer>
        <LogoSpinner />
      </LoadingContainer>
    );
  }

  if (_.isEmpty(entityProfile?.events)) {
    return hasUnprocessedCommits ? (
      <EmptyMessage>
        <LearningButton>No events found yet. We are still analyzing the repository.</LearningButton>
      </EmptyMessage>
    ) : (
      <EmptyMessage>No events found. Element might be too old to show in timeline.</EmptyMessage>
    );
  }

  return (
    <Container>
      {entityProfile.events.map((event, index) => (
        <ElementTimelineItem
          key={event.commitSha + event.label}
          isFirst={index === 0}
          isLast={index === entityProfile.events.length - 1}
          index={index}
          event={event}
          previousEvent={entityProfile.events[index - 1]}
          repositoryProfile={repositoryProfile}
          developerProfile={developerProfileByIdentityKey[event.developerIdentityKey]}
        />
      ))}
    </Container>
  );
};

const Container = styled.div`
  margin-top: 2rem;
`;

const LoadingContainer = styled.div`
  margin-top: 9rem;
  text-align: center;
`;

const EmptyMessage = styled.div`
  padding: 20rem;
  font-size: var(--font-size-l);
  font-weight: 300;
  text-align: center;
`;
