import { observer } from 'mobx-react';
import { Fragment } from 'react';
import styled from 'styled-components';
import { Collapsible } from '@src-v2/components/collapsible';
import { NavigationGroup, NavigationLink } from '@src-v2/containers/sidebar/navigation-elements';
import { useInject, useLocalStorage, useToggle } from '@src-v2/hooks';
import { useDetectScrolling } from '@src-v2/hooks/dom-events/use-detect-scrolling';
import { customScrollbar } from '@src-v2/style/mixins';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { classNames } from '@src-v2/utils/style-utils';

export const SidebarNavigation = observer(
  ({ items, isDocked, className = '', children = null, ...props }) => {
    const [scrolled, onScroll] = useDetectScrolling();

    const [menuState, setMenuState] = useLocalStorage('layout.menu.new', {});

    return (
      <Container
        {...props}
        data-scrolled={dataAttr(scrolled)}
        className={classNames(className, { isDocked })}
        onScroll={onScroll}>
        {items.map((navGroup, index) =>
          navGroup.nested ? (
            <Fragment key={index}>
              <Divider />
              <NestedCategory
                navGroup={navGroup}
                isDocked={isDocked}
                menuState={menuState}
                setMenuState={setMenuState}
                data-test-marker="sidebar-nested-solutions">
                {navGroup.items.map(item => (
                  <NavigationOption
                    key={item.title}
                    navGroup={item}
                    isDocked={isDocked}
                    menuState={menuState}
                    setMenuState={setMenuState}
                  />
                ))}
              </NestedCategory>
              <Divider />
            </Fragment>
          ) : (
            <NavigationOption
              key={index}
              navGroup={navGroup}
              isDocked={isDocked}
              menuState={menuState}
              setMenuState={setMenuState}
            />
          )
        )}
        {children}
      </Container>
    );
  }
);

const NestedCategory = styled(
  observer(({ navGroup, isDocked, menuState, setMenuState, children, ...props }) => {
    const [open, toggleCollapsible] = useToggle((menuState[navGroup.title] || isDocked) ?? true);

    const handleToggle = () => {
      if (isDocked) {
        return;
      }
      setMenuState(state => {
        state[navGroup.title] = !open;
        return { ...state };
      });
      toggleCollapsible();
    };

    return (
      <Collapsible
        {...props}
        open={open || isDocked}
        title={navGroup.title}
        onToggle={handleToggle}
        data-docked={dataAttr(isDocked)}>
        {children}
      </Collapsible>
    );
  })
)`
  &[data-docked] > ${Collapsible.Head} {
    padding-left: 1.5rem;
    margin-left: 0;

    ${Collapsible.Chevron} {
      width: 0;
      transition: width 400ms;
      overflow: hidden;
    }
  }

  &[data-open] {
    > ${Collapsible.Head} {
      ${Collapsible.Title},
      ${Collapsible.Chevron} {
        color: var(--color-blue-gray-45);
        transition: all 800ms;
      }
    }
  }

  > ${Collapsible.Head} {
    padding: 3rem 0 2.5rem 2rem;
    flex-direction: row-reverse;
    margin-left: 2rem;
    gap: 0 2rem;

    &:hover {
      background-color: var(--color-blue-gray-75);
    }

    ${Collapsible.Chevron} {
      color: var(--color-blue-gray-25);
      transform: rotate(90deg);
      transition: all 600ms;
    }

    ${Collapsible.Title} {
      color: var(--color-blue-gray-25);
      font-size: var(--font-size-xs);
      font-weight: 600;
      transition: all 600ms;
    }
  }
`;

const NavigationOption = ({ navGroup, props, isDocked, menuState, setMenuState }) => {
  const { application, rbac } = useInject();
  const isOpen = menuState[navGroup.title];

  return (
    (navGroup.condition?.(application, rbac) ?? true) &&
    (navGroup.items ? (
      <NavigationGroup
        {...props}
        item={navGroup}
        isDocked={isDocked}
        isOpen={isOpen}
        onToggle={isOpen =>
          setMenuState(state => {
            state[navGroup.title] = isOpen;
            return { ...state };
          })
        }
      />
    ) : (
      <NavigationLink {...props} key={navGroup.title} item={navGroup} isDocked={isDocked} />
    ))
  );
};

const Container = styled.div`
  color: var(--color-blue-gray-60);
  font-size: var(--font-size-s);
  font-weight: 400;
  white-space: nowrap;

  ${customScrollbar};
  overflow-x: hidden;
  overflow-y: scroll;

  &::-webkit-scrollbar {
    width: 2rem;
  }

  &[data-scrolled] {
    &:before {
      content: '';
      width: 47rem;
      height: 0;
      display: block;
      position: sticky;
      top: 0;
      left: 6rem;
      border-radius: 100vmax;
      background-color: black;
      z-index: 999;
      box-shadow: black 7px -2px 8px 2px;
    }
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 0.25rem;
  position: relative;
  left: 2rem;
  background-color: var(--color-blue-gray-80);
`;
