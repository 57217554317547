import { animated, useSpring } from '@react-spring/web';
import { forwardRef, useEffect, useMemo, useRef } from 'react';
import styled from 'styled-components';
import { BaseIcon } from '@src-v2/components/icons';
import { useForwardRef, useResizeObserver } from '@src-v2/hooks';

export const AnimatedTabControl = styled(
  forwardRef(({ items, itemToTabControl, value, onChange, ...props }, forwardedRef) => {
    const [thumbStyle, thumbSpringApi] = useSpring(() => ({ left: 0, width: 0 }));
    const ref = useForwardRef(forwardedRef);
    const childrenContainerRef = useRef();
    const mountRef = useRef(false);
    const scrollRef = useRef();
    useResizeObserver(ref, applyAnimation, 'border-box');

    const selectedIndex = useMemo(() => items.indexOf(value), [items, value]);

    function applyAnimation() {
      const activeChild = childrenContainerRef.current?.children[selectedIndex];
      if (activeChild) {
        thumbSpringApi.start({
          left: activeChild.offsetLeft,
          width: activeChild.scrollWidth,
          immediate: !mountRef.current,
        });

        setTimeout(() => {
          activeChild.scrollIntoView();
          mountRef.current = true;
        });
      }
    }

    useEffect(() => applyAnimation(), [selectedIndex]);

    return (
      <VerticalStack {...props} ref={ref}>
        <AnimatedTabControlTabsContainer ref={childrenContainerRef}>
          {items.map((item, index) => (
            <AnimatedTabControlTabsContainer.TabWrapper
              ref={scrollRef}
              key={index}
              onClick={() => {
                onChange(item);
              }}>
              {itemToTabControl(item)}
            </AnimatedTabControlTabsContainer.TabWrapper>
          ))}
        </AnimatedTabControlTabsContainer>
        <AnimatedTabControlThumb style={thumbStyle} />
      </VerticalStack>
    );
  })
)`
  position: relative;
  flex-wrap: nowrap;
  white-space: nowrap;
  padding-bottom: 1rem;
`;

AnimatedTabControl.Tab = styled.div`
  display: flex;
  align-items: center;
  transition:
    color 200ms,
    font-weight 200ms;
  max-width: 200px;
  padding: 0 2rem;
  color: var(--color-blue-gray-55);
  overflow: hidden;
  font-weight: 300;
  font-size: var(--font-size-l);
  cursor: pointer;

  & ${BaseIcon} {
    flex-shrink: 0;
    margin-top: 0.5rem;
  }

  &:hover {
    color: var(--color-blue-75);
  }

  &[data-selected='true'] {
    font-weight: 600;
    color: var(--color-blue-75);
  }
`;

const AnimatedTabControlTabsContainer = styled.div`
  display: flex;
  gap: 2rem;
`;

AnimatedTabControlTabsContainer.TabWrapper = styled.div``;

const AnimatedTabControlThumb = styled(animated.span)`
  position: absolute;
  bottom: 0;
  height: 0.5rem;
  border-radius: 100vmax;
  background-color: var(--color-blue-75);
`;

const VerticalStack = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;
  gap: 3rem;
`;
