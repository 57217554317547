import { useMemo } from 'react';
import { Collapsible } from '@src-v2/components/collapsible';
import { CalendarDatePickerFilter } from '@src-v2/components/filters/menu-control/calendar-date-picker-filter';

export function DateRangeOptions({
  groupKey,
  options,
  optionsLimit,
  activeValues,
  onChange,
  onDelete,
  ...props
}) {
  return (
    <Collapsible {...props}>
      <CalendarDatePickerFilter
        date={useMemo(() => activeValues?.map(value => new Date(value)), [activeValues])}
        onChange={(value, event) => onChange?.({ key: groupKey, value }, event)}
      />
    </Collapsible>
  );
}
