import compact from 'lodash/compact';
import every from 'lodash/every';
import join from 'lodash/join';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { Number } from '@src/components/Number';
import { isSupportedTrigger } from './DetailsPane/TriggerDisplayUtils';

export const isIgnored = posture => posture.riskLevel === 'AutoIgnored';

export const getNumberByRiskLevel = (ruleTriggers, riskLevel, filterRiskChange = 'Resolved') => {
  return ruleTriggers.triggers.filter(
    trigger =>
      trigger.riskLevel === riskLevel &&
      (!filterRiskChange || trigger.riskChange !== filterRiskChange)
  ).length;
};

export const getMatchesCount = ruleTriggers => {
  const ignored = isIgnored(ruleTriggers);
  const ignoredCount = getNumberByRiskLevel(ruleTriggers, 'Ignored');
  const acceptedCount = getNumberByRiskLevel(ruleTriggers, 'Accepted');
  if (every(ruleTriggers.triggers, trigger => !isSupportedTrigger(trigger))) {
    return (
      <Number
        value={ruleTriggers.count}
        one={`${ignored ? 'potential ' : ''}component at risk`}
        other={`${ignored ? 'potential ' : ''}components at risk`}
      />
    );
  }

  const riskyCount = getNumberByRiskLevel(ruleTriggers, null);
  return join(
    compact([
      riskyCount > 0
        ? `${riskyCount} ${ignored ? 'potential ' : ''}${pluralFormat(
            riskyCount,
            'component',
            'components'
          )} at risk`
        : 'All risks resolved',
      ignoredCount > 0 ? `${ignoredCount} ignored` : null,
      acceptedCount > 0 ? `${acceptedCount} accepted` : null,
    ]),
    ', '
  );
};
