import { useMemo } from 'react';
import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { TabsRouter } from '@src-v2/components/tabs/tabs-router';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { ProfileRisksTable } from '@src-v2/containers/risks/profile-risks-table';
import { useInject } from '@src-v2/hooks';
import { DevPhase } from '@src-v2/types/enums/dev-phase';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { humanize } from '@src-v2/utils/string-utils';
import { BuildsTable } from '@src/blocks/RiskPosture/blocks/BuildsTable';
import { ProfileRisksOverviewTable } from '@src/blocks/RiskPosture/blocks/ProfileRisksOverviewTable';
import { PullRequestsTable } from '@src/blocks/RiskPosture/blocks/pull-requests-table';

const routes = [
  { title: 'Overview', path: 'overview', component: ProfileRisksOverviewTable },
  {
    path: 'design',
    condition: ({ isModuleBased }, isMonorepoProjectsEnabled) => {
      return !isModuleBased || isMonorepoProjectsEnabled;
    },
    component: props => <ProfileRisksTable {...props} devPhase={DevPhase.Design} />,
  },
  {
    path: 'development',
    component: props => <ProfileRisksTable {...props} devPhase={DevPhase.Development} />,
  },
  {
    path: 'pullRequests',
    condition: ({ isModuleBased }) => !isModuleBased,
    component: PullRequestsTable,
  },
  {
    title: 'CI/CD',
    path: 'cicd',
    condition: ({ isModuleBased }) => !isModuleBased,
    component: BuildsTable,
  },
  {
    path: 'supplyChain',
    betaFeature: FeatureFlag.SupplyChainRiskTable,
    component: props => <ProfileRisksTable {...props} devPhase={DevPhase.SupplyChain} />,
  },
];

export const RisksTabSwitch = ({ profile, profileType }) => {
  const { application } = useInject();
  const memoizedRoutes = useMemo(
    () =>
      routes
        .filter(item => {
          return (
            (!item.betaFeature || application.isFeatureEnabled(item.betaFeature)) &&
            (!item.condition ||
              item.condition(profile, application.isFeatureEnabled(FeatureFlag.MonorepoProjects)))
          );
        })
        .map(({ path, title, component: Component }) => ({
          path,
          title: title ?? humanize(path, true),
          render: () => (
            <AsyncBoundary>
              <Component profile={profile} profileType={profileType ?? profile.profileType} />
            </AsyncBoundary>
          ),
        })),
    []
  );

  return (
    <ProfileTabsRouter
      redirectPath="overview"
      routes={memoizedRoutes}
      variant={Variant.SECONDARY}
    />
  );
};

export const ModuleRisksTabSwitch = ({ moduleRoot, moduleRepositoryKey }) => {
  const moduleRoutes = useMemo(
    () => [
      {
        path: 'overview',
        title: 'Overview',
        render: () => (
          <AsyncBoundary>
            <ProfileRisksOverviewTable
              profile={{ key: moduleRepositoryKey, module: moduleRoot }}
              profileType="ModuleProfile"
            />
          </AsyncBoundary>
        ),
      },
      {
        path: 'development',
        title: 'Development',
        render: () => (
          <AsyncBoundary>
            <ProfileRisksTable
              profile={{ key: `${moduleRepositoryKey}|${moduleRoot}` }}
              devPhase={DevPhase.Development}
              profileType="ModuleProfile"
            />
          </AsyncBoundary>
        ),
      },
    ],
    []
  );

  return <ProfileTabsRouter redirectPath="overview" routes={moduleRoutes} />;
};

const ProfileTabsRouter = styled(TabsRouter)`
  margin-top: 4rem;
`;
