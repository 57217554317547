import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { ExternalLink, Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  HighlightedCode,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';
import { thenSubTypeToVerb } from '@src-v2/containers/commit/material-changes-utils';
import { AbnormalBehaviorMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/abnormal-behavior-material-change';
import { ApiAuthorizationRoleMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/api-authorization-role-material-change';
import { ApiBehindApiGatewayMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/api-behind-api-gateway-material-change';
import { ApiCheckmarxVulnerabilityMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/api-checkmarx-vulnerability-material-change';
import { ApiClassificationMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/api-classification-material-change';
import { ApiMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/api-material-change';
import { ApiMethodParameterMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/api-method-parameter-material-change';
import { ApiSecurityPolicyMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/api-security-policy-material-change';
import { CompoundMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/compound-material-change';
import { DataModelAccessMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/data-model-access-material-change';
import {
  Dependency,
  DependencyMaterialChange,
} from '@src-v2/containers/commit/material-changes/material-changes-content/dependancy-material-change';
import { DockerfileMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/dockerfile-material-change';
import { EntityInsightsMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/entity-insights-material-change';
import { EntitySatisfiesExpressionMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/entity-satisfies-expression-material-change';
import { GqlMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/gql-material-change';
import { InputValidationMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/input-validation-material-change';
import { ManagedCheckovMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/managed-checkov-material-change';
import { ManagedSemgrepMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/managed-semgrep-material-change';
import { NewDeveloperChange } from '@src-v2/containers/commit/material-changes/material-changes-content/new-developer-change';
import { ProtobufMessageMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/protobuf-message-material-change';
import { ProtobufServiceMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/protobuf-service-material-change';
import { RbacRoleMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/rbac-role-material-change';
import { SecurityControlsMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/security-controls-material-change';
import { SensitiveDataExitPointMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/sensitive-data-exit-point-material-change';
import { SensitiveDataMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/sensitive-data-material-change';
import { DataOfInterestPresenceMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/sensitive-data-presence-material-change';
import { ServerlessFunctionMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/serverless-function-material-change';
import { StorageBucketAccessMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/storage-bucket-access-material-change';
import { TerraformMisconfigurationsMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/terraform-misconfigurations-material-change';
import { TerraformResourceMaterialChange } from '@src-v2/containers/commit/material-changes/material-changes-content/terraform-resource-material-change';
import { HelpModalButton } from '@src-v2/containers/modals/help-modal';
import { useInject } from '@src-v2/hooks';
import { pluralFormat } from '@src-v2/utils/number-utils';

const fileName = filePath => filePath.replace(/^.*[\\/]/, '');

export const MaterialChangeFactory = props => {
  const { session } = useInject();
  return (
    <AsyncBoundary
      errorFallback={
        <ErrorMessage>
          Material change cannot be displayed.{' '}
          {session.data.jiraServiceDeskEnabled ? (
            <>
              <HelpModalButton /> if problem persists.
            </>
          ) : (
            'if problem persists, contact your Apiiro admin.'
          )}
          ;
        </ErrorMessage>
      }>
      <MaterialChange {...props} />
    </AsyncBoundary>
  );
};

const ErrorMessage = styled(({ children, ...props }) => {
  return <Paragraph {...props}>{children}</Paragraph>;
})`
  color: var(--color-red-55);
  display: flex;
  gap: 2rem;
`;

const MaterialChange = ({
  governanceRule,
  thenSubType,
  materialChange,
  repository,
  commitSha,
  whenIndex = 0,
  developerProfileByIdentityKey,
}) => {
  const { type: whenType, value: whenValue } = governanceRule.when[whenIndex];
  const { name, className, relativeFilePath, lineNumber, methodName, apiName, methodSignature } =
    materialChange?.codeReference ?? {};
  const displayName = name || className || apiName;

  if (materialChange.partialMaterialChanges) {
    return (
      <CompoundMaterialChange
        materialChange={materialChange}
        governanceRule={governanceRule}
        thenSubType={thenSubType}
        repository={repository}
        commitSha={commitSha}
        developerProfileByIdentityKey={developerProfileByIdentityKey}
      />
    );
  }

  switch (whenType) {
    case 'CommitBehavior':
      return <AbnormalBehaviorMaterialChange materialChange={materialChange} />;
    case 'InputValidation':
      return (
        <InputValidationMaterialChange
          materialChange={materialChange}
          thenSubType={thenSubType}
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}
          lineNumber={lineNumber}
          whenValue={whenValue}
        />
      );

    case 'Secrets':
      const { secret } = materialChange;
      return (
        <>
          <Paragraph>
            <MaterialChangesRow>
              Secrets were {thenSubType === 'Removed' ? 'removed' : 'exposed'} at&nbsp;
              <CommitCodeReference
                repository={repository}
                commitSha={commitSha}
                relativeFilePath={secret.relativeFilePath}
                lineNumber={lineNumber}>
                {fileName(secret.relativeFilePath)}
              </CommitCodeReference>
              &nbsp;of type&nbsp;<Strong>{secret.fileClassification}</Strong>
            </MaterialChangesRow>
          </Paragraph>
          {secret.censoredValues}
        </>
      );

    case 'Licenses':
      const { licenseName, dependencies, dependencyDeclarations } = materialChange;
      const actualDependencies =
        dependencies && dependencies.length > 0 ? dependencies : dependencyDeclarations;
      return (
        <>
          <Paragraph>
            Dependant on packages with license
            <Strong>{licenseName}</Strong>
          </Paragraph>
          {actualDependencies?.map(dependency => (
            <Dependency showVersions key={dependency.name} dependency={dependency} />
          ))}
        </>
      );

    case 'SensitiveData':
      return (
        <SensitiveDataMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          whenValue={whenValue}
          thenSubType={thenSubType}
          name={name}
          methodName={methodName}
          fieldNames={materialChange.fieldNames ?? {}}
          lineNumber={lineNumber}
        />
      );

    case 'Authorization':
      return (
        <>
          <Paragraph>
            Authorization is missing for API methods of{' '}
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={relativeFilePath}
              lineNumber={lineNumber}>
              {displayName}
            </CommitCodeReference>
          </Paragraph>
          <Paragraph>
            {whenValue !== 'any' && <Paragraph>under {whenValue}</Paragraph>}
            <HighlightedCode language="java" code={methodSignature} />
          </Paragraph>
        </>
      );

    case 'Authentication': // Relevant only to Node and demo
      return (
        <>
          <MaterialChangesRow>
            <Paragraph>
              <Strong>Authentication changes</Strong> to API {apiName} defined at{' '}
              <CommitCodeReference
                repository={repository}
                commitSha={commitSha}
                relativeFilePath={relativeFilePath}
                lineNumber={lineNumber}>
                {displayName || relativeFilePath}
              </CommitCodeReference>
            </Paragraph>
          </MaterialChangesRow>
          <MaterialChangesRow>
            {materialChange.descriptions.map(description => (
              <Paragraph>{description}</Paragraph>
            ))}
          </MaterialChangesRow>
        </>
      );

    case 'ExposedToInternet': // This is a demo only option
      return (
        <>
          <Paragraph>Service</Paragraph>
          <Strong>{materialChange.serviceReference.name}</Strong>
          <Paragraph>is</Paragraph>
          <ExternalLink href="https://bitbucket.apiiro.com:8443/projects/LIM/repos/terraform-lab/commits/9b71c152c195fa0229606c62d7ba67ce8b69a05d" />
        </>
      );

    case 'ExternalDependency':
      const { dependency, previousVersion, newVersion } = materialChange;
      switch (thenSubType) {
        case 'Downgraded':
        case 'Upgraded':
          return (
            <DependencyMaterialChange dependency={dependency} thenSubType={thenSubType}>
              {' '}
              {previousVersion && (
                <>
                  from <Strong>{previousVersion}</Strong>
                </>
              )}
              {newVersion && (
                <>
                  {' '}
                  to <Strong>{newVersion}</Strong>
                </>
              )}
            </DependencyMaterialChange>
          );

        default:
          return (
            <DependencyMaterialChange dependency={dependency} thenSubType={thenSubType}>
              {' '}
              at{' '}
              <CommitCodeReference
                repository={repository}
                commitSha={commitSha}
                relativeFilePath={dependency?.codeReference?.relativeFilePath}>
                {name ?? dependency.codeReference.relativeFilePath}
              </CommitCodeReference>
            </DependencyMaterialChange>
          );
      }

    case 'ScaFindings':
      return (
        <>
          Vulnerability {pluralFormat(materialChange.cveCount, 'CVE', null, true)}{' '}
          {pluralFormat(materialChange.cveCount, 'was', 'were')}{' '}
          <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>{' '}
          <Dependency showVersions dependency={materialChange.dependency} />
        </>
      );

    case 'EntityInsights':
      return (
        <EntityInsightsMaterialChange
          materialChange={materialChange}
          commitSha={commitSha}
          repository={repository}
        />
      );

    case 'DataModel':
      return (
        <>
          <Paragraph>
            Data model{' '}
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={relativeFilePath}
              lineNumber={lineNumber}>
              {displayName}
            </CommitCodeReference>{' '}
            was <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>
          </Paragraph>
          {Boolean(materialChange.descriptions?.length) && (
            <MaterialChangesRow>
              {materialChange.descriptions.map(description => (
                <Paragraph>{description}</Paragraph>
              ))}
            </MaterialChangesRow>
          )}
        </>
      );

    case 'Infrastructure':
      return (
        <TerraformResourceMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
        />
      );

    case 'Dockerfile':
      return (
        <DockerfileMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
        />
      );

    case 'Api':
      return (
        <ApiMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );

    case 'SecurityControls':
      return (
        <SecurityControlsMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );

    case 'SensitiveFiles':
      const { filePath, changeType } = materialChange;
      return (
        <>
          <Paragraph>File</Paragraph>
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={filePath}
            lineNumber={lineNumber}>
            {fileName(filePath)}
          </CommitCodeReference>
          <Strong>{thenSubTypeToVerb(changeType)}</Strong>
        </>
      );

    case 'PipelineConfigurationFiles':
      return (
        <Paragraph>
          Pipeline configuration file{' '}
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={materialChange.filePath}
            lineNumber={lineNumber}>
            {fileName(materialChange.filePath)}
          </CommitCodeReference>{' '}
          of provider <Strong>{materialChange.iacFrameworkDescription}</Strong> was{' '}
          <Strong>{thenSubTypeToVerb(materialChange.changeType)}</Strong>
        </Paragraph>
      );

    case 'ApiClassification': {
      return (
        <ApiClassificationMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'ApiAuthorizationRole': {
      return (
        <ApiAuthorizationRoleMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'ApiSecurityPolicy': {
      return (
        <ApiSecurityPolicyMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'BehindApiGateway': {
      return (
        <ApiBehindApiGatewayMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'LegacySastFindings': {
      return (
        <ApiCheckmarxVulnerabilityMaterialChange
          commitSha={commitSha}
          repository={repository}
          relativeFilePath={relativeFilePath}
          materialChange={materialChange}
          thenSubType={thenSubType}
          apiName={displayName}
        />
      );
    }

    case 'NewDeveloper':
    case 'DeveloperFirstCommitToRepository': {
      return <NewDeveloperChange materialChange={materialChange} />;
    }

    case 'RbacRole': {
      return (
        <RbacRoleMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'DataModelAccess': {
      return (
        <DataModelAccessMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          thenSubType={thenSubType}
        />
      );
    }

    case 'MandatoryTechnology':
      const { mandatoryFramework, mandatoryTechnologyDisplayName } = materialChange;
      return (
        <>
          <Paragraph>
            Usage of unauthorized technology <Strong>{mandatoryFramework}</Strong> of type{' '}
            <Strong>{mandatoryTechnologyDisplayName}</Strong> was{' '}
            <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>{' '}
            {thenSubType === 'Added' ? 'at' : 'from'}
          </Paragraph>
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={relativeFilePath}
            lineNumber={lineNumber}>
            {fileName(relativeFilePath)}
          </CommitCodeReference>
        </>
      );

    case 'Technology':
      const { framework, technologyDisplayName } = materialChange;
      return (
        <>
          Usage of the framework <Strong>{framework}</Strong> of type{' '}
          <Strong>{technologyDisplayName}</Strong> was{' '}
          <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>{' '}
          {thenSubType === 'Added' ? 'at' : 'from'}{' '}
          <CommitCodeReference
            repository={repository}
            commitSha={commitSha}
            relativeFilePath={relativeFilePath}
            lineNumber={lineNumber}>
            {fileName(relativeFilePath)}
          </CommitCodeReference>
        </>
      );

    case 'UserFacing':
      return <Paragraph>Is user facing</Paragraph>;

    case 'ApiMethodParameters':
      return (
        <ApiMethodParameterMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          relativeFilePath={relativeFilePath}
        />
      );

    case 'AccessingStorageBucket':
      return (
        <StorageBucketAccessMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          relativeFilePath={relativeFilePath}
          thenSubType={thenSubType}
        />
      );

    case 'ManagedCheckov':
      const { entity } = materialChange;
      return (
        <ManagedCheckovMaterialChange
          isAdded={materialChange.isAdded}
          commitSha={commitSha}
          repository={repository}
          lineNumber={entity.lineNumber}
          filePath={entity.filePath}
          entityDisplayName={entity.displayName}
          technologyDisplayName={
            entity.cicdPipelineDeclarationReferences[0].cicdTechnologyDisplayName
          }
        />
      );
    case 'EmbeddedSemgrep':
      return (
        <ManagedSemgrepMaterialChange
          isAdded={materialChange.isAdded}
          commitSha={commitSha}
          repository={repository}
          lineNumber={materialChange.lineNumber}
          filePath={materialChange.relatedFilePath}
          entityDisplayName={materialChange.displayName ?? materialChange.finding?.displayName}
        />
      );
    case 'SensitiveDataExitPoint':
      return (
        <SensitiveDataExitPointMaterialChange
          commitSha={commitSha}
          repository={repository}
          materialChange={materialChange}
          relativeFilePath={relativeFilePath}
          name={name}
        />
      );

    case 'TerraformMisconfiguration':
      return (
        <TerraformMisconfigurationsMaterialChange
          materialChange={materialChange}
          repository={repository}
          commitSha={commitSha}
        />
      );

    case 'ServerlessFunction':
      const { serverlessFunction } = materialChange;
      return (
        <ServerlessFunctionMaterialChange
          serverlessFunction={serverlessFunction}
          repository={repository}
          commitSha={commitSha}
        />
      );

    case 'ProtobufMessage':
      const { protobufMessage, descriptions, contentSummary, codeReference } = materialChange;
      return (
        <ProtobufMessageMaterialChange
          protobufMessage={protobufMessage}
          repository={repository}
          commitSha={commitSha}
          descriptions={descriptions}
          thenSubType={thenSubType}
          contentSummary={contentSummary}
          codeReference={codeReference}
        />
      );

    case 'ProtobufService':
      return (
        <ProtobufServiceMaterialChange
          materialChange={materialChange}
          repository={repository}
          commitSha={commitSha}
          thenSubType={thenSubType}
        />
      );

    case 'SensitiveDataPresence':
      return (
        <DataOfInterestPresenceMaterialChange
          codeDataTypeName={materialChange.associatedSensitiveDataTypeDescription}
        />
      );

    case 'GqlObject':
    case 'GqlOperation':
      return (
        <GqlMaterialChange
          materialChange={materialChange}
          repository={repository}
          commitSha={commitSha}
          thenSubType={thenSubType}
          type={whenType}
        />
      );

    case 'EntitySatisfiesExpression':
      return (
        <EntitySatisfiesExpressionMaterialChange
          materialChange={materialChange}
          thenSubType={thenSubType}
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}
          lineNumber={lineNumber}
          whenValue={whenValue}
        />
      );
    default:
      console.warn(`unsupported materialChange type ${whenType ?? ''}`);
  }
};
