import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Gutters } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { UpgradeRequestModal } from '@src-v2/components/marketing/upgrade-request-modal';
import { usePageModalPresenter } from '@src-v2/components/modals/page-modal-presenter';
import { SmartPolicyModal } from '@src-v2/containers/modals/smart-policy-modal';
import { ToastSuccessMessage } from '@src-v2/containers/risks/secrets/secrets-exclusion/secrets-exclusion-menu';
import { SecretsExclusionModal } from '@src-v2/containers/risks/secrets/secrets-exclusion/secrets-exclusion-modal';
import { SmartPolicyDisplayBlock } from '@src-v2/containers/smart-policy/smart-policy-display-block';
import { useInject, useLocalStorage, useQueryParams, useToggle } from '@src-v2/hooks';
import { useModalState } from '@src-v2/hooks/use-modal-state';
import { GovernanceType } from '@src-v2/types/enums/GovernanceType';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { CategorySelect } from '@src/blocks/GovernancePage/blocks/CategorySelect';
import { SensitiveDataDefinition } from '@src/blocks/GovernancePage/blocks/Definitions/SensitiveDataDefinition';
import { SensitiveDataDefinitionModal } from '@src/blocks/GovernancePage/blocks/Definitions/SensitiveDataDefinitionModal';
import { SecretsExclusionDefinition } from '@src/blocks/GovernancePage/blocks/Definitions/secrets-exclusion-definition';
import RiskyIssueDefinition from '@src/blocks/RiskyIssueDefinition';
import { DefinitionBanner } from '@src/components/DefinitionBanner';
import {
  EditRuleModal,
  EditRuleModalErrorContextProvider,
} from '@src/components/Rule/EditRuleModal';
import { transformDefinitionToRule } from '@src/utils/definitionsToRuleTransformer';
import { filterScopeReadOnlyElements } from '@src/utils/scopeUtils';
import DefinitionConsts, {
  getDefinitionNamePrefix,
  getDefinitionType,
} from './blocks/Definitions/DefinitionConsts';
import { EditDefinitionModal } from './blocks/Definitions/EditDefinitionModal';
import { InternalFrameworkDefinition } from './blocks/Definitions/InternalFrameworkDefinition';
import GovernanceItemsTable from './blocks/GovernanceItemsTable';
import { ProcessTags } from './blocks/ProcessTags';
import Rule from './blocks/Rule';
import { definitionToRuleFormat } from './blocks/definitionOptionsUtils';

const SUB_PATHS = {
  RULES: 'rules',
  DEFINITIONS: 'definitions',
};

export const filterReadOnlyGivenOptions = givenOptions =>
  _.mapValues(givenOptions, option => ({
    ...option,
    options: filterScopeReadOnlyElements(option.options),
  }));

export const filterReadOnlyOptions = options => ({
  ...options,
  given: filterReadOnlyGivenOptions(options.given),
});

const GovernancePage = ({
  fetchData,
  clearRulesData,
  clearDefinitionsData,
  projectProfilesByKey,
  assetCollectionProfilesByKey,
  editedRule,
  setEditedRule,
  editRuleIsOpen,
  editErrors,
  rules,
  options,
  isLoading,
  addRule,
  closeEditRule,
  setEditErrors,
  deleteRule,
  failedToLoad,

  // Definitions (e.g. api classification)
  definitions,
  editedDefinition,
  editedDefinitionType,
  editDefinitionIsOpen,
  editDefinitionErrorMessage,
  definitionOptions,
  setEditedDefinition,
  setEditedDefinitionType,
  addDefinition,
  closeEditDefinition,
  applyDefinitionModifications,
  deleteDefinition,
  issueTypes,
}) => {
  const { subscription, governance, toaster, application } = useInject();
  const [isUpgradePlanOpen, toggleUpgradePlan] = useToggle();
  const { governanceType = SUB_PATHS.RULES } = useParams();
  const [modalElement, setModal, closeModal] = useModalState();
  const {
    queryParams: { edit: linkedEditItemKey, create: createItem },
    updateQueryParams,
  } = useQueryParams();

  const [ruleByDefinition, setRuleByDefinition] = useLocalStorage('rule.by.definition', {});
  const [openedCreateRuleFromBanner, setOpenedCreateRuleFromBanner] = useState(false);

  const apiDefinitions = definitions.filter(
    def => def.type === DefinitionConsts.typeDisplayNameByType.API
  );

  const pageModalPresenter = usePageModalPresenter();

  const createRuleOptions = useMemo(
    () =>
      !application.isFeatureEnabled(FeatureFlag.SmartPolicies)
        ? addRule
        : [
            { title: 'Classic policy', handler: addRule },
            {
              title: 'Explorer policy',
              handler: async () => {
                const result = await pageModalPresenter.showModal(SmartPolicyModal, {
                  governanceOptions: options,
                });
                if (result) {
                  toaster.success(<>Policy was created successfully.</>);
                  await fetchData({ invalidateCache: true });
                  document.location.hash = `#${result}`;
                }
              },
            },
          ],
    [pageModalPresenter, toaster, options]
  );

  const handleEditRule = useCallback(
    async (editedRule, editForDuplicate) => {
      let updatedRuleKey;
      switch (editedRule.type) {
        case 'apiiroQlPolicyRule':
          updatedRuleKey = await pageModalPresenter.showModal(SmartPolicyModal, {
            editedRule: editForDuplicate ? null : editedRule,
            initialRuleEdits: editForDuplicate ? editedRule : null,
            governanceOptions: options,
          });
          if (updatedRuleKey) {
            toaster.success(<>Policy was saved successfully.</>);
            await fetchData({ invalidateCache: true });
            if (editForDuplicate) {
              document.location.hash = `#${updatedRuleKey}`;
            }
          }
          break;

        default:
          setEditedRule(editedRule);
          break;
      }
    },
    [pageModalPresenter, setEditedRule, options]
  );

  const handleDuplicateRule = useCallback(
    async ruleToDuplicate => {
      const duplicatedRule = {
        ..._.cloneDeep(ruleToDuplicate),
        key: crypto.randomUUID(),
        name: `Copy of ${ruleToDuplicate.name}`,
        isDuplicated: true,
      };

      await handleEditRule(duplicatedRule, true);
    },
    [handleEditRule]
  );

  useEffect(() => {
    if (editDefinitionIsOpen) {
      setModal(getDefinitionEditModalByType(editedDefinitionType));
    }

    if (!editDefinitionIsOpen && modalElement) {
      closeModal();
    }
  }, [editedDefinitionType, editDefinitionIsOpen, setModal]);

  const ruleGlobalEditErrorMessages = useMemo(
    () =>
      editErrors &&
      editErrors
        .filter(errorInfo => !errorInfo.rulePortionType)
        .map(errorInfo => errorInfo.message)
        .join('\n'),
    [editErrors]
  );

  useEffect(() => {
    if (editRuleIsOpen && !modalElement) {
      setModal(
        <EditRuleModal
          disabled={!options}
          ruleTitleName="policy"
          rule={editedRule}
          rules={rules}
          options={options}
          onConfirm={confirmEditRule}
          onCancel={closeEditRule}
          ruleNameExample="PII exposed by an internet facing API"
          filterReadOnlyOptions={filterReadOnlyOptions}
          ruleByDefinition={ruleByDefinition}
          setRuleByDefinition={setRuleByDefinition}
          openedCreateRuleFromBanner={openedCreateRuleFromBanner}
          setOpenedCreateRuleFromBanner={setOpenedCreateRuleFromBanner}
          validatedRule={rule => Boolean(rule.category)}
          sideComponent={props => (
            <>
              <CategorySelect required {...props} />
              <ProcessTags {...props} />
            </>
          )}>
          {props => (
            <Rule
              {...props}
              validationErrors={editErrors}
              isSaving={false}
              filterReadOnlyGivenOptions={filterReadOnlyGivenOptions}
            />
          )}
        </EditRuleModal>
      );
    }

    if (!editRuleIsOpen && modalElement) {
      closeModal();
    }
  }, [editRuleIsOpen, ruleGlobalEditErrorMessages]);

  const confirmEditRule = useCallback(
    async itemToSave => {
      const errors = await governance.saveRule(itemToSave);
      if (!errors?.length) {
        await fetchData({ invalidateCache: true });

        closeEditRule();
        return false;
      }

      setEditErrors(errors);
      return true;
    },
    [setEditErrors, closeEditRule]
  );

  const suggestRuleDisplayBlock = useCallback(
    item => {
      switch (item.type) {
        case 'apiiroQlPolicyRule':
          return <SmartPolicyDisplayBlock rule={item} />;

        default:
          return <Rule isReadOnly rule={item} options={options} />;
      }
    },
    [options]
  );

  const confirmEditDefinition = useCallback(
    async (itemToSave, throwError) => {
      const [success, displayName] = await applyDefinitionModifications({
        definitionRaw: itemToSave,
        throwError,
      });

      if (!success) {
        return false;
      }

      setEditedDefinitionType(null);
      closeEditDefinition();
      closeModal();

      if (!editedDefinition || editedDefinition?.key === ruleByDefinition?.definitionKey) {
        const rule = transformDefinitionToRule(editedDefinitionType, {
          ...itemToSave,
          displayName,
        });

        setRuleByDefinition(rule);
      }

      return true;
    },
    [
      applyDefinitionModifications,
      closeEditDefinition,
      setEditedDefinitionType,
      editedDefinitionType,
      editedDefinition,
      setRuleByDefinition,
    ]
  );

  const getDefinitionEditModalByType = type => {
    switch (type) {
      case DefinitionConsts.types.API:
        return (
          <EditRuleModal
            disabled={!options}
            ruleTitleName="definition"
            rule={
              editedDefinition ? definitionToRuleFormat(editedDefinition, definitionOptions) : null
            }
            rules={
              apiDefinitions
                ? apiDefinitions.map(definition =>
                    definitionToRuleFormat(definition, definitionOptions)
                  )
                : []
            }
            options={definitionOptions}
            onConfirm={confirmEditDefinition}
            onCancel={() => {
              setEditedDefinitionType(null);
              closeEditDefinition();
              closeModal();
            }}
            ruleNameExample="Internet Facing">
            {props => <Rule {...props} isSaving={false} />}
          </EditRuleModal>
        );
      case DefinitionConsts.types.CustomSensitiveData:
        return (
          <SensitiveDataDefinitionModal
            definition={editedDefinition}
            onSubmit={confirmEditDefinition}
            onClose={() => {
              setEditedDefinitionType(null);
              closeEditDefinition();
            }}
          />
        );
      case DefinitionConsts.types.SecretsExclusion:
        return (
          <SecretsExclusionModal
            definition={editedDefinition}
            onClose={() => {
              setEditedDefinitionType(null);
              closeEditDefinition();
            }}
            onSubmit={async itemsToSave => {
              await confirmEditDefinition(itemsToSave, true);
              toaster.success(ToastSuccessMessage);
            }}
          />
        );
      default:
        return (
          <EditDefinitionModal
            definition={editedDefinition}
            issueTypes={issueTypes}
            projectProfilesByKey={projectProfilesByKey}
            assetCollectionProfilesByKey={assetCollectionProfilesByKey}
            editedDefinitionType={editedDefinitionType}
            errorMessage={editDefinitionErrorMessage}
            onCancel={() => {
              closeEditDefinition();
              closeModal();
            }}
            openApiDefinition={() => setEditedDefinitionType(DefinitionConsts.types.API)}
            openSensitiveDataModal={() =>
              setEditedDefinitionType(DefinitionConsts.types.CustomSensitiveData)
            }
            setEditedDefinitionType={setEditedDefinitionType}
            onConfirm={confirmEditDefinition}
          />
        );
    }
  };

  const title = governanceType === SUB_PATHS.DEFINITIONS ? 'Definitions' : 'Policies';

  useEffect(() => {
    governanceType === SUB_PATHS.RULES ? handleRulesSmartLink() : handleDefinitionSmartLink();

    function handleRulesSmartLink() {
      if (editRuleIsOpen || !options) {
        return;
      }

      if (createItem) {
        addRule();
        return clearQueryParams();
      }
      if (linkedEditItemKey && rules?.length) {
        const targetRule = rules.find(rule => rule.key === linkedEditItemKey);
        if (targetRule) {
          setEditedRule(targetRule);
        }

        return clearQueryParams();
      }
    }

    function handleDefinitionSmartLink() {
      if (editDefinitionIsOpen) {
        return;
      }

      if (createItem) {
        const createType = DefinitionConsts.types[createItem];
        if (createType) {
          setEditedDefinitionType(createType);
        }
        addDefinition();

        return clearQueryParams();
      }

      if (linkedEditItemKey && definitions?.length) {
        const targetDefinition = definitions.find(
          definition => definition.key === linkedEditItemKey
        );

        if (targetDefinition) {
          setEditedDefinitionType(getDefinitionType(targetDefinition));
          setEditedDefinition(targetDefinition);
        }

        return clearQueryParams();
      }
    }

    function clearQueryParams() {
      updateQueryParams({ edit: null, create: null });
    }
  }, [editDefinitionIsOpen, linkedEditItemKey, createItem, governanceType, definitions, rules]);

  return (
    <Page title={title}>
      <Gutters>
        {governanceType === SUB_PATHS.DEFINITIONS && !_.isEmpty(ruleByDefinition) && (
          <DefinitionBanner
            rule={ruleByDefinition}
            addRule={addRule}
            setRule={setRuleByDefinition}
            setOpenedCreateRuleFromBanner={setOpenedCreateRuleFromBanner}
          />
        )}
        {governanceType === SUB_PATHS.RULES ? (
          <GovernanceItemsTable
            fetchData={fetchData}
            clearData={clearRulesData}
            itemOptions={options}
            isLoading={isLoading}
            items={rules}
            createItemFunctionOrOptions={createRuleOptions}
            setEditedItem={handleEditRule}
            duplicateItem={handleDuplicateRule}
            definitionFormatting={suggestRuleDisplayBlock}
            deleteItem={deleteRule}
            itemType={GovernanceType.Policy}
            itemTypePlural="policies"
            failedToLoad={failedToLoad}
          />
        ) : (
          <GovernanceItemsTable
            fetchData={fetchData}
            clearData={clearDefinitionsData}
            itemOptions={definitionOptions}
            isLoading={isLoading}
            items={definitions}
            createItemFunctionOrOptions={addDefinition}
            setEditedItem={definition => {
              setEditedDefinitionType(getDefinitionType(definition));
              setEditedDefinition(definition);
            }}
            deleteItem={definition =>
              deleteDefinition({ definition, type: getDefinitionType(definition) })
            }
            setRuleByDefinition={setRuleByDefinition}
            ruleByDefinition={ruleByDefinition}
            namePrefixFormatter={definition => getDefinitionNamePrefix(definition)}
            definitionFormatting={definition => {
              switch (definition.type) {
                case 'RiskRelatedIssueDefinition':
                case 'UserStoryDefinition':
                  return <RiskyIssueDefinition definition={definition} />;

                case 'InternalFrameworkDefinition':
                  return <InternalFrameworkDefinition definition={definition} />;

                case 'CustomSensitiveDataDefinition':
                  return <SensitiveDataDefinition definition={definition} />;
                case DefinitionConsts.typeDisplayNameByType.SecretsExclusion:
                  return <SecretsExclusionDefinition definition={definition} />;
                default:
                  return null;
              }
            }}
            itemType={GovernanceType.Definition}
            itemTypePlural="definitions"
            itemConverter={definition =>
              definition.type === 'ApiClassificationDefinition'
                ? definitionToRuleFormat(definition, definitionOptions)
                : definition
            }
            nameFormatter={definition => {
              switch (definition.type) {
                case 'RiskRelatedIssueDefinition':
                  return `${definition.processTag.name} (${definition.processTag.processName})`;

                case 'InternalFrameworkDefinition':
                  return definition.frameworkName;

                default:
                  return null;
              }
            }}
          />
        )}
      </Gutters>

      {subscription.limitations.limitGovernanceRules && isUpgradePlanOpen && (
        <UpgradeRequestModal onClose={toggleUpgradePlan} />
      )}

      <EditRuleModalErrorContextProvider
        errorMessage={ruleGlobalEditErrorMessages || editDefinitionErrorMessage}>
        {modalElement}
      </EditRuleModalErrorContextProvider>
    </Page>
  );
};

export default GovernancePage;
