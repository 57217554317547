import _ from 'lodash';
import filtersService from '@src/services/filtersService';
import { apiInsightConditions } from '@src/utils/apiUtils';

const hasControlUsage = (profileHasControlUsage, hasControlUsageByProfileKey) =>
  Object.values(profileHasControlUsage).some(targets => targets?.length) ||
  hasControlUsageByProfileKey;

export const getApiFiltersOptions = ({ profile, elements, integrations }) => {
  const options = [
    {
      name: 'Exposure',
      filterOptions: [
        {
          name: 'Sensitive',
          displayName: 'Sensitive',
        },
        {
          name: 'UserFacing',
          displayName: 'User Facing',
          shouldDisplay: profile.hasSwaggerRoles,
        },
        {
          name: 'InternetFacing',
          displayName: 'Internet Facing',
        },
      ],
    },
    {
      name: 'Data',
      filterOptions: [
        {
          name: 'ExposesSensitiveData',
          displayName: 'Exposes Sensitive Data',
        },
        {
          name: 'InvolvesSensitiveData',
          displayName: 'Involves Sensitive Data',
        },
      ],
    },
    {
      name: 'Controls',
      filterOptions: [
        {
          name: 'MissingInputValidation',
          displayName: 'Missing Input Validation',
          shouldDisplay: hasControlUsage(
            profile.hasValidationUsage,
            profile.validationCodeParsingTargetByProfile
          ),
        },
        {
          name: 'MissingAuthorization',
          displayName: 'Missing Authorization',
          shouldDisplay: hasControlUsage(
            profile.hasApiAuthorizationUsage,
            profile.authorizationCodeParsingTargetByProfile
          ),
        },
      ],
    },
    {
      name: 'Security',
      filterOptions: [
        {
          name: 'CheckmarxVulnerabilities',
          displayName: 'Vulnerabilities - 3rd Party',
          shouldDisplay: integrations.hasCodeVulnerabilityScan,
        },
      ],
    },
    {
      name: 'Infrastructure',
      filterOptions: [
        {
          name: 'BehindApiGateway',
          displayName: 'Behind API Gateway',
          shouldDisplay: integrations.connectedToApiGateway,
        },
        {
          name: 'NotBehindApiGateway',
          displayName: 'Not Behind API Gateway',
          shouldDisplay: integrations.connectedToApiGateway,
        },
        {
          name: 'ReadsFromStorageBucket',
          displayName: 'Reads From Storage Bucket',
        },
        {
          name: 'WritesToStorageBucket',
          displayName: 'Writes To Storage Bucket',
        },
      ],
    },
  ];

  const httpMethods = _.uniq(elements.map(api => api.httpMethod));
  if (!_.isEmpty(httpMethods)) {
    options.push({
      name: 'HttpMethod',
      displayName: 'HTTP Method',
      filterOptions: httpMethods.map(method => ({
        name: method,
        displayName: method,
      })),
    });
  }

  return options.map(option => ({
    ...option,
    filterOptions: _.filter(option.filterOptions, filter => filter.shouldDisplay !== false),
  }));
};

export const filterApiToCondition = {
  Sensitive: apiInsightConditions.isSensitive,
  UserFacing: apiInsightConditions.isUserFacing,
  InternetFacing: apiInsightConditions.isInternetFacing,
  ExposesSensitiveData: apiInsightConditions.exposingSensitiveData,
  InvolvesSensitiveData: apiInsightConditions.involvingSensitiveData,
  MissingInputValidation: apiInsightConditions.missingInputValidation,
  MissingAuthorization: apiInsightConditions.missingAuthorization,
  CheckmarxVulnerabilities: apiInsightConditions.isVulnerable,
  BehindApiGateway: apiInsightConditions.isBehindGateway,
  NotBehindApiGateway: api => !api.apiGatewayReference,
  ReadsFromStorageBucket: apiInsightConditions.readsFromStorageBucket,
  WritesToStorageBucket: apiInsightConditions.writesToStorageBucket,
};

export const filterApis = (allItems, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, item => [
    item.httpRoute,
    item.codeReference.relativeFilePath,
  ]);

  const filterConditions = getFilterConditions(filters);
  if (!_.isEmpty(filterConditions)) {
    filtered = filtered.filter(api => doesApiMatch(api, filterConditions, filterOperator));
  }

  const httpMethodsFilter = filters.listFilters?.HttpMethod;
  if (!_.isEmpty(httpMethodsFilter)) {
    filtered = filtered.filter(api =>
      filterOperator(
        httpMethodsFilter.map(
          httpMethod => _.isEmpty(httpMethod) || httpMethod === api.codeReference.httpMethod
        )
      )
    );
  }

  return filtered;
};

const getFilterConditions = filters =>
  filters.booleanFilters.map(filter => filterApiToCondition[filter]).filter(Boolean);
const doesApiMatch = (api, filterConditions, filterOperator) =>
  filterOperator(filterConditions, condition => condition(api));
