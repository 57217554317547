import _ from 'lodash';
import filtersService from '@src/services/filtersService';

export const getStorageBucketInteractionsFilterOptions = ({ elements }) => {
  const actions = _.uniq(_.map(elements, 'methodAction').flat()).sort();
  const providers = _.uniq(_.map(elements, 'provider').flat()).sort();

  return [
    {
      name: 'Actions',
      filterOptions: filtersService.getListFilterOptions(actions),
    },
    {
      name: 'Providers',
      filterOptions: filtersService.getListFilterOptions(providers),
    },
  ];
};

export const filterStorageBucketInteractionsToCondition = {
  Read: storageBucketMethod => storageBucketMethod.methodAction === 'Read',
  Write: storageBucketMethod => storageBucketMethod.methodAction === 'Write',
};

export const filterStorageBucketInteractions = (allItems, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, item => [
    item.methodName,
    item.provider,
    ...item.bucketNames.flat(),
  ]);

  const providersFilters = filters.listFilters?.Providers;
  if (!_.isEmpty(providersFilters)) {
    filtered = filtered.filter(storageBucketMethod =>
      filterOperator(providersFilters.map(provider => storageBucketMethod.provider === provider))
    );
  }

  const filterConditions = filters.booleanFilters
    .map(filter => filterStorageBucketInteractionsToCondition[filter])
    .filter(Boolean);
  if (!_.isEmpty(filterConditions)) {
    filtered = filtered.filter(storageBucketMethod =>
      filterOperator(filterConditions, condition => condition(storageBucketMethod))
    );
  }
  return filtered;
};
