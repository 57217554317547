import styled from 'styled-components';
import { FontBodySmall } from '@src/style/common';

export const StyledRemainingChars = styled.span`
  ${FontBodySmall};
  opacity: 0.7;
  display: flex;
  justify-content: flex-end;
  margin: 0;
`;
