import { useMemo } from 'react';
import styled from 'styled-components';
import { ScrollSyncContext } from '@src-v2/components/scroll-sync';
import { ScrollToTop } from '@src-v2/components/scroll-to-top';
import { ContributorCard } from '@src-v2/containers/contributors/contributor-card';
import { ApplicationGroupCard } from '@src/blocks/ConsumableInfiniteScroll/blocks/ApplicationGroupCard';
import { ClusterCard } from '@src/blocks/ConsumableInfiniteScroll/blocks/ClusterCard';
import { TeamCard } from '@src/blocks/ConsumableInfiniteScroll/blocks/TeamCard';
import CodeProfileWidget from './ConsumableProfileWidget';

const prefixesForGroups = {
  Risk: 'Risk',
  'Containing risk level': 'Containing risk',
  'Application Group': 'App group',
  'Business unit': 'Business unit',
  Team: 'Team',
  'Application type': 'App type',
  'Compliance requirements': 'Compliance',
  'Estimated revenue': 'Est. revenue',
  'Number of users': 'No. of users',
  // specific to repositories filters
  Application: 'Application',
  Licenses: 'Licenses',
  'Repository group': 'Repo group',
  'Server URL': 'Server URL',
};

export default function Consumables({ name, profiles, filterOptions }) {
  const appsAndReposFiltersMetadata = useMemo(
    () =>
      filterOptions.reduce((map, { key, title, options }) => {
        options.forEach(
          option =>
            (map[`${key}.${option.value}`] = {
              group: title,
              title: option.title,
              prefix: prefixesForGroups[title],
            })
        );
        return map;
      }, {}),
    [filterOptions]
  );

  return (
    <ScrollSyncContext>
      <Container>
        {profiles.map(profile => (
          <ProfileCardFactory
            key={profile.key}
            name={name}
            profile={profile}
            appsAndReposFiltersMetadata={appsAndReposFiltersMetadata}
          />
        ))}
        <ScrollToTop />
      </Container>
    </ScrollSyncContext>
  );
}

function ProfileCardFactory({ name, profile, appsAndReposFiltersMetadata }) {
  switch (name) {
    case 'developers':
      return <ContributorCard developerProfile={profile} />;

    case 'teams':
      return profile && <TeamCard profile={profile} />;

    case 'applicationGroups':
      return <ApplicationGroupCard profile={profile} />;

    case 'assetCollections':
    case 'repositories':
      return <CodeProfileWidget profile={profile} filtersMetadata={appsAndReposFiltersMetadata} />;

    case 'clusters':
      return profile && <ClusterCard profile={profile} />;

    default:
      return null;
  }
}

const Container = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-flow: column;
  padding-bottom: 10rem;
  gap: 4rem;
`;
