import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { SvgIcon } from '@src-v2/components/icons';

export function ChecklistBox({
  title,
  list,
  actionLabel,
  onAction,
  disabledAction,
  onItemClick,
  ...props
}) {
  return (
    <Container {...props}>
      <ChecklistBox.Title>{title}</ChecklistBox.Title>
      <ChecklistBox.List>
        {list.map((item, index) => (
          <ChecklistBox.ListItem key={index}>
            <Checked
              checked={item.checked}
              onClick={onItemClick && (() => onItemClick(item, index))}
            />
            <Label>{item.label}</Label>
          </ChecklistBox.ListItem>
        ))}
      </ChecklistBox.List>
      <Button disabled={disabledAction} onClick={onAction}>
        {actionLabel}
      </Button>
    </Container>
  );
}

const Container = styled.div`
  width: 84rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 4rem;
  border-radius: 3rem;
  padding: 7rem 6rem;
  background-color: var(--color-blue-25);
  box-shadow: var(--elevation-2);

  ${Button} {
    background-color: var(--color-green-45);
    color: var(--color-blue-gray-70);
    &:hover {
      background-color: var(--color-green-55);
    }

    &[data-disabled] {
      color: var(--color-blue-gray-10);
      background-color: var(--color-blue-gray-35);
    }
  }
`;

ChecklistBox.Title = styled.h2`
  font-weight: 700;
`;

ChecklistBox.List = styled.ul`
  width: 100%;
`;

ChecklistBox.ListItem = styled.li`
  display: flex;
  padding: 1.25rem 2rem 1.25rem 0;
  gap: 1rem;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }
`;

const Checked = styled(({ checked, ...props }) => (
  <div {...props}>{checked && <SvgIcon name="Check" />}</div>
))`
  position: relative;
  display: flex;
  width: 8rem;
  height: 6rem;
  align-items: center;
  justify-content: center;
  color: var(--color-white);
  cursor: ${props => (props.onClick ? 'pointer' : 'auto')};

  &:before {
    content: '';
    position: absolute;
    width: 4.5rem;
    height: 4.5rem;
    border-radius: 100vmax;
    border: ${props =>
      props.checked ? '0.75rem solid transparent' : '0.25rem solid var(--color-blue-gray-40)'};
    background-color: ${props =>
      props.checked ? 'var(--color-green-45)' : 'var(--color-blue-gray-15'};
    box-sizing: content-box;
  }
`;

const Label = styled.span`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
