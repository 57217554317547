import _ from 'lodash';
import uniq from 'lodash/uniq';
import { useMemo } from 'react';
import styled from 'styled-components';
import { VendorIcon } from '@src-v2/components/icons';
import { RiskBadge } from '@src-v2/components/risk/risk-badge';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { ExternalLink } from '@src-v2/components/typography';
import { getVendorIconUrl } from '@src-v2/data/icons';
import { makeUrl } from '@src-v2/utils/history-utils';
import { StyledRiskIcon } from '@src/blocks/RiskPosture/blocks/styles';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { autoIgnoreRiskLevel } from '@src/components/RiskIcon';
import { TitledList } from '@src/components/TitledList';
import { TimelineEvent } from './TimelineEvent';

const StyledMaterialChange = styled(HorizontalStack)`
  color: var(--default-text-color);
  padding-bottom: 3rem;
`;

const StyledIgnoredMaterialChange = styled(StyledMaterialChange)`
  opacity: 0.5;
`;

const StyledPopover = styled(PopoverTip)`
  text-decoration: underline;
`;

const StyledDependencyChange = styled.div`
  padding-bottom: 3rem;
`;

export const TimelineFindingsReport = ({
  isLast,
  isFirst,
  index,
  findingsReportTimelineEvent,
  previousTimelineEvent,
  materialChangeFilters,
}) => {
  const { materialChangesLabelsToCount } = findingsReportTimelineEvent;

  const MaterialChange = ({ isIgnored, children }) =>
    isIgnored ? (
      <StyledIgnoredMaterialChange>
        <div>{children}</div>
        <StyledRiskIcon riskLevel={autoIgnoreRiskLevel} />
      </StyledIgnoredMaterialChange>
    ) : (
      <StyledMaterialChange>{children}</StyledMaterialChange>
    );

  const DetectionString = changeType =>
    changeType === 'Resolved' ? 'no longer detected as' : 'detected as';

  const ChangeSeverity = changeSeverity => (
    <RiskBadge.RiskLevel riskLevel={_.camelCase(changeSeverity)}>
      {changeSeverity}
    </RiskBadge.RiskLevel>
  );

  const ListModificationRender = modification => (
    <StyledDependencyChange>
      {modification.dependency.name}:{modification.dependency.version}&nbsp;
      {DetectionString(modification.changeType)}&nbsp;
      {ChangeSeverity(modification.changeSeverity)}
    </StyledDependencyChange>
  );

  const materialChangesLabels = useMemo(
    () =>
      _.zip(
        Object.entries(materialChangesLabelsToCount),
        findingsReportTimelineEvent.materialChangeLabelsToIsIgnore
      ).map(([[label, count], isIgnored]) => [label, count, isIgnored]),
    [materialChangesLabelsToCount, findingsReportTimelineEvent]
  );

  const findingsReportLabels = useMemo(
    () =>
      _.isEmpty(materialChangeFilters)
        ? materialChangesLabels
        : materialChangesLabels.filter(([label]) => materialChangeFilters.includes(label)),
    [materialChangeFilters, materialChangesLabels]
  );

  const materialChangesCount = useMemo(
    () => _.sumBy(findingsReportLabels, '[1]'),
    [findingsReportLabels]
  );

  const dependencies = uniq(
    findingsReportTimelineEvent.dependencyFindingsModifications?.map(
      modification => modification.dependency?.name
    )
  );

  const runtimeFindingsCount = findingsReportTimelineEvent.apiFindingsModifications?.length ?? 0;

  const codeFindingsCount = findingsReportTimelineEvent.codeFindingsModifications?.length ?? 0;

  const timelineEventTitle = useMemo(() => (
    <>
      Overall risk changed: detected&nbsp;
      {materialChangesCount > 1
        ? `${materialChangesCount} vulnerability changes`
        : 'a vulnerability change'}{' '}
      in a scan from {findingsReportTimelineEvent.provider}{' '}
      {getVendorIconUrl(findingsReportTimelineEvent.provider) && (
        <VendorIcon name={findingsReportTimelineEvent.provider} />
      )}
      &nbsp;
      {dependencies.length !== 0 && (
        <>
          Detected dependency changes in
          <StyledPopover
            linkText={<Number value={dependencies.length} one="package" other="packages" />}
            title="Packages">
            <TitledList
              list={findingsReportTimelineEvent.dependencyFindingsModifications}
              listItemRender={ListModificationRender}
            />
          </StyledPopover>
        </>
      )}
      {runtimeFindingsCount !== 0 && (
        <>
          {dependencies.length !== 0 ? `and d` : `D`}etected {runtimeFindingsCount} runtime findings
        </>
      )}
      {codeFindingsCount !== 0 && <>Detected {codeFindingsCount} code findings</>}
      {findingsReportTimelineEvent.reportUrl && (
        <ExternalLink href={findingsReportTimelineEvent.reportUrl}> View Scan</ExternalLink>
      )}
    </>
  ));

  const materialChanges = useMemo(
    () =>
      _.sortBy(findingsReportLabels, '[2]', '[0]').map(([label, count, isIgnored]) => (
        <MaterialChange key={label} isIgnored={isIgnored}>
          {count === 1 ? label : `${label} (${count})`}
        </MaterialChange>
      )),
    [findingsReportLabels]
  );
  return (
    <TimelineEvent
      currentTimelineEvent={findingsReportTimelineEvent}
      previousTimelineEvent={previousTimelineEvent}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      titleContent={timelineEventTitle}
      body={materialChanges}
      exploreUrl={makeUrl(`/risks`, {
        group: null,
        fl: { Provider: { values: [findingsReportTimelineEvent.provider] } },
      })}
      testMarker="timeline-report"
    />
  );
};
