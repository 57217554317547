import _ from 'lodash';
import { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { EditRuleModalErrorContext } from '@src/components/Rule/EditRuleModal';
import DefinitionConsts from './DefinitionConsts';
import { DefinitionTypeSelector } from './DefinitionTypeSelector';
import { InterestingIssueDefinitionForm } from './InterestingIssueDefinitionForm';
import { InternalFrameworkDefinitionForm as NewInternalFrameworkDefinitionForm } from './InternalFrameworkDefinitionForm';
import { InternalFrameworkDefinitionForm as OldInternalFrameworkDefinitionForm } from './InternalFrameworkDefinitionForm.Old';

const emptyDefinitionForType = type => {
  switch (type) {
    case DefinitionConsts.types.Issue:
    case DefinitionConsts.types.UserStory:
      return {
        key: crypto.randomUUID(),
        allProjects: true,
        projectKeys: [],
        processTagKey: null,
        labels: [],
        titles: [],
        types: [],
        components: [],
      };
    case DefinitionConsts.types.InternalFramework:
      return {
        key: crypto.randomUUID(),
        frameworkName: '',
        dependencies: [],
        frameworkType: '',
      };
    case DefinitionConsts.types.SecretsExclusion:
      return {
        key: crypto.randomUUID(),
      };
    // no default
  }
};

export const EditDefinitionModal = ({
  definition,
  issueTypes,
  projectProfilesByKey,
  assetCollectionProfilesByKey,
  editedDefinitionType,
  onCancel,
  onConfirm,
  openApiDefinition,
  openSensitiveDataModal,
  setEditedDefinitionType,
}) => {
  const { application } = useInject();
  const [definitionType, setDefinitionType] = useState(editedDefinitionType);
  const [editedDefinition, setEditedDefinition] = useState(definition);
  const { errorMessage } = useContext(EditRuleModalErrorContext);
  const [isFormValid, setFormValid] = useState(false);

  const InternalFrameworkDefinitionForm = application.isFeatureEnabled(
    FeatureFlag.NewInternalFrameworkDefinition
  )
    ? NewInternalFrameworkDefinitionForm
    : OldInternalFrameworkDefinitionForm;

  useEffect(() => {
    if (!_.isNil(definitionType)) {
      setEditedDefinitionType(definitionType);
    }
  }, [definitionType, setEditedDefinitionType]);

  useEffect(() => {
    if (definitionType === DefinitionConsts.types.API) {
      openApiDefinition();
    } else if (definitionType === DefinitionConsts.types.CustomSensitiveData) {
      openSensitiveDataModal();
    }
  }, [definitionType, openApiDefinition]);

  const title = _.isNil(definitionType)
    ? 'Select Definition Type'
    : DefinitionConsts.modalTitleByType[definitionType];

  useEffect(() => {
    if (definitionType && !editedDefinition) {
      setEditedDefinition(emptyDefinitionForType(definitionType));
    }
  }, [definitionType, definition]);

  return (
    <EditDefinitionModalWrapper
      title={title}
      disabledSubmitButton={!isFormValid}
      onSubmit={() => isFormValid && onConfirm(editedDefinition)}
      submitText={_.isNil(definition) ? 'Create' : 'Save'}
      onClose={onCancel}
      showFooter={!_.isNil(definitionType)}>
      {_.isNil(definitionType) && (
        <DefinitionTypeSelector
          onSelect={type => {
            setDefinitionType(type);
          }}
        />
      )}
      {editedDefinition && definitionType === DefinitionConsts.types.Issue && (
        <InterestingIssueDefinitionForm
          definition={editedDefinition}
          issueTypes={issueTypes}
          projectProfilesByKey={projectProfilesByKey}
          assetCollectionProfilesByKey={assetCollectionProfilesByKey}
          setDefinition={setEditedDefinition}
          onValidityChange={setFormValid}
          errorMessage={errorMessage}
          filterSectionTitle="Track issues by"
          isTrackingTypeRequired={true}
        />
      )}
      {editedDefinition && definitionType === DefinitionConsts.types.UserStory && (
        <InterestingIssueDefinitionForm
          definition={editedDefinition}
          issueTypes={issueTypes}
          projectProfilesByKey={projectProfilesByKey}
          assetCollectionProfilesByKey={assetCollectionProfilesByKey}
          setDefinition={setEditedDefinition}
          onValidityChange={setFormValid}
          errorMessage={errorMessage}
          filterSectionTitle="Analyze issues with"
          isTrackingTypeRequired={false}
        />
      )}
      {editedDefinition && definitionType === DefinitionConsts.types.InternalFramework && (
        <InternalFrameworkDefinitionForm
          definition={editedDefinition}
          projectProfilesByKey={projectProfilesByKey}
          assetCollectionProfilesByKey={assetCollectionProfilesByKey}
          setDefinition={setEditedDefinition}
          onValidityChange={setFormValid}
          errorMessage={errorMessage}
        />
      )}
    </EditDefinitionModalWrapper>
  );
};

const EditDefinitionModalWrapper = styled(ConfirmationModal)`
  width: 270rem;
`;
