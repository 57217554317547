import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import apiService from '@src/services/apiService';

const clearData = () => ({
  moduleKeys: [],
  projectKeys: [],
  repositoryKeys: [],
  repositoryGroupKeys: [],
  apiGatewayKeys: [],
  apisGroupKeys: [],
  suggestionBased: false,
  pointsOfContactTitles: [],
  isPointsOfContactEnabled: false,
  isApiGatewaysSelectionEnabled: false,
  assetCollection: {
    type: 'multiple',
    name: '',
    isInternetFacing: false,
    hasBusinessImpact: false,
    businessImpactLevel: 'High',
    hasDeploymentLocation: false,
    deploymentLocation: 'Cloud',
    moduleRepositoryKey: null,
    pointsOfContact: [],
  },
});

export default {
  state: clearData(),
  reducers: {
    clearData,
    initialize: (state, suggestionBased, pointsOfContactTitles) => ({
      ...state,
      suggestionBased,
      pointsOfContactTitles,
    }),
    setModuleKeys: (state, moduleKeys) => ({
      ...state,
      moduleKeys,
    }),
    setProjectKeys: (state, projectKeys) => ({
      ...state,
      projectKeys,
    }),
    setRepositoryKeys: (state, repositoryKeys) => ({
      ...state,
      repositoryKeys,
    }),
    setApiGatewayKeys: (state, apiGatewayKeys) => ({
      ...state,
      apiGatewayKeys,
    }),
    setApisGroupKeys: (state, apisGroupKeys) => ({
      ...state,
      apisGroupKeys,
    }),
    setRepositoryGroupKeys: (state, repositoryGroupKeys) => ({
      ...state,
      repositoryGroupKeys,
    }),
    setAssetCollection: (state, assetCollection) => ({
      ...state,
      assetCollection,
    }),
    setType: (state, type) => ({
      ...state,
      assetCollection: {
        ...state.assetCollection,
        type,
      },
    }),
    toggleIsPointsOfContactEnabled: state => ({
      ...state,
      isPointsOfContactEnabled: !state.isPointsOfContactEnabled,
    }),
    setIsApiGatewaysSelectionEnabled: (state, isApiGatewaysSelectionEnabled) => ({
      ...state,
      isApiGatewaysSelectionEnabled,
    }),
  },
  selectors: slice => ({
    moduleKeys: () => slice(state => state?.moduleKeys),
    projectKeys: () => slice(state => state?.projectKeys),
    repositoryKeys: () => slice(state => state?.repositoryKeys),
    repositoryGroupKeys: () => slice(state => state?.repositoryGroupKeys),
    apiGatewayKeys: () => slice(state => state?.apiGatewayKeys),
    apisGroupKeys: () => slice(state => state?.apisGroupKeys),
    pointsOfContactTitles: () =>
      slice(state =>
        map(state?.pointsOfContactTitles, (titleDescription, title) => ({
          label: titleDescription,
          value: title,
        }))
      ),
    isPointsOfContactEnabled: () => slice(state => state?.isPointsOfContactEnabled),
    isApiGatewaysSelectionEnabled: () => slice(state => state?.isApiGatewaysSelectionEnabled),
    assetCollectionType: () => slice(state => state?.assetCollection.type),
    assetCollection: () => slice(state => state?.assetCollection),
  }),
  effects: dispatch => ({
    async fetchData({
      isSuggestion,
      assetCollectionConfiguration,
      recommendedAssetCollectionConfiguration,
      existingKey,
    }) {
      if (existingKey) {
        ({ configuration: assetCollectionConfiguration } =
          await dispatch.assetCollectionProfiles.getAssetCollectionProfileAsync({
            key: existingKey,
          }));
      }

      this.initialize(
        Boolean(isSuggestion),
        (await apiService.get('/api/developers/pointsOfContact/types')).data
      );

      let existing = { ...assetCollectionConfiguration };
      let existingChanged = false;

      const applyExisting = configuration => {
        existing = {
          ...existing,
          businessImpactLevel: configuration.businessImpact,
          hasBusinessImpact: configuration.businessImpact !== 'None',
          deploymentLocation: configuration.deploymentLocation,
          hasDeploymentLocation: configuration.deploymentLocation !== 'None',
          type: configuration.isModuleBased ? 'modules' : 'multiple',
        };

        if (configuration.isModuleBased) {
          existing.moduleRepositoryKey = configuration.modulesGroup.repositoryKey;
          this.setModuleKeys(configuration.modulesGroup.moduleKeys);
        } else {
          this.setProjectKeys(uniq([...existing.projectKeys, ...configuration.projectKeys]));
          this.setRepositoryKeys(
            uniq([...existing.repositoryKeys, ...configuration.repositoryKeys])
          );
          this.setRepositoryGroupKeys(
            uniq([...existing.repositoryGroups, ...configuration.repositoryGroups]).map(
              _ => `${_.serverUrl}+${_.groupName}`
            )
          );
          const apiGatewayKeys = uniq([
            ...existing.apiGatewayKeys,
            ...configuration.apiGatewayKeys,
          ]);
          this.setApiGatewayKeys(apiGatewayKeys);
          this.setIsApiGatewaysSelectionEnabled(!isEmpty(apiGatewayKeys));
          this.setApisGroupKeys(uniq([...existing.apisGroupKeys, ...configuration.apisGroupKeys]));
        }
      };

      if (!isNil(assetCollectionConfiguration)) {
        applyExisting(assetCollectionConfiguration);
        existingChanged = true;
      }

      if (!isNil(recommendedAssetCollectionConfiguration)) {
        applyExisting(recommendedAssetCollectionConfiguration);
        existingChanged = true;
      }

      if (!isEmpty(existing.pointsOfContact)) {
        this.toggleIsPointsOfContactEnabled();

        existing.pointsOfContact = await Promise.all(
          existing.pointsOfContact.map(async pointOfContact => ({
            ...pointOfContact,
            profile: await dispatch.developerProfiles.getDeveloperAsync({
              key: pointOfContact.representativeIdentityKeySha,
            }),
          }))
        );
      }

      if (existingChanged) {
        this.setAssetCollection({
          ...existing,
        });
      }
    },
  }),
};
