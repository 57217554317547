import _ from 'lodash';
import styled from 'styled-components';
import { getRiskColor } from '@src-v2/data/risk-data';
import { riskToCountPairs } from '@src/services/riskService';

const StyledContainer = styled.div`
  display: inline-block;
  width: 100%;
  padding-top: 6px;
  padding-bottom: 6px;
`;

const StyledBar = styled.div`
  background-color: var(--color-blue-gray-20);
  display: flex;
  width: 100%;
  height: 3px;
`;

const StyledBarSection = styled.div`
  background-color: ${getRiskColor};
  width: ${props => `${props.width}%`};
  height: 100%;
`;

export const RiskBar = ({ totalCount, countByLevel, className }) => (
  <StyledContainer className={className}>
    <StyledBar>
      {riskToCountPairs(countByLevel).map(([riskLevel, count]) => (
        <StyledBarSection
          key={riskLevel}
          width={(100 * count) / totalCount}
          riskLevel={_.camelCase(riskLevel)}
        />
      ))}
    </StyledBar>
  </StyledContainer>
);
