import _ from 'lodash';

export const primaryPaneLevel = 'primary';
export const secondaryPaneLevel = 'secondary';

const levelNames = [primaryPaneLevel, secondaryPaneLevel];

const reduceLevels = cb =>
  levelNames.reduce((obj, levelName) => Object.assign(obj, cb(levelName)), {});

const percentageByLevelName = {
  [primaryPaneLevel]: {
    defaultValue: 60,
    maxValue: 80,
  },
  [secondaryPaneLevel]: {
    defaultValue: 60,
  },
};

export default {
  state: reduceLevels(levelName => ({
    [levelName]: {
      widthPercentage: percentageByLevelName[levelName].defaultValue,
    },
  })),

  selectors: slice =>
    reduceLevels(levelName => ({
      [`${levelName}Pane`]: () => slice(state => state[levelName]),
    })),

  reducers: reduceLevels(levelName => ({
    [`set${_.upperFirst(levelName)}PaneOpen`]: (
      state,
      { innerPane, content, id, relevantPath, onClose }
    ) => ({
      ...state,
      [levelName]: {
        ...state[levelName],
        isOpen: true,
        id,
        innerPane,
        content,
        relevantPath,
        onClose,
      },
    }),

    [`set${_.upperFirst(levelName)}PaneClosed`]: state => ({
      ...state,
      [levelName]: {
        isOpen: false,
        widthPercentage: state[levelName].widthPercentage,
        relevantPath: null,
        onClose: null,
      },
    }),

    [`set${_.upperFirst(levelName)}WidthPercentage`]: (state, widthPercentage) => ({
      ...state,
      [levelName]: {
        ...state[levelName],
        widthPercentage: Math.min(
          widthPercentage,
          levelName === primaryPaneLevel
            ? percentageByLevelName[primaryPaneLevel].maxValue
            : 0.9 * state[primaryPaneLevel].widthPercentage
        ),
      },
    }),
  })),

  effects: dispatch => ({
    openPane({ level = primaryPaneLevel, innerPane, content, id, relevantPath, onClose }) {
      dispatch.pushNotifications.disableNotifications();
      this[`set${_.upperFirst(level)}PaneOpen`]({ innerPane, content, id, relevantPath, onClose });

      if (level === secondaryPaneLevel) {
        this.setPrimaryWidthPercentage(percentageByLevelName[primaryPaneLevel].maxValue);
      }
    },
    closePrimaryPane(_, state) {
      this.closeSecondaryPane();
      state.pane.primary.onClose?.(state);
      this.setPrimaryPaneClosed();
      dispatch.pushNotifications.enableNotifications();
    },
    closeSecondaryPane(_, state) {
      state.pane.secondary.onClose?.(state);
      this.setSecondaryPaneClosed();
    },
  }),
};
