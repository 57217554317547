import { Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';

export const ApiMethodParameterMaterialChange = ({ commitSha, repository, materialChange }) => {
  const { className, relativeFilePath, lineNumber, apiName } = materialChange.codeReference ?? {};
  return (
    <>
      <Paragraph>
        <Strong>Method parameters were modified</Strong> in API <Strong>{apiName}</Strong> defined
        at{' '}
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}
          lineNumber={lineNumber}>
          {className ?? relativeFilePath}
        </CommitCodeReference>
      </Paragraph>

      {materialChange.modifications.map((modification, index) => (
        <ModificationDescription {...modification} key={index} />
      ))}
    </>
  );
};

const ModificationDescription = ({
  name,
  parentType,
  modificationType,
  previousType,
  currentType,
}) => {
  switch (modificationType) {
    case 'Added':
    case 'Removed':
      return (
        <MaterialChangesRow>
          <Paragraph>
            {parentType ? 'Field' : 'Parameter'}{' '}
            <Strong>
              {currentType ?? previousType} {name}
            </Strong>
            {parentType && (
              <>
                in type
                <Strong>{parentType}</Strong>{' '}
              </>
            )}{' '}
            was {modificationType.toLowerCase()}
          </Paragraph>
        </MaterialChangesRow>
      );
    case 'ChangedType':
      return (
        <MaterialChangesRow>
          <Paragraph>
            Type of {parentType ? 'field' : 'parameter'} <Strong>{name}</Strong>{' '}
            {parentType && (
              <>
                in type <Strong>{parentType}</Strong>
              </>
            )}{' '}
            was changed from
            <Strong>{previousType}</Strong> to {currentType}
          </Paragraph>
        </MaterialChangesRow>
      );

    default:
      console.warn(`unsupported modification type ${modificationType ?? ''}`);
      return null;
  }
};
