import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { Dropdown } from '@src-v2/components/dropdown';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { SvgIcon } from '@src-v2/components/icons';
import { Table } from '@src-v2/components/table/table';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { useDataTableContext } from '@src-v2/containers/data-table/data-table';
import { dataAttr, stopPropagation } from '@src-v2/utils/dom-utils';

export const SORT = {
  ASC: 'Ascending',
  DESC: 'Descending',
};

export const SortControl = observer(({ fieldName }) => {
  const {
    dataModel: {
      searchState: {
        params: { onSetActiveSort, sortBy, sortDirection },
      },
    },
  } = useDataTableContext();

  const isActive = useMemo(() => sortBy === fieldName, [sortBy, fieldName]);

  const nextSortDirection = useMemo(
    () => (sortDirection === SORT.ASC ? SORT.DESC : SORT.ASC),
    [sortDirection]
  );

  const handleSortClick = useCallback(() => {
    onSetActiveSort({ name: fieldName, sortDirection: nextSortDirection });
  }, [sortBy, nextSortDirection]);

  if (!sortDirection) {
    return null;
  }

  return (
    <SortContainer data-hidden={dataAttr(!isActive)}>
      <SortIcon data-hide={dataAttr(!isActive)} name={sortDirection} onClick={handleSortClick} />
    </SortContainer>
  );
});

export const HeaderMenu = ({
  dataModel,
  fieldName,
  columnKey,
  index,
  isNestedParent,
  pinned,
  sortable,
}) => {
  const trackAnalytics = useTrackAnalytics();
  const {
    dataModel: {
      searchState: {
        params: { onSetActiveSort, sortBy, sortDirection },
      },
    },
  } = useDataTableContext();

  const handleSortClick = useCallback(
    ({ isSelected, sortDirection }) => {
      if (isSelected) {
        onSetActiveSort();
        return;
      }
      onSetActiveSort({ name: fieldName ?? columnKey, sortDirection });
      trackAnalytics(AnalyticsEventName.ActionClicked, {
        [AnalyticsDataField.ColumnName]: columnKey,
        [AnalyticsDataField.ActionType]: 'Add Column',
      });
    },
    [fieldName, onSetActiveSort, trackAnalytics]
  );

  const handleRemoveColumnClick = useCallback(() => {
    dataModel.toggleColumnView({ key: columnKey });
    dataModel.hideAllNestedColumns({ key: columnKey });

    trackAnalytics(AnalyticsEventName.ActionClicked, {
      [AnalyticsDataField.ColumnName]: columnKey,
      [AnalyticsDataField.ActionType]: 'Remove Column',
    });
  }, [dataModel, columnKey, trackAnalytics]);

  const handleTogglePinClick = useCallback(() => {
    dataModel.togglePinColumn({ index });

    if (!pinned) {
      trackAnalytics(AnalyticsEventName.ActionClicked, {
        [AnalyticsDataField.ColumnName]: columnKey,
        [AnalyticsDataField.ActionType]: 'Pin Column',
      });
    }
  }, [dataModel, columnKey, index, trackAnalytics]);

  return (
    <HeaderMenuDropDown
      onClick={stopPropagation}
      onItemClick={stopPropagation}
      size="medium"
      variant={Variant.TERTIARY}>
      {sortable && (
        <Dropdown.Group>
          <Dropdown.Item
            selected={sortBy === fieldName && sortDirection === SORT.ASC}
            onClick={() =>
              handleSortClick({
                isSelected: sortBy === fieldName && sortDirection === SORT.ASC,
                sortDirection: SORT.ASC,
              })
            }>
            <SortIcon name={SORT.ASC} />
            Sort Ascending
          </Dropdown.Item>
          <Dropdown.Item
            selected={sortBy === fieldName && sortDirection === SORT.DESC}
            onClick={() =>
              handleSortClick({
                isSelected: sortBy === fieldName && sortDirection === SORT.DESC,
                sortDirection: SORT.DESC,
              })
            }>
            <SortIcon name={SORT.DESC} />
            Sort Descending
          </Dropdown.Item>
        </Dropdown.Group>
      )}
      {(dataModel.hasToggleColumns || dataModel.isPinFeatureEnabled) && (
        <Dropdown.Group>
          {dataModel.hasToggleColumns && (
            <Dropdown.Item onClick={handleRemoveColumnClick}>
              <SvgIcon name="Invisible" />
              Remove Column
            </Dropdown.Item>
          )}
          {!isNestedParent && dataModel.isPinFeatureEnabled && (
            <Dropdown.Item onClick={handleTogglePinClick}>
              <SvgIcon name={pinned ? 'UnPin' : 'Pin'} />
              {pinned ? 'Remove Pin' : 'Pin to Left'}
            </Dropdown.Item>
          )}
        </Dropdown.Group>
      )}
    </HeaderMenuDropDown>
  );
};

export const SortIcon = styled(SvgIcon)`
  width: 6rem;
  height: 6rem;
  color: var(--color-blue-gray-50);
  padding: 0.5rem;

  &:hover {
    color: var(--color-blue-gray-60);
  }

  &[data-hide] {
    visibility: hidden;
  }
`;

export const SortContainer = styled.div`
  width: 8rem;
  display: flex;
  margin: 0 0 0 1rem;

  &[data-hidden] {
    width: 2rem;
  }
`;

const HeaderMenuDropDown = styled(DropdownMenu)`
  display: flex;
  position: absolute;
  right: 1.5rem;
  z-index: 1;
  visibility: hidden;

  ${Table.Header} & {
    width: 6rem;
    height: 6rem;
  }

  &[data-active] {
    visibility: visible;
  }

  ${Table.Header}:hover & {
    visibility: visible;
  }

  ${Table} ${Table.Header} &:hover {
    background-color: var(--color-blue-gray-25);
    visibility: visible;
  }

  ${Table.Header} &[data-active] {
    color: var(--color-blue-gray-60);
  }
`;
