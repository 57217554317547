import { toast } from 'react-toastify';
import { StatusIcon } from '@src-v2/components/icons';

export class Toaster {
  info(message, options = {}) {
    toast.info(message, {
      icon: <StatusIcon name="Info" />,
      autoClose: false,
      ...options,
    });
  }

  success(message, options = {}) {
    const { icon = <StatusIcon name="Success" /> } = options;
    toast.success(message, {
      icon,
      autoClose: 8000,
      ...options,
    });
  }

  error(message, options = {}) {
    toast.error(message, {
      icon: <StatusIcon name="Warning" />,
      autoClose: false,
      ...options,
    });
  }

  warning(message, options = {}) {
    toast.warning(message, {
      icon: <StatusIcon name="Warning" />,
      autoClose: false,
      ...options,
    });
  }

  dismiss() {
    toast.dismiss();
  }
}
