export function useGroupProperties(props, ...propGroups) {
  const leftoverProps = { ...props };
  return propGroups
    .map(keys =>
      keys.reduce((groupProps, key) => {
        if (props.hasOwnProperty(key)) {
          groupProps[key] = props[key];
        }
        delete leftoverProps[key];
        return groupProps;
      }, {})
    )
    .concat(leftoverProps);
}
