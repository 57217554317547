import _ from 'lodash';
import { extractBy } from '@src-v2/utils/collection-utils';
import apiService from '@src/services/apiService';

export default {
  state: {
    isFetchingData: false,
    developerProfileByKey: {},
    developerProfileByIdentityKey: {},
    developerProfileByRepresentativeKey: {},
  },
  reducers: {
    setDeveloperProfiles: (state, developerProfiles) => ({
      ...state,
      developerProfileByKey: Object.assign(
        {},
        state.developerProfileByKey,
        _.keyBy(developerProfiles, 'key')
      ),
      developerProfileByIdentityKey: Object.assign(
        {},
        state.developerProfileByIdentityKey,
        mapDeveloperProfiles(developerProfiles, 'key')
      ),
      developerProfileByRepresentativeKey: Object.assign(
        {},
        state.developerProfileByRepresentativeKey,
        mapDeveloperProfiles(developerProfiles, 'keySha256')
      ),
    }),
    setIsFetchingData: (state, isFetching) => ({
      ...state,
      isFetchingData: isFetching,
    }),
  },
  selectors: (slice, createSelector) => ({
    developerProfileByKey: () => slice(state => state.developerProfileByKey),
    developerProfileByIdentityKey: () => slice(state => state.developerProfileByIdentityKey),
    developerProfileByRepresentativeKey: () =>
      slice(state => state.developerProfileByRepresentativeKey),
    isFetchingData: () => slice(state => state.isFetchingData),
    profileGetter: models =>
      createSelector(
        [
          models.developerProfiles.developerProfileByKey,
          models.developerProfiles.developerProfileByRepresentativeKey,
        ],
        (developerByKey, developerByIdentityKeySha) => developerKey =>
          developerByKey &&
          (developerByKey[developerKey] || developerByIdentityKeySha[developerKey])
      ),
  }),
  effects: () => ({
    async fetchDeveloperAsync({ url, invalidateCache }) {
      this.setIsFetchingData(true);
      try {
        const { data: developerProfile } = await apiService.get(url, {
          clearCacheEntry: invalidateCache,
        });
        if (developerProfile) {
          this.setDeveloperProfiles([developerProfile]);
        }
        this.setIsFetchingData(false);
        return developerProfile;
      } catch (error) {
        console.error(error);
        return null;
      }
    },
    getDeveloperAsync({ key, invalidateCache }, { developerProfiles }) {
      if (!invalidateCache) {
        if (!_.isNil(developerProfiles.developerProfileByKey[key])) {
          return developerProfiles.developerProfileByKey[key];
        }
        if (!_.isNil(developerProfiles.developerProfileByRepresentativeKey[key])) {
          return developerProfiles.developerProfileByRepresentativeKey[key];
        }
      }
      return this.fetchDeveloperAsync({ url: `/api/developers/${key}`, invalidateCache });
    },
    getDeveloperByIdentityKeyAsync({ key, invalidateCache }, { developerProfiles }) {
      if (!invalidateCache && !_.isNil(developerProfiles.developerProfileByIdentityKey[key])) {
        return developerProfiles.developerProfileByIdentityKey[key];
      }
      return this.fetchDeveloperAsync({
        url: `/api/developers/byIdentity/${key}`,
        invalidateCache,
      });
    },
    getDevelopersByIdentitiesKeysAsync({ keys, invalidateCache }, models) {
      return Promise.all(
        _.uniq(keys).map(key =>
          this.getDeveloperByIdentityKeyAsync({ key, invalidateCache }, models)
        )
      );
    },
    getDevelopersByRepresentativeKeysAsync({ keys, invalidateCache }, models) {
      return Promise.all(
        _.uniq(keys).map(key => this.getDeveloperAsync({ key, invalidateCache }, models))
      );
    },
    addDeveloperProfiles({ developerProfiles }) {
      this.setDeveloperProfiles(developerProfiles);
    },
  }),
};

function mapDeveloperProfiles(developerProfiles, keyMapper) {
  const developerIdentities = developerProfiles.flatMap(profile => profile.developerIdentities);
  return developerIdentities.reduce((result, developerIdentity) => {
    result[extractBy(developerIdentity, keyMapper)] = developerProfiles.find(
      profile => profile.key === developerIdentity.developerKey
    );
    return result;
  }, {});
}
