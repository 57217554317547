import _ from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { ActionsHistory } from '@src-v2/containers/actions-timeline/actions-history';

export const ActionResults = ({
  actionsTaken: { sentMessages = [], createdIssues = [], actionsTakenSummaries },
  pluralAttackSurfaceFormat,
  riskLevel,
  ...props
}) => {
  const actionsTaken = useMemo(() => {
    if (actionsTakenSummaries) {
      return actionsTakenSummaries;
    }

    const allTimelineItems = _.orderBy(
      sentMessages
        ?.map(transformMessageToActionTakenDetails)
        ?.concat(createdIssues.map(transformIssueToActionTakenDetails)) ?? [],
      ({ createdAt }) => new Date(createdAt),
      'desc'
    );
    return Object.entries(_.groupBy(allTimelineItems, 'provider')).map(([provider, items]) => ({
      provider,
      items,
      type: items[0].type,
    }));
  }, [actionsTakenSummaries, sentMessages, createdIssues]);

  return (
    <Container {...props}>
      {actionsTaken.map((summary, index) => (
        <ActionsHistory key={index} summary={summary} />
      ))}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  gap: 2rem;
`;

function transformMessageToActionTakenDetails({ key, channel, ...message }) {
  return {
    key,
    channel,
    createdBy: message.sentBy,
    createdAt: message.timestamp * 1000,
    vendor: message.triggerMessageType,
    externalLink: message.messageLink,
    type: 'Message',
  };
}

function transformIssueToActionTakenDetails({ key, createdBy, createdAt, issueLink, provider }) {
  return {
    key,
    createdBy,
    createdAt,
    vendor: provider ?? 'Jira',
    externalLink: issueLink,
    type: 'Issue',
  };
}
