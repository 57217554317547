import { format } from 'date-fns';
import { useMemo } from 'react';
import { BadgeColors } from '@src-v2/components/badges';
import { Chart, ChartProvider, Line, XAxis, YAxis } from '@src-v2/components/charts';
import { ChartLegend, LineLegendItem } from '@src-v2/components/charts/legends';
import { ChartTooltip, LineDatumIndicator } from '@src-v2/components/charts/tooltips';
import { OverviewStateBoundary } from '@src-v2/components/overview/overview-state-boundary';
import { OverviewTile } from '@src-v2/components/overview/overview-tiles';
import { RiskyScoreTrendBadge } from '@src-v2/containers/overview/tiles/risky-score-trend-tile/risky-score-trend-badge';
import {
  useRiskyScoreTrendData,
  useRiskyTrendStyle,
} from '@src-v2/containers/overview/tiles/risky-score-trend-tile/utils';
import { abbreviate } from '@src-v2/utils/number-utils';

export function RiskScoreTrendTile() {
  return (
    <OverviewTile title="Risk score over time" titleBadge={<RiskyScoreTrendBadge />}>
      <RiskyScoreTrendTileContent />
    </OverviewTile>
  );
}

const RiskyScoreTrendTileContent = () => {
  const { data, percentageChange } = useRiskyScoreTrendData();
  const { trendColor } = useRiskyTrendStyle(percentageChange);

  const { normalizedData } = useMemo(() => {
    if (data.length === 0) {
      return { normalizedData: [], minValue: 0, maxValue: 0 };
    }
    const minValue = Math.min(...data.map(d => d.count));
    const maxValue = Math.max(...data.map(d => d.count));
    const normalizedData = data.map(item => ({
      ...item,
      normalizedCount: (item.count - minValue) / (maxValue - minValue),
    }));
    return { normalizedData, minValue, maxValue };
  }, [data]);

  return (
    <OverviewStateBoundary
      isEmpty={normalizedData.length === 0 || normalizedData.every(item => item.count === 0)}>
      <ChartProvider
        xScale={{ type: 'time', nice: true }}
        yScale={{ type: 'linear', nice: true }}
        theme={{
          colors: [lineColor(trendColor)],
        }}>
        <Chart margin={{ top: 20, right: 20, bottom: 40, left: 50 }}>
          <XAxis
            numTicks={4}
            tickFormat={(tick: Date | number | string) => format(tick, 'd/M')}
            tickLabelProps={() => ({
              dy: 10,
              dx: 10,
              fill: 'var(--color-text-secondary)',
              fontSize: 12,
              textAnchor: 'middle',
            })}
          />
          <YAxis
            numTicks={4}
            tickFormat={(value: number) => {
              const minValue = Math.min(...data.map(d => d.count));
              const maxValue = Math.max(...data.map(d => d.count));

              return abbreviate(value * (maxValue - minValue) + minValue);
            }}
            tickLabelProps={() => ({
              dx: 16,
              dy: -16,
              fontSize: 12,
              textAnchor: 'end',
            })}
          />
          <Line
            dataKey="Risk score"
            data={normalizedData}
            xAccessor={d => d?.date}
            yAccessor={d => d?.normalizedCount}
          />
          <ChartTooltip datumIndicator={LineDatumIndicator} useAbsoluteYValue />
        </Chart>
        <ChartLegend item={LineLegendItem} />
      </ChartProvider>
    </OverviewStateBoundary>
  );
};

const lineColor = (trendColor: BadgeColors | string): string => {
  switch (trendColor) {
    case BadgeColors.Red:
      return 'var(--color-red-50)';
    case BadgeColors.Green:
      return 'var(--color-green-45)';
    case BadgeColors.Blue:
      return 'var(--color-blue-60)';
    default:
      return 'var(--color-blue-gray-50)';
  }
};
