import styled from 'styled-components';
import { HtmlRoot } from '@src-v2/components/svg/svg-elements';

export const ZoomControls = styled(({ onIncrease, onDecrease, ...props }) => {
  return (
    <svg {...props}>
      <ZoomButton x="0" y="0" width="100%" height="50%" onClick={onDecrease}>
        <ZoomIcon stroke="var(--color-blue-gray-40)">
          <line x1="1" y1="5" x2="9" y2="5" />
        </ZoomIcon>
      </ZoomButton>
      <ZoomButton x="0" y="50%" width="100%" height="50%" onClick={onIncrease}>
        <ZoomIcon stroke="var(--color-blue-70)">
          <line x1="1" y1="5" x2="9" y2="5" />
          <line x1="5" y1="1" x2="5" y2="9" />
        </ZoomIcon>
      </ZoomButton>
      <line x1="0" y1="50%" x2="100%" y2="50%" />
    </svg>
  );
})`
  width: 4.5rem;
  height: 8rem;
  stroke: var(--color-blue-gray-30);
  stroke-width: 1;
  border-radius: 100vmax;
  border: 0.25rem solid var(--color-blue-gray-30);
  background-color: var(--color-white);
`;

const ZoomButton = styled(HtmlRoot)`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  stroke-width: 1.5;
  stroke-linecap: round;
  transition: background-color 200ms;
  cursor: pointer;

  &:hover {
    background-color: var(--color-blue-gray-15);
  }
`;

const ZoomIcon = styled.svg`
  width: 2.5rem;
  height: 2.5rem;
`;
