import { useCallback, useState } from 'react';
import styled from 'styled-components';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { InfoBanner } from '@src-v2/components/banner';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { DraggableProvidersPreferences } from '@src-v2/containers/entity-pane/sca/draggable-providers-preferences';
import { useInject, useSuspense } from '@src-v2/hooks';

export const ThirdPartyOrderModal = ({
  closeModal,
  currentRiskVersion,
}: {
  closeModal: () => void;
  currentRiskVersion?: string;
}) => {
  const { toaster, scaConfiguration } = useInject();
  const availableServers = useSuspense(scaConfiguration.getAvailableServerProviders);
  const [providers, setProviders] = useState(availableServers);
  const trackAnalytics = useTrackAnalytics();

  const handleApply = useCallback(async () => {
    try {
      await scaConfiguration.updatePriorityConfiguration(providers);
      toaster.success('SCA source preference updated');
      trackAnalytics(AnalyticsEventName.ActionClicked, {
        [AnalyticsDataField.ActionType]: 'SCA provider order change',
        [AnalyticsDataField.ScaProviderOrder]: providers.join(', '),
        [AnalyticsDataField.Context]: currentRiskVersion,
      });
    } catch (e) {
      toaster.error('Failed to update SCA source preference');
    } finally {
      closeModal();
    }
  }, [providers]);

  return (
    <ConfirmationModal
      title="Order findings by provider"
      submitText="Apply"
      onClose={closeModal}
      onSubmit={handleApply}>
      <ThirdPartyOrderModalWrapper>
        <Header>
          Apiiro consolidates findings from your SCA sources. Set the order of provider findings for
          Risk pane evidence, remediation data, and tickets generated by Apiiro.
        </Header>
        <DraggableProvidersPreferences
          providers={providers}
          onChange={updatedProviders => setProviders(updatedProviders)}
        />
        <InfoBanner description="This setting will apply to all SCA risks" />
      </ThirdPartyOrderModalWrapper>
    </ConfirmationModal>
  );
};

const Header = styled.span`
  font-size: var(--font-size-s);
  font-weight: 400;
  line-height: 5rem;
`;

const ThirdPartyOrderModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;
