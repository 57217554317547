import { LightAsync as SyntaxHighlighter } from 'react-syntax-highlighter';
import styled from 'styled-components';
import HighlightStyle from '@src/style/Highlightjs';

const StyledSyntaxHighlighter = styled(SyntaxHighlighter)`
  code * {
    white-space: pre-wrap;
  }
`;

const HighlightedCode = ({ code, language, className }) => (
  <div className={className}>
    <StyledSyntaxHighlighter
      language={language}
      style={HighlightStyle}
      customStyle={{
        margin: 0,
        padding: 0,
        paddingLeft: '18px',
        marginRight: '18px',
        textIndent: `-18px`,
        whiteSpace: 'pre-wrap',
      }}>
      {code}
    </StyledSyntaxHighlighter>
  </div>
);

export default HighlightedCode;
