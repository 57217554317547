import { useMemo } from 'react';
import styled from 'styled-components';
import { abbreviate } from '@src-v2/utils/number-utils';

export function HighlightsBreakdown({ data: originalData, maxSize = 4 }) {
  const rowsData = useMemo(() => {
    const data = originalData.slice();
    for (let i = data.length - 1; data.length > maxSize && i >= 0; i--) {
      if (data[i].count === 0) {
        data.splice(i, 1);
      }
    }
    return data.slice(0, maxSize);
  }, [originalData, maxSize]);
  return rowsData.map(props => <BreakdownRow key={props.title} {...props} />);
}

const BreakdownRow = styled(({ title, count, ...props }) => (
  <div {...props}>
    <BreakdownCell>{abbreviate(count)}</BreakdownCell>
    <BreakdownCell>{title}</BreakdownCell>
  </div>
))`
  display: table-row;
`;

const BreakdownCell = styled.span`
  display: table-cell;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:first-child {
    padding-right: 5rem;
  }
`;
