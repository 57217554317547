import { isObservable } from 'mobx';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { VendorCircle } from '@src-v2/components/circles';
import { Combobox, Input } from '@src-v2/components/forms';
import { Field } from '@src-v2/components/forms/modal-form-layout';
import { SuccessToast } from '@src-v2/components/toastify';
import { ExternalLink, ListItem, Paragraph, UnorderedList } from '@src-v2/components/typography';
import { ActionModal } from '@src-v2/containers/modals/action-modal';
import { IssueContentEditor } from '@src-v2/containers/modals/issues/issue-content-editor';
import { IssueDestinationControl } from '@src-v2/containers/modals/issues/issue-destination-control';
import { IssuePreview } from '@src-v2/containers/modals/issues/issue-preview';
import { useProviderModalSettings } from '@src-v2/containers/modals/issues/providers-issue-modals';
import { TicketingProviders, createIssueSummary } from '@src-v2/data/ticketing-issues-provider';
import { useInject } from '@src-v2/hooks';
import { humanize } from '@src-v2/utils/string-utils';

const issueDisplayName = {
  [TicketingProviders.AzureDevops]: 'Work Item',
  [TicketingProviders.Jira]: 'Ticket',
};

export function CreateIssueModal({
  provider,
  riskData,
  rule,
  defaultValues: initialValues = {},
  onSubmit,
  onClose,
  children,
  ...props
}) {
  const { ticketingIssues, risks, toaster } = useInject();
  const trackAnalytics = useTrackAnalytics();
  const { summaryFieldKey } = useProviderModalSettings(provider);

  const defaultValues = useMemo(
    () => ({
      ...initialValues,
      provider,
      [summaryFieldKey]: createIssueSummary(riskData),
    }),
    [initialValues, provider, summaryFieldKey, riskData, rule]
  );

  const handleSubmit = useCallback(
    async issue => {
      try {
        const response = await ticketingIssues.createIssue({
          riskData,
          issue,
        });

        trackAnalytics(AnalyticsEventName.ActionInvoked, {
          [AnalyticsDataField.ActionType]: `Create ${humanize(provider, true)} issue`,
        });

        if (isObservable(riskData)) {
          risks.modifyActionTimelineItem(riskData, response);
        }

        onSubmit?.();
        toaster.success(
          <SuccessToast>
            An issue was created successfully!{' '}
            {response?.externalLink && (
              <ExternalLink href={response?.externalLink}>View issue</ExternalLink>
            )}
          </SuccessToast>
        );

        onClose();
      } catch (error) {
        toaster.error(<ErrorToast provider={provider} error={error} />);
      }
    },
    [riskData, provider, onSubmit, onClose]
  );

  return (
    <Modal
      {...props}
      defaultValues={defaultValues}
      title={
        <>
          <VendorCircle size="xlarge" name={provider} />
          {issueDisplayName[provider]
            ? `Create a ${issueDisplayName[provider]}`
            : 'Create an Issue'}
        </>
      }
      submitText="Create"
      onSubmit={handleSubmit}
      onClose={onClose}>
      <IssuePreview provider={provider} riskData={riskData} />

      <AsyncBoundary>
        <IssueDestinationControl provider={provider} riskData={riskData} />
      </AsyncBoundary>

      <AsyncBoundary>
        <IssueContentEditor provider={provider} riskData={riskData} />
      </AsyncBoundary>
    </Modal>
  );
}

const Modal = styled(ActionModal)`
  display: flex;
  flex-direction: column;
  gap: 2rem;

  ${Field},
  ${Input} {
    margin: 0;
    font-size: var(--font-size-s);
    font-weight: 400;
  }

  ${Combobox} {
    width: 100%;
  }
`;

const ErrorToast = styled(({ provider, error, ...props }) => {
  return (
    <div {...props}>
      <Paragraph>
        Failed to open new {provider} {issueDisplayName[provider]?.toLowerCase() ?? 'issue'}.
      </Paragraph>
      <UnorderedList>
        {error?.response?.data?.map?.(validationError => (
          <ListItem key={validationError.code}>{validationError.message}</ListItem>
        ))}
      </UnorderedList>
      <Paragraph>please try again.</Paragraph>
    </div>
  );
})`
  ${Paragraph}, ${UnorderedList} {
    margin-bottom: 0;
  }
`;
