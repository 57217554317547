import { addMonths } from 'date-fns';
import { isEmpty } from 'lodash';
import { useCallback } from 'react';
import { Banner } from '@src-v2/components/banner';
import { Button } from '@src-v2/components/button-v2';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { useInject, useLocalStorage, useToggle } from '@src-v2/hooks';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { MonitorAllModal } from '@src/blocks/AssetCollectionPage/blocks/MonitorAllModal';
import { Number } from '@src/components/Number';

export const MonitorAllBanner = ({ monitorAll, profileKey, unmonitored }) => {
  const { subscription } = useInject();
  const [dismissDate, setDismissDate] = useLocalStorage(`unmonitored.${profileKey}`, null);

  const [monitorAllModalOpen, toggleMonitorAllModalOpen] = useToggle();
  const onMonitorAll = useCallback(
    () => monitorAll().then(toggleMonitorAllModalOpen),
    [monitorAll, toggleMonitorAllModalOpen]
  );

  if (dismissDate !== null && dismissDate > Date.now()) {
    return null;
  }

  const anyRepositories = !isEmpty(unmonitored.repositories);
  const anyProjects = !isEmpty(unmonitored.projects);

  if (!anyRepositories && !anyProjects) {
    return null;
  }

  return (
    <>
      <Banner
        onClose={() => setDismissDate(addMonths(Date.now(), 1).getTime())}
        description={
          <>
            There{' '}
            {pluralFormat(
              (unmonitored.repositories?.length ?? 0) + (unmonitored.projects?.length ?? 0),
              'is',
              'are'
            )}{' '}
            {anyRepositories && (
              <Number
                one="unmonitored repository"
                other="unmonitored repositories"
                value={unmonitored.repositories.length}
              />
            )}{' '}
            {anyRepositories && anyProjects && 'and '}
            {anyProjects && (
              <Number
                one="unmonitored project"
                other="unmonitored projects"
                value={unmonitored.projects.length}
              />
            )}{' '}
            in this application configuration
          </>
        }>
        {!subscription.limitations.limitMultiMonitorOptions && (
          <Button size="medium" variant={Variant.PRIMARY} onClick={toggleMonitorAllModalOpen}>
            Monitor All
          </Button>
        )}
      </Banner>
      {monitorAllModalOpen && (
        <MonitorAllModal
          data={unmonitored}
          onSubmit={onMonitorAll}
          onClose={toggleMonitorAllModalOpen}
        />
      )}
    </>
  );
};
