import { observer } from 'mobx-react';
import { forwardRef } from 'react';
import styled from 'styled-components';
import * as GraphIconsUrl from '@src-v2/assets/images/graph';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { HtmlRoot } from '@src-v2/components/svg/svg-elements';
import { getLanguageIconUrl, getVendorIconUrl, hasLanguageIcon } from '@src-v2/data/icons';
import { abbreviate } from '@src-v2/utils/number-utils';
import { toRem } from '@src-v2/utils/style-utils';

const NodeSymbol = styled(({ node, ...props }) => {
  switch (node.type) {
    case 'DataStore':
      return <StorageSymbol {...props} />;
    default:
      return <CircleSymbol {...props} />;
  }
})``;

const IconImage = styled.image`
  width: 8rem;
  height: 8rem;
  transform-box: fill-box;
  transform: translate(-50%, -50%);
`;

const GraphIconImage = styled(IconImage)`
  width: 6rem;
  height: 6rem;
`;

const StorageSymbol = styled(props => (
  <g {...props}>
    <path d="M30-21v42c0 5-13.3 9-30 9s-30-4-30-9v-42m0 0c0-5 13.4-9 30-9s30 4 30 9-13.4 9-30 9-30-4-30-9z" />
    <ellipse cx="0" cy="-21" rx="26" ry="6" />
  </g>
))`
  fill: var(--color-white);

  > ellipse {
    fill: #eeeff4;
  }

  ~ ${IconImage} {
    transform: translate(-50%, -25%);
  }
`;

export const CircleSymbol = styled.circle`
  r: 7.5rem;
  filter: drop-shadow(0 0 2.5rem #eeeff4);
  stroke-width: 1;
  transition: all 400ms;
`;

export const NodeThumbnail = styled(
  observer(
    forwardRef(({ node, children, ...props }, ref) => (
      <g ref={ref} {...props}>
        {node.type === 'Group' && !node.expanded && (
          <>
            <CircleSymbol transform="translate(4, 4)" />
            <CircleSymbol transform="translate(2, 2)" />
          </>
        )}
        <NodeSymbol node={node} />
        {!node.expanded && <NodeIcon node={node} />}
        {node.children && !node.expanded && (
          <>
            <NodeThumbnail.Badge x="11" y="11" data-highlight={props['data-highlight']}>
              {abbreviate(node.children.totalSize)}
            </NodeThumbnail.Badge>
            {node.children.hasLinks && (
              <NodeThumbnail.RiskBadge x="-34" y="-34" data-highlight={props['data-highlight']}>
                <SvgIcon name="Risk" />
              </NodeThumbnail.RiskBadge>
            )}
          </>
        )}
        {children}
      </g>
    ))
  )
)`
  fill: var(--color-white);
  stroke: #8a8fa8;
  stroke-width: ${props => (props.node.children ? 2 : 0)};
  transition:
    fill 400ms,
    stroke 400ms,
    stroke-width 400ms,
    transform 200ms cubic-bezier(0.68, -0.6, 0.32, 1.6);

  &:hover {
    fill: var(--color-blue-gray-15);
  }

  > ${NodeSymbol} {
    r: ${props => toRem(props.node.radius)};
    filter: ${props =>
      props.node.expanded ? 'drop-shadow(0 0 4rem #e2e2e9)' : 'drop-shadow(0 0 2.5rem #eeeff4)'};
  }
`;

NodeThumbnail.Badge = styled(HtmlRoot)`
  min-width: 5rem;
  height: 5rem;
  padding: 0 0.5rem;
  font-size: 2.5rem;
  font-weight: 600;
  line-height: 5rem;
  text-align: center;
  border-radius: 100vmax;
  border: 0.25rem solid var(--color-blue-gray-50);
  background-color: var(--color-blue-gray-10);
  transition: color 400ms;
`;

NodeThumbnail.RiskBadge = styled(HtmlRoot)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: var(--risk-color-high);
  stroke: none;

  ${BaseIcon} {
    width: 5rem;
    height: 5rem;
    transition: opacity 400ms;
  }
`;

export const NodeIcon = styled(IconFactory)`
  transition: opacity 400ms;
`;

function IconFactory({ node, highlight, ...props }) {
  if (node.type === 'Group') {
    node = { ...node, type: node.subType };
    if (hasLanguageIcon(node.type)) {
      node.data = { Language: node.type };
    }
  }
  if (node.data?.Language) {
    return <IconImage {...props} href={getLanguageIconUrl(node.data?.Language)} />;
  }
  switch (node.type) {
    case 'Ui':
    case 'DataStore':
    case 'ApiGateway':
      const vendorName =
        {
          'Amazon S3': 'S3',
          AmazonS3: 'S3',
          'Amazon DynamoDB': 'DynamoDB',
          'Apache Cassandra': 'Cassandra',
          'Apache Hadoop': 'Hadoop',
          'Apache Kafka': 'Kafka',
          'Apache Solr': 'Solr',
          'Google Cloud Storage': 'GoogleCloudStorage',
          'Spring Data': 'Spring',
          PostgreSQL: 'PostgreSql',
          DynamoDb: 'DynamoDB',
          MongoDb: 'MongoDB',
          'Microsoft SQL Server': 'MsSql',
          MySQL: 'MySql',
          'React Native': 'React',
          SQLite: 'Sqlite',
          'Java Persistence API (JPA)': 'Oracle',
        }[node.name] ?? node.name;
      return (
        <IconImage {...props} href={getVendorIconUrl(vendorName) ?? GraphIconsUrl[node.type]} />
      );

    case 'Api':
    case 'Controller':
    case 'DataObject':
    case 'File':
    case 'Library':
    case 'Module':
    case 'Pci':
    case 'Phi':
    case 'Pii':
    case 'Service':
      return <GraphIconImage {...props} href={GraphIconsUrl[node.type]} />;

    case 'SensitiveData':
      return <GraphIconImage {...props} href={GraphIconsUrl.DataObject} />;

    default:
      console.info(`Missing node type icon for "${node.type}"`);
      return null;
  }
}
