import styled from 'styled-components';
import { RiskTag } from '@src-v2/components/tags';
import { Size } from '@src-v2/components/types/enums/size';
import { Heading3, SubHeading3 } from '@src-v2/components/typography';
import { useCveEntityContext } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-context-provider';

export function CveEntityHeader() {
  const { cveEntity } = useCveEntityContext();

  return (
    <Container>
      <TitleWrapper>
        <Heading3>{cveEntity.triage?.cve}</Heading3>
        <RiskTag size={Size.SMALL} riskLevel={cveEntity.severity.toLowerCase()}>
          {cveEntity.severity}
        </RiskTag>
      </TitleWrapper>
      <SubHeading3>{cveEntity.displayName}</SubHeading3>
      <HorizontalLine />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 1rem;
  justify-content: center;
  gap: 2rem;
`;

const HorizontalLine = styled.hr`
  border-bottom: 0.25rem solid var(--color-blue-gray-20);
  width: 100%;
  margin-top: 2rem;
`;

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
