import { DeleteButton } from '@src-v2/components/buttons';
import { Collapsible } from '@src-v2/components/collapsible';
import { ContentLimiter } from '@src-v2/components/content-limiter';
import { FiltersMenu } from '@src-v2/components/filters/menu-control/filters-menu';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Paragraph } from '@src-v2/components/typography';

export function CustomOptions({
  groupKey,
  options,
  optionsLimit,
  activeValues,
  onChange,
  onDelete,
  ...props
}) {
  return (
    <Collapsible {...props}>
      {options.length ? (
        <ContentLimiter limit={optionsLimit}>
          {options.map(option => (
            <FiltersMenu.Option key={option.key} data-type="custom">
              <FiltersMenu.OptionLabel onClick={event => onChange?.(option.value, event)}>
                {option.title}
              </FiltersMenu.OptionLabel>
              <Tooltip content="Delete custom filter">
                <DeleteButton onClick={event => onDelete?.(option, event)} />
              </Tooltip>
            </FiltersMenu.Option>
          ))}
        </ContentLimiter>
      ) : (
        <Paragraph>No options found</Paragraph>
      )}
    </Collapsible>
  );
}
