import _ from 'lodash';
import { observer } from 'mobx-react';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { Combobox } from '@src-v2/components/forms/combobox';
import { defaultItemToString, useGroupSearch } from '@src-v2/hooks';
import { creatableSymbol } from '@src-v2/hooks/use-downshift';
import { inputSeparatorSplit } from '@src-v2/utils/string-utils';

export const SearchCombobox = observer(
  forwardRef(
    (
      {
        as: Component = Combobox,
        searchable = true,
        items = [],
        onSelect,
        searchState,
        onInput,
        isLoading,
        creatable = false,
        shouldSplitInputValue = false,
        useSearchHook = useGroupSearch, // only for demo
        itemToString = defaultItemToString,
        renderItemContent,
        ...props
      },
      ref
    ) => {
      const [selectedItems, setSelectedItems] = useState(getSelectedItems(props));
      const { inputItems, searchTerm, setSearchTerm } = useSearchHook(
        items,
        itemToString,
        '',
        !searchable
      );
      const [appendingItems, setAppendingItems] = useState(inputSeparatorSplit(searchTerm ?? ''));

      if (
        creatable &&
        matchNoneEmpty.test(searchTerm) &&
        !inputItems?.some(item => itemToString(item) === searchTerm) &&
        !selectedItems?.some(item => itemToString(item) === searchTerm)
      ) {
        if (shouldSplitInputValue && appendingItems.length > 1) {
          inputItems.push({
            label: `${appendingItems.length} items`,
            value: searchTerm,
            [creatableSymbol]: true,
          });
        } else {
          inputItems.push({ label: searchTerm, value: searchTerm, [creatableSymbol]: true });
        }
      }

      useEffect(() => {
        setSelectedItems(getSelectedItems(props));
      }, [props.selectedItems, props.selectedItems]);

      const handleInputValueChange = useCallback(
        event => {
          setSearchTerm(event.inputValue.trim());
          setAppendingItems(inputSeparatorSplit(event.inputValue));
          onInput?.(event);
        },
        [onInput]
      );

      const handleSelectedItemsChange = useCallback(
        event => {
          setSelectedItems(getSelectedItems(event));
          onSelect?.(event);
        },
        [onSelect]
      );

      return (
        <Component
          ref={ref}
          {...props}
          searchState={searchState}
          items={inputItems}
          markTerm={searchTerm}
          creatable={creatable}
          emptyState={!creatable}
          isLoading={isLoading}
          itemToString={itemToString}
          renderItemContent={renderItemContent}
          onSelect={handleSelectedItemsChange}
          onInput={handleInputValueChange}
        />
      );
    }
  )
);

const matchNoneEmpty = /\S/;

function getSelectedItems(root) {
  return _.isNil(root.selectedItems) ? [] : [root.selectedItems];
}
