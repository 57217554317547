import _ from 'lodash';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { InsightTag } from '@src-v2/components/tags';
import ioc from '@src-v2/ioc';
import { Anchor } from '@src/components/Anchor';
import { Link } from '@src/components/Link';
import { EllipsisDiv, FontBodySmall } from '@src/style/common';

export const TriggerBoxSmallTitle = styled(EllipsisDiv)`
  ${FontBodySmall};
  opacity: 0.5;
`;

const StyledLink = styled(Link)`
  flex-grow: 1;

  > ${Anchor} {
    display: flex;
    flex-direction: row;
    gap: 3rem;
  }

  &&& > a {
    display: block;

    &:hover,
    &:hover > * {
      opacity: 1;
    }
  }
`;

const LinkTitle = styled.div``;

export const riskPanePrefix = 'ruleTriggers';
export const triggerPanePrefix = 'trigger';

export const onPaneClose = prefix => {
  const parts = ioc.history.location.pathname.split('/');
  const prefixIndex = parts.indexOf(prefix);
  if (prefixIndex >= 0) {
    ioc.history.push(parts.slice(0, prefixIndex).join('/'));
  }
};

export const PaneLocationLink = ({ id, onClick, children }) => {
  const { pathname } = useLocation();
  const history = useHistory();

  const openPane = () => {
    let parts = pathname.split('/');
    const prefixIndex = parts.indexOf(triggerPanePrefix);

    if (prefixIndex >= 0) {
      parts = parts.slice(0, prefixIndex + 1);
      parts[prefixIndex + 1] = id;
    } else {
      parts.push(triggerPanePrefix, id);
    }

    const newLocation = parts.join('/');
    if (newLocation !== pathname) {
      history.push(newLocation);
    }
    onClick({
      relevantPath: triggerPanePrefix,
      onClose: () => onPaneClose(triggerPanePrefix),
    });
  };

  return (
    <StyledLink onClick={openPane} ellipsis>
      <LinkTitle>{children}</LinkTitle>
    </StyledLink>
  );
};

export const TriggerInfoContainer = ({ id, title, insights, children }) => (
  <StyledTriggerInfoContainerDiv id={id}>
    <StyledTriggerInfoContainerDiv.Content>
      <TriggerBoxSmallTitle>{title}</TriggerBoxSmallTitle>
      {children}
    </StyledTriggerInfoContainerDiv.Content>
    {!_.isEmpty(insights) && <TriggerInsights insights={insights} />}
  </StyledTriggerInfoContainerDiv>
);

const StyledTriggerInfoContainerDiv = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 3rem;
`;

StyledTriggerInfoContainerDiv.Content = styled.div``;

const TriggerInsights = styled(({ insights, ...props }) => {
  return (
    <div {...props}>
      {insights.map((insight, index) => (
        <InsightTag key={index} insight={insight} />
      ))}
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
`;
