import { observer } from 'mobx-react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { ProfileCard } from '@src-v2/components/cards/profile-card';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Dropdown } from '@src-v2/components/dropdown';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { SvgIcon } from '@src-v2/components/icons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Heading, Paragraph, SubHeading3, Title } from '@src-v2/components/typography';
import { ProfileTagsList } from '@src-v2/containers/profiles/profile-tags/profile-tags-list';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject, useLegacyQueryParams, useToggle } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { dataAttr, preventDefault } from '@src-v2/utils/dom-utils';
import { makeUrl } from '@src-v2/utils/history-utils';
import {
  ActiveContributorsWidget,
  ContributorsWidget,
} from '@src/blocks/ConsumableInfiniteScroll/blocks/ActiveContributorsWidget';
import { ProductsCountWidget } from '@src/blocks/ConsumableInfiniteScroll/blocks/ProductsCountWidget';
import RiskyChangesWidget from '@src/blocks/ConsumableInfiniteScroll/blocks/RiskyChangesWidget';
import TopLanguagesWidget from '@src/blocks/ConsumableInfiniteScroll/blocks/TopLanguagesWidget';
import { TagsWidget } from '@src/components/TagsWidget';

export const ApplicationGroupCard = observer(({ profile }) => {
  if (!profile) {
    return null;
  }

  const query = useLegacyQueryParams();
  const { rbac, applicationProfiles, toaster, profiles, contributors, history, application } =
    useInject();

  const {
    name,
    description,
    languagePercentages,
    contributorCount,
    activeContributorCount,
    sampleContributorData,
    productsCount,
    repositoriesCount,
    riskyChanges,
    highlightedContributor,
    profileCalculationComplete,
    key,
  } = profile;
  const [isDeleteGroupModalOpen, toggleDeleteGroupModal] = useToggle(false);

  const handleEdit = useCallback(async () => {
    const configuration = await profiles.getApplicationGroup({ key });
    const defaultValues = {
      ...configuration,
      pointsOfContact: await contributors.groupPointsOfContactsByType(
        configuration?.pointsOfContact
      ),
    };

    const applications = defaultValues?.applicationKeys?.length
      ? await applicationProfiles.searchSuggestions({ searchTerm: '' })
      : [];

    history.push(`/profiles/groups/${key}/edit`, {
      defaultValues: {
        ...defaultValues,
        applications: applications.filter(application =>
          defaultValues?.applicationKeys.includes(application.key)
        ),
        tags: application.isFeatureEnabled(FeatureFlag.ApplicationGroupTags)
          ? defaultValues?.tags?.filter(Boolean)
          : defaultValues?.tags?.filter(Boolean)?.map(tag => ({ label: tag, value: tag })),
      },
      query,
    });
  }, [key, query]);

  return (
    <>
      <Tooltip
        content="Creating your new group: You’ll be able to see it soon!"
        disabled={profileCalculationComplete}>
        <ProfileCard
          data-calculating={dataAttr(!profileCalculationComplete)}
          to={
            profileCalculationComplete
              ? makeUrl(productsCount ? '/profiles/applications' : '/profiles/repositories', {
                  fl: { ApplicationGroupKeys: { values: [key] } },
                })
              : null
          }>
          <Header>
            <Description>
              <Title>{name}</Title>
              {Boolean(description) && <SubHeading3>{description}</SubHeading3>}
            </Description>
            {rbac.canEdit(resourceTypes.Products) && (
              <DropdownMenu
                data-name="application-groups-actions"
                onClick={preventDefault}
                onItemClick={preventDefault}>
                <Dropdown.Item disabled={!profileCalculationComplete} onClick={handleEdit}>
                  <SvgIcon name="Edit" /> Edit
                </Dropdown.Item>
                <Dropdown.Item disabled={Boolean(profile.source)} onClick={toggleDeleteGroupModal}>
                  <SvgIcon name="Trash" /> Delete
                </Dropdown.Item>
              </DropdownMenu>
            )}
          </Header>
          {profileCalculationComplete ? (
            <Widgets>
              <ProductsCountWidget
                productsCount={productsCount}
                repositoriesCount={repositoriesCount}
              />
              <TopLanguagesWidget languagePercentages={languagePercentages} />
              <ActiveContributorsWidget
                activeContributorCount={activeContributorCount}
                contributorCount={contributorCount}
                contributorData={sampleContributorData}
              />
              <RiskyChangesWidget riskyChanges={riskyChanges} />
              {highlightedContributor && (
                <ContributorsWidget
                  tip={highlightedContributor.displayName}
                  label={highlightedContributor.pointOfContactTitle}
                  contributorData={[highlightedContributor]}
                />
              )}
              {profile.configuration?.tags?.length > 0 &&
                (application.isFeatureEnabled(FeatureFlag.ApplicationGroupTags) ? (
                  <ProfileTagsList tags={profile.configuration?.tags} />
                ) : (
                  <TagsWidget tags={profile.configuration?.tags} />
                ))}
            </Widgets>
          ) : (
            <CalculatingGroup>
              <LogoSpinner />
              <Heading>Analyzing data and creating your group</Heading>
              <Paragraph>
                This process may take up to 2 hours, depending on your environment. When it
                completes, you’ll need to refresh the page.
              </Paragraph>
            </CalculatingGroup>
          )}
        </ProfileCard>
      </Tooltip>

      {isDeleteGroupModalOpen && (
        <ConfirmationModal
          title={`Delete ${name}?`}
          submitStatus="failure"
          submitText="Delete"
          onClose={toggleDeleteGroupModal}
          onSubmit={() =>
            profiles
              .deleteApplicationGroup({ key })
              .catch(() => toaster.error(`Failed to delete ${name}`))
              .finally(() => toggleDeleteGroupModal(false))
          }>
          Are you sure you want to delete this group?
        </ConfirmationModal>
      )}
    </>
  );
});

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  line-height: 1;
`;

const Description = styled.div``;

const Widgets = styled.div`
  display: flex;
  height: 18rem;
  gap: 12rem;
`;

const CalculatingGroup = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  ${Heading} {
    font-size: var(--font-size-m);
    margin-top: 2rem;
    margin-bottom: 0;
  }
`;
