import { CheckboxOptions } from '@src-v2/components/filters/menu-control/checkbox-options';
import { CustomOptions } from '@src-v2/components/filters/menu-control/custom-options';
import { DateRangeOptions } from '@src-v2/components/filters/menu-control/date-range-options';

export function FilterOptionFactory({ type, ...props }) {
  switch (type) {
    case 'checkbox':
      return <CheckboxOptions lazyLoad {...props} />;

    case 'custom':
      return <CustomOptions {...props} />;

    case 'dateRange':
      return <DateRangeOptions {...props} />;

    default:
      console.warn(`Unknown filter type "${type}"`);
      return null;
  }
}
