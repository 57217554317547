import _ from 'lodash';
import styled from 'styled-components';
import { Circle } from '@src-v2/components/circles';
import { SvgIcon } from '@src-v2/components/icons';
import { StyledRiskIcon } from '@src/blocks/RiskPosture/blocks/styles';
import { StyledContent } from '@src/components/CodeAggregation/Insights/InsightStyles';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { StyledCell, StyledRow, Table } from '@src/components/Table';
import VulnerabilityIdentifiers from '@src/components/VulnerabilityIdentifiers';
import VulnerabilityProviderLink from '@src/components/VulnerabilityProviderLink';
import { Ellipsis } from '@src/style/common';

const parseFixedIn = dependencyFinding => {
  const fixedInString = dependencyFinding.tags.FixedIn;
  if (!fixedInString) {
    return null;
  }
  return (JSON.parse(fixedInString) ?? []).join(', ');
};

const severityToOrder = {
  Critical: 0,
  High: 1,
  Medium: 2,
  Low: 3,
};

const Vulnerabilities = ({ dependencyFindings }) => (
  <VulnerabilitiesTable
    items={dependencyFindings}
    noTopPadding
    headers={[
      { name: 'IDs', weight: 3 },
      { name: 'Vulnerability', weight: 3 },
      { name: 'Exploit Maturity', weight: 2, requiresNonEmpty: true },
      { name: 'CWE', weight: 2 },
      { name: 'CVSS', weight: 1, requiresNonEmpty: true },
      { name: 'Fix Version', weight: 1.5 },
    ]}
    rows={_.map(
      _.orderBy(
        dependencyFindings,
        dependencyFinding => severityToOrder[_.upperFirst(dependencyFinding.severity)]
      ),
      dependencyFinding => ({
        key: dependencyFinding.url,
        cells: [
          {
            content: (
              <StyledHorizontalStack spacing="0.3">
                <StyledRiskIcon riskLevel={_.upperFirst(dependencyFinding.severity)} />
                <VulnerabilityIdentifiers cveIdentifiers={dependencyFinding.cveIdentifiers} />
              </StyledHorizontalStack>
            ),
          },
          {
            content: <FullTextContent>{dependencyFinding.type}</FullTextContent>,
          },
          {
            content: <StyledContent>{dependencyFinding.exploitMaturity}</StyledContent>,
          },
          {
            content: <VulnerabilityIdentifiers cweIdentifiers={dependencyFinding.cweIdentifiers} />,
          },
          {
            content: dependencyFinding.cvssScore !== 0 && (
              <StyledContent>{dependencyFinding.cvssScore}</StyledContent>
            ),
          },
          {
            content: (
              <StyledContent>
                {dependencyFinding.tags.NearestFixedVersion ||
                  parseFixedIn(dependencyFinding) ||
                  '-'}
              </StyledContent>
            ),
            menu: [
              dependencyFinding.provider !== 'ApiiroSca' && (
                <VulnerabilityProviderLink
                  isFloating
                  dependencyFinding={dependencyFinding}
                  provider={dependencyFinding.provider}
                />
              ),
              dependencyFinding.isApiProvided && (
                <VulnerabilityCircle>
                  <SvgIcon name="Api" />
                </VulnerabilityCircle>
              ),
            ],
          },
        ],
      })
    )}
  />
);

export default Vulnerabilities;

export const StyledHorizontalStack = styled(HorizontalStack)`
  ${Ellipsis};
`;

const FullTextContent = styled(StyledContent)`
  overflow: unset;
  white-space: break-spaces;
  text-overflow: unset;
`;

const VulnerabilitiesTable = styled(Table)`
  ${StyledRow} {
    padding: 0;

    ${StyledCell}:not(:first-child) {
      padding-left: 1rem;
    }
  }
`;
const VulnerabilityCircle = styled(Circle)`
  box-shadow: 0 0.5rem 6rem 0 rgba(186, 202, 213, 0.3);
  background-color: var(--color-white);

  &:hover {
    background-color: var(--color-blue-gray-30);
  }
`;
