import _ from 'lodash';
import { dispatch } from '@src/store';
import { riskOrder } from './riskService';

const Definitions = {
  assetCollections: {
    activeType: 'commits',
    profileType: 'assetCollection',
    profileTypePlural: 'assetCollections',
    profileTypeDisplayName: 'application',
    profileTypeDisplayNamePlural: 'Applications',
    routePath: '/profiles/applications',
    profileGetter: key =>
      dispatch.assetCollectionProfiles.getAssetCollectionProfileAsync({
        key,
        invalidateCache: true,
      }),
  },
  applicationGroups: {
    activeType: 'commits',
    profileType: 'applicationGroup',
    profileTypePlural: 'applicationGroups',
    profileTypeDisplayName: 'application group',
    profileTypeDisplayNamePlural: 'Application Groups',
    routePath: '/profiles/groups',
    profileGetter: key =>
      dispatch.applicationGroups.getApplicationGroupProfileAsync({
        key,
        invalidateCache: true,
      }),
    riskTriggersGetter: key =>
      dispatch.applicationGroups.getApplicationGroupRiskTriggersAsync({
        key,
        invalidateCache: true,
      }),
  },
  repositories: {
    activeType: 'commits',
    profileType: 'repository',
    profileTypePlural: 'repositories',
    profileTypeDisplayName: 'repository',
    profileTypeDisplayNamePlural: 'repositories',
    routePath: '/profiles/repositories',
    defaultSortKey: 'Risk',
    profileGetter: key =>
      dispatch.repositoryProfiles.getRepositoryProfileAsync({ key, invalidateCache: true }),
  },
  projects: {
    activeType: 'issues',
    profileType: 'project',
    profileTypePlural: 'projects',
    profileTypeDisplayName: 'project',
    profileTypeDisplayNamePlural: 'projects',
    routePath: '/profiles/projects',
    profileGetter: key =>
      dispatch.projectProfiles.getProjectProfileAsync({ key, invalidateCache: true }),
    riskTriggersGetter: key =>
      dispatch.projectProfiles.getProjectRiskTriggersAsync({ key, invalidateCache: true }),
  },

  clusters: {
    profileType: 'cluster',
    profileTypePlural: 'clusters',
    profileTypeDisplayName: 'cluster',
    profileTypeDisplayNamePlural: 'clusters',
    profileGetter: key =>
      dispatch.projectProfiles.getProjectProfileAsync({ key, invalidateCache: true }),
    riskTriggersGetter: key =>
      dispatch.projectProfiles.getProjectRiskTriggersAsync({ key, invalidateCache: true }),
  },
  pipelines: {
    profileType: 'pipeline',
    profileTypePlural: 'pipelines',
    profileTypeDisplayName: 'pipeline',
    profileTypeDisplayNamePlural: 'pipelines',
    profileGetter: key =>
      dispatch.projectProfiles.getProjectProfileAsync({ key, invalidateCache: true }),
    riskTriggersGetter: key =>
      dispatch.projectProfiles.getProjectRiskTriggersAsync({ key, invalidateCache: true }),
  },
};

const getProfileDefinitionByType = profileType =>
  Object.values(Definitions).find(
    definitions => definitions.profileType === normalizeProfileType(profileType)
  );

const processProfileRiskTriggers = (profileRiskTriggers, governanceRulesByKey) =>
  _.orderBy(
    (_.values(profileRiskTriggers?.ruleRiskTriggersByKey) ?? [])
      .map(ruleRiskTriggers => ({
        ...ruleRiskTriggers,
        rule: governanceRulesByKey[ruleRiskTriggers.ruleKey],
        triggers: _.values(ruleRiskTriggers.triggersByKey),
      }))
      .filter(ruleRiskTriggers => ruleRiskTriggers.rule),
    [
      ruleRiskTriggers => countTriggersByRiskChangeValue(ruleRiskTriggers, 'New') !== 0,
      ruleRiskTriggers => countTriggersByRiskChangeValue(ruleRiskTriggers, 'Existing') !== 0,
      ruleRiskTriggers => ruleRiskTriggers.count !== 0,
      ruleRiskTriggers => riskOrder[ruleRiskTriggers.riskLevel],
      ruleRiskTriggers => ruleRiskTriggers.count,
    ],
    ['desc', 'desc', 'desc', 'desc', 'asc']
  );

const countTriggersByRiskChangeValue = (ruleTriggers, riskChange) =>
  ruleTriggers.triggers.filter(trigger => trigger.riskChange === riskChange).length;

export const normalizeProfileType = profileType => _.camelCase(profileType.replace(/Profile$/, ''));

const profilesService = {
  getProfileDefinition: profileTypeKey => Definitions[profileTypeKey],

  getProfileDefinitionByType,

  getRoutePrefixByType: profileType => getProfileDefinitionByType(profileType).profileTypePlural,

  geDisplayNameByType: profileType =>
    getProfileDefinitionByType(profileType).profileTypeDisplayName,

  profileTypes: _.keys(Definitions),

  processProfileRiskTriggers,

  countTriggersByRiskChangeValue,
};

export default profilesService;
