import { forwardRef } from 'react';
import styled from 'styled-components';
import { ConditionalProviderIcon } from '@src-v2/components/icons';

const sizeSelector = ({ size, small }) => {
  switch (small ? 'small' : size) {
    case 'small':
      return '24px';
    case 'large':
      return '50px';
    default:
      return '36px';
  }
};

const ServerIcon = styled(
  forwardRef(({ provider, className }, ref) => (
    <span ref={ref} className={className}>
      <ConditionalProviderIcon name={provider} />
    </span>
  ))
)`
  display: inline-block;
  width: ${sizeSelector};
  height: ${sizeSelector};
  flex-shrink: 0;

  &.centered {
    display: flex;
  }

  img {
    width: ${sizeSelector};
    height: ${sizeSelector};
  }
`;

export default ServerIcon;
