import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { IconButton } from '@src-v2/components/buttons';
import { Circle } from '@src-v2/components/circles';
import { BaseIcon } from '@src-v2/components/icons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { FilterButton } from '@src/components/FilterButton';
import { TrimmedHorizontalStack } from '@src/components/TrimmedHorizontalStack';
import { FontBodySmall } from '@src/style/common';

const StyledHorizontalStack = styled(TrimmedHorizontalStack)`
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 3px;
`;

const StyledBottomPart = styled.div`
  ${FontBodySmall};
  padding: 3rem;
  border-radius: 3rem;
  background-color: var(--color-blue-gray-15);

  ${Circle} {
    margin-top: 4rem;
    background-color: var(--color-white);
    box-shadow: var(--elevation-1);
    transition: box-shadow 200ms;
    cursor: pointer;

    &:hover {
      box-shadow: var(--elevation-2);

      ${BaseIcon} {
        color: var(--color-blue-gray-60);
      }
    }
  }
`;

const SelectedFiltersBox = ({ filterCount, clearFilters, filterOptions, handleFilterToggle }) => {
  const filterButtons = filterOptions.flatMap(filterGroup =>
    filter(filterGroup.options, filterOption => filterOption.isSelected).map(filterOption => (
      <FilterButton
        key={filterGroup.component + filterOption.name}
        small
        isSelected
        onClose={() => handleFilterToggle(filterOption.name, filterGroup.component)}>
        {filterOption.displayName}
      </FilterButton>
    ))
  );

  return (
    !isEmpty(filterButtons) && (
      <StyledBottomPart>
        {!isEmpty(filterButtons) && (
          <StyledHorizontalStack
            spacing="0.3"
            items={filterButtons}
            nameSingle="filter"
            namePlural="filters"
          />
        )}

        {filterCount > 1 && (
          <Tooltip content="Clear filters">
            <Circle size="xsmall" onClick={clearFilters}>
              <IconButton name="Close" />
            </Circle>
          </Tooltip>
        )}
      </StyledBottomPart>
    )
  );
};

export default SelectedFiltersBox;
