export enum Provider {
  Unresolved = 'Unresolved',
  AzureDevops = 'AzureDevops',
  AzureDevopsServer = 'AzureDevopsServer',
  BitbucketServer = 'BitbucketServer',
  BitbucketCloud = 'BitbucketCloud',
  Github = 'Github',
  GithubEnterprise = 'GithubEnterprise',
  GithubSecrets = 'GithubSecrets',
  Gitlab = 'Gitlab',
  GitlabServer = 'GitlabServer',
  GitlabSast = 'GitlabSast',
  Perforce = 'Perforce',
  GitlabSca = 'GitlabSca',
  GitlabDast = 'GitlabDast',
  GitlabSecretDetection = 'GitlabSecretDetection',
  Jira = 'Jira',
  JiraServer = 'JiraServer',
  Slack = 'Slack',
  Teams = 'Teams',
  GoogleChat = 'GoogleChat',
  Checkmarx = 'Checkmarx',
  Veracode = 'Veracode',
  Fortify = 'Fortify',
  Snyk = 'Snyk',
  SnykSca = 'SnykSca',
  SnykSast = 'SnykSast',
  SnykContainerScanning = 'SnykContainerScanning',
  Mend = 'Mend',
  Mend2 = 'Mend2',
  BlackDuck = 'BlackDuck',
  SonarCloud = 'SonarCloud',
  SonarQube = 'SonarQube',
  ShiftLeft = 'ShiftLeft',
  Semgrep = 'Semgrep',
  SemgrepCode = 'SemgrepCode',
  SemgrepSupplyChain = 'SemgrepSupplyChain',
  AWS = 'AWS',
  GitPlain = 'GitPlain',
  LocalKubeConfig = 'LocalKubeConfig',
  Okta = 'Okta',
  Wiz = 'Wiz',
  JFrog = 'JFrog',
  JFrogXray = 'JFrogXray',
  PrismaCloud = 'PrismaCloud',
  CrowdStrike = 'CrowdStrike',
  Jenkins = 'Jenkins',
  FindingsApi = 'FindingsApi',
  CodeQL = 'CodeQL',
  Dependabot = 'Dependabot',
  AppScanSast = 'AppScanSast',
  AppScanDast = 'AppScanDast',
  ManagedCheckov = 'ManagedCheckov',
  ManagedSemgrep = 'ManagedSemgrep',
  Splunk = 'Splunk',
  NetworkBroker = 'NetworkBroker',
  AzureActiveDirectory = 'AzureActiveDirectory',
  AzureCloud = 'AzureCloud',
  GoogleCloud = 'GoogleCloud',
  SpringApiGateway = 'SpringApiGateway',
  NginxController = 'NginxController',
  TykApiGateway = 'TykApiGateway',
  ApiiroSca = 'ApiiroSca',
  Apiiro = 'Apiiro',
  Invicti = 'Invicti',
  CircleCi = 'CircleCi',
  Qualys = 'Qualys',
  PolarisSast = 'PolarisSast',
  Bugcrowd = 'Bugcrowd',
  BurpSuite = 'BurpSuite',
  ServiceNow = 'ServiceNow',
  SonatypeLifecycle = 'SonatypeLifecycle',
  SecureCodeWarrior = 'SecureCodeWarrior',
  ManualUpload = 'ManualUpload',
  HackerOne = 'HackerOne',
  AkamaiApiSecurity = 'AkamaiApiSecurity',
  Orca = 'Orca',
  Backstage = 'Backstage',
  SdElements = 'SDElements',
  SaltSecurity = 'SaltSecurity',
  Tenable = 'Tenable',
  InsightVm = 'InsightVm',
  Nexpose = 'Nexpose',
  AkamaiApiSecurityActiveTesting = 'AkamaiApiSecurityActiveTesting',
}
