import isEmpty from 'lodash/isEmpty';
import { useCallback, useState } from 'react';
import ReactSelect from 'react-select';
import styled from 'styled-components';
import { SvgIcon } from '@src-v2/components/icons';
import { toggleValues } from '@src-v2/utils/collection-utils';
import { AddElementPlaceholder } from '@src/components/AddElementPlaceholder';
import { HorizontalStack } from '@src/components/HorizontalStack';
import {
  ReactSelectThemeLightClass,
  ReactSelectThemeLightStyle,
} from '@src/components/ReactSelect/ReactSelectThemeLight';
import { VerticalStack } from '@src/components/VerticalStack';
import { Input } from '@src/components/reactstrap-polyfill';

const inputWidth = 250;

const StyledInputContainer = styled.div`
  display: inline-block;
  width: ${inputWidth}px;
  margin-bottom: 3rem;
`;

const StyledAddElementPlaceholder = styled(AddElementPlaceholder)`
  margin-bottom: 3rem;
`;

const RemoveButton = styled.div`
  position: absolute;
  right: 0;
  display: flex;
  padding: 6px;
  color: #495057;
  align-items: center;
  border: 1px solid #ced4da;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
`;

const StyledInput = styled(Input)`
  background-color: var(--color-blue-gray-15);

  &:focus {
    box-shadow: none;
    border-color: var(--color-blue-gray-35);
  }

  &::placeholder {
    color: var(--color-blue-gray-45);
    opacity: 1;
  }
`;

const StyledInputGroup = styled.div`
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  align-items: stretch;
  width: 100%;

  ${StyledInput} {
    padding-right: 40px;
    border-radius: 1rem;
  }
`;

const StyledTypeSelector = styled(ReactSelect)`
  ${ReactSelectThemeLightStyle};
  width: 170px;
  min-width: 170px;
  align-self: flex-start;
`;

const StyledAddTrackingType = styled(AddElementPlaceholder)`
  margin-top: 3rem;
`;

const StyledSelect = styled(StyledTypeSelector)`
  ${ReactSelectThemeLightStyle};
  width: ${inputWidth * 2}px;
`;

const updateArrayAtIndex = (arr, index, value, setter) => {
  const clone = [...arr];
  clone[index] = value;
  setter(clone);
};

const removeByIndex = (array, index) => {
  const clone = [...array];
  clone.splice(index, 1);
  return clone;
};

const TypeSelector = ({ availableOptions, value, ...props }) => (
  <StyledTypeSelector
    classNamePrefix={ReactSelectThemeLightClass}
    isSearchable={false}
    options={availableOptions}
    value={value ?? availableOptions[0]}
    components={{
      IndicatorSeparator: () => null,
    }}
    {...props}
  />
);

const MultiValueSelector = ({ definition, field, setter, inputPlaceholder, removeSection }) => {
  const addElement = useCallback(current => setter([...current, '']), [setter]);
  const currentValues = isEmpty(definition[field]) ? [''] : definition[field];

  return (
    <HorizontalStack wrapItems>
      {currentValues.map((label, index) => (
        <StyledInputContainer key={index}>
          <StyledInputGroup>
            <StyledInput
              value={label}
              onChange={event =>
                updateArrayAtIndex(definition[field], index, event.target.value, setter)
              }
              placeholder={inputPlaceholder}
            />
            <RemoveButton
              onClick={() => {
                const newValue = removeByIndex(definition[field], index);
                if (isEmpty(newValue)) {
                  removeSection();
                }

                setter(newValue);
              }}>
              <SvgIcon name="Close" />
            </RemoveButton>
          </StyledInputGroup>
        </StyledInputContainer>
      ))}
      {!isEmpty(definition[field]) && (
        <StyledAddElementPlaceholder text="Add Value" onClick={() => addElement(currentValues)} />
      )}
    </HorizontalStack>
  );
};

const options = [
  { label: 'Label values', value: 'labels' },
  { label: 'Title contains', value: 'titles' },
  { label: 'Issue types', value: 'types' },
  { label: 'Component values', value: 'components' },
];

export const FiltersSection = ({ definition, setDefinition, issueTypes }) => {
  const [showingSections, setShowingSections] = useState(() => {
    const initialShowingSections = [];

    if (!isEmpty(definition.labels)) {
      initialShowingSections.push('labels');
    }

    if (!isEmpty(definition.titles)) {
      initialShowingSections.push('titles');
    }

    if (!isEmpty(definition.types)) {
      initialShowingSections.push('types');
    }

    if (!isEmpty(definition.components)) {
      initialShowingSections.push('components');
    }

    return initialShowingSections;
  });

  const setLabels = useCallback(
    labels =>
      setDefinition({
        ...definition,
        labels,
      }),
    [definition, setDefinition]
  );

  const setTitles = useCallback(
    titles =>
      setDefinition({
        ...definition,
        titles,
      }),
    [definition, setDefinition]
  );

  const setTypes = useCallback(
    types =>
      setDefinition({
        ...definition,
        types,
      }),
    [definition, setDefinition]
  );

  const setComponents = useCallback(
    components =>
      setDefinition({
        ...definition,
        components,
      }),
    [definition, setDefinition]
  );

  const sections = {
    labels: definition => (
      <MultiValueSelector
        definition={definition}
        setter={setLabels}
        field="labels"
        inputPlaceholder="e.g 'Pen-Test'"
        removeSection={() => setShowingSections(toggleValues(showingSections, 'labels'))}
      />
    ),
    titles: definition => (
      <MultiValueSelector
        definition={definition}
        setter={setTitles}
        field="titles"
        inputPlaceholder="e.g '[PT]'"
        removeSection={() => setShowingSections(toggleValues(showingSections, 'titles'))}
      />
    ),
    types: definition => (
      <StyledSelect
        isMulti
        placeholder="e.g 'Story'"
        classNamePrefix={ReactSelectThemeLightClass}
        options={issueTypes.map(type => ({ label: type, value: type }))}
        value={definition.types?.map(type => ({ label: type, value: type }))}
        onChange={selectedTypes => setTypes(selectedTypes?.map(type => type.value))}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    ),
    components: definition => (
      <MultiValueSelector
        definition={definition}
        setter={setComponents}
        field="components"
        inputPlaceholder="e.g 'API'"
        removeSection={() => setShowingSections(toggleValues(showingSections, 'components'))}
      />
    ),
  };

  const [showTrackingTypeSelector, setShowTrackingTypeSelector] = useState(false);
  const availableOptions = options.filter(option => !showingSections.includes(option.value));

  return (
    <VerticalStack>
      {showingSections.map(name => (
        <HorizontalStack key={name}>
          <TypeSelector
            availableOptions={options}
            value={options.find(option => option.value === name)}
            onChange={newType => {
              if (newType.value === name) {
                return;
              }
              setDefinition({
                ...definition,
                [name]: [],
              });

              let newSections = showingSections;
              if (!showingSections.includes(newType.value)) {
                newSections = toggleValues(showingSections, newType.value);
              }

              setShowingSections(toggleValues(newSections, name));
            }}
          />
          {sections[name](definition)}
        </HorizontalStack>
      ))}
      {!isEmpty(availableOptions) && (
        <div>
          {showTrackingTypeSelector && (
            <TypeSelector
              autoFocus
              defaultMenuIsOpen
              availableOptions={availableOptions}
              setShowingSections={setShowingSections}
              showingSections={showingSections}
              setShowTrackingTypeSelector={setShowTrackingTypeSelector}
              onChange={selectedOption =>
                setShowingSections(toggleValues(showingSections, selectedOption.value))
              }
              onMenuClose={() => {
                setShowingSections(toggleValues(showingSections, availableOptions[0].value));
                setShowTrackingTypeSelector(false);
              }}
            />
          )}
          <StyledAddTrackingType
            text="Add tracking type"
            onClick={() => setShowTrackingTypeSelector(true)}
          />
        </div>
      )}
    </VerticalStack>
  );
};
