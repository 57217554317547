import { useEffect } from 'react';
import styled from 'styled-components';
import { MotionAnimation } from '@src-v2/components/animations/motion-animation';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { Pane } from '@src-v2/components/panes/pane';
import { PaneBody } from '@src-v2/components/panes/pane-body';
import { PaneStickyHeader } from '@src-v2/components/panes/pane-sticky-header';
import { Heading, Paragraph } from '@src-v2/components/typography';
import { useInject } from '@src-v2/hooks';
import { useDetectScrolling } from '@src-v2/hooks/dom-events/use-detect-scrolling';

export const LearningPane = styled(({ children, ...props }) => {
  const { analytics } = useInject();
  const [scrolled, onScroll] = useDetectScrolling();

  useEffect(() => {
    analytics.track('Learning Statistics Opened');
  }, []);

  return (
    <Pane {...props}>
      <PaneStickyHeader scrolled={scrolled}>
        <LearningAnimation size={3} width={5} margin={7} />
        <Heading>
          Apiiro is Continuously <br /> Scanning Your Environment
        </Heading>
      </PaneStickyHeader>

      <LearningPaneBody onScroll={onScroll}>
        <AsyncBoundary
          errorFallback={<Paragraph>Still processing data, please come back later...</Paragraph>}>
          {children}
        </AsyncBoundary>
      </LearningPaneBody>
    </Pane>
  );
})`
  ${Heading} {
    text-align: center;
    margin-bottom: 14rem;
    font-size: 5rem;
  }
`;

const LearningAnimation = styled(MotionAnimation)`
  display: block;
  margin: 5rem auto 7rem;
`;

const LearningPaneBody = styled(PaneBody)`
  padding: 1rem 6rem 0;
`;
