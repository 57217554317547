import some from 'lodash/some';
import styled from 'styled-components';
import { DistanceTime, TimeTooltip } from '@src-v2/components/time';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { ProfileRiskBadge } from '@src/blocks/ConsumableInfiniteScroll/blocks/ProfileRiskBadge';
import { Number } from '@src/components/Number';
import { ConsumableLink } from './ConsumableLink';
import { HorizontalStack } from './HorizontalStack';
import { Languages } from './Languages';
import { VerticalStack } from './VerticalStack';

const StyledPopover = styled(PopoverTip)`
  text-decoration: underline;
`;

export const RepositoryPopover = ({ repositoryProfile, className }) => (
  <StyledPopover
    className={className}
    linkText={repositoryProfile.repository.name}
    maxWidth={400}
    title={
      <HorizontalStack>
        <ConsumableLink consumable={repositoryProfile.repository} />
        <ProfileRiskBadge profile={repositoryProfile} showBusinessImpact="never" />
      </HorizontalStack>
    }>
    <VerticalStack>
      <span>
        <Number
          value={repositoryProfile.recentMaterialChangeCount}
          one="material change"
          other="material changes"
        />{' '}
        in past 12 weeks
      </span>
      {repositoryProfile.activeSince && (
        <TimeTooltip date={repositoryProfile.activeSince}>
          <span>
            First activity <DistanceTime date={repositoryProfile.activeSince} addSuffix strict />
          </span>
        </TimeTooltip>
      )}
      {repositoryProfile.isActive && (
        <TimeTooltip date={repositoryProfile.lastActivity}>
          <span>
            Last activity <DistanceTime date={repositoryProfile.lastActivity} addSuffix strict />
          </span>
        </TimeTooltip>
      )}
      {some(repositoryProfile.languagePercentages) && (
        <Languages languagePercentages={repositoryProfile.languagePercentages} small />
      )}
    </VerticalStack>
  </StyledPopover>
);
