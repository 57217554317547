import { ControlledCard, ControlledCardProps } from '@src-v2/components/cards/controlled-card';
import { Feedback } from '@src-v2/components/feedback';
import { useCveEntityContext } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-context-provider';
import { useCveFeedbackContext } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/use-cve-feedback-context';

export function CveEntityDescriptionCard(props: ControlledCardProps) {
  const { cveEntity } = useCveEntityContext();
  const feedbackContext = useCveFeedbackContext(cveEntity.triage.summary);

  return (
    <ControlledCard {...props} title="Description" footer={<Feedback context={feedbackContext} />}>
      {cveEntity.triage.summary}
    </ControlledCard>
  );
}
