import ClusterResourceContainerIconUrl from '@src-v2/assets/images/cluster-resource-container.svg';
import ClusterResourceExternalGwIconUrl from '@src-v2/assets/images/cluster-resource-external-gw.svg';
import ClusterResourceIngressIconUrl from '@src-v2/assets/images/cluster-resource-ingress.svg';
import ClusterResourceInternalGwIconUrl from '@src-v2/assets/images/cluster-resource-internal-gw.svg';
import ClusterResourceSecretIconUrl from '@src-v2/assets/images/cluster-resource-secret.svg';
import ClusterResourceVolumeIconUrl from '@src-v2/assets/images/cluster-resource-volume.svg';
import { getCssVariable } from '@src-v2/utils/style-utils';

const RISK_BADGE_OFFSET = 6;

export const clusterChartViewPresentationConfig = {
  nodeTypes: {
    ClusterContainer: {
      iconUrl: ClusterResourceContainerIconUrl,
      preScaledCanvas: null,
      image: null,
      friendlyName: 'Container',
      riskBadgeOffset: RISK_BADGE_OFFSET,
    },

    ClusterInternalTrafficGateway: {
      iconUrl: ClusterResourceInternalGwIconUrl,
      preScaledCanvas: null,
      image: null,
      friendlyName: 'Internal Gateway',
      riskBadgeOffset: RISK_BADGE_OFFSET,
    },

    ClusterExternalTrafficGateway: {
      iconUrl: ClusterResourceExternalGwIconUrl,
      preScaledCanvas: null,
      image: null,
      friendlyName: 'External Gateway',
      riskBadgeOffset: RISK_BADGE_OFFSET,
    },

    ClusterSecret: {
      iconUrl: ClusterResourceSecretIconUrl,
      preScaledCanvas: null,
      image: null,
      friendlyName: 'Kubernetes Secret',
      riskBadgeOffset: RISK_BADGE_OFFSET,
    },

    ClusterVolume: {
      iconUrl: ClusterResourceVolumeIconUrl,
      preScaledCanvas: null,
      image: null,
      friendlyName: 'Volume',
      riskBadgeOffset: RISK_BADGE_OFFSET,
    },

    ClusterIngress: {
      iconUrl: ClusterResourceIngressIconUrl,
      preScaledCanvas: null,
      image: null,
      friendlyName: 'Ingress',
      riskBadgeOffset: RISK_BADGE_OFFSET,
    },
  },

  unknownNodeType: {
    iconUrl: ClusterResourceContainerIconUrl,
    friendlyName: 'Resource',
    riskBadgeOffset: RISK_BADGE_OFFSET,
  },

  linkTypes: {
    ClusterNetworkRoute: {
      color: getCssVariable('--link-color-primary'),
      friendlyName: 'Network Route',
    },

    DataConsumptionLink: {
      color: getCssVariable('--link-color-secondary'),
      friendlyName: 'Uses',
    },
  },

  unknownLinkType: {
    color: getCssVariable('--link-color-unknown'),
    friendlyName: 'Link',
  },
};
