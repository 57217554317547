import styled from 'styled-components';
import { Button } from '@src/components/Button';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { CircledIcon, FontBodyBold, FontBodySmall } from '@src/style/common';

export const SearchAndFilterContainer = styled.div`
  ${props => props.spread && `padding-bottom: 12rem`};
`;

export const SearchAndFilter = styled(HorizontalStack)`
  display: flex;
  height: 12rem;
  padding: 0 4rem;
  align-items: baseline;
  border-radius: 3rem 3rem 0 0;
  background-color: var(--color-blue-gray-20);
  ${props => props.spread && 'justify-content: space-between'};
  ${props => props.spread && 'align-items: center'};
`;

export const StyledSearchBar = styled(HorizontalStack)`
  flex: 1;
  max-width: 270px;
  align-items: baseline;
  align-self: center;
  height: 30px;
  border-radius: 6rem;
  padding: 3px;
  margin-right: 3px;
  background-color: ${props => (props.open ? 'var(--color-white)' : 'var(--color-blue-gray-20)')};
`;

export const StyledSearch = styled.input`
  ${FontBodySmall};
  flex: 1;
  height: 6rem;
  padding: 0 4rem;
  margin-left: 0;
  border-radius: 18px;
  border: none;

  ::placeholder {
    color: var(--color-blue-gray-45);
    opacity: 1;
  }
`;

export const StyledSearchIcon = styled.div`
  ${CircledIcon};
  background-color: ${props => (props.open ? 'transparent' : 'var(--color-white)')};
`;

export const StyledSearchIconClosedButton = styled(Button)`
  width: 30px;
  height: 30px;
  padding: 0;
  align-self: center;
  border: none;
`;

export const SearchBarLeft = styled.div`
  display: flex;
  align-self: center;
  flex-grow: 1;
  gap: 3rem;
`;

export const StyledExportIcon = styled.div`
  ${CircledIcon};
  display: inline-flex;
  align-self: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 6px;
  background-color: var(--color-white);
  cursor: pointer;
`;

export const StyledCount = styled.span`
  ${FontBodyBold};
`;
