import ReactDOM from 'react-dom';
import 'react-toastify/dist/ReactToastify.css';
import Bootstrap from '@src-v2/containers/bootstrap';
import GlobalStyle from '@src-v2/style/loader';

ReactDOM.render(
  <>
    <GlobalStyle />
    <Bootstrap />
  </>,
  document.getElementById('root')
);

if (module.hot) {
  module.hot.accept();
}
