import _ from 'lodash';
import ioc from '@src-v2/ioc';
import apiService from '@src/services/apiService';

const clearData = () => ({
  entityProfileByKey: {},
});

export default {
  state: clearData(),
  reducers: {
    setEntityProfile: (state, entityProfile) => ({
      ...state,
      entityProfileByKey: {
        ...state.entityProfileByKey,
        ...entityProfile.entityIds.reduce((memo, entityId) => {
          const key = [entityProfile.repositoryKey, entityProfile.entityType, entityId].join(',');
          memo[key] = entityProfile;
          return memo;
        }, {}),
      },
    }),
  },
  selectors: slice => ({
    entityProfileByKey: () => slice(state => state.entityProfileByKey),
  }),
  effects: dispatch => ({
    async getEntityProfileAsync({ repositoryKey, entityType, entityId, invalidateCache }, models) {
      if (_.isNil(entityType) || _.isNil(entityId)) {
        return null;
      }

      const state = models.entityProfiles;
      if (!invalidateCache) {
        const key = [repositoryKey, entityType, entityId].join(',');
        if (!_.isNil(state.entityProfileByKey[key])) {
          return state.entityProfileByKey[key];
        }
      }
      const { data: entityProfile } = await apiService.get(
        `/api/repositories/${repositoryKey}/entityProfile/${entityType}`,
        {
          clearCacheEntry: invalidateCache,
          params: { entityId },
        }
      );
      if (!_.isEmpty(entityProfile)) {
        entityProfile.events = _.orderBy(entityProfile.events, 'timestamp', 'desc');
        this.setEntityProfile(entityProfile);
      }
      return entityProfile;
    },
    async getCreatorAndLastModifierAsync(
      { repositoryKey, entityType, entityId, invalidateCache },
      models
    ) {
      const entityProfile = await this.getEntityProfileAsync(
        { repositoryKey, entityType, entityId, invalidateCache },
        models
      );
      const introducedDeveloperIdentityKey = entityProfile?.creatorDeveloperIdentityKey;
      let introducedDeveloperProfile = !_.isEmpty(introducedDeveloperIdentityKey)
        ? await dispatch.developerProfiles.getDeveloperByIdentityKeyAsync({
            key: introducedDeveloperIdentityKey,
          })
        : null;

      const modifiedDeveloperIdentityKey = entityProfile?.events?.[0]?.developerIdentityKey;
      let modifiedDeveloperProfile = !_.isEmpty(modifiedDeveloperIdentityKey)
        ? await dispatch.developerProfiles.getDeveloperByIdentityKeyAsync({
            key: modifiedDeveloperIdentityKey,
          })
        : null;

      if (
        ioc.application.isDemo &&
        _.isEmpty(introducedDeveloperProfile) &&
        _.isEmpty(modifiedDeveloperProfile)
      ) {
        const { items } = await apiService.searchTable({
          tableScope: `repositories/${entityProfile.repositoryKey}/developers`,
          currentPage: 0,
        });
        modifiedDeveloperProfile = !_.isEmpty(items) && items[0].profile;
        introducedDeveloperProfile =
          !_.isEmpty(items) && (items.length > 1 ? items[1] : items[0]).profile;
      }

      return { introducedDeveloperProfile, modifiedDeveloperProfile };
    },
  }),
};
