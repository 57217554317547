import { Fragment } from 'react';
import styled from 'styled-components';
import { LanguageCircle } from '@src-v2/components/circles';
import { Size } from '@src-v2/components/types/enums/size';
import { MatchReasoning } from '@src-v2/types/artifacts/artifacts-types';

export const MatchedInfoTooltipContent = ({
  matchReasonings,
}: {
  matchReasonings: MatchReasoning[];
}) => {
  const [firstMatchReasoning] = matchReasonings;
  const {
    language = '',
    matchedPropertiesDescription = [],
    packageManager = '',
    relevantContainerPath = '',
    relevantRepositoryPath = '',
  } = firstMatchReasoning ?? {};

  return (
    <InfoNodeTooltipContent>
      <InfoNodeTooltipRow>
        Apiiro detected a match between the repository and the container image, based on:
      </InfoNodeTooltipRow>
      <InfoNodeTooltipRow>
        Methods:{' '}
        {matchedPropertiesDescription.map((description: string, index: number, array: string[]) => (
          <Fragment key={index}>
            {description}
            {index < array.length - 2 ? ', ' : index < array.length - 1 ? ' and ' : ''}
          </Fragment>
        ))}
      </InfoNodeTooltipRow>
      <InfoNodeTooltipRow>
        Technology: {packageManager && <LanguageCircle name={language} size={Size.XSMALL} />}{' '}
        {packageManager}
      </InfoNodeTooltipRow>
      <InfoNodeTooltipRow>Path in repository: {relevantRepositoryPath}</InfoNodeTooltipRow>
      <InfoNodeTooltipRow>Path in container image: {relevantContainerPath}</InfoNodeTooltipRow>
    </InfoNodeTooltipContent>
  );
};

const InfoNodeTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InfoNodeTooltipRow = styled.div``;
