import { observer } from 'mobx-react';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { Select } from '@src-v2/components/forms/select';
import { SearchCombobox } from '@src-v2/containers/search-combobox';
import { useDebounce, useGroupProperties } from '@src-v2/hooks';
import { usePersistentSearchState } from '@src-v2/hooks/use-search-state';
import { useTypeahead } from '@src-v2/hooks/use-typeahead';

const typeheadProperties = ['wait', 'ttl', 'options'];

export const SelectInput = forwardRef(({ as: Component = Select, searchMethod, ...props }, ref) => {
  const [typeheadProps, elementProps] = useGroupProperties(props, typeheadProperties);
  const [handleInput, items, loading] = useSelectTypeahead(searchMethod, typeheadProps);
  return (
    <SearchCombobox
      {...elementProps}
      as={Component}
      ref={ref}
      items={items}
      isLoading={loading}
      onInput={handleInput}
    />
  );
});

export const PersistentSearchCombobox = observer(
  forwardRef(({ as: Component = Select, searchMethod, value, ...props }, ref) => {
    const [typeheadProps, elementProps] = useGroupProperties(props, typeheadProperties);
    const [handleInput, searchState] = usePersistentTypeahead({ searchMethod, ...typeheadProps });
    return (
      <SearchCombobox
        {...elementProps}
        as={Component}
        ref={ref}
        value={value}
        items={searchState?.items ?? []}
        searchState={searchState}
        isLoading={searchState.loading}
        totalCount={searchState.total}
        onInput={handleInput}
      />
    );
  })
);

const usePersistentTypeahead = ({ searchMethod, wait, ttl, options, ...rest }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInput = useDebounce(event => setSearchTerm(event.inputValue), { wait: 200 }, [
    setSearchTerm,
  ]);

  const searchState = usePersistentSearchState(
    searchMethod,
    { ...rest, searchTerm },
    { ignoreInit: true }
  );

  return [handleInput, searchState];
};

export function useSelectTypeahead(searchMethod, params = {}) {
  const [dataFetcher, ...rest] = useTypeahead(searchMethod, params);

  useEffect(() => {
    handleInput({ inputValue: '' });
  }, [searchMethod]);

  const handleInput = useCallback(
    event => dataFetcher({ searchTerm: event.inputValue }),
    [dataFetcher]
  );

  return [handleInput, ...rest];
}
