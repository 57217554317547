import { forwardRef } from 'react';
import styled from 'styled-components';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { Button } from '@src/components/Button';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Input } from '@src/components/reactstrap-polyfill';
import {
  CircledIcon,
  Ellipsis,
  FontBody,
  FontBodyBold,
  FontBodySmall,
  StyledVerticalContainer,
} from '@src/style/common';

const FilterIcon = forwardRef((props, ref) => <SvgIcon ref={ref} {...props} name="Filter" />);

export const StyledFiltersCard = styled(StyledVerticalContainer)`
  width: ${props => (props.$inline ? '270px' : '360px')};
  max-height: calc(100vh - 220px);
  margin-left: 6px;
  align-self: flex-start;
`;

export const StyledFilterOption = styled.label`
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--color-blue-gray-15);
  }
`;

export const StyledFilterOptionCard = styled.div`
  display: flex;
  flex-flow: column;
  overflow-y: auto;
  flex: 1;
`;

export const FilterGroupHeader = styled.div`
  ${FontBodyBold};
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const FilterGroupCount = styled.div`
  ${FontBody};
  opacity: 0.7;
  margin-top: 3rem;
  margin-bottom: 3rem;
`;

export const StyledFilterIconClosed = styled(FilterIcon)`
  ${CircledIcon};
  color: var(--default-text-color);
  background-color: var(--color-white);
  border: 0;
`;

export const StyledFilterIconClosedButton = styled(Button)`
  padding: 0;
  border: 0;
  height: 12rem;
  width: 12rem;
`;

export const StyledFilterIconOpenButton = styled(Button)`
  font-size: 0;
  padding: 0;
  margin-left: 0;
  border: 0;
  height: 12rem;
  width: 12rem;
`;

export const StyledFilterIconOpen = styled(FilterIcon)`
  ${CircledIcon};
  background-color: var(--color-white);
  color: var(--default-text-color);
  margin-right: ${props => (props.open ? 0 : '3rem')};
`;

export const StyledOptionName = styled.span`
  ${Ellipsis};
  ${FontBodySmall};
  padding: 6px;
  margin-left: ${props => (props.button ? '0' : '6px')};
`;

export const StyledHeaderHorizontalStack = styled(HorizontalStack)`
  flex: 1;
  justify-content: flex-end;
  text-align: left;

  > ${BaseIcon} {
    margin-left: 3rem;
    color: var(--default-text-color);
    background-color: var(--color-white);
  }
`;

export const StyledActiveFilterCount = styled.div`
  ${FontBodySmall};
  opacity: 0.7;
  display: flex;
  align-items: center;
  flex: 1;
  padding: 6px 3rem;
  border-radius: 18px;
  border-color: ${props => (props.$inline ? 'var(--color-blue-gray-15)' : 'var(--color-white)')};
`;

export const StyledClosedNonEmptyFilter = styled(HorizontalStack)`
  align-self: center;
  margin-left: 6px;
  width: ${props => (props.$inline ? '270px' : '360px')};
  background-color: var(--color-white);
  border-radius: 6rem;
  height: ${props => (props.$inline ? '30px' : '12rem')};
`;

export const StyledSearch = styled(Input)`
  ${FontBodySmall};
  flex: 1;
  height: 9rem;
  padding: 6px 3rem;
  border: none;
  border-radius: 18px;
  outline-width: 0;
  background-color: var(--color-blue-gray-15);

  ::placeholder {
    color: var(--color-blue-gray-45);
    opacity: 1;
  }

  &:focus {
    box-shadow: none;
    background-color: var(--color-blue-gray-15);
  }
`;

export const StyledDescription = styled.div`
  opacity: 0.5;
  ${FontBodySmall};
`;

export const FiltersBehaviorContainer = styled.div`
  padding-bottom: 24px;
  border-bottom: 0.25rem solid var(--color-blue-gray-35);
`;
