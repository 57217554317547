import { isEmpty } from 'lodash';
import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { openSecretPaneWithRiskActions } from '@src/components/Panes/SecretPane';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getSecretsTriggerDisplay = ({ trigger, profile, ...options }) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  const environments = !isEmpty(trigger.environments) ? `(${trigger.environments.join(', ')})` : '';
  return [
    {
      weight: 3,
      content: (
        <PaneLocationLink
          id={trigger.key}
          onClick={args =>
            openSecretPaneWithRiskActions({ ...args, profile, trigger, ...options })
          }>
          <TriggerInfoContainer
            title={addInterpunctSeparator(
              profile.profileType === 'ApplicationProfile' && repository.name,
              trigger.relativeFilePath,
              environments
            )}
            insights={trigger.insights}>
            <HorizontalStack>
              <span>{trigger.subCategoryDescription}</span>
              <span> &middot; </span>
              <span>{trigger.type}</span>
              {trigger.lineNumbers?.length > 1 && (
                <>
                  <span> &middot; </span>
                  <span> Appears {trigger.lineNumbers.length} times in the file</span>
                </>
              )}
            </HorizontalStack>
          </TriggerInfoContainer>
        </PaneLocationLink>
      ),
    },
  ];
};

export const getSecretsMessageFormat = element => element.relativeFilePath;
