import { useEffect, useState } from 'react';
import { usePrevious } from '@src-v2/hooks/react-helpers/use-previous';

/**
 * Unique ID that changes when value (reference) has changed
 * @param {*} value any value
 * @return {number} random number indicating change ID
 */
export function useChangeId(value) {
  const [changeId, setChangeId] = useState(Math.random());
  const previousValue = usePrevious(value, value);

  useEffect(() => {
    if (value !== previousValue) {
      setChangeId(Math.random());
    }
  }, [value]);

  return changeId;
}
