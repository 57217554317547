import { ClampText } from '@src-v2/components/clamp-text';
import { ExternalLink } from '@src-v2/components/typography';
import {
  CVE_BASE_URL,
  CWE_BASE_URL,
  GHSA_BASE_URL,
  GO_BASE_URL,
} from '@src-v2/data/sca-dependency';

const getCveIdentifierUrl = (identifier: string) => {
  if (identifier.startsWith('CVE-')) {
    return CVE_BASE_URL;
  }

  if (identifier.startsWith('GHSA-')) {
    return GHSA_BASE_URL;
  }

  if (identifier.startsWith('GO-')) {
    return GO_BASE_URL;
  }

  return null;
};

export function CveLink({ cve }: { cve: string }) {
  const baseUrl = getCveIdentifierUrl(cve);
  const url = baseUrl ? `${getCveIdentifierUrl(cve)}${cve}` : null;

  return (
    <>
      {url ? (
        <ExternalLink key={cve} href={url}>
          <ClampText lines={2}>{cve}</ClampText>
        </ExternalLink>
      ) : (
        <ClampText lines={2}>{cve}</ClampText>
      )}
    </>
  );
}

export function CweLink({ cwe }: { cwe: string }) {
  return (
    <ExternalLink key={cwe} href={`${CWE_BASE_URL}${cwe.toLowerCase().replace('cwe-', '')}.html`}>
      {cwe}
    </ExternalLink>
  );
}
