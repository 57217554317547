import find from 'lodash/find';
import flattenDeep from 'lodash/flattenDeep';
import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  StyledInput,
  widgetBaseWidth,
} from '@src/blocks/AssetCollectionConfigurationPane/blocks/Styles';
import { ErrorMessage } from '@src/components/ErrorMessage';
import ReactSelectWrapper from '@src/components/ReactSelect/ReactSelectWrapper';
import apiService from '@src/services/apiService';
import { FontBody } from '@src/style/common';

const StyledForm = styled.div`
  ${FontBody};
`;

const StyledSection = styled.div`
  margin-top: ${props => (props.first ? 0 : '30px')};
`;

const StyledSectionTitle = styled.div`
  margin-bottom: 3rem;
`;

const StyledSelect = styled(ReactSelectWrapper)`
  width: ${widgetBaseWidth}px;
  border: 0.25rem solid var(--color-blue-gray-35);
  margin-bottom: 3rem;
  border-radius: 13px;
  ::placeholder {
    color: var(--color-blue-gray-45);
  }
`;

const Section = ({ children, title }) => (
  <StyledSection>
    <StyledSectionTitle>{title}</StyledSectionTitle>
    {children}
  </StyledSection>
);

const areArrayValuesValid = array => !(isEmpty(array) || array.some(value => value.trim() === ''));

const fetchFrameworkTypes = async () => {
  const frameworks = (
    await apiService.get('/api/definitions/internalFrameworkDefinitions/frameworkTypes')
  ).data;
  return map(
    groupBy(frameworks, type => type.groupDisplayName),
    (groupedFrameworks, groupName) => ({
      label: groupName,
      options: map(groupedFrameworks, _ => ({
        label: _.displayName,
        value: _.key,
      })),
    })
  );
};

const fetchDependencies = async () =>
  convertDependencyOptions(
    (await apiService.get('/api/organization/dependencies')).data.dependencies
  );

const convertDependencyOptions = options =>
  (options ?? []).map(dependency => ({ value: dependency, label: dependency }));

const convertFrameworkTypeOption = (framework, optionGroups) =>
  find(flattenDeep(map(optionGroups, _ => _.options)), option => option?.value === framework) ?? '';

export const InternalFrameworkDefinitionForm = ({
  definition,
  setDefinition,
  onValidityChange,
  errorMessage,
}) => {
  useEffect(() => {
    let isValid = true;
    if (
      !definition.frameworkName &&
      !definition.frameworkType &&
      isEmpty(definition.dependencies)
    ) {
      isValid = false;
    } else if (!areArrayValuesValid(definition.dependencies)) {
      isValid = false;
    }
    onValidityChange(isValid);
  }, [definition, onValidityChange]);

  const [frameworkTypeOptions, setFrameworkTypeOptions] = useState([]);
  const [dependencyOptions, setDependencyOptions] = useState([]);

  useEffect(() => {
    (async () => {
      const [frameworks, dependencies] = await Promise.all([
        fetchFrameworkTypes(),
        fetchDependencies(),
      ]);
      setFrameworkTypeOptions(frameworks);
      setDependencyOptions(dependencies);
    })();
  }, []);

  const setFrameworkName = value =>
    setDefinition({
      ...definition,
      frameworkName: value,
    });

  const setDependencies = selection =>
    setDefinition({
      ...definition,
      dependencies: selection?.map(dependency => dependency.value),
    });

  const setFrameworkType = selection =>
    setDefinition({
      ...definition,
      frameworkType: selection.value,
    });

  return (
    <StyledForm>
      <ErrorMessage message={errorMessage} />
      <Section first title="Framework name">
        <StyledInput
          value={definition.frameworkName}
          onChange={event => setFrameworkName(event.currentTarget.value)}
          placeholder="Choose framework name"
          invalid={false}
        />
      </Section>
      <Section title="Dependency names">
        <StyledSelect
          isMulti
          value={convertDependencyOptions(definition.dependencies)}
          options={dependencyOptions}
          onChange={newSelection => setDependencies(newSelection)}
          placeholder="Choose dependencies"
        />
      </Section>
      <Section title="Framework type">
        <StyledSelect
          isMulti={false}
          value={convertFrameworkTypeOption(definition.frameworkType, frameworkTypeOptions)}
          options={frameworkTypeOptions}
          onChange={setFrameworkType}
          placeholder="Choose framework type"
        />
      </Section>
    </StyledForm>
  );
};
