import { observer } from 'mobx-react';
import { SvgIcon } from '@src-v2/components/icons';
import { Popover } from '@src-v2/components/tooltips/popover';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink } from '@src-v2/components/typography';
import { nodeTypeToTitle } from '@src-v2/containers/spacetime-graph/spacetime-graph-legend';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import { getProviderDisplayName } from '@src-v2/data/providers';
import { useInject, useSuspense } from '@src-v2/hooks';

const popperOptions = { strategy: 'fixed' };

export const SpaceTimeGraphNodePopover = observer(({ node, children }) => {
  const { connectors } = useInject();

  const repository = node?.data?.RepositoryKey
    ? useSuspense(connectors.getRepository, {
        key: node?.data?.RepositoryKey,
      })
    : null;

  const typeDisplayName = nodeTypeToTitle[node.type] ?? node.type;
  const label =
    node.type === 'Group'
      ? node.name
      : node.name
        ? `${typeDisplayName}: ${node.name}`
        : 'undefined';

  const content = (
    <>
      <Popover.Head>{label}</Popover.Head>
      <Popover.Content>
        {repository && node?.data?.RelativeFilePath && (
          <Tooltip
            content={`View ${typeDisplayName} in ${getProviderDisplayName(
              repository.server.provider
            )}`}>
            <ExternalLink
              href={generateCodeReferenceUrl(repository, {
                relativeFilePath: node.data.RelativeFilePath,
                lineNumber: node.data.Line,
              })}>
              <SvgIcon name="Code" />
            </ExternalLink>
          </Tooltip>
        )}
      </Popover.Content>
    </>
  );
  return (
    <Popover content={content} popperOptions={popperOptions}>
      {children}
    </Popover>
  );
});
