import { AnalyticsDataField, AnalyticsLayer } from '@src-v2/components/analytics-layer';
import { Gutters } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { PrScansTable } from '@src-v2/containers/pr-logs/pr-scans-table';

export default () => {
  return (
    <Page title="PR scans">
      <AnalyticsLayer analyticsData={{ [AnalyticsDataField.Context]: 'PR scans' }}>
        <Gutters>
          <PrScansTable />
        </Gutters>
      </AnalyticsLayer>
    </Page>
  );
};
