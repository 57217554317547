import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import { Link } from './Link';

export default ({ repository, relativeFilePath, lineNumber, children, className }) => (
  <Link
    className={className}
    url={generateCodeReferenceUrl(repository, { relativeFilePath, lineNumber })}
    tip={relativeFilePath}
    openInNewTab>
    {children}
  </Link>
);
