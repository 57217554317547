import { Heading, Paragraph, Strong } from '@src-v2/components/typography';
import { CommitCodeReference } from '@src-v2/containers/commit/common-componnets';
import { thenSubTypeToVerb } from '@src-v2/containers/commit/material-changes-utils';

export const EntitySatisfiesExpressionMaterialChange = ({
  materialChange,
  commitSha,
  repository,
  thenSubType,
}) => (
  <>
    <Heading>
      <Paragraph>
        {materialChange.pullRequestEntityName || 'Entity'}{' '}
        {materialChange.codeReference?.relativeFilePath && (
          <>
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={materialChange.codeReference.relativeFilePath}>
              {materialChange.codeReference.apiName ??
                materialChange.codeReference.className ??
                materialChange.codeReference.name ??
                `in ${materialChange.codeReference.relativeFilePath}`}
            </CommitCodeReference>
          </>
        )}
        meeting the criteria for policy '{materialChange.governanceRule.name}'
      </Paragraph>
      <Paragraph>was</Paragraph>
      <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>
    </Heading>
  </>
);
