import { useLayoutEffect, useRef } from 'react';
import { useSafeState } from '@src-v2/hooks/react-helpers/use-safe-state';

export function useInfiniteScroll(
  ref,
  handler,
  { threshold = 250, capture = false, passive = true, disabled = false } = {}
) {
  const inFlight = useRef(false);
  const [state, setState] = useSafeState({ loading: false, error: null });
  const target = ref?.current ?? ref;

  useLayoutEffect(() => {
    if (!disabled) {
      target?.addEventListener('scroll', listener, { capture, passive });
      return () => target?.removeEventListener('scroll', listener, { capture });
    }

    function listener({ target }) {
      if (target?.offsetParent && !inFlight.current) {
        const offset = target.scrollHeight - target.scrollTop - target.offsetHeight;
        if (offset < threshold) {
          setState({ loading: (inFlight.current = true), error: null });
          Promise.resolve(handler()).then(
            () => setState({ loading: (inFlight.current = false), error: null }),
            error => setState({ loading: (inFlight.current = false), error })
          );
        }
      }
    }
  }, [target, handler, threshold, disabled]);

  return state;
}
