/**
 * Use native input setter to trigger native change event
 * @param inputElement
 * @param value
 */
export function setInputValue(inputElement, value) {
  nativeInputSetter(inputElement, value);
  dispatchInputEvent(inputElement);
}

/**
 * Use native input setter to trigger native change event
 * @param element
 * @param value
 */
export function nativeInputSetter(element, value) {
  return Object.getOwnPropertyDescriptor(HTMLInputElement.prototype, 'value').set.call(
    element,
    value
  );
}

/**
 * Dispatch native input event
 * @param element
 * @returns {boolean}
 */
export function dispatchInputEvent(element) {
  return element.dispatchEvent(new Event('input', { bubbles: true }));
}
