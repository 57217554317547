import { useEffect, useState } from 'react';

export function useImageStatus(imageUrl) {
  const [status, setStatus] = useState(imageUrl ? null : false);
  useEffect(() => {
    if (imageUrl) {
      setStatus(null);
      const image = new Image();
      const onLoad = () => setStatus(true);
      const onError = () => setStatus(false);
      image.addEventListener('load', onLoad);
      image.addEventListener('error', onError);
      image.src = imageUrl;
      return () => {
        image.removeEventListener('load', onLoad);
        image.removeEventListener('error', onError);
      };
    }
    setStatus(false);
  }, [imageUrl]);
  return status;
}
