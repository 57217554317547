import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

export function ScrollSync({ as: Component = 'div', ...props }) {
  const context = useContext(ScrollContext);
  const ref = useRef();

  if (!context) {
    throw Error('ScrollSync must reside inside ScrollSyncProvider');
  }

  useEffect(() => {
    const component = ref.current;
    context.addComponent(component);
    return () => context.removeComponent(component);
  });

  useEffect(() => {
    ref.current.scrollTop = context.scrollTop;
    ref.current.scrollLeft = context.scrollLeft;
  }, [context.scrollTop, context.scrollLeft]);

  return <Component ref={ref} {...props} />;
}

export function ScrollSyncContext({ children }) {
  const [scrollTop, setScrollTop] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleScroll = useCallback(
    event => {
      setScrollTop(event.target.scrollTop);
      setScrollLeft(event.target.scrollLeft);
    },
    [setScrollTop, setScrollLeft]
  );

  const addComponent = useCallback(
    ref => ref.addEventListener('scroll', handleScroll),
    [handleScroll]
  );

  const removeComponent = useCallback(
    ref => ref.removeEventListener('scroll', handleScroll),
    [handleScroll]
  );

  return (
    <ScrollContext.Provider
      value={{
        scrollTop,
        scrollLeft,
        addComponent,
        removeComponent,
      }}>
      {children}
    </ScrollContext.Provider>
  );
}

const ScrollContext = createContext(null);
