import styled from 'styled-components';
import { DateTime } from '@src-v2/components/time';
import { Paragraph, Strong } from '@src-v2/components/typography';
import { humanize } from '@src-v2/utils/string-utils';

export const RiskOverrideDetails = styled(({ data, ...props }) => (
  <div {...props}>
    <Paragraph>
      {data.riskLevel}{' '}
      {data.user && (
        <>
          by <Strong>{data.user}</Strong>{' '}
        </>
      )}
      {data.timestamp && (
        <>
          {' '}
          on <DateTime date={data.timestamp} format="MMM dd yyyy, pa" />
        </>
      )}
    </Paragraph>
    {data.ignoreType && <Paragraph> Marked as: {humanize(data.ignoreType)}</Paragraph>}
    {data.reason && <Paragraph> Reason: {data.reason}</Paragraph>}
  </div>
))`
  display: flex;
  flex-direction: column;
`;
