import isEmpty from 'lodash/isEmpty';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { Number } from '@src/components/Number';
import ConsumablesList from './ConsumablesList';

const Consumables = ({
  consumables,
  single,
  plural,
  underline,
  children,
  noLink,
  serverIconOnly,
}) =>
  !isEmpty(consumables) ? (
    <PopoverTip
      linkText={
        children || (
          <Number underline={underline} value={consumables.length} one={single} other={plural} />
        )
      }>
      <ConsumablesList
        serverIconOnly={serverIconOnly}
        consumables={consumables}
        consumablesNamePlural={plural}
        consumablesNameSingle={single}
        noLink={noLink}
      />
    </PopoverTip>
  ) : (
    <span>
      <Number value={consumables.length} one={single} other={plural} />
    </span>
  );

export default Consumables;
