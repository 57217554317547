import { animated, useSpring } from '@react-spring/web';
import { useEffect, useRef } from 'react';
import styled from 'styled-components';
import * as GraphIconsUrl from '@src-v2/assets/images/graph';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Heading } from '@src-v2/components/typography';
import { useToggle } from '@src-v2/hooks';
import { toRem } from '@src-v2/utils/style-utils';

export const nodeTypeToTitle = {
  Api: 'API',
  DataStore: 'Data Storage',
  Ui: 'UI',
  ApiGateway: 'API Gateway',
  Phi: 'PHI',
  Pii: 'PII',
  Pci: 'PCI',
};

export function Legend({ graphData, ...props }) {
  const contentRef = useRef();
  const [open, toggle] = useToggle();
  const [style, api] = useSpring(() => ({ width: '8rem', height: '8rem' }));

  useEffect(() => {
    const { scrollWidth, scrollHeight } = contentRef.current;
    api.start(
      open
        ? { width: toRem(scrollWidth), height: toRem(scrollHeight) }
        : { width: '8rem', height: '8rem' }
    );
  }, [open, contentRef]);

  return (
    <Container {...props}>
      <Tooltip content="Toggle legend">
        <SvgIcon name="Info" onClick={toggle} />
      </Tooltip>
      <Content ref={contentRef} style={style}>
        <Legend.Head>Application Graph Legend</Legend.Head>
        <Legend.Body>
          <Heading>Application Components Types</Heading>
          {graphData.nodeTypes.map(
            nodeType =>
              GraphIconsUrl[nodeType] && (
                <Legend.Item key={nodeType}>
                  <Legend.NodeSymbol>
                    <BaseIcon src={GraphIconsUrl[nodeType]} />
                  </Legend.NodeSymbol>
                  {nodeTypeToTitle[nodeType] ?? nodeType}
                </Legend.Item>
              )
          )}
          <Heading>Connection Types</Heading>
          {graphData.edgeTypes.map(edgeType => (
            <Legend.Item key={edgeType}>
              <Legend.EdgeSymbol>
                <line
                  x1="15%"
                  y1="50%"
                  x2="85%"
                  y2="50%"
                  stroke={edgeType === 'Uses' ? '#b6b6b6' : 'black'}
                  strokeWidth={2.5}
                />
              </Legend.EdgeSymbol>
              {edgeType}
            </Legend.Item>
          ))}
        </Legend.Body>
      </Content>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  top: 5rem;
  right: 10rem;
  z-index: 10;

  > ${BaseIcon} {
    position: relative;
    z-index: 10;
    width: 8rem;
    height: 8rem;
    padding: 0.5rem;
    color: var(--color-blue-gray-50);
    border-radius: 100vmax;
    border: 0.25rem solid var(--color-blue-65);
    background-color: var(--color-white);
    cursor: pointer;
  }
`;

const Content = styled(animated.div)`
  position: absolute;
  top: 0;
  right: 0;
  border-radius: 4rem;
  background-color: var(--color-white);
  border: 0.25rem solid var(--color-blue-gray-30);
  overflow: hidden;
`;

Legend.Head = styled.h3`
  display: inline-block;
  padding: 5rem 10rem 5rem 5rem;
  font-size: var(--font-size-m);
  font-weight: 700;
  line-height: 1;
  white-space: nowrap;
  border-radius: 4rem 4rem 0 0;
  border-bottom: 0.25rem solid var(--color-blue-gray-30);
  background-color: var(--color-blue-gray-10);
`;

Legend.Body = styled.div`
  padding: 5rem;
  font-size: var(--font-size-s);

  > ${Heading} {
    color: var(--color-blue-gray-50);
    font-size: var(--font-size-s);
    font-weight: 700;
    line-height: 5rem;
    white-space: nowrap;

    &:not(:first-child) {
      margin-top: 6rem;
    }
  }
`;

Legend.Item = styled.div`
  display: flex;
  margin-top: 4rem;
  align-items: center;
  gap: 5rem;
`;

Legend.NodeSymbol = styled.span`
  display: inline-flex;
  width: 10rem;
  height: 10rem;
  padding: 2rem;
  align-items: center;
  justify-content: center;
  border: 0.25rem solid var(--color-blue-gray-50);
  border-radius: 100vmax;
`;

Legend.EdgeSymbol = styled.svg`
  display: inline-flex;
  width: 10rem;
  height: 3rem;
  align-items: center;
  justify-content: center;
  border: 0.25rem solid var(--color-blue-gray-50);
  border-radius: 1rem;
`;
