import _ from 'lodash';
import styled from 'styled-components';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { primaryPaneLevel, secondaryPaneLevel } from '@src/blocks/Pane/model';
import { LegacyPaneStylingContainer } from '@src/blocks/RiskPosture/legacy-pane-styling-container';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import SensitiveDataApis from '@src/components/CodeAggregation/Data/SensitiveDataApis';
import { Highlights } from '@src/components/CodeAggregation/Insights/Highlights';
import {
  StyledDescription,
  StyledFilterButtons,
  StyledInsightsTitle,
  StyledTableContent,
} from '@src/components/CodeAggregation/Insights/InsightStyles';
import {
  getRepositoryForInventoryPropertyFromProfile,
  getRepositoryForInventoryPropertyFromProfileByKey,
} from '@src/components/CodeAggregation/InventoryUtils';
import { ElementPane } from '@src/components/ElementPane';
import {
  SensitiveDataExitPointKinds,
  SensitiveDataExitPoints,
  SensitiveDataExitPointsHeader,
} from '@src/components/ExitPoints/sensitiveDataExitPoints';
import { Number } from '@src/components/Number';
import { VerticalStack } from '@src/components/VerticalStack';
import { dispatch } from '@src/store';
import { StyledSortedList, getStyledSortedList } from './Common';

const getApiNumber = (apisCount, keyWord) => (
  <span>
    {`${keyWord} in`}&nbsp;
    <Number one="API" other="APIs" value={apisCount} />
  </span>
);

const getHighlights = sensitiveData => {
  const exposingMethodsCount = sensitiveData.exposingMethods.length;
  const involvingMethodsCount = sensitiveData.involvingMethods.length;
  const writtenToLogs = sensitiveData.fieldExitPoints.some(
    exitPoint => exitPoint.kind === SensitiveDataExitPointKinds.LOG.enumValue
  );

  const highlights = [];

  if (exposingMethodsCount > 0) {
    highlights.push(getApiNumber(exposingMethodsCount, 'Exposed'));
  }

  if (involvingMethodsCount > 0) {
    highlights.push(getApiNumber(involvingMethodsCount, 'Involved'));
  }

  if (writtenToLogs) {
    highlights.push(<span>Written to logs</span>);
  }

  return highlights;
};

const getExitPoints = (sensitiveData, repository) => {
  const exitPoints = _.sortBy(sensitiveData.fieldExitPoints, ['kind']);
  const exitPointsByMember = _.groupBy(exitPoints, 'member');
  return (
    <>
      <SensitiveDataExitPointsHeader exitPoints={exitPoints} />
      <SensitiveDataExitPoints exitPointsByMember={exitPointsByMember} repository={repository} />
    </>
  );
};

const InsightsBody = ({ sensitiveData, profile, profileType, highlights }) => {
  const repository = getRepositoryForInventoryPropertyFromProfile(sensitiveData, profile);
  const { involvingApiReferences, exposingApiReferences } = sensitiveData;

  const exposedFilterLabel = 'Exposed in APIs';
  const involvedFilterLabel = 'Involved in APIs';
  const getApisByFilterLabel = labels =>
    _.includes(labels, involvedFilterLabel) ? involvingApiReferences : exposingApiReferences;

  return (
    <>
      <Highlights highlights={highlights} />
      <StyledInsightsTitle>Detected As</StyledInsightsTitle>
      {getStyledSortedList(sensitiveData.sensitiveDataDefinedTypes)}
      {!_.isEmpty(sensitiveData.fieldExitPoints) && (
        <StyledInsightsTitle>Exit Points</StyledInsightsTitle>
      )}
      {!_.isEmpty(sensitiveData.fieldExitPoints) && getExitPoints(sensitiveData, repository)}
      {(!_.isEmpty(involvingApiReferences) || !_.isEmpty(exposingApiReferences)) && (
        <>
          <VerticalStack>
            <StyledInsightsTitle>Related APIs</StyledInsightsTitle>
            <StyledDescription>
              This sensitive data is either directly exposed or indirectly involved in the API call
              flow of the following APIs:
            </StyledDescription>
          </VerticalStack>
          <StyledTableContent>
            <StyledFilterButtons
              filterToCountMapping={[
                { name: exposedFilterLabel, count: exposingApiReferences?.length },
                { name: involvedFilterLabel, count: involvingApiReferences?.length },
              ]}
              alignLeft>
              {selectedGroup => (
                <SensitiveDataApis
                  apis={getApisByFilterLabel(selectedGroup)}
                  profileType={profileType}
                  profile={profile}
                />
              )}
            </StyledFilterButtons>
          </StyledTableContent>
        </>
      )}
    </>
  );
};

const SensitiveDataPane = ({
  profile,
  trigger,
  sensitiveData,
  repository,
  ruleTriggers,
  profileType,
  messageContent,
}) => {
  const highlights = getHighlights(sensitiveData);
  repository ??= getRepositoryForInventoryPropertyFromProfile(sensitiveData, profile);

  return (
    <ElementPane
      element={sensitiveData}
      repository={repository}
      title={
        <InsightsPaneTitle
          trigger={trigger}
          element={sensitiveData}
          title={`${sensitiveData.name} ${sensitiveData.sensitiveDataSourceDescription}`}
          codeReference={sensitiveData.codeReference}
          repository={repository}
          profile={profile}
          profileType={profileType}
          ruleTriggers={ruleTriggers}
          messageContent={messageContent}
        />
      }
      profileBody={
        <InsightsBody
          sensitiveData={sensitiveData}
          profile={profile}
          profileType={profileType}
          highlights={highlights}
        />
      }
    />
  );
};

export function LegacySensitiveDataPane({ sensitiveData, risk }) {
  return (
    <SensitiveDataStylingContainer>
      <InsightsBody
        sensitiveData={sensitiveData}
        profile={risk.profile}
        profileType={risk.profile.profileType}
        highlights={getHighlights(sensitiveData)}
      />
    </SensitiveDataStylingContainer>
  );
}

const SensitiveDataStylingContainer = styled(LegacyPaneStylingContainer)`
  & > ${StyledInsightsTitle}:not(:first-child) {
    margin-top: 4rem;
  }

  & > ${StyledSortedList}:not(:last-child) {
    margin-bottom: 4rem;
  }
`;

export const openSensitiveDataPaneWithRiskActions = ({
  ruleKey,
  profile,
  trigger,
  profileType,
  onClose,
  relevantPath,
  messageContent,
  externalRiskTriggerPane,
  level = secondaryPaneLevel,
}) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );

  dispatch.pane.openPane({
    level,
    onClose,
    relevantPath,
    id: trigger.key,
    content: (
      <RiskRuleTrigger
        profile={profile}
        ruleKey={ruleKey}
        trigger={trigger}
        externalRiskTriggerPane={externalRiskTriggerPane}
        getPane={({ element, ruleTriggers }) => (
          <SensitiveDataPane
            trigger={trigger}
            profile={profile}
            sensitiveData={element}
            repository={repository}
            ruleTriggers={ruleTriggers}
            profileType={profileType}
            messageContent={messageContent}
          />
        )}
      />
    ),
  });
};

export const openSensitiveDataPane = (type, profile, sensitiveData, ruleTriggers, profileType) =>
  dispatch.pane.openPane({
    id: sensitiveData.triggerKey,
    level: primaryPaneLevel,
    content: (
      <SensitiveDataPane
        type={type}
        profile={profile}
        sensitiveData={sensitiveData}
        ruleTriggers={ruleTriggers}
        profileType={profileType}
      />
    ),
  });
