import { useCveEntityContext } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-context-provider';
import { CveEntityDescriptionCard } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-description-card';
import { CveEntityExploitHintCard } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-exploit-hint-card';
import { CveEntityLikelihoodCard } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-likelihood-card';
import { ControlledCardProps } from '@src/src-v2/components/cards/controlled-card';
import { CveEntityTriageCard } from './cve-entity-triage-card';

export const CveEntityPaneContent = (props: ControlledCardProps) => {
  const { cveEntity } = useCveEntityContext();

  return (
    <>
      {cveEntity.triage?.summary && <CveEntityDescriptionCard {...props} />}
      <CveEntityLikelihoodCard {...props} />
      {cveEntity.triage?.description && <CveEntityTriageCard {...props} />}
      {cveEntity.triage?.exploitExample && <CveEntityExploitHintCard {...props} />}
    </>
  );
};
