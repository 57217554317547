import isEmpty from 'lodash/isEmpty';
import { useEffect } from 'react';
import { PageLoading } from '@src/components/PageLoading';
import { getDeveloperProfilesByIdentities } from '@src/services/identitiesService';

const RiskRuleTrigger = ({
  element,
  ruleTriggers,
  developerProfileByIdentityKey,
  isLoading,
  fetchData,
  clearData,
  externalRiskTriggerPane,
  developerIdentityKeys,
  trigger,
  profile,
  ruleKey,
  getPane,
}) => {
  useEffect(() => {
    fetchData({ trigger, profile, ruleKey, developerIdentityKeys });
    return clearData;
  }, [fetchData, trigger, profile, ruleKey, developerIdentityKeys]);

  if (!ruleTriggers && !externalRiskTriggerPane) {
    ruleTriggers = {
      triggersByKey: { [trigger.triggerKey]: trigger },
      ruleRiskLevel: trigger.riskLevel,
      rule: {
        key: trigger.ruleKey,
        name: trigger.ruleName,
        risk: trigger.riskLevel,
      },
    };
  }

  if (isEmpty(ruleTriggers) || !element) {
    return <PageLoading />;
  }

  element.riskLevel = trigger.riskLevel = trigger.riskLevel ?? ruleTriggers.riskLevel;

  const developerProfiles = getDeveloperProfilesByIdentities(
    developerIdentityKeys,
    developerProfileByIdentityKey
  );
  return getPane({ element, ruleTriggers, developerProfiles, isLoading });
};

export default RiskRuleTrigger;
