import _ from 'lodash';
import { useEffect, useMemo } from 'react';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { Link } from '@src-v2/components/typography';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import Projects from '@src/components/Projects';
import { VerticalStack } from '@src/components/VerticalStack';

const RiskyIssueDefinition = ({
  fetchData,
  definition,
  projectProfilesByKey,
  assetCollectionProfilesByKey,
}) => {
  useEffect(() => {
    fetchData({ definition });
  }, [fetchData, definition]);

  const projects = useMemo(
    () => definition.projectKeys?.map(key => projectProfilesByKey[key]?.project) ?? [],
    [definition, projectProfilesByKey]
  );

  if (_.some(projects, project => _.isNil(project))) {
    return <span>Loading...</span>;
  }

  return (
    <HorizontalStack withSeparator key={definition.key}>
      {definition.allProjects ? (
        <span>All projects</span>
      ) : (
        !_.isEmpty(projects) && <Projects projects={projects} />
      )}
      {definition.allAssetCollections ? (
        <span>All applications</span>
      ) : (
        !_.isEmpty(definition.assetCollectionKeys) && (
          <PopoverTip
            linkText={
              <Number
                value={definition.assetCollectionKeys.length}
                one="application"
                other="applications"
              />
            }>
            <VerticalStack>
              {definition.assetCollectionKeys.map(assetCollectionKey => (
                <Link key={assetCollectionKey} to={`/profiles/applications/${assetCollectionKey}`}>
                  {assetCollectionProfilesByKey[assetCollectionKey]?.name}
                </Link>
              ))}
            </VerticalStack>
          </PopoverTip>
        )
      )}
      <Number
        value={
          (definition.labels?.length ?? 0) +
          (definition.titles?.length ?? 0) +
          (definition.types?.length ?? 0) +
          (definition.components?.length ?? 0)
        }
        one="filter"
        other="filters"
      />
    </HorizontalStack>
  );
};

export default RiskyIssueDefinition;
