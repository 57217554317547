import { TimelineEvent } from '@src/blocks/TimelineInfiniteScroll/components/TimelineEvent';

export const TimelineBranchMinimumCodeReviewers = ({
  branchMinimumCRRuleTimelineEvent,
  previousTimelineEvent,
  repositoryProfilesByKey,
  ...props
}) => {
  let title = 'Branch protection minimum code reviewers modified';
  if (
    branchMinimumCRRuleTimelineEvent.materialChangesLabels !== null &&
    branchMinimumCRRuleTimelineEvent.materialChangesLabels.length > 0
  ) {
    [title] = branchMinimumCRRuleTimelineEvent.materialChangesLabels;
  }

  const { repository } =
    repositoryProfilesByKey[branchMinimumCRRuleTimelineEvent.repositoryKeys[0]];

  return (
    <TimelineEvent
      currentTimelineEvent={branchMinimumCRRuleTimelineEvent}
      previousTimelineEvent={previousTimelineEvent}
      {...props}
      titleContent={title}
      body={
        <div>
          <div>
            Required code reviewers for branch '{branchMinimumCRRuleTimelineEvent.branchName}' in
            repository '{repository.name}' (server URL: '{repository.serverUrl}
            ') was modified and is now set to{' '}
            {branchMinimumCRRuleTimelineEvent.reviewersCount === 0
              ? 'none'
              : branchMinimumCRRuleTimelineEvent.reviewersCount}
          </div>
        </div>
      }
    />
  );
};
