export function processCodeProfileRelatedEntity(inventory) {
  if (inventory) {
    inventory.key = inventory.entityKey;
  }
}

export function isInfrastructureTemplateLookingString(str) {
  const isTextDigitsOnly = s => /^\d+$/.test(s);
  return (
    str
      .split(/[\s,-:]+/)
      .filter(s => s && !isTextDigitsOnly(s))
      .some(s => s === s.toUpperCase()) ||
    str.includes('$') ||
    (str.startsWith('_') && str.endsWith('_'))
  );
}
