import filtersService from '@src/services/filtersService';

// export function getGqlOperationFilterOptions(gqlOperations) {}

export const filterGqlTypes = (allItems, searchTerm) =>
  filtersService.filterItemsBySearchTerm(allItems, searchTerm, gqlType => [
    gqlType.name,
    ...gqlType.fields.map(field => field.name),
  ]);

export const filterGqlOperations = (allItems, searchTerm) =>
  filtersService.filterItemsBySearchTerm(allItems, searchTerm, gqlOperation => [gqlOperation.name]);
