import styled from 'styled-components';
import { Card } from '@src-v2/components/cards';
import { Heading1 } from '@src-v2/components/typography';
import { getRiskColor, riskOrder } from '@src-v2/data/risk-data';

export function RiskCard({ title, risk, children, ...props }) {
  return (
    <Container {...props}>
      <Square>
        <Heading1>
          {title} <br />
          at risk
        </Heading1>
        <Progress risk={risk}>
          {riskOrder
            .map(riskLevel => (
              <ProgressBar
                key={riskLevel}
                riskLevel={riskLevel}
                percentage={risk?.[riskLevel] / risk?.total}
              />
            ))
            .reverse()}
        </Progress>
      </Square>
      {children}
    </Container>
  );
}

const Container = styled(Card)`
  display: flex;
  padding: 3rem;
  gap: 5rem;
`;

const Square = styled.div`
  position: relative;
  display: inline-flex;
  width: 43rem;
  padding: 3rem 6rem 12rem;
  font-size: var(--font-size-xxl);
  font-weight: 800;
  line-height: 1.25;
  align-items: center;
  background-color: var(--color-blue-gray-20);
  border-radius: 2rem;
  flex-shrink: 0;
`;

const Progress = styled.div`
  position: absolute;
  inset: auto 6rem 7rem;
  display: flex;
  height: 3rem;
  border-radius: 100vmax;
  box-shadow: 0 0.5rem 5rem 0 var(--color-blue-gray-25);
  background-color: var(--color-white);
  overflow: hidden;
`;

const ProgressBar = styled.div`
  width: ${props => `${props.percentage * 100}%`};
  height: 100%;
  background-color: ${getRiskColor};
`;
