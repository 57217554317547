import _ from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink } from '@src-v2/components/typography';
import { ContributorTooltip } from '@src-v2/containers/contributors/contributor-tooltip';
import { StyledRiskIcon } from '@src/blocks/RiskPosture/blocks/styles';
import { Commit, PerforceCommit } from '@src/components/Commit';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { BeforeHeaderPlacement, IssuesLink } from '@src/components/IssuesLink';
import { Number } from '@src/components/Number';
import { RepositoryPopover } from '@src/components/RepositoryPopover';
import { autoIgnoreRiskLevel } from '@src/components/RiskIcon';
import ServerIcon from '@src/components/ServerIcon';
import { TitledList } from '@src/components/TitledList';
import { demoService } from '@src/services/demoService';
import { Ellipsis } from '@src/style/common';
import { getCommitUrl } from '@src/utils/commitUtils';
import { TimelineEvent } from './TimelineEvent';

const StyledDeveloper = styled(ContributorTooltip)`
  flex-grow: 0;
  ${Ellipsis};
`;

const StyledRepositoryPopover = styled(RepositoryPopover)`
  ${Ellipsis};
`;

const StyledMaterialChange = styled(HorizontalStack)`
  color: var(--default-text-color);
  padding-bottom: 3rem;
`;

const StyledIgnoredMaterialChange = styled(StyledMaterialChange)`
  opacity: 0.5;
`;

const StyledPullRequest = styled(HorizontalStack)`
  color: var(--default-text-color);
  padding-bottom: 3rem;
`;

const MaterialChange = ({ isIgnored, children }) =>
  isIgnored ? (
    <StyledIgnoredMaterialChange>
      <div>{children}</div>
      <StyledRiskIcon riskLevel={autoIgnoreRiskLevel} />
    </StyledIgnoredMaterialChange>
  ) : (
    <StyledMaterialChange>{children}</StyledMaterialChange>
  );

const demoBlocksStartIndexes = [4, 10];
const indexToDemoCommit = {};

demoBlocksStartIndexes.forEach((blockStartIndex, blockIndex) => {
  const blockLength = demoService.blockLength(blockIndex);
  for (let i = 0; i < blockLength; ++i) {
    indexToDemoCommit[blockStartIndex + i] = {
      block: blockIndex,
      commit: i,
      isStart: i === 0,
      isEnd: i === blockLength - 1,
    };
  }
});

export const TimelineCommit = ({
  isDemo,
  isLast,
  isFirst,
  index,
  commitTimelineEvent,
  previousTimelineEvent,
  hideRepository,
  hideDeveloper,
  repositoryProfilesByKey,
  developerProfileByIdentityKey,
  materialChangeFilters,
  subEntityKey,
}) => {
  const repositoryProfiles = useMemo(
    () =>
      commitTimelineEvent.repositoryKeys
        .map(repositoryKey => repositoryProfilesByKey[repositoryKey])
        .filter(profile => profile),
    [commitTimelineEvent.repositoryKeys]
  );
  hideRepository = hideRepository || _.isEmpty(repositoryProfiles);

  const developerProfile = developerProfileByIdentityKey[commitTimelineEvent.authorIdentityKey];
  const committerProfile = developerProfileByIdentityKey[commitTimelineEvent.committerIdentityKey];
  hideDeveloper = hideDeveloper || !developerProfile;

  let { materialChangesLabelsToCount } = commitTimelineEvent;

  if (isDemo && indexToDemoCommit[index]) {
    materialChangesLabelsToCount = _.cloneDeep(materialChangesLabelsToCount);
    demoService
      .labelsForCommit(indexToDemoCommit[index].block, indexToDemoCommit[index].commit)
      .forEach(label => {
        if (label in materialChangesLabelsToCount) {
          materialChangesLabelsToCount[label] += 1;
        } else {
          materialChangesLabelsToCount[label] = 1;
        }
      });
  }

  const materialChangesLabels = useMemo(
    () =>
      _.zip(
        Object.entries(materialChangesLabelsToCount),
        commitTimelineEvent.materialChangeLabelsToIsIgnore
      ).map(([[label, count], isIgnored]) => [label, count, isIgnored]),
    [materialChangesLabelsToCount, commitTimelineEvent]
  );

  const commitLabels = useMemo(
    () =>
      _.isEmpty(materialChangeFilters)
        ? materialChangesLabels
        : materialChangesLabels.filter(([label]) => materialChangeFilters.includes(label)),
    [materialChangeFilters, materialChangesLabels]
  );

  const commitMaterialChangesCount = useMemo(() => _.sumBy(commitLabels, '[1]'), [commitLabels]);

  const commitLink = useMemo(
    () =>
      getCommitUrl(
        commitTimelineEvent.repositoryKeys[0],
        commitTimelineEvent.commitSha,
        isDemo && indexToDemoCommit[index]?.block,
        isDemo && indexToDemoCommit[index]?.commit,
        subEntityKey
      ),
    [commitTimelineEvent, isDemo, indexToDemoCommit, subEntityKey]
  );

  const materialChanges = useMemo(
    () =>
      _.sortBy(commitLabels, '[2]', '[0]').map(([label, count, isIgnored]) => (
        <MaterialChange key={label} isIgnored={isIgnored}>
          {count === 1 ? label : `${label} (${count})`}
        </MaterialChange>
      )),
    [commitLabels]
  );

  const commitPullRequest = commitTimelineEvent.pullRequest;
  const commitIssues = commitTimelineEvent.issues;

  const timelineEventTitle = useMemo(
    () => (
      <>
        {repositoryProfiles?.length &&
        repositoryProfiles[0].repository.server.provider === 'Perforce' ? (
          <>
            Change
            <PerforceCommit
              message={commitTimelineEvent.message}
              authorProfile={developerProfile}
              committerProfile={committerProfile}
              repositoryProfiles={hideRepository ? [] : repositoryProfiles}
            />{' '}
          </>
        ) : (
          <>
            {' '}
            Commit
            <Commit
              commitSha={commitTimelineEvent.shortSha}
              repository={repositoryProfiles?.length && repositoryProfiles[0].repository}
              commitMessage={commitTimelineEvent.message}
              authorProfile={developerProfile}
              committerProfile={committerProfile}
              repositoryProfiles={hideRepository ? [] : repositoryProfiles}
            />
          </>
        )}
        {!hideDeveloper && (
          <>
            by
            <StyledDeveloper developerProfile={developerProfile} underline hideImage link={false} />
          </>
        )}
        {!hideRepository && (
          <>
            in
            {repositoryProfiles.length > 1 ? (
              <PopoverTip
                linkText={
                  <Number value={repositoryProfiles.length} one="repository" other="repositories" />
                }
                title="Repositories">
                <TitledList
                  title="This change was seen on more than one repository:"
                  list={_.map(repositoryProfiles, repositoryProfile => (
                    <ExternalLink href={`/profile/repository/${repositoryProfile.repository.key}`}>
                      {repositoryProfile.repository.name}
                    </ExternalLink>
                  ))}
                />
              </PopoverTip>
            ) : (
              <StyledRepositoryPopover repositoryProfile={repositoryProfiles[0]} />
            )}
          </>
        )}
        introduced&nbsp;
        {commitMaterialChangesCount > 1
          ? `${commitMaterialChangesCount} material changes`
          : 'a material change'}
      </>
    ),
    [
      commitMaterialChangesCount,
      commitTimelineEvent,
      committerProfile,
      developerProfile,
      hideDeveloper,
      hideRepository,
      repositoryProfiles,
    ]
  );

  return (
    <TimelineEvent
      currentTimelineEvent={commitTimelineEvent}
      previousTimelineEvent={previousTimelineEvent}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      titleContent={timelineEventTitle}
      body={
        <>
          {commitIssues && (
            <IssuesLink issues={commitIssues} providerIconPlacement={BeforeHeaderPlacement} />
          )}
          {commitPullRequest && (
            <StyledPullRequest spacing={0.2}>
              {repositoryProfiles.length > 0 && (
                <ServerIcon size="small" provider={repositoryProfiles[0].provider} />
              )}
              <span>
                Pull Request:{' '}
                <Tooltip content={commitPullRequest.title}>
                  <ExternalLink href={commitPullRequest.url}>#{commitPullRequest.id}</ExternalLink>
                </Tooltip>
              </span>
            </StyledPullRequest>
          )}
          {materialChanges}
        </>
      }
      exploreUrl={commitLink}
      testMarker="timeline-commit"
    />
  );
};
