import _ from 'lodash';
import { VerticalStack } from '@src/components/VerticalStack';

export const StatisticsBreakdown = ({
  name,
  totalKey,
  profileStatistics,
  statisticsKeysAndNames,
}) => {
  const breakdown = _.filter(statisticsKeysAndNames, statistic => statistic.display !== false).map(
    statistic => `${profileStatistics[statistic.key] ?? 0} ${statistic.name}`
  );

  return (profileStatistics[totalKey] ?? 0) === 0 ? (
    <span>{`No ${name} found`}</span>
  ) : (
    breakdown.length > 0 && (
      <VerticalStack spacing="3rem">
        {breakdown.map(type => (
          <span key={type}>{type}</span>
        ))}
      </VerticalStack>
    )
  );
};
