import { useCallback } from 'react';
import { useSafeState } from '@src-v2/hooks/react-helpers/use-safe-state';

export function useLoading(asyncHandler, dependencies = []) {
  const [{ loading, error }, setState] = useSafeState({
    loading: false,
    error: null,
  });
  return [
    useCallback(
      (...args) => {
        setState({ loading: true, error: null });
        const result = Promise.resolve(asyncHandler(...args));
        result.then(
          () => setState({ loading: false, error: null }),
          error => setState({ loading: false, error })
        );
        return result;
      },
      [setState, ...dependencies]
    ),
    loading,
    error,
  ];
}
