import styled from 'styled-components';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';
import { LegacyPaneStylingContainer } from '@src/blocks/RiskPosture/legacy-pane-styling-container';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import { Highlights } from '@src/components/CodeAggregation/Insights/Highlights';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { ElementPane } from '@src/components/ElementPane';
import { dispatch } from '@src/store';

export const openFrameworkPane = ({
  ruleKey,
  trigger,
  profile,
  profileType,
  onClose,
  relevantPath,
  messageContent,
  level = secondaryPaneLevel,
}) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );

  dispatch.pane.openPane({
    level,
    onClose,
    relevantPath,
    id: trigger.key,
    content: (
      <RiskRuleTrigger
        profile={profile}
        ruleKey={ruleKey}
        trigger={trigger}
        getPane={({ element, ruleTriggers }) => (
          <ElementPane
            repository={repository}
            element={element}
            title={
              <InsightsPaneTitle
                trigger={trigger}
                element={element}
                title={`Usage of ${trigger.framework ?? messageContent}`}
                profile={profile}
                profileType={profileType}
                repository={repository}
                ruleTriggers={ruleTriggers}
                messageContent={messageContent}
              />
            }
            profileBody={
              <Highlights
                highlights={[
                  <Highlight>Type: {trigger.elementType}</Highlight>,
                  Boolean(element.similarFrameworks?.length) && (
                    <Highlight>
                      {element.similarFrameworks.length} similar frameworks in use in this{' '}
                      {profile.profileType === 'ApplicationProfile' ? 'application' : 'repository'}:{' '}
                      {element.similarFrameworks.join(', ')}
                    </Highlight>
                  ),
                ].filter(Boolean)}
              />
            }
          />
        )}
      />
    ),
  });
};

export function LegacyFrameworkPane({ risk, element }) {
  return (
    <LegacyPaneStylingContainer>
      <Highlights
        highlights={[
          <Highlight>Type: {risk.elementType}</Highlight>,
          Boolean(element.similarFrameworks?.length) && (
            <Highlight>
              {element.similarFrameworks.length} similar frameworks in use in this{' '}
              {risk.profile.profileType === 'ApplicationProfile' ? 'application' : 'repository'}:{' '}
              {element.similarFrameworks.join(', ')}
            </Highlight>
          ),
        ].filter(Boolean)}
      />
    </LegacyPaneStylingContainer>
  );
}

const Highlight = styled.span``;
