import styled from 'styled-components';
import { BaseIcon, ConditionalProviderIcon, SvgIcon } from '@src-v2/components/icons';
import { Popover } from '@src-v2/components/tooltips/popover';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink } from '@src-v2/components/typography';
import { getProviderDisplayName } from '@src-v2/data/providers';
import { Ellipsis, EllipsisDiv } from '@src/style/common';
import { HorizontalStack } from './HorizontalStack';
import { Link } from './Link';

export const ConsumableLink = ({
  consumable,
  className,
  displayName,
  children,
  noLink,
  floating,
  writeNotMonitored,
  serverIconOnly,
  small,
  isRepository,
}) => {
  const includeLink = !noLink && consumable.isMonitored;
  const url = includeLink && consumableTypeToRoute(consumable);

  return floating ? (
    <Tooltip content={`View "${displayName ?? consumable.name}" profile`}>
      <StyledLink className={className} url={url} flex>
        {children}
      </StyledLink>
    </Tooltip>
  ) : (
    <StyledHorizontalStack small={small} className={className}>
      {serverIconOnly ? (
        <ConditionalProviderIcon name={consumable.server.provider} />
      ) : (
        <StyledPopover
          placement="bottom-start"
          content={
            <ExternalLink href={url}>
              <SvgIcon name="External" />
              {getTypeName(consumable)} in {getProviderDisplayName(consumable.server.provider)}
            </ExternalLink>
          }>
          <ConditionalProviderIcon name={consumable.server.provider} />
        </StyledPopover>
      )}
      {includeLink ? (
        <Popover content={children} disabled={!children}>
          <StyledLink url={url} flex>
            {displayName ?? consumable.name}
          </StyledLink>
        </Popover>
      ) : (
        <EllipsisDiv>
          {consumable.name} {isRepository ? `(${consumable.initialBranch})` : null}
          {!consumable.isMonitored && writeNotMonitored
            ? consumable.isIgnored
              ? ' (ignored)'
              : null
            : null}
        </EllipsisDiv>
      )}
    </StyledHorizontalStack>
  );
};

function getTypeName(consumable) {
  switch (consumable.type) {
    case 'ProviderRepository':
      return 'Repository';
    case 'ArtifactRepository':
      return 'Artifact repository';
    default:
      return consumable.type;
  }
}

const StyledLink = styled(Link)`
  ${Ellipsis};
`;

const StyledHorizontalStack = styled(HorizontalStack)`
  align-items: center;

  > ${BaseIcon} {
    width: ${props => (props.small ? '6rem' : '9rem')};
    height: ${props => (props.small ? '6rem' : '9rem')};
  }
`;

function consumableTypeToRoute(consumable) {
  if (consumable.type === 'Repository') {
    return `/profiles/repositories/${consumable.key}`;
  }
  if (consumable.type === 'ProviderRepository') {
    let repositoryKey = consumable.branches[consumable.defaultBranch];
    if (repositoryKey === undefined) {
      repositoryKey = consumable.branches[Object.keys(consumable.branches).sort()[0]];
    }
    return `/profiles/repositories/${repositoryKey}`;
  }
  console.warn(`Unsupported consumable type "${consumable.type}"`);
  return `/${consumable.type.toLowerCase()}/${consumable.key}`;
}

const StyledPopover = styled(Popover)`
  ${Popover.Content} {
    min-width: 0;

    ${ExternalLink} {
      display: flex;
      align-items: center;
      gap: 2rem;
    }
  }
`;
