import _ from 'lodash';
import { ReactNode } from 'react';
import styled from 'styled-components';
import { Placement } from 'tippy.js';
import { Popover } from '@src-v2/components/tooltips/popover';

/**
 * @deprecated use <Tooltip /> instead
 */
export const PopoverTip = ({
  title,
  children,
  linkText,
  placement = 'bottom-start',
  ...props
}: {
  title: string;
  children: ReactNode;
  linkText: string;
  placement?: Placement;
}) => {
  if (_.isEmpty(title) && _.isEmpty(children)) {
    return <Container {...props}>{linkText}</Container>;
  }

  return (
    <Popover
      delay={200}
      placement={placement}
      content={
        <>
          {title && <Popover.Head>{title}</Popover.Head>}
          {children}
        </>
      }>
      <Container {...props}>{linkText}</Container>
    </Popover>
  );
};

const Container = styled.span`
  display: inline-block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export default PopoverTip;
