import _ from 'lodash';
import { VerticalStack } from '@src/components/VerticalStack';

export const SecretsBreakdown = ({ profileStatistics }) =>
  (profileStatistics?.secretsCount ?? 0) === 0 ? (
    <span>No secrets found</span>
  ) : (
    <VerticalStack spacing="3rem">
      {_.map(profileStatistics.secretsCountByFileClassification, (count, fileType) => (
        <span key={`${fileType}.${count}`}>
          {fileType}: {count}
        </span>
      ))}
    </VerticalStack>
  );
