import { useCallback, useEffect, useState } from 'react';
import { useInject } from '@src-v2/hooks/use-inject';
import { Column } from '@src-v2/types/table';

interface TableModel<T> {
  columns: Column<T>[];
}

interface UseTableProps<T> {
  tableColumns: Column<T>[];
  hasReorderColumns?: boolean;
}

interface UseTableReturn<T> extends TableModel<T> {
  moveColumn: (dragIndex: number, hoverIndex: number) => void;
  hasReorderColumns: boolean;
}

export function useTable<T>({
  tableColumns,
  hasReorderColumns = true,
}: UseTableProps<T>): UseTableReturn<T> {
  const [tableModel, setTableModel] = useState({ columns: tableColumns });
  const { application } = useInject();

  useEffect(() => {
    const filteredTableColumns = tableColumns.filter(
      column => !column.betaFeature || application.isFeatureEnabled(column.betaFeature)
    );
    setTableModel({ ...tableModel, columns: filteredTableColumns });
  }, [tableColumns]);

  const moveColumn = useCallback(
    (dragIndex: number, hoverIndex: number) => {
      const dragColumn = tableModel.columns?.[dragIndex];
      const filtered = tableModel.columns?.filter(column => column.label !== dragColumn.label);

      const updatedColumns = [
        ...filtered.slice(0, hoverIndex),
        dragColumn,
        ...filtered.slice(hoverIndex),
      ];

      setTableModel({ ...tableModel, columns: updatedColumns });
    },
    [tableModel]
  );

  return {
    columns: tableModel.columns,
    moveColumn,
    hasReorderColumns,
  };
}
