import { useEffect, useRef } from 'react';

export function useMountRef() {
  const isMounted = useRef(true);
  useEffect(
    () => () => {
      isMounted.current = false;
    },
    []
  );
  return isMounted;
}
