import { observer } from 'mobx-react';
import { Provider } from 'react-redux';
import { ErrorBoundary } from '@src-v2/components/error-boundary';
import Pane from '@src/blocks/Pane';
import store from '@src/store';

export const LegacyProvider = observer(({ children }) => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        {children}
        <Pane />
      </Provider>
    </ErrorBoundary>
  );
});

// Fix support for Safari <=15.3
crypto.randomUUID ||= function randomUUID() {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
  );
};

Object.hasOwn ??= (instance, prop) => instance.hasOwnProperty(prop);
