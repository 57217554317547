import _ from 'lodash';

export const localStorage = storageFactory(window.localStorage);
export const sessionStorage = storageFactory(window.sessionStorage);

function storageFactory(storage) {
  return {
    get(key) {
      try {
        const item = storage.getItem(key);
        return item ? JSON.parse(item) : null;
      } catch (error) {
        return null;
      }
    },
    set(key, val) {
      if (!_.isNil(val)) {
        storage.setItem(key, JSON.stringify(val));
      }
    },
    remove: key => storage.removeItem(key),
    clear: () => storage.clear(),
  };
}
