import { add } from 'date-fns';
import { useCallback } from 'react';
import styled from 'styled-components';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { SimpleSelect } from '@src-v2/containers/simple-select';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { dataAttr } from '@src-v2/utils/dom-utils';

export const ExtensionSelect = styled(({ onChange, ...props }) => {
  const { application } = useInject();
  const handleSelect = useCallback(
    ({ value }) => {
      onChange(add(new Date(), { [value.timeUnit]: value.duration }));
    },
    [onChange]
  );

  const isFormV2 = application.isFeatureEnabled(FeatureFlag.AccessTokenForm);

  return (
    <SimpleSelect
      {...props}
      data-form-v2={dataAttr(isFormV2)}
      variant={Variant.FILTER}
      title={isFormV2 ? null : 'When would you like this token to expire?'}
      options={extensionOptions}
      identity={({ label }) => label}
      onSelect={handleSelect}
      popover={isFormV2 ? ExtensionPopover : DropdownMenu.Popover}
    />
  );
})`
  margin-left: 2rem;

  &[data-form-v2] {
    width: 73rem;
    height: 9rem;
    border-radius: 2rem;
    margin-left: 0;
  }
`;

const ExtensionPopover = styled(DropdownMenu.Popover)`
  width: 73rem;
`;

const extensionOptions = [
  { label: '30 Days', value: { duration: 30, timeUnit: 'days' } },
  { label: '1 Year', value: { duration: 12, timeUnit: 'months' } },
];
