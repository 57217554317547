import _ from 'lodash';
import { apiClassificationDescription } from './definitionsToRuleTransformer';

export const isApiDisplayable = (httpMethod, httpRoute) => !_.isEmpty(httpRoute);
export const httpMethodAnyAware = httpMethod => httpMethod || 'ANY';

export const getApiText = (httpMethod, httpRoute, methodName, classification) => {
  const name = isApiDisplayable(httpMethod, httpRoute)
    ? `${httpMethodAnyAware(httpMethod)} ${httpRoute}`
    : methodName;
  const classificationText =
    classification && classification !== 'unknown'
      ? ` (${apiClassificationDescription(classification)})`
      : '';
  return `${name}${classificationText}`;
};

export const getApiClassification = api => (api.apiClassification ?? 'unknown').toLowerCase();

export const apiInsightConditions = {
  exposingSensitiveData: api => api.exposesSensitiveData,
  involvingSensitiveData: api => api.involvesSensitiveData,
  isSensitive: api => api.isSensitive,
  isVulnerable: api => api.hasCodeFindings,
  isUserFacing: api => api.isUserFacing,
  isInternetFacing: api => api.apiClassification.toLowerCase() === 'internetfacing',
  missingInputValidation: api => api.hasInputValidationViolation,
  missingAuthorization: api => api.hasAuthorizationViolation,
  isBehindGateway: api => api.isBehindApiGateway,
  readsFromStorageBucket: api => api.isReadingFromStorageBucket,
  writesToStorageBucket: api => api.isWritingToStorageBucket,
};

export const apiHasInsight = api =>
  Object.values(apiInsightConditions).some(condition => condition(api)) || api.insights;

export const enrichApiControls = (api, profile, repository) => {
  if (!repository) {
    return api;
  }

  api.isValidationRelevant = isControlRelevant(
    api.codeParsingTarget,
    repository.key,
    profile.validationCodeParsingTargets,
    profile.validationCodeParsingTargetByProfile
  );

  api.isAuthorizationRelevant = isControlRelevant(
    api.codeParsingTarget,
    repository.key,
    profile.authorizationCodeParsingTargets,
    profile.authorizationCodeParsingTargetByProfile
  );
  return api;
};

export const isControlRelevant = (
  codeParsingTarget,
  repositoryKey,
  profileControl,
  controlByProfileKey
) =>
  profileControl.includes(codeParsingTarget) ||
  controlByProfileKey[repositoryKey]?.includes(codeParsingTarget);
