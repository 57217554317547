import { useMemo } from 'react';
import { Badge } from '@src-v2/components/badges';
import { TagsLimiter } from '@src-v2/components/tags-limiter';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';

export function TagsWidget({ tags, maxSize = 3, extraSize = 10 }) {
  const normalizedTags = useMemo(
    () =>
      tags.filter(Boolean).map(tag => ({
        title: tag.title || tag,
        hint: tag.description ?? tag.title,
      })),
    [tags]
  );

  return (
    <TagsLimiter limit={maxSize} excessLimit={extraSize}>
      {normalizedTags.map((tag, index) =>
        tag.hint ? (
          <Tooltip key={index} content={tag.hint}>
            <Badge>{tag.title}</Badge>
          </Tooltip>
        ) : (
          <Badge key={index}>{tag.title}</Badge>
        )
      )}
    </TagsLimiter>
  );
}
