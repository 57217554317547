import _ from 'lodash';
import styled from 'styled-components';
import { VendorIcon } from '@src-v2/components/icons';
import { hasVendorIcon } from '@src-v2/data/icons';
import { formatDate } from '@src-v2/utils/datetime-utils';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { cutAroundCensoredValue } from '@src-v2/utils/secret-utils';
import { arrayJoinConjunction } from '@src-v2/utils/string-utils';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import { Highlights } from '@src/components/CodeAggregation/Insights/Highlights';
import { StyledInsightsTitle } from '@src/components/CodeAggregation/Insights/InsightStyles';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { ElementPane } from '@src/components/ElementPane';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { StyledHighlightedCode } from '@src/components/MaterialChange/Styles';
import { Number } from '@src/components/Number';
import { TitledList } from '@src/components/TitledList';
import { VerticalStack } from '@src/components/VerticalStack';
import { dispatch } from '@src/store';

const CodePreview = styled(TitledList)`
  div {
    padding: 0;
  }
`;

const VendorIconWithText = styled.div`
  display: flex;
  gap: 1rem;
`;

const HighlightContainer = styled.span``;

const getHighlights = secret => {
  const highlights = [];
  const hasIcon = hasVendorIcon(secret.secretSubCategoryPlatform);

  if (secret.validity && secret.validity !== 'Unknown' && secret.validity !== 'NoValidator') {
    highlights.push(`Last validated: ${formatDate(secret.validatedOn, 'daily')}`);
  }
  if (secret.fileClassification !== 'Default') {
    highlights.push(`File type: ${secret.fileClassification}`);
  }
  if (secret.subCategoryDescription && secret.subCategoryDescription !== '') {
    highlights.push(
      hasIcon ? (
        <VendorIconWithText>
          Secret type: <VendorIcon name={secret.secretSubCategoryPlatform} />
          {secret.subCategoryDescription}
        </VendorIconWithText>
      ) : (
        `Secret type: ${secret.subCategoryDescription}`
      )
    );
  }
  if (secret.type && secret.type !== '') {
    highlights.push(`Encryption: ${secret.type}`);
  }
  if (secret.relativeFilePath && secret.relativeFilePath !== '') {
    highlights.push(`Component: ${secret.relativeFilePath}`);
  }
  if (secret.validity && !_.isEmpty(secret.environments)) {
    highlights.push(
      _.upperFirst(
        `${arrayJoinConjunction(secret.environments.sort())} ${pluralFormat(
          secret.environments.length,
          'environment',
          'environments'
        )}`
      )
    );
  }

  if (secret.lineNumbers.length > 1) {
    highlights.push(
      <HorizontalStack spacing="0.2">
        <span>Appears</span>
        <Number value={secret.lineNumbers.length} one="time" other="times" />
        <span>in the file</span>
      </HorizontalStack>
    );
  }

  return highlights;
};

const getInsightsBody = (secret, highlights) => (
  <>
    <Highlights
      highlights={highlights.map(highlightContent => (
        <HighlightContainer>{highlightContent}</HighlightContainer>
      ))}
    />
    <VerticalStack>
      <StyledInsightsTitle>
        <span>Code Preview</span>
        {secret.lineNumbers.length > 5 && <span> - 5 examples</span>}
      </StyledInsightsTitle>
      <CodePreview
        list={secret.censoredValues.map(cutAroundCensoredValue)}
        listItemRender={(item, index) => {
          const secretLineNumber = secret.lineNumbers[index];
          return (
            <StyledHighlightedCode
              key={index}
              code={secretLineNumber ? `${secretLineNumber}: ${item}` : ''}
            />
          );
        }}
      />
    </VerticalStack>
  </>
);

const getSecretPane = ({
  trigger,
  secret,
  repository,
  profile,
  profileType,
  codeReference,
  ruleTriggers,
  messageContent,
}) => {
  const highlights = getHighlights(secret);

  return (
    <ElementPane
      element={secret}
      repository={repository}
      title={
        <InsightsPaneTitle
          trigger={trigger}
          element={secret}
          title={secret.relativeFilePath}
          codeReference={codeReference}
          repository={repository}
          profile={profile}
          profileType={profileType}
          ruleTriggers={ruleTriggers}
          messageContent={messageContent}
        />
      }
      profileBody={getInsightsBody(secret, highlights)}
    />
  );
};

export const openSecretPaneWithRiskActions = ({
  ruleKey,
  profile,
  trigger,
  profileType,
  onClose,
  relevantPath,
  messageContent,
  externalRiskTriggerPane,
  level = secondaryPaneLevel,
}) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  dispatch.pane.openPane({
    level,
    onClose,
    relevantPath,
    id: trigger.key,
    content: (
      <RiskRuleTrigger
        trigger={trigger}
        profile={profile}
        ruleKey={ruleKey}
        externalRiskTriggerPane={externalRiskTriggerPane}
        getPane={({ element, ruleTriggers }) =>
          getSecretPane({
            secret: element,
            repository,
            profile,
            profileType,
            codeReference: { ...element, lineNumber: element.lineNumbers?.[0] },
            ruleTriggers,
            messageContent,
            trigger,
          })
        }
      />
    ),
  });
};
