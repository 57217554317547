import styled, { css } from 'styled-components';

export const FontBodyExtraSmall = css`
  color: var(--default-text-color);
  font-size: var(--font-size-xs);
  line-height: 4.5rem;
`;

export const FontBodySmall = css`
  color: var(--default-text-color);
  font-size: var(--font-size-s);
  line-height: 4.5rem;
  font-weight: 300;
`;

export const FontBodySmallBold = css`
  color: var(--default-text-color);
  font-size: var(--font-size-s);
  line-height: 4.5rem;
  font-weight: 500;
`;

export const FontBody = css`
  color: var(--default-text-color);
  font-size: var(--font-size-m);
  line-height: 6rem;
  font-weight: 300;
`;

export const FontBodyBold = css`
  color: var(--default-text-color);
  font-size: var(--font-size-m);
  line-height: 6rem;
  font-weight: 700;
`;

export const FontBodyHeader = css`
  color: var(--default-text-color);
  font-size: var(--font-size-l);
  line-height: 6rem;
  font-weight: 400;
`;

export const FontBodyLargeBold = css`
  color: var(--default-text-color);
  font-size: var(--font-size-xl);
  line-height: 7rem;
  font-weight: 500;
`;

export const FontBodyLargeHeader = css`
  color: var(--default-text-color);
  font-size: var(--font-size-xl);
  line-height: 7rem;
  font-weight: 400;
`;

export const Ellipsis = css`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;

export const FontBodySpan = styled.span`
  ${FontBody};
`;

export const EllipsisDiv = styled.div`
  ${Ellipsis};
`;

export const CircledIcon = css`
  width: ${props => (props.size === 'large' ? '42px' : '30px')};
  height: ${props => (props.size === 'large' ? '42px' : '30px')};
  padding: ${props => (props.size === 'large' ? '9px' : '3px')};
  background-color: var(--color-blue-gray-15);
  border-radius: 100vmax;
  line-height: 0;
`;

export const StyledVerticalContainer = styled.div`
  ${FontBody};
  text-align: left;
  display: flex;
  flex-flow: column;
  border: 2px solid var(--color-blue-gray-20);
  border-radius: 3rem;
  padding: 6px 18px 18px;
  background-color: var(--color-white);
  z-index: 10;
  box-shadow: 0 2px 12px 0 rgba(186, 202, 213, 0.3);

  > *:not(:first-child) {
    margin-top: 3rem;
  }
`;

export const HideScrollBar = css`
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;
