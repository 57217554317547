import { addInterpunctSeparator, humanize } from '@src-v2/utils/string-utils';
import { openTerraformModuleHighlightsWithRiskActions } from '@src/components/Panes/TerraformModuleHighlightsPane';
import { EllipsisDiv } from '@src/style/common';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getTerraformResourceTriggerDisplay = ({ trigger, ...options }) => {
  const misconfigurationsTextualSummary = getMisconfigurationsTextualSummary(trigger);
  return [
    {
      weight: 3,
      content: (
        <PaneLocationLink
          id={trigger.key}
          onClick={args =>
            openTerraformModuleHighlightsWithRiskActions({ ...args, trigger, ...options })
          }>
          <TriggerInfoContainer title={trigger.modulePath} insights={trigger.insights}>
            <EllipsisDiv>
              {addInterpunctSeparator(
                `${trigger.typeDescription} (${trigger.resourceKey})`,
                misconfigurationsTextualSummary
              )}
            </EllipsisDiv>
          </TriggerInfoContainer>
        </PaneLocationLink>
      ),
    },
  ];
};

export function getTerraformResourceMisconfigurationFormat(trigger) {
  return addInterpunctSeparator(
    `${trigger.typeDescription} (${trigger.resourceKey})`,
    getMisconfigurationsTextualSummary(trigger)
  );
}

function getMisconfigurationsTextualSummary(trigger) {
  return addInterpunctSeparator(
    Object.entries(trigger.misconfigurationCategoryCount).map(
      ([category, count]) => `${humanize(category)} (${count})`
    )
  );
}
