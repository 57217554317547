import { connect } from 'react-redux';
import { select } from '@src/store';
import BuildPage from './view';

const mapState = select(
  ({
    buildPage: { buildJobId, buildData, hasFetchedData },
    commitPage,
    repositoryProfiles: { profilesByKey: repositoryProfilesByKey },
    developerProfiles: { developerProfileByIdentityKey },
  }) => ({
    buildJobId,
    buildData,
    hasFetchedData,
    commitMaterialChanges: commitPage.commitMaterialChanges,
    hasFetchedCommitData: commitPage.hasFetchedData,
    repositoryProfilesByKey,
    developerProfileByIdentityKey,
  })
);

const mapDispatch = ({ buildPage: { fetchData, clearData } }) => ({
  fetchData,
  clearData,
});

export default connect(mapState, mapDispatch)(BuildPage);
