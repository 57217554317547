export const InputAttributes = [
  'autoComplete',
  'autoFocus',
  'accept',
  'checked',
  'defaultChecked',
  'defaultValue',
  'disabled',
  'max',
  'maxLength',
  'min',
  'minLength',
  'multiple',
  'name',
  'onBlur',
  'onChange',
  'onClick',
  'onFocus',
  'pattern',
  'placeholder',
  'readOnly',
  'required',
  'src',
  'step',
  'type',
  'value',
  'icon',
];
