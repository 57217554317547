import isEmpty from 'lodash/isEmpty';
import { ExternalLink, Paragraph, Strong } from '@src-v2/components/typography';
import { MaterialChangesRow } from '@src-v2/containers/commit/common-componnets';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import { pluralFormat } from '@src-v2/utils/number-utils';

export const DockerfileMaterialChange = ({ repository, materialChange }) => (
  <>
    A change was made to dockerfile under path{' '}
    <ExternalLink
      href={generateCodeReferenceUrl(
        {
          provider: repository.provider,
          url: repository.providerUrl,
          referenceName: repository.mainBranch,
        },
        { relativeFilePath: materialChange.dockerfilePath }
      )}>
      {materialChange.dockerfilePath}
    </ExternalLink>
    {!isEmpty(materialChange.newUsers) && (
      <MaterialChangesRow>
        <Paragraph>
          {pluralFormat(materialChange.newUsers.length, 'New user is', 'New users are', true)}
        </Paragraph>
        <Paragraph>
          used when running this Docker image: <Strong>{materialChange.newUsers}</Strong>{' '}
        </Paragraph>
      </MaterialChangesRow>
    )}
    {!isEmpty(materialChange.newBaseImages) && (
      <MaterialChangesRow>
        <Paragraph>
          {pluralFormat(
            materialChange.newBaseImages.length,
            'New base image is',
            'New base images are',
            true
          )}
        </Paragraph>
        <Paragraph>
          {' '}
          newly used in this dockerfile <Strong>
            {materialChange.newBaseImages.toString()}
          </Strong>{' '}
        </Paragraph>
      </MaterialChangesRow>
    )}
    {!isEmpty(materialChange.newExposedPorts) && (
      <MaterialChangesRow>
        {pluralFormat(materialChange.newExposedPorts.length, 'Port', 'Ports', true)}
        newly exposed: <Strong>{materialChange.newExposedPorts.toString()}</Strong>
      </MaterialChangesRow>
    )}
    {materialChange.newAddCommandsCount > 0 && (
      <Paragraph>
        {materialChange.newAddCommandsCount} new Add commands used in this Dockerfile
      </Paragraph>
    )}
  </>
);
