import { forwardRef, useMemo } from 'react';
import styled from 'styled-components';
import * as languageIcons from '@src-v2/assets/languages';
import { Circle, CircleGroup, LanguageCircle } from '@src-v2/components/circles';
import { LanguageIcon } from '@src-v2/components/icons';
import { CircledIcon } from '@src/style/common';
import { SvgIcon } from './SvgIcon';
import { Tooltip } from './Tooltip';

export const LanguageStack = forwardRef(
  ({ languages: unfilteredLanguages, maxSize = 3, ...props }, ref) => {
    const languages = useMemo(
      () => unfilteredLanguages.filter(language => languageIcons[language.key]).slice(0, maxSize),
      [unfilteredLanguages, maxSize]
    );

    return (
      <CircleGroup size="xlarge" ref={ref} {...props}>
        {languages.length ? (
          languages.map((language, index) => (
            <LanguageCircle
              key={language.key}
              name={language.key}
              zIndex={languages.length - index}
            />
          ))
        ) : (
          <EmptyCircle>–</EmptyCircle>
        )}
      </CircleGroup>
    );
  }
);

export const LanguageIconWithTooltip = ({ className, name, tip }) => (
  <StyledTooltip tip={tip}>
    <SvgIcon className={className}>
      <LanguageIcon name={name} />
    </SvgIcon>
  </StyledTooltip>
);

const StyledTooltip = styled(Tooltip)`
  ${CircledIcon};
`;

const EmptyCircle = styled(Circle)`
  background-color: var(--color-blue-30);
  border: 0.4rem solid var(--color-white);
`;
