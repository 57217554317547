import ioc from '@src-v2/ioc';
import { createDebouncedAsyncCall } from '@src/utils/debouncedAsyncCalls';

export default {
  state: {},
  selectors: () => ({}),
  reducers: {},
  effects: () => ({
    searchCommitish: createDebouncedAsyncCall(async ({ repositoryKey, type, query }) => {
      try {
        return await ioc.apiClient.search(`providerRepositories/${repositoryKey}/${type}`, {
          clearCacheEntry: true,
          searchTerm: query,
        });
      } catch (error) {
        if (error.response?.status === 400 && error.response.data?.code === 'Unknown') {
          ioc.toaster.error(error.response.data.message);
        } else {
          ioc.toaster.error('Something went wrong');
        }
        return [];
      }
    }),
  }),
};
