import { throttle } from 'lodash';
import { useLayoutEffect, useState } from 'react';

export function useResizeObserver(ref, handler, box = 'content-box') {
  useLayoutEffect(() => {
    const target = ref.current;
    callbackMap.set(target, handler);
    resizeObserver.observe(target, { box });
    return () => {
      callbackMap.delete(target);
      resizeObserver.unobserve(target);
    };
  }, [ref.current, handler, box]);
}

export function useDimensionsObserver(ref, defaultValue = { width: 0, height: 0 }) {
  const [resizeTarget, setResizeTarget] = useState(null);
  useResizeObserver(ref, setResizeTarget);
  return resizeTarget?.contentRect ?? defaultValue;
}

const callbackMap = new Map();
const resizeObserver = new ResizeObserver(
  throttle(entries => {
    for (const entry of entries) {
      callbackMap.get(entry.target)?.(entry);
    }
  }, 100)
);
