import { getViewCodeColumn } from '@src/components/CodeAggregation/Common';
import { StyledContent } from '@src/components/CodeAggregation/Insights/InsightStyles';
import { Table } from '@src/components/Table';
import { Tooltip } from '@src/components/Tooltip';
import { getModuleLinkByPath } from '@src/utils/moduleFinder';

const ApiDataModels = ({ profile, repository, sensitiveDataReferences }) => (
  <Table
    medium
    headers={[
      { name: 'Module', requiresNonEmpty: true, weight: 3 },
      { name: 'Data Model Name', weight: 2 },
      { name: 'Sensitive Data Field', weight: 3 },
      { name: 'Type', weight: 2 },
    ]}
    rows={sensitiveDataReferences.map(sensitiveDataReference => {
      const sensitiveDataFieldReference = sensitiveDataReference.codeReference;
      return {
        key: sensitiveDataReference.entityId,
        cells: [
          {
            content: getModuleLinkByPath(
              profile,
              sensitiveDataFieldReference.relativeFilePath,
              StyledContent,
              'data/pii'
            ),
          },
          {
            content: (
              <Tooltip tip={sensitiveDataFieldReference.name}>
                <StyledContent>{sensitiveDataFieldReference.name}</StyledContent>
              </Tooltip>
            ),
          },
          {
            content: <StyledContent>{sensitiveDataReference.name}</StyledContent>,
          },
          {
            content: (
              <div>
                {sensitiveDataReference.sensitiveDataTypes?.map(type => (
                  <StyledContent key={type}>{type.toUpperCase()}</StyledContent>
                ))}
              </div>
            ),
            menu: [getViewCodeColumn(sensitiveDataFieldReference, repository)],
          },
        ],
      };
    })}
  />
);

export default ApiDataModels;
