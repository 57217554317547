import { animated, useSpring } from '@react-spring/web';
import _ from 'lodash';
import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import { createContext, useCallback, useContext } from 'react';
import styled from 'styled-components';
import { followCursor } from 'tippy.js';
import { BaseIcon } from '@src-v2/components/icons';
import { MarkerArrow } from '@src-v2/components/svg/marker-arrow';
import { SvgRoot } from '@src-v2/components/svg/svg-elements';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { SpaceTimeGraphNodePopover } from '@src-v2/containers/spacetime-graph/spacetime-graph-node-popover';
import {
  CircleSymbol,
  NodeIcon,
  NodeThumbnail,
} from '@src-v2/containers/spacetime-graph/spacetime-graph-symbols';
import { useInject, useSuspense } from '@src-v2/hooks';
import { useChangeId } from '@src-v2/hooks/react-helpers/use-change-id';
import { useFilters } from '@src-v2/hooks/use-filters';
import { ForceGraph } from '@src-v2/models/force-graph/force-graph';

const GraphContext = createContext(null);

const edgeColors = { light: '#b6b6b6', dark: 'black' };

export const SpacetimeGraph = observer(({ data, center, scale, onNodeClick, ...props }) => (
  <GraphContext.Provider value={data}>
    <GraphRoot {...props} key={useChangeId(data)}>
      <defs>
        <MarkerArrow
          id="marker-arrow-end-light"
          fill={edgeColors.light}
          refX={45}
          width={6}
          height={6}
        />
        <MarkerArrow
          id="marker-arrow-end-dark"
          fill={edgeColors.dark}
          refX={45}
          width={6}
          height={6}
        />
      </defs>
      <PanAndZoom transform={`matrix(${[scale, 0, 0, scale, center.x, center.y]})`}>
        <GraphFactory nodes={data.nodes} edges={data.edges} onNodeClick={onNodeClick} />
      </PanAndZoom>
    </GraphRoot>
  </GraphContext.Provider>
));

const GraphNode = observer(({ node, edges, onClick }) => {
  const { activeFilters } = useFilters();
  const graphData = useContext(GraphContext);
  const { connectors } = useInject();
  const { simulation, expanded, children } = node;

  const dependencyRepository = node?.data?.RepositoryKey
    ? useSuspense(connectors.getRepository, {
        key: node?.data?.RepositoryKey,
      })
    : null;

  const handleClick = useCallback(
    event => {
      event.stopPropagation();
      if (node.children && !node.selected) {
        node.selected || node.positionOnTop();
      }
      onClick?.({
        node,
        repository: dependencyRepository,
      });
      setTimeout(() => node.toggleSelect());
    },
    [node]
  );

  const childrenIds = children?.nodes.reduce((idsMap, node) => {
    idsMap[node.id] = true;
    return idsMap;
  }, {});
  const subEdges =
    node.expanded && childrenIds
      ? edges
          .filter(edge => childrenIds[edge.source.id] || childrenIds[edge.target.id])
          .map(edge => {
            const [intersectionPoint1, intersectionPoint2] = calcLineCircleIntersection(
              { ...simulation, radius: node.radius },
              edge.source,
              edge.target
            );
            const { x: x1, y: y1 } = ForceGraph.mergeSimulations(
              { id: edge.source.id, x: -simulation.x, y: -simulation.y },
              childrenIds[edge.source.id] ? edge.source : intersectionPoint2
            );
            const { x: x2, y: y2 } = ForceGraph.mergeSimulations(
              { x: -simulation.x, y: -simulation.y },
              childrenIds[edge.target.id] ? edge.target : intersectionPoint1
            );
            return {
              ...edge,
              source: { ...edge.source, x: x1, y: y1 },
              target: { ...edge.target, x: x2, y: y2 },
            };
          })
      : [];

  return (
    <NodeGroup
      transform={`translate(${[simulation.x, simulation.y]})`}
      onClick={handleClick}
      onMouseEnter={() => activeFilters?.searchTerm || node.setHighlight(true)}
      onMouseLeave={() => activeFilters?.searchTerm || node.setHighlight(false)}>
      <SpaceTimeGraphNodePopover node={node}>
        <NodeThumbnail
          node={node}
          data-highlight={graphData.highlights.includes(node) ? 'true' : null}
        />
      </SpaceTimeGraphNodePopover>
      {children && <GraphFactory nodes={expanded ? children.nodes : null} edges={subEdges} />}
    </NodeGroup>
  );
});

const GraphEdge = observer(({ edge }) => {
  const { activeFilters } = useFilters();
  const graphData = useContext(GraphContext);
  const edgePosition = useEdgePosition(edge);
  const source = graphData.getNodeById(edge.source.id);
  const target = graphData.getNodeById(edge.target.id);
  const noSearchTerm = !activeFilters.searchTerm?.trim();

  return (
    <EdgeGroup
      edge={edge}
      data-highlight={
        [source, target].every(node => graphData.highlights.includes(node)) ? 'true' : null
      }>
      <animated.line {...edgePosition} />
      <Tooltip content={edge.type} plugins={[followCursor]} followCursor>
        <animated.line
          {...edgePosition}
          onMouseEnter={useCallback(() => {
            runInAction(() => {
              if (noSearchTerm) {
                source.highlight = true;
                target.highlight = true;
              }
            });
          }, [source, target, noSearchTerm])}
          onMouseLeave={useCallback(() => {
            runInAction(() => {
              if (noSearchTerm) {
                source.highlight = false;
                target.highlight = false;
              }
            });
          }, [source, target, noSearchTerm])}
        />
      </Tooltip>
    </EdgeGroup>
  );
});

const PanAndZoom = styled.g`
  color: #000;
  //transition: transform 200ms;
`;

const EdgesRoot = styled.g`
  fill: none;
  stroke-width: 2.5;
`;

const NodeGroup = styled.g`
  transition: transform 400ms;
  transform-origin: center;
  cursor: pointer;
`;

const EdgeGroup = styled.g`
  transition: opacity 400ms;

  > line {
    &:first-child {
      marker-end: ${edge => `url(#marker-arrow-end-${edgeStrokeType(edge)})`};
      stroke: ${edge => edgeColors[edgeStrokeType(edge)]};
      stroke-linecap: round;
    }
    &:last-child {
      stroke: transparent;
      stroke-width: 6;
    }
  }
`;

const GraphFactory = styled(
  observer(({ nodes, edges, onNodeClick, ...props }) => {
    const { activeFilters } = useFilters();
    const highlightsCount = nodes?.filter(node => node.highlight).length ?? 0;
    return (
      <g
        {...props}
        data-has-highlights={highlightsCount || activeFilters?.searchTerm ? 'true' : null}>
        {edges?.length > 0 && (
          <EdgesRoot>
            {edges.map((edge, index) => (
              <GraphEdge key={edge.index ?? index} edge={edge} />
            ))}
          </EdgesRoot>
        )}
        {_.orderBy(nodes, 'lastInteraction', 'asc').map(node => (
          <GraphNode key={node.id} node={node} edges={edges} onClick={onNodeClick} />
        ))}
      </g>
    );
  })
)`
  transform: scale(${props => (props.nodes ? 1 : 0)});
  opacity: ${props => (props.nodes ? 1 : 0)};
  transition: all 400ms;

  &[data-has-highlights] ${EdgeGroup}:not([data-highlight]),
  &[data-has-highlights]
    :not([data-highlight])
    > ${NodeThumbnail.Badge}:not([data-highlight])
    > ${BaseIcon},
    &[data-has-highlights]
    :not([data-highlight])
    > ${NodeIcon} {
    opacity: 0.3;
  }

  &[data-has-highlights] :not([data-highlight]) > ${NodeThumbnail.Badge}:not([data-highlight]) {
    color: var(--color-blue-gray-50);
  }

  &[data-has-highlights] :not([data-highlight]) > ${CircleSymbol} {
    stroke-width: 0.5;
  }
`;

const GraphRoot = styled(SvgRoot)`
  position: absolute;
  user-select: none;
  color: #b6b6b6;
`;

function useEdgePosition(edge) {
  const {
    source: { x: x1, y: y1 },
    target: { x: x2, y: y2 },
  } = edge;
  const angle = Math.atan2(y2 - y1, x2 - x1);
  const dx = Math.cos(angle);
  const dy = Math.sin(angle);
  const points = { x1: x1 + dx, x2: x2 - dx, y1: y1 + dy, y2: y2 - dy };
  const [position, api] = useSpring(() => points);
  api.start(points);
  return position;
}

function edgeStrokeType({ edge }) {
  switch (edge.type) {
    case 'Uses':
      return 'light';
    default:
      return 'dark';
  }
}

function calcLineCircleIntersection(
  { x: cx, y: cy, radius: r },
  { x: x1, y: y1 },
  { x: x2, y: y2 }
) {
  const horizontalDistance = x2 - x1;
  const verticalDistance = y2 - y1;
  const a = horizontalDistance ** 2 + verticalDistance ** 2;
  const b = 2 * horizontalDistance * (x1 - cx) + 2 * verticalDistance * (y1 - cy);
  const c = (x1 - cx) ** 2 + (y1 - cy) ** 2 - r ** 2;
  const discriminantRoot = Math.sqrt(b ** 2 - 4 * a * c);
  const t1 = (-b + discriminantRoot) / (2 * a);
  const t2 = (-b - discriminantRoot) / (2 * a);
  return [
    { x: horizontalDistance * t1 + x1, y: verticalDistance * t1 + y1 },
    { x: horizontalDistance * t2 + x1, y: verticalDistance * t2 + y1 },
  ];
}
