import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { openFindingPaneWithRiskActions } from '@src/components/Panes/FindingPane';
import { getTrimmedPath } from '@src/utils/stringDisplayUtils';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

const buildFindingFilePath = finding =>
  finding.codeReference.relativeFilePath
    ? `${getTrimmedPath(finding.codeReference.relativeFilePath)}:${
        finding.codeReference.lineNumber
      }`
    : finding.modulePath;

export const getFindingsTriggerDisplay = ({ trigger, profile, ...options }) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  const title = addInterpunctSeparator(
    profile.profileType === 'ApplicationProfile' && repository.name,
    buildFindingFilePath(trigger)
  );

  return [
    {
      weight: 3,
      content: (
        <PaneLocationLink
          id={trigger.key}
          onClick={args =>
            openFindingPaneWithRiskActions({ ...args, profile, trigger, ...options })
          }>
          <TriggerInfoContainer title={title} insights={trigger.insights}>
            <HorizontalStack>
              <span>{trigger.provider}</span>
              <span> &middot; </span>
              <span>{trigger.displayName}</span>
            </HorizontalStack>
          </TriggerInfoContainer>
        </PaneLocationLink>
      ),
    },
  ];
};

export const getCodeFindingMessageFormat = element =>
  addInterpunctSeparator(element.providers[0], element.displayName, buildFindingFilePath(element));
