import { observer } from 'mobx-react';
import { Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { Section } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { StickyHeader } from '@src-v2/components/layout/sticky-header';
import { ProcessTag } from '@src-v2/components/tags';
import { ProcessTagDeleteModal } from '@src-v2/containers/process-tags/process-tag-delete-modal';
import { ProcessTagEditModal } from '@src-v2/containers/process-tags/process-tag-edit-modal';
import { ProcessTagsList } from '@src-v2/containers/process-tags/process-tags-list';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject } from '@src-v2/hooks';

export const ProcessTagsPage = observer(() => {
  const { rbac } = useInject();
  const canEditTag = rbac.canEdit(resourceTypes.Global);

  return (
    <Page title="Processes Management">
      <StickyHeader isStaticActions>
        <Button to="/settings/access-permissions/process-tags/create" disabled={!canEditTag}>
          Create New
        </Button>
      </StickyHeader>

      <Section>
        <ProcessTagsList />
      </Section>

      <Switch>
        <Route
          path={[
            '/settings/access-permissions/process-tags/create',
            '/settings/access-permissions/process-tags/:key/edit',
          ]}>
          <ProcessTagEditModal />
        </Route>
        <Route path="/settings/access-permissions/process-tags/:key/delete">
          <ProcessTagDeleteModal />
        </Route>
      </Switch>
    </Page>
  );
});

export const ProcessTagsIcon = styled(ProcessTag)`
  width: 12rem;
  height: 6rem;
  padding: 1rem;
  font-size: 2.5rem;
  text-align: center;
`;
