import _ from 'lodash';
import styled from 'styled-components';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import Bold from '@src/components/Bold';
import { Highlights } from '@src/components/CodeAggregation/Insights/Highlights';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { ElementPane } from '@src/components/ElementPane';
import { VerticalStack } from '@src/components/VerticalStack';
import { dispatch } from '@src/store';
import { Ellipsis } from '@src/style/common';

const StyledVerticalStack = styled(VerticalStack)`
  ${Ellipsis};
  padding-top: 6rem;
`;

const GetPaneBody = ({ serverlessFunction }) => {
  const highlights = [];
  if (!_.isEmpty(serverlessFunction.provider)) {
    highlights.push(
      <span>
        Provider: <Bold>{serverlessFunction.provider}</Bold>
      </span>
    );
  }
  if (!_.isEmpty(serverlessFunction.triggerEvents)) {
    highlights.push(
      <span>
        Triggered by: <Bold>{serverlessFunction.triggerEvents.join(', ')}</Bold>
      </span>
    );
  }
  if (!_.isEmpty(serverlessFunction.runtime)) {
    highlights.push(
      <span>
        Runtime: <Bold>{serverlessFunction.runtime}</Bold>
      </span>
    );
  }
  if (!_.isEmpty(serverlessFunction.iamRole)) {
    highlights.push(
      <span>
        Linked IAM role: <Bold>{serverlessFunction.iamRole}</Bold>
      </span>
    );
  }
  if (!_.isEmpty(serverlessFunction.handlerIdentifier)) {
    highlights.push(
      <span>
        Code handler: <Bold>{serverlessFunction.handlerIdentifier}</Bold>
      </span>
    );
  }
  if (!_.isEmpty(serverlessFunction.securityGroupIds)) {
    highlights.push(
      <span>
        Linked security groups IDs: <Bold>{serverlessFunction.securityGroupIds.join(', ')}</Bold>
      </span>
    );
  }
  if (!_.isEmpty(serverlessFunction.subnetIds)) {
    highlights.push(
      <span>
        Linked subnet IDs: <Bold>{serverlessFunction.subnetIds.join(', ')}</Bold>
      </span>
    );
  }
  if (!_.isEmpty(serverlessFunction.region)) {
    highlights.push(
      <span>
        Deployed in region: <Bold>{serverlessFunction.region}</Bold>
      </span>
    );
  }
  if (!_.isEmpty(serverlessFunction.stage)) {
    highlights.push(
      <span>
        Deployed at stage: <Bold>{serverlessFunction.stage}</Bold>
      </span>
    );
  }
  return (
    <StyledVerticalStack>
      <Highlights highlights={highlights} />
    </StyledVerticalStack>
  );
};
const getServerlessDescription = serverlessFunction => {
  const servicePart = serverlessFunction.serviceKey ? `${serverlessFunction.serviceKey} / ` : '';
  return `${servicePart}${serverlessFunction.functionKey}`;
};
const getServerlessFunctionPane = ({
  trigger,
  serverlessFunction,
  profile,
  profileType,
  ruleTriggers,
  repository,
  messageContent,
}) => {
  const title = (
    <InsightsPaneTitle
      trigger={trigger}
      element={serverlessFunction}
      title={getServerlessDescription(serverlessFunction)}
      codeReference={serverlessFunction.codeReference}
      repository={repository}
      profile={profile}
      profileType={profileType}
      ruleTriggers={ruleTriggers}
      messageContent={messageContent}
    />
  );

  const body = <GetPaneBody serverlessFunction={serverlessFunction} />;

  return (
    <ElementPane
      element={serverlessFunction}
      repository={repository}
      title={title}
      profileBody={body}
    />
  );
};

export const openServerlessFunctionPaneWithRiskActions = ({
  trigger,
  ruleKey,
  profile,
  profileType,
  onClose,
  relevantPath,
  messageContent,
}) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  dispatch.pane.openPane({
    onClose,
    relevantPath,
    id: trigger.key,
    level: secondaryPaneLevel,
    content: (
      <RiskRuleTrigger
        trigger={trigger}
        profile={profile}
        ruleKey={ruleKey}
        getPane={({ element, ruleTriggers }) =>
          getServerlessFunctionPane({
            trigger,
            serverlessFunction: element,
            profile,
            profileType,
            ruleTriggers,
            repository,
            messageContent,
          })
        }
      />
    ),
  });
};

export const openServerlessFunctionPane = ({
  serverlessFunction,
  profile,
  profileType,
  repository,
}) => {
  dispatch.pane.openPane({
    id: serverlessFunction.entityId,
    content: getServerlessFunctionPane({
      serverlessFunction,
      profile,
      profileType,
      repository,
    }),
  });
};
