import { useMemo } from 'react';
import styled from 'styled-components';
import { MotionAnimation } from '@src-v2/components/animations/motion-animation';
import { Counter } from '@src-v2/components/counter';
import { SvgIcon } from '@src-v2/components/icons';
import { ErrorLayout } from '@src-v2/components/layout';
import { RiskIcon } from '@src-v2/components/risk/risk-icon';
import { Table } from '@src-v2/components/table/table';
import { TableHeader } from '@src-v2/components/table/table-header';
import { DateTime } from '@src-v2/components/time';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink, Paragraph } from '@src-v2/components/typography';
import { RiskIconCell } from '@src-v2/containers/risks/risks-common-cells';
import { dateFormats } from '@src-v2/data/datetime';
import { riskLevelToRiskOrder } from '@src-v2/data/risk-data';
import { useInject, useSuspense } from '@src-v2/hooks';
import { useTable } from '@src-v2/hooks/use-table';
import { BetaBadge } from '@src/components/BetaBadge';
import { ConsumableLink } from '@src/components/ConsumableLink';
import { StyledManageRisk } from './ManageRiskTable';

export const BuildsTable = ({ profileType, profile }) => {
  const profileTableColumns = useMemo(() => {
    const columns = [...tableColumns];

    if (profileType === 'ApplicationProfile') {
      columns.splice(1, 0, {
        label: 'Repository',
        Cell: props => <RepositoryCell {...props} profile={profile} />,
      });
    }

    return columns;
  }, [profileType]);

  const { application, profiles } = useInject();
  const tableModel = useTable({
    tableColumns: profileTableColumns,
    hasReorderColumns: false,
  });

  const buildScans = useSuspense(profiles.getProfileBuilds, {
    profileKey: profile.key,
    profileType,
  });

  return (
    <StyledManageRisk>
      <Paragraph>
        <Paragraph>
          Risks found in build scans triggered by your CI/CD system{' '}
          <DocumentationLink href="https://docs.apiiro.com/for/buildsScan">
            Learn more
          </DocumentationLink>
        </Paragraph>
        {!application.isDemo && <BetaBadge featureName="CI/CD risks" />}
      </Paragraph>
      <Table>
        <TableHeader tableModel={tableModel} />
        <Table.Body>
          {buildScans?.length === 0 ? (
            <Table.EmptyMessage colSpan={profileTableColumns.length}>
              <ErrorLayout.NoResults data-contained />
            </Table.EmptyMessage>
          ) : (
            buildScans.map(build => (
              <Table.Row key={build.buildId}>
                {tableModel.columns?.map(({ Cell, ...column }) => (
                  <Cell key={column.label} data={build} />
                ))}
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </StyledManageRisk>
  );
};

const RepositoryCell = ({ data, profile, ...props }) => {
  return (
    <Table.FlexCell {...props}>
      {profile.repositoryByKey?.[data.repositoryKey] && (
        <ConsumableLink consumable={profile.repositoryByKey?.[data.repositoryKey]} />
      )}
    </Table.FlexCell>
  );
};

const GovernanceRuleViolationsCell = styled(({ data, ...props }) => {
  const sortedMatchedRuleResults =
    data.matchedRuleResults?.sort(
      (first, second) =>
        riskLevelToRiskOrder[second.riskLevel] - riskLevelToRiskOrder[first.riskLevel]
    ) ?? [];
  const [firstRule] = sortedMatchedRuleResults;

  return (
    <Table.FlexCell {...props}>
      {firstRule && <GovernanceRuleViolation rule={firstRule} />}
      {sortedMatchedRuleResults?.length > 1 && (
        <Tooltip
          content={
            <>
              {sortedMatchedRuleResults.slice(1, 4).map((rule, index) => (
                <GovernanceRuleViolation key={index} rule={rule} />
              ))}
              <div>
                {sortedMatchedRuleResults.length > 4 && (
                  <>+{sortedMatchedRuleResults.length - 4} more</>
                )}
              </div>
            </>
          }>
          <Counter>+{sortedMatchedRuleResults.length - 1}</Counter>
        </Tooltip>
      )}
    </Table.FlexCell>
  );
})`
  align-items: center;
  gap: 1rem;

  ${Paragraph} {
    margin: 0;
  }
`;

const GovernanceRuleViolation = styled(({ rule, ...props }) => {
  return (
    <Paragraph {...props}>
      <RiskIcon riskLevel={rule.riskLevel} /> {rule.ruleName} ({rule.count})
    </Paragraph>
  );
})`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const tableColumns = [
  {
    label: 'Risk',
    width: '15rem',
    Cell: ({ data, ...props }) =>
      data.scanError ? (
        <Table.Cell {...props}>
          <Tooltip content="Build scan failed, please check provided parameters">
            <SvgIcon name="Warning" />
          </Tooltip>
        </Table.Cell>
      ) : data.scanDone ? (
        <RiskIconCell {...props} hasOverride={false} data={data} />
      ) : (
        <Table.Cell {...props}>
          <MotionAnimation size={3} width={2} margin={3} />
        </Table.Cell>
      ),
  },
  {
    label: 'Build start time',
    width: '65rem',
    Cell: ({ data, ...props }) => (
      <Table.Cell {...props}>
        <DateTime date={data.timestamp} format={dateFormats.longDatetime} />
      </Table.Cell>
    ),
  },
  {
    label: 'Governance rule violations',
    Cell: GovernanceRuleViolationsCell,
  },
  {
    label: 'Commit SHA',
    width: '95rem',
    Cell: ({ data, ...props }) => <Table.Cell {...props}>{data.commitSha}</Table.Cell>,
  },
];

const DocumentationLink = styled(ExternalLink)`
  color: var(--color-black);
`;
