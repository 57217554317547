import ChartRiskBadgeIconUrl from '@src-v2/assets/images/chart-risk-badge.svg';
import { getCssVariable } from '@src-v2/utils/style-utils';
import { SvgGraphNode } from '@src/cluster-map-work/components/charts/svg-graph-node';
import { riskTitles } from '@src/services/riskService';

export const NO_RISK_CLUSTER_NODE_COLOR = `--color-blue-gray-40`;
const GRAPH_NODE_ICON_SIZE = 14;
const SEARCH_HIGHLIGHT_RADIUS = 14;
const SELECTION_INDICATOR_RADIUS = 12;

const GRAPH_NODE_RISK_BADGE_SIZE = 5;

const RiskLevelBadges = {
  Critical: {
    svgUrl: ChartRiskBadgeIconUrl,
    size: GRAPH_NODE_RISK_BADGE_SIZE,
  },
  High: {
    svgUrl: ChartRiskBadgeIconUrl,
    size: GRAPH_NODE_RISK_BADGE_SIZE,
  },
  Medium: {
    svgUrl: ChartRiskBadgeIconUrl,
    size: GRAPH_NODE_RISK_BADGE_SIZE,
  },
  Low: {
    svgUrl: ChartRiskBadgeIconUrl,
    size: GRAPH_NODE_RISK_BADGE_SIZE,
  },
};

export function resourceRiskColor(riskLevel) {
  return riskLevel && getCssVariable(`--risk-color-${riskLevel.toLowerCase()}`);
}

export function getAllChartNodePresentationConfigs(chartViewPresentationConfig) {
  return Object.values(chartViewPresentationConfig.nodeTypes);
}

export function getChartNodePresentationConfig(chartViewPresentationConfig, nodeType) {
  const presentationConfig =
    chartViewPresentationConfig.nodeTypes[nodeType] || chartViewPresentationConfig.unknownNodeType;

  if (!presentationConfig.noRiskSvgNode) {
    presentationConfig.noRiskSvgNode = new SvgGraphNode(
      presentationConfig.iconUrl,
      GRAPH_NODE_ICON_SIZE,
      SELECTION_INDICATOR_RADIUS,
      SEARCH_HIGHLIGHT_RADIUS,
      getCssVariable(NO_RISK_CLUSTER_NODE_COLOR)
    );
  }

  if (!presentationConfig.svgNodesByRisk) {
    presentationConfig.svgNodesByRisk = {};
    riskTitles.forEach(riskColorKey => {
      presentationConfig.svgNodesByRisk[riskColorKey] = new SvgGraphNode(
        presentationConfig.iconUrl,
        GRAPH_NODE_ICON_SIZE,
        SELECTION_INDICATOR_RADIUS,
        SEARCH_HIGHLIGHT_RADIUS,
        resourceRiskColor(riskColorKey)
      );
    });
  }

  return presentationConfig;
}

export function getAllLinkPresentationConfigs(chartViewPresentationConfig) {
  return Object.values(chartViewPresentationConfig.linkTypes);
}

export function getLinkPresentationConfig(chartViewPresentationConfig, linkType) {
  return (
    chartViewPresentationConfig.linkTypes[linkType] || chartViewPresentationConfig.unknownLinkType
  );
}

export function getRiskLevelBadge(riskLevel) {
  const badge = RiskLevelBadges[riskLevel];

  if (!badge) {
    return badge;
  }

  if (!badge.svgImage) {
    badge.svgImage = new SvgGraphNode(badge.svgUrl, badge.size, 0, 0, resourceRiskColor(riskLevel));
  }

  return badge.svgImage;
}
