import { isObservable } from 'mobx';
import { forwardRef, useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { TippyAttributes } from '@src-v2/components/tooltips/tippy';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { DismissActionItems } from '@src-v2/containers/risks/actions/dismiss-action-items';
import { MessagingActionItems } from '@src-v2/containers/risks/actions/messaging-action-items';
import { TicketingActionItems } from '@src-v2/containers/risks/actions/ticketing-action-items';
import { useGroupProperties } from '@src-v2/hooks';
import { useModalState } from '@src-v2/hooks/use-modal-state';
import profilesService from '@src/services/profilesService';
import { dispatch } from '@src/store';

export const TakeActionsDropdown = forwardRef((props, ref) => {
  return <ActionDropdown {...props} ref={ref} title="Take action" items={TakeActionItems} />;
});

function TakeActionItems(props) {
  return (
    <>
      <MessagingActionItems {...props} />
      <TicketingActionItems {...props} />
    </>
  );
}

export const DismissActionsDropdown = forwardRef((props, ref) => {
  return <ActionDropdown {...props} ref={ref} title="Dismiss" items={DismissActionItems} />;
});

const ActionDropdown = styled(
  forwardRef(({ title, items: Items, disabled, data, onClose, ...props }, ref) => {
    const [popoverProps, buttonProps] = useGroupProperties(props, TippyAttributes);
    const [modalElement, setModal, closeModal] = useModalState();

    const handleClose = useCallback(async () => {
      onClose?.(data.triggerKey);

      if (!isObservable(data)) {
        await updateProfile(data?.profile ?? data);
      }

      closeModal();
    }, [data, onClose, closeModal]);

    return (
      <>
        <DropdownMenu.Popover
          {...popoverProps}
          noArrow
          appendTo="parent"
          trigger="mouseenter focus"
          popperOptions={{ strategy: 'fixed' }}
          content={<Items data={data} setModal={setModal} closeModal={handleClose} />}
          disabled={disabled}>
          <Button
            {...buttonProps}
            ref={ref}
            disabled={disabled}
            size="large"
            variant={Variant.PRIMARY}>
            {title}
          </Button>
        </DropdownMenu.Popover>
        {modalElement}
      </>
    );
  })
)`
  height: 7rem;
  cursor: default;
`;

async function updateProfile({ profileType, profileKey }) {
  const definitions = profilesService.getProfileDefinitionByType(profileType);
  const [profileTriggers] = await Promise.all([
    definitions.riskTriggersGetter(profileKey),
    definitions.profileGetter(profileKey),
  ]);
  dispatch.riskRuleTriggers.updateRuleTriggers({ profileTriggers });
}

export function createActionsData(trigger, rule, profile, relatedEntity, applications, riskName) {
  return {
    triggerKey: trigger.triggerKey ?? trigger.key,
    riskName: riskName ?? trigger.riskName,
    riskLevel: trigger.riskLevel,
    ruleKey: trigger.ruleKey ?? rule.key,
    ruleName: trigger.ruleName ?? rule.name,
    ruleRiskLevel: rule.risk ?? trigger.ruleRiskLevel,
    profileKey: profile.key,
    profileType: profile.profileType,
    apiiroLink: window.location.href,
    relatedEntity,
    applications,
  };
}
