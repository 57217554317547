import assign from 'lodash/assign';
import keyBy from 'lodash/keyBy';
import valuesIn from 'lodash/valuesIn';
import apiService from '@src/services/apiService';
import { processCodeProfileRelatedEntity } from '@src/utils/inventoryUtils';

const clearData = () => ({
  profiles: {},
  inventories: {},
  riskTriggers: {},
});

export default {
  state: clearData(),
  reducers: {
    setProjectProfiles: (state, projectProfiles) => ({
      ...state,
      profiles: assign(
        { ...state.profiles },
        keyBy(projectProfiles, profile => profile.key)
      ),
    }),

    setProjectInventory: (state, projectInventory) => ({
      ...state,
      inventories: assign(
        { ...state.inventories },
        keyBy([projectInventory], inventory => inventory.key)
      ),
    }),

    setProjectRiskTriggers: (state, projectRiskTriggers) => ({
      ...state,
      riskTriggers: assign(
        { ...state.riskTriggers },
        keyBy([projectRiskTriggers], riskTriggers => riskTriggers.key)
      ),
    }),
  },

  selectors: slice => ({
    projectProfiles: () => slice(projects => valuesIn(projects)),
    profilesByKey: () => slice(state => state?.profiles ?? {}),
    riskTriggersByKey: () => slice(state => state?.riskTriggers ?? {}),
    inventoriesByKey: () => slice(state => state?.inventories ?? {}),
  }),

  effects: () => ({
    async getProjectProfileAsync({ key, invalidateCache }) {
      const projectProfile = (
        await apiService.get(`/api/projects/${key}/profile`, { clearCacheEntry: invalidateCache })
      ).data;
      if (projectProfile) {
        this.setProjectProfiles([projectProfile]);
      }
      return projectProfile;
    },

    async getProjectRiskTriggersAsync({ key, invalidateCache }) {
      const projectRiskTriggers = (
        await apiService.get(`/api/projects/${key}/riskTriggers`, {
          clearCacheEntry: invalidateCache,
        })
      ).data;
      if (projectRiskTriggers) {
        processCodeProfileRelatedEntity(projectRiskTriggers);
        this.setProjectRiskTriggers(projectRiskTriggers);
      }
      return projectRiskTriggers;
    },

    addProjectProfiles({ projectProfiles }) {
      this.setProjectProfiles(projectProfiles);
    },
  }),
};
