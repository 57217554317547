import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { PageSpinner, StickyHeader } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { useInject, useQueryParams, useSuspense } from '@src-v2/hooks';
import { useFilters } from '@src-v2/hooks/use-filters';
import { safeParse } from '@src-v2/utils/json-utils';
import { ChartSearchControls } from '@src/cluster-map-work/components/charts/chart-search-controls';
import { ChartViewPresentationConfigLegend } from '@src/cluster-map-work/components/charts/chart-view-presentation-config-legend';
import {
  ServerFetchingClusterDisplayChart,
  useClusterChartSearchState,
  useServerFetchingClusterChart,
} from '@src/cluster-map-work/components/charts/cluster-chart/cluster-chart';
import { clusterChartViewPresentationConfig } from '@src/cluster-map-work/components/charts/cluster-chart/cluster-object-visuals';
import { InChartHeaderControls } from '@src/cluster-map-work/components/charts/graph-utils';

export default function ClusterPage() {
  const { clusters } = useInject();

  const { clusterKey } = useParams();
  const {
    queryParams: { selected, showProperties },
  } = useQueryParams();

  const selectedNodeDesc = useMemo(() => selected && safeParse(selected), [selected]);

  const chartFilterState = useFilters();
  const chartSearchState = useClusterChartSearchState({ clusterKey });
  const clusterChartState = useServerFetchingClusterChart({
    clusterKey,
    chartSearchState,
    chartFilterState,
    initialSelectedClusterResourceName: selectedNodeDesc,
  });

  const { serverFetchedChartState, clusterProfile } = clusterChartState;
  const clusterNamespaces = useSuspense(clusters.getClusterNamespaces, { clusterKey });

  const clusterFilterOptions = useMemo(
    () => [
      {
        key: 'Namespaces',
        title: 'Namespaces',
        type: 'checkbox',
        options: clusterNamespaces.map(ns => ({ value: ns, title: ns })),
      },
      {
        key: 'NodeTypes',
        title: 'Elements',
        type: 'checkbox',
        options: Object.entries(clusterChartViewPresentationConfig.nodeTypes).map(
          ([resourceType, presConf]) => ({
            value: resourceType,
            title: presConf.friendlyName,
          })
        ),
      },
    ],
    [chartSearchState.filters, clusterNamespaces]
  );

  return (
    <Page title={`Cluster ${clusterProfile.name}`}>
      <StickyHeader
        title={
          <ClusterTitle>
            <ClusterTitleSpan>Cluster {clusterProfile.name}</ClusterTitleSpan>
            {clusterProfile.accountId && (
              <ClusterTitleSpan>Account ID: {clusterProfile.accountId}</ClusterTitleSpan>
            )}
          </ClusterTitle>
        }
        minimized
      />

      <ChartContainer>
        <AsyncBoundary pendingFallback={<PageSpinner />}>
          <ServerFetchingClusterDisplayChart
            clusterKey={clusterKey}
            serverFetchedClusterChartState={clusterChartState}
            initialShowProperties={showProperties === 'true'}
            chartProps={{
              overviewSize: { width: '38rem', height: '38rem' },
            }}
          />
        </AsyncBoundary>

        <InChartHeaderControls>
          <ChartSearchControls
            chartSearchState={chartSearchState}
            filterOptions={clusterFilterOptions}
            elementCount={serverFetchedChartState.graphData.nodes.length}
          />
          <ClusterChartLegendContainer>
            <ChartViewPresentationConfigLegend
              chartViewPresentationConfig={clusterChartViewPresentationConfig}
            />
          </ClusterChartLegendContainer>
        </InChartHeaderControls>
      </ChartContainer>
    </Page>
  );
}

const ChartContainer = styled.div`
  position: relative;
  height: calc(100vh - 26rem);
  overflow: hidden;
`;

const ClusterChartLegendContainer = styled.div`
  margin: 0 7rem;
`;

const ClusterTitleSpan = styled.span``;

const ClusterTitle = styled.div`
  display: flex;
  flex-direction: column;

  ${ClusterTitleSpan}:last-child {
    font-size: var(--font-size-m);
    font-weight: 300;
  }
`;
