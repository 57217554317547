import { forwardRef, useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IconButton, InlineButton, PlainButton } from '@src-v2/components/buttons';
import { Checkbox } from '@src-v2/components/forms';
import { BaseIcon } from '@src-v2/components/icons';
import { Popover } from '@src-v2/components/tooltips/popover';
import { ListItem, UnorderedList } from '@src-v2/components/typography';
import { useToggle } from '@src-v2/hooks';

export function OptionalFieldsSelect({
  fields,
  checkedFieldKeys: initKeys = [],
  itemToString,
  onChange,
}) {
  const [isVisible, toggleVisible] = useToggle();
  const [checkedKeys, setCheckedKeys] = useState([]);

  useEffect(() => setCheckedKeys(initKeys), [initKeys, setCheckedKeys]);

  const handleCheckItem = useCallback(
    field => {
      if (checkedKeys?.includes(field.key)) {
        setCheckedKeys(checkedKeys.filter(key => key !== field.key));
      } else {
        setCheckedKeys([...checkedKeys, field.key]);
      }
    },
    [checkedKeys, setCheckedKeys]
  );

  const handleDone = useCallback(() => {
    onChange?.(checkedKeys);
    toggleVisible(false);
  }, [checkedKeys, onChange]);

  return (
    <FieldsPopover
      noArrow
      placement="top-start"
      content={
        <>
          <UnorderedList>
            {fields.map(field => (
              <ListItem key={field.key}>
                <Label>
                  <Checkbox
                    checked={checkedKeys?.includes(field.key)}
                    onChange={() => handleCheckItem(field)}
                  />
                  {itemToString(field)}
                </Label>
              </ListItem>
            ))}
          </UnorderedList>

          <InlineButton data-status="primary" onClick={handleDone}>
            Done
          </InlineButton>
        </>
      }
      visible={isVisible}
      onClickOutside={toggleVisible}>
      <FieldsSelectorButton onClick={toggleVisible} />
    </FieldsPopover>
  );
}

const FieldsSelectorButton = styled(
  forwardRef((props, ref) => (
    <PlainButton {...props} ref={ref}>
      <IconButton name="PlusSmall" />
      Add more fields
    </PlainButton>
  ))
)`
  display: flex;
  align-items: center;
  gap: 2rem;

  &:hover {
    color: var(--color-blue-gray-80);
  }

  ${BaseIcon} {
    color: var(--color-white);
    border-radius: 100vmax;
    background-color: var(--color-blue-gray-70);
  }
`;

const FieldsPopover = styled(Popover)`
  ${Popover.Content} {
    display: flex;
    min-width: 55rem;
    flex-direction: column;

    ${UnorderedList} {
      padding: 3rem 0;
      border-bottom: 0.25rem solid var(--color-blue-gray-25);
    }

    > ${InlineButton} {
      align-self: flex-end;
      height: 6rem;
    }
  }
`;

const Label = styled.label`
  display: block;
  width: 100%;
  cursor: pointer;
  font-size: var(--font-size-s);

  ${Checkbox} {
    margin-right: 3rem;
  }
`;
