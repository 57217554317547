import _ from 'lodash';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { Number } from '@src/components/Number';
import { normalizeProfileType } from '@src/services/profilesService';
import { riskOrder } from '@src/services/riskService';
import { Ellipsis, FontBody } from '@src/style/common';
import { HorizontalStack } from './HorizontalStack';
import RiskIcon from './RiskIcon';
import TooltippedEllipsis from './TooltippedEllipsis';

const StyledHorizontalStack = styled(HorizontalStack)`
  ${Ellipsis};
`;

const riskDescriptionsByName = riskFactor => (
  <StyledHorizontalStack>
    <TooltippedEllipsis text={riskFactor.ruleName} />
    <span>
      {' '}
      &middot; <Number value={riskFactor.instances} />
    </span>
  </StyledHorizontalStack>
);

const StyledItem = styled(HorizontalStack)`
  ${FontBody};
  ${Ellipsis};
  padding-bottom: 3rem;

  &:hover div div {
    text-decoration: underline;
  }
`;

const StyledList = styled.div`
  display: flex;
  flex-direction: column;
`;

const ItemsContainer = styled.div`
  padding-bottom: 3rem;
  margin-bottom: 3rem;
  border-bottom: 0.25rem solid var(--color-blue-gray-35);

  &:empty {
    display: none;
  }

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
    border-bottom: 0;
  }

  ${StyledItem}:last-of-type {
    padding-bottom: 0;
  }
`;

const riskFactorsList = (riskFactors, consumableProfile, deepLinkToRiskTrigger) => {
  const modulePath = consumableProfile?.module?.root
    ? `/${encodeURIComponent(consumableProfile?.module?.root)}`
    : '';
  return _.orderBy(riskFactors, [riskFactor => riskOrder[riskFactor.riskLevel]], ['desc'])
    .map(riskFactor => ({
      ruleKey: riskFactor.ruleKey,
      riskLevel: riskFactor.riskLevel,
      description: riskDescriptionsByName(riskFactor),
      ruleName: riskFactor.ruleName,
      instances: riskFactor.instances,
    }))
    .map((riskFactor, index) => (
      <StyledItem key={index}>
        <RiskIcon riskLevel={riskFactor.riskLevel} />
        {deepLinkToRiskTrigger && consumableProfile.profileType ? (
          <NavLink
            to={`/${normalizeProfileType(consumableProfile.profileType)}/${
              consumableProfile.key
            }${modulePath}/risk/development/ruleTriggers/${riskFactor.ruleKey}`}>
            {riskFactor.description}
          </NavLink>
        ) : (
          <span>
            {riskFactor.ruleName} &middot; <Number value={riskFactor.instances} />
          </span>
        )}
      </StyledItem>
    ));
};

const addNonRiskFactorsSummary = (totalMatches, totalRulesWithMatches, riskLevel) =>
  totalMatches > 0 && (
    <StyledItem key={riskLevel}>
      <RiskIcon riskLevel={riskLevel} />
      <TooltippedEllipsis
        text={
          <span>
            <Number
              one={`${riskLevel} risk match`}
              other={`${riskLevel} risk matches`}
              value={totalMatches}
            />{' '}
            in <Number one="rule" other="rules" value={totalRulesWithMatches} />
          </span>
        }
      />
    </StyledItem>
  );

export const RiskFactors = ({ riskDescriptor }) => {
  if (riskDescriptor.combinedRiskLevel === 'None') {
    return null;
  }

  return (
    <StyledList>
      <ItemsContainer>
        {riskFactorsList(riskDescriptor.riskFactors, riskDescriptor.consumableProfile, true)}
      </ItemsContainer>
      <ItemsContainer>
        {addNonRiskFactorsSummary(
          riskDescriptor.totalAcceptedMatches,
          riskDescriptor.totalRulesWithAcceptedMatches,
          'Accepted'
        )}
        {addNonRiskFactorsSummary(
          riskDescriptor.totalIgnoredMatches,
          riskDescriptor.totalRulesWithIgnoredMatches,
          'Ignored'
        )}
      </ItemsContainer>
    </StyledList>
  );
};
