import { Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';
import { thenSubTypeToVerb } from '@src-v2/containers/commit/material-changes-utils';

export const GqlMaterialChange = ({ materialChange, thenSubType, repository, commitSha, type }) => {
  const { relativeFilePath, lineNumber } = materialChange.codeReference;

  return (
    <>
      <MaterialChangesRow>
        <Paragraph>GraphQL {type === 'GqlObject' && 'Object'} </Paragraph>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}
          lineNumber={lineNumber}>
          {type === 'GqlObject'
            ? materialChange.gqlObjectTypeName
            : materialChange.gqlOperationType}
        </CommitCodeReference>
        <Paragraph>was</Paragraph>
        <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>
      </MaterialChangesRow>
      <MaterialChangesRow>
        {materialChange.descriptions.map(description => (
          <Paragraph>{description}</Paragraph>
        ))}
      </MaterialChangesRow>
    </>
  );
};
