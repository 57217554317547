import { connect } from 'react-redux';
import { select } from '@src/store';
import DeveloperPage from './view';

const mapState = select(
  ({ developerPage: { hasFetchedData }, developerProfiles: { profileGetter } }) => ({
    hasFetchedData,
    profileGetter,
  })
);

const mapDispatch = ({ developerPage: { fetchData, clearData } }) => ({
  fetchData,
  clearData,
});

export default connect(mapState, mapDispatch)(DeveloperPage);
