import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import sortBy from 'lodash/sortBy';
import { useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { Popover } from '@src-v2/components/tooltips/popover';
import { Heading } from '@src-v2/components/typography';
import { useDetectClickOutside, useLegacyQueryParams, useToggle } from '@src-v2/hooks';
import { toggleValues } from '@src-v2/utils/collection-utils';
import { dataAttr } from '@src-v2/utils/dom-utils';
import DateRangePicker from '@src/components/DayPicker';
import { Number } from '@src/components/Number';
import { FontBody, FontBodyBold } from '@src/style/common';
import { Button } from './Button';
import { FilterButton } from './FilterButton';
import { HorizontalStack } from './HorizontalStack';
import { VerticalStack } from './VerticalStack';

const StyledHorizontalStack = styled(HorizontalStack)`
  margin: -3rem 0 0;
  justify-content: flex-start;
  flex-wrap: wrap;
  row-gap: 5px;

  & > * {
    margin-top: 3rem;
  }
`;

const StyledVerticalStack = styled(VerticalStack)`
  padding: 6rem 0 12rem;
  gap: 4rem;
`;

const SelectFilterButton = styled(Button)`
  ${FontBody};
  background-color: transparent;
  color: var(--default-text-color);
  border: ${props => (props.isSelected ? '1px solid' : 0)};
  border-radius: 18px;
  font-weight: ${props => (props.isSelected ? 500 : 'unset')};

  &:hover {
    background-color: var(--color-blue-gray-15);
  }

  ${props =>
    props.isSelected &&
    props.isSecondary &&
    `
    &&, &&:hover {
      ${FontBodyBold};
      color: var(--color-white);
      background-color: var(--color-blue-gray-70);
    }
  `}
`;

const StyledDropdownButton = styled.div`
  background-color: ${props => (props.isOpen ? 'var(--color-blue-gray-20)' : 'none')};
  border-radius: 50%;
  padding: 6px;

  :hover {
    background-color: var(--color-blue-gray-20);
  }
`;

export const StyledNumber = styled(Number)`
  ${FontBody};
`;

const FilterBody = ({ icon, name, count }) => (
  <HorizontalStack spacing={0}>
    {icon}
    {name} &middot;&nbsp;
    <StyledNumber value={count} />
  </HorizontalStack>
);

const filterToString = filter => (isNil(filter) ? '' : `${filter.name} (${filter.count})`);

export const FilterButtons = ({
  className,
  defaultFilter,
  onFilterChanged,
  filterToCountMapping,
  extraFilterToCountMapping,
  multiselect,
  isSecondary,
  hasDateRange,
  children,
}) => {
  const popoverRef = useRef();
  const popoverButtonRef = useRef();
  const query = useLegacyQueryParams();
  const [selectedValues, setSelectedValues] = useState([]);
  const [multiSelectOpen, toggleMultiSelectOpen] = useToggle(false);
  const [selectedFilters, setSelectedFilters] = useState(query.labels ?? []);
  const [selectedDateRange, setSelectedDateRange] = useState([]);

  useDetectClickOutside([popoverRef, popoverButtonRef], toggleMultiSelectOpen, multiSelectOpen);

  useEffect(() => {
    const values = selectedFilters.map(selectedFilter =>
      filterToString(extraFilterToCountMapping?.find(f => f.name === selectedFilter))
    );
    if (!isNil(values)) {
      setSelectedValues(values);
    }
  }, [selectedFilters, extraFilterToCountMapping]);

  useEffect(() => {
    onFilterChanged?.(selectedFilters, selectedDateRange[0], selectedDateRange[1]);
  }, [selectedFilters, selectedDateRange, onFilterChanged]);

  useEffect(() => {
    setSelectedFilters(query.labels ?? []);
  }, [query.labels, setSelectedFilters]);

  useEffect(() => {
    if (!isNil(query.fromDate) && !isNil(query.toDate)) {
      setSelectedDateRange([
        new Date(`${query.fromDate}T12:00:00Z`),
        new Date(`${query.toDate}T12:00:00Z`),
      ]);
    }
  }, [query, setSelectedDateRange]);

  const isMulti = extraFilterToCountMapping?.some(e => selectedFilters.includes(e.name));

  const toggleSelectedFilter = name => setSelectedFilters(toggleValues(selectedFilters, name));

  const removeDateRange = () => setSelectedDateRange([]);

  const dateRangeLabel = useMemo(() => {
    if (!selectedDateRange.length) {
      return 'Select time range ';
    }

    let label = selectedDateRange[0].toLocaleDateString();
    if (selectedDateRange[1]) {
      label += ` - ${selectedDateRange[1].toLocaleDateString()}`;
    }

    return label;
  }, [selectedDateRange]);

  // If default selection not specified, use the first non-empty one (or first one, if all are empty)
  const firstNonEmptyIndex = findIndex(filterToCountMapping, ({ count }) => count > 0) || 0;
  const isNonDefaultFilterSelected = (name, index) =>
    selectedFilters.includes(name) ||
    (isEmpty(selectedFilters) && !defaultFilter && index === firstNonEmptyIndex);

  if (!defaultFilter && isEmpty(selectedFilters)) {
    toggleSelectedFilter(filterToCountMapping[firstNonEmptyIndex].name);
  }

  return (
    <>
      <StyledVerticalStack className={className}>
        <StyledHorizontalStack>
          {defaultFilter && (
            <SelectFilterButton
              data-active={dataAttr(isEmpty(selectedFilters))}
              isSelected={isEmpty(selectedFilters)}
              isSecondary={isSecondary}
              onClick={() => setSelectedFilters([])}>
              <FilterBody {...defaultFilter} />
            </SelectFilterButton>
          )}
          {filterToCountMapping?.map(({ icon, name, count }, index) => (
            <SelectFilterButton
              key={name}
              data-active={dataAttr(isNonDefaultFilterSelected(name, index))}
              isSelected={isNonDefaultFilterSelected(name, index)}
              isSecondary={isSecondary}
              onClick={() =>
                multiselect ? toggleSelectedFilter(name) : setSelectedFilters([name])
              }>
              <FilterBody icon={icon} name={name} count={count} />
            </SelectFilterButton>
          ))}
          {extraFilterToCountMapping?.length > 0 && (
            <FiltersPopover
              visible={multiSelectOpen}
              placement="bottom-start"
              content={
                <FiltersPopoverContainer ref={popoverRef}>
                  <Heading>
                    Filter commits by material changes
                    <SvgIcon name="Close" onClick={toggleMultiSelectOpen} />
                  </Heading>
                  {hasDateRange && (
                    <DateRangePicker
                      selectedDateRange={selectedDateRange}
                      setSelectedDateRange={setSelectedDateRange}
                      dateRangeLabel={dateRangeLabel}
                      resetSelected={removeDateRange}
                    />
                  )}
                  <StyledHorizontalStack>
                    {sortBy(extraFilterToCountMapping, 'name')
                      .map(filter => ({
                        name: filterToString(filter),
                        action: () => toggleSelectedFilter(filter.name),
                      }))
                      .map(item => (
                        <FilterButton
                          key={item.name}
                          outlined
                          isSelected={selectedValues.includes(item.name)}
                          onClick={() => {
                            setSelectedValues(selectedValues =>
                              toggleValues(selectedValues, item.name)
                            );
                            item.action();
                          }}>
                          <span>{item.name}</span>
                        </FilterButton>
                      ))}
                  </StyledHorizontalStack>
                </FiltersPopoverContainer>
              }>
              {isMulti ? (
                <MultiSelectedButton ref={popoverButtonRef} onClick={toggleMultiSelectOpen}>
                  <SvgIcon name="Filter" /> Selected Custom Filters
                </MultiSelectedButton>
              ) : (
                <StyledDropdownButton
                  ref={popoverButtonRef}
                  isOpen={multiSelectOpen && !isMulti}
                  onClick={toggleMultiSelectOpen}>
                  <SvgIcon name="Filter" />
                </StyledDropdownButton>
              )}
            </FiltersPopover>
          )}
        </StyledHorizontalStack>
        {multiselect && !filterToCountMapping && (
          <StyledHorizontalStack>
            {selectedDateRange.length === 2 && (
              <FilterButton isSelected onClose={removeDateRange}>
                {dateRangeLabel}
              </FilterButton>
            )}
            {selectedFilters.map(filter => (
              <FilterButton isSelected key={filter} onClose={() => toggleSelectedFilter(filter)}>
                {filter}
              </FilterButton>
            ))}
          </StyledHorizontalStack>
        )}
      </StyledVerticalStack>
      {children(multiselect ? selectedFilters : selectedFilters[0], selectedDateRange)}
    </>
  );
};

const FiltersPopover = styled(Popover)`
  ${Popover.Content} {
    min-width: 40rem;
    max-width: 140rem;
    max-height: 50vh;
    padding: 6rem;
  }
`;

const FiltersPopoverContainer = styled.div`
  > ${Heading} {
    margin-bottom: 6rem;

    ${BaseIcon} {
      float: right;
    }
  }
`;

const MultiSelectedButton = styled.span`
  padding: 1rem 2rem;
  border: 0.25rem solid var(--color-black);
  border-radius: 100vmax;
  user-select: none;
`;
