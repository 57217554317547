import styled from 'styled-components';
import { IconButton } from '@src-v2/components/buttons';
import { BaseIcon } from '@src-v2/components/icons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import {
  Ellipsis,
  FontBody,
  FontBodyBold,
  FontBodySmall,
  FontBodySmallBold,
} from '@src/style/common';
import { HorizontalStack } from './HorizontalStack';

const StyledFilter = styled.span`
  ${props => (props.small ? FontBodySmall : FontBody)};
  ${props => props.outlined && `box-shadow: inset 0 0 0 1px rgba(186, 202, 213, 0.3);`};
  border-radius: 18px;
  display: flow-root;
  overflow: hidden;
  max-width: fit-content;
  padding: 3px 3rem;
  font-size: var(--font-size-s);
  ${props => props.onClick && 'cursor: pointer;'};

  ${props =>
    props.isSelected &&
    `
      ${props => (props.small ? FontBodySmallBold : FontBodyBold)};
      color: ${props.lightSchema ? 'var(--default-text-color)' : 'var(--color-white)'};
      background-color: ${
        props.highlightBorder
          ? 'var(--color-green-40)'
          : props.lightSchema
            ? 'var(--color-white)'
            : 'var(--color-blue-gray-75)'
      };
    `};

  &:hover {
    color: ${props => props.outlined && 'var(--default-text-color)'};
    background-color: ${props => props.outlined && 'var(--color-blue-gray-20)'};
  }

  ${BaseIcon} {
    width: 5rem;
    height: 5rem;
  }
`;

const FilterContent = styled(Tooltip)`
  ${Ellipsis};
  display: flex;
  max-width: 100%;
`;

export function FilterButton({
  isSelected,
  outlined,
  small,
  lightSchema,
  highlightBorder,
  onClick,
  children,
  onClose,
}) {
  return (
    <StyledFilter
      highlightBorder={highlightBorder}
      lightSchema={lightSchema}
      onClick={onClick}
      outlined={outlined}
      isSelected={isSelected}
      small={small}>
      <HorizontalStack spacing="0">
        <FilterContent content={children}>
          <span>{children}</span>
        </FilterContent>
        {onClose && <IconButton name="Close" onClick={onClose} />}
      </HorizontalStack>
    </StyledFilter>
  );
}
