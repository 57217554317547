import isEmpty from 'lodash/isEmpty';
import { Fragment } from 'react';
import styled from 'styled-components';
import { CircleButton } from '@src-v2/components/button-v2';
import { CircleIcon } from '@src-v2/components/circle-icon';
import { SvgIcon } from '@src-v2/components/icons';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { Conjunction } from '@src/components/Rule/RuleOption';
import rulesService from '@src/services/rulesService';
import Option from './Option';

const StyledOption = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 6px;
`;

const OptionGroup = ({
  isReadOnly,
  selected,
  options,
  setSelection,
  addOption,
  removeOption,
  validationErrors,
  addProperty,
  removeProperty,
  setProperty,
  preventFirstItemRemoval,
}) => {
  const lastIndex = selected.length - 1;
  const isSingleOption = selected.length === 1;
  const additionalOptions = rulesService.filterOptions(options, selected);
  const canAddMore = !isEmpty(additionalOptions);

  return (
    <StyledOption>
      {selected.map((selectedOption, index) => (
        <Fragment key={index}>
          <Option
            disabled={isReadOnly}
            isSingleOption={isSingleOption}
            selected={selectedOption}
            validationError={validationErrors?.find(
              validationError => validationError.rulePortionIndex === index
            )}
            options={
              index ? rulesService.filterOptions(options, selected, selectedOption) : options
            }
            setSelection={modifications => setSelection({ ...modifications, index })}
            removeOption={
              !preventFirstItemRemoval || index > 0 ? () => removeOption({ index }) : null
            }
            addProperty={modifications => addProperty({ ...modifications, optionIndex: index })}
            removeProperty={modifications =>
              removeProperty({ ...modifications, optionIndex: index })
            }
            setProperty={modifications => setProperty({ ...modifications, optionIndex: index })}
          />
          {index === lastIndex ? (
            !isReadOnly &&
            canAddMore && (
              <CircleButton
                variant={Variant.TERTIARY}
                size="medium"
                onClick={addOption}
                testMarker="addOption">
                <SvgIcon name="Plus" size="xsmall" />
              </CircleButton>
            )
          ) : (
            <Conjunction>
              <CircleIcon disabled icon="Plus" variant={Variant.TERTIARY} size="medium" />
            </Conjunction>
          )}
        </Fragment>
      ))}
    </StyledOption>
  );
};

export default OptionGroup;
