import { useRef, useState } from 'react';
import { useInject } from '@src-v2/hooks/use-inject';
import { useLoading } from '@src-v2/hooks/use-loading';
import { useDebounce } from '@src-v2/hooks/use-lodash';

/**
 * @param asyncHandler  expected to return a promise
 * @param wait          debounce wait option
 * @param ttl           AsyncCache time-to-live
 * @param options       debounce remaining options
 */
export function useTypeahead(asyncHandler, { wait = 200, ttl = 180, ...options } = {}) {
  const abortRequest = useRef();
  const [results, setResults] = useState([]);
  const { apiClient, asyncCache } = useInject();
  const [handleSearch, loading, error] = useLoading(
    async params => {
      const { token, cancel } = apiClient.createCancelToken();
      abortRequest.current?.('cancelled by useTypeahead');
      abortRequest.current = cancel;
      setResults(await asyncCache.suspend(asyncHandler, params, ttl, { cancelToken: token }));
    },
    [asyncCache, asyncHandler, ttl]
  );
  return [
    useDebounce(handleSearch, { wait, ...options }, [handleSearch]),
    results,
    loading,
    apiClient.isCancel(error) ? null : error,
  ];
}
