import _ from 'lodash';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Dropdown } from '@src-v2/components/dropdown';
import { SvgIcon, VendorIcon } from '@src-v2/components/icons';
import { Paragraph } from '@src-v2/components/typography';
import {
  GoogleChatBulkMessageModal,
  SlackBulkMessageModal,
  TeamsBulkMessageModal,
} from '@src-v2/containers/modals/messaging/providers-bulk-message-modals';
import {
  GoogleChatMessageModal,
  SlackMessageModal,
  TeamsMessageModal,
} from '@src-v2/containers/modals/messaging/providers-message-modals';
import { useInject } from '@src-v2/hooks';
import { Messaging } from '@src-v2/services';

export const MessagingActionItems = observer(({ data, setModal, closeModal }) => {
  const {
    application: { integrations },
  } = useInject();

  const {
    handleConnectCommunication,
    handleSendSlackMessage,
    handleSendTeamsMessage,
    handleSendGoogleChatMessage,
  } = useMessagingActionHandlers(data, setModal, closeModal);

  return (
    <>
      {!integrations.connectedToSlack &&
        !integrations.connectedToTeams &&
        !integrations.connectedToGoogleChat && (
          <Dropdown.Item onClick={handleConnectCommunication}>
            <SvgIcon name="Messages" />
            Send a Message
          </Dropdown.Item>
        )}

      {integrations.connectedToSlack && (
        <Dropdown.Item onClick={handleSendSlackMessage}>
          <VendorIcon name="Slack" />
          Send a Slack message
        </Dropdown.Item>
      )}
      {integrations.connectedToTeams && (
        <Dropdown.Item onClick={handleSendTeamsMessage}>
          <VendorIcon name="Teams" />
          Send a Teams message
        </Dropdown.Item>
      )}
      {integrations.connectedToGoogleChat && (
        <Dropdown.Item onClick={handleSendGoogleChatMessage}>
          <VendorIcon name="GoogleChat" />
          Send a Google Chat message
        </Dropdown.Item>
      )}
    </>
  );
});

export function useMessagingActionHandlers(data, setModal, closeModal) {
  const { messaging, asyncCache } = useInject();
  const trackAnalytics = useTrackAnalytics();
  const history = useHistory();

  return useMemo(() => {
    const shouldOpenBulkModal = data.selection?.length > 1;
    const singleRiskData = _.first(data.selection) ?? data;

    return {
      handleConnectCommunication() {
        setModal(
          <ConfirmationModal
            title="Add a communication connector"
            submitText="Continue"
            onClose={closeModal}
            onSubmit={() => history.push('/connectors/connect#ticketingSystems')}>
            <>
              <Paragraph>
                Sending a message requires at least one communication connector.
              </Paragraph>
              <Paragraph>
                Click 'Continue' to view your connectors page and complete this process.
              </Paragraph>
            </>
          </ConfirmationModal>
        );
      },
      async handleSendSlackMessage() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Send Slack message',
        });
        const channels = await asyncCache.suspend(messaging.getChannels, {
          provider: Messaging.TYPE_SLACK,
        });
        setModal(
          shouldOpenBulkModal ? (
            <SlackBulkMessageModal dataModel={data} channels={channels} onClose={closeModal} />
          ) : (
            <SlackMessageModal riskData={singleRiskData} channels={channels} onClose={closeModal} />
          )
        );
      },
      async handleSendTeamsMessage() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Send Teams message',
        });
        const channels = await asyncCache.suspend(messaging.getChannels, {
          provider: Messaging.TYPE_TEAMS,
        });
        setModal(
          shouldOpenBulkModal ? (
            <TeamsBulkMessageModal dataModel={data} channels={channels} onClose={closeModal} />
          ) : (
            <TeamsMessageModal riskData={singleRiskData} channels={channels} onClose={closeModal} />
          )
        );
      },
      async handleSendGoogleChatMessage() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Send Google Chat message',
        });
        const channels = await asyncCache.suspend(messaging.getChannels, {
          provider: Messaging.TYPE_GOOGLE_CHAT,
        });
        setModal(
          shouldOpenBulkModal ? (
            <GoogleChatBulkMessageModal dataModel={data} channels={channels} onClose={closeModal} />
          ) : (
            <GoogleChatMessageModal
              riskData={singleRiskData}
              channels={channels}
              onClose={closeModal}
            />
          )
        );
      },
    };
  }, [data, setModal, closeModal, trackAnalytics]);
}
