import _ from 'lodash';
import styled from 'styled-components';
import { Circle } from '@src-v2/components/circles';
import { Divider } from '@src-v2/components/divider';
import { SvgIcon } from '@src-v2/components/icons';
import { Popover } from '@src-v2/components/tooltips/popover';
import { useInject } from '@src-v2/hooks';
import { DescriptionBox } from '@src/components/DescriptionBox';
import { Number } from '@src/components/Number';
import { FontBody } from '@src/style/common';
import { HorizontalStack } from './HorizontalStack';
import { Link } from './Link';
import { RiskBar } from './RiskBar';
import { RiskSummary } from './RiskSummary';

const StyledName = styled.div`
  ${FontBody};
`;

const StyledNameWithRisk = styled(StyledName)`
  position: relative;
  align-self: stretch;
  display: flex;
  align-items: center;
`;

const StyledRiskBar = styled(RiskBar)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
`;

const StyledBackground = styled.div`
  display: inline-block;
  height: 100%;
  width: 15rem;
  transition: 0.2s ease-out;
  position: absolute;
  border-radius: 1rem;
  background-color: ${props => props.backgroundColor ?? 'var(--color-blue-gray-20)'};
`;

const StyledNumberContainer = styled.div`
  display: flex;
  flex: 0 0 15rem;
  justify-content: center;
  align-items: center;
  height: 15rem;
  z-index: 10;
`;

const StyledNumber = styled(Number)`
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
  color: ${props => props.textColor} !important;
`;

const StyledCircle = styled(Circle)`
  margin-left: auto;
  color: var(--default-text-color);
  background-color: var(--color-white);
  transition: opacity 400ms;
  opacity: 0;
`;

const StyledHorizontalStack = styled(HorizontalStack)`
  width: 100%;
`;

const StyledItemContainer = styled.div`
  display: flex;
  flex: 0 0 31%;
  margin-bottom: 6rem;
  margin-right: 3rem;
  padding-right: 3rem;
  border-radius: 1rem;
  position: relative;
  cursor: pointer;

  @media (min-width: 1440px) {
    flex: 0 0 23%;
  }

  &:hover {
    ${StyledCircle} {
      opacity: 1;
    }

    ${StyledName} {
      color: ${props => props.textColor};
    }

    ${StyledBackground} {
      width: 100%;
    }
  }
`;

function Statistic({ item }) {
  const hasRiskDescription = item.hasRisk && _.isNumber(item.value) && item.value > 0;
  return (
    <StatisticPopover
      trigger="click"
      placement="bottom-start"
      content={
        <DescriptionBox
          spreadTitle={!item.tip}
          title={
            <>
              {item.tip ? null : item.popoverTitle ?? item.name}
              {item.url && (
                <Link url={item.url} underline>
                  View all
                </Link>
              )}
            </>
          }>
          {item.tip}
          {item.popover || hasRiskDescription ? <Divider /> : null}
          {item.popover}
          {item.valuesByRisk && (
            <RiskSummary riskUrl={item.riskUrl} countByLevel={item.valuesByRisk} />
          )}
        </DescriptionBox>
      }>
      <StyledItemContainer textColor={item.textColor}>
        <StyledHorizontalStack>
          <StyledBackground backgroundColor={item.backgroundColor} />
          <StyledNumberContainer>
            <StyledNumber value={item.value || 0} textColor={item.textColor} shouldAbbreviate />
          </StyledNumberContainer>
          <StyledNameWithRisk>
            {item.name}
            {item.hasRisk && _.isNumber(item.value) ? (
              <StyledRiskBar
                name={item.name}
                totalCount={item.value}
                countByLevel={item.valuesByRisk}
              />
            ) : null}
          </StyledNameWithRisk>
          <StyledCircle size="xsmall">
            <SvgIcon name="Chevron" />
          </StyledCircle>
        </StyledHorizontalStack>
      </StyledItemContainer>
    </StatisticPopover>
  );
}

export function Statistics({ items, className, maxItems = 10 }) {
  const { application } = useInject();
  return (
    <StyledDiv className={className}>
      {items
        .filter(item => application.isDemo || !item.isDemo)
        .slice(0, maxItems)
        .map(item => (
          <Statistic item={item} key={item.name} />
        ))}
    </StyledDiv>
  );
}

const StyledDiv = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const StatisticPopover = styled(Popover)`
  ${Popover.Content} {
    min-width: 90rem;
  }
`;
