import { observer } from 'mobx-react';
import { useEffect } from 'react';
import { ProfilesProvider } from '@src-v2/components/profiles-inject-context';
import { useInject } from '@src-v2/hooks';
import { useBreadcrumbs } from '@src-v2/hooks/use-breadcrumbs';
import { PageLoading } from '@src/components/PageLoading';
import {
  getApisStatistics,
  getPiisStatistics,
  getSecretsStatistics,
  getTechnologiesStatistics,
} from '@src/components/RiskProfiles/ProfileStatistics';
import RiskProfilePage from '@src/components/RiskProfiles/RiskProfilePage';

const ModulePage = observer(
  ({
    fetchData,
    clearData,
    hasFetchedData,
    moduleProfile,
    moduleRiskTriggers,
    repositoryKey,
    governanceRulesByKey,
    moduleKey,
  }) => {
    const { foreignEntities, repositoryProfiles } = useInject();

    useEffect(() => {
      fetchData({ key: repositoryKey, invalidateCache: false, moduleKey });
      return clearData;
    }, [fetchData, repositoryKey, clearData, moduleKey]);

    useBreadcrumbs({
      breadcrumbs: [
        {
          label: moduleProfile?.repository.name ?? '...',
          to: `/profiles/repositories/${repositoryKey}`,
        },
        {
          label: 'Modules',
          to: `/profiles/repositories/${repositoryKey}/inventory/components/modules`,
        },
      ],
    });

    if (!hasFetchedData) {
      return <PageLoading />;
    }

    return (
      <ProfilesProvider value={repositoryProfiles}>
        <RiskProfilePage
          fetchData={fetchData}
          clearData={clearData}
          hasFetchedData={hasFetchedData}
          profileKey={repositoryKey}
          profile={moduleProfile}
          profileRiskTriggers={moduleRiskTriggers}
          statistics={
            moduleProfile && [
              getTechnologiesStatistics(moduleProfile),
              getApisStatistics(moduleProfile),
              getPiisStatistics(moduleProfile),
              getSecretsStatistics(moduleProfile),
            ]
          }
          title={moduleProfile?.module?.name}
          governanceRulesByKey={governanceRulesByKey}
          subEntityKey={moduleKey}
          profilePageUrl={`/module/${repositoryKey}/${moduleKey}`}
          profilesPage="/profiles/repositories"
          profileType="module"
          profileTypePlural="repositories"
          foreignEntityLinkTarget={foreignEntities.createModuleLinkTarget(
            repositoryKey,
            moduleProfile?.module?.name,
            moduleProfile?.module?.key
          )}
          server={moduleProfile?.repository.server}
        />
      </ProfilesProvider>
    );
  }
);

export default ModulePage;
