const clearData = () => ({});

export default {
  state: clearData(),
  reducers: {
    setHasFetchedData: (state, hasFetchedData) => ({
      ...state,
      hasFetchedData,
    }),
    setUnmonitored: (state, unmonitored) => ({
      ...state,
      unmonitored,
    }),
    clearData,
  },
  selectors: (slice, createSelector) => ({
    hasFetchedData: () => slice(state => state?.hasFetchedData),
    unmonitored: () => slice(state => state?.unmonitored),
    assetCollectionKey: () => (rootState, props) => props.match.params.key,
    assetCollectionProfile: models =>
      createSelector(
        [
          models.assetCollectionPage.assetCollectionKey,
          models.assetCollectionProfiles.profilesByKey,
        ],
        (assetCollectionKey, assetCollectionProfilesByKey) =>
          assetCollectionProfilesByKey && assetCollectionProfilesByKey[assetCollectionKey]
      ),
  }),
  effects: dispatch => ({
    async fetchData({ key, invalidateCache }) {
      this.setHasFetchedData(false);
      const [unmonitored] = await Promise.all([
        dispatch.assetCollectionProfiles.getUnmonitoredAssetCollectionConsumables({
          key,
          invalidateCache,
        }),
        dispatch.organization.getOrganizationProfileAsync(invalidateCache),
        dispatch.assetCollectionProfiles.getAssetCollectionProfileAsync({ key, invalidateCache }),
      ]);

      this.setUnmonitored(unmonitored);
      this.setHasFetchedData(true);
    },
  }),
};
