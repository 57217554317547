import styled from 'styled-components';
import { FilterButtons } from '@src/components/FilterButtons';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { VerticalStack } from '@src/components/VerticalStack';
import {
  Ellipsis,
  FontBody,
  FontBodyHeader,
  FontBodyLargeHeader,
  FontBodySmall,
} from '@src/style/common';

export const StyledDescription = styled.div`
  ${FontBody};
`;

export const StyledInsightsTitle = styled(HorizontalStack)`
  ${FontBodyLargeHeader};
  margin-top: 9rem;
`;

export const StyledInsightsSubTitle = styled.div`
  ${FontBodyHeader};
`;

export const StyledFilterButtons = styled(FilterButtons)`
  margin-top: 3rem;
  padding-bottom: 6rem;
`;

export const StyledTableContent = styled.div`
  margin-top: 3rem;
  padding-bottom: 0;
`;

export const StyledContent = styled.div`
  ${Ellipsis};
  ${FontBodySmall};
`;

export const StyledVerticalStack = styled(VerticalStack)`
  ${Ellipsis};
  ${FontBodySmall};
`;
