import _ from 'lodash';
import { useMemo } from 'react';

export function usePaginationRanges({ total, selected, selectedPadding = 2, boundaryPadding = 1 }) {
  return useMemo(
    () => ({
      atStart: selected === 1,
      atEnd: total === selected,
      ranges: getRanges(selected, total, selectedPadding, boundaryPadding),
    }),
    [selected, total, selectedPadding, boundaryPadding]
  );
}

function getRanges(selected, total, selectedPadding, boundaryPadding) {
  const hasLeftPadding = boundaryPadding + 2 < selected - selectedPadding;
  const hasRightPadding = total - boundaryPadding - 1 > selected + selectedPadding;

  if (hasLeftPadding && hasRightPadding) {
    return [
      range(1, boundaryPadding + 1),
      range(selected - selectedPadding, selected + selectedPadding),
      range(total - boundaryPadding, total),
    ];
  }
  if (hasLeftPadding) {
    return [range(1, boundaryPadding + 1), range(selected - selectedPadding, total)];
  }
  if (hasRightPadding) {
    return [range(1, selected + selectedPadding), range(total - boundaryPadding, total)];
  }
  return [range(1, total)];
}

// an inclusive version of lodash `range`
function range(start, end) {
  return _.range(start, end + 1);
}
