const clearData = () => ({});

export default {
  state: clearData(),
  reducers: {
    setHasFetchedData: (state, hasFetchedData) => ({
      ...state,
      hasFetchedData,
    }),
    clearData,
  },
  selectors: slice => ({
    hasFetchedData: () => slice(state => state?.hasFetchedData),
  }),
  effects: dispatch => ({
    async fetchData({ key, invalidateCache }) {
      try {
        await dispatch.developerProfiles.getDeveloperAsync({ key, invalidateCache });
      } catch (error) {}
      this.setHasFetchedData(true);
    },
  }),
};
