import { Badge } from '@src-v2/components/badges';
import { SvgIcon } from '@src-v2/components/icons';
import {
  useRiskyScoreTrendData,
  useRiskyTrendStyle,
} from '@src-v2/containers/overview/tiles/risky-score-trend-tile/utils';
import { abbreviate } from '@src-v2/utils/number-utils';

export const RiskyScoreTrendBadge = () => {
  const { percentageChange } = useRiskyScoreTrendData();
  const { trendColor, badgeIcon } = useRiskyTrendStyle(percentageChange);

  return (
    <Badge color={trendColor}>
      <SvgIcon name={badgeIcon} />
      &nbsp;
      {abbreviate(percentageChange, 1)}%
    </Badge>
  );
};
