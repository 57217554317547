import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { openSensitiveDataPaneWithRiskActions } from '@src/components/Panes/SensitiveDataPane';
import { EllipsisDiv } from '@src/style/common';
import { getTrimmedPath } from '@src/utils/stringDisplayUtils';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getSensitiveDataTriggerDisplay = ({ trigger, profile, ...options }) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  const path = getTrimmedPath(trigger.codeReference.relativeFilePath);
  const title = addInterpunctSeparator(
    profile.profileType === 'ApplicationProfile' && repository.name,
    trigger.sensitiveDataSourceDescription,
    path
  );

  return [
    {
      weight: 3,
      content: (
        <PaneLocationLink
          id={trigger.key}
          onClick={args =>
            openSensitiveDataPaneWithRiskActions({ ...args, profile, trigger, ...options })
          }>
          <TriggerInfoContainer title={title} insights={trigger.insights}>
            <HorizontalStack withSeparator>
              <EllipsisDiv>{trigger.codeReference.name}</EllipsisDiv>
              <EllipsisDiv>{trigger.name}</EllipsisDiv>
            </HorizontalStack>
          </TriggerInfoContainer>
        </PaneLocationLink>
      ),
    },
  ];
};

export const getSensitiveDataMessageFormat = element => element.name;
