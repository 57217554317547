import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { Collapsible } from '@src-v2/components/collapsible';
import { Radio } from '@src-v2/components/forms';
import { Paragraph } from '@src-v2/components/typography';
import { ConnectorEditor } from '@src-v2/containers/connectors/server-modal/connector-editor';
import { useInject, useToggle } from '@src-v2/hooks';
import { useCollapsible } from '@src-v2/hooks/use-collapsible';

export function GithubConnectorEditor({ providerGroup, isEdit, ...props }) {
  const { github } = useInject();
  const [useSignIn, toggleMethod] = useToggle(true);
  const collapsibleSignIn = useCollapsible({ open: useSignIn });
  const collapsibleToken = useCollapsible({ open: !useSignIn });

  return (
    <Container {...props}>
      <Label>
        <Paragraph>
          <Radio defaultChecked name="connectorType" onChange={toggleMethod} />
          Sign in with GitHub
        </Paragraph>
        <Paragraph>Automatically connect all permitted organizations</Paragraph>
      </Label>
      <Collapsible.Body {...collapsibleSignIn.getContentProps()}>
        <Button startIcon="Github" onClick={github.redirectToGithubOAuth}>
          Sign in with GitHub
        </Button>
      </Collapsible.Body>

      <Label>
        <Paragraph>
          <Radio name="connectorType" onChange={toggleMethod} />
          Connect with a URL and a personal access token
        </Paragraph>
        <Paragraph>Manually connect a specific organization</Paragraph>
      </Label>
      <Collapsible.Body {...collapsibleToken.getContentProps()}>
        <ConnectorEditor providerGroup={providerGroup} isEdit={isEdit} />
      </Collapsible.Body>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  margin-top: 6rem;
  flex-direction: column;
  gap: 4rem;

  ${Collapsible.Body} {
    padding-left: 6.5rem;
  }
`;

const Label = styled.label`
  display: block;
  cursor: pointer;

  ${Paragraph} {
    &:first-child {
      display: flex;
      align-items: center;
      gap: 2rem;
    }

    &:last-child {
      color: var(--color-blue-gray-45);
      font-size: var(--font-size-s);
      text-indent: 6.5rem;
    }
  }
`;
