export function getPartialPath(path, maxParts = 4) {
  const pathParts = path.split('/').slice(0, -1);
  return pathParts.length > maxParts
    ? `${pathParts.slice(0, maxParts - 1).join('/')}/.../${pathParts.pop()}/`
    : `${pathParts.join('/')}/`;
}

export function getFileNameFromPath(path) {
  return path.split('/').pop();
}

export function getTrimmedPath(path) {
  return getPartialPath(path) + getFileNameFromPath(path);
}

export function getMethodName(methodFullPath, removeParams) {
  const methodExpression = methodFullPath.split('.').pop();
  return removeParams
    ? methodExpression.substr(0, methodExpression.indexOf('('))
    : methodExpression;
}
