import { StatisticsBreakdown } from './StatisticsBreakdown';

export const PiisBreakdown = ({ profileStatistics }) => (
  <StatisticsBreakdown
    name="PII"
    totalKey="piisCount"
    profileStatistics={profileStatistics}
    statisticsKeysAndNames={[
      {
        key: 'exposedInApiPiisCount',
        name: 'Exposed in API',
      },
      {
        key: 'involvedInApiPiisCount',
        name: 'Involved in API',
      },
    ]}
  />
);
