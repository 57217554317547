import _ from 'lodash';
import ioc from '@src-v2/ioc';
import apiService from '@src/services/apiService';
import rulesService from '@src/services/rulesService';
import DefinitionConsts from './blocks/Definitions/DefinitionConsts';
import { getDefinitionOptions } from './blocks/definitionOptionsUtils';

const clearRulesData = state => ({
  ...state,
  failedToLoad: false,
  editedRule: null,
  editRuleIsOpen: false,
  editErrors: null,
  rulesByKey: {},
  disableRefreshOnPushNotification: true,
});

const clearDefinitionsData = state => ({
  ...state,
  failedToLoad: false,
  editedDefinition: null,
  editedDefinitionType: null,
  editDefinitionIsOpen: false,
  editDefinitionErrorMessage: null,
  definitionsByKey: {},
});

export default {
  state: {
    ...clearRulesData(),
    ...clearDefinitionsData(),
  },
  reducers: {
    // Rules
    addRule: state => ({ ...state, editRuleIsOpen: true }),
    closeEditRule: state => ({
      ...state,
      editedRule: null,
      editRuleIsOpen: false,
      editErrors: null,
    }),
    setFailedToLoad: (state, value) => ({
      ...state,
      failedToLoad: Boolean(value),
    }),
    setRules: (state, rules, options) => {
      const updatedRules = rules.map(rule => {
        const updatedRule = { ...rule };
        rulesService.groupSameTypeValues(updatedRule, options);
        updatedRule.processTags = _.sortBy(updatedRule.processTags, ['name']);

        return updatedRule;
      });
      return {
        ...state,
        rulesByKey: _.keyBy(updatedRules, rule => rule.key),
      };
    },
    setOptions: (state, options) => ({
      ...state,
      options,
    }),
    setEditedRule: (state, editedRule) => ({
      ...state,
      editRuleIsOpen: true,
      editedRule: _.cloneDeep(editedRule),
    }),

    // Definitions
    addDefinition: state => ({ ...state, editDefinitionIsOpen: true }),
    closeEditDefinition: state => ({
      ...state,
      editedDefinition: null,
      editedDefinitionType: null,
      editDefinitionIsOpen: false,
      editDefinitionErrorMessage: null,
    }),
    setDefinitionOptions: (state, definitionOptions) => ({
      ...state,
      definitionOptions,
    }),
    setDefinitions: (state, definitions) => ({
      ...state,
      definitionsByKey: _.keyBy(definitions, definition => definition.key),
    }),
    setEditedDefinition: (state, editedDefinition) => ({
      ...state,
      editDefinitionIsOpen: true,
      editedDefinition: _.cloneDeep(editedDefinition),
    }),
    setEditedDefinitionType: (state, definitionType) => ({
      ...state,
      editedDefinitionType: definitionType,
    }),
    setEditErrors: (state, errors) => ({
      ...state,
      editErrors: errors,
    }),
    setEditDefinitionErrorMessage: (state, errorMessage) => ({
      ...state,
      editDefinitionErrorMessage: errorMessage,
    }),
    clearRulesData,
    clearDefinitionsData,
  },

  selectors: slice => ({
    failedToLoad: () => slice(state => state.failedToLoad),

    // Rules
    rules: () => slice(state => _.valuesIn(state.rulesByKey)),
    rulesByKey: () => slice(state => state.rulesByKey),
    editedRule: () => slice(state => state.editedRule),
    editRuleIsOpen: () => slice(state => state.editRuleIsOpen),
    editErrors: () => slice(state => state.editErrors),
    options: () => slice(state => state.options),

    // Definitions
    definitions: () => slice(state => _.valuesIn(state.definitionsByKey)),
    definitionOptions: () => slice(state => state.definitionOptions),
    definitionsByKey: () => slice(state => state.definitionsByKey),
    editedDefinition: () => slice(state => state.editedDefinition),
    editedDefinitionType: () => slice(state => state.editedDefinitionType),
    editDefinitionIsOpen: () => slice(state => state.editDefinitionIsOpen),
    editDefinitionErrorMessage: () => slice(state => state.editDefinitionErrorMessage),
  }),

  // Rules
  effects: dispatch => ({
    async fetchRules(invalidateCache) {
      if (invalidateCache) {
        ioc.asyncCache.invalidate(ioc.governance.getRules);
      }
      return await ioc.asyncCache.suspend(ioc.governance.getRules);
    },
    async fetchOptions(invalidateCache) {
      if (invalidateCache) {
        ioc.asyncCache.invalidate(ioc.governance.getRuleOptions);
      }
      return await ioc.asyncCache.suspend(ioc.governance.getRuleOptions);
    },
    async fetchData({ invalidateCache }) {
      const [rules, options] = await Promise.all([
        this.fetchRules(invalidateCache),
        this.fetchOptions(invalidateCache),
        this.fetchDefinitions(invalidateCache),
      ]);
      this.setOptions(options);
      this.setDefinitionOptions(getDefinitionOptions(options));
      try {
        this.setRules(rules, options);
      } catch (e) {
        this.setFailedToLoad(true);
      }
    },
    async deleteRule(deletedRule) {
      try {
        await apiService.delete(`/api/governance/rules/${deletedRule.key}`);
        await this.fetchData({ invalidateCache: true });
      } catch (error) {
        dispatch.toast.error(error);
      }
    },

    // Definitions
    async fetchDefinitions(invalidateCache) {
      const definitions = await ioc.definitions.getDefinitions();

      this.setDefinitions(definitions);

      const issueDefinitions = definitions.filter(
        _ => _.type === DefinitionConsts.typeDisplayNameByType.Issue
      );
      await Promise.all([
        dispatch.organization.getOrganizationProfileAsync(invalidateCache),
        ...issueDefinitions.flatMap(issueDefinition =>
          issueDefinition.projectKeys?.forEach(key =>
            dispatch.projectProfiles.getProjectProfileAsync({ key, invalidateCache })
          )
        ),
        ...issueDefinitions.flatMap(issueDefinition =>
          issueDefinition.assetCollectionKeys?.forEach(key =>
            dispatch.assetCollectionProfiles.getAssetCollectionProfileAsync({
              key,
              invalidateCache,
            })
          )
        ),
      ]);
    },

    async applyDefinitionModifications(
      { definitionRaw, throwError = false },
      { governancePage: { editedDefinitionType } }
    ) {
      try {
        await ioc.definitions.applyDefinition({
          definitionRaw,
          definitionType: editedDefinitionType,
        });
        await this.fetchDefinitions();
        const options = await this.fetchOptions(true);
        this.setOptions(options);

        const [{ displayName }] = (DefinitionConsts.typeToRisk[editedDefinitionType] &&
          options?.given[DefinitionConsts.typeToRisk[editedDefinitionType]].options?.filter(
            risk => risk.key === definitionRaw.key
          )) || [{ displayName: '' }];

        return [true, displayName];
      } catch (error) {
        if (throwError) {
          throw error;
        }

        if (error.response.status === 400) {
          this.setEditDefinitionErrorMessage(error.response.data);
        } else if (_.isString(error)) {
          this.setEditDefinitionErrorMessage(error);
        }
        return [false, ''];
      }
    },

    async deleteDefinition({ definition, type }) {
      try {
        await ioc.definitions.removeDefinition({ definition, type });
        await this.fetchDefinitions();
        const options = await this.fetchOptions(true);
        this.setOptions(options);
      } catch (error) {
        dispatch.toast.error(error);
      }
    },
  }),
};
