export default {
  state: {},
  reducers: {},
  selectors: () => ({}),
  effects: dispatch => ({
    async fetchData({ definition }) {
      const projectProfilesTasks = definition.projectKeys.map(key =>
        dispatch.projectProfiles.getProjectProfileAsync({ key })
      );
      const assetCollectionProfilesTasks = definition.assetCollectionKeys.map(key =>
        dispatch.assetCollectionProfiles.getAssetCollectionProfileAsync({ key })
      );
      await Promise.all([...projectProfilesTasks, ...assetCollectionProfilesTasks]);
    },
  }),
};
