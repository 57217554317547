import _ from 'lodash';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Paragraph, Strong } from '@src-v2/components/typography';
import { HighlightedCode, MaterialChangesRow } from '@src-v2/containers/commit/common-componnets';
import { getApiText } from '@src-v2/containers/commit/material-changes-utils';

const UsagesForType = ({ methods, verb }) => (
  <>
    <Paragraph>
      {verb} in the following {methods.length === 1 ? 'API' : 'APIs'} :{' '}
    </Paragraph>

    {methods.map((method, index) => (
      <Tooltip
        key={index}
        content={<HighlightedCode language="java">{method.methodSignature}</HighlightedCode>}>
        <Paragraph>{getApiText(method.httpMethod, method.httpRoute, method.methodName)} </Paragraph>
      </Tooltip>
    ))}
  </>
);

const UsageList = ({ exposingMethods, involvingMethods }) => {
  return (
    <>
      {!_.isEmpty(exposingMethods) && <UsagesForType methods={exposingMethods} verb="Exposed" />}
      !_.isEmpty(exposingMethods) && (
      <UsagesForType methods={involvingMethods} verb="Involved" />)
    </>
  );
};

export const DataModelAccessMaterialChange = ({ materialChange }) => {
  const { name } = materialChange.codeReference;
  const { exposingMethods, involvingMethods } = materialChange;

  return (
    <>
      <MaterialChangesRow>
        <Paragraph>Data model</Paragraph> <Strong>{name}</Strong>
        <Paragraph>was accessed by an API for the first time</Paragraph>
      </MaterialChangesRow>
      <UsageList exposingMethods={exposingMethods} involvingMethods={involvingMethods} />
    </>
  );
};
