import _ from 'lodash';
import { observer } from 'mobx-react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { AnalyticsDataField, AnalyticsLayer } from '@src-v2/components/analytics-layer';
import { Badge } from '@src-v2/components/badges';
import { Card } from '@src-v2/components/cards';
import { FiltersControls } from '@src-v2/components/filters/inline-control/containers/filters-controls';
import { BaseIcon, SvgIcon, VendorIcon } from '@src-v2/components/icons';
import { Gutters } from '@src-v2/components/layout';
import { OverviewTilesGrid } from '@src-v2/components/overview/overview-tiles';
import { OverviewTilesOrderProvider } from '@src-v2/components/overview/overview-tiles-order-context';
import { ReorderTilesButton } from '@src-v2/components/overview/reorder-tiles-button';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink, Heading4, Link, SubHeading4 } from '@src-v2/components/typography';
import { GeneralDashboardTilesLayout } from '@src-v2/containers/overview/tiles/general-dashboard-tiles-layout';
import { ActivityBadge } from '@src-v2/containers/profiles/activity-badge';
import { ProfileTagsList } from '@src-v2/containers/profiles/profile-tags/profile-tags-list';
import { ProfileTagsPreviewContainer } from '@src-v2/containers/profiles/profile-tags/profile-tags-preview-container';
import { useManageProfileTagsModal } from '@src-v2/containers/profiles/profile-tags/use-profile-overview-tags';
import { RiskScoreInfo } from '@src-v2/containers/profiles/risk-score-profile-info';
import { SlaConfigurationBadge } from '@src-v2/containers/profiles/sla-configuration-badge';
import { useInject, useSuspense } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { ApplicationProfileResponse } from '@src-v2/types/profiles/application-profile-response';
import { OrganizationTeamProfileResponse } from '@src-v2/types/profiles/organization-team-profile-response';
import { RepositoryProfileResponse } from '@src-v2/types/profiles/repository-profile-response';

export const ApplicationOverview = observer(() => {
  const { application, overview, applicationProfilesV2 } = useInject();
  const { key } = useParams();
  const [activeProfile, filterOptions, maxRiskScore] = useSuspense([
    [applicationProfilesV2.getProfile, { key }],
    [overview.getApplicationFilterOptions],
    [applicationProfilesV2.getMaxRiskScore],
  ]);

  const [modalElement, handleManageTagsClicked] = useManageProfileTagsModal({
    profileKey: key,
    taggableEntityKey: key,
    typePrefix: 'Application',
    defaultTags: activeProfile.tags,
    optionsFetcher: applicationProfilesV2.getTagsOptions,
    onSubmit: applicationProfilesV2.updateProfileTags,
    submitText: 'Save',
  });

  const isEmptyStatusCard =
    activeProfile.isModuleBased &&
    application.isFeatureEnabled(FeatureFlag.GranularSLA) &&
    !application.isFeatureEnabled(FeatureFlag.RiskScore);

  return (
    <>
      {!activeProfile?.stillProcessing && (
        <ProfileInfo>
          <StatusCard>
            <Heading4>Status</Heading4>
            {isEmptyStatusCard ? (
              <NoStatusSubHeading>No additional info</NoStatusSubHeading>
            ) : (
              <>
                {!activeProfile.isModuleBased && <ActivityBadge profile={activeProfile} />}
                {application.isFeatureEnabled(FeatureFlag.GranularSLA) && (
                  <SlaConfigurationBadge profile={activeProfile} />
                )}
                {application.isFeatureEnabled(FeatureFlag.RiskScore) && (
                  <RiskScoreInfo riskScore={activeProfile.riskScore} maxRiskScore={maxRiskScore} />
                )}
              </>
            )}
          </StatusCard>

          <ProfileTagsPreviewContainer
            heading="Application tags"
            isEmpty={!activeProfile.tags?.length}
            onOpenManageModal={handleManageTagsClicked}>
            <ProfileTagsList tags={activeProfile.tags} />
          </ProfileTagsPreviewContainer>
        </ProfileInfo>
      )}

      <AnalyticsLayer
        analyticsData={{ [AnalyticsDataField.Context]: 'Application overview dashboard' }}>
        <OverviewTilesOrderProvider
          storagePrefix={_.kebabCase(ApplicationProfileResponse.profileType)}>
          <OverviewGutters>
            <FiltersControls filterOptions={filterOptions} />
            <ReorderTilesButton />
          </OverviewGutters>
          <OverviewGutters>
            <GeneralDashboardTilesLayout showMultiAssetsTiles={!activeProfile.isModuleBased} />
          </OverviewGutters>
        </OverviewTilesOrderProvider>
      </AnalyticsLayer>
      {modalElement}
    </>
  );
});

export const RepositoryOverview = observer(({ repositoryProfile }) => {
  const { key } = useParams();
  const { overview, repositoryProfiles, application } = useInject();

  const [activeProfile, filterOptions, maxRiskScore] = useSuspense([
    [repositoryProfiles.getProfile, { key }],
    [overview.getRepositoryFilterOptions],
    [repositoryProfiles.getMaxRiskScore],
  ]);

  const [modalElement, handleManageTagsClicked] = useManageProfileTagsModal({
    profileKey: key,
    taggableEntityKey: repositoryProfile.providerRepositoryKey,
    typePrefix: 'Repository',
    defaultTags: activeProfile.tags,
    optionsFetcher: repositoryProfiles.getRepositoryTagsOptions,
    onSubmit: repositoryProfiles.updateProviderRepositoryTags,
    submitText: 'Save',
  });

  return (
    <>
      <ProfileInfo>
        <StatusCard>
          <Heading4>Status</Heading4>
          <ActivityBadge profile={activeProfile} />
          {application.isFeatureEnabled(FeatureFlag.RiskScore) && (
            <RiskScoreInfo riskScore={activeProfile.riskScore} maxRiskScore={maxRiskScore} />
          )}
          {repositoryProfile?.provider !== 'Perforce' && (
            <TagLinkContainer>
              <ProviderLink
                icon={activeProfile.providerGroup ?? activeProfile.provider}
                href={activeProfile.providerUrl}>
                {activeProfile.mainBranch && `Analyzing ${activeProfile.mainBranch} branch`}
              </ProviderLink>
              {activeProfile.tag && <Badge>{activeProfile.tag}</Badge>}
              <Tooltip content="Configure branches via connection page">
                <Link
                  to={`/profiles/repositories/${repositoryProfile.key}/profile/${repositoryProfile?.repository?.providerRepositoryKey}/multi-branch`}>
                  <SvgIcon name="Settings" />
                </Link>
              </Tooltip>
            </TagLinkContainer>
          )}
        </StatusCard>

        <ProfileTagsPreviewContainer
          heading="Repository tags"
          isEmpty={!activeProfile.tags?.length}
          onOpenManageModal={handleManageTagsClicked}>
          <ProfileTagsList tags={activeProfile.tags} />
        </ProfileTagsPreviewContainer>
      </ProfileInfo>
      <AnalyticsLayer
        analyticsData={{ [AnalyticsDataField.Context]: 'Repository overview dashboard' }}>
        <OverviewTilesOrderProvider
          storagePrefix={_.kebabCase(RepositoryProfileResponse.profileType)}>
          <OverviewGutters>
            <FiltersControls filterOptions={filterOptions} />
            <ReorderTilesButton />
          </OverviewGutters>
          <OverviewGutters>
            <GeneralDashboardTilesLayout />
          </OverviewGutters>
        </OverviewTilesOrderProvider>
      </AnalyticsLayer>

      {modalElement}
    </>
  );
});

const OverviewGutters = styled(Gutters)`
  display: flex;
  justify-content: space-between;
  margin-top: 6rem;

  ${OverviewTilesGrid} {
    padding-bottom: 4rem;
  }
`;

const ProfileInfo = styled(Gutters)`
  display: flex;
  gap: 7rem;
  margin-top: 5rem;
  align-items: stretch;

  ${Card} {
    flex-grow: 1;
    margin-top: 0;
    padding: 4rem 5rem;
    width: 50%;
  }
`;

const ProviderLink = styled(({ icon, children, ...props }) => (
  <ExternalLink {...props}>
    <VendorIcon name={icon} />
    {children}
  </ExternalLink>
))`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

const TagLinkContainer = styled.div`
  display: flex;
  gap: 2rem;

  ${BaseIcon} {
    color: var(--color-blue-gray-50);
    cursor: pointer;
  }
`;

export const TeamOverview = observer(() => {
  const { application, overview, orgTeamProfiles } = useInject();
  const { key } = useParams();

  const [activeProfile, filterOptions, maxRiskScore] = useSuspense([
    [orgTeamProfiles.getProfile, { key }],
    [overview.getTeamFilterOptions],
    [orgTeamProfiles.getMaxRiskScore],
  ]);

  const [modalElement, handleManageTagsClicked] = useManageProfileTagsModal({
    taggableEntityKey: key,
    typePrefix: 'Team',
    defaultTags: activeProfile.tags,
    optionsFetcher: orgTeamProfiles.getTagsOptions,
    onSubmit: orgTeamProfiles.updateProfileTags,
    submitText: 'Save',
  });

  const showProfileInfo =
    application.isFeatureEnabled(FeatureFlag.GranularSLA) ||
    application.isFeatureEnabled(FeatureFlag.RiskScore) ||
    application.isFeatureEnabled(FeatureFlag.OrgTeamTags);

  const isEmptyStatusCard =
    !application.isFeatureEnabled(FeatureFlag.RiskScore) &&
    !application.isFeatureEnabled(FeatureFlag.GranularSLA);

  return (
    <>
      {showProfileInfo && (
        <ProfileInfo>
          <StatusCard>
            <Heading4>Status</Heading4>
            {isEmptyStatusCard ? (
              <NoStatusSubHeading>No additional info</NoStatusSubHeading>
            ) : (
              <>
                {application.isFeatureEnabled(FeatureFlag.GranularSLA) && (
                  <SlaConfigurationBadge profile={activeProfile} />
                )}
                {application.isFeatureEnabled(FeatureFlag.RiskScore) && (
                  <RiskScoreInfo riskScore={activeProfile.riskScore} maxRiskScore={maxRiskScore} />
                )}
              </>
            )}
          </StatusCard>

          <ProfileTagsPreviewContainer
            heading="Team tags"
            isEmpty={!activeProfile.tags?.length}
            onOpenManageModal={handleManageTagsClicked}>
            <ProfileTagsList tags={activeProfile.tags} />
          </ProfileTagsPreviewContainer>
        </ProfileInfo>
      )}
      <AnalyticsLayer analyticsData={{ [AnalyticsDataField.Context]: 'Team overview dashboard' }}>
        <OverviewTilesOrderProvider
          storagePrefix={_.kebabCase(OrganizationTeamProfileResponse.profileType)}>
          <OverviewGutters>
            <FiltersControls filterOptions={filterOptions} />
            <ReorderTilesButton />
          </OverviewGutters>
          <OverviewGutters>
            <GeneralDashboardTilesLayout showMultiAssetsTiles={!activeProfile.isModuleBased} />
          </OverviewGutters>
        </OverviewTilesOrderProvider>
      </AnalyticsLayer>

      {modalElement}
    </>
  );
});

const StatusCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const NoStatusSubHeading = styled(SubHeading4)`
  margin: auto;
`;
