import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { EllipsisDiv } from '@src/style/common';
import { getTrimmedPath } from '@src/utils/stringDisplayUtils';
import { TriggerInfoContainer } from './Common';

export const getProtobufServiceTriggerDisplay = ({ trigger }) => {
  return [
    {
      weight: 3,
      content: (
        <TriggerInfoContainer
          id={trigger.key}
          title={getTrimmedPath(trigger.codeReference.relativeFilePath)}
          insights={trigger.insights}>
          <HorizontalStack withSeparator>
            <EllipsisDiv>{trigger.displayName}</EllipsisDiv>
            <Number value={trigger.count} one="statement" other="statements" />
          </HorizontalStack>
        </TriggerInfoContainer>
      ),
    },
  ];
};

export const getProtobufServiceMessageFormat = element => element.codeReference.relativeFilePath;
