import CommonResourceProperties from './common-resource-properties';
import ContainerProperties from './container-properties';
import GatewayProperties from './gateway-properties';
import IngressProperties from './ingress-properties';

export default [
  CommonResourceProperties,
  GatewayProperties,
  IngressProperties,
  ContainerProperties,
];
