import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { openGqlObjectPaneWithRiskActions } from '@src/components/Panes/GqlObjectPane';
import { EllipsisDiv } from '@src/style/common';
import { getTrimmedPath } from '@src/utils/stringDisplayUtils';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getGqlObjectTriggerDisplay = ({ trigger, ...options }) => [
  {
    weight: 3,
    content: (
      <PaneLocationLink
        id={trigger.key}
        onClick={args => openGqlObjectPaneWithRiskActions({ ...args, trigger, ...options })}>
        <TriggerInfoContainer
          title={getTrimmedPath(trigger.codeReference.relativeFilePath)}
          insights={trigger.insights}>
          <HorizontalStack withSeparator>
            <EllipsisDiv>{trigger.displayName}</EllipsisDiv>
            {trigger.plainFieldsCount > 0 && (
              <Number value={trigger.plainFieldsCount} one="field" other="fields" />
            )}
            {trigger.resolverFieldsCount > 0 && (
              <Number value={trigger.resolverFieldsCount} one="resolver" other="resolvers" />
            )}
          </HorizontalStack>
        </TriggerInfoContainer>
      </PaneLocationLink>
    ),
  },
];

export const getGqlObjectMessageFormat = element => element.codeReference.relativeFilePath;
export const getGqlObjectFieldCounts = gqlObject => {
  const fieldsCount = gqlObject.fields.length;
  const resolverFieldsCount = gqlObject.fields.filter(_ => _.resolverReference).length;
  const plainFieldsCount = fieldsCount - resolverFieldsCount;
  return { fieldsCount, plainFieldsCount, resolverFieldsCount };
};
