import _ from 'lodash';
import { arrayJoinConjunction } from '@src-v2/utils/string-utils';
import TooltippedEllipsis from './TooltippedEllipsis';

const ExpertiseToDescription = expertise => {
  switch (expertise) {
    case 'Authentication':
      return 'authentication';
    case 'Authorization':
      return 'authorization';
    case 'General':
    // falls through
    default:
      return 'general security';
  }
};

export const SecurityExpertise = ({ securityExpertise, className }) => (
  <TooltippedEllipsis
    className={className}
    text={_.upperFirst(
      arrayJoinConjunction(_.sortBy(securityExpertise).map(ExpertiseToDescription))
    )}
  />
);
