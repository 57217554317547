import { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';
import { CircularSvgIconButton } from '@src/cluster-map-work/components/CircularSvgIconButton';

export function ChartZoomControl({ minZoom, maxZoom, value, onChange, zoomStep = 0.2 }) {
  const [typematicTimer, setTypematicTimer] = useState(null);

  const valueRef = useRef();
  valueRef.current = value;

  const handleZoomIn = useCallback(
    event => {
      if (valueRef.current < maxZoom) {
        onChange(event, valueRef.current + zoomStep);
      }
    },
    [valueRef, maxZoom, zoomStep, onChange]
  );

  const handleZoomOut = useCallback(
    event => {
      if (valueRef.current > minZoom) {
        onChange(event, valueRef.current - zoomStep);
      }
    },
    [valueRef, minZoom, zoomStep, onChange]
  );

  const startTypematic = useCallback(
    fn => {
      if (typematicTimer) {
        clearInterval(typematicTimer);
      }

      setTypematicTimer(
        setInterval(() => {
          fn();
        }, 100)
      );

      fn();
    },
    [typematicTimer, setTypematicTimer]
  );

  const endTypematic = useCallback(() => {
    clearInterval(typematicTimer);
    setTypematicTimer(null);
  }, [typematicTimer, setTypematicTimer]);

  const handleZoomAll = useCallback(
    event => {
      onChange(event, minZoom, true);
    },
    [minZoom, onChange]
  );

  return (
    <ChartZoomControlsContainer>
      <ChartZoomControlsContainer.Button
        onMouseDown={() => startTypematic(handleZoomIn)}
        onMouseUp={endTypematic}
        disabled={value >= maxZoom}
        backgroundHilightOnHover={true}
        name="PlusSmall"
      />
      <ChartZoomControlsContainer.Button
        onMouseDown={() => startTypematic(handleZoomOut)}
        onMouseUp={endTypematic}
        disabled={value <= minZoom}
        backgroundHilightOnHover={true}
        name="MinusSmall"
      />
      <ChartZoomControlsContainer.Button
        onClick={handleZoomAll}
        name="FullScreen"
        backgroundHilightOnHover={true}
      />
    </ChartZoomControlsContainer>
  );
}

const ChartZoomControlsContainer = styled.div`
  display: flex;
  flex-direction: column;
  box-shadow: var(--elevation-0);
  border-radius: 100vmax;
  width: 10rem;
  background: white;
  align-items: center;

  padding: 2rem;
  gap: 2rem;
`;

ChartZoomControlsContainer.Button = styled(CircularSvgIconButton)`
  width: 6rem;
  height: 6rem;
`;
