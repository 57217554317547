import _ from 'lodash';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useState } from 'react';
import { MonitorStatus } from '@src-v2/data/monitor-status';
import apiService from '@src/services/apiService';
import { createDebouncedAsyncCall } from '@src/utils/debouncedAsyncCalls';
import { HorizontalStack } from './HorizontalStack';
import { Selector } from './Selector';
import ServerIcon from './ServerIcon';

const createItem = ({ data: { label, provider } }, hideIcon) =>
  isNil(provider) ? (
    label
  ) : (
    <HorizontalStack>
      {!hideIcon && <ServerIcon size="small" provider={provider} colorDimmed />}
      <span>{label}</span>
    </HorizontalStack>
  );

const consumableToOption = consumable =>
  isNil(consumable)
    ? null
    : {
        label: consumable.name,
        value: consumable.key,
        serverUrl: consumable.serverUrl,
        provider: consumable.server?.provider,
      };

export const ConsumablesSelector = ({
  hideIcon,
  isMulti,
  onChange,
  allOption,
  specialOptions,
  initialSelectedConsumable,
  tableScope,
  name,
  fetchProfiles,
  components,
  filters,
  searchParams = {},
  ...props
}) => {
  const [savedFilters, setSavedFilters] = useState(filters);
  const searchConsumables = useCallback(
    createDebouncedAsyncCall(async input =>
      fetchProfiles
        ? (
            await apiService.searchTable({
              tableScope: `${tableScope}/profiles`,
              currentPage: 0,
              searchTerm: input,
            })
          )?.items
        : (
            await apiService.searchTable({
              tableScope,
              currentPage: 0,
              searchTerm: input,
              filters: {
                Monitoring: MonitorStatus.Monitored,
                ...savedFilters,
              },
              ...searchParams,
            })
          )?.items
    ),
    [savedFilters, tableScope, fetchProfiles]
  );

  useEffect(() => {
    if (!_.isEqual(savedFilters, filters)) {
      setSavedFilters(filters);
    }
  }, [filters]);

  return (
    <Selector
      searchFunc={searchConsumables}
      itemToOption={consumableToOption}
      createItem={item => createItem(item, hideIcon)}
      components={components}
      specialOptions={specialOptions}
      name={name ?? tableScope}
      allOption={allOption}
      initialSelectedItem={initialSelectedConsumable}
      isMulti={isMulti}
      onChange={onChange}
      {...props}
    />
  );
};
