import { format, isDate } from 'date-fns';
import { observer } from 'mobx-react';
import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { TextButton } from '@src-v2/components/button-v2';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { CalendarDatePicker } from '@src-v2/components/forms/calendar-date-picker';
import { DateTime } from '@src-v2/components/time';
import { InfoTooltip } from '@src-v2/components/tooltips/icon-tooltips';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Size } from '@src-v2/components/types/enums/size';
import { Link } from '@src-v2/components/typography';
import { dateFormats } from '@src-v2/data/datetime';
import { useInject } from '@src-v2/hooks';
import { RiskTriggerSummaryResponse } from '@src-v2/types/risks/risk-trigger-summary-response';
import { StubAny } from '@src-v2/types/stub-any';
import { dataAttr, stopPropagation } from '@src-v2/utils/dom-utils';
import { modify } from '@src-v2/utils/mobx-utils';

export const RiskDueDate = observer(({ risk }: { risk: RiskTriggerSummaryResponse }) => {
  const { toaster, risks } = useInject();
  const trackAnalytics = useTrackAnalytics();

  const handleDueDateChange = useCallback(
    async (newDate: Date) => {
      try {
        const utcDate = new Date(format(newDate, dateFormats.serverDate));
        const result = await risks.changeRiskDueDate({ newDate: utcDate, triggerKeys: [risk.key] });
        modify(risk, { dueDate: utcDate, slaPolicyMetadata: null });
        toaster.success(
          result
            ? `Risk due-date changed successfully but ${result}`
            : `Risk due-date changed successfully`
        );
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Override due date',
        });
      } catch (error) {
        toaster.error(error.response.data);
      }
    },
    [risk, toaster]
  );

  const nonTimezonedDueDate = useMemo(() => {
    const dueDate = isDate(risk.dueDate) ? risk.dueDate : new Date(risk.dueDate);
    return new Date(dueDate.getUTCFullYear(), dueDate.getUTCMonth(), dueDate.getUTCDate());
  }, [risk.dueDate]);

  const dateExceeded = useMemo(
    () => new Date(nonTimezonedDueDate) < new Date(),
    [nonTimezonedDueDate]
  );

  return (
    <Container>
      <Tooltip
        interactive
        content={
          risk.dueDate ? (
            'Override due date'
          ) : (
            <>
              Override due date or <Link to="settings/general">set SLA</Link>
            </>
          )
        }>
        <DropdownMenu
          plugins={[hideCalendarOnClickCustomPlugin]}
          onClick={stopPropagation}
          onItemClick={stopPropagation}
          size={Size.MEDIUM}
          maxHeight="100%"
          icon="Calendar">
          <CalendarPicker
            minDate={new Date(risk.discoveredAt)}
            selectRange={false}
            onChange={handleDueDateChange}
            maxDate={null}
          />
        </DropdownMenu>
      </Tooltip>
      {risk.dueDate ? (
        <>
          <Tooltip interactive content="SLA due date exceeded" disabled={!dateExceeded}>
            <DateTimeWrapper data-exceeded={dataAttr(dateExceeded)}>
              <DateTime date={nonTimezonedDueDate} format={dateFormats.longDate} />
            </DateTimeWrapper>
          </Tooltip>
          <InfoTooltip
            interactive
            content={
              <>
                {risk.slaPolicyMetadata?.key ? (
                  <>
                    This due date is controlled by SLA policy
                    <br />
                    <TextButton to="/settings/general">
                      {risk.slaPolicyMetadata.key === 'GlobalSlaKey'
                        ? risk.slaPolicyMetadata.name.replace('Sla', 'SLA')
                        : risk.slaPolicyMetadata.name}
                    </TextButton>
                  </>
                ) : (
                  <>Due date set manually</>
                )}
              </>
            }
          />
        </>
      ) : (
        'Not set'
      )}
    </Container>
  );
});

const DateTimeWrapper = styled.span`
  &[data-exceeded] {
    color: var(--color-red-60);
  }
`;

const Container = styled.div`
  display: flex;
  height: 5rem;
  gap: 1rem;
  align-items: center;

  ${DropdownMenu} {
    overflow: visible;
    box-shadow: var(--elevation-1);

    &:hover {
      box-shadow: var(--elevation-2);
    }
  }
`;

const CalendarPicker = styled(CalendarDatePicker)`
  border: 0;

  &[disabled] {
    opacity: 0.5;
  }
`;

export const hideCalendarOnClickCustomPlugin = {
  name: 'hideCalendarOnChange',
  defaultValue: true,
  fn: (instance: StubAny) => ({
    onCreate() {
      instance.popper.addEventListener('click', (event: StubAny) => {
        if (event.target.closest('[class*="months"]') || event.target.closest('[class*="years"]')) {
          return;
        }
        const closestItem = event.target.closest('.react-calendar__tile');
        if (closestItem && !event.defaultPrevented && instance.popper.contains(closestItem)) {
          setTimeout(() => instance.state.isMounted && instance.hide());
        }
      });
    },
  }),
};
