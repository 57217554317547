export const timeframes = Object.freeze([
  { title: 'QoQ', value: 'quarterly' },
  { title: 'Past 12w', value: 'weekly' },
]);

export const dateFormats = {
  shortDate: 'MM/dd',
  longDate: 'MMM dd, yyyy',
  longDatetime: 'MMM dd, yyyy HH:mm',
  serverDate: 'yyyy-MM-dd',
  usDateTime: 'PPpp',
};
