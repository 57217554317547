import { formatDistanceToNowStrict } from 'date-fns';
import isNil from 'lodash/isNil';
import { DistanceTime, TimeTooltip } from '@src-v2/components/time';
import { TriggerBoxSmallTitle } from './Common';

export const getMissingSASTScanTriggerDisplay = ({ trigger }) => {
  return [
    {
      weight: 3,
      content: (
        <div>
          <TriggerBoxSmallTitle>{trigger.name}</TriggerBoxSmallTitle>
          {!isNil(trigger.value) ? (
            <TimeTooltip date={trigger.value}>
              <div>
                Last scan <DistanceTime key={trigger.key} date={trigger.value} addSuffix strict />
              </div>
            </TimeTooltip>
          ) : (
            <div>Never scanned</div>
          )}
        </div>
      ),
    },
  ];
};

export const getMissingSASTScanMessageFormat = element =>
  `${element.name} - ${
    !isNil(element.value)
      ? `Last scan ${formatDistanceToNowStrict(new Date(element.value), { addSuffix: true })}`
      : 'Never scanned'
  }`;
