import _ from 'lodash';
import { observer } from 'mobx-react';
import { useCallback, useMemo, useRef } from 'react';
import styled from 'styled-components';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { Dropdown } from '@src-v2/components/dropdown';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { Checkbox } from '@src-v2/components/forms';
import { BaseIcon, SvgIcon, VendorIcon } from '@src-v2/components/icons';
import { Table } from '@src-v2/components/table/table';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { useDataTableContext } from '@src-v2/containers/data-table/data-table';
import { HeaderMenu, SortControl } from '@src-v2/containers/data-table/table-header-controls';
import {
  useColumnDrag,
  usePinnedColumnRef,
  usePinnedStyle,
} from '@src-v2/containers/data-table/utils/hooks';
import { dataAttr, stopPropagation } from '@src-v2/utils/dom-utils';
import { fromRem } from '@src-v2/utils/style-utils';

export const DataTableHeader = observer(props => {
  const { dataModel } = useDataTableContext();
  const partialSelect = !dataModel.isAllSelected && dataModel.selection.length;
  const noPinnedColumns = useMemo(
    () => !dataModel.columns.find(column => Boolean(column.pinned)),
    [dataModel.columns]
  );

  const hasNestedHeader = dataModel.columns.some(column => Boolean(column.nestedColumns));
  const hasResults = dataModel.searchState.items.length > 0;

  return (
    <Table.Head {...props}>
      <Table.Row data-has-nested={dataAttr(hasNestedHeader)} data-test-marker="table-header-row">
        {hasResults ? (
          <>
            {dataModel.selectable && (
              <SelectableHeader
                data-selection-column
                data-pinned-column={dataAttr(
                  dataModel.isPinFeatureEnabled,
                  noPinnedColumns ? 'last' : ''
                )}>
                <ContentContainer data-nested={dataAttr(hasNestedHeader)}>
                  <Checkbox
                    icon={partialSelect ? 'Minus' : 'Check'}
                    checked={dataModel.selection.length > 0}
                    onChange={dataModel.toggleSelectAll}
                  />
                </ContentContainer>
              </SelectableHeader>
            )}
            {dataModel.columns
              ?.filter(column => !column.hidden)
              ?.filter(column => column.key !== 'actions-menu')
              ?.map((column, index) => (
                <Header
                  {...column}
                  key={column.key}
                  columnKey={column.key}
                  isNestedParent={Boolean(column.nestedColumns)}
                  index={index}
                  colSpan={column.nestedColumns?.filter(item => !item?.hidden).length ?? 1}
                  data-test-marker="table-header"
                />
              ))}
            {(dataModel.hasActionsMenu || dataModel.hasToggleColumns) && (
              <AddColumnsHeader hasNestedHeader={hasNestedHeader} />
            )}
          </>
        ) : (
          <Table.Header />
        )}
      </Table.Row>
      {hasNestedHeader && (
        <Table.Row data-nested-row>
          {hasResults ? (
            <>
              {dataModel.selectable && (
                <SelectableHeader
                  data-selection-column
                  data-pinned-column={dataAttr(
                    dataModel.isPinFeatureEnabled,
                    noPinnedColumns ? 'last' : ''
                  )}
                />
              )}
              {dataModel.columns
                ?.filter(column => !column.hidden)
                ?.filter(column => column.key !== 'actions-menu')
                ?.map((column, index) => {
                  if (column.nestedColumns) {
                    return column.nestedColumns
                      .filter(({ hidden }) => !hidden)
                      .map((nestedColumn, nestedIndex) => (
                        <HeaderNested
                          {...nestedColumn}
                          key={nestedColumn.key}
                          columnKey={nestedColumn.key}
                          parentKey={column.key}
                          pinned={column.pinned}
                          index={nestedIndex}>
                          {nestedColumn.label}
                        </HeaderNested>
                      ));
                  }
                  return (
                    <Header
                      {...column}
                      key={column.key}
                      columnKey={column.key}
                      index={index}
                      label=""
                      colSpan={1}
                      isNested
                    />
                  );
                })}
              {(dataModel.hasActionsMenu || dataModel.hasToggleColumns) && (
                <AddColumnsHeader hasNestedHeader={hasNestedHeader} hidden />
              )}
            </>
          ) : (
            <Table.Header />
          )}
        </Table.Row>
      )}
    </Table.Head>
  );
});

const ControlsContainer = styled.div`
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  z-index: 3;
`;

const RemoveNestedButton = styled(SvgIcon)`
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  padding: 0.5rem;

  &:hover {
    color: var(--color-blue-gray-70);
  }
`;

const ContentContainer = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > ${BaseIcon}:not(${RemoveNestedButton}) {
    margin: 0 2rem;
  }

  // center container on tables with nested columns

  &[data-nested] {
    position: relative;
    top: 4rem;
    z-index: 1;
  }

  &:hover {
    ${RemoveNestedButton} {
      display: block;
    }
  }
`;

const Resize = styled.div`
  width: 2rem;
  height: 1rem;
  position: relative;
  top: 0;
  transform: scaleY(12);
  overflow: auto;
  opacity: 0;
  z-index: 1;

  &[data-resizeable] {
    resize: horizontal;
  }

  &[data-nested] {
    transform: scaleY(18);
  }
`;

const Line = styled.div`
  position: absolute;
  top: 2rem;
  right: 0;
  width: 0.25rem;
  height: 7.5rem;
  bottom: 0;
  opacity: 0;
  transition: 0.1s;
  border-radius: 100vmax;
  pointer-events: none;

  &[data-resizeable] {
    ${Resize}:hover + &,
    ${Resize}:active + & {
      opacity: 1;
    }
  }
`;

const Header = observer(
  ({
    columnKey,
    index,
    colSpan,
    label,
    fieldName,
    width,
    minWidth,
    maxWidth,
    sortable,
    pinned,
    type,
    isNested,
    isNestedParent,
    resizeable = true,
    draggable = true,
    ...props
  }) => {
    const trackAnalytics = useTrackAnalytics();
    const { dataModel } = useDataTableContext();
    const { left, pinnedColumnDataAttr } = usePinnedStyle({ index, pinned });
    const { pinnedColumnRef } = usePinnedColumnRef({
      index,
    });

    const labelRef = useRef();
    const resizeWidth = useMemo(
      // 36 for sort-arrow and box padding, 16 for just padding
      () => {
        const parsedWidth = (typeof width === 'string' ? fromRem(width, false) : width) ?? 80;
        const sortSize =
          sortable && dataModel.searchState.params.sortBy === fieldName
            ? 36
            : sortable && dataModel.searchState.params.sortDirection
              ? 12
              : 0;
        const paddingSize = index === 0 ? 24 : 16;
        const finalWidth =
          parsedWidth - paddingSize - sortSize - (labelRef.current?.offsetWidth ?? 0);
        return Math.max(finalWidth ?? 0, 12);
      },
      [width, labelRef.current?.offsetWidth]
    );

    const { dragRef, dropRef, dragState, dropState } = useColumnDrag({
      onDrop: item => {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ColumnName]: item.key,
          [AnalyticsDataField.ActionType]: 'Drag column',
        });
        dataModel.moveColumn({ dragIndex: item.index, hoverIndex: index });
      },
      canDrop: item => (item.index !== index ? Boolean(item.pinned) === Boolean(pinned) : null),
      type: 'column',
      item: { index, pinned, key: columnKey },
    });

    const hasNestedHeader = dataModel.columns.some(column => Boolean(column.nestedColumns));
    const enableDragForPinnedOnNested = !(pinned && hasNestedHeader);

    const {
      dataModel: {
        searchState: {
          params: { sortBy },
        },
      },
    } = useDataTableContext();

    return (
      <Table.Header
        {...props}
        ref={node =>
          enableDragForPinnedOnNested &&
          (dataModel.hasReorderColumns && draggable ? dragRef(dropRef(node)) : null)
        }
        className={columnKey}
        colSpan={colSpan}
        data-draggable={dataAttr(
          dataModel.hasReorderColumns && draggable && enableDragForPinnedOnNested
        )}
        data-dragging={dataAttr(dragState.isDragging)}
        data-dropzone={dataAttr(dropState.isOver, dropState.canDrop)}
        data-pinned-column={pinnedColumnDataAttr}
        data-nested-header={dataAttr(isNested)}
        data-nested-parent={dataAttr(isNestedParent)}
        data-has-nested={dataAttr(hasNestedHeader)}
        style={{
          width: resizeable ? 'auto' : width,
          minWidth,
          maxWidth: maxWidth ?? '150rem',
          left,
        }}>
        <ContentContainer
          ref={pinnedColumnRef}
          data-nested={dataAttr(hasNestedHeader && !isNestedParent)}>
          <Label ref={labelRef} data-centered={dataAttr(isNestedParent)}>
            {label}
          </Label>
          {!isNested && (sortable || dataModel.hasToggleColumns) && (
            <ControlsContainer>
              {sortable && Boolean(sortBy) && sortBy === (fieldName ?? columnKey) && (
                <SortControl fieldName={fieldName ?? columnKey} />
              )}
              <HeaderMenu
                dataModel={dataModel}
                fieldName={fieldName}
                sortable={sortable}
                columnKey={columnKey}
                isNestedParent={isNestedParent}
                pinned={pinned}
                index={index}
              />
            </ControlsContainer>
          )}
          {!isNested && !isNestedParent && resizeable && (
            <>
              <Resize
                ref={node =>
                  enableDragForPinnedOnNested &&
                  (dataModel.hasReorderColumns && draggable ? dragRef(dropRef(node)) : null)
                }
                style={{ width: `${resizeWidth}px` }}
                data-resizeable={dataAttr(resizeable)}
                data-nested={dataAttr(hasNestedHeader)}
              />
              <Line data-resizeable={dataAttr(resizeable)} />
            </>
          )}
          {isNested && <NestedDropzonePreview />}
        </ContentContainer>
      </Table.Header>
    );
  }
);

const AddColumnsHeader = styled(
  observer(({ hasNestedHeader, hidden, ...props }) => {
    const trackAnalytics = useTrackAnalytics();
    const { dataModel } = useDataTableContext();

    const hiddenNestedColumns = dataModel.columns
      .map(
        column =>
          column.nestedColumns && {
            key: column.key,
            label: column.label,
            columns: column.nestedColumns?.filter(nested => Boolean(nested.hidden)),
          }
      )
      .filter(column => column?.columns?.length > 0);

    const hasHiddenColumns = useMemo(
      () =>
        Boolean(dataModel.columns.find(column => column.hidden)) || hiddenNestedColumns.length > 0,
      [dataModel, hiddenNestedColumns]
    );

    const handleAddColumnClick = useCallback(
      column => {
        dataModel.toggleColumnView(column);
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ColumnName]: column.key,
          [AnalyticsDataField.ActionType]: 'Add Column',
        });
      },
      [dataModel, trackAnalytics]
    );

    return (
      <Table.Header
        {...props}
        data-action-menu
        data-pinned-column={dataAttr(dataModel.isPinFeatureEnabled)}>
        <ContentContainer data-nested={dataAttr(hasNestedHeader)}>
          {!hidden && dataModel.hasToggleColumns && (
            <Tooltip content={hasHiddenColumns ? 'Add Columns' : 'No columns available'}>
              <DropdownMenu
                icon="Plus"
                data-test-marker="add-column-header"
                disabled={!hasHiddenColumns}
                onClick={stopPropagation}
                onItemClick={stopPropagation}>
                <Dropdown.Group>
                  {_.orderBy(
                    dataModel.columns.filter(column =>
                      Boolean(column.hidden && !column.nestedColumns)
                    ),
                    ['label']
                  ).map(column => (
                    <Dropdown.Item key={column.key} onClick={() => handleAddColumnClick(column)}>
                      {column.label}
                      {/* for icon columns */}
                      {typeof column.label !== 'string' && column.key}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Group>
                {hiddenNestedColumns.map(column => (
                  <Dropdown.Group key={column.key} title={column.label}>
                    {_.orderBy(column.columns, ['key']).map(nested => (
                      <Dropdown.Item
                        key={nested.key}
                        onClick={() =>
                          dataModel.toggleNestedColumnView({
                            key: nested.key,
                            parentKey: column.key,
                          })
                        }>
                        <VendorIcon name={nested.key} />
                        {typeof nested.label === 'string' ? nested.label : nested.key}
                      </Dropdown.Item>
                    ))}
                  </Dropdown.Group>
                ))}
              </DropdownMenu>
            </Tooltip>
          )}
        </ContentContainer>
      </Table.Header>
    );
  })
)`
  width: 8rem;
  min-width: 8rem;
  padding-right: 4rem;
  text-align: right;
`;

const SelectableHeader = styled(Table.Header)`
  width: 16rem;
  min-width: 16rem;
`;

const HeaderNested = observer(
  ({
    parentKey,
    columnKey,
    index,
    label,
    width,
    minWidth,
    maxWidth,
    pinned,
    type,
    nestedColumns,
    style,
    ...props
  }) => {
    const { dataModel } = useDataTableContext();
    const labelRef = useRef();

    const handleToggle = useCallback(() => {
      dataModel.toggleNestedColumnView({ key: columnKey, parentKey });
    }, [dataModel.toggleNestedColumnView, columnKey, parentKey]);

    return (
      <Table.Header
        {...props}
        data-is-nested
        className={`${parentKey}-${columnKey}`}
        colSpan={1}
        style={{
          ...style,
          width,
          minWidth,
          maxWidth: maxWidth ?? '150rem',
          padding: '0 0 0 4rem',
        }}>
        <ContentContainer>
          <Label ref={labelRef} data-centered data-nested>
            {label}
          </Label>
          <Tooltip content="Remove column">
            <RemoveNestedButton name="Close" onClick={handleToggle} />
          </Tooltip>
        </ContentContainer>
      </Table.Header>
    );
  }
);

const Label = styled.label`
  &[data-centered] {
    width: 100%;
    text-align: center;
  }

  &[data-nested] {
    padding-right: 4rem;
  }
`;

const NestedDropzonePreview = styled.div`
  height: 18rem;
  position: absolute;
  width: 1rem;
  bottom: 0;
  left: 0;
`;
