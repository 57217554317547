import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import some from 'lodash/some';
import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { SubHeading3 } from '@src-v2/components/typography';
import { useInject } from '@src-v2/hooks';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { ErrorMessage } from '@src/components/ErrorMessage';
import { VerticalStack } from '@src/components/VerticalStack';
import apiService from '@src/services/apiService';
import rulesService from '@src/services/rulesService';
import { RuleHeader } from './RuleHeader';

const StyledModalBody = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 8rem;
`;

const StyledModalSideComponent = styled(VerticalStack)`
  width: 250px;
  min-width: 250px;
  padding: 5rem;
  flex: 0 1 auto;
  background-color: var(--color-blue-gray-20);
  border-radius: 5rem;

  ${SubHeading3} {
    margin-bottom: 1rem;
  }
`;

const ruleEditDisableForSelection = (selection, portionOptions) => {
  const typeOption = portionOptions[selection.type];
  const { subTypes } = typeOption;

  if (typeOption.type === 'text' && isEmpty(selection.values[0])) {
    return true;
  }

  if (!isNil(subTypes)) {
    const currentSubType = find(subTypes, subType => subType.key === selection.subType);
    return currentSubType?.type === 'text' && isEmpty(selection.values[0]);
  }

  return false;
};

const ruleEditDisableForPortion = (portion, rule, options) =>
  some(rule[portion], selection => ruleEditDisableForSelection(selection, options[portion]));

const ruleEditDisabled = (rule, options) =>
  !rule.name ||
  ruleEditDisableForPortion('when', rule, options) ||
  ruleEditDisableForPortion('then', rule, options);

export const EditRuleModalErrorContext = createContext(null);

export const EditRuleModalErrorContextProvider = ({ children, errorMessage }) => {
  return (
    <EditRuleModalErrorContext.Provider value={{ errorMessage }}>
      {children}
    </EditRuleModalErrorContext.Provider>
  );
};

export const EditRuleModal = ({
  children,
  ruleTitleName,
  ruleNameExample = '',
  rule,
  rules,
  options,
  onConfirm,
  onCancel,
  filterReadOnlyOptions = options => options,
  newRule = rulesService.newRule,
  setOption = rulesService.setOption,
  sideComponent,
  ruleByDefinition,
  setRuleByDefinition,
  openedCreateRuleFromBanner,
  setOpenedCreateRuleFromBanner,
  validatedRule = () => true,
}) => {
  const { toaster } = useInject();
  const { errorMessage } = useContext(EditRuleModalErrorContext);
  const [editedRule, setEditedRule] = useState(
    rule
      ? rulesService.markRequiredThenProperties(rule, filterReadOnlyOptions(options))
      : newRule(filterReadOnlyOptions(options))
  );

  const additionalButton = useMemo(() => {
    const webhooks = editedRule.then.filter(_ => _.type === 'Webhook');
    if (!isEmpty(webhooks)) {
      return {
        text: pluralFormat(webhooks.length, 'Test Webhook'),
        type: 'primary',
        onClick: event => {
          event.preventDefault();
          webhooks.forEach(webhook =>
            apiService
              .post('/api/workflows/testWebhook', null, {
                params: {
                  url: webhook.values[0],
                  authorizationHeader: webhook.additionalProperties?.find(
                    _ => _.type === 'AuthorizationHeader'
                  )?.values[0],
                },
              })
              .then(() =>
                toaster.success(
                  `Successfully verified connection to webhook server ${webhook.values[0]}`
                )
              )
              .catch(() => toaster.error(`Failed to validate webhook server ${webhook.values[0]}`))
          );
        },
      };
    }
    return null;
  }, [editedRule.then]);

  useEffect(() => {
    if (!isEmpty(ruleByDefinition) && openedCreateRuleFromBanner) {
      setEditedRule({ ...editedRule, ...ruleByDefinition });
      setRuleByDefinition && setRuleByDefinition({});
      setOpenedCreateRuleFromBanner && setOpenedCreateRuleFromBanner(false);
    }
  }, []);

  const isNewRule = !rule || rule.isDuplicated;

  const nameExists = useMemo(() => {
    const otherRuleNames = Object.values(rules)
      .filter(custom => custom.key !== editedRule.key && custom.name)
      .map(custom => custom.name.toLowerCase().trim());

    return otherRuleNames.includes(editedRule.name?.toLowerCase().trim());
  }, [rules, editedRule?.name]);
  const title = (
    <RuleHeader
      rule={editedRule}
      nameExists={nameExists}
      isNew={isNewRule}
      ruleTitleName={ruleTitleName}
      ruleNameExample={ruleNameExample}
      onRuleNameChange={name => setEditedRule(rulesService.changeRuleName(editedRule, name))}
    />
  );

  const modificationHandlers = mapValues(
    {
      setOption,
      addOption: rulesService.addOption,
      removeOption: rulesService.removeOption,
      addProperty: rulesService.addProperty,
      removeProperty: rulesService.removeProperty,
      setProperty: rulesService.setProperty,
      setProcessTags: rulesService.setProcessTags,
      setRuleCategory: rulesService.setRuleCategory,
      setRuleDescription: rulesService.setRuleDescription,
    },
    modifierFunction => modifications =>
      setEditedRule(modifierFunction(editedRule, modifications, options))
  );

  const propsToChildren = {
    isReadOnly: false,
    rule: editedRule,
    setEditedRule,
    options,
    ...modificationHandlers,
  };

  return (
    <InnerModal
      title={title}
      submitText={isNewRule ? 'Create' : 'Save'}
      onSubmit={() => onConfirm(editedRule)}
      onClose={onCancel}
      disabledSubmitButton={ruleEditDisabled(editedRule, options) || !validatedRule(editedRule)}
      customButtonProps={additionalButton}>
      <StyledModalBody>
        {children(propsToChildren)}
        {sideComponent && (
          <StyledModalSideComponent>{sideComponent(propsToChildren)}</StyledModalSideComponent>
        )}
      </StyledModalBody>
      <ErrorMessage data-test-marker="error" message={errorMessage} />
    </InnerModal>
  );
};

const InnerModal = styled(ConfirmationModal)`
  width: 100%;
  min-width: 275rem;
  max-width: min-content;
`;
