import { useState } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { CircularSvgIconButton } from '@src/cluster-map-work/components/CircularSvgIconButton';

export const InChartLegend = ({ children, horizontal }) => {
  const [expanded, setExpanded] = useState(null);

  const handleToggleLegendClicked = () => {
    setExpanded(expanded === false);
  };

  return (
    <InChartLegendContainer>
      <LegendCalloutContainer
        data-direction={horizontal ? 'right' : 'bottom'}
        data-transition={
          expanded === null ? '' : expanded ? 'chart-legend-show' : 'chart-legend-hide'
        }>
        <LegendCalloutArrow />
        <LegendCalloutBody>{children}</LegendCalloutBody>
      </LegendCalloutContainer>

      <Tooltip content="Show/hide legend">
        <ShowLegendButton
          name="LegendInfo"
          onClick={handleToggleLegendClicked}
          highlighted={expanded !== false}
        />
      </Tooltip>
    </InChartLegendContainer>
  );
};

function InChartLegendCategory({ title, children }) {
  return (
    <CategoryContainer>
      {title && <CategoryTitle>{title}</CategoryTitle>}
      {children}
    </CategoryContainer>
  );
}

InChartLegend.Category = InChartLegendCategory;

const CategoryTitle = styled.div`
  margin: 5rem 0 0 5rem;
  color: var(--color-blue-gray-50);
  font-size: var(--font-size-s);
  font-weight: bold;
`;

const CategoryContainer = styled.div`
  display: block;
`;

const InChartLegendContainer = styled.div`
  position: relative;
`;

const LegendTitle = styled.div`
  height: 13.75rem;
  padding: 5rem;
  margin-bottom: 3rem;
  font-size: var(--font-size-m);
  line-height: 4rem;
  font-weight: bold;
  background-color: var(--color-blue-gray-15);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

InChartLegend.Title = LegendTitle;

const LegendCalloutArrow = styled.div`
  position: absolute;
  height: 3rem;
  width: 3rem;
  box-sizing: border-box;
  transform: rotate(45deg);
  background: white;
  box-shadow: inherit;
  border: solid 0.275rem var(--color-blue-gray-30);
`;

const LegendCalloutContainer = styled.div`
  position: absolute;
  opacity: 1;
  max-height: 100vh;
  box-sizing: border-box;
  overflow: hidden;
  transition: opacity 300ms, max-height 300ms;

  &[data-transition="chart-legend-show"] {
    opacity: 1;
    max-height: 100vh;
  }

  &[data-transition="chart-legend-hide"] {
    opacity: 0;
    max-height: 0;
  }

  &[data-direction="right"] {
    padding-left: 2rem;
    top -3rem;
    left: 10rem;

    ${LegendCalloutArrow} {
      border-top: 0;
      border-right: 0;
      top: 5rem;
      left: 0.5rem;
    }    
  }

  &[data-direction="top"] {
    padding-bottom: 3rem;
    bottom: 10rem;
    left: -0.5rem;

    ${LegendCalloutArrow} {
      border-top: 0;
      border-left: 0;
      bottom: 1.5rem;
      left: 3.5rem;
    }
  }
  
  &[data-direction="bottom"] {
    padding-top: 2rem;
    top: 10rem;
    left: -0.5rem;

    ${LegendCalloutArrow} {
      border-bottom: 0;
      border-right: 0;
      top: 0.5rem;
      left: 3.5rem;
    }
  }
`;

const LegendCalloutBody = styled.div`
  border-radius: 3rem;
  box-shadow: 0 0.55rem 5.4rem 0 var(--color--light-gray-shadow-color);
  border: solid 0.275rem var(--color-blue-gray-30);
  background-color: var(--color-white);
  overflow: scroll;
`;

const ShowLegendButton = styled(CircularSvgIconButton)`
  width: 8rem;
  height: 8rem;
  box-shadow: var(--elevation-4);
`;

InChartLegend.Item = styled.div`
  flex-shrink: 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  font-size: var(--font-size-s);
`;
