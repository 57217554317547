import apiService from '@src/services/apiService';

const clearData = () => ({});

export default {
  state: clearData(),
  reducers: {
    clearData,
    setBuild: (state, buildData) => ({
      ...state,
      buildData,
      hasFetchedData: true,
    }),
    setHasFetchedData: (state, hasFetchedData) => ({
      ...state,
      hasFetchedData,
    }),
  },
  selectors: slice => ({
    hasFetchedData: () => slice(state => state.hasFetchedData),
    buildJobId: () => (rootState, props) => props.match.params.buildJobId,
    buildData: () => slice(state => state.buildData),
  }),
  effects: dispatch => ({
    async fetchData(buildJobId, invalidateCache) {
      const buildData = (await apiService.get(`/api/inspectBinaries/${buildJobId.buildJobId}`))
        .data;
      dispatch.commitPage.fetchData({
        key: buildData.sourceCommit,
        invalidateCache,
      });
      this.setBuild(buildData);
    },
  }),
};
