import map from 'lodash/map';
import { riskToCountPairs } from '@src/services/riskService';
import { HorizontalStack } from './HorizontalStack';
import { Link } from './Link';
import RiskIcon from './RiskIcon';
import { VerticalStack } from './VerticalStack';

export const RiskSummary = ({ countByLevel, riskUrl, className }) => {
  const riskCountRow = (riskLevel, count) => (
    <HorizontalStack key={riskLevel}>
      <RiskIcon riskLevel={riskLevel} />
      <span>{`${riskLevel}: ${count}`}</span>
    </HorizontalStack>
  );

  return (
    <VerticalStack className={className} spacing="3rem">
      {map(riskToCountPairs(countByLevel), ([riskLevel, count]) =>
        riskUrl ? (
          <Link key={riskLevel} url={riskUrl(riskLevel)}>
            {riskCountRow(riskLevel, count)}
          </Link>
        ) : (
          riskCountRow(riskLevel, count)
        )
      )}
    </VerticalStack>
  );
};
