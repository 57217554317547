import styled from 'styled-components';
import { BusinessImpactPopover, RiskFactorPopover } from '@src-v2/components/risk/risk-popovers';
import { usePopoverManager } from '@src-v2/components/risk/use-risk-popover';
import {
  getRiskColor,
  getRiskTextColor,
  getTextOverRiskColor,
  noRisk,
} from '@src-v2/data/risk-data';
import { humanize } from '@src-v2/utils/string-utils';

export function RiskBadge({ profile, placement = 'bottom-end', showBusinessImpact, ...props }) {
  const [popoverState, { closeAll, toggleRiskLevel, toggleBusinessImpact }] = usePopoverManager();

  return (
    <Container {...props}>
      <RiskFactorPopover
        title={
          profile.riskLevel !== 'none' ? humanize(`${profile.riskLevel} Risk`, true) : undefined
        }
        profile={profile}
        placement={placement}
        visible={popoverState === SHOW_RISK_LEVEL}
        onClickOutside={closeAll}>
        <RiskBadge.RiskLevel riskLevel={profile.riskLevel} onClick={toggleRiskLevel}>
          {profile.riskLevel === noRisk ? 'No Risk' : profile.riskLevel}
        </RiskBadge.RiskLevel>
      </RiskFactorPopover>
      {showBusinessImpact !== RiskBadge.BUSINESS_IMPACT_NEVER &&
        (profile.businessImpactLevel !== noRisk ||
          showBusinessImpact === RiskBadge.BUSINESS_IMPACT_ALWAYS) && (
          <BusinessImpactPopover
            businessImpact={profile.businessImpact}
            profile={profile}
            placement={placement}
            visible={popoverState === SHOW_BUSINESS_IMPACT}
            onClickOutside={closeAll}>
            <RiskBadge.BusinessImpactLevel
              riskLevel={profile.businessImpactLevel}
              onClick={toggleBusinessImpact}>
              {profile.businessImpactLevel !== noRisk
                ? `${profile.businessImpactLevel[0]}bi`
                : 'No Business Impact'}
            </RiskBadge.BusinessImpactLevel>
          </BusinessImpactPopover>
        )}
    </Container>
  );
}

RiskBadge.BUSINESS_IMPACT_ALWAYS = 'always';
RiskBadge.BUSINESS_IMPACT_NEVER = 'never';

RiskBadge.RiskLevel = styled.span`
  display: inline-flex;
  padding: 0 2rem;
  align-items: center;
  color: ${getTextOverRiskColor};
  color: ${props =>
    props.riskLevel === noRisk ? 'var(--color-blue-gray-50)' : getTextOverRiskColor(props)};
  font-size: var(--font-size-m);
  font-weight: 600;
  text-transform: capitalize;
  background-color: ${getRiskColor};
  border-radius: 0 1rem 1rem 0;
  pointer-events: ${props => (props.riskLevel === noRisk ? 'none' : 'unset')};
  cursor: pointer;
  z-index: 1;

  &:last-child {
    border-radius: 1rem;
  }
`;

RiskBadge.BusinessImpactLevel = styled.span`
  display: inline-flex;
  padding: 0 2rem;
  align-items: center;
  color: ${props =>
    props.riskLevel !== noRisk ? getRiskTextColor(props, true) : 'var(--color-blue-gray-50)'};
  font-size: ${props => (props.riskLevel !== noRisk ? 'var(--font-size-s)' : '2.25rem')};
  font-weight: 500;
  line-height: ${props => (props.riskLevel !== noRisk ? 'inherit' : '1.2')};
  text-transform: ${props => (props.riskLevel !== noRisk ? 'uppercase' : 'none')};
  border: 0.25rem solid ${getRiskColor};
  border-radius: 1rem 0 0 1rem;
  pointer-events: ${props => (props.riskLevel === noRisk ? 'none' : 'unset')};
  cursor: pointer;
`;

const Container = styled.div`
  display: inline-flex;
  height: 9rem;
  align-items: stretch;
  flex-direction: row-reverse;
  border-radius: 1rem;
  user-select: none;
  cursor: default;

  --risk-color-none: var(--color-blue-gray-20);

  ${RiskBadge.RiskLevel} {
    &:not(:last-child) {
      border-radius: 0 1rem 1rem 0;
    }
  }

  ${RiskBadge.BusinessImpactLevel} {
    position: relative;
    left: 0.25rem;
  }
`;

const SHOW_RISK_LEVEL = 1;
const SHOW_BUSINESS_IMPACT = 2;
