import { connect } from 'react-redux';
import { select } from '@src/store';
import TableSearch from './view';

const mapState = select(({ tableSearch: { filters, searchTerm, filterType, filterOptions } }) => ({
  filters,
  searchTerm,
  filterType,
  filterOptions,
}));

const mapDispatch = ({
  tableSearch: {
    setSearchTerm,
    clearSearch,
    clearFilters,
    updateFilterType,
    fetchFilterOptions,
    handleFilterToggle,
  },
}) => ({
  setSearchTerm,
  clearSearch,
  clearFilters,
  updateFilterType,
  fetchFilterOptions,
  handleFilterToggle,
});

export default connect(mapState, mapDispatch)(TableSearch);
