import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { Collapsible } from '@src-v2/components/collapsible';
import { DateTime } from '@src-v2/components/time';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink, Heading, Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  HighlightedCode,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';
import {
  getApiText,
  thenSubTypeToConjunction,
  thenSubTypeToVerb,
} from '@src-v2/containers/commit/material-changes-utils';
import { dateFormats } from '@src-v2/data/datetime';
import { pluralFormat } from '@src-v2/utils/number-utils';

export const ApiMaterialChange = ({
  commitSha,
  repository,
  relativeFilePath,
  materialChange,
  thenSubType,
}) => {
  const {
    apiGatewayReference,
    codeReference: { apiName, methodName, methodSignature, httpMethod, httpRoute, className },
  } = materialChange;

  return thenSubType === 'Altered' ? (
    <>
      <>
        API{' '}
        <Tooltip
          disabled={!methodSignature}
          content={<HighlightedCode language="java" code={methodSignature} />}>
          <span>{getApiText(httpMethod, httpRoute, methodName)}</span>
        </Tooltip>{' '}
        of{' '}
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}>
          {className ?? relativeFilePath}
        </CommitCodeReference>{' '}
        was <Strong>significantly changed</Strong>
      </>
      {apiGatewayReference && (
        <ApiGatewayReferenceDescription apiGatewayReference={apiGatewayReference} />
      )}
      <MaterialChangesRow>
        {materialChange.descriptions.map(description => (
          <Paragraph key={description}>{description}</Paragraph>
        ))}
      </MaterialChangesRow>
    </>
  ) : (
    <>
      <Paragraph>
        API was <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>{' '}
        {thenSubTypeToConjunction(thenSubType)} class{' '}
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}>
          {className || relativeFilePath}
        </CommitCodeReference>
      </Paragraph>
      <MaterialChangesRow>
        {apiGatewayReference && (
          <ApiGatewayReferenceDescription apiGatewayReference={apiGatewayReference} />
        )}
        <Tooltip content={<HighlightedCode language="java" code={methodSignature} />}>
          <Strong>{apiName ?? methodName}</Strong>
        </Tooltip>
      </MaterialChangesRow>
    </>
  );
};

const ApiGatewayReferenceDescription = ({ apiGatewayReference }) => (
  <ApiCollapsible
    title={
      <ExternalLink href={apiGatewayReference.gatewayUrl} tip="Open Gateway Settings" external>
        <MaterialChangesRow>
          <Paragraph>API is exposed through</Paragraph>
          <Paragraph>{apiGatewayReference.displayName}</Paragraph>
        </MaterialChangesRow>
      </ExternalLink>
    }>
    <>
      {apiGatewayReference.routeExposureTime && (
        <Paragraph>
          Route
          <Strong>{apiGatewayReference.exposingRoute}</Strong> exposed at{' '}
          <Strong>
            <DateTime date={apiGatewayReference.routeExposureTime} format={dateFormats.longDate} />
          </Strong>
        </Paragraph>
      )}
      <MaterialChangesRow>
        <Strong>Group includes</Strong>
        <Tooltip
          content={
            <>
              {apiGatewayReference.operations.map(operation => (
                <MaterialChangesRow>
                  {operation.method && <Paragraph>{operation.method}</Paragraph>}
                  <Paragraph>{operation.urls.join(', ')}</Paragraph>
                </MaterialChangesRow>
              ))}
            </>
          }>
          <>{pluralFormat(apiGatewayReference.operations.length, 'endpoint', null, true)}</>
        </Tooltip>
      </MaterialChangesRow>
      {!isEmpty(apiGatewayReference.serviceEndpointUrls) && (
        <ApiGatewayInfoLine
          title={`HTTP(s) 
              ${pluralFormat(apiGatewayReference.serviceEndpointUrls.length, 'endpoint')}`}
          value={apiGatewayReference.serviceEndpointUrls.join(', ')}
        />
      )}
      {!isEmpty(apiGatewayReference.listenPath) && (
        <ApiGatewayInfoLine title="Listen Path:" value={apiGatewayReference.listenPath} />
      )}
      {!isEmpty(apiGatewayReference.listenPort) && (
        <ApiGatewayInfoLine title="Listen Port:" value={apiGatewayReference.listenPort} />
      )}
      {!isEmpty(apiGatewayReference.forwardUrl) && (
        <ApiGatewayInfoLine title="Forward URL:" value={apiGatewayReference.forwardUrl} />
      )}
      {!isEmpty(apiGatewayReference.forwardPath) && (
        <ApiGatewayInfoLine title="Forward Path:" value={apiGatewayReference.forwardPath} />
      )}
      {!isEmpty(apiGatewayReference.apiSecurityControls) && (
        <>
          <Heading>API security controls:</Heading>
          <Paragraph>{apiGatewayReference.apiSecurityControls.join(', ')}</Paragraph>
        </>
      )}
    </>
  </ApiCollapsible>
);

const ApiGatewayInfoLine = ({ title, value }) => (
  <MaterialChangesRow>
    <Paragraph>{title}</Paragraph>
    <Paragraph>{value}</Paragraph>
  </MaterialChangesRow>
);

const ApiCollapsible = styled(Collapsible)`
  ${Collapsible.Title} {
    font-size: 14px !important;
  }
`;
