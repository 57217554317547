import styled from 'styled-components';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';
import { LegacyPaneStylingContainer } from '@src/blocks/RiskPosture/legacy-pane-styling-container';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import { Highlights } from '@src/components/CodeAggregation/Insights/Highlights';
import { StyledInsightsTitle } from '@src/components/CodeAggregation/Insights/InsightStyles';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { ElementPane } from '@src/components/ElementPane';
import { VerticalStack } from '@src/components/VerticalStack';
import ViewCode from '@src/components/ViewCode';
import { dispatch } from '@src/store';

export const openGqlOperationPaneWithRiskActions = ({
  ruleKey,
  element,
  profile,
  profileType,
  onClose,
  relevantPath,
  messageContent,
  externalRiskTriggerPane,
  level = secondaryPaneLevel,
}) => {
  const gqlOperation = element;
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    gqlOperation.entityKey,
    profile
  );
  dispatch.pane.openPane({
    level,
    onClose,
    relevantPath,
    id: gqlOperation.triggerKey,
    content: (
      <RiskRuleTrigger
        profile={profile}
        ruleKey={ruleKey}
        triggerKey={gqlOperation.triggerKey}
        externalRiskTriggerPane={externalRiskTriggerPane}
        getPane={({ element, ruleTriggers }) =>
          getGqlOperationPane({
            withRiskContext: true,
            gqlOperation: element,
            repository,
            profile,
            profileType,
            ruleTriggers,
            messageContent,
          })
        }
      />
    ),
  });
};

const getGqlOperationPane = ({
  withRiskContext,
  gqlOperation,
  repository,
  profile,
  profileType,
  ruleTriggers,
  messageContent,
}) => {
  return (
    <ElementPane
      element={gqlOperation}
      repository={repository}
      title={
        <InsightsPaneTitle
          withRiskContext={withRiskContext}
          element={gqlOperation}
          title={`GraphQL ${gqlOperation.operationType}: ${gqlOperation.name}`}
          codeReference={gqlOperation.codeReference}
          repository={repository}
          profile={profile}
          profileType={profileType}
          ruleTriggers={ruleTriggers}
          messageContent={messageContent}
        />
      }
      profileBody={getInsightsBody(gqlOperation, repository)}
    />
  );
};

export function LegacyGqlOperationPane({ gqlOperation, repository }) {
  return (
    <LegacyPaneStylingContainer>
      {getInsightsBody(gqlOperation, repository)}
    </LegacyPaneStylingContainer>
  );
}

const getHighlights = (gqlOperation, repository) => {
  const highlights = [];

  if (gqlOperation.resolverReference) {
    highlights.push(
      <span>
        has resolver code
        <StyledViewCode
          url={generateCodeReferenceUrl(repository, gqlOperation.resolverReference)}
          tip="View resolver code"
          inline={true}
        />
      </span>
    );
  }

  return highlights;
};

const getInsightsBody = (gqlOperation, repository) => (
  <>
    <Highlights highlights={getHighlights(gqlOperation, repository)} />
    <VerticalStack>
      <StyledInsightsTitle>Returns</StyledInsightsTitle>
      <StyledFieldType>{gqlOperation.returnType}</StyledFieldType>
    </VerticalStack>
  </>
);

const StyledFieldType = styled.span`
  color: var(--color-blue-gray-45);
`;

const StyledViewCode = styled(ViewCode)`
  margin-left: 3px;
  transform: scale(0.75);
`;
