import styled from 'styled-components';
import { HorizontalStack } from './HorizontalStack';

export const DescriptionBox = ({ title, spreadTitle, className, children }) => (
  <Container className={className}>
    <HorizontalStack spread={spreadTitle}>{title}</HorizontalStack>
    {children}
  </Container>
);

const Container = styled.div`
  display: flex;
  padding: 1rem;
  flex-direction: column;
  font-size: var(--font-size-m);
  font-weight: 300;
  line-height: 6rem;
  gap: 3rem;
`;
