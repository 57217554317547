import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { FontBody } from '@src/style/common';

export const StyledSortedList = styled(HorizontalStack)`
  ${FontBody};
`;

export const getStyledSortedList = items => (
  <StyledSortedList withSeparatorAfter wrapItems>
    {map(sortBy(items), item => (
      <span key={item}>{item}</span>
    ))}
  </StyledSortedList>
);
