import { Children, useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { DragHandle } from '@src-v2/components/drag-handle';

export function SplitView({ children, minSizes = [], defaultSizes = [] }) {
  const [splits, setSplits] = useState([]);
  const [movingSplitAndBase, setMovingSplitAndBase] = useState(null);
  const splitViewContainerRef = useRef(null);

  const filteredChildren = Children.toArray(children).filter(child => Boolean(child));

  useEffect(() => {
    setSplits(
      filteredChildren.map((child, index) =>
        Math.max(minSizes[index] || 0, defaultSizes[index] || 100)
      )
    );
  }, [filteredChildren.length]);

  const handleSplitMouseDown = useCallback(
    (event, index) => {
      event.target.setPointerCapture(event.pointerId);
      setMovingSplitAndBase({ index, baseClientY: event.clientY, baseSplit: splits[index] });
      event.preventDefault();
    },
    [splits, setMovingSplitAndBase]
  );

  const handleSplitMouseUp = useCallback(
    event => {
      event.target.releasePointerCapture(event.pointerId);
      setMovingSplitAndBase(null);
    },
    [setMovingSplitAndBase]
  );

  const handleSplitMouseMove = useCallback(
    event => {
      if (movingSplitAndBase) {
        setSplits(splits => {
          const splitIndex = movingSplitAndBase.index;
          const newSplits = [...splits];
          let newSplit =
            event.clientY - movingSplitAndBase.baseClientY + movingSplitAndBase.baseSplit;
          if (minSizes[splitIndex] && minSizes[splitIndex] > newSplit) {
            newSplit = minSizes[splitIndex];
          }
          newSplits[splitIndex] = newSplit;
          return newSplits;
        });
      }
    },
    [movingSplitAndBase]
  );

  return (
    <SplitViewContainer ref={splitViewContainerRef}>
      {filteredChildren.flatMap((child, index) => [
        <SplitViewContainerChild key={`child-${index}`} size={splits[index]}>
          {child}
        </SplitViewContainerChild>,
        index < filteredChildren.length - 1 && (
          <DragHandle
            key={`sep-${index}`}
            onPointerDown={event => handleSplitMouseDown(event, index)}
            onPointerUp={handleSplitMouseUp}
            onPointerMove={handleSplitMouseMove}
          />
        ),
      ])}
    </SplitViewContainer>
  );
}

const SplitViewContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SplitViewContainerChild = styled.div`
  height: ${props => `${props.size}px`};
  display: flex;
  align-items: fill;
  justify-items: stretch;
`;
