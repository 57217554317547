import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { ListItem, Paragraph, UnorderedList } from '@src-v2/components/typography';
import { pluralFormat } from '@src-v2/utils/number-utils';
import apiService from '@src/services/apiService';

export function SensitiveDataRulesIndicator() {
  const [rulesInfo, setRulesInfo] = useState({});

  useEffect(async () => {
    const rules = (await fetchSensitiveDataRules()) ?? [];
    setRulesInfo({
      rules: rules.splice(0, 3),
      additionalRulesCount: rules.length,
      loaded: true,
    });
  }, []);

  if (!rulesInfo.loaded) {
    return <LogoSpinner />;
  }

  return rulesInfo.rules.length ? (
    <Container>
      <Paragraph>
        This custom definition will effect the results of the following&nbsp;
        {pluralFormat(rulesInfo.rules.length, 'rule', 'rules')}:
      </Paragraph>
      <UnorderedList>
        {rulesInfo.rules.map(rule => (
          <ListItem key={rule.key}>
            RULE-{rule.ordinalId} &middot; {rule.name}
          </ListItem>
        ))}
      </UnorderedList>

      {Boolean(rulesInfo.additionalRulesCount) && (
        <Paragraph>and {rulesInfo.additionalRulesCount} more.</Paragraph>
      )}
    </Container>
  ) : (
    <Paragraph>No rules will be effected at this point.</Paragraph>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;

  ${ListItem} {
    font-weight: 700;
  }
`;

async function fetchSensitiveDataRules() {
  const { data: rules } = await apiService.get(
    `/api/definitions/customSensitiveDataDefinitions/rules`
  );
  return rules;
}
