import _ from 'lodash';

const getRepositoryKeysFilterOption = ({ profile, elements }) => {
  if (!profile?.repositoryByKey || _.isEmpty(elements)) {
    return;
  }

  const repositoryFilterOptions = _.uniq(
    elements
      .filter(element => element.repositoryKeys || element.repositoryKey)
      .flatMap(element => element.repositoryKeys ?? element.repositoryKey)
  ).map(repositoryKey => profile.repositoryByKey[repositoryKey]);

  if (!_.isEmpty(repositoryFilterOptions)) {
    return {
      name: 'RepositoryKeys',
      displayName: 'Repositories',
      filterOptions: repositoryFilterOptions.map(repository => ({
        name: repository.key,
        displayName: repository.name,
      })),
    };
  }
};

export const getDefaultFilterOptions = ({
  profile,
  elements,
  hasExternalConnectivity,
  integrations,
}) => {
  const defaultOptionCreators = [getRepositoryKeysFilterOption];

  return defaultOptionCreators
    .map(filterOption => filterOption({ profile, elements, hasExternalConnectivity, integrations }))
    .filter(_ => _);
};

const filterByRepositoryKeys = ({ filters, filterOperator, items }) => {
  const repositoriesFilter = filters.listFilters?.RepositoryKeys;
  return _.isEmpty(repositoriesFilter)
    ? items
    : items.filter(item =>
        filterOperator(
          repositoriesFilter?.map(
            repositoryKey =>
              item.repositoryKey === repositoryKey || item.repositoryKeys?.includes(repositoryKey)
          )
        )
      );
};

export const filterByDefaultOptions = (allItems, searchTerm, filters, filterOperator) => {
  const defaultFilterFunctions = [filterByRepositoryKeys];

  let filtered = allItems;
  for (const filterFunction of defaultFilterFunctions) {
    filtered = filterFunction({ items: filtered, searchTerm, filters, filterOperator });
  }

  return filtered;
};
