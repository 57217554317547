export class Processes {
  #client;
  #asyncCache;

  constructor({ apiClient, asyncCache }) {
    this.#client = apiClient;
    this.#asyncCache = asyncCache;
  }

  listProcessTags() {
    return this.#client.get('processes/v2');
  }

  listTagTypes() {
    return this.#client.get('processes/types');
  }

  async upsertProcessTag(data) {
    const response = await this.#client.request({
      method: data.key ? 'put' : 'post',
      url: 'processes',
      data,
    });
    this.#asyncCache.invalidate(this.listProcessTags);
    return response;
  }

  async deleteProcessTag({ key }) {
    await this.#client.delete(`processes/${key}`);
    this.#asyncCache.invalidate(this.listProcessTags);
  }
}
