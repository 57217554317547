import styled from 'styled-components';
import { CollapsibleCard } from '@src-v2/components/cards';
import { ClampText } from '@src-v2/components/clamp-text';
import { Collapsible } from '@src-v2/components/collapsible';
import { PropertiesPanel } from '../../../layout/properties-panel';

export default function ({ clusterResource, highlightsList, clusterProfile }) {
  return (
    <>
      <ClusterPaneCollapsibleCard defaultOpen title="Kubernetes">
        <PropertiesPanel.Section.Item
          highlighted={Boolean(
            highlightsList.find(highlight => highlight.propertyName === 'NativeClusterReference')
          )}>
          <ClampText>{clusterResource.nativeClusterReference}</ClampText>
        </PropertiesPanel.Section.Item>
        {clusterProfile.accountId && (
          <PropertiesPanel.Section.Item>
            <ClampText>{`Account ID: ${clusterProfile.accountId}`}</ClampText>
          </PropertiesPanel.Section.Item>
        )}
      </ClusterPaneCollapsibleCard>

      {Boolean(clusterResource.resourceTags.length) && (
        <ClusterPaneCollapsibleCard defaultOpen title="Tags">
          {clusterResource.resourceTags.map(resourceTag => (
            <PropertiesPanel.Section.Item
              key={resourceTag.key}
              highlighted={Boolean(
                highlightsList.find(
                  highlight =>
                    highlight.propertyName === 'ResourceTags' &&
                    highlight.propertyInternalIdRef === resourceTag.key
                )
              )}>
              <ResourceTagDisplay resourceTag={resourceTag} />
            </PropertiesPanel.Section.Item>
          ))}
        </ClusterPaneCollapsibleCard>
      )}
    </>
  );
}

export const ClusterPaneCollapsibleCard = styled(CollapsibleCard)`
  ${Collapsible.Head} {
    padding: 5rem 4rem 5rem;
  }

  ${Collapsible.Body} {
    padding: 0 4rem 0;
  }
`;

function ResourceTagDisplay({ resourceTag }) {
  switch (resourceTag.resourceTagTypeQualifier) {
    case 'KubernetesClusterResourceAnnotation':
    case 'KubernetesClusterResourceLabel':
      return <ClampText>{`${resourceTag.name}: ${resourceTag.value}`}</ClampText>;

    default:
      return <ClampText>{resourceTag.name}</ClampText>;
  }
}
