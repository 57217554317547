import styled from 'styled-components';
import { InsightTag } from '@src-v2/components/tags';

export const BadgesGroup = styled(({ badges, ...props }) => {
  return (
    <div {...props}>
      {badges.map(badge => (
        <InsightTag insight={badge} key={badge.key} hint={badge.tip} />
      ))}
    </div>
  );
})`
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;
