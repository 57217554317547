import _ from 'lodash';
import styled from 'styled-components';
import TableSearch from '@src/blocks/TableSearch';
import { InfiniteScroller } from './InfiniteScroller';
import { Table } from './Table';

export default function TableInfiniteScroll({
  filtersScope,
  itemsCount,
  totalCount,
  items,
  filters,
  filterType,
  rowProvider,
  emptyMessage,
  getRowOnClick,
  searchPlaceholder,
  allowSearchAndFilter,
  onFilterChange,
  searchFunc,
  initialFilterOptions,
  description,
  resultName,
  resultNamePlural,
  supportFilterTypeToggle,
  resultDisplay,
  customSearchBarSection,
  cellsBackground,
  downloadTable,
  scrollParent,
  ...props
}) {
  return (
    <InfiniteScrollerContainer>
      {allowSearchAndFilter && (
        <TableSearch
          table={filtersScope}
          onChange={onFilterChange}
          itemsCount={itemsCount}
          totalCount={totalCount}
          placeholder={searchPlaceholder ?? ''}
          description={description}
          resultDisplay={resultDisplay}
          initialFilterOptions={initialFilterOptions}
          supportFilterTypeToggle={supportFilterTypeToggle}
          customSearchBarSection={customSearchBarSection}
          downloadTable={downloadTable}
          resultName={resultName}
          resultNamePlural={resultNamePlural}
        />
      )}
      <InfiniteScroller
        scrollParent={scrollParent}
        parentFilterType={filterType}
        parentFilters={filters}
        searchFunc={searchFunc}
        contentIsSet={!_.isNil(items)}>
        {isLoading => (
          <Table
            key={JSON.stringify(items)}
            {...props}
            rows={_.map(items, rowProvider)}
            tableIsEmpty={_.isEmpty(items)}
            cellsBackground={cellsBackground}
            noTopPadding={allowSearchAndFilter}
            getRowOnClick={getRowOnClick && (({ index }) => getRowOnClick(items[index]))}
            isLoading={isLoading}
          />
        )}
      </InfiniteScroller>
    </InfiniteScrollerContainer>
  );
}

export const InfiniteScrollerContainer = styled.div`
  min-height: 24rem;
  border-radius: 3rem;
  box-shadow: var(--elevation-0);
  background-color: var(--color-white);
`;
