import { Redirect, matchPath } from 'react-router-dom';

/**
 * All external and legacy URLs will be directed into the RedirectAdapter
 * This adapter contains redirect logic for each legacy URL to avoid broken links
 */
export function RedirectAdapter({
  match: {
    params: { path },
  },
}) {
  const matchRiskTrigger = matchPath(path, {
    path: ':profileType(application|repository)/:profileKey/rule/:ruleKey/trigger/:triggerKey',
  });
  if (matchRiskTrigger) {
    const { profileType, profileKey, ruleKey, triggerKey } = matchRiskTrigger.params;
    const profilePath =
      profileType === 'application'
        ? `applications/${profileKey}/risk/design`
        : `repositories/${profileKey}/risk/development`;
    return (
      <Redirect to={`/profiles/${profilePath}/ruleTriggers/${ruleKey}/trigger/${triggerKey}`} />
    );
  }

  // default case:
  return <Redirect to={`/${path}`} />;
}
