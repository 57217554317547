import { observer } from 'mobx-react';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Card } from '@src-v2/components/cards';
import { Dropdown } from '@src-v2/components/dropdown';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { SvgIcon } from '@src-v2/components/icons';
import { DateTime, DistanceTime } from '@src-v2/components/time';
import { Caption1, Heading } from '@src-v2/components/typography';
import { JwtCopyBanner } from '@src-v2/containers/access-tokens/jwt-copy-banner';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { dataAttr } from '@src-v2/utils/dom-utils';

export const AccessTokensListItem = observer(({ accessToken, onDelete }) => {
  const { accessTokens, rbac, history, application } = useInject();
  const jwt = accessTokens.getJwt(accessToken.key);

  return (
    <TokenCard key={accessToken.key} data-has-jwt={dataAttr(jwt)}>
      <ItemContainer>
        <ItemContent>
          <Caption1>
            {createTokenTypeCaption(
              accessToken.permissions.map(({ resourceName }) => resourceName)
            )}
          </Caption1>
          <Heading>{accessToken.name}</Heading>
          <ItemDescription>
            {accessToken.expired ? 'Expired' : 'Expires'} on{' '}
            <DateTime date={accessToken.expirationTime} format="PPP" />
            {accessToken.lastUsedTime && (
              <>
                {' '}
                &middot; Last used{' '}
                <DistanceTime
                  date={accessToken.lastUsedTime}
                  baseDate={useMemo(() => new Date(), [])}
                  addSuffix
                  strict
                />
              </>
            )}
          </ItemDescription>
        </ItemContent>

        <ActivityTag
          data-status={
            accessToken.expired ? 'expired' : accessToken.aboutToExpire ? 'expires-soon' : null
          }>
          {accessToken.expired
            ? 'Expired'
            : accessToken.aboutToExpire
              ? 'About to expire'
              : 'Active'}
        </ActivityTag>

        {((rbac.canEdit(resourceTypes.Global) && rbac.hasGlobalScopeAccess) ||
          application.isFeatureEnabled(FeatureFlag.AccessTokenForm)) && (
          <DropdownMenu>
            <Dropdown.Item
              onClick={() =>
                history.push(`/settings/access-permissions/tokens/${accessToken.key}/edit`)
              }>
              <SvgIcon name="Edit" /> Edit
            </Dropdown.Item>
            <Dropdown.Item onClick={() => onDelete(accessToken)}>
              <SvgIcon name="Trash" /> Delete
            </Dropdown.Item>
          </DropdownMenu>
        )}
      </ItemContainer>

      {jwt && <JwtCopyBanner jwt={jwt} />}
    </TokenCard>
  );
});

const TokenCard = styled(Card)`
  &:not([data-has-jwt], :last-child) {
    border-bottom: 0.25rem solid var(--color-blue-gray-20);
  }
`;

const ItemContainer = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 5rem;
`;

const ItemContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  ${Heading} {
    font-weight: 600;
  }

  ${Caption1} {
    color: var(--color-blue-gray-55);
  }
`;

const ItemDescription = styled.span`
  font-size: var(--font-size-s);
  font-weight: 400;
  line-height: 4rem;
  color: var(--color-blue-gray-60);
`;

const ActivityTag = styled.div`
  padding: 0 3rem;
  line-height: 8rem;
  border-radius: 100vmax;
  background-color: var(--color-green-25);

  &[data-status='expires-soon'] {
    background-color: var(--color-red-25);
  }

  &[data-status='expired'] {
    color: var(--color-white);
    background-color: var(--color-failure);
  }
`;

const tokenTypeMapper = {
  [resourceTypes.NetworkBrokerConfigurations]: 'Network Broker token',
  [resourceTypes.GitLabResources]: 'Webhook token',
};

export const createTokenTypeCaption = permissionsKeys => {
  return tokenTypeMapper[permissionsKeys?.[0]] ?? 'Rest API token';
};
