import styled from 'styled-components';
import ApiiroLogoSmall from '@src-v2/assets/apiiro-logo-small.svg';
import { VendorIcon } from '@src-v2/components/icons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { CircledIcon } from './styles';

export const RiskSource = ({ rule, triggers }) => (
  <Container>
    {isApiiroProvidedFinding(rule) && (
      <Tooltip content="Apiiro">
        <CircledIcon as={ApiiroLogoSmall} />
      </Tooltip>
    )}
    {getRelatedProviders(rule, triggers).map(relatedProvider => (
      <Tooltip key={relatedProvider} content={relatedProvider}>
        <CircledIcon as={VendorIcon} name={relatedProvider} />
      </Tooltip>
    ))}
  </Container>
);

const relatedProviders = [
  'Checkmarx',
  'Veracode',
  'Fortify',
  'Snyk',
  'Mend',
  'BlackDuck',
  'SonatypeLifecycle',
  'Semgrep',
  'SonarCloud',
  'SonarQube',
  'Dependabot',
  'ShiftLeft',
  'Github',
  'Gitlab',
];

const isSecurityProviderRule = (rule, provider) =>
  rule.whenTypes.includes(`${provider}Vulnerability`) ||
  rule.when.some(when => when.type === 'MissingSASTScan' && when.subType === provider);

const isSecurityProviderRelated = (rule, triggers, provider) =>
  isSecurityProviderRule(rule, provider) ||
  ((rule.whenTypes.includes('LegacySastFindings') || rule.whenTypes.includes('ScaFindings')) &&
    triggers.some(trigger => trigger.providers?.includes(provider)));

const getRelatedProviders = (rule, triggers) =>
  relatedProviders.filter(provider => isSecurityProviderRelated(rule, triggers, provider));

const isApiiroProvidedFinding = rule =>
  rule.whenTypes.some(whenType => whenType !== 'ExternalGeneralFinding');

const Container = styled.div`
  display: flex;
  gap: 2rem;
`;
