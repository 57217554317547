import toPairs from 'lodash/toPairs';
import apiService from '@src/services/apiService';
import { createDebouncedAsyncCall } from '@src/utils/debouncedAsyncCalls';

const clearData = () => ({
  loading: false,
  error: null,
  selectedProcessTag: null,
  processTypes: [],
  newProcess: null,
  editMode: false,
});

export default {
  state: clearData(),
  selectors: slice => ({
    selectedProcessTag: () => slice(({ selectedProcessTag }) => selectedProcessTag),
    loading: () => slice(({ loading }) => loading),
    error: () => slice(({ error }) => error),
    processTypes: () => slice(({ processTypes }) => processTypes),
    newProcess: () => slice(({ newProcess }) => newProcess),
    editMode: () => slice(({ editMode }) => editMode),
  }),
  reducers: {
    setSelectedProcess: (state, selectedProcessTag) => ({
      ...state,
      selectedProcessTag,
    }),
    setLoading: (state, loading) => ({
      ...state,
      loading,
    }),
    setError: (state, error) => ({
      ...state,
      error,
    }),
    setProcessTypes: (state, processTypes) => ({
      ...state,
      processTypes,
    }),
    setNewProcess: (state, newProcess) => ({
      ...state,
      newProcess,
    }),
    setEditMode: (state, editMode) => ({
      ...state,
      editMode,
    }),
    clearData,
  },
  effects: () => {
    return {
      async createProcessTag(newProcessTag) {
        try {
          this.setError(null);
          this.setLoading(true);
          const createdProcess = (await apiService.post('/api/processes', newProcessTag)).data;
          this.setSelectedProcess({ label: createdProcess.name, value: createdProcess.key });
          return createdProcess;
        } catch (error) {
          this.setError(error.response?.data ?? 'An error occurred');
        } finally {
          this.setLoading(false);
        }
      },

      async fetchProcessTypes() {
        const fetchedTypes = toPairs((await apiService.get('/api/processes/types')).data).map(
          ([type, description]) => ({ name: description, key: type })
        );

        this.setProcessTypes(fetchedTypes);
      },

      getFilteredProcesses: createDebouncedAsyncCall(async ({ searchTerm }, state) =>
        (await apiService.get(`/api/processes?filter=${searchTerm}`)).data
          .filter(process => state.processTagSelector?.selectedProcessTag?.value !== process.key)
          .map(process => ({ label: process.name, value: process.key }))
      ),
    };
  },
};
