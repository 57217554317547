import { connect } from 'react-redux';
import { select } from '@src/store';
import RiskyIssueDefinition from './view';

const mapState = select(
  ({
    projectProfiles: { profilesByKey: projectProfilesByKey },
    assetCollectionProfiles: { profilesByKey: assetCollectionProfilesByKey },
  }) => ({
    projectProfilesByKey,
    assetCollectionProfilesByKey,
  })
);

const mapDispatch = ({ riskyIssueDefinition: { fetchData } }) => ({
  fetchData,
});

export default connect(mapState, mapDispatch)(RiskyIssueDefinition);
