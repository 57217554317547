import { useCombobox, useMultipleSelection } from 'downshift';
import _ from 'lodash';
import { Children, forwardRef, useCallback, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { Chip } from '@src-v2/components/chips';
import { CollapsibleLite } from '@src-v2/components/collapsible-lite';
import { Dropdown } from '@src-v2/components/dropdown';
import { Input } from '@src-v2/components/forms/input';
import { stateReducer } from '@src-v2/components/forms/multi-select';
import { BaseIcon, SvgIcon, VendorIcon } from '@src-v2/components/icons';
import { RepositoryInfoTooltip } from '@src-v2/components/repositories/repository-info-tooltip';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { SearchCombobox } from '@src-v2/containers/search-combobox';
import { defaultItemToString } from '@src-v2/hooks/use-downshift';

export const MultiAssetsCollection = forwardRef(
  (
    {
      items,
      moduleRepository,
      disabled,
      itemToString = defaultItemToString,
      value: defaultSelectedItems = [],
      totalCount,
      selectedItems: controlledItems,
      onSelect: onSelectedItemsChange,
      maxSize = 3,
      chip: Chip = MultiAssetCollectionChip,
      isGroup,
      placeholder,
      hideCounter,
      ...props
    },
    ref
  ) => {
    const [inputValue, setInputValue] = useState('');
    const [transformedItems, setTransformedItems] = useState([]);
    const {
      selectedItems,
      addSelectedItem,
      removeSelectedItem,
      getSelectedItemProps,
      getDropdownProps,
    } = useMultipleSelection({
      defaultSelectedItems,
      onSelectedItemsChange,
      // must not define a key if the value is undefined
      ...(controlledItems && { selectedItems: controlledItems }),
    });

    useEffect(() => {
      const newItems = items?.map(item => ({
        ...item,
        isHighlight:
          selectedItems?.find(selected => selected.key === item.key)?.isHighlight ?? false,
      }));
      setTransformedItems(newItems);

      if (props.name === 'modulesGroup') {
        const shouldClearItems =
          selectedItems.length > 0 &&
          selectedItems.some(item => moduleRepository?.key !== item.repositoryKey);

        if (shouldClearItems) {
          selectedItems?.forEach(removeSelectedItem);
        }
      }
    }, [items, selectedItems, moduleRepository]);

    const itemsFilter = useMemo(() => {
      const selectedKeys = selectedItems?.map(item => item.key);

      return item => {
        return selectedKeys.every(selectedKey => item.key !== selectedKey);
      };
    }, [selectedItems]);

    const handleStateChange = useCallback(
      ({ type, inputValue, selectedItem }) => {
        switch (type) {
          case useCombobox.stateChangeTypes.InputChange:
            setInputValue(inputValue);
            break;
          case useCombobox.stateChangeTypes.InputBlur:
            setInputValue('');
            break;
          case useCombobox.stateChangeTypes.InputKeyDownEnter:
          case useCombobox.stateChangeTypes.ItemClick:
            if (selectedItem) {
              addSelectedItem(selectedItem);
            }
            break;
          // no default
        }
      },
      [setInputValue, addSelectedItem]
    );

    const hide = hideCounter || (items.length === 0 && selectedItems.length === 0);

    const [highlightItems, existingItems] = useMemo(
      () => _.partition(selectedItems, 'isHighlight'),
      [selectedItems]
    );

    return (
      <Container>
        <MultiAssetsCollection.SearchCombobox
          {...props}
          ref={ref}
          items={transformedItems}
          selectedItem={null}
          disabled={disabled}
          inputValue={inputValue}
          defaultHighlightedIndex={0}
          itemToString={itemToString}
          getDropdownProps={getDropdownProps}
          onStateChange={handleStateChange}
          stateReducer={stateReducer}
          itemsFilter={itemsFilter}
          placeholder={items.length === 0 || !placeholder ? 'No Options' : placeholder}>
          <ItemsCounter>
            {!hide && `${selectedItems.length} / ${totalCount ?? items.length}`}
          </ItemsCounter>
        </MultiAssetsCollection.SearchCombobox>
        {selectedItems?.length > 0 && (
          <MultiAssetsCollection.ChipsContainer>
            {highlightItems.length > 0 && (
              <HighlightsContainer>
                <HighlightsMenu>
                  <Tooltip content="Linked by developers activity">
                    <SvgIcon name="Info" />
                  </Tooltip>
                  <Tooltip content="Decline all repositories group suggestions">
                    <CloseIcon
                      name="Close"
                      onClick={() => highlightItems.forEach(removeSelectedItem)}
                    />
                  </Tooltip>
                </HighlightsMenu>
                <ItemsContainer
                  items={highlightItems}
                  getSelectedItemProps={getSelectedItemProps}
                  removeSelectedItem={removeSelectedItem}
                  itemToString={itemToString}
                  disabled={disabled}
                  isGroup={isGroup}
                  chip={Chip}
                  maxSize={maxSize}
                  isHighlight
                />
              </HighlightsContainer>
            )}
            <ItemsContainer
              items={existingItems}
              getSelectedItemProps={getSelectedItemProps}
              removeSelectedItem={removeSelectedItem}
              itemToString={itemToString}
              disabled={disabled}
              isGroup={isGroup}
              chip={Chip}
              maxSize={maxSize}
            />
          </MultiAssetsCollection.ChipsContainer>
        )}
      </Container>
    );
  }
);

const HighlightsContainer = styled.div`
  width: 100%;
  padding: 2rem;
  margin-bottom: 3rem;
  border: 1px solid var(--color-green-40);
  border-radius: 1rem;
  background-color: var(--color-white);
`;

const ItemsContainer = ({
  items,
  maxSize = 3,
  disabled,
  isGroup,
  getSelectedItemProps,
  removeSelectedItem,
  itemToString,
  isHighlight,
  chip: Chip,
  ...props
}) => (
  <ItemsContainerWrapper>
    <MyShowMore maxSize={maxSize}>
      {items.map((selectedItem, index) => {
        const providerGroup = selectedItem?.providerGroup ?? selectedItem?.server?.providerGroup;
        return (
          <Chip
            key={selectedItem.key ?? selectedItem.name}
            data-highlight={String(selectedItem.isHighlight)}
            item={selectedItem}
            {...props}
            {...(!disabled &&
              getSelectedItemProps({
                index,
                selectedItem,
                onRemove: event => {
                  event.stopPropagation();
                  removeSelectedItem(selectedItem);
                },
              }))}>
            {providerGroup && <VendorIcon name={providerGroup} />}
            {renderChipLabel({ item: selectedItem, isGroup })}
          </Chip>
        );
      })}
    </MyShowMore>
  </ItemsContainerWrapper>
);

const ShowMore = ({ children, maxSize = 3, ...props }) => {
  const childrenArray = Children.toArray(children);
  return (
    <>
      {children.slice(0, maxSize)}
      {childrenArray.length > maxSize && (
        <CollapsibleLite moreCount={childrenArray.length - maxSize} {...props}>
          {children.slice(maxSize)}
        </CollapsibleLite>
      )}
    </>
  );
};

const MyShowMore = styled(ShowMore)`
  width: 100%;
`;

MultiAssetsCollection.SearchCombobox = styled(SearchCombobox)`
  width: 80rem;
  padding: 0 2rem;
  flex-wrap: wrap;
  background-color: ${props =>
    props.disabled ? 'var(--color-blue-gray-15)' : 'var(--color-white)'};
  box-shadow: inset 0 0.5rem 0 var(--input-shadow-color);
  border: 0.25rem solid var(--color-blue-gray-30);
  border-radius: 1rem;

  &:focus-within {
    border-color: var(--color-black);
  }

  &[data-invalid] {
    border-color: var(--color-red-55);

    &:hover {
      border-color: var(--color-red-60);
    }
  }

  ${Input} {
    width: calc(100% - 15rem);
    height: 8rem;
    padding: 0 1rem;
    line-height: 5rem;
    box-shadow: none;
    border: none;
    background-color: transparent;
    flex-grow: 1;
    order: 1;

    &:disabled {
      display: none;
    }
  }
`;

MultiAssetsCollection.ChipsContainer = styled.div`
  width: 80rem;
  box-shadow: inset 0 0.5rem 0 var(--input-shadow-color);
  padding: 3rem;
  border-radius: 2rem;
  background-color: #f0f4f7;

  ${CollapsibleLite.Body} {
    max-width: calc(100% - 6rem);
  }

  ${CollapsibleLite.Button} {
    font-size: var(--font-size-s);
    font-weight: 400;
    margin-left: 2rem;
    cursor: pointer;
  }
`;

export const DropdownItemWithVendorIcon = styled(
  forwardRef(({ item, creatable, isGroup, children, useLabel = false, ...props }, ref) => {
    const providerGroup = item.value?.providerGroup ?? item.value?.server?.providerGroup;
    return (
      <Dropdown.Item ref={ref} {...props}>
        {providerGroup && <VendorIcon name={providerGroup} />}
        {creatable ? (
          <>Create "{children}"</>
        ) : (
          <>{useLabel ? item.label : renderChipLabel({ item: item?.value, isGroup })}</>
        )}
      </Dropdown.Item>
    );
  })
)`
  display: flex;
  align-items: center;
  gap: 2rem;
`;

export const DropdownItemWithTooltip = forwardRef(
  ({ item, tooltipContent: TooltipContent = RepositoryInfoTooltip, ...props }, ref) => (
    <Tooltip
      delay={[300, 200]}
      disabled={!item?.value}
      content={<TooltipContent item={item.value} />}>
      <DropdownItemWithVendorIcon {...props} ref={ref} item={item} />
    </Tooltip>
  )
);

export function assetItemToString(item) {
  return item ? `${item.name} (${item.providerGroup ?? item?.server?.providerGroup})` : '';
}

function renderChipLabel({ item, isGroup }) {
  return [item?.root || item?.name || item?.displayName || item?.key]
    .concat(
      item?.isMonitored !== false || isGroup
        ? ''
        : item?.isIgnored
          ? ' (ignored)'
          : ' (not monitored)'
    )
    .join(' ');
}

export const MultiAssetCollectionChip = styled(
  forwardRef((props, ref) => <Chip {...props} ref={ref} onClick={null} />)
)`
  ${BaseIcon}:first-child {
    padding: 1rem;
  }
`;

export const ProviderChipWithTooltip = forwardRef(({ item, selectedItem, ...props }, ref) => {
  const providerItem = item ?? selectedItem;

  return (
    <Tooltip content={<RepositoryInfoTooltip item={providerItem} />}>
      <MultiAssetCollectionChip {...props} ref={ref} item={providerItem} />
    </Tooltip>
  );
});

const ItemsContainerWrapper = styled.div`
  &,
  ${CollapsibleLite.Body} {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4rem;
`;

const ItemsCounter = styled.span`
  display: block;
  position: absolute;
  top: 1rem;
  right: 0;
  color: var(--color-blue-gray-45);
`;

const HighlightsMenu = styled.span`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 2rem;
  padding: 0 1rem;
  float: right;

  ${BaseIcon} {
    padding: 0.5rem;
  }
`;

const CloseIcon = styled(SvgIcon)`
  border-radius: 100vmax;
  background-color: var(--color-blue-gray-25);
  cursor: pointer;
`;
