import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink, Paragraph, Strong } from '@src-v2/components/typography';
import {
  HighlightedCode,
  MaterialChangesContainer,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';
import { pluralFormat } from '@src-v2/utils/number-utils';

const VulnerabilitiesChangeDescription = ({ additionalVulnerabilitiesByTypes, connectorText }) => (
  <>
    {Object.entries(additionalVulnerabilitiesByTypes).map(([vulnerabilityType, count]) => (
      <MaterialChangesContainer>
        <Paragraph>found</Paragraph>
        <Paragraph>
          {pluralFormat(count, `${connectorText} ${vulnerabilityType}`, null, true)}
        </Paragraph>
        <Paragraph>for this API</Paragraph>
      </MaterialChangesContainer>
    ))}
  </>
);

export const ApiCheckmarxVulnerabilityMaterialChange = ({ materialChange }) => {
  const { methodSignature, apiName, methodName } = materialChange.codeReference;
  return (
    <>
      <MaterialChangesRow>
        <ExternalLink href={materialChange.latestScanUrl}>
          <Strong>according to correlated Checkmarx scan</Strong>
        </ExternalLink>
        <VulnerabilitiesChangeDescription
          additionalVulnerabilitiesByTypes={materialChange.addedVulnerabilitiesByType}
          connectorText="additional"
        />
        <VulnerabilitiesChangeDescription
          additionalVulnerabilitiesByTypes={materialChange.addedVulnerabilitiesByType}
          connectorText="less"
        />
        <Tooltip content={<HighlightedCode language="java">{methodSignature}</HighlightedCode>}>
          <Paragraph>{apiName ?? methodName} </Paragraph>
        </Tooltip>
      </MaterialChangesRow>
    </>
  );
};
