import isEmpty from 'lodash/isEmpty';
import isNumber from 'lodash/isNumber';

export const getCommitUrl = (
  repositoryKey,
  commitSha,
  demoBlockIndex = null,
  demoCommitIndex = null,
  moduleKey = null
) => {
  let url = `/commit/${repositoryKey}/${commitSha}`;
  url = conditionalAppend(
    url,
    `/demo/${demoBlockIndex}/${demoCommitIndex}`,
    isNumber(demoCommitIndex)
  );
  url = conditionalAppend(url, `?module=${encodeURIComponent(moduleKey)}`, !isEmpty(moduleKey));

  return url;
};

const conditionalAppend = (source, addition, condition) => (condition ? source + addition : source);
