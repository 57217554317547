import isEmpty from 'lodash/isEmpty';
import orderBy from 'lodash/orderBy';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Badge } from '@src-v2/components/badges';
import { VendorIcon } from '@src-v2/components/icons';
import { ProcessTag } from '@src-v2/components/tags';
import { DateTime } from '@src-v2/components/time';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink, Light, Paragraph, Strong } from '@src-v2/components/typography';
import { ContributorAvatar } from '@src-v2/containers/contributors/contributor-avatar';
import { ContributorLink } from '@src-v2/containers/contributors/contributor-link';
import { generateIssueReferenceUrl } from '@src-v2/data/connectors';
import { dateFormats } from '@src-v2/data/datetime';
import { stringifyIssueId } from '@src-v2/data/ticketing-issues-provider';
import { useInject, useSuspense } from '@src-v2/hooks';
import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { primaryPaneLevel, secondaryPaneLevel } from '@src/blocks/Pane/model';
import { LegacyPaneStylingContainer } from '@src/blocks/RiskPosture/legacy-pane-styling-container';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import { Highlights } from '@src/components/CodeAggregation/Insights/Highlights';
import { StyledDescription } from '@src/components/CodeAggregation/Insights/InsightStyles';
import { getProjectForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { ElementPane } from '@src/components/ElementPane';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Link } from '@src/components/Link';
import { PageLoading } from '@src/components/PageLoading';
import { VerticalStack } from '@src/components/VerticalStack';
import { dispatch } from '@src/store';
import { FontBody } from '@src/style/common';

const getInsightsTitle = (issue, project) => (
  <Link
    external
    underline
    openInNewTab
    url={generateIssueReferenceUrl(project, issue.id)}
    tip="View issue">
    {issue.id.startsWith(project.name)
      ? addInterpunctSeparator(issue.type, issue.id)
      : addInterpunctSeparator(project.name, issue.type, issue.id)}
  </Link>
);

const getHighlights = (issue, project) => {
  const highlights = [];

  highlights.push(
    <HorizontalStack>
      Found in ticket
      <ExternalLink href={issue.externalUrl}>
        <VendorIcon name={project.server.provider} /> {stringifyIssueId(issue.id)}
      </ExternalLink>
    </HorizontalStack>
  );

  if (!isEmpty(issue.processTags)) {
    highlights.push(
      <ProcessesContainer>
        <Light>Processes:</Light>
        {orderBy(issue.processTags, ['type', _ => _.name.toLowerCase()]).map(
          (processTag, index) => (
            <ProcessTag key={index}>{processTag.name}</ProcessTag>
          )
        )}
      </ProcessesContainer>
    );
  }

  if (issue.creationTime) {
    highlights.push(
      <span>
        Created on <DateTime date={issue.creationTime} format={dateFormats.longDate} />
      </span>
    );
  }

  return highlights;
};

const InsightsBody = ({ issue, assigneeProfiles, isLoading, highlights }) => {
  const issueLabels = useMemo(() => issue?.labels?.filter(Boolean), [issue.labels]);

  return isLoading ? (
    <PageLoading />
  ) : (
    <VerticalStack>
      <Highlights highlights={highlights} />
      <InfoLine>
        <TitleStrong>Title</TitleStrong>
        <Paragraph>{issue.title}</Paragraph>
      </InfoLine>
      {!isEmpty(issue.description) && (
        <InfoLine>
          <Strong>Description</Strong>
          <DescriptionWithHints description={issue.description} riskHints={issue.riskHints} />
        </InfoLine>
      )}
      <InfoLine>
        <Strong>Assignees</Strong>
        <VerticalStack>
          {isEmpty(assigneeProfiles) ? (
            <span>None</span>
          ) : (
            assigneeProfiles.map(assigneeProfile => (
              <ContributorLink
                developerProfile={assigneeProfile}
                key={assigneeProfile.representativeIdentityKeySha}>
                <HorizontalStack>
                  <ContributorAvatar
                    size="small"
                    avatarUrl={assigneeProfile.avatarUrl}
                    name={assigneeProfile.displayName}
                  />
                  <span>{assigneeProfile.displayName}</span>
                </HorizontalStack>
              </ContributorLink>
            ))
          )}
        </VerticalStack>
      </InfoLine>

      {Boolean(issueLabels?.length) && (
        <InfoLine>
          <Strong>Labels</Strong>
          <StyledInsightsBody withSeparatorAfter wrapItems>
            {issueLabels.map(label => (
              <Badge key={label}>{label}</Badge>
            ))}
          </StyledInsightsBody>
        </InfoLine>
      )}
      {!isEmpty(issue.components) && (
        <InfoLine>
          <Strong>Components</Strong>
          <StyledInsightsBody withSeparatorAfter wrapItems>
            {issue.components.map(component => (
              <Badge key={component}>{component}</Badge>
            ))}
          </StyledInsightsBody>
        </InfoLine>
      )}
    </VerticalStack>
  );
};

const getIssuePane = ({
  trigger,
  issue,
  project,
  profile,
  profileType,
  ruleTriggers,
  isLoading,
  assigneeProfiles,
  messageContent,
}) => {
  const highlights = getHighlights(issue, project);
  return (
    <ElementPane
      element={issue}
      repository={profile.repository}
      title={
        <InsightsPaneTitle
          trigger={trigger}
          element={issue}
          title={getInsightsTitle(issue, project)}
          noTitleTip
          profile={profile}
          profileType={profileType}
          ruleTriggers={ruleTriggers}
          messageContent={messageContent}
          repository={profile.repository}
        />
      }
      profileBody={
        <InsightsBody
          issue={issue}
          assigneeProfiles={assigneeProfiles}
          isLoading={isLoading}
          highlights={highlights}
        />
      }
    />
  );
};

export function LegacyIssuePane({ risk, issue }) {
  const project = getProjectForInventoryPropertyFromProfileByKey(
    risk.elementEntityKey ?? risk.elementKey,
    risk.profile
  );

  const { developers } = useInject();
  const assigneeProfiles = useSuspense(developers.getDeveloperProfiles, {
    keys: risk.assigneeIdentitiesKeys,
  });

  const highlights = getHighlights(issue, project);
  return (
    <IssueStylingContainer>
      <InsightsBody
        issue={issue}
        assigneeProfiles={assigneeProfiles}
        isLoading={false}
        highlights={highlights}
      />
    </IssueStylingContainer>
  );
}

export const openIssuePaneWithRiskActions = ({
  ruleKey,
  trigger,
  profile,
  profileType,
  relevantPath,
  onClose,
  messageContent,
  externalRiskTriggerPane,
  level = secondaryPaneLevel,
}) => {
  const project = getProjectForInventoryPropertyFromProfileByKey(trigger.elementEntityKey, profile);

  dispatch.pane.openPane({
    level,
    onClose,
    relevantPath,
    id: trigger.key,
    content: (
      <RiskRuleTrigger
        profile={profile}
        ruleKey={ruleKey}
        trigger={trigger}
        externalRiskTriggerPane={externalRiskTriggerPane}
        developerIdentityKeys={trigger.assigneeIdentitiesKeys}
        getPane={({ element, ruleTriggers, developerProfiles, isLoading }) =>
          getIssuePane({
            trigger,
            issue: element,
            project,
            profile,
            profileType,
            ruleTriggers,
            isLoading,
            assigneeProfiles: developerProfiles,
            messageContent,
          })
        }
      />
    ),
  });
};

const DescriptionWithHints = ({ description, riskHints }) => {
  const hintsSet = new Set(riskHints?.map(hint => hint.toLowerCase()) ?? []);
  const descriptionSegments = description.split(/\b/);
  return (
    <StyledDescription>
      {descriptionSegments.map((segment, index) =>
        hintsSet.has(segment.toLowerCase()) ? (
          <Tooltip
            key={index}
            content="Apiiro’s NLP engine marked this ticket as risky based on the presence of the highlighted terms">
            <Strong>{segment}</Strong>
          </Tooltip>
        ) : (
          segment
        )
      )}
    </StyledDescription>
  );
};

export const openIssuePane = ({ issue, profile, profileType }) => {
  const project = getProjectForInventoryPropertyFromProfileByKey(issue.projectKey, profile);

  dispatch.pane.openPane({
    id: issue.key,
    level: primaryPaneLevel,
    content: getIssuePane({
      issue,
      project,
      profile,
      profileType,
      assigneeProfiles: issue.assigneeProfiles,
    }),
  });
};

const InfoLine = styled.div`
  &:not(:last-child) {
    margin-bottom: 2rem;
  }

  ${StyledDescription} {
    white-space: pre-line;
  }
`;

const StyledInsightsBody = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  ${FontBody};
`;

const ProcessesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

const TitleStrong = styled(Strong)`
  margin-top: 1rem;
`;

const IssueStylingContainer = styled(LegacyPaneStylingContainer)`
  & > ${VerticalStack} {
    gap: 4rem;

    ${TitleStrong} {
      font-size: var(--font-size-s);
      font-weight: 400;
      text-decoration: underline;
    }

    ${StyledDescription} {
      font-weight: unset;
    }

    ${InfoLine} {
      margin-bottom: 0;
    }

    ${ExternalLink} {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
  }
`;
