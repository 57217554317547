import _ from 'lodash';
import apiService from '@src/services/apiService';
import filtersService from '@src/services/filtersService';

const cleanFilters = () =>
  _.cloneDeep({
    booleanFilters: [],
    listFilters: {},
  });

const clearData = () => ({
  searchTerm: '',
  filters: cleanFilters(),
  filterType: filtersService.filterTypes.AND,
  filterOptions: [],
});

export default {
  state: {
    ...clearData(),
  },
  selectors: slice => ({
    searchTerm: () => slice(state => state?.searchTerm),
    filters: () => slice(state => state?.filters),
    filterType: () => slice(state => state?.filterType),
    filterOptions: () =>
      slice(state =>
        state ? filtersService.constructFilterOptions(state.filters, state.filterOptions) : []
      ),
  }),

  reducers: {
    setSearchTerm: (state, searchTerm) => ({
      ...state,
      searchTerm,
    }),
    clearSearch: state => ({
      ...state,
      ...clearData(),
    }),
    setFilters: (state, filters) => ({
      ...state,
      filters,
    }),
    clearFilters: state => ({
      ...state,
      filters: cleanFilters(),
    }),
    setFilterType: (state, filterType) => ({
      ...state,
      filterType,
    }),
    setFilterOptions: (state, filterOptions) => ({
      ...state,
      filterOptions,
    }),
  },

  effects: () => ({
    initializeFromQueryString({ searchTerm, filters, filterType }) {
      if (!_.isEmpty(searchTerm)) {
        this.setSearchTerm(searchTerm);
      }
      if (!_.isEmpty(filters)) {
        this.setFilters(filters);
      }
      if (!_.isEmpty(filterType)) {
        this.setFilterType(filterType);
      }
    },

    async fetchFilterOptions({ table, initialFilterOptions = [] }) {
      const filterOptionsRaw = table
        ? await apiService.getFilterOptions({ table })
        : initialFilterOptions;

      const filterOptions = filterOptionsRaw
        .filter(group => !_.isEmpty(group.filterOptions))
        .map(group => ({
          component: group.name,
          componentDisplayName: group.displayName ?? group.name,
          sortOrder: group.sortOrder,
          options: group.filterOptions,
        }));

      this.setFilterOptions(_.orderBy(filterOptions, 'component'));
    },

    handleFilterToggle({ filters, filterGroup, filter }) {
      return filtersService.listFilterCategories.includes(filterGroup)
        ? this.handleListFilterToggle({ filters, filterGroup, filter })
        : this.handleBooleanFilterToggle({ filters, filter });
    },

    handleListFilterToggle({ filters, filter, filterGroup }) {
      filtersService.handleListFilterToggle(filters, filter, filterGroup);
      return this.setFilters({ ...filters });
    },

    handleBooleanFilterToggle({ filters, filter }) {
      filtersService.handleBooleanFilterToggle(filters, filter);
      return this.setFilters({ ...filters });
    },

    updateFilterType({ filterType }) {
      if (Object.values(filtersService.filterTypes).includes(filterType)) {
        this.setFilterType(filterType);
        return true;
      }
      return false;
    },
  }),
};
