import _ from 'lodash';
import apiService from '@src/services/apiService';

const clearData = () => ({
  timeline: [],
  timelineFilterOptions: null,
  defaultFilter: {},
});

export default {
  state: clearData(),
  selectors: slice => ({
    timelineFilterOptions: () => slice(state => state?.timelineFilterOptions),
    defaultFilter: () => slice(state => state?.defaultFilter),
    timeline: () => slice(state => state?.timeline),
  }),
  reducers: {
    setTimelineFilterOptions: (state, timelineFilterOptions) => ({
      ...state,
      timelineFilterOptions,
      defaultFilter: {
        name: 'All Material Changes',
        count: _.sumBy(timelineFilterOptions, 'count'),
      },
    }),
    setTimeline: (state, timelinePage, currentPage) => ({
      ...state,
      timeline: currentPage > 0 ? [...state.timeline, ...timelinePage] : timelinePage,
    }),
    clearData,
  },
  effects: dispatch => ({
    async fetchData({ table, entityKey, subEntityKey }) {
      await this.fetchTimelineFilterOptions({ table, entityKey, subEntityKey });
    },
    async onFilterChanged({ table, entityKey, subEntityKey, filters, fromDate, toDate }) {
      await this.fetchTimelineDataPage({
        table,
        entityKey,
        subEntityKey,
        currentPage: 0,
        filters,
        fromDate,
        toDate,
      });
    },
    async fetchTimelineFilterOptions({ table, entityKey, subEntityKey }) {
      const timelineFilterOptions = await apiService.getTimelineFilterOptions({
        table: `${table}/${entityKey}`,
        subEntityKey,
      });
      this.setTimelineFilterOptions(
        Object.entries(timelineFilterOptions).map(([name, count]) => ({ name, count }))
      );
    },
    async fetchTimelineDataPage({
      table,
      entityKey,
      subEntityKey,
      currentPage,
      filters,
      fromDate,
      toDate,
    }) {
      const results = await apiService.searchTimeline({
        table,
        entityKey,
        subEntityKey,
        currentPage,
        filters,
        fromDate,
        toDate,
      });
      if (_.isNil(results)) {
        return null;
      }
      const {
        materialChanges: timeline,
        developerProfiles,
        repositoryProfiles,
        providerRepositories,
        projectProfiles,
        autoIgnoredLabels,
        commitShaToPullRequest,
        commitShaToIssues,
      } = results;
      developerProfiles && dispatch.developerProfiles.addDeveloperProfiles({ developerProfiles });
      repositoryProfiles &&
        dispatch.repositoryProfiles.addRepositoryProfiles({ repositoryProfiles });
      providerRepositories &&
        dispatch.repositoryProfiles.addProviderRepositories({ providerRepositories });
      projectProfiles && dispatch.projectProfiles.addProjectProfiles({ projectProfiles });

      timeline.forEach(timelineEvent => {
        timelineEvent.materialChangeLabelsToIsIgnore = Object.keys(
          timelineEvent.materialChangesLabelsToCount
        ).map(label => autoIgnoredLabels.includes(label));

        if (commitShaToPullRequest?.[timelineEvent.commitSha]) {
          timelineEvent.pullRequest = commitShaToPullRequest[timelineEvent.commitSha];
        }

        if (commitShaToIssues?.[timelineEvent.commitSha]) {
          timelineEvent.issues = commitShaToIssues[timelineEvent.commitSha];
        }
      });

      this.setTimeline(timeline, currentPage);
      return timeline;
    },
  }),
};
