import { ForceGraph } from '@src-v2/models/force-graph/force-graph';

export class Graph {
  #client;

  constructor({ apiClient }) {
    this.#client = apiClient;
  }

  getApplicationGraph({ key }) {
    return this.#client.get(`graph/assetCollection/${key}`).then(ForceGraph.create);
  }

  getRepositoryGraph({ key }) {
    return this.#client.get(`graph/repository/${key}`).then(ForceGraph.create);
  }
}
