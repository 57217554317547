import _ from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components/macro';
import { VendorIcon } from '@src-v2/components/icons';
import { InsightTag } from '@src-v2/components/tags';
import { makeUrl } from '@src-v2/utils/history-utils';
import { TimelineEvent } from '@src/blocks/TimelineInfiniteScroll/components/TimelineEvent';
import { HorizontalStack } from '@src/components/HorizontalStack';

export const TimelineSecretValidity = ({
  secretValidityTimelineEvent,
  index,
  isFirst,
  isLast,
  previousTimelineEvent,
  materialChangeFilters,
}) => {
  const { materialChangesLabelsToCount } = secretValidityTimelineEvent;

  const materialChangesLabels = useMemo(
    () =>
      _.zip(
        Object.entries(materialChangesLabelsToCount),
        secretValidityTimelineEvent.materialChangeLabelsToIsIgnore
      ).map(([[label, count], isIgnored]) => [label, count, isIgnored]),
    [materialChangesLabelsToCount, secretValidityTimelineEvent]
  );

  const findingsReportLabels = useMemo(
    () =>
      _.isEmpty(materialChangeFilters)
        ? materialChangesLabels
        : materialChangesLabels.filter(([label]) => materialChangeFilters.includes(label)),
    [materialChangeFilters, materialChangesLabels]
  );

  const materialChanges = useMemo(
    () =>
      _.sortBy(findingsReportLabels, '[2]', '[0]').map(([label, count, isIgnored]) => (
        <div key={label} isIgnored={isIgnored}>
          {count === 1 ? label : `${label} (${count})`}
        </div>
      )),
    [findingsReportLabels]
  );

  const [platform] = secretValidityTimelineEvent.platforms;

  return (
    <TimelineEvent
      currentTimelineEvent={secretValidityTimelineEvent}
      previousTimelineEvent={previousTimelineEvent}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      titleContent="Secret validity changed"
      body={
        <div>
          <HorizontalStack>
            <div>
              <VendorIcon key={platform} name={platform} />
            </div>
            <div>{secretValidityTimelineEvent.secretTypes[0]}</div>
          </HorizontalStack>
          <div>
            New Status:{' '}
            <Validity validity={secretValidityTimelineEvent.newResult}>
              {secretValidityTimelineEvent.newResult}
            </Validity>
          </div>
          {!_.isEmpty(secretValidityTimelineEvent.insights) && (
            <HorizontalStack>
              <div>Insights:</div>
              <div>
                {secretValidityTimelineEvent.insights.map(_ => (
                  <InsightTag key={_.badge} insight={_} />
                ))}
              </div>
            </HorizontalStack>
          )}
          <div>
            This secret has {secretValidityTimelineEvent.sprawlCount} related risks in your
            environment
          </div>
          <div>
            Applications: {secretValidityTimelineEvent.assetCollectionNames.slice(0, 2).join(', ')}
            {secretValidityTimelineEvent.assetCollectionNames.length > 2 && (
              <>, and {secretValidityTimelineEvent.assetCollectionNames.length - 2} more</>
            )}
          </div>
          <br />
          {!_.isEmpty(findingsReportLabels) && (
            <>
              <div>Rule Labels:</div>
              {materialChanges}
            </>
          )}
        </div>
      }
      exploreUrl={makeUrl(`/risks/secrets`, {
        group: null,
        fl: { SecretHash: { values: [secretValidityTimelineEvent.secretSha] } },
      })}
    />
  );
};

const Validity = styled.span`
  ${props => (props.validity === 'Valid' ? 'color: var(--color-red-55)' : '')};
`;
