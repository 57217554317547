import { useEffect, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import styled from 'styled-components';
import ReactSelectComponents from './ReactSelectComponents';
import ReactSelectStyle from './ReactSelectStyle';
import ReactSelectTheme from './ReactSelectTheme';
import ReactSelectThemeSolid from './ReactSelectThemeSolid';

const StyledSelect = styled(Select)`
  ${ReactSelectStyle};
`;

const customStyles = {
  option: (provided, state) => ({
    ...provided,
    opacity: state.isDisabled ? 0.7 : 1,
  }),
  control: provided => ({
    ...provided,
    border: 'none',
    margin: 0,
    padding: `0 3rem`,
    borderRadius: '18px',
  }),
  singleValue: provided => ({
    ...provided,
    margin: 0,
    maxWidth: 'none',
    color: 'var(--default-text-color)',
  }),
};

const ReactSelect = props => (
  <StyledSelect
    {...props}
    styles={customStyles}
    classNamePrefix="select"
    theme={props.solidTheme ? ReactSelectThemeSolid : ReactSelectTheme}
    components={ReactSelectComponents(props)}
  />
);

const StyledCreatableSelect = styled(CreatableSelect)`
  ${ReactSelectStyle};
`;

const ReactCreatableSelect = props => (
  <StyledCreatableSelect
    {...props}
    classNamePrefix="select"
    theme={ReactSelectTheme}
    components={ReactSelectComponents(props)}
  />
);

const ReactSelectWrapper = ({ options, ...props }) => {
  const [optionsPage, setOptionsPage] = useState(options);

  useEffect(() => {
    setOptionsPage(options);
  }, [options]);

  return props.createable ? (
    <ReactCreatableSelect options={optionsPage} {...props} />
  ) : (
    <ReactSelect options={optionsPage} {...props} />
  );
};

export default ReactSelectWrapper;
