import _ from 'lodash';
import filtersService from '../filtersService';

export const getServerlessFunctionsFiltersOptions = ({ elements }) => {
  const runtimes = _.uniq(_.map(elements, 'runtime').flat());
  return [
    {
      name: 'Runtime',
      displayName: 'Runtime',
      sortOrder: 1,
      filterOptions: runtimes.map(runtime => ({
        name: runtime,
        displayName: runtime,
        filterOptions: filtersService.getListFilterOptions(runtimes),
      })),
    },
  ];
};

export const filterServerlessFunctions = (allItems, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, item => [
    item.functionKey,
    item.serviceKey,
  ]);
  const runtimeFilters = filters.listFilters?.Runtime;
  if (!_.isEmpty(runtimeFilters)) {
    filtered = filtered.filter(item =>
      filterOperator(runtimeFilters.map(runtime => runtime === item.runtime))
    );
  }

  return filtered;
};
