import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { openDependencyPaneWithRiskActions } from '@src/components/Panes/DependencyPane';
import { EllipsisDiv } from '@src/style/common';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getDependencyTriggerDisplay = ({ trigger, profile, ...options }) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  const dependency = trigger.isSubDependency ? 'Sub-dependency' : 'Direct dependency';

  const title = addInterpunctSeparator(
    profile.profileType === 'ApplicationProfile' && repository.name,
    dependency
  );

  return [
    {
      weight: 3,
      content: (
        <PaneLocationLink
          id={trigger.key}
          onClick={args =>
            openDependencyPaneWithRiskActions({ ...args, profile, trigger, ...options })
          }>
          <TriggerInfoContainer insights={trigger.insights} title={title}>
            <HorizontalStack withSeparator>
              <EllipsisDiv>{trigger.displayName}</EllipsisDiv>
              {trigger.totalVulnerabilitiesCount && (
                <Number
                  one="vulnerability"
                  other="vulnerabilities"
                  value={trigger.totalVulnerabilitiesCount}
                />
              )}
            </HorizontalStack>
          </TriggerInfoContainer>
        </PaneLocationLink>
      ),
    },
  ];
};

export const getDependencyMessageFormat = element => element.displayName;
