import _ from 'lodash';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { openRiskyLicenseDependenciesPane } from '@src/components/Panes/RiskyLicensePane';
import { EllipsisDiv } from '@src/style/common';
import { PaneLocationLink, TriggerBoxSmallTitle, TriggerInfoContainer } from './Common';

export const getLicenseWithDependenciesTriggerDisplay = ({ trigger, profile }) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  return [
    {
      weight: 3,
      content: (
        <PaneLocationLink
          id={trigger.key}
          onClick={args =>
            openRiskyLicenseDependenciesPane({
              ...args,
              trigger,
              profile,
            })
          }>
          <TriggerInfoContainer
            title={
              !_.isEmpty(trigger.repositoryKeys) &&
              (trigger.repositoryKeys.length === 1
                ? repository.name
                : `In ${trigger.repositoryKeys.length} repositories`)
            }
            insights={trigger.insights}>
            <HorizontalStack withSeparator>
              <EllipsisDiv>{trigger.displayName}</EllipsisDiv>
              <TriggerBoxSmallTitle>
                Used in{' '}
                <Number value={trigger.dependencies.length} one="dependency" other="dependencies" />
              </TriggerBoxSmallTitle>
            </HorizontalStack>
          </TriggerInfoContainer>
        </PaneLocationLink>
      ),
    },
  ];
};

export const getLicenseWithDependenciesMessageFormat = element => {
  const dependenciesNumber = element.dependencies.length;

  return `${element.elementKey} used in ${dependenciesNumber} ${pluralFormat(
    dependenciesNumber,
    'dependency',
    'dependencies'
  )}`;
};
