import { connect } from 'react-redux';
import { select } from '@src/store';
import { RiskProfilesPage } from './view';

const mapState = select(
  ({
    organization: { assetCollectionCount },
    assetCollectionProfiles: { assetOptions },
    assetCollectionConfigurationPane: { assetCollectionType },
  }) => ({
    assetOptions,
    assetCollectionCount,
    assetCollectionType,
  })
);

const mapDispatch = ({
  organization: { getOrganizationProfileAsync },
  assetCollectionConfigurationPane: { setType },
}) => ({
  getOrganizationProfileAsync,
  setType,
});

export default connect(mapState, mapDispatch)(RiskProfilesPage);
