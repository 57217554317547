import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  HighlightedCode,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';
import { getApiClassificationDescription } from '@src-v2/containers/commit/material-changes-utils';

export const ApiClassificationMaterialChange = ({
  commitSha,
  repository,
  relativeFilePath,
  materialChange,
}) => {
  const { methodSignature, apiName, methodName } = materialChange.codeReference;
  return (
    <>
      <MaterialChangesRow>
        <Paragraph>API is</Paragraph>
        <Strong>{getApiClassificationDescription(materialChange.classification)}</Strong>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}>
          {apiName ?? methodName}
        </CommitCodeReference>
        <Tooltip content={<HighlightedCode language="java">{methodSignature}</HighlightedCode>}>
          <Paragraph>{apiName ?? methodName} </Paragraph>
        </Tooltip>
      </MaterialChangesRow>
    </>
  );
};
