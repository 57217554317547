import styled from 'styled-components';
import { Link } from '@src/components/Link';
import { Ellipsis, FontBody } from '@src/style/common';

export const TitledList = ({ title, titleLink, list = [], listItemRender, wrap, ...props }) => (
  <Titled title={title} titleLink={titleLink} {...props}>
    {list.map((item, index) => (
      <StyledItem key={index} wrap={wrap && 'true'}>
        {listItemRender ? listItemRender(item, index) : item}
      </StyledItem>
    ))}
  </Titled>
);

export const Titled = styled(({ title, titleLink, children, ...props }) => (
  <div {...props}>
    <div>
      {titleLink ? (
        <Link openInNewTab tip={title} url={titleLink}>
          {title}
        </Link>
      ) : (
        title
      )}
    </div>
    {children}
  </div>
))`
  color: var(--default-text-color);
  font-size: var(--font-size-m);
  line-height: 6rem;
  font-weight: 700;
`;

export const StyledItem = styled.div`
  ${FontBody};
  overflow-wrap: ${props => (props.wrap ? 'anywhere' : 'unset')};
  ${props => !props.wrap && Ellipsis};
`;
