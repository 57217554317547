import { format } from 'date-fns';
import styled from 'styled-components';
import { ChecklistBox } from '@src-v2/components/marketing/checklist-box';
import { plansChecklists } from '@src-v2/data/marketing';
import { useInject } from '@src-v2/hooks';

export function Checklists({ checklists = plansChecklists, onAction }) {
  const { subscription } = useInject();
  const currentDate = format(subscription.expirationDate, 'PP');

  return (
    <Container>
      <ChecklistBox
        title="Trial"
        actionLabel={`Expires on ${currentDate}`}
        disabledAction
        list={checklists.trial}
      />
      <ChecklistBox
        title="Premium"
        actionLabel="Request upgrade"
        list={checklists.premium}
        onAction={onAction}
      />
    </Container>
  );
}

const Container = styled.div`
  width: fit-content;
  display: flex;
  gap: 14rem;
`;
