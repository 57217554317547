import _ from 'lodash';

export const isAssetCollectionProfile = profileType => profileType === 'assetCollection';

export const getRepositoryForInventoryPropertyFromProfile = (inventoryProperty, profile) => {
  if (profile.repository) {
    return profile.repository;
  }

  if (_.isEmpty(inventoryProperty.repositoryKeys)) {
    return inventoryProperty.repositoryKey
      ? profile.repositoryByKey[inventoryProperty.repositoryKey]
      : undefined;
  }

  const repositoryKey = profile?.isModuleBased
    ? profile.configuration.modulesGroup.repositoryKey
    : inventoryProperty.repositoryKeys[0];

  return profile.repositoryByKey[repositoryKey];
};

export const getRepositoryForInventoryPropertyFromProfileByKey = (repositoryKey, profile) =>
  profile.repository ?? profile.repositoryByKey?.[repositoryKey];

export const getProjectForInventoryPropertyFromProfileByKey = (projectKey, profile) =>
  profile.project
    ? profile.project
    : profile.projectByKey
      ? profile.projectByKey[projectKey]
      : null;
