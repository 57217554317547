import _ from 'lodash';
import { useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeUrl, qs } from '@src-v2/utils/history-utils';
import { alphabeticalSort } from '@src-v2/utils/string-utils';

export function useQueryParams(defaultOptions = {}) {
  const history = useHistory();
  const { search } = useLocation();
  return {
    queryParams: useMemo(() => qs.parse(search), [search]),
    updateQueryParams(params, { recursive, replace = false } = defaultOptions) {
      const currentParams = qs.parse(window.location.search);
      const newQueryParams = _.omitBy(
        recursive ? _.merge({}, currentParams, params) : _.assign({}, currentParams, params),
        value => (_.isObjectLike(value) ? _.isEmpty(value) : _.isNil(value))
      );
      if (
        qs.stringify(currentParams, { sort: alphabeticalSort }) !==
        qs.stringify(newQueryParams, { sort: alphabeticalSort })
      ) {
        const url = makeUrl(window.location.pathname, newQueryParams);
        if (replace) {
          history.replace(url);
        } else {
          history.push(url);
        }
      }
    },
  };
}
