export const IssueEntityTypesToPull = [
  'ACCESS_KEY',
  'ACCESS_ROLE',
  'ACCESS_ROLE_BINDING',
  'ACCESS_ROLE_PERMISSION',
  'API_GATEWAY',
  'APPLICATION',
  'AUTHENTICATION_CONFIGURATION',
  'AUTHENTICATION_POLICY',
  'BRANCH_PACKAGE',
  'BACKUP_SERVICE',
  'BACKEND_BUCKET',
  'BUCKET',
  'CDN',
  'CERTIFICATE',
  'CICD_SERVICE',
  'CLOUD_LOG_CONFIGURATION',
  'CLOUD_ORGANIZATION',
  'CLOUD_RESOURCE',
  'CONTAINER_INSTANCE_GROUP',
  'COMPUTE_INSTANCE_GROUP',
  'CONFIGURATION_FINDING',
  'CONFIG_MAP',
  'DATA_RESOURCE',
  'HOST_CONFIGURATION_FINDING',
  'HOST_CONFIGURATION_RULE',
  'CONFIGURATION_RULE',
  'CONFIGURATION_SCAN',
  'CONTAINER_GROUP',
  'CONTAINER',
  'CONTAINER_IMAGE',
  'CONTAINER_REGISTRY',
  'CONTAINER_REPOSITORY',
  'CONTAINER_SERVICE',
  'CONTROLLER_REVISION',
  'DAEMON_SET',
  'DATABASE',
  'DATA_STORE',
  'DATA_INVENTORY',
  'DATA_SCHEMA',
  'DATA_FINDING',
  'DATA_WORKLOAD',
  'DB_SERVER',
  'DEPLOYMENT',
  'DNS_RECORD',
  'DNS_ZONE',
  'DOMAIN',
  'EMAIL_SERVICE',
  'ENCRYPTION_KEY',
  'ENDPOINT',
  'EXCESSIVE_ACCESS_FINDING',
  'FILE_DESCRIPTOR',
  'FILE_DESCRIPTOR_FINDING',
  'PORT_RANGE',
  'IP_RANGE',
  'FILE_SYSTEM_SERVICE',
  'FIREWALL',
  'GATEWAY',
  'GOVERNANCE_POLICY',
  'GOVERNANCE_POLICY_GROUP',
  'GROUP',
  'HOSTED_APPLICATION',
  'HOSTED_TECHNOLOGY',
  'IAC_DECLARATION_INSTANCE',
  'IAC_RESOURCE_DECLARATION',
  'IAC_STATE_INSTANCE',
  'IAM_BINDING',
  'IDENTITY_PROVIDER',
  'KUBERNETES_CLUSTER',
  'KUBERNETES_CRON_JOB',
  'KUBERNETES_INGRESS',
  'KUBERNETES_INGRESS_CONTROLLER',
  'KUBERNETES_JOB',
  'KUBERNETES_NETWORK_POLICY',
  'KUBERNETES_NODE',
  'KUBERNETES_PERSISTENT_VOLUME',
  'KUBERNETES_PERSISTENT_VOLUME_CLAIM',
  'KUBERNETES_POD_SECURITY_POLICY',
  'KUBERNETES_SERVICE',
  'KUBERNETES_STORAGE_CLASS',
  'KUBERNETES_VOLUME',
  'LAST_LOGIN',
  'LATERAL_MOVEMENT_FINDING',
  'LOAD_BALANCER',
  'LOCAL_USER',
  'MALWARE_INSTANCE',
  'MALWARE',
  'MANAGED_CERTIFICATE',
  'MANAGEMENT_SERVICE',
  'MAP_REDUCE_CLUSTER',
  'MESSAGING_SERVICE',
  'MONITOR_ALERT',
  'NAMESPACE',
  'NAT',
  'NETWORK_ADDRESS',
  'NETWORK_APPLIANCE',
  'NETWORK_INTERFACE',
  'NETWORK_ROUTING_RULE',
  'NETWORK_SECURITY_RULE',
  'PACKAGE',
  'PEERING',
  'PRINCIPAL',
  'POD',
  'PREDEFINED_GROUP',
  'PRIVATE_ENDPOINT',
  'PROJECT',
  'PROXY',
  'PROXY_RULE',
  'RAW_ACCESS_POLICY',
  'REGION',
  'REGISTERED_DOMAIN',
  'REPLICA_SET',
  'REPOSITORY',
  'REPOSITORY_BRANCH',
  'REPOSITORY_TAG',
  'RESOURCE_GROUP',
  'ROUTE_TABLE',
  'SEARCH_INDEX',
  'SECRET',
  'SECRET_CONTAINER',
  'SECRET_DATA',
  'SECRET_INSTANCE',
  'SECURITY_TOOL_FINDING',
  'SECURITY_EVENT_FINDING',
  'SECURITY_TOOL_FINDING_TYPE',
  'SECURITY_TOOL_SCAN',
  'SERVERLESS',
  'SERVERLESS_PACKAGE',
  'SERVICE_ACCOUNT',
  'SERVICE_CONFIGURATION',
  'STORAGE_ACCOUNT',
  'STATEFUL_SET',
  'SNAPSHOT',
  'SUBNET',
  'SUBSCRIPTION',
  'SWITCH',
  'TECHNOLOGY',
  'VIRTUAL_DESKTOP',
  'USER_ACCOUNT',
  'VIRTUAL_MACHINE',
  'VIRTUAL_MACHINE_IMAGE',
  'VIRTUAL_NETWORK',
  'VOLUME',
  'VULNERABILITY',
  'WEAKNESS',
  'WEB_SERVICE',
  'SERVICE_USAGE_TECHNOLOGY',
  'CALL_CENTER_SERVICE',
  'PRIVATE_LINK',
  'DATA_WORKFLOW',
];
