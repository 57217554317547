import _ from 'lodash';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { EllipsisDiv } from '@src/style/common';
import { TriggerBoxSmallTitle } from './Common';

export const getUserFacingTriggerDisplay = ({ profile, profileType }) => {
  const profileTypeDisplay =
    profileType === 'assetCollection' ? 'Application' : _.upperFirst(profileType);
  return [
    {
      weight: 3,
      content: (
        <EllipsisDiv>
          <TriggerBoxSmallTitle>{profile.name}</TriggerBoxSmallTitle>
          <HorizontalStack withSeparator>
            <span>{profileTypeDisplay}</span>
            <span>User Facing</span>
          </HorizontalStack>
        </EllipsisDiv>
      ),
    },
  ];
};
