import every from 'lodash/every';
import isFunction from 'lodash/isFunction';
import some from 'lodash/some';
import apiService from '@src/services/apiService';
import filtersService from '@src/services/filtersService';

const clearData = () => ({
  tableScope: null,
  items: [],
  itemsCount: null,
  pageCount: 0,
});

export default {
  state: clearData(),
  selectors: slice => ({
    items: () => slice(state => state?.items),
    itemsInPages: () =>
      slice(state => state?.items.slice(0, apiService.pageSize * (state.pageCount + 1))),
    itemsCount: () => slice(state => state?.itemsCount),
    currentTableScope: () => slice(state => state?.tableScope),
  }),
  reducers: {
    setPageCount: (state, pageCount) => ({
      ...state,
      pageCount,
    }),
    setBulkState: (state, { tableScope, items, itemsCount, pageCount }) => ({
      ...state,
      tableScope,
      items,
      itemsCount,
      pageCount,
    }),
    clearData,
  },
  effects: () => ({
    fetchData({
      tableScope,
      items,
      filterFunction,
      countFunction,
      searchTerm,
      filters,
      filterType,
    }) {
      const filterOperator = filterType === filtersService.filterTypes.AND ? every : some;
      if (isFunction(filterFunction)) {
        items = filterFunction(items, searchTerm?.toLowerCase(), filters, filterOperator);
      }
      countFunction ??= items => items.length;

      this.setBulkState({
        tableScope,
        items,
        itemsCount: countFunction(items),
        pageCount: 0,
      });
    },

    fetchPage({ currentPage, items }) {
      this.setPageCount(currentPage);

      return items.slice(
        apiService.pageSize * currentPage,
        apiService.pageSize * (currentPage + 1)
      );
    },
  }),
};
