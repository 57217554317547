import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import { useMemo } from 'react';
import styled from 'styled-components';
import { getViewCodeColumn } from '@src/components/CodeAggregation/Common';
import { StyledContent } from '@src/components/CodeAggregation/Insights/InsightStyles';
import { Table } from '@src/components/Table';
import { Tooltip } from '@src/components/Tooltip';
import { Ellipsis, FontBodySmall } from '@src/style/common';
import { getApiClassification, getApiText } from '@src/utils/apiUtils';
import { getModuleLinkByPath } from '@src/utils/moduleFinder';

const SensitiveDataApis = ({ profile, apis }) => {
  apis = useMemo(() => sortApis(apis), [apis]);
  return (
    <Table
      medium
      headers={[
        { name: 'Module', requiresNonEmpty: true, weight: 3 },
        { name: 'Class', weight: 2 },
        { name: 'API', weight: 3 },
      ]}
      rows={map(apis, api => ({
        key: api.codeReference.relativeFilePath + api.codeReference.lineNumber,
        cells: [
          {
            content: getModuleLinkByPath(
              profile,
              api.codeReference.relativeFilePath,
              StyledContent,
              'components/apis'
            ),
          },
          {
            content: (
              <Tooltip tip={api.codeReference.className}>
                <StyledContent>{api.codeReference.className}</StyledContent>
              </Tooltip>
            ),
          },
          {
            content: getApiNameDisplay(api),
            menu: [getViewCodeColumn(api.codeReference, profile.repository)],
          },
        ],
      }))}
    />
  );
};

const StyledApiName = styled.div`
  ${FontBodySmall};
  ${Ellipsis};
`;

const getApiNameDisplay = api => (
  <StyledApiName>
    {getApiText(api.httpMethod, api.httpRoute, api.methodName, getApiClassification(api))}
  </StyledApiName>
);

const sortApis = apis => sortBy(apis, ['relativeFilePath', 'apiMethodDetails.httpRoute']);

export default SensitiveDataApis;
