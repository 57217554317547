import { useState } from 'react';
import styled from 'styled-components';
import { Number } from '@src/components/Number';
import { FontBodySmallBold } from '@src/style/common';
import { Button } from './Button';
import { VerticalStack } from './VerticalStack';

const StyledButton = styled(Button)`
  ${FontBodySmallBold};
  display: flex;
  text-decoration: underline;
  padding: 0;
  margin: 0;
`;

export const TrimmedVerticalStack = ({
  className,
  items,
  spacing,
  nameSingle,
  namePlural,
  maxChildren = 3,
}) => {
  const [isExpanded, setExpanded] = useState(false);

  return (
    <VerticalStack className={className} spacing={spacing}>
      {isExpanded ? items : items.slice(0, maxChildren)}
      {items.length > maxChildren && (
        <StyledButton onClick={() => setExpanded(!isExpanded)}>
          {isExpanded ? (
            'Show less'
          ) : (
            <span>
              +<Number value={items.length - maxChildren} one={nameSingle} other={namePlural} />
            </span>
          )}
        </StyledButton>
      )}
    </VerticalStack>
  );
};
