import styled from 'styled-components';
import { ChevronPagingButton } from '@src-v2/components/buttons';
import { FiltersControls } from '@src-v2/components/filters/inline-control/containers/filters-controls';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { InChartHeaderControls } from '@src/cluster-map-work/components/charts/graph-utils';
import { GraphSearchInput } from '@src/cluster-map-work/components/graph-search-input';

export function ChartSearchControls({ chartSearchState, filterOptions, elementCount }) {
  return (
    <InChartHeaderControls.SearchHeader>
      <GraphSearchInput
        placeholder="Search for risks, insights and more..."
        graphSearchState={chartSearchState.graphSearchState}
      />

      <SearchResultPagingContainer
        data-displayed={dataAttr(chartSearchState.searchStatus !== 'inactive')}>
        <ChevronPagingButton
          name="Chevron"
          data-prev
          disabled={
            !chartSearchState.numSearchResults || chartSearchState.searchResultSelectedIndex === 0
          }
          onClick={() =>
            chartSearchState.numSearchResults &&
            chartSearchState.setSearchResultSelectedIndex(result => (result > 0 ? result - 1 : 0))
          }
        />
        <SearchResultPagingStatus>
          {chartSearchState.searchStatus === 'running'
            ? 'Searching...'
            : chartSearchState.searchResultSelectedIndex !== null
              ? `${chartSearchState.searchResultSelectedIndex + 1} / ${
                  chartSearchState.numSearchResults
                }`
              : `${chartSearchState.numSearchResults ?? 0} results`}
        </SearchResultPagingStatus>
        <ChevronPagingButton
          data-next
          disabled={
            !chartSearchState.numSearchResults ||
            chartSearchState.searchResultSelectedIndex >= chartSearchState.numSearchResults - 1
          }
          onClick={() =>
            chartSearchState.numSearchResults &&
            chartSearchState.setSearchResultSelectedIndex(result =>
              result === null
                ? 0
                : result < chartSearchState.numSearchResults - 1
                  ? result + 1
                  : chartSearchState.numSearchResults - 1
            )
          }
        />
      </SearchResultPagingContainer>

      <FiltersControls filterOptions={filterOptions} />

      {elementCount !== null && <ElementCountBox>{elementCount} elements</ElementCountBox>}
    </InChartHeaderControls.SearchHeader>
  );
}

const ElementCountBox = styled.div`
  font-size: var(--font-size-s);
`;

const SearchResultPagingContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  overflow: hidden;
  max-width: 0;

  transition: max-width 0.5s;

  &[data-displayed] {
    max-width: 100vw;
  }
`;

const SearchResultPagingStatus = styled.div`
  flex-shrink: 0;
  width: 20rem;
  text-align: center;
  font-size: var(--font-size-s);
`;
