import styled from 'styled-components';
import { Heading, Paragraph } from '@src-v2/components/typography';

export const WebhookConnectorHeading = styled(props => {
  return (
    <div {...props}>
      <Heading>Connect with a Webhook</Heading>
      <Paragraph>Send messages to a specific channel via a webhook</Paragraph>
    </div>
  );
})`
  ${Heading} {
    margin-bottom: 1rem;
    font-size: var(--font-size-m);
    font-weight: 350;
  }

  ${Paragraph} {
    color: var(--color-blue-gray-45);
    font-size: var(--font-size-s);
    font-weight: 325;
  }
`;
