export const customScrollbar = `
  transform: translateZ(0);
  overflow: auto;
  scrollbar-gutter: stable;  /* This reserves space for the scrollbar */
  
  &[data-scrollbar-gutters] {
    scrollbar-gutter: stable both-edges;
  }

  &::-webkit-scrollbar {
    width: var(--scrollbar-width);
    height: 3rem;
    background-color: transparent;
  }

  &::-webkit-scrollbar-thumb {
    opacity: 0;  
    background-clip: padding-box;
    background-color: var(--scrollbar-color);
    box-shadow: inset 0 0 0 0.25rem var(--scrollbar-border-color);
    border: 0.75rem solid transparent;
    border-radius: 100vmax;
    transition: opacity 0.2s;  
    
    &:hover {
      border: 0.5rem solid transparent;
    }
  }
  
  &:hover {
    &::-webkit-scrollbar-thumb {
      opacity: 1; 
    }
  }

  &::-webkit-scrollbar-corner {
    background-color: transparent;
  }
`;
