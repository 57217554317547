import { CreateBulkIssueModal } from '@src-v2/containers/modals/issues/create-bulk-issue-modal';
import { TicketingProviders } from '@src-v2/data/ticketing-issues-provider';

export function AzureDevopsBulkModal(props) {
  return <CreateBulkIssueModal {...props} provider={TicketingProviders.AzureDevops} />;
}

export function GithubBulkModal(props) {
  return <CreateBulkIssueModal {...props} provider={TicketingProviders.Github} />;
}

export function JiraBulkModal(props) {
  return <CreateBulkIssueModal {...props} provider={TicketingProviders.Jira} />;
}
