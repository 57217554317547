import styled from 'styled-components';
import { Checkbox } from '@src-v2/components/forms';
import { SearchInput } from '@src-v2/components/forms/search-input';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { Table } from '@src-v2/components/table/table';
import { TableHeader } from '@src-v2/components/table/table-header';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink } from '@src-v2/components/typography';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import { getProviderDisplayName } from '@src-v2/data/providers';
import { useTable } from '@src-v2/hooks/use-table';

export const DependenciesTable = styled(
  ({
    currentRepository,
    dependenciesFilters,
    filteredDependencies,
    setDependenciesFilters,
    ...props
  }) => {
    const tableModel = useTable({ tableColumns, hasReorderColumns: false });

    return (
      <div {...props}>
        <DependenciesFilters>
          <SearchInput
            placeholder="Search dependency..."
            onChange={({ target }) =>
              setDependenciesFilters({
                ...dependenciesFilters,
                searchTerm: target?.value,
              })
            }
          />
          <Label>
            <Checkbox
              onChange={event =>
                setDependenciesFilters({
                  ...dependenciesFilters,
                  hasFindings: event.target.checked,
                })
              }
            />
            <span>Has Findings</span>
          </Label>
        </DependenciesFilters>
        <Table>
          <TableHeader tableModel={tableModel} />
          <Table.Body>
            {filteredDependencies?.map(dependency => {
              const { relativeFilePath, lineNumber } = dependency.codeReference;
              return (
                <Table.Row key={dependency.entityId}>
                  <Table.Cell>{dependency.displayName}</Table.Cell>
                  <Table.Cell>{dependency.version}</Table.Cell>
                  <Table.Cell>
                    {currentRepository && relativeFilePath && (
                      <Tooltip
                        content={`View in ${getProviderDisplayName(
                          currentRepository.server.provider
                        )}`}>
                        <ExternalLink
                          href={generateCodeReferenceUrl(currentRepository, {
                            relativeFilePath,
                            lineNumber,
                          })}>
                          <SvgIcon name="Code" />
                        </ExternalLink>
                      </Tooltip>
                    )}
                  </Table.Cell>
                </Table.Row>
              );
            })}
          </Table.Body>
        </Table>
      </div>
    );
  }
)`
  ${Table} {
    > :first-child > tr:first-child {
      > :first-child {
        border-radius: 0;
      }

      > :last-child {
        border-radius: 0;
      }
    }

    > :last-child > tr:last-child {
      > :first-child {
        border-radius: 0;
      }

      > :last-child {
        border-radius: 0;
      }
    }

    ${BaseIcon} {
      width: 5rem;
      height: 5rem;
      color: var(--color-black);
      background-color: unset;

      &:hover {
        background-color: var(--color-blue-gray-25);
      }
    }
  }
`;

const DependenciesFilters = styled.div`
  width: 80rem;
  display: flex;
  flex-direction: column;
  padding: 4rem;
  gap: 2rem;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  font-size: var(--font-size-s);
  gap: 1rem;
`;

const tableColumns = [
  { label: 'Name', width: '58rem' },
  { label: 'Version', width: '24rem' },
  { label: 'View Code', width: '28rem' },
];
