import assign from 'lodash/assign';
import isNil from 'lodash/isNil';
import keyBy from 'lodash/keyBy';
import valuesIn from 'lodash/valuesIn';
import apiService from '@src/services/apiService';

const clearData = () => ({
  profiles: {},
  providerRepositories: {},
  inventories: {},
  riskTriggers: {},
});

export default {
  state: clearData(),
  reducers: {
    setRepositoryProfiles: (state, repositoryProfiles) => ({
      ...state,
      profiles: assign(
        { ...state.profiles },
        keyBy(repositoryProfiles, profile => profile.key)
      ),
    }),
    setProviderRepositories: (state, providerRepositories) => ({
      ...state,
      providerRepositories: assign(
        { ...state.providerRepositories },
        keyBy(providerRepositories, providerRepository => providerRepository.key)
      ),
    }),
  },

  selectors: slice => ({
    repositoryProfiles: () => slice(repositories => valuesIn(repositories)),
    profilesByKey: () => slice(state => state?.profiles ?? {}),
    providerRepositoriesByKey: () => slice(state => state?.providerRepositories ?? {}),
    riskTriggersByKey: () => slice(state => state?.riskTriggers ?? {}),
    inventoriesByKey: () => slice(state => state?.inventories ?? {}),
  }),

  effects: () => ({
    async getRepositoryProfileAsync({ key, invalidateCache }) {
      const repositoryProfile = (
        await apiService.get(`/api/repositories/${key}/profile`, {
          clearCacheEntry: invalidateCache,
        })
      ).data;
      if (repositoryProfile) {
        this.setRepositoryProfiles([repositoryProfile]);
      }
      return repositoryProfile;
    },

    async getModuleProfileAsync({ key, invalidateCache, moduleKey }) {
      if (isNil(moduleKey)) {
        return;
      }

      const moduleProfile = (
        await apiService.get(`/api/repositories/${key}/profile/module/${moduleKey}`, {
          clearCacheEntry: invalidateCache,
        })
      ).data;
      if (moduleProfile) {
        this.setRepositoryProfiles([moduleProfile]);
      }
      return moduleProfile;
    },
    async getRepositoryProfilesByKeysAsync({ keys, invalidateCache }) {
      return await Promise.all(
        keys.map(key => this.getRepositoryProfileAsync({ key, invalidateCache }))
      );
    },

    addRepositoryProfiles({ repositoryProfiles }) {
      this.setRepositoryProfiles(repositoryProfiles);
    },

    addProviderRepositories({ providerRepositories }) {
      this.setProviderRepositories(providerRepositories);
    },
  }),
};
