import _ from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { RiskBadge } from '@src-v2/components/risk/risk-badge';
import { noRisk, riskOrder } from '@src-v2/data/risk-data';
import { useInject, useSuspense } from '@src-v2/hooks';

export function ProfileRiskBadge({ profile, showBusinessImpact = false, ...props }) {
  const { applicationProfiles } = useInject();

  const { estimatedRevenue, estimatedUsersNumber } = useSuspense(
    applicationProfiles.getStaticConfigurationOptions
  );

  if (profile.profileType === 'ProjectProfile') {
    showBusinessImpact = RiskBadge.BUSINESS_IMPACT_NEVER;
  }

  return (
    <RiskBadge
      showBusinessImpact={showBusinessImpact}
      profile={useMemo(() => {
        const configuredBusinessImpact = _.camelCase(profile.configuredBusinessImpact);
        return {
          key: profile.key,
          profileType: normalizeProfileType(profile),
          // TODO: remove patch after LIM-5735 is fixed
          riskLevel: _.camelCase(profile.risk?.combinedRiskLevel) || noRisk,
          businessImpactLevel: _.camelCase(profile.businessImpact),
          riskFactors: _.orderBy(
            profile.risk?.riskFactors.map(({ ruleName, ruleKey, riskLevel, devPhase }) => ({
              ruleKey,
              title: ruleName,
              riskLevel: _.camelCase(riskLevel),
              devPhase,
            })),
            item => riskOrder.indexOf(item.riskLevel),
            'desc'
          ),
          businessImpactFactors:
            showBusinessImpact === RiskBadge.BUSINESS_IMPACT_NEVER
              ? []
              : _.orderBy(
                  Object.entries(profile.businessImpactToKeywords)
                    .flatMap(([riskLevel, keywords]) =>
                      keywords.map(keyword => ({
                        title: `Data includes ${keyword}`,
                        riskLevel: _.camelCase(riskLevel),
                        isConfigurable: false,
                      }))
                    )
                    .concat(
                      [
                        configuredBusinessImpact !== noRisk && {
                          title: `Business Impact configured as ${profile.configuredBusinessImpact}`,
                          riskLevel: configuredBusinessImpact,
                          isConfigurable: true,
                        },
                        profile.estimatedRevenue !== 'Undefined' && {
                          title: `Estimated revenue is ${
                            estimatedRevenue?.find(
                              option => option.value === profile.estimatedRevenue
                            )?.label ?? profile.estimatedRevenue
                          }`,
                          riskLevel: EstimatedRevenueMapping[profile.estimatedRevenue] ?? 'low',
                          isConfigurable: true,
                        },
                        profile.estimatedUsersNumber !== 'Undefined' && {
                          title: `Estimated number of users is ${
                            estimatedUsersNumber?.find(
                              option => option.value === profile.estimatedUsersNumber
                            )?.label ?? profile.estimatedUsersNumber
                          }`,
                          riskLevel:
                            EstimatedUsersNumberMapping[profile.estimatedUsersNumber] ?? 'low',
                          isConfigurable: true,
                        },
                        profile.isInternetExposed && {
                          title: 'Internet exposed',
                          riskLevel: noRisk,
                          isConfigurable: true,
                        },
                      ].filter(Boolean)
                    ),
                  item => riskOrder.indexOf(item.riskLevel),
                  'desc'
                ),
        };
      }, [profile])}
      {...props}
    />
  );
}

const EstimatedRevenueMapping = {
  ExtraLarge: 'high',
  Large: 'high',
  Medium: 'medium',
  Small: 'low',
};

const EstimatedUsersNumberMapping = {
  VeryLarge: 'high',
  Large: 'high',
  Medium: 'medium',
  Small: 'low',
  VerySmall: 'low',
};

export const BusinessImpactBadge = styled(({ profile, ...props }) => (
  <RiskBadge.BusinessImpactLevel {...props} riskLevel={profile.businessImpactLevel}>
    {profile.businessImpactLevel !== noRisk
      ? `${profile.businessImpactLevel[0]}bi`
      : 'No Business Impact'}
  </RiskBadge.BusinessImpactLevel>
))`
  height: 9rem;
  align-self: center;
  border-radius: 1rem;
`;

const normalizeProfileType = profile => {
  switch (profile.profileType) {
    case 'ApplicationProfile':
      return 'applications';
    case 'RepositoryProfile':
      return 'repositories';
    case 'ProjectProfile':
      return;
    default:
      console.warn(`Unsupported profileType "${profile.profileType}"`);
  }
};
