import styled from 'styled-components';
import { IconButton } from '@src-v2/components/buttons';
import { BaseIcon } from '@src-v2/components/icons';
import { useCollapsible } from '@src-v2/hooks/use-collapsible';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { HorizontalStack } from './HorizontalStack';

export default function OldCollapsible({ title, spread, suffix, expanded, children, ...props }) {
  const { isOpen, shouldRender, getTriggerProps, getContentProps } = useCollapsible({
    open: expanded,
  });

  return (
    <CollapsibleContainer {...props} data-open={dataAttr(isOpen)}>
      <HorizontalStack
        spread={spread}
        {...getTriggerProps({
          onClick(isOpen, event) {
            event.stopPropagation();
          },
        })}>
        {title}
        <IconButton name="Chevron" />
        {suffix}
      </HorizontalStack>
      <div {...getContentProps()}>{shouldRender && children}</div>
    </CollapsibleContainer>
  );
}

export const CollapsibleContainer = styled.div`
  ${HorizontalStack} {
    width: 100%;
    cursor: pointer;
  }

  > ${HorizontalStack} > ${BaseIcon} {
    transition: transform 0.2s ease-in-out;
    transform: rotate(90deg);
  }

  &[data-open] > ${HorizontalStack} > ${BaseIcon} {
    transform: rotate(-90deg);
  }
`;
