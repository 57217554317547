import { useMemo } from 'react';
import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { TabsRouter, TabsRouterProps } from '@src-v2/components/tabs/tabs-router';

const TabsContainer = styled.div`
  margin-top: 4rem;
`;

export const InventoryTabs = ({ routes, ...props }: TabsRouterProps) => {
  const asyncRoutes = useMemo(() => {
    return routes.map(route => ({
      ...route,
      render: () => <AsyncBoundary>{route.render()}</AsyncBoundary>,
    }));
  }, [routes]);

  return (
    <TabsContainer>
      <TabsRouter {...props} routes={asyncRoutes} />
    </TabsContainer>
  );
};
