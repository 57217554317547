import { useFieldArray, useFormContext } from 'react-hook-form';
import { StubAny } from '@src-v2/types/stub-any';

export const useTemplateEditor = () => {
  const { control, watch, setValue } = useFormContext();

  const { append, insert, remove } = useFieldArray({
    control,
    name: 'sections',
  });

  const template = watch();

  return {
    appendSection: () => {
      append({
        id: crypto.randomUUID(),
        title: '',
        description: '',
        questions: [],
      });
    },

    insertSection: (index: number) => {
      insert(index + 1, {
        id: crypto.randomUUID(),
        title: '',
        description: '',
        questions: [],
      });
    },

    removeSection: remove,

    addQuestion: (sectionIndex: number) => {
      setValue(`sections[${sectionIndex}].questions`, [
        ...template.sections[sectionIndex].questions,
        {
          id: crypto.randomUUID(),
          type: 'singleselect',
          title: '',
          description: '',
          options: [
            {
              value: 'Yes',
              score: '0.0',
            },
            {
              value: 'No',
              score: '0.0',
            },
          ],
          enabledBy: null,
          answer: null,
          answeredBy: null,
          score: null,
        },
      ]);
    },

    deleteQuestion: ({
      sectionIndex,
      questionIndex,
    }: {
      sectionIndex: number;
      questionIndex: number;
    }) =>
      setValue(
        `sections[${sectionIndex}].questions`,
        template.sections[sectionIndex].questions.filter(
          (_: StubAny, index: number) => index !== questionIndex
        )
      ),
    template,
  };
};
