import { Fragment, useCallback } from 'react';
import styled from 'styled-components';
import { IconButton } from '@src-v2/components/buttons';
import { BaseIcon } from '@src-v2/components/icons';
import { usePaginationRanges } from '@src-v2/hooks/use-pagination';
import { dataAttr } from '@src-v2/utils/dom-utils';

export function Pagination({
  total,
  selected = 1,
  padding = '...',
  boundaryPadding = 0,
  selectedPadding = 2,
  onClick,
  ...props
}) {
  const { atStart, atEnd, ranges } = usePaginationRanges({
    total,
    selected,
    boundaryPadding,
    selectedPadding,
  });

  const handleClick = useCallback(
    event => onClick?.(Number(event.currentTarget.dataset.value), event),
    [onClick]
  );

  return (
    <Container {...props}>
      <IconButton
        name="Chevron"
        data-value={selected - 1}
        data-disabled={dataAttr(atStart)}
        onClick={handleClick}
      />
      {ranges.flatMap((range, index) =>
        [
          index && <Fragment key={`padding.${index}`}>{padding}</Fragment>,
          range.map(pageNumber => (
            <Pagination.Button
              key={pageNumber}
              data-value={pageNumber}
              data-selected={dataAttr(pageNumber === selected)}
              onClick={handleClick}>
              {pageNumber}
            </Pagination.Button>
          )),
        ].filter(Boolean)
      )}
      <IconButton
        name="Chevron"
        data-value={selected + 1}
        data-disabled={dataAttr(atEnd)}
        onClick={handleClick}
      />
    </Container>
  );
}

Pagination.Button = styled.span`
  cursor: pointer;

  &:hover {
    color: var(--color-blue-gray-50);
  }

  &[data-selected] {
    color: var(--color-blue-gray-70);
    font-weight: 500;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 3rem;
  font-size: var(--font-size-s);
  color: var(--color-blue-gray-55);
  user-select: none;

  > ${BaseIcon}:first-child {
    transform: rotateY(180deg);
  }
`;
