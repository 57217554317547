import _ from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Heading, Paragraph, Title } from '@src-v2/components/typography';

export const FrameworksTooltip = styled(({ frameworks, ...props }) => {
  const frameworksByType = useMemo(
    () => _.groupBy(_.sortBy(frameworks, ['typeDisplayName', 'displayName']), 'typeDisplayName'),
    [frameworks]
  );
  return (
    <Tooltip
      {...props}
      content={
        <>
          <Title>Added Usage of Technologies</Title>
          {Object.entries(frameworksByType).map(([frameworkType, items]) => (
            <FrameworkGroup key={frameworkType}>
              <Heading>{frameworkType}</Heading>
              {items.map(framework => (
                <Paragraph key={framework.name}>{framework.displayName}</Paragraph>
              ))}
            </FrameworkGroup>
          ))}
        </>
      }
    />
  );
})`
  padding: 4rem 0;

  ${Title} {
    margin-bottom: 2rem;
    padding: 0 3rem 3rem;
    border-bottom: 0.25rem solid var(--color-white);
    font-size: var(--font-size-l);
  }
`;

const FrameworkGroup = styled.div`
  padding: 0 3rem;
  &:not(:last-of-type) {
    margin-bottom: 5rem;
  }

  > :not(:last-child) {
    margin-bottom: 1rem;
  }

  ${Heading} {
    font-size: var(--font-size-s);
    font-weight: 300;
  }

  ${Paragraph} {
    font-size: var(--font-size-m);
    font-weight: 500;
  }
`;
