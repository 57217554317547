import _ from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';

export const Marker = styled(({ text, term, ...props }) =>
  text
    .split(useMemo(() => new RegExp(`(${_.escapeRegExp(term)})`, 'gi'), [term]))
    .map((match, index) =>
      index % 2 ? (
        <em key={index} {...props}>
          {match}
        </em>
      ) : (
        match
      )
    )
)`
  font-style: normal;
  font-weight: 800;
`;
