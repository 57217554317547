import _ from 'lodash';
import { useMemo } from 'react';
import {
  AnalyticsDataField,
  AnalyticsEventName,
  useTrackAnalytics,
} from '@src-v2/components/analytics-layer';
import { Dropdown } from '@src-v2/components/dropdown';
import { SvgIcon } from '@src-v2/components/icons';
import { RiskIcon } from '@src-v2/components/risk/risk-icon';
import { OverrideRiskStatusModal } from '@src-v2/containers/action-modals/override-risk/override-risk-status';
import { getHighestRiskLevel } from '@src-v2/data/risk-data';
import { pluralFormat } from '@src-v2/utils/number-utils';

export function DismissActionItems({ data, setModal, closeModal }) {
  const risksData = useMemo(() => (_.isArray(data) ? data : [data]), [data]);

  const { handleAcceptRisk, handleIgnoreRisk, handleRevertOverrideRisk } = useDismissActionHandlers(
    risksData,
    setModal,
    closeModal
  );

  return (
    <>
      {risksData.every(riskItem => riskItem.riskLevel === 'Accepted') ? (
        <Dropdown.Item onClick={handleRevertOverrideRisk}>
          <RiskIcon
            riskLevel={getHighestRiskLevel(
              risksData.map(({ ruleRiskLevel }) => ruleRiskLevel.toLowerCase())
            )}
          />
          Return to Original Risk
        </Dropdown.Item>
      ) : (
        <Dropdown.Item onClick={handleAcceptRisk}>
          <SvgIcon name="Accept" />
          Accept {pluralFormat(risksData.length, 'Risk')}
        </Dropdown.Item>
      )}

      {risksData.every(riskItem => riskItem.riskLevel === 'Ignored') ? (
        <Dropdown.Item onClick={handleRevertOverrideRisk}>
          <SvgIcon name="Visible" />
          Un-Ignore risk
        </Dropdown.Item>
      ) : (
        <Dropdown.Item onClick={handleIgnoreRisk}>
          <SvgIcon name="Invisible" />
          Ignore {pluralFormat(risksData.length, 'Risk')}
        </Dropdown.Item>
      )}
    </>
  );
}

function useDismissActionHandlers(risksData, setModal, closeModal) {
  const trackAnalytics = useTrackAnalytics();

  return useMemo(() => {
    return {
      handleAcceptRisk() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Accept Risk',
        });
        setModal(
          <OverrideRiskStatusModal risk={risksData} newRiskStatus="Accepted" onClose={closeModal} />
        );
      },
      handleIgnoreRisk() {
        trackAnalytics(AnalyticsEventName.ActionClicked, {
          [AnalyticsDataField.ActionType]: 'Ignore Risk',
        });
        setModal(
          <OverrideRiskStatusModal risk={risksData} newRiskStatus="Ignored" onClose={closeModal} />
        );
      },
    };
  }, [setModal, closeModal, risksData]);
}
