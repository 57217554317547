import styled from 'styled-components';
import { VendorIcon } from '@src-v2/components/icons';
import { ExternalLink } from '@src-v2/components/typography';
import ServerTableInfiniteScroll from '@src/blocks/ServerTableInfiniteScroll';
import { Number } from '@src/components/Number';

const Projects = ({ developerKey, className }) => (
  <ServerTableInfiniteScroll
    className={className}
    tableScope={`developers/${developerKey}/projects`}
    headers={[
      { name: 'Name', weight: 2 },
      { name: 'Authored Issues', weight: 1 },
      { name: 'Participated Issues', weight: 1 },
    ]}
    rowProvider={relatedEntityProfile => {
      const {
        profile: { project },
      } = relatedEntityProfile;

      const developerProjectProfile = relatedEntityProfile.relatedProfile;
      return {
        key: relatedEntityProfile.profile.key,
        cells: [
          {
            content: (
              <ProjectNameColumn>
                <VendorIcon name={project.server.provider} />{' '}
                <ExternalLink href={project.url}>
                  {project.name ?? project.displayName}
                </ExternalLink>
              </ProjectNameColumn>
            ),
          },
          { content: <Number value={developerProjectProfile.authoredIssueCount || 0} /> },
          { content: <Number value={developerProjectProfile.participatedIssueCount || 0} /> },
        ],
      };
    }}
  />
);

const ProjectNameColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;

  ${ExternalLink} {
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export default Projects;
