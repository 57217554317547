import * as svgIcons from '@src-v2/assets/icons';
import * as languageIcons from '@src-v2/assets/languages';
import * as vendorIcons from '@src-v2/assets/vendors';
import { apiiroProviderNames, embeddedSemgrepProvider } from '@src-v2/services/coverage';

export function getSvgIcon(name) {
  if (!svgIcons[name]) {
    console.info(`SvgIcon "${name}" not found`);
  }
  return svgIcons[name] ?? null;
}

export function getVendorIconUrl(name) {
  if (apiiroProviderNames.includes(name)) {
    return vendorIcons.Apiiro;
  }
  if (name === embeddedSemgrepProvider) {
    return vendorIcons.ManagedSemgrep;
  }

  const icon = vendorIcons[name];
  if (!icon) {
    console.info(`VendorIcon "${name}" not found`);
    return null;
  }
  return icon;
}

export function getLanguageIconUrl(name) {
  if (!languageIcons[name]) {
    console.info(`LanguageIcon "${name}" not found`);
  }
  return languageIcons[name] ?? null;
}

export function hasSvgIcon(name) {
  return Boolean(svgIcons[name]);
}

export function hasVendorIcon(name) {
  return (
    apiiroProviderNames.includes(name) ||
    name === embeddedSemgrepProvider ||
    Boolean(vendorIcons[name])
  );
}

export function hasLanguageIcon(name) {
  return Boolean(languageIcons[name]);
}
