import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';
import { useSelector, useStore } from 'react-redux';
import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { InsightTag } from '@src-v2/components/tags';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { EllipsisText } from '@src-v2/components/typography';
import { riskLevelToRiskOrder } from '@src-v2/data/risk-data';
import { useInject, useSuspense } from '@src-v2/hooks';
import { makeUrl } from '@src-v2/utils/history-utils';
import { StyledRiskIcon } from '@src/blocks/RiskPosture/blocks/styles';
import { ClusterPaneCollapsibleCard } from '@src/cluster-map-work/components/charts/cluster-chart/property-providers/common-resource-properties';
import { ListPropertiesPanelSection, PropertiesPanel } from '../../../layout/properties-panel';

export default function ({ clusterKey, clusterResource, onToggleSearchTerm, highlightsList }) {
  if (clusterResource.resourceType === 'ClusterContainer') {
    return (
      <AsyncBoundary key="containerProperties">
        <ClusterContainerProperties
          clusterKey={clusterKey}
          clusterResource={clusterResource}
          onToggleSearchTerm={onToggleSearchTerm}
          highlightsList={highlightsList}
        />
      </AsyncBoundary>
    );
  }

  return [];
}

function ContainerDependenciesList({ overlays, highlightsList, onToggleSearchTerm, maxListItems }) {
  const uniqueDependencies = useMemo(
    () => _.uniq(overlays.dependencies?.dependencies || []),
    [overlays]
  );

  const highlightedUniqueDependencies = useMemo(
    () =>
      highlightsList
        .filter(highlight => highlight.overlayName === 'dependencies')
        .map(highlight =>
          uniqueDependencies.find(dep => dep.name === highlight.overlayInternalIdRef)
        ),
    [highlightsList, uniqueDependencies]
  );

  if (!uniqueDependencies.length) {
    return null;
  }

  return (
    <ListPropertiesPanelSection
      title="Dependencies"
      key="dependencies"
      list={uniqueDependencies}
      itemToString={dependency => (
        <Tooltip content={dependency.name}>
          <EllipsisText>{dependency.name}</EllipsisText>
        </Tooltip>
      )}
      itemToLink={dependency =>
        `/profiles/repositories/${dependency.repositoryKey}/inventory/components/dependencies`
      }
      maxListItems={maxListItems}
      itemToSearchTerm={item => ({ overlay: 'dependencies', value: item.name })}
      onToggleSearchTerm={onToggleSearchTerm}
      highlightedItems={highlightedUniqueDependencies}
    />
  );
}

function ContainerSecretsList({ overlays, highlightsList, onToggleSearchTerm, maxListItems }) {
  const uniqueSecrets = useMemo(() => _.uniq(overlays.secrets?.secrets || []), [overlays]);

  const highlightedUniqueSecrets = useMemo(
    () =>
      highlightsList
        .filter(highlight => highlight.overlayName === 'secrets')
        .map(highlight =>
          uniqueSecrets.find(secret => secret.refId === highlight.overlayInternalIdRef)
        ),
    [highlightsList, uniqueSecrets]
  );

  if (!uniqueSecrets.length) {
    return null;
  }

  return (
    <ListPropertiesPanelSection
      title="Secrets"
      key="secrets"
      list={uniqueSecrets}
      maxListItems={maxListItems}
      itemToString={secret => (
        <Tooltip
          content={`${secret.secret.subCategoryDescription} with hash ${secret.secret.shortSha}`}>
          <EllipsisText>
            {`${secret.secret.subCategoryDescription} with hash ${secret.secret.shortSha}`}
          </EllipsisText>
        </Tooltip>
      )}
      itemToLink={secret =>
        secret.moduleName
          ? `/module/${secret.repositoryKey}/${secret.moduleName}/inventory/data/secrets`
          : `/profiles/repositories/${secret.repositoryKey}/inventory/data/secrets`
      }
      itemToSearchTerm={item => ({ overlay: 'secrets', value: item.secret.shortSha })}
      onToggleSearchTerm={onToggleSearchTerm}
      highlightedItems={highlightedUniqueSecrets}
    />
  );
}

function ContainerRiskTriggerList({ overlays, highlightsList, onToggleSearchTerm, maxListItems }) {
  const store = useStore();

  useEffect(() => {
    store.dispatch.governanceRules.fetchData({ invalidateCache: false });
  }, []);

  const governanceRulesByKey = useSelector(
    ({ governanceRules: { rulesByKey: governanceRulesByKey } }) => governanceRulesByKey
  );

  const riskTriggers = useMemo(() => {
    return _.orderBy(
      (_.values(overlays['risk-triggers']?.triggersDataByRuleKey) ?? [])
        .map(triggersData => ({
          ...triggersData,
          rule: governanceRulesByKey?.[triggersData.ruleKey],
        }))
        .filter(triggersData => triggersData.rule),
      [
        triggersData => riskLevelToRiskOrder[triggersData.riskLevel],
        triggersData => triggersData.triggers.length,
      ],
      ['desc', 'desc']
    );
  }, [overlays, governanceRulesByKey]);

  const highlightedRiskTriggers = useMemo(
    () =>
      highlightsList
        .filter(highlight => highlight.overlayName === 'risk-triggers')
        .map(highlight =>
          riskTriggers.find(trigger => trigger.ruleKey === highlight.overlayInternalIdRef)
        ),
    [riskTriggers, highlightsList]
  );

  const renderRiskTriggerElement = riskTrigger => (
    <RenderRiskTriggerElementContainer>
      <StyledRiskIcon riskLevel={riskTrigger.riskLevel} />
      <Tooltip content={`${riskTrigger.rule.name} (${riskTrigger.triggers.length})`}>
        <EllipsisText>{`${riskTrigger.rule.name} (${riskTrigger.triggers.length})`}</EllipsisText>
      </Tooltip>
    </RenderRiskTriggerElementContainer>
  );

  const RenderRiskTriggerElementContainer = styled.div`
    display: flex;
    align-items: flex-end;
    margin: 1rem 0;
    gap: 2rem;
  `;

  const riskTriggerLink = ruleWithTriggers => {
    const [firstTrigger] = ruleWithTriggers.triggers;
    const profilePrefix = firstTrigger && `/profiles/repositories/${firstTrigger.profileKey}`;
    return (
      profilePrefix &&
      makeUrl(`${profilePrefix}/risk/development`, {
        fl: { searchTerm: ruleWithTriggers.rule.name },
      })
    );
  };

  if (!riskTriggers.length) {
    return null;
  }

  return (
    <ListPropertiesPanelSection
      title="Risks"
      key="risks"
      list={riskTriggers}
      maxListItems={maxListItems}
      itemToElementContent={renderRiskTriggerElement}
      itemToLink={riskTriggerLink}
      itemToSearchTerm={riskTrigger => ({
        overlay: 'risk-triggers',
        value: riskTrigger.rule.name,
      })}
      onToggleSearchTerm={onToggleSearchTerm}
      highlightedItems={highlightedRiskTriggers}
    />
  );
}

function ContainerInsightsList({ overlays, highlightsList, onToggleSearchTerm, maxListItems }) {
  const insights = useMemo(() => overlays.insights?.insights || [], [overlays]);

  const highlightedInsights = useMemo(() =>
    highlightsList
      .filter(highlight => highlight.overlayName === 'insights')
      .map(highlight => insights[parseInt(highlight.overlayInternalIdRef)])
  );

  const insighItemToElementContent = useCallback(
    insight => (
      <InsightTag
        onClick={
          onToggleSearchTerm &&
          (() => onToggleSearchTerm({ overlay: 'insights', value: insight.displayLabel }))
        }
        hint={onToggleSearchTerm && 'Click to toggle insight in search'}
        insight={{ description: insight.description, badge: insight.displayLabel }}
      />
    ),
    [onToggleSearchTerm]
  );

  if (!insights.length) {
    return null;
  }

  return (
    <ListPropertiesPanelSection
      title="Insights"
      key="insights"
      list={insights}
      maxListItems={maxListItems}
      itemToElementContent={insighItemToElementContent}
      highlightedItems={highlightedInsights}
    />
  );
}

function ContainerPortsList({
  clusterContainer,
  highlightsList,
  onToggleSearchTerm,
  maxListItems,
}) {
  const exposedPortNumbers = clusterContainer.exposedPortNumbers.map((port, index) => ({
    port: port.toString(),
    index: index.toString(),
    type: 'ExposedPortNumbers',
  }));

  const exposedSymbolicPorts = clusterContainer.exposedSymbolicPorts.map((port, index) => ({
    port,
    index: index.toString(),
    type: 'ExposedSymbolicPorts',
  }));

  const exposedPorts = exposedPortNumbers.concat(exposedSymbolicPorts);

  const highlightedPorts = highlightsList
    .filter(
      highlight =>
        highlight.propertyName === 'ExposedPortNumbers' ||
        highlight.propertyName === 'ExposedSymbolicPorts'
    )
    .map(highlight =>
      exposedPorts.find(
        exposedPort =>
          exposedPort.index === highlight.propertyInternalIdRef &&
          exposedPort.type === highlight.propertyName
      )
    );

  if (!exposedPorts.length) {
    return null;
  }

  return (
    <ListPropertiesPanelSection
      title="Exposed Ports"
      key="exposedPorts"
      list={exposedPorts}
      itemToString={exposedPort => exposedPort.port}
      maxListItems={maxListItems}
      itemToSearchTerm={item => ({ overlay: 'port', value: item.port })}
      onToggleSearchTerm={onToggleSearchTerm}
      highlightedItems={highlightedPorts}
    />
  );
}

function ContainerRepositoriesList({ overlays, maxListItems }) {
  if (!overlays?.repository?.linkedRepositoriesAndModules?.length) {
    return null;
  }

  const repoAndModuleToString = repoAndModule => {
    const repoModule =
      repoAndModule.moduleName || repoAndModule.modulePath
        ? `Module '${repoAndModule.moduleName || repoAndModule.modulePath}' in ${
            repoAndModule.repositoryName
          }`
        : repoAndModule.repositoryName;
    return (
      <Tooltip content={repoModule}>
        <EllipsisText>{repoModule}</EllipsisText>
      </Tooltip>
    );
  };

  const repoAndModuleToLink = repoAndModule =>
    repoAndModule.moduleName
      ? `/module/${encodeURIComponent(repoAndModule.repositoryKey)}/${encodeURIComponent(repoAndModule.moduleRoot)}/profile`
      : `/profiles/repositories/${encodeURIComponent(repoAndModule.repositoryKey)}/profile`;

  return (
    <ListPropertiesPanelSection
      title="Repositories"
      key="repos"
      list={overlays.repository.linkedRepositoriesAndModules}
      itemToString={repoAndModuleToString}
      itemToLink={repoAndModuleToLink}
      maxListItems={maxListItems}
    />
  );
}

function ClusterContainerProperties({
  clusterKey,
  clusterResource,
  onToggleSearchTerm,
  highlightsList,
  maxListItems = 10,
}) {
  const { clusters } = useInject();
  const clusterContainer = clusterResource;

  const overlays = useSuspense(clusters.getClusterOverlayInfo, {
    clusterKey,
    resourceName: clusterContainer.name,
    overlays: ['repository', 'dependencies', 'secrets', 'risk-triggers', 'insights'],
  });
  return (
    <>
      <ContainerInsightsList
        overlays={overlays}
        highlightsList={highlightsList}
        onToggleSearchTerm={onToggleSearchTerm}
        maxListItems={maxListItems}
      />

      <ContainerRiskTriggerList
        overlays={overlays}
        highlightsList={highlightsList}
        onToggleSearchTerm={onToggleSearchTerm}
        maxListItems={maxListItems}
      />

      <ClusterPaneCollapsibleCard defaultOpen title="Image">
        <PropertiesPanel.Section.Item
          highlighted={highlightsList.find(hl => hl.propertyName === 'Image')}>
          <Tooltip content={clusterContainer.image}>
            <EllipsisText>{clusterContainer.image}</EllipsisText>
          </Tooltip>
        </PropertiesPanel.Section.Item>
      </ClusterPaneCollapsibleCard>

      <ContainerRepositoriesList
        overlays={overlays}
        highlightsList={highlightsList}
        onToggleSearchTerm={onToggleSearchTerm}
        maxListItems={maxListItems}
      />

      <ContainerPortsList
        clusterContainer={clusterContainer}
        highlightsList={highlightsList}
        onToggleSearchTerm={onToggleSearchTerm}
        maxListItems={maxListItems}
      />
      <ContainerSecretsList
        overlays={overlays}
        highlightsList={highlightsList}
        onToggleSearchTerm={onToggleSearchTerm}
        maxListItems={maxListItems}
      />
      <ContainerDependenciesList
        overlays={overlays}
        highlightsList={highlightsList}
        onToggleSearchTerm={onToggleSearchTerm}
        maxListItems={maxListItems}
      />
    </>
  );
}
