import isEmpty from 'lodash/isEmpty';
import { ContentLimiter } from '@src-v2/components/content-limiter';
import { abbreviate, pluralFormat } from '@src-v2/utils/number-utils';
import { ConsumableLink } from './ConsumableLink';

export default function ConsumablesList({
  consumables,
  consumablesNamePlural,
  consumablesNameSingle,
  serverIconOnly,
  noLink,
  limit = 10,
  isRepository = false,
}) {
  const remaining = consumables.length - limit;
  return !isEmpty(consumables) ? (
    <ContentLimiter
      limit={limit}
      moreLabel={`+${abbreviate(remaining)} ${pluralFormat(
        remaining,
        consumablesNameSingle,
        consumablesNamePlural
      )}`}>
      {consumables.map(consumable => (
        <ConsumableLink
          key={consumable.key}
          consumable={consumable}
          serverIconOnly={serverIconOnly}
          noLink={noLink}
          isRepository={isRepository}
          writeNotMonitored
          small
        />
      ))}
    </ContentLimiter>
  ) : (
    <span>No {consumablesNamePlural}</span>
  );
}
