import _ from 'lodash';
import { useEffect } from 'react';
import { usePrevious } from '@src-v2/hooks/react-helpers/use-previous';

/**
 * an alternative version of useEffect that takes an object of dependencies
 * instead of an array and checks for deep equality instead of refs matches
 * @param handler
 * @param currentDeps
 */
export function useDeepObserver(handler, currentDeps) {
  const previousDeps = usePrevious(currentDeps, currentDeps);
  const hasChanged = previousDeps !== currentDeps && !_.isEqual(currentDeps, previousDeps);
  useEffect(hasChanged ? handler : _.noop, [hasChanged]);
}
