import { connect } from 'react-redux';
import { select } from '@src/store';
import RolesPage from './view';

const mapState = select(
  ({
    rolesPage: {
      roleConfigurations,
      isModalOpen,
      roleToEdit,
      isRoleValid,
      isEdit,
      supportedPermissions,
      servers,
    },
    assetCollectionProfiles: { assetConfigurations },
    applicationGroupProfiles: { applicationGroupAssetConfigurations },
  }) => ({
    roleConfigurations,
    isModalOpen,
    roleToEdit,
    isRoleValid,
    isEdit,
    supportedPermissions,
    assetConfigurations,
    servers,
    applicationGroupAssetConfigurations,
  })
);

const mapDispatch = ({
  rolesPage: {
    fetchData,
    createNew,
    onRoleEdited,
    saveRole,
    closeEditModal,
    setEditedRole,
    deleteRole,
  },
}) => ({
  fetchData,
  createNew,
  onRoleEdited,
  saveRole,
  closeEditModal,
  setEditedRole,
  deleteRole,
});

export default connect(mapState, mapDispatch)(RolesPage);
