import { observer } from 'mobx-react';
import styled from 'styled-components';
import { TextButton } from '@src-v2/components/button-v2';
import { ChangeBadge } from '@src-v2/components/change-badge';
import { Heading3 } from '@src-v2/components/typography';
import { preventDefault } from '@src-v2/utils/dom-utils';
import { formatNumber } from '@src-v2/utils/number-utils';

export const HighlightsContent = observer(
  ({ title, riskPercent, changePercent, callToAction, children, ...props }) => {
    return (
      <Container {...props}>
        <Heading3>
          {title}
          {isNaN(riskPercent) || (
            <ChangePercent>({formatNumber(riskPercent * 100, 0)}%)</ChangePercent>
          )}
          {isNaN(changePercent) || <ChangeBadge percent={changePercent} />}
        </Heading3>
        <Content>{children}</Content>
        <CallToAction>
          <TextButton underline showArrow size="xsmall" onClick={preventDefault}>
            {callToAction}
          </TextButton>
        </CallToAction>
      </Container>
    );
  }
);

const Container = styled.div`
  position: relative;
  padding: 4rem 4rem 4rem 0;
  flex-grow: 1;

  ${Heading3} {
    font-size: var(--font-size-l);
    margin-bottom: 3rem;
  }

  ${ChangeBadge} {
    float: right;
    margin-left: 2rem;
  }
`;

const ChangePercent = styled.span`
  margin-left: 2rem;
  color: var(--color-blue-gray-60);
`;

const Content = styled.div`
  padding-right: 10rem;
  color: var(--color-blue-gray-60);
`;

const CallToAction = styled.span`
  display: none;
  position: absolute;
  right: 4rem;
  max-width: 40rem;

  ${Container}:hover & {
    display: inline-block;
  }
`;
