import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import { useEffect } from 'react';
import styled from 'styled-components';
import ProcessTagSelector from '@src/blocks/ProcessTagSelector';
import { ConsumablesSelector } from '@src/components/ConsumablesSelector';
import { ErrorMessage } from '@src/components/ErrorMessage';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { FontBody } from '@src/style/common';
import { FiltersSection } from './FiltersSection';

const StyledForm = styled.div`
  ${FontBody};
`;

const StyledSection = styled.div`
  margin-top: ${props => (props.first ? 0 : '30px')};
`;

const StyledSectionTitle = styled.div`
  margin-bottom: 3rem;
`;

const StyledConsumablesSelector = styled(ConsumablesSelector)`
  width: 300px;

  .async-select-class__control {
    height: auto;
  }

  .async-select-class__indicators {
    height: 8rem;
    padding: 1rem;
    margin-top: 1rem;
  }
`;

const Section = ({ children, title, ...props }) => (
  <StyledSection {...props}>
    <StyledSectionTitle>{title}</StyledSectionTitle>
    {children}
  </StyledSection>
);

const areArrayValuesValid = array => !(isEmpty(array) || array.some(value => value.trim() === ''));

const isDefinitionProductScope = definition =>
  definition.allAssetCollections || !isEmpty(definition.assetCollectionKeys);

const allKey = 'all';
const productKey = 'product';

export const InterestingIssueDefinitionForm = ({
  definition,
  issueTypes,
  projectProfilesByKey,
  assetCollectionProfilesByKey,
  setDefinition,
  onValidityChange,
  errorMessage,
  filterSectionTitle,
  isTrackingTypeRequired,
}) => {
  const setProcessTags = tags =>
    setDefinition({
      ...definition,
      processTagKey: tags,
    });

  useEffect(() => {
    let isValid = true;

    if (
      !definition.allProjects &&
      !definition.allAssetCollections &&
      isEmpty(definition.projectKeys) &&
      isEmpty(definition.assetCollectionKeys)
    ) {
      isValid = false;
    } else if (isNil(definition.processTagKey)) {
      isValid = false;
    } else if (
      isTrackingTypeRequired &&
      ![definition.labels, definition.titles, definition.types, definition.components].some(
        areArrayValuesValid
      )
    ) {
      isValid = false;
    }

    onValidityChange(isValid);
  }, [definition, isTrackingTypeRequired, onValidityChange]);

  return (
    <StyledForm>
      <ErrorMessage message={errorMessage} />
      <Section first title="For the following ticketing projects">
        <HorizontalStack>
          <StyledConsumablesSelector
            isMulti
            isClearable={false}
            onKeyDown={event => event.stopPropagation()}
            tableScope="projects"
            placeholder="Project Name"
            allOption={allKey}
            specialOptions={[{ label: 'Related to an Application', value: productKey }]}
            initialSelectedConsumable={
              isDefinitionProductScope(definition)
                ? productKey
                : definition.allProjects
                  ? allKey
                  : compact(
                      definition.projectKeys.map(projectKey => projectProfilesByKey[projectKey])
                    ).map(projectProfile => projectProfile.project)
            }
            onChange={selectedProjects => {
              if (selectedProjects?.value === productKey) {
                setDefinition({
                  ...definition,
                  allProjects: false,
                  projectKeys: [],
                  allAssetCollections: true,
                  assetCollectionKeys: [],
                });
                return;
              }

              const useAllProjects = selectedProjects?.value === allKey;
              setDefinition({
                ...definition,
                allProjects: useAllProjects,
                projectKeys: useAllProjects ? [] : selectedProjects,
                allAssetCollections: false,
                assetCollectionKeys: [],
              });
            }}
          />
          {isDefinitionProductScope(definition) && (
            <StyledConsumablesSelector
              isMulti
              fetchProfiles
              isClearable={false}
              onKeyDown={event => event.stopPropagation()}
              tableScope="assetCollections"
              name="applications"
              placeholder="Application Name"
              allOption={allKey}
              initialSelectedConsumable={
                definition.allAssetCollections
                  ? allKey
                  : compact(
                      definition.assetCollectionKeys.map(
                        assetCollectionKey => assetCollectionProfilesByKey[assetCollectionKey]
                      )
                    )
              }
              onChange={selectedProducts => {
                const useAllProducts = selectedProducts?.value === allKey;
                setDefinition({
                  ...definition,
                  allProjects: false,
                  projectKeys: [],
                  allAssetCollections: useAllProducts,
                  assetCollectionKeys: useAllProducts ? [] : selectedProducts,
                });
              }}
            />
          )}
        </HorizontalStack>
      </Section>
      <ProcessesSection title="For this process">
        <ProcessTagSelector
          processes={definition.processTag}
          onSelect={processTag => setProcessTags(processTag?.value)}
        />
      </ProcessesSection>
      <Section title={filterSectionTitle}>
        <FiltersSection
          definition={definition}
          setDefinition={setDefinition}
          issueTypes={issueTypes}
        />
      </Section>
    </StyledForm>
  );
};

const ProcessesSection = styled(Section)`
  width: 75rem;
`;
