import { css } from 'styled-components';
import { FontBodyBold } from '@src/style/common';

export const ReactSelectThemeLightClass = 'async-select-class';
export const ReactSelectThemeLightStyle = css`
  .async-select-class__control {
    height: auto;
    min-height: 9rem;
    cursor: auto;
    border: 0.25rem solid var(--color-blue-gray-30);
    border-radius: 2rem;

    &:hover {
      border-color: var(--color-blue-gray-50);
    }

    &:focus,
    .async-select-class__control--is-focused {
      outline: none;
      box-shadow: none;
      border-color: var(--color-blue-gray-50);
    }
  }

  .async-select-class__option:hover,
  .async-select-class__option--is-focused {
    background-color: var(--color-blue-gray-15);
  }

  .async-select-class__option--is-selected {
    ${FontBodyBold};
    background-color: var(--color-blue-gray-15);
  }

  .async-select-class__multi-value {
    background-color: var(--color-blue-gray-15);
  }

  .async-select-class__placeholder {
    font-weight: 300;
    color: var(--color-blue-gray-50);
  }

  .async-select-class__control--is-disabled {
    .async-select-class__single-value--is-disabled,
    .async-select-class__placeholder {
      font-weight: 300;
      color: var(--color-blue-gray-50);
    }

    background-color: var(--color-blue-gray-15);
  }
`;
