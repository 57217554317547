export function safeParse(text, reviver) {
  try {
    return JSON.parse(text, reviver);
  } catch (error) {
    return text;
  }
}

export function toBase64(value) {
  return btoa(JSON.stringify(value));
}

export function fromBase64(value) {
  return atob(safeParse(value));
}
