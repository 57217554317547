import { connect } from 'react-redux';
import { select } from '@src/store';
import GovernancePage from './view';

const mapState = select(
  ({
    governancePage: {
      editedRule,
      editRuleIsOpen,
      editErrors,
      rules,
      options,
      failedToLoad,

      editedDefinition,
      editedDefinitionType,
      editDefinitionIsOpen,
      editDefinitionErrorMessage,
      definitionOptions,
      definitions,
    },
    projectProfiles: { profilesByKey: projectProfilesByKey },
    assetCollectionProfiles: { profilesByKey: assetCollectionProfilesByKey },
    organization: { issueTypes },
  }) => ({
    editedRule,
    editRuleIsOpen,
    editErrors,
    rules,
    options,
    failedToLoad,
    editedDefinition,
    editedDefinitionType,
    editDefinitionIsOpen,
    editDefinitionErrorMessage,
    definitionOptions,
    definitions,
    projectProfilesByKey,
    assetCollectionProfilesByKey,
    issueTypes,
    isLoading: state => state.loading.effects.governancePage.fetchData,
  })
);

const mapDispatch = ({
  governancePage: {
    fetchData,
    clearRulesData,
    clearDefinitionsData,
    setEditedRule,
    setEditErrors,
    addRule,
    closeEditRule,
    deleteRule,

    setEditedDefinition,
    setEditedDefinitionType,
    addDefinition,
    closeEditDefinition,
    applyDefinitionModifications,
    deleteDefinition,
  },
}) => ({
  fetchData,
  clearRulesData,
  clearDefinitionsData,
  addRule,
  closeEditRule,
  setEditErrors,
  deleteRule,
  setEditedRule,

  setEditedDefinition,
  setEditedDefinitionType,
  addDefinition,
  closeEditDefinition,
  applyDefinitionModifications,
  deleteDefinition,
});

export default connect(mapState, mapDispatch)(GovernancePage);
