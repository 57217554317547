import { forwardRef, useCallback, useImperativeHandle, useMemo, useRef, useState } from 'react';
import ForceGraph2D from 'react-force-graph-2d';
import styled from 'styled-components';
import { SearchInput } from '@src-v2/components/forms/search-input';
import { Link } from '@src-v2/components/typography';
import { useResizeObserver } from '@src-v2/hooks';
import { MultiSelect } from '@src/cluster-map-work/components/multi-select';

const D3GraphOverviewBase = (
  {
    graphData,
    overviewBoundingBox,
    screenBoundingBox,
    style,
    selectedNode,
    className,
    onClick,
    onDragTo,
    background = 'white',
    foreground = '#B6B9C9',
    selectedNodeForeground = '#012B70',
    viewportBackground = '#D5E2F4',
    viewportBorder = '#D5E2F4',
  },
  ref
) => {
  const canvasRef = useRef();
  const canvasVWidth = 200;
  const canvasVHeight = 200;

  useImperativeHandle(ref, () => ({
    refreshOverview,
  }));

  const toOverviewCoordinate = useCallback(
    (x, y) => {
      return [
        ((x - overviewBoundingBox.left) / overviewBoundingBox.width) * canvasVWidth,
        ((y - overviewBoundingBox.top) / overviewBoundingBox.width) * canvasVHeight,
      ];
    },
    [overviewBoundingBox]
  );

  const clientToChartCoordinate = useCallback(
    (clientX, clientY) => {
      const clientRect = canvasRef.current.getBoundingClientRect();

      const [canvasRelativeX, canvasRelativeY] = [
        (clientX - clientRect.left) / clientRect.width,
        (clientY - clientRect.top) / clientRect.height,
      ];

      return [
        overviewBoundingBox.left + canvasRelativeX * overviewBoundingBox.width,
        overviewBoundingBox.top + canvasRelativeY * overviewBoundingBox.height,
      ];
    },
    [overviewBoundingBox, canvasRef.current]
  );

  const toOverviewSize = useCallback(
    (w, h) => {
      return [
        (w / overviewBoundingBox.width) * canvasVWidth,
        (h / overviewBoundingBox.width) * canvasVHeight,
      ];
    },
    [overviewBoundingBox]
  );

  const refreshOverview = () => {
    const canvasContext = canvasRef.current.getContext('2d');

    canvasContext.fillStyle = background;
    canvasContext.fillRect(0, 0, canvasVWidth, canvasVHeight);

    canvasContext.fillStyle = viewportBackground;
    canvasContext.strokeStyle = viewportBorder;
    const [viewPortX, viewPortY] = toOverviewCoordinate(
      screenBoundingBox.left,
      screenBoundingBox.top
    );
    const [viewPortWidth, viewPortHeight] = toOverviewSize(
      screenBoundingBox.width,
      screenBoundingBox.height
    );
    canvasContext.fillRect(viewPortX, viewPortY, viewPortWidth, viewPortHeight);
    canvasContext.strokeRect(viewPortX, viewPortY, viewPortWidth, viewPortHeight);

    const drawNode = node => {
      canvasContext.beginPath();
      const [cx, cy] = toOverviewCoordinate(node.x, node.y);
      canvasContext.ellipse(cx, cy, 3, 3, 0, 0, 2 * Math.PI);
      canvasContext.fill();
    };

    canvasContext.fillStyle = foreground;
    graphData.nodes.forEach(drawNode);

    if (selectedNode) {
      canvasContext.fillStyle = selectedNodeForeground;
      drawNode(selectedNode);
    }
  };

  const handleCanvasClick = useCallback(event => {
    if (onClick) {
      const [chartCoordinateX, chartCoordinateY] = clientToChartCoordinate(
        event.clientX,
        event.clientY
      );

      onClick(event, chartCoordinateX, chartCoordinateY);
    }
  }, []);

  const handleCanvasMove = useCallback(event => {
    if (event.buttons & 1 && onDragTo) {
      const [chartCoordinateX, chartCoordinateY] = clientToChartCoordinate(
        event.clientX,
        event.clientY
      );

      onDragTo(event, chartCoordinateX, chartCoordinateY);
    }
  }, []);

  return (
    <canvas
      className={className}
      height={canvasVWidth}
      width={canvasVHeight}
      style={style}
      ref={canvasRef}
      onClick={handleCanvasClick}
      onMouseMove={handleCanvasMove}
    />
  );
};

export const D3GraphOverview = forwardRef(D3GraphOverviewBase);

export function SizedForceGraph2D(props) {
  const ref = useRef(null);
  const [{ width, height }, setDimensions] = useState({ width: 0, height: 0 });

  useResizeObserver(
    ref,
    useCallback(({ contentRect }) => {
      if (contentRect.width !== width || contentRect.height !== height) {
        setDimensions(contentRect);
      }
    }, [])
  );

  return (
    <SizedForceGraph2DContainer ref={ref}>
      <ForceGraph2D width={width} height={height} {...props} ref={props.chartRef} />
    </SizedForceGraph2DContainer>
  );
}

export const InChartHeaderControls = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;

  top: 0;
  left: 0;

  width: 100%;
`;

InChartHeaderControls.SearchHeader = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;

  padding: 6rem 7rem;
  gap: 3rem;

  background: linear-gradient(
    180deg,
    #fbfbfe 0%,
    rgba(251, 251, 254, 0.96) 21.35%,
    rgba(251, 251, 254, 0.96) 75%,
    rgba(251, 251, 254, 0) 100%
  );
`;

InChartHeaderControls.SearchBox = styled(SearchInput)`
  margin-right: 3rem;
  width: 90rem;
`;

InChartHeaderControls.MultiSelectFilter = MultiSelectFilter;

function MultiSelectFilter({ filterOptions, onChange }) {
  const handleComponentUpdate = useCallback(
    (index, newComponent) => {
      const newFilterOptions = [...filterOptions];
      newFilterOptions[index] = newComponent;

      onChange(newFilterOptions);
    },
    [onChange, filterOptions]
  );

  const handleClearFilters = useCallback(() => {
    const newFilterOptions = filterOptions.map(filterComponent => ({
      ...filterComponent,
      options: filterComponent.options.map(filterOption => ({
        ...filterOption,
        isSelected: false,
      })),
    }));
    onChange(newFilterOptions);
  }, [onChange, filterOptions]);

  const anySelected = useMemo(
    () =>
      Boolean(
        filterOptions.find(filterComponent =>
          filterComponent.options.find(option => option.isSelected)
        )
      ),
    [filterOptions]
  );

  return (
    <MultiSelectFilterContainer>
      {filterOptions.map((filterComponent, index) => (
        <MultiSelectFilterSingleFilterDropdown
          key={`filter${index}`}
          filterComponent={filterComponent}
          onChange={newComponent => handleComponentUpdate(index, newComponent)}
        />
      ))}
      {anySelected && <StyledLink onClick={handleClearFilters}>Clear filters</StyledLink>}
    </MultiSelectFilterContainer>
  );
}

function MultiSelectFilterSingleFilterDropdown({ filterComponent, onChange }) {
  const selected = useMemo(
    () => new Set(filterComponent.options.filter(option => option.isSelected)),
    [filterComponent]
  );

  const handleSelectionChange = useCallback(
    newSelection => {
      const newFilterComponent = {
        ...filterComponent,
        options: filterComponent.options.map(option => ({
          ...option,
          isSelected: newSelection.has(option),
        })),
      };

      onChange(newFilterComponent);
    },
    [filterComponent, onChange]
  );

  const placeholder = useMemo(() => {
    return selected.size === 0
      ? filterComponent.componentDisplayName
      : selected.size === 1
        ? `${filterComponent.componentDisplayName}: ${selected.values().next().value.displayName}`
        : `${filterComponent.componentDisplayName}: ${selected.size} selected`;
  }, [filterComponent]);

  return (
    <MultiSelect
      options={filterComponent.options}
      value={selected}
      placeholder={placeholder}
      identity={option => option.displayName}
      onChange={handleSelectionChange}
    />
  );
}

const MultiSelectFilterContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 3rem;
  margin-right: 3rem;
`;

const SizedForceGraph2DContainer = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  overflow: hidden;
`;

const StyledLink = styled(Link)`
  text-decoration: underline;
  font-weight: 600;
`;
