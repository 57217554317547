import _ from 'lodash';
import isEmpty from 'lodash/isEmpty';
import { getLanguageDisplayName, isIgnoredLanguage } from '@src-v2/data/languages';
import { businessImpactLevelToName } from './businessImpactService';

const filterTypes = {
  AND: 'and',
  OR: 'or',
};

const listFilterCategories = [
  'Languages',
  'Licenses',
  'Technologies',
  'Labels',
  'BusinessImpact',
  'DeploymentLocation',
  'RiskLevel',
  'Monitoring',
  'ContainingRiskLevel',
  'ServerUrl',
  'SecretsFilePath',
  'SecretsCategory',
  'SecretsType',
  'SecretsEnvironment',
  'SensitiveDataTypes',
  'SensitiveDataPath',
  'RoleType',
  'SpringSecurityControls',
  'StorageBucketAccessPolicy',
  'IssueLabels',
  'IssueComponents',
  'ProcessTags',
  'IssueTypes',
  'RiskFactors',
  'ProjectId',
  'ServiceType',
  'VulnerabilityTitles',
  'VulnerabilityExploitMaturity',
  'VulnerabilityDependency',
  'ElementsRiskLevel',
  'HttpMethod',
  'IacCategory',
  'MisconfigurationType',
  'Providers',
  'Runtime',
  'TeamKeys',
  'ApplicationGroupKeys',
  'ApplicationTags',
  'ApplicationType',
  'ComplianceRequirements',
  'BusinessUnit',
  'EstimatedUsersNumber',
  'EstimatedRevenue',
  'RepositoryKeys',
  'Namespaces',
  'ClusterType',
  'NodeTypes',
  'AccessType',
  'CreatedBy',
  'Permission',
];

const constructFilterOptions = (currentFilters, filterOptions) => {
  const filters = [];
  filterOptions.forEach(filtersGroup => {
    const options = filtersGroup.options
      .filter(filterOption => !shouldIgnore(filterOption, filtersGroup.component))
      .map(filterOption => ({
        name: filterOption.name,
        sortOrder: filterOption.sortOrder,
        displayName: getDisplayName(filterOption, filtersGroup.component),
        isSelected: isFilterOptionSelected(
          currentFilters,
          filtersGroup.component,
          filterOption.name
        ),
      }));
    filters.push({
      component: filtersGroup.component,
      componentDisplayName: filtersGroup.componentDisplayName,
      sortOrder: filtersGroup.sortOrder,
      options,
    });
  });

  return filters;
};

const isFilterOptionSelected = (filters, filterGroup, filter) =>
  _.includes(listFilterCategories, filterGroup)
    ? filterGroup in filters.listFilters && _.includes(filters.listFilters[filterGroup], filter)
    : _.includes(filters.booleanFilters, filter);

const getDisplayName = (filterOption, groupName) => {
  switch (groupName) {
    case 'Languages':
      return getLanguageDisplayName(filterOption.name);

    case 'BusinessImpact':
      return businessImpactLevelToName[filterOption.name] ?? filterOption.name;

    default:
      return filterOption.displayName;
  }
};

const shouldIgnore = (filterOption, groupName) =>
  groupName === 'Languages' && isIgnoredLanguage(filterOption.name);

const mergeFilters = (originalFilters, newFilters) =>
  _.mergeWith({}, originalFilters, newFilters, (objValue, srcValue) => {
    if (_.isArray(objValue)) {
      return _.union(objValue, srcValue);
    }
  });

const handleListFilterToggle = (filters, filter, filterGroup) => {
  if (!(filterGroup in filters.listFilters)) {
    filters.listFilters[filterGroup] = [filter];
  } else if (!_.includes(filters.listFilters[filterGroup], filter)) {
    filters.listFilters[filterGroup].push(filter);
  } else {
    _.remove(filters.listFilters[filterGroup], value => value === filter);
  }
};

const handleBooleanFilterToggle = (filters, filter) => {
  if (!_.includes(filters.booleanFilters, filter)) {
    filters.booleanFilters.push(filter);
  } else {
    _.remove(filters.booleanFilters, value => value === filter);
  }
};

const filterItemsBySearchTerm = (items, searchTerm, comparisonKeysGetter) => {
  const filtered = _.cloneDeep(items);
  return _.isEmpty(searchTerm)
    ? filtered
    : filtered.filter(item =>
        _.some(comparisonKeysGetter(item), key => key && key.toLowerCase().includes(searchTerm))
      );
};

const getListFilterOptions = (items, displayFunction) =>
  _.map(items, item => ({
    name: item,
    displayName: displayFunction?.(item) ?? item,
  }));

const isFiltersEmpty = filters =>
  !filters ||
  (isEmpty(filters.booleanFilters) &&
    isEmpty(filters.customFilters) &&
    isEmpty(filters.listFilters));

const filtersService = {
  filterTypes,
  listFilterCategories,

  constructFilterOptions,
  getDisplayName,
  mergeFilters,

  handleListFilterToggle,
  handleBooleanFilterToggle,

  filterItemsBySearchTerm,

  getListFilterOptions,
  isFiltersEmpty,
};

export default filtersService;
