import { useLayoutEffect, useState } from 'react';

export function useDetectOverflowRight(ref, threshold = 0) {
  const [overflow, setOverflow] = useState(false);
  useLayoutEffect(() => {
    const { x, width } = ref.current.getBoundingClientRect();
    setOverflow(document.body.clientWidth < x + width + threshold);
  }, []);
  return overflow;
}
