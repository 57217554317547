import { TimelineEvent } from '@src/blocks/TimelineInfiniteScroll/components/TimelineEvent';

export const TimelineBranchDeletion = ({
  branchDeletionRuleTimelineEvent,
  previousTimelineEvent,
  repositoryProfilesByKey,
  ...props
}) => {
  let title = 'Branch protection deletion modified';
  if (
    branchDeletionRuleTimelineEvent.materialChangesLabels !== null &&
    branchDeletionRuleTimelineEvent.materialChangesLabels.length > 0
  ) {
    [title] = branchDeletionRuleTimelineEvent.materialChangesLabels;
  }

  const { repository } = repositoryProfilesByKey[branchDeletionRuleTimelineEvent.repositoryKeys[0]];

  return (
    <TimelineEvent
      currentTimelineEvent={branchDeletionRuleTimelineEvent}
      previousTimelineEvent={previousTimelineEvent}
      {...props}
      titleContent={title}
      body={
        <div>
          <div>
            Branch deletion policy for branch '{branchDeletionRuleTimelineEvent.branchName}' in
            repository '{repository.name}' (server URL: '{repository.serverUrl}
            ') was modified and is now set to{' '}
            {branchDeletionRuleTimelineEvent.governanceThenSubType === 'Discovered'
              ? 'allowed'
              : 'not allowed'}
          </div>
        </div>
      }
    />
  );
};
