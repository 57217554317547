import { isObservable } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { ActivityIndicator } from '@src-v2/components/activity-indicator';
import { Counter } from '@src-v2/components/counter';
import { ElementSeparator } from '@src-v2/components/element-separator';
import { VendorIcon } from '@src-v2/components/icons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Link } from '@src-v2/components/typography';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject } from '@src-v2/hooks';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';
import { ActionResults } from '@src/blocks/RiskRuleTriggers/blocks/ActionResults';
import {
  DismissActionsDropdown,
  TakeActionsDropdown,
  createActionsData,
} from '@src/blocks/RiskRuleTriggers/blocks/RiskTriggerActionsMenu';
import TriggerStatus from '@src/blocks/RiskRuleTriggers/blocks/TriggerStatus';
import {
  ExploreActionsMenu,
  RenderAvatarContributors,
} from '@src/components/CodeAggregation/Common';
import { HorizontalStack } from '@src/components/HorizontalStack';
import TooltippedEllipsis from '@src/components/TooltippedEllipsis';

export const InsightsPaneTitle = observer(
  ({
    fetchData,
    introducedDeveloperProfile,
    modifiedDeveloperProfile,
    codeOwnerEntity,
    trigger,
    element,
    title,
    noTitleTip,
    codeReference,
    repository,
    additionalLocationContext,
    profile,
    profileType,
    ruleTriggers,
    messageContent,
  }) => {
    const { rbac } = useInject();
    const hasWritePermission = rbac.canEdit(resourceTypes.Risk);
    useEffect(() => {
      fetchData({ repository, element });
    }, [fetchData, repository, element]);

    const [triggerApplication, ...excessiveApplications] =
      trigger?.applications ?? (profile?.profileType === 'ApplicationProfile' ? [profile] : []);

    const actionsData = useMemo(() => {
      if (!ruleTriggers || !trigger) {
        return;
      }

      if (isObservable(trigger)) {
        return trigger;
      }

      const relatedEntityRepository = repository ?? profile.repositoryByKey?.[trigger.profileKey];
      return createActionsData(
        trigger,
        ruleTriggers.rule,
        profile,
        relatedEntityRepository
          ? {
              key: relatedEntityRepository.key,
              name: relatedEntityRepository.uniqueName ?? relatedEntityRepository.name,
              type: 'RepositoryProfile',
            }
          : undefined,
        [triggerApplication, ...excessiveApplications].filter(Boolean),
        messageContent
      );
    }, [
      ruleTriggers,
      profile,
      profileType,
      trigger,
      repository,
      triggerApplication,
      excessiveApplications,
    ]);

    const shouldRenderActionsAndStatus = element && element?.entityType !== 'Issue';

    const actions =
      ruleTriggers && trigger && !trigger?.riskChange ? (
        <>
          <ExploreActionsMenu
            codeReference={codeReference}
            annotatedRepositoryAndModuleReferences={trigger}
            defaultPaneOpenOptions={{ level: secondaryPaneLevel }}
            repository={repository}
            entityTitle={title}
          />
          <Tooltip
            content="Contact your admin perform risk-related actions"
            disabled={hasWritePermission}>
            <DismissActionsDropdown disabled={!hasWritePermission} data={actionsData} />
          </Tooltip>
          <Tooltip
            content="Contact your admin perform risk-related actions"
            disabled={hasWritePermission}>
            <TakeActionsDropdown disabled={!hasWritePermission} data={actionsData} />
          </Tooltip>
        </>
      ) : (
        <ExploreActionsMenu
          codeReference={codeReference}
          repository={repository}
          defaultPaneOpenOptions={{ level: secondaryPaneLevel }}
          entityTitle={title}
        />
      );

    return (
      <>
        <StyledHorizontalStack spread>
          <TitleContainer>
            <TooltippedEllipsis text={title} tip={noTitleTip ? '' : title} />
            <ElementSeparator as={RepositoryInterpunct}>
              {triggerApplication && (
                <>
                  <TooltippedEllipsis text={triggerApplication.name} />
                  {Boolean(excessiveApplications?.length) && (
                    <Tooltip
                      content={excessiveApplications.map(app => (
                        <div key={app.name}>{app.name}</div>
                      ))}>
                      <Counter>+{excessiveApplications.length}</Counter>
                    </Tooltip>
                  )}
                </>
              )}
              {repository && (
                <RepositoryDetails>
                  {repository.server?.providerGroup && (
                    <VendorIcon
                      name={repository.server.providerGroup ?? repository.server.provider}
                    />
                  )}
                  <Link to={`/profiles/repositories/${repository.key}`}>
                    <TooltippedEllipsis text={`${repository.name} (${repository.referenceName})`} />
                  </Link>
                  {Boolean(profile) && (
                    <ActivityIndicator
                      content={profile.isActive ? 'Active' : 'Inactive'}
                      active={profile.isActive}
                    />
                  )}
                </RepositoryDetails>
              )}
              {additionalLocationContext &&
                additionalLocationContext.map((locationContext, index) => (
                  <span key={index}>{locationContext}</span>
                ))}
            </ElementSeparator>
          </TitleContainer>
          {shouldRenderActionsAndStatus && (
            <HorizontalStack>
              {actions}
              <RenderAvatarContributors
                modifiedDeveloperProfile={modifiedDeveloperProfile}
                introducedDeveloperProfile={introducedDeveloperProfile}
                codeOwnerEntity={codeOwnerEntity}
              />
              {Boolean(trigger) && (
                <>
                  <ActionResults actionsTaken={trigger} />
                  <TriggerStatus
                    riskLevel={trigger.riskLevel}
                    triggerRiskOverride={trigger.triggerRiskOverride}
                  />
                </>
              )}
            </HorizontalStack>
          )}
        </StyledHorizontalStack>
      </>
    );
  }
);

const StyledHorizontalStack = styled(HorizontalStack)`
  width: 100%;
  line-height: normal;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  gap: 2rem;
`;

const FlexContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  ${ActivityIndicator} {
    margin-top: 0.5rem;
  }
`;

const RepositoryInterpunct = styled(FlexContainer)`
  font-weight: 400;
  font-size: var(--font-size-m);
  color: var(--color-blue-gray-70);
  max-width: 130rem;
`;

const RepositoryDetails = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  ${Link} {
    display: flex;
    align-items: center;
    flex-shrink: 1;
    text-after-overflow: elipsis;
    overflow: hidden;
  }
`;
