import _ from 'lodash';
import styled from 'styled-components';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Link } from '@src/components/Link';
import ServerIcon from '@src/components/ServerIcon';

export const BeforeHeaderPlacement = 'before_header';
export const AfterHeaderPlacement = 'after_header';

export const IssuesLink = ({ issues, providerIconPlacement, spacing = 0.2 }) =>
  _.map(
    _.groupBy(issues, issue => issue.provider),
    (issuesInProvider, provider) => (
      <StyledIssue spacing={spacing} key={provider}>
        {providerIconPlacement === BeforeHeaderPlacement && (
          <ServerIcon size="small" provider={provider} />
        )}
        {pluralFormat(issuesInProvider.length, 'Issue:', 'Issues:')}
        <HorizontalStack spacing={0.3}>
          {providerIconPlacement === AfterHeaderPlacement && (
            <ServerIcon size="small" provider={provider} />
          )}
          {issuesInProvider.map(issue => (
            <Tooltip content={issue.title} key={issue.id}>
              <div>
                <Link url={issue.url} underline external>
                  {issue.id.match(/^\d/) ? '#' : ''}
                  {issue.id}
                </Link>
              </div>
            </Tooltip>
          ))}
        </HorizontalStack>
      </StyledIssue>
    )
  );

const StyledIssue = styled(HorizontalStack)`
  color: var(--default-text-color);
  padding-bottom: 3rem;
`;
