import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { useQueryParams, useSafeState } from '@src-v2/hooks';

export const StyledInfiniteScroll = styled(InfiniteScroll)`
  width: 100%;
  margin-bottom: 8rem;
`;

const StyledDiv = styled.div`
  text-align: center;
  margin-top: 9rem;
`;

export const ScrollContext = createContext(null);

export const InfiniteScroller = ({
  searchFunc,
  children,
  className,
  parentFilterType,
  parentFilters,
  parentSort,
  contentIsSet,
  scrollParent,
}) => {
  const scrollContext = useContext(ScrollContext);
  const [prevSearchTerm, setPrevSearchTerm] = useState(null);
  const [pageState, setPageState] = useSafeState({
    hasMoreItems: true,
    isLoadingMore: false,
    pageCount: 0,
    sort: '',
    filters: {},
    searchTerm: '',
    filtersType: '',
  });
  const {
    queryParams: { searchTerm },
  } = useQueryParams();

  useEffect(() => {
    if (
      (contentIsSet === false && pageState.pageCount > 0) ||
      parentSort !== pageState.sort ||
      parentFilters !== pageState.filters ||
      parentFilterType !== pageState.filtersType ||
      prevSearchTerm !== searchTerm
    ) {
      setPageState({
        ...pageState,
        sort: parentSort,
        filters: parentFilters,
        searchTerm,
        filtersType: parentFilterType,
        pageCount: 0,
        isLoadingMore: false,
        hasMoreItems: true,
      });
      setPrevSearchTerm(searchTerm);
    }
  }, [contentIsSet, parentSort, parentFilters, parentFilterType, searchTerm]);

  const loadMore = async () => {
    setPageState(currentState =>
      currentState.isLoadingMore ? currentState : { ...currentState, isLoadingMore: true }
    );
    const results = (await searchFunc(pageState.pageCount)) ?? [];
    setPageState({
      ...pageState,
      hasMoreItems: results.length > 0,
      pageCount: pageState.pageCount + 1,
      isLoadingMore: false,
    });
  };

  const getScrollParent = useMemo(() => {
    if (scrollContext) {
      scrollParent = scrollContext;
    }

    if (typeof scrollParent === 'function') {
      return scrollParent;
    }
    if (typeof scrollParent === 'string' && scrollParent !== 'parentNode') {
      return () => document.getElementById(scrollParent);
    }
  }, [scrollParent]);

  return (
    <StyledInfiniteScroll
      loadMore={loadMore}
      loader={null}
      useWindow={false}
      className={className}
      getScrollParent={getScrollParent}
      hasMore={pageState.hasMoreItems && !pageState.isLoadingMore}>
      {children(pageState.isLoadingMore)}
      {pageState.isLoadingMore && (
        <StyledDiv>
          <LogoSpinner />
        </StyledDiv>
      )}
    </StyledInfiniteScroll>
  );
};
