import _ from 'lodash';
import { useEffect } from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { Gutters, StickyHeader } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { HeaderRiskDetails } from '@src-v2/components/risk/risk-details';
import { useRiskProfile } from '@src-v2/components/risk/risk-utils';
import { InventoryV2 } from '@src-v2/containers/inventory/inventory-v2';
import { ModuleRisksTabSwitch } from '@src/blocks/RiskPosture/risks-tab-switch';
import TimelineInfiniteScroll from '@src/blocks/TimelineInfiniteScroll';
import { PageLoading } from '@src/components/PageLoading';
import profilesService from '@src/services/profilesService';
import { ForeignEntityBackConnections } from '../ForeignEntityBackConnections';
import Profile from './Profile';
import {
  enrichProfileStatisticsRiskAndBusinessImpact,
  enrichProfileStatisticsUrls,
} from './ProfileStatistics';

export default function RiskProfilePage({
  fetchData,
  clearData,
  hasFetchedData,
  profileKey,
  profile,
  title,
  statistics,
  profileDefinitionsKey,
  profilesPage,
  profilePageUrl,
  subEntityKey,
  profileTypePlural,
  profileType,
  foreignEntityLinkTarget,
  maxStatisticsCount = 8,
}) {
  const { path: basePath } = useRouteMatch();
  const profileDefinitions = profilesService.getProfileDefinition(profileDefinitionsKey);
  profileType ??= profileDefinitions.profileType;

  const riskProfile = useRiskProfile(profile);

  if (!_.isNil(profileDefinitions)) {
    profilesPage = profileDefinitions.routePath;
    profilePageUrl = `${profileDefinitions.routePath}/${profileKey}`;
  }

  useEffect(() => {
    fetchData({ key: profileKey, invalidateCache: false });
    return clearData;
  }, [fetchData, profileKey, clearData]);

  if (!hasFetchedData) {
    return <PageLoading />;
  }

  if (!profile) {
    return <Redirect to={profilesPage} />;
  }

  if (statistics) {
    statistics = statistics.slice(0, maxStatisticsCount);
    enrichProfileStatisticsRiskAndBusinessImpact(statistics, profile);
    enrichProfileStatisticsUrls(statistics, profilePageUrl);
  }

  return (
    <Page>
      <StickyHeader
        title={title ?? profile.name}
        navigation={[
          { label: 'Profile', to: 'profile' },
          { label: 'Risk', to: 'risk' },
          { label: 'Timeline', to: 'timeline' },
          { label: 'Inventory', to: 'inventory' },
          foreignEntityLinkTarget && { label: 'Connections', to: 'connections' },
        ].filter(Boolean)}>
        <HeaderRiskDetails profile={riskProfile} />
      </StickyHeader>

      <Gutters>
        <Switch>
          <Route path={`${basePath}/profile`}>
            <Profile profile={profile} statistics={statistics} />
          </Route>

          <Route path={`${basePath}/timeline`}>
            <TimelineInfiniteScroll
              subEntityKey={subEntityKey}
              entityKey={profileKey}
              table={profileTypePlural ?? profileDefinitions.profileTypePlural}
            />
          </Route>

          <Route path={`${basePath}/inventory`}>
            <InventoryV2 profile={profile} profileType={profileType} />
          </Route>
          <Route path={`${basePath}/risk`}>
            <ModuleRisksTabSwitch
              moduleRoot={profile.module.root}
              moduleRepositoryKey={profileKey}
            />
          </Route>

          <Route path={`${basePath}/connections`}>
            <ForeignEntityBackConnections
              linkTarget={foreignEntityLinkTarget}
              linkTargetDescription={profile.name}
              profile={profile}
            />
          </Route>

          <Redirect to={`${basePath}/profile`} />
        </Switch>
      </Gutters>
    </Page>
  );
}
