import { connect } from 'react-redux';
import { select } from '@src/store';
import RiskRuleTriggers from './view';

const mapState = select(
  ({
    riskRuleTriggers: { ruleTriggers },
    pane: { secondaryPane },
    governanceRules: { rulesByKey: governanceRulesByKey },
  }) => ({
    ruleTriggers,
    secondaryPane,
    governanceRulesByKey,
  })
);

const mapDispatch = ({ riskRuleTriggers: { clearData, fetchData } }) => ({
  clearData,
  fetchData,
});

export default connect(mapState, mapDispatch)(RiskRuleTriggers);
