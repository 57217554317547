import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { Card } from '@src-v2/components/cards';
import { Section } from '@src-v2/components/layout';
import { DateTime, Time } from '@src-v2/components/time';
import { Subtitle } from '@src-v2/components/typography';
import { customScrollbar } from '@src-v2/style/mixins';

export const HighlightsSection = styled(
  ({ attackSurface, contributors, technologies, ...props }) => (
    <Section {...props}>
      <Subtitle>
        Attack Surface Highlights <DateTime format="QQQ yyyy" />
      </Subtitle>
      {attackSurface && <AttackSurfaceCard>{attackSurface}</AttackSurfaceCard>}
      {contributors && <ContributorsCard>{contributors}</ContributorsCard>}
      {technologies && <TechnologiesCard>{technologies}</TechnologiesCard>}
    </Section>
  )
)`
  display: grid;
  padding-top: 0;
  grid-template-columns: minmax(120rem, 1fr) minmax(100rem, 40%);
  grid-template-rows: 13rem fit-content(0) 78rem;
  grid-template-areas:
    'title title'
    'attackSurface technologies'
    'contributors technologies';
  gap: 7rem;

  > ${Subtitle} {
    grid-area: 1 / span 2;
    margin: 0 3rem 6rem;
    font-size: var(--font-size-xl);
    font-weight: 700;

    ${Time} {
      margin-left: 3rem;
      color: var(--color-blue-gray-45);
      font-size: var(--font-size-m);
      font-weight: 600;
    }
  }

  ${Card} {
    border-radius: 2rem;
  }
`;

const AttackSurfaceCard = styled(({ children, ...props }) => (
  <div {...props}>
    <AsyncBoundary defaultsContainer={AttackSurfacePlaceholder}>{children}</AsyncBoundary>
  </div>
))`
  grid-area: attackSurface;
  display: grid;
  grid-auto-rows: 49rem;
  gap: 6rem;
`;

const ContributorsCard = styled(({ children, ...props }) => (
  <Card {...props}>
    <AsyncBoundary>{children}</AsyncBoundary>
  </Card>
))`
  grid-area: contributors;
  display: flex;
  padding: 8rem;
`;

const TechnologiesCard = styled(({ children, ...props }) => (
  <Card {...props} data-scrollbar-gutters>
    <AsyncBoundary>{children}</AsyncBoundary>
  </Card>
))`
  grid-area: technologies;
  padding: 8rem 6rem;

  ${customScrollbar};
`;

const AttackSurfacePlaceholder = styled(Card)`
  display: flex;
  grid-area: span 3;
  padding: 8rem;
`;
