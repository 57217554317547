import { connect } from 'react-redux';
import { select } from '@src/store';
import ModulePage from './view';

const mapState = select(
  ({
    modulePage: { moduleProfile, moduleRiskTriggers, repositoryKey, hasFetchedData, moduleKey },
    governanceRules: { rulesByKey: governanceRulesByKey },
  }) => ({
    moduleProfile,
    moduleRiskTriggers,
    repositoryKey,
    hasFetchedData,
    governanceRulesByKey,
    moduleKey,
  })
);

const mapDispatch = ({ modulePage: { fetchData, clearData } }) => ({
  fetchData,
  clearData,
});

export default connect(mapState, mapDispatch)(ModulePage);
