import _ from 'lodash';
import styled from 'styled-components';
import { SvgIcon } from '@src-v2/components/icons';
import { RiskIndicator } from '@src/blocks/RiskPosture/blocks/OpenPaneIndicator';
import { HorizontalStack } from '@src/components/HorizontalStack';

const StyledHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: baseline;
  margin-left: 6rem;
`;

const StyledRuleName = styled.div`
  color: var(--default-text-color);
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
`;

const StyledHorizontalStack = styled(HorizontalStack)`
  width: 100%;
  padding: 12rem 0;

  ${RiskIndicator} {
    height: 12rem;
  }
`;

export default function DetailsPaneHeader({ ruleTriggers, actionsMenu }) {
  const ruleName = ruleTriggers.rule.name ?? `Rule ${ruleTriggers.rule.ordinalId}`;

  return (
    <StyledHorizontalStack spread>
      <HorizontalStack spacing="0">
        <RiskIndicator riskLevel={_.camelCase(ruleTriggers.riskLevel)} data-selected>
          <SvgIcon name="Chevron" />
        </RiskIndicator>
        <StyledHeader>
          <StyledRuleName>{ruleName}</StyledRuleName>
        </StyledHeader>
      </HorizontalStack>
      <HorizontalStack>{actionsMenu}</HorizontalStack>
    </StyledHorizontalStack>
  );
}
