import _ from 'lodash';
import { humanize } from '@src-v2/utils/string-utils';
import filtersService from '@src/services/filtersService';

const humanizeMisconfigurationTypes = types =>
  types.map(type => ({ name: type, displayName: humanize(type) }));
const moduleCategories = module =>
  _.uniq(module.resourcesSummary.map(summary => summary.iacCategory));
const moduleMisconfigurationTypes = module =>
  _.uniq(
    module.resourcesSummary.flatMap(summary =>
      summary.misconfigurations.flatMap(misconfiguration => misconfiguration.checkCategories)
    )
  );

const getTerraformFilterOptions = ({ categories, misconfigurationTypes }) => [
  {
    name: 'IacCategory',
    sortOrder: 1,
    displayName: 'Category',
    filterOptions: filtersService.getListFilterOptions(categories),
  },
  {
    name: 'MisconfigurationType',
    displayName: 'Misconfiguration Type',
    filterOptions: humanizeMisconfigurationTypes(misconfigurationTypes),
  },
];

export const getTerraformModulesHighlightsFilterOptions = ({ elements }) => {
  const categories = _.uniq(elements.flatMap(moduleCategories));
  const misconfigurationTypes = _.uniq(elements.flatMap(moduleMisconfigurationTypes));

  return getTerraformFilterOptions({ categories, misconfigurationTypes });
};

export const getTerraformResourcesFilterOptions = ({ elements }) => {
  const categories = _.uniq(elements.map(element => element.iacCategory));
  const misconfigurationTypes = _.uniq(elements.flatMap(element => element.checkCategories));

  return getTerraformFilterOptions({ categories, misconfigurationTypes });
};

export const filterTerraformModulesHighlights = (allItems, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, item => [
    item.modulePath,
  ]);

  const categoryFilter = filters.listFilters?.IacCategory;
  if (!_.isEmpty(categoryFilter)) {
    filtered = filtered.filter(module =>
      filterOperator(categoryFilter.map(category => moduleCategories(module).includes(category)))
    );
  }

  const misconfigurationTypesFilter = filters.listFilters?.MisconfigurationType;
  if (!_.isEmpty(misconfigurationTypesFilter)) {
    filtered = filtered.filter(module =>
      filterOperator(
        misconfigurationTypesFilter.map(type => moduleMisconfigurationTypes(module).includes(type))
      )
    );
  }

  return filtered;
};

export const filterTerraformResources = (allItems, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, _ => [_.modulePath]);
  const categoryFilter = filters.listFilters?.IacCategory;
  if (!_.isEmpty(categoryFilter)) {
    filtered = filtered.filter(element =>
      filterOperator(categoryFilter.map(category => element.iacCategory === category))
    );
  }

  const misconfigurationTypesFilter = filters.listFilters?.MisconfigurationType;
  if (!_.isEmpty(misconfigurationTypesFilter)) {
    filtered = filtered.filter(element =>
      filterOperator(
        misconfigurationTypesFilter.map(type => element.checkCategories.includes(type))
      )
    );
  }

  return filtered;
};
