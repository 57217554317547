import { observer } from 'mobx-react';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { ErrorLayout } from '@src-v2/components/layout';
import { Paragraph } from '@src-v2/components/typography';
import { filterTokensList } from '@src-v2/containers/access-tokens/access-tokens-display-panel';
import { AccessTokensListItem } from '@src-v2/containers/access-tokens/access-tokens-list-item';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject, useSuspense } from '@src-v2/hooks';
import { useFilters } from '@src-v2/hooks/use-filters';
import { useModalState } from '@src-v2/hooks/use-modal-state';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';

export const AccessTokensList = observer(() => {
  const { accessTokens, rbac, application } = useInject();
  const data = useSuspense(accessTokens.listAccessTokens);
  const [modalElement, setModal, closeModal] = useModalState();
  const { activeFilters } = useFilters();

  const canEditToken =
    rbac.canEdit(resourceTypes.Global) || application.isFeatureEnabled(FeatureFlag.AccessTokenForm);

  useEffect(() => accessTokens.clearJwt, []);

  const filteredAccessTokensList = filterTokensList(data, activeFilters);

  const handleDelete = useCallback(
    token =>
      setModal(
        <ConfirmationModal
          title={`Delete ${token.name}?`}
          submitText="Delete"
          submitStatus="failure"
          onSubmit={() => accessTokens.deleteAccessToken(token).then(closeModal)}
          onClose={closeModal}>
          <Paragraph>Are you sure you want to delete this token?</Paragraph>
          <Paragraph>Once a token is deleted it can’t be retrieved.</Paragraph>
        </ConfirmationModal>
      ),
    []
  );

  return data.length ? (
    <Container>
      {filteredAccessTokensList.length > 0 ? (
        filteredAccessTokensList.map(token => (
          <AccessTokensListItem key={token.key} accessToken={token} onDelete={handleDelete} />
        ))
      ) : (
        <ErrorLayout.NoResults />
      )}

      {modalElement}
    </Container>
  ) : (
    <EmptyListContainer>
      <Paragraph>No tokens created yet. Create your first token.</Paragraph>
      <Button
        type="link"
        size="large"
        to="/settings/access-permissions/tokens/create"
        disabled={!canEditToken}>
        Create token
      </Button>
    </EmptyListContainer>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6rem;
`;

const EmptyListContainer = styled.div`
  display: flex;
  height: 75rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 5rem;
  border-radius: 3rem;
  box-shadow: var(--elevation-1);
  background-color: var(--color-blue-gray-15);
`;
