import { action, computed, makeObservable, observable } from 'mobx';
import { modify } from '@src-v2/utils/mobx-utils';

export class Session {
  static authVerifyEndpoint = 'authentication/verify';

  static authTypes = {
    default: 'Password',
    clientSide: ['Ldap', 'Password'],
    requireUsername: ['Ldap'],
    gateway: ['SelfServiceGateway', 'SelfServiceRbacGateway'],
  };

  #client;

  connectivity = true;
  connected = false;
  authType;
  data;

  constructor({ apiClient }) {
    this.#client = apiClient;

    makeObservable(this, {
      connectivity: observable,
      connected: observable,
      authType: observable,
      data: observable,
      serverAuthentication: computed,
      unauthorizedEmptyScope: computed,
      gatewayAuthentication: computed,
      fetchSessionData: action,
    });
  }

  get username() {
    return this.data?.consoleUser.name;
  }

  get permissions() {
    return this.data?.consoleUser.permissions;
  }

  get roles() {
    return this.data?.consoleUser.roles;
  }

  get unauthorizedEmptyScope() {
    return this.data?.consoleUser.unauthorizedEmptyScope;
  }

  get serverAuthentication() {
    return !this.constructor.authTypes.clientSide.includes(this.authType);
  }

  get gatewayAuthentication() {
    return this.constructor.authTypes.gateway.includes(this.authType);
  }

  get requireUsername() {
    return this.constructor.authTypes.requireUsername.includes(this.authType);
  }

  static handleRedirect(error) {
    if (error.response.headers.location) {
      window.location = error.response.headers.location;
      return new Promise(() => {}); // don't resolve if redirecting
    }
    throw error;
  }

  setAuthType(authType) {
    modify(this, 'authType', authType);
  }

  setConnectivityStatus(value) {
    modify(this, 'connectivity', value);
    if (!value) {
      setTimeout(() => this.verifyConnection(), 5000);
    }
  }

  async fetchSessionData() {
    const sessionData = await this.#client.get('session');

    if (sessionData?.provisionedByAccountService) {
      try {
        const [{ user: userAccount }, environments] = await Promise.all([
          this.#client.get('/session', { baseURL: '/accounts/api' }),
          this.#client.get('/environments', { baseURL: '/accounts/api' }),
        ]);
        sessionData.userAccount = userAccount;
        sessionData.environments = environments;
      } catch (error) {
        console.error('Failed to fetch account-service information', error);
      }
    }

    modify(this, 'data', sessionData);
  }

  async verifyConnection() {
    try {
      await this.#client.get(Session.authVerifyEndpoint);

      if (!this.connected) {
        await this.fetchSessionData();
        modify(this, 'connected', true);
      }

      this.setConnectivityStatus(true);
    } catch (error) {
      console.error(error);
      if (error.response?.status === 401) {
        this.setConnectivityStatus(true);
        modify(this, { connected: false, data: null });
      } else {
        this.setConnectivityStatus((error.response?.status ?? -1) < 500);
      }
    }
  }

  async login({ username, password, returnUrl }) {
    await this.#client
      .post('authentication/signin', { username, password }, { params: { returnUrl } })
      .catch(this.constructor.handleRedirect);
    await this.fetchSessionData();
    modify(this, 'connected', true);
  }

  async logout() {
    await this.#client.post('authentication/signout').catch(this.constructor.handleRedirect);
    modify(this, { connected: false, data: null });
  }
}
