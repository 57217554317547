import { SendMessageModal } from '@src-v2/containers/modals/messaging/send-message-modal';
import { Messaging } from '@src-v2/services';

export const SlackMessageModal = props => (
  <SendMessageModal
    {...props}
    allowCustomChannel
    messageType={Messaging.TYPE_SLACK}
    previewTags={['APP']}
  />
);

export function GoogleChatMessageModal(props) {
  return <SendMessageModal {...props} messageType={Messaging.TYPE_GOOGLE_CHAT} />;
}

export function TeamsMessageModal(props) {
  return <SendMessageModal {...props} messageType={Messaging.TYPE_TEAMS} />;
}
