import axios from 'axios';
import { setupCache } from 'axios-cache-interceptor';
import { format } from 'date-fns';
import objGet from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import omitBy from 'lodash/omitBy';
import objSet from 'lodash/set';
import { dateFormats } from '@src-v2/data/datetime';
import ioc from '@src-v2/ioc';
import { qs } from '@src-v2/utils/history-utils';
import { dispatch } from '@src/store';

const apiService = setupCache(
  axios.create({
    paramsSerializer: qs.stringify,
  }),
  {
    ttl: 15 * 60 * 1000,
    update: async ({ config, id }) => {
      if (config.clearCacheEntry) {
        await apiService.storage.remove(id);
      }
    },
  }
);

const networkError = error => error?.message.startsWith('Network Error');

const unauthorizedStatus = 401;
const forbiddenStatus = 403;
const proxyErrorStatuses = [502, 503, 504];
const authRelatedPaths = ['authentication', 'session'];

apiService.interceptors.response.use(
  response => response,
  async error => {
    const isGatewayEnv = error.response?.headers['x-apiiro-source'] === 'gateway';

    if (error.response?.status === forbiddenStatus) {
      window.location.href = '/unauthorized';
    } else if (
      isGatewayEnv &&
      (error.response?.status === unauthorizedStatus ||
        proxyErrorStatuses.includes(error.response?.status))
    ) {
      window.location.reload();
      return;
    } else if (error.response?.status === unauthorizedStatus) {
      const requestUrl = error.request?.responseURL || '';
      if (!authRelatedPaths.some(path => requestUrl.includes(path))) {
        const redirectLocation = objGet(error, 'response.headers.location');
        await ioc.session.verifyConnection();
        if (!ioc.session.connected) {
          if (redirectLocation) {
            window.location.href = redirectLocation;
          } else if (!document.cookie.includes('refreshed')) {
            document.cookie = `refreshed=true; expires=${new Date(
              new Date().getTime() + 10 * 60 * 1000
            ).toUTCString()}`;
            window.location.reload();
          }
        }
      }
    } else if (networkError(error)) {
      ioc.session.setConnectivityStatus(false);
    }
    return Promise.reject(error);
  }
);

const inflightGetPromises = new Map();
const nonDedupedGet = apiService.get;

apiService.get = (url, config) => {
  const key = url + JSON.stringify(config);
  if (!inflightGetPromises.get(key)) {
    inflightGetPromises.set(
      key,
      new Promise((resolve, reject) =>
        nonDedupedGet(url, config)
          .then(response => resolve(response))
          .catch(error => {
            dispatch.toast.error(error);
            return reject(error);
          })
          .then(() => setTimeout(() => inflightGetPromises.delete(key)))
      )
    );
  }

  return inflightGetPromises.get(key);
};

const pageSize = 20;
apiService.pageSize = pageSize;

const lastParamsForUrl = {};
apiService.getAndAbortIfOutdated = async (url, config) => {
  const params = JSON.stringify(objGet(config, 'params'));
  objSet(lastParamsForUrl, url, params);
  const { data: results } = await apiService.get(url, config);
  return objGet(lastParamsForUrl, url) === params ? results : null;
};

export const normalizeFiltersParam = filters =>
  mapValues(omitBy(filters, isEmpty), filterValues => [].concat(filterValues));

apiService.getTimelineFilterOptions = ({ table, subEntityKey }) =>
  apiService.getAndAbortIfOutdated(
    isNil(subEntityKey)
      ? `/api/${table}/timelineEvents/filterOptions`
      : `/api/${table}/timelineEvents/filterOptions/${subEntityKey}`,
    { clearCacheEntry: true }
  );

apiService.getFilterOptions = ({ table }) =>
  apiService.getAndAbortIfOutdated(`/api/${table}/filterOptions`, { clearCacheEntry: true });

apiService.getCustomFilters = ({ table }) =>
  apiService.getAndAbortIfOutdated(`/api/${table}/customFilters`, { clearCacheEntry: true });

apiService.createCustomFilter = ({ table, customFilter }) =>
  apiService.post(`/api/${table}/customFilters`, customFilter);

apiService.deleteCustomFilter = ({ table, customFilterKey }) =>
  apiService.delete(`/api/${table}/customFilters/${customFilterKey}`);

apiService.getSortOptions = ({ table }) =>
  apiService.getAndAbortIfOutdated(`/api/${table}/sortOptions`, { clearCacheEntry: true });

apiService.searchTimeline = ({
  table,
  entityKey,
  subEntityKey,
  currentPage,
  filters,
  fromDate,
  toDate,
}) => {
  const url = isNil(subEntityKey)
    ? `/api/${table}/${entityKey}/timelineEvents`
    : `/api/${table}/${entityKey}/timelineEvents/${subEntityKey}`;
  const tableFilters = { anyLabel: filters };

  if (fromDate && toDate) {
    const toDateNextDay = new Date(toDate);
    toDateNextDay.setDate(toDateNextDay.getDate() + 1);
    tableFilters.dateRange = [
      format(fromDate, dateFormats.serverDate),
      format(toDateNextDay, dateFormats.serverDate),
    ];
  }

  return apiService.getAndAbortIfOutdated(url, {
    params: {
      pageSize,
      skip: currentPage * pageSize,
      tableFilterToQuery: normalizeFiltersParam(tableFilters),
    },
  });
};

apiService.searchTable = ({
  tableScope,
  currentPage,
  searchTerm,
  filters = null,
  filterType,
  sort = '',
  ...searchParams
}) =>
  apiService.getAndAbortIfOutdated(`/api/${tableScope}/search`, {
    params: {
      searchTerm,
      pageSize,
      skip: currentPage * pageSize,
      tableFilterOperator: filterType,
      tableFilterToQuery: normalizeFiltersParam(filters),
      sortOption: sort,
      ...searchParams,
    },
  });

apiService.getMonitoredRepositoriesSize = () =>
  apiService.getAndAbortIfOutdated('/api/repositories/monitoredCountAndSize', {
    clearCacheEntry: true,
  });

export default apiService;
