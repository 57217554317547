import _ from 'lodash';
import apiService from '@src/services/apiService';

const clearData = () => ({
  entityProfile: null,
  hasUnprocessedCommits: true,
});

export default {
  state: clearData(),
  selectors: slice => ({
    entityProfile: () => slice(({ entityProfile }) => entityProfile),
    hasUnprocessedCommits: () => slice(({ hasUnprocessedCommits }) => hasUnprocessedCommits),
  }),
  reducers: {
    clearData,
    setBulkState: (state, { entityProfile, hasUnprocessedCommits }) => ({
      ...state,
      entityProfile,
      hasUnprocessedCommits,
    }),
  },
  effects: dispatch => ({
    async fetchData({ repositoryKey, entityType, entityId }) {
      let entityProfile;
      let hasUnprocessedCommits = true;
      if (!_.isEmpty(repositoryKey)) {
        entityProfile = await dispatch.entityProfiles.getEntityProfileAsync({
          repositoryKey,
          entityType,
          entityId,
        });

        if (!_.isEmpty(entityProfile)) {
          if (_.isEmpty(entityProfile.events)) {
            hasUnprocessedCommits = (
              await apiService.get(`/api/repositories/${repositoryKey}/hasUnprocessedCommits`)
            ).data;
          }

          const developerIdentityKeys = entityProfile.events.map(
            event => event.developerIdentityKey
          );

          await dispatch.developerProfiles.getDevelopersByIdentitiesKeysAsync({
            keys: developerIdentityKeys,
          });
        }
      }

      this.setBulkState({ entityProfile, hasUnprocessedCommits });
    },
  }),
};
