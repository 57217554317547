import { Circle } from '@src-v2/components/circles';
import { VendorIcon } from '@src-v2/components/icons';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { Link } from './Link';
import { VerticalStack } from './VerticalStack';

const VulnerabilityProviderLink = ({ dependencyFinding, isFloating, provider }) => {
  const urlsToProjectsMap = dependencyFinding.urlsToProjectsMap
    ? JSON.parse(dependencyFinding.urlsToProjectsMap)
    : {};
  const urlCount = Object.keys(urlsToProjectsMap).length;
  const icon = <VendorIcon name={provider} />;
  return urlCount > 1 ? (
    <PopoverTip
      linkText={
        <Circle
          style={
            isFloating ?? {
              boxShadow: '0 2px 24px 0 rgba(186, 202, 213, 0.3)',
              backgroundColor: 'var(--color-white)',
              '&:hover': {
                backgroundColor: 'var(--color-blue-gray-30)',
              },
            }
          }>
          {icon}
        </Circle>
      }
      title={`View issue in ${provider}`}>
      <VerticalStack>
        {Object.entries(urlsToProjectsMap).map(([url, project]) => (
          <Link underline key={url} openInNewTab url={url}>
            {project}
          </Link>
        ))}
      </VerticalStack>
    </PopoverTip>
  ) : (
    <Link external openInNewTab url={dependencyFinding.url} tip={`View issue in ${provider}`}>
      <Circle
        style={
          isFloating ?? {
            boxShadow: '0 2px 24px 0 rgba(186, 202, 213, 0.3)',
            backgroundColor: 'var(--color-white)',
            '&:hover': {
              backgroundColor: 'var(--color-blue-gray-30)',
            },
          }
        }>
        {icon}
      </Circle>
    </Link>
  );
};

export default VulnerabilityProviderLink;
