import { connect } from 'react-redux';
import { select } from '@src/store';
import ClientTableInfiniteScroll from './view';

const mapState = select(
  ({
    clientTableInfiniteScroll: { items, itemsInPages, itemsCount, currentTableScope },
    tableSearch: { filters, filterType, filterOptions, searchTerm },
  }) => ({
    items,
    itemsInPages,
    itemsCount,
    filters,
    filterType,
    filterOptions,
    searchTerm,
    currentTableScope,
  })
);

const mapDispatch = ({
  clientTableInfiniteScroll: { fetchPage, fetchData, clearData },
  tableSearch: { initializeFromQueryString },
}) => ({
  fetchPage,
  fetchData,
  clearData,
  initializeFromQueryString,
});

export default connect(mapState, mapDispatch)(ClientTableInfiniteScroll);
