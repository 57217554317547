import { Heading, Paragraph, Strong } from '@src-v2/components/typography';
import { CommitCodeReference } from '@src-v2/containers/commit/common-componnets';
import { thenSubTypeToVerb } from '@src-v2/containers/commit/material-changes-utils';

export const ProtobufMessageMaterialChange = ({
  commitSha,
  repository,
  contentSummary,
  codeReference,
  thenSubType,
}) => (
  <>
    <Heading>
      <Paragraph>Protobuf message scheme </Paragraph>
      <CommitCodeReference
        repository={repository}
        commitSha={commitSha}
        relativeFilePath={codeReference.relativeFilePath}>
        <Strong>{codeReference.name}</Strong>
      </CommitCodeReference>
      <Paragraph>was</Paragraph>
      <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>
    </Heading>
    {contentSummary.map(({ name, type }) => (
      <Paragraph key={name}>
        {name}
        {type && <Strong>: {type}</Strong>}
      </Paragraph>
    ))}
  </>
);
