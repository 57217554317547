import styled from 'styled-components';
import { ClampText } from '@src-v2/components/clamp-text';

export const DetailsChips = styled(({ title, children, ...props }) => (
  <div {...props}>
    <DetailsChips.Title>{title}</DetailsChips.Title>
    {children}
  </div>
))`
  display: flex;
  height: 6rem;
  gap: 2rem;
  padding: 0.5rem 1.5rem 0.5rem 2rem;
  align-items: center;
  border-radius: 100vmax;
  background-color: var(--color-white);
`;

DetailsChips.Title = styled(ClampText)`
  flex: 100 1 auto;
  padding: 2px 0;
`;
