import { FC } from 'react';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { CollapsibleCardsController } from '@src-v2/components/cards/collapsbile-cards-controller';
import { ControlledCardProps } from '@src-v2/components/cards/controlled-card';
import { Pane, PaneProps } from '@src-v2/components/panes/pane';
import { PaneBody } from '@src-v2/components/panes/pane-body';
import { PaneStickyHeader } from '@src-v2/components/panes/pane-sticky-header';
import { CveEntityContextProvider } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-context-provider';
import { CveEntityHeader } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-header';
import { CveEntityPaneContent } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-pane-content';
import { useQueryParams } from '@src-v2/hooks';
import { useDetectScrolling } from '@src-v2/hooks/dom-events/use-detect-scrolling';
import { DiffableEntityDataModelReference } from '@src-v2/types/data-model-reference/data-model-reference';

export function CveEntityPane({
  navigationTab = ['profile'],
  cve,
  dataModelReference,
  ...props
}: {
  cve: string;
  navigationTab?: string[];
  dataModelReference?: DiffableEntityDataModelReference;
} & PaneProps) {
  const [scrolled, onScroll] = useDetectScrolling();

  return (
    <Pane {...props}>
      <AsyncBoundary>
        <CveEntityContextProvider cve={cve} dataModelReference={dataModelReference}>
          <PaneStickyHeader scrolled={scrolled} navigation={navigationTab}>
            <CveEntityHeader />
          </PaneStickyHeader>
          <PaneBody onScroll={onScroll}>
            <AsyncBoundary>
              <TabsRouter>{props => <CveEntityPaneContent {...props} />}</TabsRouter>
            </AsyncBoundary>
          </PaneBody>
        </CveEntityContextProvider>
      </AsyncBoundary>
    </Pane>
  );
}

function TabsRouter({ children }: { children: FC<ControlledCardProps> }) {
  const {
    queryParams: { pane = 'profile' },
  } = useQueryParams();

  // eslint-disable-next-line default-case
  switch (pane) {
    case 'profile':
      return <CollapsibleCardsController>{props => children(props)}</CollapsibleCardsController>;
  }
}
