import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import styled from 'styled-components';
import { FontBodySmall } from '@src/style/common';
import { VerticalStack } from './VerticalStack';

const StyledGroupName = styled.span`
  ${FontBodySmall};
  opacity: 0.7;
  display: inline-flex;
`;
const GroupedList = ({ groupNameToValues, emptyMessage }) =>
  !isEmpty(groupNameToValues) ? (
    <VerticalStack spacing="30px">
      {sortBy(Object.keys(groupNameToValues)).map(type => (
        <VerticalStack key={type} spacing="6px">
          <StyledGroupName>{type}</StyledGroupName>
          {groupNameToValues[type]
            .filter(value => value !== type)
            .map(value => (
              <span key={value + type}>{value}</span>
            ))}
        </VerticalStack>
      ))}
    </VerticalStack>
  ) : (
    emptyMessage ?? ''
  );

export default GroupedList;
