import compact from 'lodash/compact';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import uniq from 'lodash/uniq';
import filtersService from '@src/services/filtersService';

export const getSensitiveDataFilterOptions = ({ elements }) => {
  const filters = [
    {
      name: 'Insights',
      displayName: 'Insights',
      filterOptions: [
        {
          name: 'ExposedByApi',
          displayName: 'Exposed by API',
        },
        {
          name: 'InvolvedInApi',
          displayName: 'Involved in API',
        },
        {
          name: 'WrittenToLogs',
          displayName: 'Written to logs',
        },
      ],
    },
  ];

  const types = uniq(elements.flatMap(entity => entity.sensitiveDataDefinedTypes));
  filters.push({
    name: 'SensitiveDataTypes',
    displayName: 'Data Type',
    filterOptions: filtersService.getListFilterOptions(types, type => type),
  });

  const codeReferenceName = uniq(elements.flatMap(entity => entity.codeReference.name));
  filters.push({
    name: 'SensitiveDataPath',
    displayName: 'Source Name',
    filterOptions: filtersService.getListFilterOptions(codeReferenceName, name => name),
  });

  return filters;
};

export const filterSensitiveDateToCondition = {
  ExposedByApi: entity => entity.exposedByApi,
  InvolvedInApi: entity => entity.involvedInApi,
  WrittenToLogs: entity => entity.writtenToLogs,
};

export const filterSensitiveData = (allItems, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, _ => [_.name]);

  const filterConditions = compact(
    filters.booleanFilters.map(filter => get(filterSensitiveDateToCondition, filter))
  );
  if (!isEmpty(filterConditions)) {
    filtered = filtered.filter(dataOdInterest =>
      filterOperator(filterConditions, condition => condition(dataOdInterest))
    );
  }

  const filterTypes = get(filters.listFilters, 'SensitiveDataTypes', []);
  if (!isEmpty(filterTypes)) {
    filtered = filtered.filter(entity =>
      filterOperator(map(filterTypes, type => entity.sensitiveDataDefinedTypes.includes(type)))
    );
  }

  const filterPath = get(filters.listFilters, 'SensitiveDataPath', []);
  if (!isEmpty(filterPath)) {
    filtered = filtered.filter(entity =>
      filterOperator(map(filterPath, name => entity.codeReference.name === name))
    );
  }

  return filtered;
};
