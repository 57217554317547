import { TimelineEvent } from './TimelineEvent';

const getConsumables = (serverTimelineEvent, providerRepositoriesByKey, projectProfilesByKey) => {
  switch (serverTimelineEvent.consumableEntityType) {
    case 'Repository':
      return providerRepositoriesByKey[serverTimelineEvent.consumableEntityKey];
    case 'Project':
      return projectProfilesByKey[serverTimelineEvent.consumableEntityKey];
    default:
      console.error(
        `Unsupported consumable type "${serverTimelineEvent.consumableEntityType}" for server timeline event`
      );
  }
};

export const TimelineServer = ({
  isLast,
  isFirst,
  index,
  serverTimelineEvent,
  previousTimelineEvent,
  providerRepositoriesByKey,
  projectProfilesByKey,
}) => {
  const consumable = getConsumables(
    serverTimelineEvent,
    providerRepositoriesByKey,
    projectProfilesByKey
  );

  return (
    <TimelineEvent
      currentTimelineEvent={serverTimelineEvent}
      previousTimelineEvent={previousTimelineEvent}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      titleContent={`${serverTimelineEvent.consumableEntityType} ${consumable.name} created`}
      testMarker="timeline-server"
    />
  );
};
