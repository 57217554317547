import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Dropdown } from '@src-v2/components/dropdown';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { ProcessTag } from '@src-v2/components/tags';
import { Heading, Paragraph } from '@src-v2/components/typography';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject, useSuspense } from '@src-v2/hooks';
import { formatNumber, pluralFormat } from '@src-v2/utils/number-utils';

export const ProcessTagsList = observer(() => {
  const { processes, rbac } = useInject();
  const processTagsList = useSuspense(processes.listProcessTags);

  return (
    <Container>
      {processTagsList?.map(tag => (
        <TagListItem key={tag.key}>
          <TagSection>
            <TagHeader>
              <Heading>{tag.name}</Heading>
              <ProcessTag>{tag.processName}</ProcessTag>
            </TagHeader>

            <Paragraph>
              {tag.rulesCount
                ? `${formatNumber(tag.rulesCount)} ${pluralFormat(
                    tag.rulesCount,
                    'rule',
                    'rules'
                  )}  associated with this process`
                : 'No rules associated with this process'}
            </Paragraph>
          </TagSection>
          {rbac.canEdit(resourceTypes.Global) && (
            <TagSection>
              <DropdownMenu>
                <Link to={`/settings/access-permissions/process-tags/${tag.key}/edit`}>
                  <Dropdown.Item disabled>Edit</Dropdown.Item>
                </Link>
                <Link to={`/settings/access-permissions/process-tags/${tag.key}/delete`}>
                  <Dropdown.Item>Delete</Dropdown.Item>
                </Link>
              </DropdownMenu>
            </TagSection>
          )}
        </TagListItem>
      ))}
    </Container>
  );
});

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;

const TagListItem = styled.div`
  display: flex;
  padding: 6rem;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 1rem;
  box-shadow: 0 0.5rem 2.5rem 0 var(--color-blue-gray-25);
`;

const TagSection = styled.div``;

const TagHeader = styled.div`
  display: flex;
  margin-bottom: 3rem;
  flex-direction: row;
  align-items: center;
  gap: 4rem;

  ${Heading} {
    margin-bottom: 0;
    font-size: 6rem;
  }
`;
