import { connect } from 'react-redux';
import { select } from '@src/store';
import { MultiSelectFilter } from './view';

const mapState = select(({ multiSelectFilter: { dropdownOpen, searchTerm } }) => ({
  dropdownOpen,
  searchTerm,
}));

const mapDispatch = ({ multiSelectFilter: { setDropdownOpen, setSearchTerm } }) => ({
  setDropdownOpen,
  setSearchTerm,
});

export default connect(mapState, mapDispatch)(MultiSelectFilter);
