import styled from 'styled-components';
import { SvgIcon } from '@src-v2/components/icons';
import { FontBody } from '@src/style/common';
import { HorizontalStack } from './HorizontalStack';

const StyledSelectPlaceholder = styled.div`
  ${FontBody};
  border: 1px dashed var(--color-green-45);
  background-color: var(--color-green-20);
  display: inline-block;
  padding: 6px;
  padding-right: 3rem;
  padding-left: ${props => (props.padLeft ? '3rem' : '3px')};
  border-radius: 1rem;
  cursor: pointer;
  line-height: 1;
`;

const StyledContent = styled(HorizontalStack)`
  & > * {
    align-self: center;
  }
`;

const StyledAddIcon = styled(props => <SvgIcon {...props} name="Plus" />)`
  color: var(--color-green-45);
`;

export const AddElementPlaceholder = ({ text, showAddIcon = true, ...props }) => (
  <StyledSelectPlaceholder padLeft={!showAddIcon} {...props}>
    <StyledContent>
      {showAddIcon && <StyledAddIcon />} <span>{text}</span>
    </StyledContent>
  </StyledSelectPlaceholder>
);
