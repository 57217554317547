import { forwardRef, useEffect, useMemo, useState } from 'react';
import { Button } from '@src-v2/components/button-v2';
import { Dropdown } from '@src-v2/components/dropdown';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { TippyAttributes } from '@src-v2/components/tooltips/tippy';
import { useGroupProperties } from '@src-v2/hooks';
import { humanize } from '@src-v2/utils/string-utils';
import { primaryPaneLevel } from '@src/blocks/Pane/model';
import { dispatch } from '@src/store';

export const GROUP_TYPES = {
  takeAction: 'takeAction',
  default: 'default',
};

export const ActionsMenu = forwardRef(
  (
    {
      label,
      groupType,
      disabled,
      actions,
      defaultPaneOpenOptions,
      onOpenJiraTicketClick,
      ...props
    },
    ref
  ) => {
    const [popoverProps, actionsProps] = useGroupProperties(props, TippyAttributes);

    const visibleActions = useMemo(
      () => actions.filter(action => action.shouldShow(actionsProps)),
      [actions]
    );

    const [asyncVisibleActions, setAsyncVisibleActions] = useState(visibleActions);

    useEffect(() => {
      let ok = true;
      setAsyncVisibleActions(visibleActions);
      Promise.all(
        visibleActions.map(async action => [
          !action.shouldShowAsync || (await action.shouldShowAsync(actionsProps)),
          action,
        ])
      ).then(
        filterResult =>
          ok &&
          setAsyncVisibleActions(
            filterResult
              .filter(resultAndAction => resultAndAction[0])
              .map(resultAndAction => resultAndAction[1])
          )
      );
      return () => {
        ok = false;
      };
    }, [visibleActions]);

    if (!asyncVisibleActions.length) {
      return null;
    }

    return (
      <DropdownMenu.Popover
        {...popoverProps}
        content={asyncVisibleActions.map((action, index) => (
          <Dropdown.Item
            key={index}
            action={action}
            onClick={() => {
              if (action.name === 'openJiraIssue' && onOpenJiraTicketClick) {
                onOpenJiraTicketClick();
                return;
              }

              if (action.linkTargetGetter) {
                window.open(action.linkTargetGetter(actionsProps), '_blank', 'noopener,noreferrer');
              } else {
                action.onExecute?.(actionsProps, {
                  openPane: (paneContent, paneOpenOptions) =>
                    dispatch.pane.openPane({
                      onClose() {},
                      innerPane: true,
                      level: primaryPaneLevel,
                      content: paneContent,
                      ...defaultPaneOpenOptions,
                      ...paneOpenOptions,
                    }),
                });
              }
            }}>
            {action.iconGetter(actionsProps)}
            {action.nameGetter(actionsProps)}
          </Dropdown.Item>
        ))}
        appendTo="parent"
        popperOptions={{ strategy: 'fixed' }}
        trigger="mouseenter focus"
        hideOnClick={false}
        noArrow>
        <Button
          ref={ref}
          disabled={disabled}
          variant={groupType === GROUP_TYPES.takeAction ? 'primary' : 'secondary'}>
          {humanize(label)}
        </Button>
      </DropdownMenu.Popover>
    );
  }
);
