// TODO should be moved to the backend (LIM-3738)

const languagesDisplayName = {
  CSharp: 'C#',
  CPlusPlus: 'C++',
  VisualBasic: 'Visual Basic',
  Html: 'HTML',
  ObjectiveC: 'Objective-C',
  Php: 'PHP',
  Hcl: 'Terraform',
};

const ignoredLanguages = ['CiCd', 'Hcl'];

export function getLanguageDisplayName(languageKey) {
  return languagesDisplayName[languageKey] ?? languageKey;
}

export function isIgnoredLanguage(languageKey) {
  return ignoredLanguages.includes(languageKey);
}
