import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import ReactSelectWrapper from '@src/components/ReactSelect/ReactSelectWrapper';

const convertModuleOption = module => ({ value: module.root, label: module.name });

const ModuleInput = ({
  propertySettings,
  selectedValue,
  property,
  disabled,
  setProperty,
  index,
}) => {
  const modules = selectedValue[propertySettings.optionsKey];
  if (isEmpty(selectedValue.modules) || isEmpty(modules)) {
    return 'No modules have been identified';
  }
  const currentValues = map(property.values, propertyValue =>
    convertModuleOption(find(modules, ['root', propertyValue]) || modules[0])
  );
  return (
    <ReactSelectWrapper
      key={index}
      isMulti={propertySettings.multi}
      isDisabled={disabled}
      value={currentValues}
      options={modules.map(module => convertModuleOption(module))}
      onChange={newSelection =>
        newSelection &&
        setProperty({
          propertyIndex: index,
          type: property.type,
          values: isArray(newSelection) ? map(newSelection, _ => _.value) : [newSelection.value],
        })
      }
    />
  );
};

export default ModuleInput;
