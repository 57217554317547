import { Link } from 'react-router-dom';
import { RiskCard } from '@src-v2/components/risk/risk-card';
import { HighlightsBreakdown } from '@src-v2/containers/overview/highlights-breakdown';
import { HighlightsContent } from '@src-v2/containers/overview/highlights-content';
import { abbreviate } from '@src-v2/utils/number-utils';

export function HighlightsCard({
  title,
  elementType,
  callToAction,
  attackSurface,
  trendPercent,
  breakdown,
  children,
  ...props
}) {
  const { totalRisk, total } = attackSurface;
  return (
    <RiskCard as={props.to ? Link : 'div'} title={title} risk={attackSurface} {...props}>
      {children || (
        <HighlightsContent
          title={`${abbreviate(totalRisk)} / ${abbreviate(total)} ${elementType ?? title}`}
          riskPercent={totalRisk / total}
          changePercent={trendPercent}
          callToAction={callToAction}>
          <HighlightsBreakdown data={breakdown} />
        </HighlightsContent>
      )}
    </RiskCard>
  );
}
