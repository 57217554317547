import _ from 'lodash';
import { extractBy } from '@src-v2/utils/collection-utils';

/**
 * Takes an array of arrays and return their cartesian product
 * @example cartesianProduct([1, 2, [3, 4]]) => [[1,2,3], [1,2,4]]
 */
export const cartesianProduct = a => a.reduce((a, b) => a.flatMap(d => b.map(e => [d, e].flat())));

export const csvMapper = (items, columnMap) => {
  switch (typeof columnMap) {
    case 'function':
      const firstResult = columnMap(items[0]);
      const columnNames = items.length
        ? Object.keys(_.isArray(firstResult) ? firstResult[0] : firstResult)
        : [];
      return [
        columnNames,
        ...csvMapRows(
          items,
          item => {
            const rows = [].concat(columnMap(item));
            return rows.map(rowValues => columnNames.map(key => rowValues[key]));
          },
          true
        ),
      ];

    case 'object':
      const itemPaths = Object.values(columnMap);
      return [
        Object.keys(columnMap),
        ...csvMapRows(items, item => itemPaths.map(path => extractBy(item, path) ?? '')),
      ];

    default:
      throw Error(`csvMapper: type "${typeof columnMap}" of columnMap not supported`);
  }
};

export const csvEscapeCell = cell => `"${String(cell).replace(/"/g, '""')}"`;

export const csvFromArray = csvArray =>
  csvArray.map(line => line.map(csvEscapeCell).join(',')).join('\n');

function csvMapRows(items, valuesExtractor, multirow = false) {
  const toArray = val => {
    const values = [].concat(val);
    // must not return an empty array otherwise
    // cartesianProduct will remove the entire line
    return values.length ? values : [''];
  };

  const mapRow = rowValues => {
    const columns = rowValues.map(toArray);
    return columns.length > 1 ? cartesianProduct(columns) : columns;
  };

  return multirow
    ? items.flatMap(item => valuesExtractor(item).flatMap(mapRow))
    : items.flatMap(item => mapRow(valuesExtractor(item)));
}

export const columnsFromFilterConditions = (filterConditions, item) =>
  _.mapKeys(
    _.mapValues(filterConditions, condition =>
      typeof item !== 'undefined' ? Boolean(condition(item)) : item => Boolean(condition(item))
    ),
    (val, key) => _.startCase(key)
  );
