export class ForeignEntities {
  #client;
  #asyncCache;

  constructor({ apiClient, asyncCache }) {
    this.#client = apiClient;
    this.#asyncCache = asyncCache;
  }

  getForeignEntitiesByLinkTarget({ linkTarget, providerType }) {
    return this.#client.get('foreign-entities/by-link', { params: { linkTarget, providerType } });
  }

  getLinkableForeignEntitiesByLinkTarget({ linkTarget }) {
    return this.#client.get('foreign-entities/linkable', { params: { linkTarget } });
  }

  getLinkableProviders({ linkTarget }) {
    return this.#client.get('foreign-entities/linkable-providers', { params: { linkTarget } });
  }

  async addLinkToMultipleForeignEntities({ entityKeys, linkTarget }) {
    const result = await this.#client.post('foreign-entities/link-to-multiple-entities', {
      entityKeys,
      linkTarget,
    });
    this.#asyncCache.invalidateAll(this.getForeignEntitiesByLinkTarget);
    return result;
  }

  async removeLink({ entityKeys, linkTarget }) {
    const result = await this.#client.post('foreign-entities/unlink-multiple-entities', {
      entityKeys,
      linkTarget,
    });
    this.#asyncCache.invalidateAll(this.getForeignEntitiesByLinkTarget);
    return result;
  }

  createRepositoryLinkTarget(repoKey) {
    return JSON.stringify({
      _type: 'DataModelReferenceRepositoryProfile',
      RepositoryKey: repoKey,
    });
  }

  createModuleLinkTarget(repoKey, moduleName, moduleKey) {
    return JSON.stringify({
      _type: 'DataModelReferenceModule',
      RepositoryKey: repoKey,
      ModuleName: moduleName,
      ModuleKey: moduleKey,
    });
  }
}
