import _ from 'lodash';
import {
  filterByDefaultOptions,
  getDefaultFilterOptions,
} from '@src/services/ElementsFilters/DefaultFilterOptions';
import {
  filterGqlOperations,
  filterGqlTypes,
} from '@src/services/ElementsFilters/GqlEntitiesFilter';
import {
  filterModules,
  getModuleFiltersOptions,
} from '@src/services/ElementsFilters/ModulesFilters';
import {
  filterSensitiveData,
  getSensitiveDataFilterOptions,
} from '@src/services/ElementsFilters/SensitiveDataFilters';
import { riskDisplay, riskOrder } from '@src/services/riskService';
import { filterApiToCondition, filterApis, getApiFiltersOptions } from './ApiFilters';
import { filterDataAccessObjects } from './DataAccessObjectFilters';
import { filterDataModels, getDataModelsFilterOptions } from './DataModelsFilters';
import {
  filterDependencies,
  filterDependenciesToCondition,
  getDependenciesFilterOptions,
} from './DependenciesFilter';
import { filterDeployKeys, getDeployKeysFilterOptions } from './DeployKeysFilters';
import { filterIssues, getIssuesFilterOptions } from './IssueFilters';
import { filterSecrets, getSecretsFilterOptions } from './SecretsFilters';
import {
  filterServerlessFunctions,
  getServerlessFunctionsFiltersOptions,
} from './ServerlessFunctionsFilters';
import {
  filterSnykVulnerabilities,
  filterSnykVulnerabilitiesToCondition,
  getSnykVulnerabilitiesFilterOptions,
} from './SnykVulnerabilitiesFilters';
import {
  filterStorageBucketInteractions,
  getStorageBucketInteractionsFilterOptions,
} from './StorageBucketInteractionsFilters';
import { filterTerraformFirewalls } from './TerraformFirewallsFilter';
import {
  filterTerraformModulesHighlights,
  filterTerraformResources,
  getTerraformModulesHighlightsFilterOptions,
  getTerraformResourcesFilterOptions,
} from './TerraformModulesHighlightsFilter';

const FILTER_OPTIONS_GETTER_BY_ELEMENT_TYPE = {
  Api: getApiFiltersOptions,
  Issue: getIssuesFilterOptions,
  DataModel: getDataModelsFilterOptions,
  Dependency: getDependenciesFilterOptions,
  Secret: getSecretsFilterOptions,
  Module: getModuleFiltersOptions,
  SnykVulnerability: getSnykVulnerabilitiesFilterOptions,
  TerraformModuleHighlights: getTerraformModulesHighlightsFilterOptions,
  TerraformResource: getTerraformResourcesFilterOptions,
  StorageBucketInteraction: getStorageBucketInteractionsFilterOptions,
  serverlessFunctions: getServerlessFunctionsFiltersOptions,
  SensitiveData: getSensitiveDataFilterOptions,
  DeployKey: getDeployKeysFilterOptions,
};

const FILTER_TO_CONDITION_GETTER_BY_ELEMENT_TYPE = {
  Api: filterApiToCondition,
  ApisGroup: filterApiToCondition,
  Dependency: filterDependenciesToCondition,
  SnykVulnerability: filterSnykVulnerabilitiesToCondition,
};

const FILTER_FUNCTION_GETTER_BY_ELEMENT_TYPE = {
  Api: filterApis,
  Issue: filterIssues,
  DataModel: filterDataModels,
  Dependency: filterDependencies,
  Secret: filterSecrets,
  DataAccessObject: filterDataAccessObjects,
  Module: filterModules,
  SnykVulnerability: filterSnykVulnerabilities,
  TerraformModuleHighlights: filterTerraformModulesHighlights,
  TerraformResource: filterTerraformResources,
  TerraformFirewall: filterTerraformFirewalls,
  GqlType: filterGqlTypes,
  GqlOperation: filterGqlOperations,
  StorageBucketInteraction: filterStorageBucketInteractions,
  serverlessFunctions: filterServerlessFunctions,
  SensitiveData: filterSensitiveData,
  DeployKey: filterDeployKeys,
};

const SEARCH_TERM_BY_ELEMENT_TYPE = {
  Api: 'route / path',
  Issue: 'issue title',
  ApiObject: 'path',
  DataModel: 'name',
  Dependency: 'name',
  Secret: 'path',
  DataAccessObject: 'name',
  Module: 'module path',
  SnykVulnerability: 'dependency',
  GqlType: 'name or member',
  GqlOperation: 'name',
  StorageBucketInteraction: 'method / name',
  SensitiveData: 'type / name',
  DeployKey: 'title / created by',
};

const elementsFilterProvider = {
  getFilterOptions: ({
    profile,
    elements,
    filterByRisk,
    elementsType,
    hasExternalConnectivity,
    integrations,
  }) => {
    const filterOptionsFunction = FILTER_OPTIONS_GETTER_BY_ELEMENT_TYPE[elementsType];

    if (!filterOptionsFunction) {
      return [];
    }

    const options = filterOptionsFunction({
      profile,
      elements,
      hasExternalConnectivity,
      integrations,
    });

    const defaultOptions = getDefaultFilterOptions({
      profile,
      elements,
      hasExternalConnectivity,
      integrations,
    });
    defaultOptions && options.push(...defaultOptions);

    if (filterByRisk) {
      const riskLevels = _.uniq(_.map(elements, 'riskLevel'));
      if (riskLevels.length > 1) {
        options.push({
          name: 'ElementsRiskLevel',
          sortOrder: 0,
          displayName: 'Risk',
          filterOptions: riskLevels.map(level => ({
            name: level,
            displayName: riskDisplay[level] ?? level,
            sortOrder: -riskOrder[level],
          })),
        });
      }
    }

    return options;
  },

  getFilterToCondition: elementType =>
    FILTER_TO_CONDITION_GETTER_BY_ELEMENT_TYPE[elementType] ?? {},

  getFilterFunction: (elementsType, filterByRisk) => {
    const elementFilterFunction =
      FILTER_FUNCTION_GETTER_BY_ELEMENT_TYPE[elementsType] ?? _.identity;

    const filterFunction = (allItems, searchTerm, filters, filterOperator) =>
      filterByDefaultOptions(
        elementFilterFunction(allItems, searchTerm, filters, filterOperator),
        searchTerm,
        filters,
        filterOperator
      );

    if (filterByRisk) {
      return (allItems, searchTerm, filters, filterOperator) => {
        let filtered = filterFunction(allItems, searchTerm, filters, filterOperator);

        const riskFilters = filters.listFilters?.ElementsRiskLevel;
        if (!_.isEmpty(riskFilters)) {
          filtered = filtered.filter(item =>
            filterOperator(riskFilters.map(riskLevel => _.includes(item.riskLevel, riskLevel)))
          );
        }

        return filtered;
      };
    }

    return filterFunction;
  },

  getSearchTerm: elementsType => `Search ${SEARCH_TERM_BY_ELEMENT_TYPE[elementsType] ?? ''}`,
};

export default elementsFilterProvider;
