import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { ProcessTag } from '@src-v2/components/tags';
import { Paragraph } from '@src-v2/components/typography';
import { useInject, useSuspense } from '@src-v2/hooks';

export const TagsTypeSelector = () => {
  const { processes } = useInject();
  const types = useSuspense(processes.listTagTypes);

  return (
    <>
      <Paragraph as="div">Process type:</Paragraph>
      <Controller
        name="type"
        render={({ field: { onChange, value, ...field } }) => (
          <TypesSelector>
            {Object.entries(types).map(([key, type]) => (
              <ProcessTag
                key={key}
                {...field}
                selected={value === key}
                onClick={() => onChange(key)}>
                {type}
              </ProcessTag>
            ))}
          </TypesSelector>
        )}
      />
    </>
  );
};

const TypesSelector = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  gap: 2rem;
`;
