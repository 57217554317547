import styled from 'styled-components';
import { Ellipsis } from '@src/style/common';
import { Tooltip } from './Tooltip';

const StyledTooltip = styled(Tooltip)`
  ${Ellipsis};
`;

const TooltippedEllipsis = ({ tip, text, className }) => (
  <StyledTooltip className={className} tip={tip ?? text}>
    {text}
  </StyledTooltip>
);

export default TooltippedEllipsis;
