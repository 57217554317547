import isNil from 'lodash/isNil';
import uniqBy from 'lodash/uniqBy';

export const getDeveloperProfilesByIdentities = (identityKeys, developerProfileByIdentityKey) =>
  uniqBy(
    (identityKeys ?? [])
      .filter(key => !isNil(developerProfileByIdentityKey[key]))
      .map(key => developerProfileByIdentityKey[key]),
    'key'
  );
