import _ from 'lodash';
import { createContext, useContext, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import { SvgIcon } from '@src-v2/components/icons';
import { Gutters, StickyHeader } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { RiskBadge } from '@src-v2/components/risk/risk-badge';
import { DistanceTime, TimeTooltip } from '@src-v2/components/time';
import { InfoTooltip } from '@src-v2/components/tooltips/icon-tooltips';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink } from '@src-v2/components/typography';
import { FiltersContextProvider } from '@src-v2/containers/commit/material-changes/material-changes-filters';
import { generateCommitReferenceUrl } from '@src-v2/data/connectors';
import { useInject, useQueryParams, useSuspense } from '@src-v2/hooks';
import { useBreadcrumbs } from '@src-v2/hooks/use-breadcrumbs';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { getFilters, getMaterialChangeLabelsData } from '../commit/material-changes-utils';

const FiltersContext = createContext(null);

export const useMaterialChangesFilters = () => {
  const { activeFilters, updateFilters } = useContext(FiltersContext);
  return { activeFilters, updateFilters };
};

const CommitPage = () => {
  const { commits, repositoryProfiles } = useInject();
  const { commitSha, repositoryKey } = useParams();
  const {
    queryParams: { module: moduleKey },
  } = useQueryParams();

  const [
    {
      commitData: { materialChangesRiskLevel, shortSha, timestamp },
      labelsToMaterialChangeKeys,
      isUpToDate,
      governanceRules,
      materialChangesByLabels,
    },
    repositoryProfile,
  ] = useSuspense([
    [commits.getMaterialChanges, { repositoryKey, commitSha, moduleKey }],
    [repositoryProfiles.getProfile, { key: repositoryKey }],
  ]);

  const [activeFilters, setSelectedFilters] = useState([]);

  const materialChangeLabels = getMaterialChangeLabelsData(labelsToMaterialChangeKeys);

  const filters = getFilters(labelsToMaterialChangeKeys, materialChangesByLabels, governanceRules);

  const materialChangesCount = useMemo(
    () => _.sumBy(materialChangeLabels, pair => pair.count),
    [materialChangeLabels]
  );
  const module = useMemo(
    () => moduleKey && repositoryProfile?.modules?.find(module => module.root === moduleKey),
    [moduleKey, repositoryProfile]
  );

  useBreadcrumbs({
    breadcrumbs: repositoryProfile
      ? [
          {
            label: repositoryProfile.name,
            to: `/profiles/repositories/${repositoryProfile.key}`,
          },
          module && {
            label: 'Modules',
            to: `/profiles/repositories/${repositoryProfile.key}/inventory/components/modules`,
          },
          module && {
            label: module.name,
            to: `/module/${repositoryProfile.key}/${module.root}`,
          },
        ].filter(Boolean)
      : [],
  });

  return (
    <Page>
      <StickyHeader
        title={
          <TitleContainer>
            {pluralFormat(materialChangesCount, 'Material Change')} in {shortSha}{' '}
            <RiskBadge.RiskLevel
              data-risk={materialChangesRiskLevel}
              riskLevel={materialChangesRiskLevel?.toLowerCase()}>
              {materialChangesRiskLevel}
            </RiskBadge.RiskLevel>
          </TitleContainer>
        }>
        {!isUpToDate && <InfoTooltip content="Calculation in progress" />}
        {repositoryProfile && (
          <Tooltip content={`View commit in ${repositoryProfile.provider}`}>
            <ExternalLink href={generateCommitReferenceUrl(repositoryProfile, shortSha)}>
              <SvgIcon name="Code" />
            </ExternalLink>
          </Tooltip>
        )}
        <HeaderDetails align="right">
          <TimeTooltip date={timestamp}>
            <DistanceTime date={timestamp} addSuffix strict />
          </TimeTooltip>
        </HeaderDetails>
      </StickyHeader>
      <Gutters>
        <FiltersContextProvider
          context={FiltersContext}
          activeFilters={activeFilters}
          updateFilters={setSelectedFilters}
          repositoryKey={repositoryKey}
          moduleKey={moduleKey}
          commitSha={commitSha}
          repository={repositoryProfile}
          filters={filters}
          materialChangesCount={materialChangesCount}
        />
      </Gutters>
    </Page>
  );
};

export default CommitPage;

const HeaderDetails = styled.div`
  color: var(--default-text-color);
  flex-wrap: wrap;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 4rem;

  ${RiskBadge.RiskLevel} {
    border-radius: 4rem;
    padding: 0 3rem;
    line-height: 8rem;
    font-weight: 300;

    &[data-risk='None'] {
      display: none;
    }
  }
`;
