import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useMouseDrag } from '@src-v2/hooks/dom-events/use-mouse-drag';

export function usePanAndZoom({ x = 0, y = 0, minScale = 0, maxScale = Infinity } = {}) {
  const [center, setCenter] = useState({ x, y });
  const [scale, setScale] = useState(1);
  const originCenter = useRef({ x, y });

  useLayoutEffect(() => {
    const { x: cx, y: cy } = originCenter.current;
    if (x !== cx || y !== cy) {
      setCenter(center => ({
        x: center.x + x - cx,
        y: center.y + y - cy,
      }));
      originCenter.current = { x, y };
    }
  }, [x, y]);

  const modifyScale = useCallback(
    multiplier =>
      setScale(
        currentScale =>
          clampMultiplication(currentScale, multiplier, {
            min: minScale,
            max: maxScale,
          }).value
      ),
    [minScale, maxScale, setScale]
  );

  const handleMouseDown = useMouseDrag(
    (moveEvent, downEvent) => {
      downEvent.initial ??= center;
      setCenter({
        x: downEvent.initial.x + moveEvent.clientX - downEvent.clientX,
        y: downEvent.initial.y + moveEvent.clientY - downEvent.clientY,
      });
    },
    [center, setCenter]
  );

  const handleWheel = useCallback(
    event => {
      const { offsetX, offsetY } = currentTargetOffset(event);
      setScale(currentScale => {
        const { value: scale, multiplier: modifier } = clampMultiplication(
          currentScale,
          event.deltaY > 0 ? 1 / scaleRatio : scaleRatio,
          { min: minScale, max: maxScale }
        );
        setCenter(center => ({
          x: offsetX - (offsetX - center.x) * modifier,
          y: offsetY - (offsetY - center.y) * modifier,
        }));
        return scale;
      });
    },
    [setScale, setCenter]
  );

  return [
    {
      scale,
      center,
      onMouseDown: handleMouseDown,
      onWheel: handleWheel,
    },
    modifyScale,
  ];
}

const scaleRatio = 1.08;

function currentTargetOffset(event) {
  const { x, y } = event.currentTarget.getBoundingClientRect();
  return { offsetX: event.clientX - x, offsetY: event.clientY - y };
}

function clampMultiplication(baseValue, multiplier, { min = 0, max = Infinity } = {}) {
  const value = Math.min(Math.max(baseValue * multiplier, min), max);
  return { value, multiplier: value / baseValue };
}
