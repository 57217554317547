import { useFormContext } from 'react-hook-form';
import { Input } from '@src-v2/components/forms';
import { Paragraph } from '@src-v2/components/typography';
import { TagsTypeSelector } from '@src-v2/containers/process-tags/tags-type-selector';

export const ProcessTagFormEditor = () => {
  const { register } = useFormContext();

  return (
    <>
      <Paragraph as="div">
        <Input {...register('name', { required: true })} placeholder="Process name" autoFocus />
      </Paragraph>
      <Paragraph as="div">
        <TagsTypeSelector />
      </Paragraph>
    </>
  );
};
