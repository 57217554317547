import styled from 'styled-components';
import { pluralFormat } from '@src-v2/utils/number-utils';
import {
  ProfileWidget,
  ProfileWidgetLabel,
} from '@src/blocks/ConsumableInfiniteScroll/blocks/ConsumableProfileWidget';
import { Number } from '@src/components/Number';

export const ProductsCountWidget = ({ productsCount, repositoriesCount }) => {
  const hasProducts = productsCount > 0;
  const label = hasProducts
    ? pluralFormat(productsCount, 'Application', 'Applications')
    : pluralFormat(repositoriesCount, 'Repository', 'Repositories');
  return (
    <ProfileWidget>
      <CounterLabel>
        <Number value={hasProducts ? productsCount : repositoriesCount} />
      </CounterLabel>
      <ProfileWidgetLabel>{label}</ProfileWidgetLabel>
    </ProfileWidget>
  );
};

const CounterLabel = styled.span`
  align-self: center;
  line-height: 1;
  text-align: center;
  font-size: 10rem;
`;
