import styled from 'styled-components';
import { Ellipsis, FontBodySmall } from '@src/style/common';
import { Link } from './Link';
import { VerticalStack } from './VerticalStack';

export const StyledVerticalStack = styled(VerticalStack)`
  ${Ellipsis};
  ${FontBodySmall};
`;

const CVE_URL = 'https://cve.mitre.org/cgi-bin/cvename.cgi?name=';
const GHSA_URL = 'https://github.com/advisories/';
const CWE_URL = 'https://cwe.mitre.org/data/definitions/';

const VulnerabilityIdentifiers = ({ cveIdentifiers, cweIdentifiers }) => (
  <StyledVerticalStack>
    {cveIdentifiers?.map(cve => (
      <Link
        key={cve}
        url={cve.startsWith('CVE') ? `${CVE_URL}${cve}` : `${GHSA_URL}${cve}`}
        underline
        openInNewTab>
        {cve}
      </Link>
    ))}
    {cweIdentifiers?.map(cwe => (
      <Link
        key={cwe}
        url={`${CWE_URL}${cwe.split('-').length > 1 && cwe.split('-')[1]}.html`}
        underline
        openInNewTab>
        {cwe}
      </Link>
    ))}
  </StyledVerticalStack>
);

export default VulnerabilityIdentifiers;
