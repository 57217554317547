import { Heading, Paragraph, Strong } from '@src-v2/components/typography';
import { abnormalBehaviorTypeToString } from '@src-v2/containers/commit/material-changes-utils';

export const AbnormalBehaviorMaterialChange = ({ materialChange }) => {
  return (
    <Heading>
      <Paragraph>Abnormal behavior of type</Paragraph>
      <Strong>{abnormalBehaviorTypeToString(materialChange.abnormalBehavior)}</Strong>
      <Paragraph>was detected</Paragraph>
    </Heading>
  );
};
