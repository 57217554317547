import _, { capitalize, lowerCase } from 'lodash';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import { Link, useParams } from 'react-router-dom';
import styled from 'styled-components';
import { Collapsible } from '@src-v2/components/collapsible';
import { Heading1 } from '@src-v2/components/typography';
import { VerticalCounter } from '@src-v2/components/vertical-counter';
import { useSuspense } from '@src-v2/hooks';

export const TechnologiesContent = observer(
  ({ profileType = null, dataFetcher, linkFunc = null }) => {
    const { key } = useParams();
    const categories = useSuspense(dataFetcher, { profileType, key });
    const totalCount = useMemo(
      () =>
        _.sumBy(categories, ({ subCategories }) => _.sumBy(subCategories, 'technologies.length')),
      [categories]
    );

    return (
      <>
        <Heading1>Technology stack</Heading1>
        <Counter title="Technologies" value={totalCount} />

        {categories.map(({ category, subCategories }, index) => (
          <OuterCollapsible
            key={category}
            defaultOpen={index === 0}
            title={<CategoryTitle category={category} subCategories={subCategories} />}>
            {subCategories.map(({ subCategory, technologies }) => (
              <InnerCollapsible
                key={subCategory}
                title={<SubCategoryTitle subCategory={subCategory} technologies={technologies} />}>
                {technologies.map(item => (
                  <TechItem
                    key={item.key}
                    as={linkFunc ? Link : 'span'}
                    to={linkFunc?.(item.value ?? item.key, subCategory, category)}>
                    {item.label}
                  </TechItem>
                ))}
              </InnerCollapsible>
            ))}
          </OuterCollapsible>
        ))}
      </>
    );
  }
);

const Counter = styled(VerticalCounter)`
  margin-top: 1rem;
  margin-bottom: 8rem;
`;

const OuterCollapsible = styled(Collapsible)`
  min-height: 16rem;
  margin: 5rem 0;
  border-radius: 1rem;
  box-shadow: 0 0 0 0.25rem var(--color-blue-gray-30);
  transition: box-shadow 400ms;

  &[data-open] {
    background-color: var(--color-blue-gray-15);
    box-shadow: 0 0 0 0 var(--color-blue-gray-15);
  }

  & > ${Collapsible.Head} {
    padding: 5rem;
  }

  ${Collapsible.Head}:hover {
    background-color: rgba(0, 0, 0, 0.03);
  }
`;

const InnerCollapsible = styled(Collapsible)`
  &:last-child {
    margin-bottom: 5rem;
  }

  ${Collapsible.Head} {
    margin-top: 1rem;
    padding: 0 5rem 0 8rem;
  }

  ${Collapsible.Title} {
    font-size: var(--font-size-m);
    font-weight: 400;
    line-height: 7rem;
  }

  ${Collapsible.Chevron} {
    width: 5rem;
    height: 5rem;
    margin: 0 0.25rem;
  }
`;

const TechItem = styled(Link)`
  display: block;
  max-width: fit-content;
  margin: 2rem 11rem;
  font-size: var(--font-size-s);
  font-weight: 300;
  color: var(--color-blue-gray-60);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &:hover {
    text-decoration: underline;
  }

  &:last-child {
    margin-bottom: 4rem;
  }
`;

function CategoryTitle({ category, subCategories }) {
  return (
    <>
      {useMemo(() => _.sumBy(subCategories, 'technologies.length'), [subCategories])}{' '}
      {capitalize(lowerCase(category))}
    </>
  );
}

function SubCategoryTitle({ subCategory, technologies }) {
  return (
    <>
      {subCategory} &middot; {technologies.length}
    </>
  );
}
