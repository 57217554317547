import styled from 'styled-components';
import { HorizontalStack } from './HorizontalStack';

const StyledPaneHeader = styled.div`
  padding: 9rem 12rem 6px;
  min-height: 120px;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  position: relative;
  background-color: var(--color-white);
`;

const StyledPaneHeaderName = styled(HorizontalStack)`
  color: var(--default-text-color);
  font-size: 24px;
  line-height: 36px;
  font-weight: 500;
  align-items: flex-end;
  margin-bottom: 3px;
  max-width: 100%;
`;

const StyledDivider = styled.div`
  margin-top: 9rem;
  height: 6px;
  background-color: var(--color-blue-gray-15);
`;

const PaneHeader = ({ title, overview, actions, ...props }) =>
  title || overview ? (
    <StyledPaneHeader {...props}>
      <Container>
        <Description>
          <StyledPaneHeaderName>{title}</StyledPaneHeaderName>
          {overview}
        </Description>
        {actions}
      </Container>
      <StyledDivider />
    </StyledPaneHeader>
  ) : null;

export default PaneHeader;

const Container = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Description = styled.div`
  width: 100%;
  flex-grow: 1;
`;
