import { addDays } from 'date-fns';
import { useCallback, useState } from 'react';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Paragraph } from '@src-v2/components/typography';
import { ExtensionSelect } from '@src-v2/containers/access-tokens/extension-select';

export const RegenerationModal = ({ onRegenerate, onClose }) => {
  const [extensionValue, setExtensionValue] = useState(addDays(new Date(), 30));

  const handleSubmit = useCallback(
    () => onRegenerate(extensionValue),
    [setExtensionValue, extensionValue]
  );

  return (
    <ConfirmationModal
      title="Regenerate access token"
      submitText="Regenerate Token"
      onSubmit={handleSubmit}
      onClose={onClose}>
      <Paragraph>
        Submitting this form will generate a new token. Be aware that any scripts or applications
        using this token will need to be updated.
      </Paragraph>

      <Paragraph as="div">
        The token will expire in <ExtensionSelect onChange={setExtensionValue} />
      </Paragraph>
    </ConfirmationModal>
  );
};
