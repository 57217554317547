import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Avatar, AvatarStack } from '@src-v2/components/avatar';
import { CircleGroup } from '@src-v2/components/circles';
import { Popover } from '@src-v2/components/tooltips/popover';
import { Heading4 } from '@src-v2/components/typography';
import { humanize } from '@src-v2/utils/string-utils';

export function PointsOfContact({
  identities,
  profileKey,
  isActive,
  placement = 'bottom-end',
  ...props
}) {
  return (
    <Container {...props}>
      <Popover
        content={
          <>
            <PointOfContactPopoverHead>
              <Heading4>Points of Contact</Heading4>
            </PointOfContactPopoverHead>
            {identities.map(identity => (
              <PointOfContact
                key={identity.identityKey}
                to={`/users/contributors/${identity.identityKey}`}>
                <Avatar username={identity.username} size="xlarge" active={isActive} />
                <Content>
                  <Username>{identity.username}</Username>
                  <Description>{humanize(identity.jobTitle)}</Description>
                </Content>
              </PointOfContact>
            ))}
          </>
        }
        placement={placement}>
        <AvatarStack identities={identities} maxSize={2} />
      </Popover>
    </Container>
  );
}

const Container = styled.div`
  ${CircleGroup} {
    cursor: pointer;
  }
`;
const PointOfContactPopoverHead = styled(Popover.Head)`
  padding: 2rem;
`;
const PointOfContact = styled(Link)`
  display: flex;
  padding: 2rem;
  align-items: center;
  border-radius: 1rem;
  gap: 4rem;

  &:hover {
    background-color: var(--color-blue-gray-15);
  }
`;

const Content = styled.div`
  min-width: 0;
  flex-grow: 0;
  white-space: nowrap;
`;

const Username = styled.h4`
  font-weight: 700;
  text-overflow: ellipsis;
  overflow: hidden;
`;

const Description = styled.p`
  font-size: var(--font-size-s);
  text-overflow: ellipsis;
  overflow: hidden;
`;
