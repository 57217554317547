import _ from 'lodash';
import { useCallback } from 'react';
import styled from 'styled-components';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Form } from '@src-v2/components/forms/form-layout';
import { Modal } from '@src-v2/components/modals';
import { SensitiveDataRulesIndicator } from '@src/blocks/GovernancePage/blocks/Definitions/SensitiveDataRulesIndicator';
import apiService from '@src/services/apiService';
import { SensitiveDataDefinitionForm } from './SensitiveDataDefinitionForm';

export const SensitiveDataDefinitionModal = styled(
  ({ definition, onSubmit, onClose, ...props }) => {
    const handleSubmit = useCallback(
      async data => {
        const shouldOpenRuleBanner = await getShouldOpenBanner();
        return onSubmit({ ...data, shouldOpenRuleBanner });
      },
      [onSubmit, getShouldOpenBanner]
    );

    return (
      <ConfirmationModal
        {...props}
        as={Form}
        title="Custom Sensitive Data"
        submitText={definition ? 'Save' : 'Create'}
        defaultValues={definition ?? newDefinition()}
        resolver={sensitiveDataDefinitionResolver}
        onSubmit={handleSubmit}
        onClose={onClose}>
        <>
          <FormPane>
            <SensitiveDataDefinitionForm />
          </FormPane>
          <VerticalDivider />
          <IndicatorPane>
            <SensitiveDataRulesIndicator />
          </IndicatorPane>
        </>
      </ConfirmationModal>
    );
  }
)`
  min-width: 300rem;

  ${Modal.Content} {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    }
  }
`;

const FormPane = styled.div`
  flex: 5;
`;

const IndicatorPane = styled.div`
  display: flex;
  flex: 3;
  flex-direction: column;
  align-items: center;
`;

const VerticalDivider = styled.div`
  margin: 0 8rem;
  border-left: 0.25rem solid var(--color-blue-gray-30);
`;

function newDefinition() {
  return {
    key: crypto.randomUUID(),
    name: '',
    exactMatch: [],
    multipleTokens: [],
    wildcards: [],
  };
}

function sensitiveDataDefinitionResolver(data) {
  let errors = {};
  if (_.isEmpty(data.name)) {
    errors.name = 'Name is required';
  }

  if (_.isEmpty(data.exactMatch) && _.isEmpty(data.multipleTokens) && _.isEmpty(data.wildcards)) {
    const errorString = 'At least one term is required';
    errors = {
      ...errors,
      exactMatch: errorString,
      multipleToken: errorString,
      wildcards: errorString,
    };
  }

  return { values: data, errors };
}

async function getShouldOpenBanner() {
  const { data } = await apiService.get(`/api/definitions/customSensitiveDataDefinitions/rules`);
  return !data.length;
}
