import _ from 'lodash';

const clearData = () => ({
  repositoryProfiles: [],
  selectedRepositoryProfile: null,
});

export default {
  state: clearData(),
  selectors: slice => ({
    repositoryProfiles: () => slice(({ repositoryProfiles }) => repositoryProfiles),
    selectedRepositoryProfile: () =>
      slice(({ selectedRepositoryProfile }) => selectedRepositoryProfile),
  }),
  reducers: {
    clearData,
    setRepositoryProfiles: (state, repositoryProfiles) => ({
      ...state,
      repositoryProfiles,
    }),
    setSelectedRepositoryProfile: (state, selectedRepositoryProfile) => ({
      ...state,
      selectedRepositoryProfile,
    }),
  },
  effects: dispatch => ({
    async fetchData({ repositoryKeys }) {
      if (_.isEmpty(repositoryKeys)) {
        this.clearData();
        return;
      }

      const repositoryProfiles = await dispatch.repositoryProfiles.getRepositoryProfilesByKeysAsync(
        {
          keys: repositoryKeys,
        }
      );
      this.setRepositoryProfiles(repositoryProfiles);
      this.setSelectedRepositoryProfile(repositoryProfiles?.[0]);
    },
  }),
};
