import styled from 'styled-components';
import { ContributorTooltip } from '@src-v2/containers/contributors/contributor-tooltip';
import { TimelineEvent } from '@src/blocks/TimelineInfiniteScroll/components/TimelineEvent';
import { Commit } from '@src/components/Commit';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { RepositoryPopover } from '@src/components/RepositoryPopover';
import { Ellipsis } from '@src/style/common';
import { getCommitUrl } from '@src/utils/commitUtils';

const StyledDeveloper = styled(ContributorTooltip)`
  ${Ellipsis};
`;

const StyledRepositoryPopover = styled(RepositoryPopover)`
  ${Ellipsis};
`;

const MaterialChange = styled(HorizontalStack)`
  color: var(--default-text-color);
  padding-bottom: 3rem;
`;

export const ElementTimelineItem = ({
  isFirst,
  isLast,
  index,
  event,
  previousEvent,
  repositoryProfile,
  developerProfile,
  testMarker,
}) => {
  const commitTitle = (
    <>
      Commit
      <Commit
        commitSha={event.shortSha}
        repository={repositoryProfile.repository}
        authorProfile={developerProfile}
        repositoryProfiles={[repositoryProfile]}
      />
      by
      <StyledDeveloper developerProfile={developerProfile} underline hideImage link={false} />
      in
      <StyledRepositoryPopover repositoryProfile={repositoryProfile} />
    </>
  );

  return (
    <TimelineEvent
      currentTimelineEvent={event}
      previousTimelineEvent={previousEvent}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      titleContent={commitTitle}
      body={<MaterialChange>{event.label}</MaterialChange>}
      riskLevel={event.riskLevel}
      exploreUrl={getCommitUrl(repositoryProfile.key, event.commitSha)}
      data-test-marker={testMarker}
    />
  );
};
