export const FormProperties = [
  'mode',
  'reValidateMode',
  'defaultValues',
  'resolver',
  'context',
  'criteriaMode',
  'shouldFocusError',
  'shouldUnregister',
  'shouldUseNativeValidation',
  'delayError',
  'errors',
];
