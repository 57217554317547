import styled from 'styled-components';
import { SvgIcon } from '@src-v2/components/icons';
import { DateTime } from '@src-v2/components/time';
import { InfoTooltip } from '@src-v2/components/tooltips/icon-tooltips';
import { dateFormats } from '@src-v2/data/datetime';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';
import { PaneBody } from '@src/blocks/Pane/view';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import {
  StyledInsightsSubTitle,
  StyledVerticalStack,
} from '@src/components/CodeAggregation/Insights/InsightStyles';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Link } from '@src/components/Link';
import { Table } from '@src/components/Table';
import { VerticalStack } from '@src/components/VerticalStack';
import { dispatch } from '@src/store';

const getGlobalIdentifierLink = globalIdentifier => {
  switch (globalIdentifier.identifierType) {
    case 'Cwe':
      return `https://cwe.mitre.org/data/definitions/${globalIdentifier.identifier.replace(
        /^CWE-/,
        ''
      )}.html`;

    case 'Cve':
      return `https://nvd.nist.gov/vuln/detail/${globalIdentifier.identifier}`;

    default:
      return null;
  }
};

export const openExternalGeneralFindingPane = ({
  ruleKey,
  trigger,
  profile,
  profileType,
  inventory,
  onClose,
  relevantPath,
  messageContent,
}) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  dispatch.pane.openPane({
    onClose,
    relevantPath,
    id: trigger.key,
    level: secondaryPaneLevel,
    content: (
      <RiskRuleTrigger
        profile={profile}
        ruleKey={ruleKey}
        triggerKey={trigger.key}
        inventory={inventory}
        getPane={({ ruleTriggers, externalGeneralFinding }) =>
          getExternalGeneralFindingPane({
            trigger,
            externalGeneralFinding,
            repository,
            profile,
            profileType,
            ruleTriggers,
            messageContent,
          })
        }
      />
    ),
  });
};

const getExternalGeneralFindingPane = ({
  withRiskContext,
  externalGeneralFinding,
  profile,
  profileType,
  ruleTriggers,
  messageContent,
}) => {
  return (
    <PaneBody
      title={
        <InsightsPaneTitle
          withRiskContext={withRiskContext}
          element={externalGeneralFinding}
          title={externalGeneralFinding.description}
          codeReference={externalGeneralFinding.codeReference}
          additionalLocationContext={[
            externalGeneralFinding.sourceSystemId,
            externalGeneralFinding.id,
          ]}
          profile={profile}
          profileType={profileType}
          ruleTriggers={ruleTriggers}
          messageContent={messageContent}
        />
      }
      body={
        <>
          <StyledInsightsSubTitleWithSpacing>Overview</StyledInsightsSubTitleWithSpacing>
          <StyledVerticalStack>
            <HorizontalStack>
              <StyledFieldLabel>Finding type:</StyledFieldLabel>
              <ExternalGeneralFindingType dotQualifiedType={externalGeneralFinding.type} />
            </HorizontalStack>
            <HorizontalStack>
              <StyledFieldLabel>Reported severity:</StyledFieldLabel>
              {externalGeneralFinding.severity}
              <InfoTooltip
                content="This is the finding's severity as reported by the external system. 
                            Depending on governance rule definitions, it may be different than the risk 
                            severity assigned by Apiiro."
              />
            </HorizontalStack>
            <HorizontalStack>
              <StyledFieldLabel>Reported at:</StyledFieldLabel>
              <DateTime date={externalGeneralFinding.time} format={dateFormats.longDatetime} />
            </HorizontalStack>
            <HorizontalStack>
              <StyledFieldLabel>First occurrence:</StyledFieldLabel>
              <DateTime
                date={externalGeneralFinding.FirstOccurenceTime}
                format={dateFormats.longDatetime}
              />
            </HorizontalStack>
          </StyledVerticalStack>

          {externalGeneralFinding.globalIssueIdentifiers?.length && (
            <>
              <StyledInsightsSubTitleWithSpacing>
                Vulnerabilities and Weaknesses
              </StyledInsightsSubTitleWithSpacing>

              <Table
                items={[0]}
                noTopPadding
                headers={[{ name: 'CVE / CWE', weight: 1 }]}
                rows={[
                  {
                    key: '0',
                    cells: [
                      {
                        content: (
                          <VerticalStack>
                            {externalGeneralFinding.globalIssueIdentifiers.map(
                              (globalIssueIdentifier, index) => {
                                const linkForIdentifier =
                                  getGlobalIdentifierLink(globalIssueIdentifier);

                                return (
                                  <span key={index}>
                                    {globalIssueIdentifier.identifier}
                                    {linkForIdentifier && (
                                      <Link url={linkForIdentifier} key={index} external>
                                        <SvgIcon name="External" />
                                      </Link>
                                    )}
                                  </span>
                                );
                              }
                            )}
                          </VerticalStack>
                        ),
                      },
                    ],
                  },
                ]}
              />
            </>
          )}

          {Object.entries(externalGeneralFinding.tags || {}).length && (
            <>
              <StyledInsightsSubTitleWithSpacing>Properties</StyledInsightsSubTitleWithSpacing>
              <StyledVerticalStack>
                {Object.entries(externalGeneralFinding.tags).map(([key, value]) => (
                  <HorizontalStack key={key}>
                    <StyledFieldLabel>{key}:</StyledFieldLabel>
                    {value}
                  </HorizontalStack>
                ))}
              </StyledVerticalStack>
            </>
          )}

          {externalGeneralFinding.findingLinks?.length && (
            <>
              <StyledInsightsSubTitleWithSpacing>
                Links to Additional Information
              </StyledInsightsSubTitleWithSpacing>
              <StyledVerticalStack>
                {externalGeneralFinding.findingLinks.map((linkInfo, index) => (
                  <Link url={linkInfo.url} key={index} external>
                    {linkInfo.url}
                  </Link>
                ))}
              </StyledVerticalStack>
            </>
          )}
        </>
      }
    />
  );
};

function ExternalGeneralFindingType({ dotQualifiedType }) {
  return (
    <>
      {dotQualifiedType.split('.').map((typeStep, index, allItems) => (
        <ExternalGeneralFindingTypeStepContainer key={index}>
          <ExternalGeneralFindingTypeStep>{typeStep}</ExternalGeneralFindingTypeStep>
          {index < allItems.length - 1 && (
            <ExternalGeneralFindingTypeStepSeparator>
              &#x203a;
            </ExternalGeneralFindingTypeStepSeparator>
          )}
        </ExternalGeneralFindingTypeStepContainer>
      ))}
    </>
  );
}

const ExternalGeneralFindingTypeStepContainer = styled(HorizontalStack)``;

const ExternalGeneralFindingTypeStep = styled.div`
  background-color: var(--color-blue-gray-20);
  padding: 0.5rem 1rem;
  border-radius: 1rem;
`;

const ExternalGeneralFindingTypeStepSeparator = styled.div``;

const StyledFieldLabel = styled.span`
  color: var(--color-blue-gray-45);
`;

const StyledInsightsSubTitleWithSpacing = styled(StyledInsightsSubTitle)`
  margin-top: 5rem;
  margin-bottom: 2rem;
`;
