import { AvatarStack } from '@src-v2/components/avatar';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import {
  ProfileWidget,
  ProfileWidgetLabel,
} from '@src/blocks/ConsumableInfiniteScroll/blocks/ConsumableProfileWidget';
import { Number } from '@src/components/Number';

export const ActiveContributorsWidget = ({
  activeContributorCount,
  contributorCount,
  contributorData,
  isModuleBased = false,
}) => {
  return (
    <ContributorsWidget
      tip={
        isModuleBased
          ? 'Not calculated for monorepo applications'
          : `${activeContributorCount} active out of ${contributorCount}`
      }
      label={
        activeContributorCount ? (
          <Number
            value={activeContributorCount}
            one="Active Contributor"
            other="Active Contributors"
          />
        ) : (
          'No Active Contributors'
        )
      }
      contributorData={contributorData}
    />
  );
};

export const ContributorsWidget = ({ tip, label, contributorData }) => {
  const activeDeveloperData = contributorData.map(
    ({ representativeIdentityKeySha: identityKey, displayName: username, ...identity }) => ({
      username,
      identityKey,
      ...identity,
    })
  );
  return (
    <Tooltip content={tip}>
      <ProfileWidget>
        <AvatarStack identities={activeDeveloperData} size="xlarge" />
        <ProfileWidgetLabel>{label}</ProfileWidgetLabel>
      </ProfileWidget>
    </Tooltip>
  );
};
