import { useCallback, useEffect } from 'react';
import { useLegacyQueryParams } from '@src-v2/hooks';
import TableInfiniteScroll from '@src/components/TableInfiniteScroll';

const ServerTableInfiniteScroll = ({
  currentTableScope,
  filtersScope,
  tableScope,
  fetchData,
  fetchPage,
  postFetchPage,
  clearData,
  itemsCount,
  items,
  filters,
  searchTerm,
  headers,
  rowProvider,
  emptyMessage = 'No results found',
  className,
  getRowOnClick,
  searchPlaceholder,
  allowSearchAndFilter,
  onFilterChange,
  description,
  resultName,
  resultNamePlural,
  scrollParent,
  initializeFromQueryString,
  forceRender,
  searchParams,
  ...props
}) => {
  const query = useLegacyQueryParams();

  useEffect(() => {
    initializeFromQueryString(query);
    fetchData({ tableScope });
    return clearData;
  }, [fetchData, clearData, tableScope]);

  useEffect(() => {
    fetchPage({
      tableScope,
      currentPage: 0,
      filters,
      searchTerm,
      postFetchPage,
      ...searchParams,
    });
  }, [forceRender, searchParams]);

  const searchFunc = useCallback(
    pageCount =>
      fetchPage({
        tableScope,
        currentPage: pageCount,
        filters,
        searchTerm,
        postFetchPage,
        ...searchParams,
      }),
    [fetchPage, tableScope, filters, searchTerm, postFetchPage, searchParams]
  );

  return tableScope !== currentTableScope ? null : (
    <TableInfiniteScroll
      {...props}
      description={description}
      resultName={resultName}
      resultNamePlural={resultNamePlural}
      filtersScope={filtersScope ?? tableScope}
      itemsCount={itemsCount}
      items={items}
      filters={filters}
      headers={headers}
      rowProvider={rowProvider}
      emptyMessage={emptyMessage}
      className={className}
      getRowOnClick={getRowOnClick}
      searchPlaceholder={searchPlaceholder}
      allowSearchAndFilter={allowSearchAndFilter}
      onFilterChange={onFilterChange}
      searchFunc={searchFunc}
      scrollParent={scrollParent ?? 'main'}
    />
  );
};

export default ServerTableInfiniteScroll;
