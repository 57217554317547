import _ from 'lodash';
import { humanize } from '@src-v2/utils/string-utils';
import filtersService from '@src/services/filtersService';

const secretsUnknownEnvironment = 'Unknown';
const getEnvironmentName = environment =>
  environment === secretsUnknownEnvironment ? 'Unknown (assumed production)' : environment;

export const getSecretsFilterOptions = ({ elements }) => {
  const filters = [];

  const fileClassifications = _.uniq(
    elements
      .filter(secret => secret.fileClassification !== 'Default')
      .map(secret => secret.fileClassification)
  );
  if (fileClassifications.length > 1) {
    filters.push({
      name: 'SecretsFilePath',
      displayName: 'File Type',
      filterOptions: filtersService.getListFilterOptions(fileClassifications),
    });
  }

  const categories = _.uniq(elements.map(secret => secret.category));
  if (categories.length > 1) {
    filters.push({
      name: 'SecretsCategory',
      displayName: 'Detection Method',
      filterOptions: filtersService.getListFilterOptions(categories, type => humanize(type)),
    });
  }

  const types = _.uniq(elements.map(secret => secret.type));
  if (types.length > 1) {
    filters.push({
      name: 'SecretsType',
      displayName: 'Exposure',
      filterOptions: filtersService.getListFilterOptions(types),
    });
  }

  const environments = _.uniq(elements.flatMap(element => element.environments));
  if (!_.isEmpty(environments)) {
    if (elements.some(element => _.isEmpty(element.environments))) {
      environments.push(secretsUnknownEnvironment);
    }
    filters.push({
      name: 'SecretsEnvironment',
      displayName: 'Environment',
      filterOptions: filtersService.getListFilterOptions(environments, getEnvironmentName),
    });
  }

  return filters;
};

export const filterSecrets = (allItems, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, item => [
    item.relativeFilePath,
  ]);

  const filterClassifications = filters.listFilters?.SecretsFilePath;
  if (!_.isEmpty(filterClassifications)) {
    filtered = filtered.filter(secret =>
      filterOperator(
        filterClassifications.map(classification => secret.fileClassification === classification)
      )
    );
  }

  const filterCategories = filters.listFilters?.SecretsCategory;
  if (!_.isEmpty(filterCategories)) {
    filtered = filtered.filter(secret =>
      filterOperator(filterCategories.map(type => secret.category === type))
    );
  }

  const filterTypes = filters.listFilters?.SecretsType;
  if (!_.isEmpty(filterTypes)) {
    filtered = filtered.filter(secret =>
      filterOperator(filterTypes.map(type => secret.type === type))
    );
  }

  const filterEnvironments = filters.listFilters?.SecretsEnvironment;
  if (!_.isEmpty(filterEnvironments)) {
    filtered = filtered.filter(secret =>
      filterOperator(
        filterEnvironments.map(
          environment =>
            _.includes(secret.environments, environment) ||
            (environment === secretsUnknownEnvironment && _.isEmpty(secret.environments))
        )
      )
    );
  }

  return filtered;
};
