import { computed, makeObservable, observable } from 'mobx';
import { ForceNode } from '@src-v2/models/force-graph/force-node';

export class NodeCollection {
  static create = (...args) => new this(...args);
  static defaultSize = ForceNode.defaultSize * 5;
  static radiusModifier = 0.2;
  static radiusModifierRange = [600, 1000];

  #rootGraph;

  constructor(nodes, rootGraph) {
    this.#rootGraph = rootGraph;
    this.nodes = nodes.map(node => Object.assign(node, { collection: this }));

    makeObservable(this, {
      nodes: observable.ref,
      descendants: computed,
      dimensions: computed,
      totalSize: computed,
      radius: computed,
    });
  }

  get hasLinks() {
    return this.nodes.some(node => this.#rootGraph.isLinked(node) || node.children?.hasLinks);
  }

  get descendants() {
    return this.nodes
      .filter(node => node.children && node.selected)
      .flatMap(node => [{ parent: this, collection: node.children }, ...node.children.descendants]);
  }

  get dimensions() {
    const { defaultSize } = this.constructor;
    const {
      x: [minX, maxX],
      y: [minY, maxY],
    } = this.nodes.reduce(
      ({ x: [minX, maxX], y: [minY, maxY] }, { simulation: node }) => ({
        x: [Math.min(minX, node.x - node.radius), Math.max(maxX, node.x + node.radius)],
        y: [Math.min(minY, node.y - node.radius), Math.max(maxY, node.y + node.radius)],
      }),
      { x: [null, null], y: [null, null] }
    );
    return {
      width: Math.max(Math.ceil(maxX - minX), defaultSize),
      height: Math.max(Math.ceil(maxY - minY), defaultSize),
    };
  }

  get radius() {
    const {
      radiusModifier,
      radiusModifierRange: [minRange, maxRange],
    } = this.constructor;
    const { width, height } = this.dimensions;
    const radius = Math.ceil(Math.max(width, height) / Math.sqrt(2));
    const modifier =
      1 - Math.min(1, Math.max(0, (radius - minRange) / (maxRange - minRange))) * radiusModifier;
    return radius * modifier;
  }

  get totalSize() {
    const parentNodes = this.nodes.filter(node => node.children);
    return parentNodes.reduce(
      (sum, node) => sum + node.children.totalSize,
      this.nodes.length - parentNodes.length
    );
  }
}
