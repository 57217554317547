import { ParentSize } from '@visx/responsive';
import { useCallback } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import {
  BubbleChart,
  BubbleData,
  calculateLinearInterpolation,
} from '@src-v2/components/charts/bubble-chart';
import { OverviewTile } from '@src-v2/components/overview/overview-tiles';
import { useOverviewFilters } from '@src-v2/components/overview/use-overview-filters';
import { useMakeOverviewUrl } from '@src-v2/containers/overview/tiles/utils';
import { useInject, useSuspense } from '@src-v2/hooks';
import {
  BusinessImpactAbbreviation,
  BusinessImpactToLevel,
} from '@src-v2/types/enums/business-impact';
import { AppsRiskScoreResponse } from '@src-v2/types/overview/overview-responses';
import { abbreviate, parseAbbreviated } from '@src-v2/utils/number-utils';
import { resourceRiskColor } from '@src/cluster-map-work/components/charts/chart-view-presentation-config';

const businessImpactOrder = [
  BusinessImpactAbbreviation.low,
  BusinessImpactAbbreviation.medium,
  BusinessImpactAbbreviation.high,
];

const getBubbleColor = (_: any, BI: BusinessImpactAbbreviation) =>
  resourceRiskColor(BusinessImpactToLevel[BI]);
const getTextColor = (_: any, BI: BusinessImpactAbbreviation) => {
  switch (BI) {
    case 'HBI':
      return 'var(--color-white)';
    default:
      return 'var(--color-blue-gray-70)';
  }
};

function generateRiskScoreDisplay(
  appData: AppsRiskScoreResponse[]
): { value: number; display: string }[] {
  const uniqueRanges = [...new Set(appData.map(item => item.range))].sort((a, b) => a - b);
  return uniqueRanges.map(range => ({
    value: range,
    display: abbreviate(range),
  }));
}

function formatRiskData(appData: AppsRiskScoreResponse[]): BubbleData {
  const formattedData: BubbleData = {};

  appData.forEach(item => {
    const rangeKey = abbreviate(item.range);

    if (!formattedData[rangeKey]) {
      formattedData[rangeKey] = {};
    }

    if (!formattedData[rangeKey][item.businessImpact]) {
      formattedData[rangeKey][item.businessImpact] = 0;
    }

    formattedData[rangeKey][item.businessImpact] += item.count;
  });

  return formattedData;
}

function AppsBubbleChart() {
  const { overview } = useInject();
  const { activeFilters } = useOverviewFilters();
  const appData = useSuspense(overview.getAppsCountByBusinessImpactAndRiskScore, {
    filters: activeFilters,
  });

  const formattedAppsData = formatRiskData(appData);
  const riskScoreRangesForDisplay = generateRiskScoreDisplay(appData);
  const makeOverviewUrl = useMakeOverviewUrl();
  const history = useHistory();

  const onValueClick = useCallback(
    (riskScore: string, BusinessImpact: string) => {
      const range = getRiskScoreFilterRange(riskScore, appData);

      if (range) {
        history.push(
          makeOverviewUrl({
            baseUrl: '/profiles/applications',
            query: {
              RiskScore: { values: [range] },
              BusinessImpact: [
                BusinessImpactToLevel[BusinessImpact as keyof typeof BusinessImpactToLevel],
              ],
            },
          })
        );
      }
    },
    [history, makeOverviewUrl, appData, getRiskScoreFilterRange]
  );

  return (
    <ChartContainer>
      <ParentSize>
        {({ width, height }) => (
          <BubbleChart
            getBubbleSize={getBubbleSize}
            width={width - 36}
            handleValueClick={onValueClick}
            height={height - 36}
            data={formattedAppsData}
            getBubbleColor={getBubbleColor}
            getTextColor={getTextColor}
            xLabels={riskScoreRangesForDisplay.map(range => range.display)}
            yLabels={businessImpactOrder.slice()}
            xAxisLabel="Risk score"
          />
        )}
      </ParentSize>
    </ChartContainer>
  );
}

const ChartContainer = styled.div`
  width: 100%;
  height: 100%;
`;

export const AppsByBusinessImpactAndScore = () => (
  <OverviewTile title="Apps by Business Impact and Risk Score">
    <AppsBubbleChart />
  </OverviewTile>
);

function getRiskScoreFilterRange(
  clickedScore: string | number,
  appData: AppsRiskScoreResponse[]
): string {
  const uniqueRanges = [...new Set(appData.map(item => item.range))].sort((a, b) => a - b);
  const defaultValue = -1;
  const targetScore = parseAbbreviated(clickedScore.toString());
  const closestRange = uniqueRanges.reduce((prev, curr) => {
    return Math.abs(curr - targetScore) < Math.abs(prev - targetScore) ? curr : prev;
  });
  const rangeIndex = uniqueRanges.indexOf(closestRange);
  return `${uniqueRanges[rangeIndex - 1] ?? defaultValue}||${uniqueRanges[rangeIndex]}`;
}

function getBubbleSize(value: number) {
  if (value <= 10) {
    return calculateLinearInterpolation(value, 1, 10, 20, 35);
  }
  if (value <= 25) {
    return calculateLinearInterpolation(value, 10, 25, 35, 40);
  }
  if (value <= 50) {
    return calculateLinearInterpolation(value, 25, 50, 42, 44);
  }
  if (value <= 75) {
    return calculateLinearInterpolation(value, 50, 75, 44, 55);
  }
  if (value <= 100) {
    return calculateLinearInterpolation(value, 75, 100, 55, 65);
  }
  if (value <= 250) {
    return calculateLinearInterpolation(value, 100, 250, 65, 70);
  }
  if (value <= 500) {
    return calculateLinearInterpolation(value, 250, 500, 70, 80);
  }
  if (value <= 1000) {
    return calculateLinearInterpolation(value, 500, 1000, 80, 85);
  }
  if (value <= 2500) {
    return calculateLinearInterpolation(value, 1000, 2500, 85, 90);
  }
  if (value <= 5000) {
    return calculateLinearInterpolation(value, 2500, 5000, 90, 95);
  }
  if (value <= 10000) {
    return calculateLinearInterpolation(value, 5000, 10000, 95, 100);
  }
  if (value <= 100000) {
    return calculateLinearInterpolation(value, 10000, 100000, 100, 105);
  }
  if (value <= 1000000) {
    return calculateLinearInterpolation(value, 100000, 1000000, 100, 120);
  }
  return 65;
}
