import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { TextButton } from '@src-v2/components/button-v2';
import { SvgIcon } from '@src-v2/components/icons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { ClusterPaneCollapsibleCard } from '@src/cluster-map-work/components/charts/cluster-chart/property-providers/common-resource-properties';

export const PropertiesPanel = styled.div`
  display: flex;
  padding: 0;
  flex-direction: column;
  overflow: hidden;
`;

PropertiesPanel.ScrollableRegion = styled.div`
  padding: 0;
  margin: 0;
  overflow-y: scroll;
`;

PropertiesPanel.TitleBar = styled.div`
  display: flex;
  flex-direction: column;
`;

PropertiesPanel.TitleBar.ObjectName = styled.div`
  grid-area: name;
  margin-right: 2.5rem;
  font-size: 4.5rem;
  font-weight: 600;
  line-height: 6rem;
  color: var(--color-blue-gray-70);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  &[data-highlighted] {
    background: var(--color-yellow-25);
  }
`;

PropertiesPanel.TitleBar.Title = PropertiesPanel.TitleBar.ObjectName;

PropertiesPanel.TitleBar.ObjectType = styled.div`
  grid-area: type;
  place-self: start;
  display: flex;
  align-items: center;
  font-size: var(--font-size-s);
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  background-color: ${props => props.tagColor ?? 'var(--color-blue-gray-20)'};
  color: ${props => props.tagForegroundColor ?? 'unset'};

  height: 6rem;
  padding: 0.25rem 3rem;
  border-radius: 3rem;
  margin-bottom: 3rem;
`;

PropertiesPanel.TitleBar.ObjectDescription = styled.div`
  grid-area: description;
  font-size: var(--font-size-s);
  font-weight: 300;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  color: var(--color-blue-gray-60);
`;

PropertiesPanel.TitleBar.Separator = styled.hr`
  grid-area: separator;
  border: 0;
  border-top: 0.25rem solid var(--color-blue-gray-30);
  margin-top: 3.5rem;
  margin-bottom: 0;
`;

PropertiesPanel.Section = styled.div``;

PropertiesPanel.Section.Header = styled.div`
  font-size: 4rem;
  line-height: 1.5;
  color: var(--color-blue-gray-60);
  text-overflow: ellipsis;
  font-weight: 300;
  white-space: nowrap;
  overflow: hidden;
`;

export const PropertiesScrollableRegion = styled(PropertiesPanel.ScrollableRegion)`
  display: flex;
  flex-direction: column;
  padding: 2rem 4rem;
  position: relative;
  gap: 4rem;
`;

export const SectionItemHoverIcon = styled(SvgIcon)``;

const SectionItemContainer = styled.div`
  height: 8rem;
  display: flex;
  font-size: var(--font-size-s);
  font-style: normal;
  font-weight: 400;

  ${TextButton} {
    max-width: 95%;
  }

  & ${SectionItemHoverIcon} {
    display: none;
    min-width: 6rem;
    margin-top: 1rem;
  }

  &:hover ${SectionItemHoverIcon} {
    display: inline;
  }

  &[data-highlighted] {
    background: var(--color-yellow-25);
  }
`;

function PropertiesPanelSectionItem({ children, highlighted }) {
  return (
    <SectionItemContainer data-highlighted={dataAttr(highlighted)}>{children}</SectionItemContainer>
  );
}

PropertiesPanel.Section.Item = PropertiesPanelSectionItem;

export function ListPropertiesPanelSection({
  list,
  title,
  maxListItems = 10,
  itemToString = item => item.toString(),
  itemToLink = () => null,
  itemToElementContent = itemToString,
  highlightedItems = null,
  itemToSearchTerm = null,
  onToggleSearchTerm = null,
}) {
  const handleAddSearchTerm = useCallback(
    item => {
      onToggleSearchTerm(itemToSearchTerm(item));
    },
    [itemToSearchTerm, onToggleSearchTerm]
  );

  const highlightedItemSet = useMemo(() => new Set(highlightedItems), [highlightedItems]);

  const highlitedOrderedItems = useMemo(
    () =>
      highlightedItems
        ? highlightedItems.concat(list.filter(item => !highlightedItemSet.has(item)))
        : list,
    [highlightedItemSet, highlightedItems]
  );

  const listItems = highlitedOrderedItems.slice(0, maxListItems).map((item, index) => {
    const link = itemToLink(item);
    const content = itemToElementContent(item);
    return (
      <PropertiesPanel.Section.Item
        key={index}
        isRiskItem={title === 'Risks'}
        highlighted={highlightedItemSet.has(item)}>
        {link !== null ? (
          <TextButton to={link} size="xsmall">
            {content}
          </TextButton>
        ) : (
          content
        )}

        {onToggleSearchTerm && itemToSearchTerm && itemToSearchTerm(item) && (
          <Tooltip content="Toggle in search">
            <SectionItemHoverIcon
              name="Search"
              size="xsmall"
              onClick={() => handleAddSearchTerm(item)}
            />
          </Tooltip>
        )}
      </PropertiesPanel.Section.Item>
    );
  });

  if (list.length > maxListItems) {
    listItems.push(
      <PropertiesPanel.Section.Item key="____others">
        and {list.length - listItems.length} others
      </PropertiesPanel.Section.Item>
    );
  }

  return (
    <ClusterPaneCollapsibleCard defaultOpen title={title}>
      {listItems}
    </ClusterPaneCollapsibleCard>
  );
}
