import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import { useInject, useLegacyQueryParams } from '@src-v2/hooks';
import { csvFromArray, csvMapper } from '@src-v2/utils/csv-utils';
import { downloadFile } from '@src-v2/utils/dom-utils';
import { getRepositoryForInventoryPropertyFromProfile } from '@src/components/CodeAggregation/InventoryUtils';
import TableInfiniteScroll from '@src/components/TableInfiniteScroll';

const ClientTableInfiniteScroll = ({
  currentTableScope,
  tableScope,
  items,
  itemsInPages,
  itemsCount,
  filters,
  filterType,
  filterOptions,
  searchTerm,
  clearData,
  fetchPage,
  fetchData,
  allItems,
  filterItemsFunc,
  searchPlaceholder,
  initialFilterOptions,
  headers,
  rowProvider,
  emptyMessage = 'No results found',
  getRowOnClick,
  className,
  allowSearchAndFilter,
  description,
  resultName,
  resultNamePlural,
  countItemsFunction,
  resultDisplay,
  small,
  gridColor,
  customSearchBarSection,
  downloadCsv,
  cellsBackground,
  initializeFromQueryString,
  supportFilterTypeToggle = true,
  scrollParent,
  ...props
}) => {
  const { application } = useInject();
  const query = useLegacyQueryParams();

  useEffect(() => {
    fetchData({
      tableScope,
      items: allItems,
      filterFunction: filterItemsFunc,
      countFunction: countItemsFunction,
      searchTerm,
      filters,
      filterType,
    });
  }, [
    fetchData,
    tableScope,
    allItems,
    filterItemsFunc,
    countItemsFunction,
    searchTerm,
    filters,
    filterType,
  ]);

  useEffect(() => {
    initializeFromQueryString(query);
    return clearData;
  }, []); // eslint-disable-line

  const searchFunc = useCallback(
    pageCount => fetchPage({ currentPage: pageCount, items }),
    [fetchPage, items]
  );

  const resultDisplayFunc = useCallback(resultDisplay, [resultDisplay]);

  const mapFilterSelectedOptions = filterOptions =>
    filterOptions.reduce((selectedMap, filter) => {
      const selectedFilterOptions = filter.options.filter(option => option.isSelected);
      if (selectedFilterOptions.length) {
        selectedMap[filter.componentDisplayName] = selectedFilterOptions.map(
          option => option.displayName
        );
      }
      return selectedMap;
    }, {});

  const downloadCsvFunction =
    downloadCsv &&
    (() => {
      if (application.isDemo) {
        return;
      }

      const usedFilters = mapFilterSelectedOptions(filterOptions);
      const filename = _.kebabCase(
        [downloadCsv.title.toLowerCase(), JSON.stringify(usedFilters)].toString()
      );
      const csvArray = csvMapper(items, {
        ...downloadCsv.columnMap,
        Repository: item =>
          getRepositoryForInventoryPropertyFromProfile(item, downloadCsv.profile)?.name,
      });
      csvArray.unshift(
        [
          `${downloadCsv.title} (${new Date().toLocaleDateString()})`,
          `=HYPERLINK("${window.location.href}", "For the most updated version click here")`,
        ],
        []
      );
      downloadFile(`${filename}.csv`, csvFromArray(csvArray), 'text/csv');
    });

  return tableScope !== currentTableScope ? null : (
    <TableInfiniteScroll
      {...props}
      items={itemsInPages}
      itemsCount={itemsCount}
      totalCount={allItems.length}
      filters={filters}
      filterType={filterType}
      searchTerm={searchTerm}
      headers={headers}
      rowProvider={rowProvider}
      emptyMessage={emptyMessage}
      className={className}
      getRowOnClick={getRowOnClick}
      resultName={resultName}
      resultNamePlural={resultNamePlural}
      searchPlaceholder={searchPlaceholder}
      allowSearchAndFilter={allowSearchAndFilter}
      onFilterChange={_.noop}
      searchFunc={searchFunc}
      initialFilterOptions={initialFilterOptions}
      description={description}
      supportFilterTypeToggle={supportFilterTypeToggle}
      resultDisplay={resultDisplay && resultDisplayFunc}
      small={small}
      gridColor={gridColor}
      customSearchBarSection={customSearchBarSection}
      downloadTable={downloadCsvFunction}
      scrollParent={scrollParent ?? 'main'}
      cellsBackground={cellsBackground}
    />
  );
};

export default ClientTableInfiniteScroll;
