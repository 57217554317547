import { observer } from 'mobx-react';
import { useRouteMatch } from 'react-router-dom';
import { HighlightsCard } from '@src-v2/containers/overview/highlights-card';
import { useSuspense } from '@src-v2/hooks';
import { makeUrl } from '@src-v2/utils/history-utils';

export const ProfileAttackSurfaceHighlights = observer(({ dataFetcher }) => {
  const {
    url,
    params: { key },
  } = useRouteMatch('/profiles/:profileType/:key');
  const { highlights } = useSuspense(dataFetcher, { key });

  return highlights.map(({ dataType, ...highlight }) => {
    switch (dataType) {
      case 'apis':
        return (
          <HighlightsCard
            key={dataType}
            {...highlight}
            title="APIs"
            callToAction="View APIs at risk"
            to={makeUrl(`${url}/inventory/components/apis`, {
              filterType: '"or"',
              filters: JSON.stringify({
                listFilters: {},
                booleanFilters: [
                  'Sensitive',
                  'InternetFacing',
                  'MissingAuthorization',
                  'MissingInputValidation',
                  'ExposesPii',
                  'CheckmarxVulnerabilities',
                ],
              }),
            })}
          />
        );

      case 'sensitiveData':
        return (
          <HighlightsCard
            key={dataType}
            {...highlight}
            title="Data"
            elementType="Fields"
            callToAction="View data at risk"
            to={`${url}/inventory/data`}
          />
        );

      case 'iac':
        return (
          <HighlightsCard
            key={dataType}
            {...highlight}
            title="Cloud infra"
            elementType="Resources"
            callToAction="View IaC at risk"
            to={`${url}/inventory/infrastructure`}
          />
        );

      default:
        console.warn(`unknown attack surface highlight dataType "${dataType}"`);
        return null;
    }
  });
});
