import styled from 'styled-components';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { TimelineEvent } from './TimelineEvent';

export const TimelineBuild = ({
  isLast,
  isFirst,
  index,
  buildTimelineEvent,
  previousTimelineEvent,
}) => {
  const { buildJobId, materialChangesLabels } = buildTimelineEvent;
  const title = `Build includes ${
    materialChangesLabels.length
  } suspicious code change ${pluralFormat(
    materialChangesLabels.length,
    'indication',
    'indications'
  )}`;

  return (
    <TimelineEvent
      currentTimelineEvent={buildTimelineEvent}
      previousTimelineEvent={previousTimelineEvent}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      titleContent={title}
      body={materialChangesLabels.map(materialChangesLabel => (
        <StyledMaterialChange key={materialChangesLabel}>
          {materialChangesLabel}
        </StyledMaterialChange>
      ))}
      exploreUrl={`/build/${buildJobId}`}
      testMarker="timeline-build"
    />
  );
};

const StyledMaterialChange = styled(HorizontalStack)`
  color: var(--default-text-color);
  padding-bottom: 3rem;
`;
