import _ from 'lodash';
import styled from 'styled-components';
import { Markdown } from '@src-v2/components/markdown';

const StyledDiv = styled.div`
  position: relative;
  padding: 2rem;
  margin-top: 3rem;
  margin-bottom: 0;
  white-space: pre;
  border: 1px solid transparent;
  border-radius: 0.5rem;

  a {
    text-decoration: underline;
  }
`;

const AlertMessage = ({ message, ...props }) =>
  message ? (
    <StyledDiv {...props}>
      {_.isString(message) ? <Markdown linkTarget="_blank">{message}</Markdown> : message}
    </StyledDiv>
  ) : null;

export const InfoMessage = styled(AlertMessage)`
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
`;

export const ErrorMessage = styled(AlertMessage)`
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
`;
