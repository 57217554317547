import _ from 'lodash';
import { useMemo } from 'react';
import styled from 'styled-components';
import { CalendarPicker } from '@src-v2/components/filters/inline-control/components/calendar-date-picker-filter-control';
import { useToggle } from '@src-v2/hooks';
import ReactSelectWrapper from './ReactSelect/ReactSelectWrapper';

const DateRangePicker = ({
  selectedDateRange,
  setSelectedDateRange,
  resetSelected,
  dateRangeLabel,
}) => {
  const [isDatePickerOpen, toggleDatePicker] = useToggle();

  const values = useMemo(
    () => (selectedDateRange?.length === 2 ? [{ value: 'dateRange', label: dateRangeLabel }] : []),
    [selectedDateRange, dateRangeLabel]
  );

  return (
    <StyledDayPickerWrapper>
      <StyledReactSelectWrapper
        isMulti
        isSearchable={false}
        value={values}
        options={[]}
        placeholder="Select time range"
        menuIsOpen={false}
        onFocus={() => toggleDatePicker(true)}
        onChange={selectedItems => _.isEmpty(selectedItems) && resetSelected()}
      />
      {isDatePickerOpen && (
        <CalendarPicker
          selectRange
          values={[selectedDateRange]}
          onChange={value => setSelectedDateRange(value)}
        />
      )}
    </StyledDayPickerWrapper>
  );
};

export default DateRangePicker;

const StyledDayPickerWrapper = styled.div`
  margin-bottom: 6rem;
`;

const StyledReactSelectWrapper = styled(ReactSelectWrapper)`
  border: 2px solid var(--color-blue-gray-15);
  border-radius: 100vmax;
`;
