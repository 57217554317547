import { ReactNode, createContext, useContext } from 'react';
import { useInject, useSuspense } from '@src-v2/hooks';
import { DiffableEntityDataModelReference } from '@src-v2/types/data-model-reference/data-model-reference';
import { DependencyElement } from '@src-v2/types/inventory-elements';
import { LeanConsumableProfile } from '@src-v2/types/profiles/lean-consumable-profile';

const context = createContext<{
  cveEntity: any;
  profile: LeanConsumableProfile;
  element: DependencyElement;
}>(null);

export function CveEntityContextProvider({
  cve,
  dataModelReference,
  children,
}: {
  cve: string;
  dataModelReference: DiffableEntityDataModelReference;
  children: ReactNode;
}) {
  const { inventory } = useInject();

  const [element, { relatedProfile }] = useSuspense([
    [
      inventory.getDependencyElement,
      {
        reference: dataModelReference,
      },
    ] as const,
    [inventory.getElementContext, { dataModelReference }] as const,
  ]);

  const cveEntity =
    element.dependencyFindings?.find(dependency => dependency.entityId === cve) ?? {};

  return (
    <context.Provider value={{ cveEntity, profile: relatedProfile, element }}>
      {children}
    </context.Provider>
  );
}

export function useCveEntityContext() {
  return useContext(context);
}
