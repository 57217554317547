import _ from 'lodash';

const clearData = () => ({
  introducedDeveloperProfile: null,
  modifiedDeveloperProfile: null,
  codeOwnerEntity: null,
});
export default {
  state: clearData(),
  selectors: slice => ({
    introducedDeveloperProfile: () =>
      slice(({ introducedDeveloperProfile }) => introducedDeveloperProfile),
    modifiedDeveloperProfile: () =>
      slice(({ modifiedDeveloperProfile }) => modifiedDeveloperProfile),
    codeOwnerEntity: () => slice(({ codeOwnerEntity }) => codeOwnerEntity),
  }),
  reducers: {
    setBulkState: (
      state,
      { introducedDeveloperProfile, modifiedDeveloperProfile, codeOwnerEntity }
    ) => ({
      ...state,
      introducedDeveloperProfile,
      modifiedDeveloperProfile,
      codeOwnerEntity,
    }),
    clearData,
  },

  effects: dispatch => ({
    async fetchData({ repository, element }) {
      const repositoryKey =
        repository?.key || (element.repositoryKeys?.length === 1 && element.repositoryKeys[0]);
      let introducedDeveloperProfile, modifiedDeveloperProfile, codeOwnerEntity;

      if (element.codeOwnerEntity) {
        codeOwnerEntity = {
          profile: await dispatch.developerProfiles.getDeveloperByIdentityKeyAsync({
            key: element.codeOwnerEntity.identityKey,
          }),
          activity: element.codeOwnerEntity.activity,
        };
      }

      if (!_.isEmpty(repositoryKey)) {
        const results = await dispatch.entityProfiles.getCreatorAndLastModifierAsync({
          repositoryKey,
          entityType: element.entityType,
          entityId: element.entityId,
        });
        introducedDeveloperProfile = results.introducedDeveloperProfile;
        modifiedDeveloperProfile = results.modifiedDeveloperProfile;
      }

      this.setBulkState({ introducedDeveloperProfile, modifiedDeveloperProfile, codeOwnerEntity });
    },
  }),
};
