import _ from 'lodash';
import { useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Dropdown } from '@src-v2/components/dropdown';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { SvgIcon } from '@src-v2/components/icons';
import { Gutters } from '@src-v2/components/layout';
import { Page } from '@src-v2/components/layout/page';
import { StickyHeader } from '@src-v2/components/layout/sticky-header';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject } from '@src-v2/hooks';
import { useModalState } from '@src-v2/hooks/use-modal-state';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { PageLoading } from '@src/components/PageLoading';
import { Table } from '@src/components/Table';
import { VerticalStack } from '@src/components/VerticalStack';
import { FontBody, FontBodyHeader } from '@src/style/common';
import { RoleEditModal } from './blocks/RoleEditModal';

const RolesPage = ({
  fetchData,
  clearData,
  roleConfigurations,
  createNew,
  isModalOpen,
  closeEditModal,
  roleToEdit,
  onRoleEdited,
  saveRole,
  isRoleValid,
  searchRepositories,
  isEdit,
  supportedPermissions,
  setEditedRole,
  deleteRole,
  assetConfigurations,
  servers,
  applicationGroupAssetConfigurations,
}) => {
  const { rbac } = useInject();
  const [modalElement, setModal, closeModal] = useModalState();
  const canEditRoles = rbac.canEdit(resourceTypes.Roles);

  useEffect(() => {
    if (isModalOpen) {
      setModal(
        <RoleEditModal
          isValid={isRoleValid}
          isEdit={isEdit}
          onSave={async () => {
            await saveRole(roleToEdit);
            await fetchData();
            closeEditModal();
            closeModal();
          }}
          onCancel={() => {
            closeEditModal();
            closeModal();
          }}
          role={roleToEdit}
          modificationHandlers={onRoleEdited}
          searchRepositories={searchRepositories}
          supportedPermissionByResource={_.groupBy(supportedPermissions, 'resourceDisplayName')}
          assetConfigurations={assetConfigurations}
          applicationGroupAssetConfigurations={applicationGroupAssetConfigurations}
          serverOptions={servers}
        />
      );
    }
  }, [
    isRoleValid,
    isEdit,
    saveRole,
    fetchData,
    roleToEdit,
    onRoleEdited,
    searchRepositories,
    supportedPermissions,
    assetConfigurations,
    applicationGroupAssetConfigurations,
    servers,
  ]);

  useEffect(() => {
    fetchData();
    return clearData;
  }, [fetchData, clearData]);

  const onDeleteRole = useCallback(
    async roleConfiguration => {
      await deleteRole(roleConfiguration);
      await fetchData();
      closeModal();
    },
    [deleteRole, fetchData, closeModal]
  );

  if (_.isNil(supportedPermissions) || _.isNil(roleConfigurations)) {
    return <PageLoading />;
  }

  return (
    <Page title="Roles">
      <StickyHeader isStaticActions>
        <Tooltip content="Contact your admin to create a role" disabled={canEditRoles}>
          <Button size="large" disabled={!canEditRoles} onClick={createNew}>
            Create a Role
          </Button>
        </Tooltip>
      </StickyHeader>

      <Gutters>
        <StyledTable
          as={Table}
          tableIsEmpty={_.isEmpty(roleConfigurations)}
          headers={[
            { name: '', weight: 3 },
            { name: '', weight: 1 },
            { name: '', fixedWidth: 25, align: 'right' },
          ]}
          rows={
            roleConfigurations &&
            roleConfigurations.map(roleConfiguration => {
              const identifiersCount =
                roleConfiguration.identifiers.length +
                (roleConfiguration.apiiroGroups?.length ?? 0);
              return {
                key: roleConfiguration.key,
                cells: [
                  {
                    content: (
                      <StyledDiv>
                        <StyledHeader>{roleConfiguration.name}</StyledHeader>
                        <StyledDescription>{roleConfiguration.description}</StyledDescription>
                      </StyledDiv>
                    ),
                  },
                  {
                    content: (
                      <PopoverTip
                        disabled={identifiersCount === 0}
                        linkText={pluralFormat(
                          identifiersCount,
                          'Assigned group',
                          undefined,
                          true
                        )}>
                        <VerticalStack>
                          {roleConfiguration.identifiers
                            .concat(roleConfiguration?.apiiroGroups?.map(group => group.name) ?? [])
                            .map(name => (
                              <div key={name}>{name}</div>
                            ))}
                        </VerticalStack>
                      </PopoverTip>
                    ),
                  },
                  {
                    content: (
                      <HorizontalStack align="right">
                        <Tooltip
                          content="Contact your admin to edit or delete a role"
                          disabled={canEditRoles}>
                          <DropdownMenu disabled={!canEditRoles}>
                            <>
                              <Dropdown.Item onClick={() => setEditedRole(roleConfiguration)}>
                                <SvgIcon name="Edit" /> Edit
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() =>
                                  setModal(
                                    <DeleteRoleModal
                                      data={roleConfiguration}
                                      onSubmit={onDeleteRole}
                                      onClose={closeModal}
                                    />
                                  )
                                }>
                                <SvgIcon name="Trash" /> Delete
                              </Dropdown.Item>
                            </>
                          </DropdownMenu>
                        </Tooltip>
                      </HorizontalStack>
                    ),
                    allowOverflow: true,
                  },
                ],
              };
            })
          }
        />
      </Gutters>

      {modalElement}
    </Page>
  );
};

export default RolesPage;

const DeleteRoleModal = ({ data, onSubmit, ...props }) => (
  <ConfirmationModal
    {...props}
    title="Delete role"
    submitStatus="failure"
    submitText="Delete"
    onSubmit={useCallback(() => onSubmit(data), [data, onSubmit])}>
    Are you sure you want to delete this role?
  </ConfirmationModal>
);

const StyledDiv = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledTable = styled(Gutters)`
  padding-top: 5rem;
`;

const StyledHeader = styled.div`
  ${FontBodyHeader};
`;

const StyledDescription = styled.div`
  ${FontBody};
`;
