import { VendorIcon } from '@src-v2/components/icons';

export const GatewayTypeIcon = ({ provider }) => {
  switch (provider) {
    case 'AzureCloud':
      return <VendorIcon name="AzureCloud" />;
    case 'SpringApiGateway':
      return <VendorIcon name="Spring" />;
    case 'TykApiGateway':
      return <VendorIcon name="Tyk" />;
    case 'NginxController':
      return <VendorIcon name="Nginx" />;
    case 'AWS':
      return <VendorIcon name="AWS" />;
    default:
      return null;
  }
};
