import { useEffect, useState } from 'react';
import { usePaneState } from '@src-v2/components/panes/pane-context-provider';
import { CveEntityPane } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-pane';
import { useQueryParams } from '@src-v2/hooks';
import { DiffableEntityDataModelReference } from '@src-v2/types/data-model-reference/data-model-reference';

export const useOpenCvePane = (dataModelReference: DiffableEntityDataModelReference) => {
  const { pushPane } = usePaneState();
  const { queryParams, updateQueryParams } = useQueryParams();
  const [showCvePane, setShowCvePane] = useState(false);

  useEffect(() => {
    if (queryParams.cve) {
      pushPane(
        <CveEntityPane
          dataModelReference={dataModelReference}
          cve={queryParams.cve.toString()}
          onClose={() => {
            updateQueryParams({ trigger: null, pane: null, cve: null });
          }}
          onPop={() => {
            updateQueryParams({ cve: null });
          }}
        />
      );
      setShowCvePane(true);
    } else {
      setShowCvePane(false);
    }
  }, [queryParams.cve, dataModelReference, pushPane, updateQueryParams]);

  return showCvePane;
};
