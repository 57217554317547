import _ from 'lodash';
import { useCallback } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { ClampText } from '@src-v2/components/clamp-text';
import { BaseIcon } from '@src-v2/components/icons';
import { ErrorLayout } from '@src-v2/components/layout';
import { Table } from '@src-v2/components/table/table';
import { TableHeader } from '@src-v2/components/table/table-header';
import { Paragraph } from '@src-v2/components/typography';
import { RiskIconCell, SourceCell, TeamsCell } from '@src-v2/containers/risks/risks-common-cells';
import { useInject, useSuspense } from '@src-v2/hooks';
import { useTable } from '@src-v2/hooks/use-table';
import { DevPhase } from '@src-v2/types/enums/dev-phase';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { makeUrl } from '@src-v2/utils/history-utils';

export const ProfileRisksOverviewTable = ({ profileType, profile }) => {
  const { risks } = useInject();
  const history = useHistory();
  const tableModel = useTable({
    tableColumns,
    hasReorderColumns: false,
  });

  const isTeamsPage = Boolean(useRouteMatch('/profiles/teams'));
  const profileTypeName = getprofileTypeName(profileType, isTeamsPage);
  const profileTypeUrl = getProfileTypeUrl(profileType, isTeamsPage);

  const navigateToRiskTable = useCallback(
    (ruleName, devPhase, riskLevel) => {
      let categoryUrl = 'development';
      if (devPhase === DevPhase.Design || devPhase === DevPhase.SupplyChain) {
        categoryUrl = _.camelCase(devPhase);
      }

      const url = makeUrl(`/profiles/${profileTypeUrl}/${profile.key}/risk/${categoryUrl}`, {
        fl: { searchTerm: ruleName, RiskLevel: { values: [riskLevel] } },
      });
      history.push(url);
    },
    [profileType, profile.key]
  );

  const tableRows = useSuspense(risks.getProfileRiskOverview, {
    profileKey: profile.key,
    profileType,
    moduleRoot: profile.module,
  });

  return (
    <StyledOverviewTable>
      <Paragraph>
        {`View the summarized risk and rule information per ${profileTypeName}`}
      </Paragraph>
      <Table>
        <TableHeader tableModel={tableModel} />
        <Table.Body>
          {tableRows?.length === 0 ? (
            <Table.EmptyMessage colSpan={tableColumns.length}>
              <ErrorLayout.NoResults data-contained />
            </Table.EmptyMessage>
          ) : (
            tableRows.map(ruleRiskTriggers => (
              <Table.Row
                key={ruleRiskTriggers.ruleKey}
                onClick={() =>
                  navigateToRiskTable(
                    ruleRiskTriggers.ruleName,
                    ruleRiskTriggers.devPhase,
                    ruleRiskTriggers.riskLevel
                  )
                }>
                {tableModel.columns?.map(({ Cell, ...column }) => (
                  <Cell key={column.label} data={ruleRiskTriggers} />
                ))}
              </Table.Row>
            ))
          )}
        </Table.Body>
      </Table>
    </StyledOverviewTable>
  );
};

const tableColumns = [
  {
    label: 'Risk level',
    width: '25rem',
    Cell: props => <RiskIconCell {...props} />,
  },
  {
    label: 'Policy name',
    Cell: ({ data, ...props }) => (
      <Table.Cell {...props}>
        <ClampText lines={2}>{data.ruleName}</ClampText>
      </Table.Cell>
    ),
  },
  {
    label: 'Rule category',
    width: '50rem',
    Cell: ({ data, ...props }) => (
      <Table.Cell {...props}>
        <ClampText lines={2}>{data.riskCategory}</ClampText>
      </Table.Cell>
    ),
  },
  {
    label: 'Total',
    width: '24rem',
    Cell: ({ data, ...props }) => <Table.Cell {...props}>{data.count}</Table.Cell>,
  },
  {
    label: 'Accepted',
    width: '24rem',
    Cell: ({ data, ...props }) => <Table.Cell {...props}>{data.accepted}</Table.Cell>,
  },
  {
    label: 'Ignored',
    width: '24rem',
    Cell: ({ data, ...props }) => <Table.Cell {...props}>{data.ignored}</Table.Cell>,
  },
  {
    label: 'Sources',
    width: '22rem',
    Cell: SourceCell,
  },
  {
    key: 'teams',
    label: 'Teams',
    Cell: TeamsCell,
    betaFeature: FeatureFlag.OrgTeams,
  },
];

const StyledTableRow = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  padding: 0 4rem;
  background-color: ${props => (props.$cellsBackground ? 'transparent' : 'var(--color-white)')};
  cursor: ${props => (props.onClick ? 'pointer' : 'unset')};

  ${BaseIcon} {
    color: var(--color-blue-gray-50);

    &:hover {
      color: var(--color-blue-gray-60);
    }
  }
`;

const StyledOverviewTable = styled.div`
  margin-bottom: 10rem;

  ${StyledTableRow} {
    padding: 0 2rem;
  }

  > ${Paragraph} {
    margin: 4rem 2rem;

    &:first-child {
      display: flex;
      height: 9rem;
      align-items: center;
      justify-content: space-between;
    }
  }
`;

const getprofileTypeName = (profileType, isTeamsPage) => {
  if (isTeamsPage) {
    return 'team';
  }
  if (profileType === 'ApplicationProfile') {
    return 'application';
  }
  return 'repository';
};

const getProfileTypeUrl = (profileType, isTeamsPage) => {
  if (isTeamsPage) {
    return 'teams';
  }
  if (profileType === 'ApplicationProfile') {
    return 'applications';
  }
  return 'repositories';
};
