import { useCallback } from 'react';
import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { FeedbackModal } from '@src-v2/containers/modals/feedback-modal';
import { useModalState } from '@src-v2/hooks/use-modal-state';

export const BetaBadge = styled(({ featureName, onClick, ...props }) => {
  const [modalElement, setModal, closeModal] = useModalState();
  const handleClick = useCallback(
    event => {
      setModal(<FeedbackModal featureName={featureName} onClose={closeModal} />);
      onClick?.(event);
    },
    [onClick]
  );
  return (
    <>
      <Tooltip content="This is a beta feature, we would love to hear your feedback">
        <Button {...props} variant={Variant.PRIMARY} onClick={handleClick}>
          Beta
        </Button>
      </Tooltip>
      {modalElement}
    </>
  );
})`
  height: 7rem;
`;
