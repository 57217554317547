import styled from 'styled-components';
import { LanguageCircle } from '@src-v2/components/circles';
import { Size } from '@src-v2/components/types/enums/size';
import { ExposurePathNodeType } from '@src-v2/types/exposure-path';

export const InfoNodeTooltip = ({ nodeDatum }: { nodeDatum: ExposurePathNodeType }) => {
  const getProperty = (key: string) =>
    nodeDatum.propertiesSections
      .find(section => section.key === 'match_reasoning')
      ?.properties.find(prop => prop.key === key);

  const properties = {
    methods: getProperty('matched_properties')?.text,
    technology: getProperty('language')?.text,
    packageManager: getProperty('package_manager')?.text,
    repoPath: getProperty('relevant_repository_path')?.text,
    containerPath: getProperty('relevant_container_path')?.text,
  };

  return (
    <InfoNodeTooltipContent>
      <InfoNodeTooltipRow>
        Apiiro detected a match between the repository and the container image, based on:
      </InfoNodeTooltipRow>
      <InfoNodeTooltipRow>Methods: {properties.methods || ''}</InfoNodeTooltipRow>
      <InfoNodeTooltipRow>
        Technology:{' '}
        {properties.technology && (
          <LanguageCircle name={properties.technology} size={Size.XSMALL} />
        )}{' '}
        {properties.technology} {properties.packageManager}
      </InfoNodeTooltipRow>
      <InfoNodeTooltipRow>Path in repository: {properties.repoPath || ''}</InfoNodeTooltipRow>
      <InfoNodeTooltipRow>
        Path in container image: {properties.containerPath || ''}
      </InfoNodeTooltipRow>
    </InfoNodeTooltipContent>
  );
};

const InfoNodeTooltipContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

const InfoNodeTooltipRow = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;
