import styled from 'styled-components';

export const DetailsHeading = styled.h4`
  width: 100%;
  margin: 24px 0 12px;
  color: #6d6d6d;

  &:first-of-type {
    margin-top: 0;
  }
`;
