import { useCombobox } from 'downshift';
import _ from 'lodash';
import { useCallback, useEffect, useMemo } from 'react';

export const creatableSymbol = Symbol('creatable');

export function defaultItemToString(item) {
  return typeof item === 'string' ? item : item?.label ?? item?.name ?? '';
}

export function useExtendedCombobox({
  ref,
  items,
  itemsFilter,
  itemToString = defaultItemToString,
  initialHighlightedIndex = 0,
  defaultSelectedItem,
  getDropdownProps,
  ...options
}) {
  const { itemsFlatten, itemsGrouped } = useItemsNormalizer(
    itemsFilter ? items.filter(itemsFilter) : items,
    itemToString
  );

  defaultSelectedItem = useMemo(
    () => itemsFlatten.find(item => item === defaultSelectedItem) ?? defaultSelectedItem,
    [itemsFlatten, defaultSelectedItem]
  );

  useEffect(() => {
    // _.isEqual is used because useForm will clone the value, overriding its ref
    if (!_.isEqual(defaultSelectedItem, comboboxProps.selectedItem)) {
      comboboxProps.selectItem(defaultSelectedItem ?? null);
    }
  }, [defaultSelectedItem]);

  const comboboxProps = useCombobox({
    ...options,
    itemToString,
    items: itemsFlatten,
    defaultSelectedItem,
    initialHighlightedIndex,
  });

  const getItemProps = useCallback(
    (item, options) =>
      comboboxProps.getItemProps({
        index: item.index,
        highlight: comboboxProps.highlightedIndex === item.index,
        selected: comboboxProps.selectedItem?.value === item.value,
        ...options,
        item,
      }),
    [comboboxProps.getItemProps, comboboxProps.selectedItem, comboboxProps.highlightedIndex]
  );

  const getInputProps = useCallback(
    options => {
      const dropdownProps = getDropdownProps?.({ ref });
      return comboboxProps.getInputProps({
        ref,
        ...options,
        autoCorrect: 'off',
        spellCheck: 'false',
        onMouseDown(event) {
          comboboxProps.isOpen ? comboboxProps.closeMenu() : comboboxProps.openMenu();
          options?.onMouseDown?.(event);
        },
        onChange:
          options?.onChange ??
          (event => {
            if (!event.target.value) {
              comboboxProps.selectItem(null);
            }
          }),
        ...dropdownProps,
        onKeyDown(event) {
          if (event.target?.value === '' && event.key === 'Backspace') {
            return null;
          }
          dropdownProps?.onKeyDown(event);
        },
      });
    },
    [
      comboboxProps.isOpen,
      comboboxProps.closeMenu,
      comboboxProps.openMenu,
      comboboxProps.selectItem,
      getDropdownProps,
    ]
  );

  return { ...comboboxProps, itemsGrouped, getItemProps, getInputProps };
}

function useItemsNormalizer(items, itemToString) {
  return useMemo(() => {
    let index = 0;

    const createOption = item => ({
      index: index++,
      label: itemToString(item),
      value: item,
    });

    const itemsGrouped = [];
    const itemsFlatten = items.flatMap(item => {
      if (item?.options) {
        const options = item.options.map(createOption);
        if (options.length) {
          itemsGrouped.push({ label: itemToString(item), options });
        }
        return options.map(option => option.value);
      }
      const option = createOption(item);
      itemsGrouped.push(option);
      return option.value;
    });

    return { itemsFlatten, itemsGrouped };
  }, [items, itemToString]);
}
