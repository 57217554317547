import { forwardRef, useCallback, useState } from 'react';
import { useForwardRef } from '@src-v2/hooks';

export const OptionalTitle = forwardRef(
  ({ as: Component = 'span', title, onMouseEnter, ...props }, forwardRef) => {
    const [shouldDisplay, setShouldDisplay] = useState(false);
    const ref = useForwardRef(forwardRef);

    if (typeof props.children === 'string') {
      title ??= props.children;
    }

    const handleMouseEnter = useCallback(
      event => {
        setShouldDisplay(ref.current?.offsetWidth < ref.current?.scrollWidth);
        onMouseEnter?.(event);
      },
      [ref, setShouldDisplay, onMouseEnter]
    );

    return (
      <Component
        ref={ref}
        {...props}
        title={shouldDisplay ? title : undefined}
        onMouseEnter={handleMouseEnter}
      />
    );
  }
);
