import styled from 'styled-components';
import { pluralFormat } from '@src-v2/utils/number-utils';
import { HorizontalStack } from '@src/components/HorizontalStack';

export const SensitiveDataDefinition = ({ definition }) => (
  <HorizontalStack withSeparator key={definition.key}>
    <Field>Custom sensitive data</Field>
    <Field>
      {definition.termsCount} {pluralFormat(definition.termsCount, 'term', 'terms')}
    </Field>
  </HorizontalStack>
);

const Field = styled.span``;
