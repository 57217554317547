import _ from 'lodash';
import { useCallback, useMemo } from 'react';
import { useInject, useSuspense } from '@src-v2/hooks';
import { ChartView, riskForNode } from '@src/cluster-map-work/components/charts/chart-view';
import { clusterChartViewPresentationConfig } from '@src/cluster-map-work/components/charts/cluster-chart/cluster-object-visuals';
import {
  useChartSearchState,
  useServerFetchedChartState,
} from '@src/cluster-map-work/components/charts/server-chart-state';
import { ClusterResourceInfoPanel } from './cluster-resource-info-panel';

export function ServerFetchingClusterDisplayChart({
  clusterKey,
  serverFetchedClusterChartState,
  initialShowProperties,
  chartProps,
}) {
  const { serverFetchedChartState, clusterProfile } = serverFetchedClusterChartState;
  const handleToggleSearchTerm = useCallback(
    searchTermToAdd => {
      serverFetchedChartState.chartSearchState?.graphSearchState.toggleSearchOverlayValue(
        searchTermToAdd.overlay,
        searchTermToAdd.value
      );
    },
    [serverFetchedChartState.chartSearchState?.graphSearchState]
  );

  const handleCreatePropertiesPane = useCallback(
    (selectedNode, onHandleClose) => {
      const selectedNodeSearchResult =
        selectedNode &&
        serverFetchedChartState.chartSearchState?.searchResults?.find(
          searchResult => searchResult.node === selectedNode.id
        );

      return (
        <ClusterResourceInfoPanel
          clusterResource={selectedNode?.resource}
          clusterResourceRisk={riskForNode(serverFetchedChartState.nodeRiskSummary, selectedNode)}
          clusterProfile={clusterProfile}
          clusterKey={clusterKey}
          onToggleSearchTerm={serverFetchedChartState.chartSearchState && handleToggleSearchTerm}
          highlightsList={selectedNodeSearchResult?.results ?? []}
          onHandleClose={onHandleClose}
        />
      );
    },
    [
      clusterKey,
      clusterProfile,
      handleToggleSearchTerm,
      serverFetchedChartState.chartSearchState?.searchResults,
      serverFetchedChartState.nodeRiskSummary,
    ]
  );

  return (
    <ChartView
      chartViewPresentationConfig={clusterChartViewPresentationConfig}
      graphData={serverFetchedChartState.graphData}
      nodeRiskSummary={serverFetchedChartState.nodeRiskSummary}
      highlightsList={serverFetchedChartState.highlightsList}
      externalSelectedNode={serverFetchedChartState.externalSelectedNode}
      initialShowProperties={initialShowProperties}
      onCreatePropertiesPane={handleCreatePropertiesPane}
      {...chartProps}
    />
  );
}

export function useServerFetchingClusterChart({
  clusterKey,
  chartSearchState,
  chartFilterState,
  initialSelectedClusterResourceName,
}) {
  const { clusters } = useInject();
  const fetchArgs = useMemo(() => ({ clusterKey, clusters }), [clusterKey, clusters]);

  const clusterProfile = useSuspense(clusters.getClusterInfo, { clusterKey });

  return {
    serverFetchedChartState: useServerFetchedChartState({
      fetchArgs,
      loadGraphData,
      loadNodeRiskSummary,
      chartSearchState,
      chartFilterState,
      initialSelectedNode: JSON.stringify(initialSelectedClusterResourceName),
    }),
    clusterProfile,
  };
}

export function useClusterChartSearchState({ clusterKey }) {
  const { clusters } = useInject();
  const fetchArgs = useMemo(() => ({ clusterKey, clusters }), [clusterKey, clusters]);

  return useChartSearchState({ getSearchResults, fetchArgs });
}

async function getSearchResults({ fetchArgs: { clusters, clusterKey }, searchTerm, cancelToken }) {
  const clusterSearchResults = await clusters.searchGraphNodes(
    { clusterKey, searchTerm },
    { cancelToken }
  );
  return Object.entries(
    _.groupBy(clusterSearchResults, clusterSearchResult =>
      JSON.stringify(clusterSearchResult.matchedResourceName.clusterResourceName)
    )
  ).map(([node, results]) => ({
    node,
    results,
  }));
}

async function loadGraphData({ filters, fetchArgs }) {
  const clusterMap = await fetchArgs.clusters.getClusterMap({
    clusterKey: fetchArgs.clusterKey,
    ns: filters?.Namespaces?.values,
    nodeTypes: filters?.NodeTypes?.value,
  });

  return createGraphDataFromClusterMap(clusterMap);
}

async function loadNodeRiskSummary({ fetchArgs: { clusters, clusterKey } }) {
  const riskSummaryOverlay = await clusters.getRiskSummaryOverlay({ clusterKey });
  const riskSummary = {};

  riskSummaryOverlay['risk-triggers-summary'].forEach(clusterResourceRiskSummaryEntry => {
    const entryResourceName = JSON.stringify(
      clusterResourceRiskSummaryEntry.clusterResourceName.clusterResourceName
    );
    riskSummary[entryResourceName] = clusterResourceRiskSummaryEntry;
  });

  return riskSummary;
}

function createGraphDataFromClusterMap(clusterMap) {
  const clusterNodes = clusterMap.resources.map(resource => ({
    id: JSON.stringify(resource.name),
    name: resource.name.name,
    nodeType: resource.resourceType,
    resource,
  }));

  const clusterLinks = clusterMap.links.map(link => ({
    source: JSON.stringify(link.fromName),
    target: JSON.stringify(link.toName),
    linkType: link.linkType,
  }));

  const linkedGraphNodes = _.keyBy(
    clusterNodes.map(node => ({ id: node.id, node, neighbours: [] })),
    n => n.id
  );

  clusterLinks.forEach(link => {
    linkedGraphNodes[link.source].neighbours.push(linkedGraphNodes[link.target]);
    linkedGraphNodes[link.target].neighbours.push(linkedGraphNodes[link.source]);
  });

  return {
    nodes: clusterNodes,
    links: clusterLinks,
    linkedNodes: linkedGraphNodes,
  };
}
