import map from 'lodash/map';
import styled from 'styled-components';
import { ExternalLink } from '@src-v2/components/typography';
import { HorizontalStack } from '@src/components/HorizontalStack';
import ServerIcon from '@src/components/ServerIcon';
import { TimelineEvent } from './TimelineEvent';

const StyledIssueTitle = styled.span`
  font-style: italic;
`;

const StyledMaterialChange = styled(HorizontalStack)`
  color: var(--default-text-color);
  padding-bottom: 3rem;
`;

const materialChangesBody = labels => (
  <>
    {map(labels, materialChangesLabel => (
      <StyledMaterialChange key={materialChangesLabel}>{materialChangesLabel}</StyledMaterialChange>
    ))}
  </>
);

export const TimelineRiskyIssue = ({
  isLast,
  isFirst,
  index,
  riskyIssueTimelineEvent,
  previousTimelineEvent,
  projectProfilesByKey,
}) => {
  const issueProject = projectProfilesByKey[riskyIssueTimelineEvent.projectKeys[0]];
  const riskyIssueTitle = (
    <>
      Issue
      <ExternalLink href={riskyIssueTimelineEvent.issueExternalUrl}>
        {riskyIssueTimelineEvent.issueOrdinalId}
      </ExternalLink>
      <StyledIssueTitle> "{riskyIssueTimelineEvent.issueDescription}"</StyledIssueTitle> in{' '}
      {issueProject.name}
      <ServerIcon size="small" provider={issueProject.provider} />
    </>
  );

  const riskyIssueBody = materialChangesBody(riskyIssueTimelineEvent.materialChangesLabels);

  return (
    <TimelineEvent
      currentTimelineEvent={riskyIssueTimelineEvent}
      previousTimelineEvent={previousTimelineEvent}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      titleContent={riskyIssueTitle}
      body={riskyIssueBody}
      testMarker="timeline-issue"
    />
  );
};

export const TimelineRiskyIssueV3 = ({
  isLast,
  isFirst,
  index,
  riskyIssueTimelineEvent,
  previousTimelineEvent,
  projectProfilesByKey,
}) => {
  const issueProject = projectProfilesByKey[riskyIssueTimelineEvent.projectKeys[0]];
  const riskyIssueTitle = (
    <>
      Ticket
      <ExternalLink href={riskyIssueTimelineEvent.issueExternalUrl}>
        {riskyIssueTimelineEvent.issueOrdinalId}
      </ExternalLink>
      <StyledIssueTitle> "{riskyIssueTimelineEvent.issueDescription}"</StyledIssueTitle> in{' '}
      {issueProject.name}
      <ServerIcon size="small" provider={issueProject.provider} />
    </>
  );

  const riskyIssueBody = materialChangesBody(riskyIssueTimelineEvent.materialChangesLabels);

  return (
    <TimelineEvent
      currentTimelineEvent={riskyIssueTimelineEvent}
      previousTimelineEvent={previousTimelineEvent}
      index={index}
      isFirst={isFirst}
      isLast={isLast}
      titleContent={riskyIssueTitle}
      body={riskyIssueBody}
      testMarker="timeline-issue"
    />
  );
};
