import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Heading, Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';
import { thenSubTypeToVerb } from '@src-v2/containers/commit/material-changes-utils';
import { pluralFormat } from '@src-v2/utils/number-utils';

const rbacTypeToDisplayString = {
  MethodAuthorization: 'To authorization method of',
  SecurityConfiguration: 'To security configuration',
  Swagger: 'To Swagger definition',
};

const rbacTypeToEntity = {
  MethodAuthorization: 'Method',
  SecurityConfiguration: 'Security configuration',
  Swagger: 'Swagger definition',
};

const UsageIndication = ({
  usageIndicationData,
  repository,
  commitSha,
  rbacTypeToEntity,
  rbacTypeToDisplayString,
}) => {
  const {
    rbacType,
    usages: [firstIndication, ...remainingIndications],
  } = usageIndicationData;
  return (
    <>
      <Heading>
        <Paragraph>{rbacTypeToDisplayString[rbacType]}</Paragraph>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={firstIndication.relativeFilePath}>
          {rbacType === 'MethodAuthorization' ? firstIndication.methodName : firstIndication.name}
        </CommitCodeReference>
      </Heading>
      {remainingIndications.length > 0 ? (
        <MaterialChangesRow>
          <Paragraph>and</Paragraph>
          <Tooltip
            content={pluralFormat(
              remainingIndications.length,
              `more ${rbacTypeToEntity[rbacType].toLowerCase()}`,
              null,
              true
            )}>
            {remainingIndications.map((indication, index) => (
              <CommitCodeReference
                key={index}
                repository={repository}
                commitSha={commitSha}
                relativeFilePath={indication.relativeFilePath}>
                {rbacType === 'MethodAuthorization' ? indication.methodName : indication.name}
              </CommitCodeReference>
            ))}
          </Tooltip>
        </MaterialChangesRow>
      ) : null}
    </>
  );
};

export const RbacRoleMaterialChange = ({ materialChange, thenSubType, repository, commitSha }) => {
  const { name: roleName, usageIndications } = materialChange;

  return (
    <>
      <MaterialChangesRow>
        <Paragraph>API Role</Paragraph>
        <Strong>{roleName}</Strong>
        <Paragraph> was </Paragraph>
        <Paragraph>{thenSubTypeToVerb(thenSubType)}</Paragraph>
      </MaterialChangesRow>

      {usageIndications.map(usageIndicationData => (
        <UsageIndication
          usageIndicationData={usageIndicationData}
          repository={repository}
          commitSha={commitSha}
          rbacTypeToEntity={rbacTypeToEntity}
          rbacTypeToDisplayString={rbacTypeToDisplayString}
        />
      ))}
    </>
  );
};
