import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { SearchInput } from '@src-v2/components/forms/search-input';
import { InsightTag } from '@src-v2/components/tags';
import { TagsLimiter } from '@src-v2/components/tags-limiter';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Heading5, NavLink } from '@src-v2/components/typography';
import { useDebounce, useResizeObserver } from '@src-v2/hooks';
import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { CircularSvgIconButton } from '@src/cluster-map-work/components/CircularSvgIconButton';
import { ChartViewPresentationConfigLegend } from '@src/cluster-map-work/components/charts/chart-view-presentation-config-legend';
import {
  ServerFetchingClusterDisplayChart,
  useServerFetchingClusterChart,
} from '@src/cluster-map-work/components/charts/cluster-chart/cluster-chart';
import { clusterChartViewPresentationConfig } from '@src/cluster-map-work/components/charts/cluster-chart/cluster-object-visuals';
import { InChartHeaderControls } from '@src/cluster-map-work/components/charts/graph-utils';
import { GroupedList } from '@src/cluster-map-work/components/grouped-list';
import { HorizontalStack } from '@src/components/HorizontalStack';

export function ClusterLocationsView({ clusterLocations, selectedNode }) {
  const [selectedLocation, setSelectedLocation] = useState([]);
  const locationViewContainer = useRef(null);
  const [legendWidth, setLegendWidth] = useState('150rem');

  useResizeObserver(
    locationViewContainer,
    useCallback(
      ({ contentRect }) => {
        const suggestedLegendWidth = `${contentRect.width - 130}px`;
        if (suggestedLegendWidth !== legendWidth) {
          setLegendWidth(suggestedLegendWidth);
        }
      },
      [legendWidth, setLegendWidth]
    )
  );

  useEffect(() => {
    if (clusterLocations?.length) {
      const selectedResource = clusterLocations.find(
        clusterLocation =>
          selectedNode === clusterLocation.nodeResourceName.clusterResourceName.name
      );
      setSelectedLocation(
        selectedResource?.nodeResourceName ?? clusterLocations[0].nodeResourceName
      );
    }
  }, [clusterLocations]);

  const openClusterMapLink = useMemo(() => {
    const selectedNodeQueryParam = encodeURIComponent(
      JSON.stringify(selectedLocation.clusterResourceName)
    );
    return `/inventory/clusters/${selectedLocation.clusterKey}?showProperties=true&selected=${selectedNodeQueryParam}`;
  });

  return (
    <ClusterLocationViewContainer ref={locationViewContainer}>
      <ClusterLocationList
        clusterLocations={clusterLocations}
        onSelectClusterLocation={setSelectedLocation}
        selectedClusterLocation={selectedLocation}
      />

      <ClusterChartContainer>
        {(selectedLocation?.clusterKey && (
          <AsyncBoundary>
            <ClusterLocationClusterChart
              clusterKey={selectedLocation.clusterKey}
              initialSelectedClusterResourceName={selectedLocation.clusterResourceName}
              chartProps={{
                overviewSize: { width: '28rem', height: '28rem' },
              }}
            />
            <InChartHeaderControls>
              <ClusterChartTopControlsStack>
                <Tooltip content="Show in cluster map">
                  <NavLink to={openClusterMapLink}>
                    <CircularSvgIconButton name="External" />
                  </NavLink>
                </Tooltip>
                <ChartViewPresentationConfigLegend
                  chartViewPresentationConfig={clusterChartViewPresentationConfig}
                  maxLegendWidth={legendWidth}
                  compact
                />
              </ClusterChartTopControlsStack>
            </InChartHeaderControls>
          </AsyncBoundary>
        )) || <div>&nbsp;</div>}
      </ClusterChartContainer>
    </ClusterLocationViewContainer>
  );
}

function ClusterLocationList({
  clusterLocations,
  selectedClusterLocation,
  onSelectClusterLocation,
}) {
  const [locationSearchText, setLocationSearchText] = useState(null);

  const filteredLocations = useMemo(() => {
    const searchTextLower = locationSearchText && locationSearchText.toLowerCase();

    const filteredLocations = clusterLocations.filter(
      location =>
        !searchTextLower ||
        [
          location.clusterName,
          location.nodeResourceName.clusterResourceName.name,
          location.nodeResourceName.clusterResourceName.ns,
          location.nodeResourceName.clusterResourceName.typeName,
        ].find(soughtText => soughtText.toLowerCase().includes(searchTextLower))
    );

    return filteredLocations.sort(
      (a, b) =>
        a.clusterName.localeCompare(b.clusterName) ||
        a.nodeResourceName.clusterResourceName.ns.localeCompare(
          b.nodeResourceName.clusterResourceName.ns
        ) ||
        a.nodeResourceName.clusterResourceName.name.localeCompare(
          b.nodeResourceName.clusterResourceName.name
        ) ||
        0
    );
  }, [clusterLocations, locationSearchText, selectedClusterLocation]);

  const clusterLocationGroups = useMemo(
    () =>
      _.groupBy(filteredLocations, clusterNodeLink => clusterNodeLink.nodeResourceName.clusterKey),
    [filteredLocations]
  );

  const clusterLocationItems = useMemo(
    () =>
      Object.entries(clusterLocationGroups).map(([clusterKey, clusterNodeLinks]) => {
        return (
          <GroupedList.Group
            key={clusterKey}
            title={`${clusterNodeLinks[0].clusterName} (${clusterNodeLinks.length})`}
            initialExpand={clusterNodeLinks.find(
              link => selectedClusterLocation === link.nodeResourceName
            )}>
            {clusterNodeLinks.map(clusterNodeLink => (
              <ClusterLocationItem
                key={JSON.stringify(clusterNodeLink.nodeResourceName)}
                onClick={() => onSelectClusterLocation(clusterNodeLink.nodeResourceName)}
                selected={selectedClusterLocation === clusterNodeLink.nodeResourceName}
                nodeLink={clusterNodeLink}
              />
            ))}
          </GroupedList.Group>
        );
      }),

    [filteredLocations]
  );

  const handleSearchChange = useDebounce(({ target }) => setLocationSearchText(target?.value), {
    wait: 200,
  });

  return (
    <>
      <StyledLocationListHeader>
        <SearchInput placeholder="Search location name" onChange={handleSearchChange} />
        <StyledNumLocations>
          {`${filteredLocations.length} resources in ${
            Object.values(clusterLocationGroups).length
          } clusters`}
        </StyledNumLocations>
      </StyledLocationListHeader>
      <StyledGroupedList>{clusterLocationItems}</StyledGroupedList>
    </>
  );
}

function ClusterLocationItem({ onClick, selected, nodeLink }) {
  const resourceName = nodeLink.nodeResourceName.clusterResourceName;
  const insights = nodeLink.insights.map(insight => ({
    badge: insight.displayLabel,
    description: insight.description,
  }));

  return (
    <StyledGroupedListItem className={selected ? 'selected' : ''} onClick={onClick}>
      <HorizontalStack>
        <ClusterLocationDescription>
          {addInterpunctSeparator(resourceName.name, resourceName.ns, resourceName.typeName)}
        </ClusterLocationDescription>

        <TagsLimiter maxSize={2}>
          {insights.map(insight => (
            <InsightTag insight={insight} />
          ))}
        </TagsLimiter>
      </HorizontalStack>
    </StyledGroupedListItem>
  );
}

function ClusterLocationClusterChart({
  clusterKey,
  initialSelectedClusterResourceName,
  chartProps,
}) {
  const chartState = useServerFetchingClusterChart({
    clusterKey,
    initialSelectedClusterResourceName,
  });

  return (
    <ServerFetchingClusterDisplayChart
      clusterKey={clusterKey}
      chartProps={chartProps}
      serverFetchedClusterChartState={chartState}
    />
  );
}

const StyledGroupedListItem = styled(GroupedList.Item)`
  &.selected {
    background: var(--color-blue-25);
    box-shadow: var(--elevation-1);
    border-color: (--color-blue-35);
  }
`;

const StyledGroupedList = styled(GroupedList)`
  max-height: 70rem;
  min-height: 28rem;
`;

const ClusterLocationViewContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  flex-grow: 1;
`;

const ClusterChartContainer = styled.div`
  position: relative;
  flex-grow: 2;
  min-height: 95rem;
  margin-top: 6rem;
  background: white;
  border: 0.25rem solid var(--color-blue-gray-30);
  border-radius: var(--table-border-radius, 5rem);
`;

const StyledLocationListHeader = styled(HorizontalStack)`
  margin: 5rem 0;
`;

const StyledNumLocations = styled.div`
  flex-grow: 1;
  text-align: right;
  font-weight: 400;
`;

const ClusterLocationDescription = styled(Heading5)`
  margin: 0 4rem;
  font-weight: 400;
`;

const ClusterChartTopControlsStack = styled.div`
  display: flex;
  flex-direction: row;
  margin: 6rem;
  gap: 2rem;

  ${CircularSvgIconButton} {
    height: 8rem;
    width: 8rem;
    box-shadow: var(--elevation-4);
  }
`;
