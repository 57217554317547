import { useInject } from '@src-v2/hooks';
import { StatisticsBreakdown } from './StatisticsBreakdown';

export const ApisBreakdown = ({ profileStatistics }) => {
  const { application } = useInject();

  const statisticsKeysAndNames = [
    {
      key: 'sensitiveApiCount',
      name: 'Sensitive',
    },
    {
      key: 'exposesPiiApiCount',
      name: 'Expose PII',
    },
    {
      key: 'involvesPiiApiCount',
      name: 'Involve PII',
    },
    {
      key: 'internetFacingApiCount',
      name: 'Internet Facing',
    },
    {
      key: 'securityVulnerabilitiesApisCount',
      name: 'With 3rd Party Security Vulnerabilities',
      display: Boolean(profileStatistics.securityVulnerabilitiesApisCount),
    },
    {
      key: 'apisBehindApiGateway',
      name: 'Behind API Gateway',
      display: application.integrations.connectedToApiGateway,
    },
  ];

  return (
    <StatisticsBreakdown
      name="APIs"
      totalKey="apiCount"
      profileStatistics={profileStatistics}
      statisticsKeysAndNames={statisticsKeysAndNames}
    />
  );
};
