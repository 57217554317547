import { useCallback, useState } from 'react';
import { useMountRef } from '@src-v2/hooks/react-helpers/use-mount-ref';

export function useSafeState(initialState) {
  const mountRef = useMountRef();
  const [state, setState] = useState(initialState);
  return [
    state,
    useCallback((...args) => mountRef.current && setState(...args), [mountRef, setState]),
  ];
}
