import ioc from '@src-v2/ioc';

export default {
  state: null,
  effects: {
    error(error) {
      const errorMessage = error?.toString();
      const errorStatus = error?.response?.status;
      if (
        ![401, 403, 504].includes(errorStatus) &&
        !['401', '403', '504', 'Network Error'].some(errorMessageToSkip =>
          errorMessageToSkip.includes(errorMessage)
        )
      ) {
        ioc.toaster.error('Something went wrong');
        console.error(error);
      }
    },
    success({ message, closeButton = null, delay = null }) {
      ioc.toaster.success(message, { closeButton, delay });
    },
  },
};
