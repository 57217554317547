import styled from 'styled-components';
import { SvgIcon } from '@src-v2/components/icons';

export const CircularSvgIconButton = styled(SvgIcon)`
  width: 7rem;
  height: 7rem;
  padding: 0.5rem;
  background: var(--color-white);
  color: ${props =>
    props.disabled
      ? 'var(--color-blue-gray-40)'
      : props.highlighted
        ? 'var(--color-blue-65)'
        : 'var(--color-blue-gray-50)'};
  border: 0.25rem solid
    ${props =>
      props.disabled
        ? 'var(--color-blue-gray-30)'
        : props.highlighted
          ? 'var(--color-blue-65)'
          : 'var(--color-blue-gray-50)'};
  transition:
    color 400ms,
    border-color 400ms,
    background-color 400ms;
  border-radius: 100vmax;
  cursor: pointer;

  &:hover {
    color: var(--color-blue-gray-60);
    border-color: var(--color-blue-gray-60);
    background: ${props =>
      props.backgroundHilightOnHover ? 'var(--color-blue-gray-20)' : 'var(--color-white)'};
  }
`;
