import styled from 'styled-components';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { getCssVariable } from '@src-v2/utils/style-utils';
import { InChartLegend } from '@src/cluster-map-work/components/charts/chart-legend';
import {
  NO_RISK_CLUSTER_NODE_COLOR,
  getAllChartNodePresentationConfigs,
  getAllLinkPresentationConfigs,
} from '@src/cluster-map-work/components/charts/chart-view-presentation-config';
import { SvgGraphNodeImage } from '@src/cluster-map-work/components/charts/svg-graph-node';

export function ChartViewPresentationConfigLegend({
  chartViewPresentationConfig,
  compact,
  maxLegendWidth,
  className,
  style,
}) {
  const nodeImageColor = getCssVariable(NO_RISK_CLUSTER_NODE_COLOR);
  const nodeTypeLegend = getAllChartNodePresentationConfigs(chartViewPresentationConfig)
    .sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
    .map(conf => (
      <InChartLegend.Item data-compact={dataAttr(compact)} key={conf.friendlyName}>
        <LegendNodeContainer>
          <SvgGraphNodeImage iconUrl={conf.iconUrl} currentColor={nodeImageColor} />
        </LegendNodeContainer>
        {conf.friendlyName}
      </InChartLegend.Item>
    ));

  const linkTypeLegend = getAllLinkPresentationConfigs(chartViewPresentationConfig)
    .sort((a, b) => a.friendlyName.localeCompare(b.friendlyName))
    .map(linkTypeDisplayConfig => (
      <InChartLegend.Item data-compact={dataAttr(compact)} key={linkTypeDisplayConfig.friendlyName}>
        <LegendLinkContainer>
          <LegendLinkContainer.LinkDepiction color={linkTypeDisplayConfig.color} />
        </LegendLinkContainer>
        {linkTypeDisplayConfig.friendlyName}
      </InChartLegend.Item>
    ));

  return (
    <InChartLegend horizontal={compact} className={className} style={style}>
      <StyledInChartLegendContent legendWidth={maxLegendWidth}>
        {nodeTypeLegend}
        {linkTypeLegend}
      </StyledInChartLegendContent>
    </InChartLegend>
  );
}

const StyledInChartLegendContent = styled.div`
  [data-direction='right'] & {
    width: ${props => props.legendWidth ?? 'unset'};
  }

  & ${InChartLegend.Item}[data-compact] {
    display: inline-flex;

    img {
      height: 9rem;
    }
  }

  & ${InChartLegend.Item}:not([data-compact]) {
    min-width: 50rem;
  }
`;

const LegendNodeContainer = styled.div`
  margin: 2rem 2rem 2rem 4rem;
  width: 10rem;
`;

const LegendLinkContainer = styled.div`
  height: 12.5rem;
  width: 10rem;
  padding: 1.5rem 2rem;
  margin-left: 3rem;

  [data-compact] & {
    height: 9rem;
    padding: 0 2rem;
  }
`;

LegendLinkContainer.LinkDepiction = styled.hr`
  border-top: solid 0.5rem;
  width: 100%;
  height: 0.5rem;
  border-top-color: ${props => props.color ?? 'unset'};
  box-shadow: var(--elevation-1);
  margin-top: 4.5rem;
`;
