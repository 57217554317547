import _ from 'lodash';
import assign from 'lodash/assign';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import mapValues from 'lodash/mapValues';
import valuesIn from 'lodash/valuesIn';
import ioc from '@src-v2/ioc';
import apiService from '@src/services/apiService';

const baseRoute = '/api/assetCollections/';
const configurationRoute = `${baseRoute}configuration/`;

const clearData = () => ({
  profiles: {},
  inventories: {},
  assetOptions: {},
});

export default {
  state: clearData(),
  reducers: {
    setAssetCollectionProfiles: (state, assetCollectionProfiles) => ({
      ...state,
      profiles: assign(
        { ...state.profiles },
        keyBy(assetCollectionProfiles, profile => profile.key)
      ),
    }),
    setAssetOptions: (state, assetOptions) => ({
      ...state,
      assetOptions,
    }),
    setAssetConfigurations: (state, assetConfigurations) => ({
      ...state,
      assetConfigurations,
    }),
  },

  selectors: slice => ({
    assetCollectionProfiles: () => slice(assetCollections => valuesIn(assetCollections)),
    profilesByKey: () => slice(state => state?.profiles ?? {}),
    inventoriesByKey: () => slice(state => state?.inventories ?? {}),
    assetOptions: () => slice(state => state.assetOptions),
    assetConfigurations: () => slice(state => state.assetConfigurations),
  }),

  effects: dispatch => ({
    async getAssetCollectionProfileAsync({ key, invalidateCache }) {
      if (invalidateCache) {
        ioc.asyncCache.invalidate(ioc.profiles.getAssetCollectionProfile, { key });
      }

      const assetCollectionProfile = await ioc.asyncCache.suspend(
        ioc.profiles.getAssetCollectionProfile,
        {
          key,
        }
      );

      if (assetCollectionProfile) {
        this.setAssetCollectionProfiles([assetCollectionProfile]);
      }
      return assetCollectionProfile;
    },

    async fetchAvailableAssetOptions() {
      const options = (await apiService.get(`${configurationRoute}options`)).data;

      const orderedOptions = _.mapValues(options, value => _.orderBy(value, 'isMonitored', 'desc'));

      orderedOptions.projectByKey = keyBy(orderedOptions.projects, 'key');
      orderedOptions.repositoryByKey = keyBy(orderedOptions.repositories, 'key');
      orderedOptions.apiGatewayByKey = keyBy(orderedOptions.apiGateways, 'key');
      orderedOptions.repositoryGroupOptionsByKey = keyBy(orderedOptions.repositoryGroups, 'key');
      orderedOptions.modulesByRepositoryKey = mapValues(
        groupBy(orderedOptions.modules, 'groupKey'),
        object => keyBy(object, 'key')
      );

      this.setAssetOptions(orderedOptions);
    },

    async fetchAssetConfigurationsAsync() {
      const options = await apiService.get(`${baseRoute}configurations`);
      this.setAssetConfigurations(options.data);
    },

    async getUnmonitoredAssetCollectionConsumables({ key, invalidateCache }) {
      try {
        return (
          await apiService.get(`${baseRoute}${key}/unmonitored`, {
            clearCacheEntry: invalidateCache,
          })
        ).data;
      } catch (error) {
        const status = error.response?.status;
        if (status === 404) {
          ioc.history.push('/profiles/applications/');
        }
      }
    },

    async monitorAllAssetCollectionConsumables(key) {
      await apiService.put(`${baseRoute}${key}/monitorAll`);
    },

    async resyncAssetCollection({ key }) {
      await Promise.all([
        this.getAssetCollectionProfileAsync({ key, invalidateCache: true }),
        dispatch.organization.getOrganizationProfileAsync(true),
      ]);
    },
  }),
};
