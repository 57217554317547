import { useCallback, useEffect, useRef } from 'react';
import styled from 'styled-components';

function getHighlightedHtml(text, highlights) {
  const div = document.createElement('div');
  let index = 0;

  highlights.forEach(highlight => {
    const [highlightStart, highlightEnd] = highlight;
    div.appendChild(document.createTextNode(text.substring(0, highlightStart - index)));
    const highlightSpan = document.createElement('span');
    highlightSpan.className = 'highlight-error';
    highlightSpan.textContent = text.substring(highlightStart - index, highlightEnd);
    div.appendChild(highlightSpan);
    text = text.substring(highlightEnd);
    index = highlightEnd;
  });

  div.appendChild(document.createTextNode(text));

  return div.innerHTML;
}

function getCharacterRangeWithinEditor(range, editor) {
  const isWithinEditor = node =>
    node === editor ||
    (node.compareDocumentPosition(editor) & Node.DOCUMENT_POSITION_CONTAINS) !== 0;

  const getNodeStartTextOffset = node => {
    if (node === editor) {
      return 0;
    }

    let offset = getNodeStartTextOffset(node.parentNode);
    let siblingTraverse = node.previousSibling;
    while (siblingTraverse) {
      offset += siblingTraverse.textContent.length;
      siblingTraverse = siblingTraverse.previousSibling;
    }

    return offset;
  };

  const getRangeTextOffset = (node, offset) => {
    if (node.nodeType === Node.ELEMENT_NODE) {
      return getNodeStartTextOffset(node.childNodes.item(offset));
    }

    return getNodeStartTextOffset(node) + offset;
  };

  if (isWithinEditor(range.startContainer) && isWithinEditor(range.endContainer)) {
    return [
      getRangeTextOffset(range.startContainer, range.startOffset),
      getRangeTextOffset(range.endContainer, range.endOffset),
    ];
  }

  return null;
}

function createRangeFromEditorCharacterRange(editor, range) {
  const characterIndexToElementAndOffset = (withinElement, characterIndex) => {
    if (withinElement.nodeType === Node.TEXT_NODE) {
      return [withinElement, characterIndex];
    }

    let currentOffset = 0;
    let nextChild = null;
    for (
      let currentChildIndex = 0;
      currentChildIndex < withinElement.childNodes.length;
      currentChildIndex++
    ) {
      nextChild = withinElement.childNodes.item(currentChildIndex);
      if (currentOffset + nextChild.textContent.length >= characterIndex) {
        return characterIndexToElementAndOffset(nextChild, characterIndex - currentOffset);
      }
      currentOffset += nextChild.textContent.length;
    }

    return characterIndexToElementAndOffset(nextChild, nextChild.textContent.length - 1);
  };

  const [startNode, startOfs] = characterIndexToElementAndOffset(editor, range[0]);
  const [endNode, endOfs] = characterIndexToElementAndOffset(editor, range[1]);

  const newRange = document.createRange();
  newRange.setStart(startNode, startOfs);
  newRange.setEnd(endNode, endOfs);

  return newRange;
}

const StyledExpressionEditorDiv = styled.div`
  background: white;
  border: solid 1px black;
  overflow-x: scroll;
  white-space: nowrap;
  padding-right: 2rem;

  & .highlight-error {
    color: red;
    text-decoration: underline;
  }
`;

export function ExpressionEditor({ disabled, value, onValueChange, highlights, className }) {
  const editorRef = useRef();

  const handleInput = useCallback(() => {
    const newTextContent = editorRef.current.textContent;
    if (onValueChange) {
      onValueChange(newTextContent);
    }
  }, [onValueChange, editorRef]);

  useEffect(() => {
    const newHtml = getHighlightedHtml(value, highlights);
    if (editorRef.current && editorRef.current.innerHTML !== newHtml) {
      const selection =
        (window.getSelection()?.rangeCount && window.getSelection()?.getRangeAt(0)) || null;
      const preChangeRange =
        editorRef.current &&
        selection &&
        getCharacterRangeWithinEditor(selection, editorRef.current);

      editorRef.current.innerHTML = newHtml;

      if (preChangeRange) {
        const newRange = createRangeFromEditorCharacterRange(editorRef.current, preChangeRange);
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(newRange);
      }
    }
  }, [editorRef.current, value, highlights]);

  return (
    <StyledExpressionEditorDiv
      className={className}
      ref={editorRef}
      spellCheck="false"
      contentEditable={!disabled}
      onInput={handleInput}
    />
  );
}
