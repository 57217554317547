import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import {
  getRepositoryForInventoryPropertyFromProfileByKey,
  isAssetCollectionProfile,
} from '@src/components/CodeAggregation/InventoryUtils';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Link } from '@src/components/Link';
import apiService from '@src/services/apiService';

export const ApiSecurityHint = ({ profile, profileType, apiKey, violationType }) => {
  const [apiHint, setApiHint] = useState();

  useEffect(() => {
    const controller = isAssetCollectionProfile(profileType) ? 'assetCollections' : 'repositories';
    apiService
      .get(
        `/api/${controller}/${profile.key}/apiSecurityHints?apiKey=${apiKey}&violationType=${violationType}`
      )
      .then(res => setApiHint(res?.data))
      .catch(error => console.error('Failed fetching API hint', error));
  }, []);

  return (
    <>
      <HorizontalStack>
        (Other APIs in this {isAssetCollectionProfile(profileType) ? 'application' : 'repository'}{' '}
        implemented {violationTypeToDisplay[violationType]}.
        {apiHint && (
          <ApiHintLink
            url={getApiLink(apiHint.codeReference, apiHint.repositoryKey, profile)}
            ellipsis
            openInNewTab
            external
            underline>
            See example
          </ApiHintLink>
        )}
      </HorizontalStack>
      )
    </>
  );
};

const ApiHintLink = styled(Link)`
  color: var(--color-blue-60);
`;

const violationTypeToDisplay = {
  Authorization: 'authorization',
  InputValidation: 'input validation',
};

const getApiLink = (codeReference, repositoryKey, profile) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(repositoryKey, profile);
  return generateCodeReferenceUrl(repository, codeReference);
};
