import _ from 'lodash';
import filtersService from '@src/services/filtersService';

export const getModuleFiltersOptions = () => [
  {
    name: 'Components',
    filterOptions: [
      {
        name: 'Sensitive',
        displayName: 'Sensitive',
      },
    ],
  },
];

const filterModuleToCondition = {
  Sensitive: module => module.isSensitive,
};

export const filterModules = (allItems, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, item => [item.root]);

  const filterConditions = filters.booleanFilters
    .map(filter => filterModuleToCondition[filter])
    .filter(Boolean);

  if (!_.isEmpty(filterConditions)) {
    filtered = filtered.filter(module =>
      filterOperator(filterConditions, condition => condition(module))
    );
  }

  return filtered;
};
