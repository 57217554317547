import { computed, makeObservable } from 'mobx';

export class Subscription {
  #client;
  #application;
  #session;

  constructor({ apiClient, application, session }) {
    this.#client = apiClient;
    this.#application = application;
    this.#session = session;

    makeObservable(this, {
      expirationDate: computed,
      canManageAccount: computed,
    });
  }

  get isTrial() {
    return this.#session.data?.isTrial ?? false;
  }

  get account() {
    return this.#session.data?.userAccount;
  }

  get limitations() {
    return this.#session.data?.featureLimitations;
  }

  get expirationDate() {
    return this.#session.data?.environmentExpirationDatetime
      ? new Date(this.#session.data.environmentExpirationDatetime)
      : null;
  }

  get canManageAccount() {
    return Boolean(
      this.#session.data?.environments?.length > 1 ||
        this.#session.data?.userAccount?.roles.some(role => role !== 'User')
    );
  }

  requestPlanUpgrade() {
    const { environmentId } = this.#session.data;
    return this.#client.post(`/environments/${environmentId}/requestUpgrade`, null, {
      baseURL: '/accounts/api',
    });
  }
}
