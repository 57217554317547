import _ from 'lodash';
import { observer } from 'mobx-react';
import { useCallback } from 'react';
import { Dropdown } from '@src-v2/components/dropdown';
import { SelectMenu } from '@src-v2/components/select-menu';
import { Variant } from '@src-v2/components/types/enums/variant-enum';

export const MultiSelect = observer(
  ({ title, options = [], identity = _.identity, value, onChange, ...props }) => {
    const handleToggleSelection = useCallback(
      option => {
        const newSelected = new Set(value);
        if (newSelected.has(option)) {
          newSelected.delete(option);
        } else {
          newSelected.add(option);
        }

        onChange(newSelected);
      },
      [onChange, value]
    );

    return (
      <SelectMenu {...props} variant={Variant.FILTER}>
        <MaybeGroup title={title}>
          {options.map(option => (
            <Dropdown.CheckboxItem
              checked={value?.has(option)}
              onChange={() => handleToggleSelection(option)}
              key={identity(option)}>
              {identity(option)}
            </Dropdown.CheckboxItem>
          ))}
        </MaybeGroup>
      </SelectMenu>
    );
  }
);

function MaybeGroup(props) {
  return props.title ? <Dropdown.Group {...props} /> : props.children;
}
