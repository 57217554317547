import styled from 'styled-components';
import { AvatarProfile } from '@src-v2/components/avatar';
import { DateTime } from '@src-v2/components/time';
import { Link, Paragraph } from '@src-v2/components/typography';
import { dateFormats } from '@src-v2/data/datetime';
import { useInject, useSuspense } from '@src-v2/hooks';

export const NewDeveloperChange = ({ materialChange }) => {
  const { developers } = useInject();
  const developerProfile = useSuspense(developers.getDeveloperProfile, {
    key: materialChange.authorIdentityKey,
  });

  return (
    <Container>
      <Paragraph>Committed by new developer</Paragraph>{' '}
      <AvatarProfile
        size="small"
        active={developerProfile.isActive}
        identityKey={developerProfile.key}
        username={developerProfile.displayName}
        lastActivity={developerProfile.lastActivity}
        activeSince={developerProfile.activeSince}>
        <Link to={`/users/contributors/${developerProfile.representativeIdentityKeySha}`}>
          {developerProfile.displayName}
        </Link>
      </AvatarProfile>
      <Paragraph>
        first activity{' '}
        <DateTime date={developerProfile.activeSince} format={dateFormats.longDate} />
      </Paragraph>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  align-items: center;
  font-weight: 700;

  & ${Link} {
    display: block;
    margin-right: 2rem;
    font-weight: 500;
  }

  ${Paragraph} {
    margin-bottom: 0;
  }
`;
