import { matchPath } from 'react-router-dom';

const declarativePageRoutes = [
  {
    route: 'repository/:key',
    toLink: ({ key }) => `/profiles/repositories/${key}`,
  },
  {
    route: 'repositories',
    toLink: () => '/profiles/repositories',
  },
];

export function createLinkFromPageRoute(pageRoute) {
  for (const { route, toLink } of declarativePageRoutes) {
    const match = matchPath(pageRoute, { path: route });
    if (match) {
      return toLink(match.params);
    }
  }
  return '/';
}
