import _ from 'lodash';
import rulesService from '@src/services/rulesService';

export const getDefinitionOptions = options => ({
  given: {
    Repository: {
      displayName: 'API in repository',
      multi: false,
      options: [{ key: 'any', displayName: 'Any' }],
    },
  },
  when: {
    ApiRoutes: {
      group: 'Components',
      displayName: 'API route listed in',
      subTypeConjunction: 'repository',
      subTypes: options?.given.Repository.options.filter(option => option.key !== 'any') || [],
      optionsConjunction: 'at path',
      type: 'text',
    },
  },
  then: {
    ClassifyApis: {
      displayName: 'Classify API',
      optionsConjunction: 'as',
      options: [
        { key: 'internetFacing', displayName: 'Internet Facing' },
        { key: 'internal', displayName: 'Internal' },
      ],
    },
  },
});

export const ruleFormatToDefinition = ruleFormat => {
  const definitionFormat = rulesService.ungroupSameTypeValues(ruleFormat);
  return {
    ...definitionFormat,
    key: definitionFormat.key,
    name: definitionFormat.name,
    repositoryKey: definitionFormat.when[0].subType,
    filePath: definitionFormat.when[0].value,
    apiClassification: definitionFormat.then[0].value,
  };
};

export const definitionToRuleFormat = (definition, options) => {
  const newFormatRule = {
    ...definition,
    given: [
      {
        type: 'Repository',
        value: 'any',
      },
    ],
    when: [
      {
        type: 'ApiRoutes',
        subType: definition.repositoryKey,
        value: definition.filePath,
      },
    ],
    then: [
      {
        type: 'ClassifyApis',
        value: _.lowerFirst(definition.apiClassification),
      },
    ],
  };
  rulesService.groupSameTypeValues(newFormatRule, options);
  return newFormatRule;
};

export const customImportDefinitionSupportedLanguages = ['Java', 'CSharp'];
