import styled from 'styled-components';

export const HorizontalStack = styled.div`
  display: flex;
  justify-content: ${justifyContent};
  text-align: ${props => props.align ?? 'left'};
  flex-wrap: ${props => (props.wrapItems ? 'wrap' : 'unset')};
  align-items: ${props => props.alignItems ?? 'center'};
  gap: ${spacing};

  > *:not(:first-child):before {
    content: ${props => (props.withSeparator ? `"${props.separator ?? '·'}"` : '')};
    display: inline-block;
    margin-right: ${spacing};
  }

  > *:not(:last-child):after {
    content: ${props => (props.withSeparatorAfter ? '"·"' : '')};
    display: inline-block;
    margin-left: 10px;
  }
`;

function spacing(props) {
  const spacing = Number(props.spacing ?? 0.6) / 0.6;
  return `${spacing * 10}px`;
}

function justifyContent(props) {
  if (props.spread) {
    return 'space-between';
  }
  switch (props.align) {
    case 'center':
      return 'center';
    case 'right':
      return 'flex-end';
    case 'left':
    default:
      return 'flex-start';
  }
}
