import styled from 'styled-components';
import { ErrorBoundary } from '@src-v2/components/error-boundary';
import { ScrollToTop } from '@src-v2/components/scroll-to-top';
import { useInject } from '@src-v2/hooks';
import { TimelineBranchDeletion } from '@src/blocks/TimelineInfiniteScroll/components/TimelineBranchDeletionRule';
import { TimelineBranchForcePush } from '@src/blocks/TimelineInfiniteScroll/components/TimelineBranchForcePushRule';
import { TimelineBranchMinimumCodeReviewers } from '@src/blocks/TimelineInfiniteScroll/components/TimelineBranchMinimumCodeReviewersRule';
import { TimelineFindingsReport } from '@src/blocks/TimelineInfiniteScroll/components/TimelineFindingsReport';
import { TimelineSecretValidity } from '@src/blocks/TimelineInfiniteScroll/components/TimelineSecretValidity';
import { TimelineApiGateway } from './TimelineApiGateway';
import { TimelineBuild } from './TimelineBuild';
import { TimelineCommit } from './TimelineCommit';
import { TimelineRiskyIssue, TimelineRiskyIssueV3 } from './TimelineRiskyIssue';
import { TimelineServer } from './TimelineServer';

const StyledContainer = styled.div`
  position: relative;
  padding-top: 1.5rem;
`;

export const Timeline = props => {
  const { timelineEvents, noItems, noItemsMessage, className } = props;

  return (
    <StyledContainer className={className}>
      {noItems && noItemsMessage ? (
        <EmptyMessage>{noItemsMessage}</EmptyMessage>
      ) : (
        timelineEvents.map((timelineEvent, index) => (
          <ErrorBoundary key={index} extraInfo={timelineEvent}>
            <TimelineFactory {...props} timelineEvent={timelineEvent} index={index} />
          </ErrorBoundary>
        ))
      )}
      <ScrollToTop />
    </StyledContainer>
  );
};

const TimelineFactory = ({ timelineEvent, index, ...props }) => {
  const { application } = useInject();
  const extendedProps = { ...props };
  extendedProps.index = index;
  extendedProps.isFirst = index === 0;
  extendedProps.isLast = index === props.timelineEvents.length - 1;
  extendedProps.previousTimelineEvent = props.timelineEvents[index - 1];
  switch (timelineEvent.timelineEventType) {
    case 'Commit':
      return (
        <TimelineCommit
          key={timelineEvent.commitSha}
          subEntityKey={props.subEntityKey}
          isDemo={application.isDemo}
          commitTimelineEvent={timelineEvent}
          materialChangeFilters={props.materialChangeFilters}
          {...extendedProps}
        />
      );

    case 'FindingsReport':
      return (
        <TimelineFindingsReport
          key={timelineEvent.key}
          findingsReportTimelineEvent={timelineEvent}
          materialChangeFilters={props.materialChangeFilters}
          {...extendedProps}
        />
      );

    case 'Server':
      return (
        <TimelineServer
          key={timelineEvent.consumableEntityKey}
          serverTimelineEvent={timelineEvent}
          {...extendedProps}
        />
      );

    case 'Build':
      return (
        timelineEvent.materialChangesRiskLevel !== 'None' && (
          <TimelineBuild
            key={timelineEvent.buildJobId}
            buildTimelineEvent={timelineEvent}
            {...extendedProps}
          />
        )
      );

    case 'RiskyIssue':
      return (
        <TimelineRiskyIssue
          key={timelineEvent.key}
          riskyIssueTimelineEvent={timelineEvent}
          {...extendedProps}
        />
      );

    case 'RiskyIssueV3':
      return (
        <TimelineRiskyIssueV3
          key={timelineEvent.key}
          riskyIssueTimelineEvent={timelineEvent}
          {...extendedProps}
        />
      );

    case 'ApiGateway':
      return <TimelineApiGateway key={timelineEvent.key} apiGatewayTimelineEvent={timelineEvent} />;

    case 'SecretValidity':
      return (
        <TimelineSecretValidity
          key={timelineEvent.key}
          secretValidityTimelineEvent={timelineEvent}
          {...extendedProps}
        />
      );

    case 'BranchDeletionRule':
      return (
        <TimelineBranchDeletion
          key={timelineEvent.key}
          branchDeletionRuleTimelineEvent={timelineEvent}
          {...extendedProps}
        />
      );
    case 'BranchForcePushRule':
      return (
        <TimelineBranchForcePush
          key={timelineEvent.key}
          branchForcePushRuleTimelineEvent={timelineEvent}
          {...extendedProps}
        />
      );
    case 'BranchMinimumCodeReviewersRule':
      return (
        <TimelineBranchMinimumCodeReviewers
          key={timelineEvent.key}
          branchMinimumCRRuleTimelineEvent={timelineEvent}
          {...extendedProps}
        />
      );

    default:
      console.error(`Unsupported timeline event type "${timelineEvent.timelineEventType}"`);
      return null;
  }
};

const EmptyMessage = styled.div`
  padding: 20rem;
  font-weight: 300;
  text-align: center;
`;
