import { Paragraph, Strong } from '@src-v2/components/typography';
import { CommitCodeReference } from '@src-v2/containers/commit/common-componnets';

export const ManagedCheckovMaterialChange = ({
  isAdded,
  commitSha,
  repository,
  lineNumber,
  filePath,
  entityDisplayName,
  technologyDisplayName,
}) => (
  <>
    <Paragraph>
      A misconfiguration was {isAdded ? 'added to' : 'removed from'} a pipeline configuration file{' '}
      <CommitCodeReference
        repository={repository}
        commitSha={commitSha}
        lineNumber={lineNumber}
        relativeFilePath={filePath}>
        {filePath}
      </CommitCodeReference>{' '}
      of provider {technologyDisplayName}
    </Paragraph>
    <Strong>{entityDisplayName}</Strong>
  </>
);
