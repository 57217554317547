import _ from 'lodash';
import { Fragment, useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import styled from 'styled-components';
import { Button } from '@src-v2/components/button-v2';
import { Divider as BaseDivider } from '@src-v2/components/divider';
import { Checkbox, CheckboxToggle, Input } from '@src-v2/components/forms';
import { Combobox } from '@src-v2/components/forms/combobox';
import { InputGroup } from '@src-v2/components/forms/input-group';
import { MultiSelect } from '@src-v2/components/forms/multi-select';
import { Radio } from '@src-v2/components/forms/radio';
import { Select } from '@src-v2/components/forms/select';
import { SvgIcon } from '@src-v2/components/icons';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { Heading } from '@src-v2/components/typography';
import { SearchCombobox } from '@src-v2/containers/search-combobox';
import { alphabeticalSort } from '@src-v2/utils/string-utils';
import { customImportDefinitionSupportedLanguages } from '@src/blocks/GovernancePage/blocks/definitionOptionsUtils';
import apiService from '@src/services/apiService';

export const InternalFrameworkDefinitionForm = ({
  definition,
  setDefinition,
  onValidityChange,
}) => {
  const [{ dependencies, frameworks }, setOptions] = useState({ dependencies: [], frameworks: [] });
  const {
    watch,
    control,
    register,
    formState: { errors },
  } = useForm({ mode: 'onBlur', defaultValues: definition });
  const formData = watch();
  const dataType = watch('dataType', 'dependencies');

  useEffect(() => {
    Promise.all([fetchDependencies(), fetchFrameworkTypes()]).then(([dependencies, frameworks]) =>
      setOptions({ dependencies, frameworks })
    );
  }, []);

  useEffect(() => {
    onValidityChange(_.isEmpty(errors));
  }, [errors]);

  useEffect(() => {
    if (!_.isEqual(definition, formData)) {
      setDefinition(formData);
    }
  }, [formData]);

  return (
    <Form>
      <Heading>How would you like to name this framework?</Heading>

      <Input
        {...register('frameworkName', { required: true })}
        placeholder="e.g. Our Internal Authorization"
        autoComplete="off"
      />

      <Heading>What is it used for?</Heading>

      <Controller
        control={control}
        name="frameworkType"
        rules={{ required: true }}
        render={({ field: { onChange, ...field } }) => (
          <SearchCombobox
            {...field}
            as={Select}
            items={frameworks}
            placeholder="e.g. Authorization"
            onSelect={event => onChange(event.selectedItem?.value)}
          />
        )}
      />

      <Heading>What does it include?</Heading>

      <InputGroup
        input={<Radio {...register('dataType')} value="dependencies" defaultChecked />}
        label={
          <>
            <InputTitle>Dependencies</InputTitle>
            <InputDescription>
              Collect internal dependencies (e.g. custom encryption framework)
            </InputDescription>
          </>
        }>
        {dataType === 'dependencies' && (
          <Controller
            control={control}
            name="dependencies"
            rules={{ required: true }}
            render={({ field: { onChange, ...field } }) => (
              <SearchCombobox
                {...field}
                as={MultiSelect}
                items={dependencies}
                selectedItems={formData.dependencies}
                placeholder="Select or paste dependencies"
                onSelect={event => onChange(event.selectedItems)}
              />
            )}
          />
        )}
      </InputGroup>

      <Divider />

      <InputGroup
        input={<Radio value="packageImport" {...register('dataType')} />}
        label={
          <>
            <InputTitle>Package Import</InputTitle>
            <InputDescription>Select a package import and define annotations</InputDescription>
          </>
        }>
        {dataType === 'packageImport' && (
          <PackageImport watch={watch} control={control} register={register} />
        )}
      </InputGroup>
    </Form>
  );
};

const PackageImport = ({ name = 'packageImports', watch, control, register }) => {
  const arrayControls = useFieldArray({ name, control });
  const formData = watch(name);

  useEffect(() => {
    if (arrayControls.fields.length === 0) {
      arrayControls.append({});
    }
  }, []);

  return (
    <>
      <PatchGroup>
        <InputTitle>Select language</InputTitle>
        <Controller
          control={control}
          name="language"
          rules={{ required: true }}
          render={({ field: { onChange, ...field } }) => (
            <SearchCombobox
              {...field}
              as={Select}
              items={customImportDefinitionSupportedLanguages}
              placeholder="e.g. Java"
              onSelect={event => onChange(event.selectedItem)}
            />
          )}
        />
      </PatchGroup>

      {arrayControls.fields.map((field, index) => (
        <Fragment key={field.id}>
          <Input
            placeholder="e.g. com.Apiiro.Authorization"
            {...register(`${name}.${index}.importString`, { required: true })}
          />
          {arrayControls.fields.length > 1 && (
            <RemoveIcon name="Trash" onClick={() => arrayControls.remove(index)} />
          )}
          <InputGroup
            input={<CheckboxToggle {...register(`${name}.${index}.specificAnnotations`)} />}
            label={<InputDescription>Define specific annotations</InputDescription>}>
            {watch(`${name}.${index}.specificAnnotations`) && (
              <>
                <Controller
                  control={control}
                  name={`${name}.${index}.annotations`}
                  rules={{ required: true }}
                  render={({ field: { onChange, ...field } }) => (
                    <SearchCombobox
                      {...field}
                      as={MultiSelect}
                      placeholder="Enter Strings"
                      selectedItems={formData[index].annotations}
                      onSelect={event => onChange(event.selectedItems.map(item => item.value))}
                      creatable
                    />
                  )}
                />
                <InputGroup
                  input={<Checkbox {...register(`${name}.${index}.requireParameters`)} />}
                  label={<InputDescription>Must include parameters</InputDescription>}
                />
              </>
            )}
          </InputGroup>
        </Fragment>
      ))}
      <Button startIcon="Add" variant={Variant.TERTIARY} onClick={() => arrayControls.append({})}>
        Add import
      </Button>
    </>
  );
};

const fetchFrameworkTypes = async () => {
  const { data } = await apiService.get(
    '/api/definitions/internalFrameworkDefinitions/frameworkTypes'
  );
  return _.map(_.groupBy(data, 'groupDisplayName'), (frameworks, groupName) => ({
    label: groupName,
    options: frameworks.map(framework => ({
      label: framework.displayName,
      value: framework.key,
    })),
  }));
};

const fetchDependencies = async () => {
  const {
    data: { dependencies },
  } = await apiService.get('/api/organization/dependencies');
  return dependencies.sort(alphabeticalSort);
};

const Form = styled.form`
  ${Heading} {
    font-size: 1em;

    &:not(:first-of-type) {
      margin-top: 9rem;
    }
  }

  ${Input},
  ${Combobox} {
    width: 85rem;
  }
`;

const InputTitle = styled.span``;

const InputDescription = styled.small`
  display: block;
  color: var(--color-blue-gray-45);
  font-size: var(--font-size-s);
  font-weight: 300;
`;

const Divider = styled(BaseDivider)`
  margin: 4rem auto;
`;

const RemoveIcon = styled(SvgIcon)`
  width: 3rem;
  margin: 0 1rem;
  padding: 0 1rem;
  box-sizing: content-box;
  cursor: pointer;
`;

const PatchGroup = styled.div`
  margin-bottom: 3rem;

  ${InputTitle} {
    display: block;
    margin-bottom: 2rem;
  }
`;
