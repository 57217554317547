import find from 'lodash/find';
import isArray from 'lodash/isArray';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import ReactSelectWrapper from '@src/components/ReactSelect/ReactSelectWrapper';

const convertMultiSelectOption = secretValidationResult => ({
  value: secretValidationResult.key,
  label: secretValidationResult.displayName,
});

const MultiSelectInput = ({ items, property, disabled, setProperty, index }) => {
  if (isEmpty(items)) {
    return 'No items have been identified';
  }
  const currentValues = map(property.values, propertyValue =>
    convertMultiSelectOption(find(items, ['key', propertyValue]) || items[0])
  );
  return (
    <ReactSelectWrapper
      key={index}
      isMulti={true}
      isDisabled={disabled}
      value={currentValues}
      options={items.map(module => convertMultiSelectOption(module))}
      onChange={newSelection =>
        newSelection &&
        setProperty({
          propertyIndex: index,
          type: property.type,
          values: isArray(newSelection) ? map(newSelection, _ => _.value) : [newSelection.value],
        })
      }
    />
  );
};

export default MultiSelectInput;
