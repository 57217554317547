import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Input } from '@src-v2/components/forms';
import { SelectV2 } from '@src-v2/components/select/select-v2';
import { SubHeading3 } from '@src-v2/components/typography';
import { useInject } from '@src-v2/hooks';
import { dataAttr } from '@src-v2/utils/dom-utils';
import { entries } from '@src-v2/utils/ts-utils';

export const CategorySelect = ({ rule, setRuleCategory, required }) => {
  const { apiClient } = useInject();
  const [selectedCategory, setSelectedCategory] = useState();
  const [categories, setCategories] = useState([]);

  useEffect(async () => {
    const categoriesDictionary = await apiClient.get('governance/rules/categories');
    const flatCategories = entries(categoriesDictionary).map(([value, label]) => ({
      value,
      label,
    }));

    setCategories(flatCategories);
    setSelectedCategory(flatCategories.find(category => category.value === rule.category));
  }, []);

  useEffect(() => {
    const categoryValue = selectedCategory?.value;
    if (rule.category !== categoryValue) {
      return setRuleCategory(categoryValue);
    }
  }, [selectedCategory]);

  return (
    <Container data-invalid={dataAttr(required && !rule.category)}>
      <SubHeading3>Finding category</SubHeading3>
      <SelectV2
        placeholder="Select category..."
        value={selectedCategory}
        options={categories}
        onChange={setSelectedCategory}
      />
    </Container>
  );
};

const Container = styled.div`
  &[data-invalid] ${Input} {
    border-color: var(--color-red-45);

    &:not(:focus):hover {
      border-color: var(--color-red-60);
    }
  }
`;
