import { Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  HighlightedCode,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';
import { thenSubTypeToVerb } from '@src-v2/containers/commit/material-changes-utils';

export const SecurityControlsMaterialChange = ({
  commitSha,
  repository,
  materialChange,
  thenSubType,
}) => {
  if (materialChange.apiCodeReference) {
    const { apiName, methodName, methodSignature, relativeFilePath } =
      materialChange.apiCodeReference;
    return (
      <>
        <MaterialChangesRow>
          <>
            <Paragraph>API method</Paragraph>
            <CommitCodeReference
              repository={repository}
              commitSha={commitSha}
              relativeFilePath={relativeFilePath}>
              {apiName ?? methodName}
            </CommitCodeReference>
            <Paragraph>has potential security issues</Paragraph>
          </>
          <HighlightedCode language="java">{methodSignature}</HighlightedCode>
        </MaterialChangesRow>
        <MaterialChangesRow>
          {materialChange.descriptions.map(description => (
            <Paragraph>{description}</Paragraph>
          ))}
        </MaterialChangesRow>
      </>
    );
  }

  const { name, relativeFilePath } = materialChange.classCodeReference;
  return (
    <>
      <Paragraph>
        Web security configuration class{' '}
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}>
          {name}
        </CommitCodeReference>{' '}
        was <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>
      </Paragraph>
      <MaterialChangesRow>
        {materialChange.descriptions.map(description => (
          <Paragraph>{description}</Paragraph>
        ))}
      </MaterialChangesRow>
    </>
  );
};
