import groupBy from 'lodash/groupBy';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import sortBy from 'lodash/sortBy';
import PopoverTip from '@src-v2/components/tooltips/popover-tip';
import { Number } from '@src/components/Number';
import GroupedList from './GroupedList';

const Frameworks = ({ codeFrameworks, title, inline }) => {
  const frameworksByType = groupBy(
    sortBy(codeFrameworks, ['typeDisplayName', 'displayName']),
    framework => framework.typeDisplayName
  );
  keys(frameworksByType).forEach(
    key => (frameworksByType[key] = frameworksByType[key].map(_ => _.displayName))
  );

  const text = <Number value={codeFrameworks.length} one="technology" other="technologies" />;

  const groupedList = <GroupedList groupNameToValues={frameworksByType} emptyMessage={text} />;

  if (inline) {
    return groupedList;
  }

  return !isEmpty(frameworksByType) ? (
    <PopoverTip linkText={text} title={title}>
      {groupedList}
    </PopoverTip>
  ) : (
    text
  );
};

export default Frameworks;
