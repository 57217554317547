import debounce from 'lodash/debounce';
import flatten from 'lodash/flatten';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import { SvgIcon } from '@src-v2/components/icons';
import { useQueryParams } from '@src-v2/hooks';
import MultiSelectFilter from '@src/blocks/MultiSelectFilter';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { Tooltip } from '@src/components/Tooltip';
import { FontBodySmall } from '@src/style/common';
import {
  SearchAndFilter,
  SearchAndFilterContainer,
  SearchBarLeft,
  StyledCount,
  StyledExportIcon,
  StyledSearch,
  StyledSearchBar,
  StyledSearchIcon,
  StyledSearchIconClosedButton,
} from './Blocks/Styles';

const DEBOUNCE_TIMEOUT_MS = 500;

const StyledTooltip = styled(Tooltip)`
  align-self: center;
`;

const StyledTotalCount = styled.span`
  ${FontBodySmall}
`;

const TableSearch = ({
  table,
  filters,
  filterType,
  itemsCount,
  totalCount,
  searchTerm,
  clearSearch,
  setSearchTerm,
  updateFilterType,
  clearFilters,
  filterOptions,
  fetchFilterOptions,
  initialFilterOptions,
  handleFilterToggle,
  placeholder,
  onChange,
  description,
  supportFilterTypeToggle,
  customSearchBarSection,
  customActionsSection,
  downloadTable,
  resultDisplay,
  resultName,
  resultNamePlural,
  spread,
  initialSearchTerm,
}) => {
  const [searchOpen, toggleSearchOpen] = useState(!isEmpty(searchTerm));
  const prevFilterOptions = useRef(initialFilterOptions);
  const { updateQueryParams } = useQueryParams();

  const handleClearFilters = useCallback(() => {
    clearFilters();
    updateQueryParams({ filters: null });
  }, []);

  useEffect(() => {
    if ((searchTerm || initialSearchTerm) && !searchOpen) {
      toggleSearchOpen(true);
    }
  }, [searchTerm, initialSearchTerm]);

  const handleToggleSearchOpen = useCallback(
    toggleValue => {
      if (!toggleValue) {
        setSearchTerm(null);
        updateQueryParams({ searchTerm: null });
      }
      toggleSearchOpen(toggleValue);
    },
    [setSearchTerm]
  );

  const customResultDisplay = Boolean(resultDisplay);
  const hasCustomSection = !isEmpty(customSearchBarSection);
  const shouldSpread = (customResultDisplay || hasCustomSection) && spread !== false;
  resultDisplay =
    resultDisplay ??
    ((itemsCount, hasSelectedFilters) =>
      !isNil(itemsCount) &&
      itemsCount > 0 &&
      resultName && (
        <HorizontalStack>
          <StyledCount>
            <Number value={itemsCount} one={resultName} other={resultNamePlural} />
          </StyledCount>
          {hasSelectedFilters && totalCount && (
            <StyledTotalCount>out of {totalCount}</StyledTotalCount>
          )}
        </HorizontalStack>
      ));

  const onFilterToggleCallback = useCallback(
    (filter, filterGroup) => {
      const { payload } = handleFilterToggle({ filters, filter, filterGroup });
      updateQueryParams({ filters: JSON.stringify(payload) });
      onChange?.();
    },
    [onChange, handleFilterToggle, filters]
  );

  const onDebounceChange = useMemo(
    () =>
      debounce(value => {
        updateQueryParams({ searchTerm: value });
        onChange?.();
      }, DEBOUNCE_TIMEOUT_MS),
    [setSearchTerm, onChange]
  );

  const handleSearchChange = useCallback(
    event => {
      setSearchTerm(event.target.value);
      onDebounceChange?.(event.target.value);
    },
    [onDebounceChange]
  );

  const onFilterTypeChange = useCallback(
    filterType => {
      if (updateFilterType({ filterType })) {
        updateQueryParams({ filterType: JSON.stringify(filterType) });
      }
      onChange?.();
    },
    [onChange, updateFilterType]
  );

  useEffect(() => {
    fetchFilterOptions({ table, initialFilterOptions });
    return () => {
      // initialFilterOptions gets a new reference each render so the search keeps on clearing
      if (!isEqual(prevFilterOptions.current, initialFilterOptions)) {
        prevFilterOptions.current = initialFilterOptions;
        clearSearch();
      }
    };
  }, [table, initialFilterOptions, fetchFilterOptions, clearSearch]);

  const hasSelectedFilters =
    !isEmpty(searchTerm) ||
    !isEmpty(flatten(Object.values(filters.listFilters ?? {}))) ||
    !isEmpty(filters.booleanFilters);

  return (
    <SearchAndFilterContainer spread={shouldSpread}>
      <SearchAndFilter spacing="0" spread={shouldSpread}>
        <SearchBarLeft>
          {resultDisplay(itemsCount, hasSelectedFilters)}
          {hasCustomSection && customSearchBarSection}
        </SearchBarLeft>
        <StyledSearchBar open={searchOpen} align="right" spacing={0}>
          {searchOpen && (
            <StyledSearch
              name="search"
              placeholder={placeholder}
              onChange={handleSearchChange}
              value={searchTerm || initialSearchTerm}
            />
          )}
          <StyledSearchIconClosedButton
            className="table-search"
            onClick={() => handleToggleSearchOpen(!searchOpen)}>
            <StyledSearchIcon open={searchOpen}>
              <SvgIcon name="Search" />
            </StyledSearchIcon>
          </StyledSearchIconClosedButton>
        </StyledSearchBar>
        {downloadTable && (
          <StyledTooltip tip="Export table to CSV">
            <StyledExportIcon onClick={downloadTable}>
              <SvgIcon name="Export" />
            </StyledExportIcon>
          </StyledTooltip>
        )}
        {!isEmpty(filterOptions) && (
          <MultiSelectFilter
            filterType={filterType}
            filterOptions={filterOptions}
            clearFilters={handleClearFilters}
            updateFilterType={onFilterTypeChange}
            handleFilterToggle={onFilterToggleCallback}
            supportFilterTypeToggle={supportFilterTypeToggle}
            description={description}
            inlineFilters
          />
        )}
        {!isEmpty(customActionsSection) && customActionsSection}
      </SearchAndFilter>
    </SearchAndFilterContainer>
  );
};

export default TableSearch;
