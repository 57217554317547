const censorValue = '•••••';

export function cutAroundCensoredValue(value) {
  if (value.length <= 100) {
    return value;
  }

  const indexOfSecret = value.indexOf(censorValue);
  const reverseBeforeSecret = value.substring(0, indexOfSecret).split('').reverse().join('');
  const matchBeforeSecret = reverseBeforeSecret.match(/[,&;]|\s\w-|--/);
  if (matchBeforeSecret && indexOfSecret > 2 && matchBeforeSecret.index < 90) {
    return displayCutSecret(
      value,
      indexOfSecret,
      matchBeforeSecret.index + matchBeforeSecret[0].length,
      censorValue.length + 1
    );
  }

  return displayCutSecret(value, indexOfSecret, 10, censorValue.length + 10);
}

function displayCutSecret(value, indexOfSecret, before, after) {
  return `...${value.substring(indexOfSecret - before, indexOfSecret + after)}...`;
}
