import styled from 'styled-components';
import { BaseIcon, LanguageIcon } from '@src-v2/components/icons';
import { SvgArrow } from '@src-v2/components/svg/svg-arrow';
import { DateTime } from '@src-v2/components/time';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { NavLink, Paragraph } from '@src-v2/components/typography';
import { abbreviate } from '@src-v2/utils/number-utils';

export function BreakdownFactory({ category, breakdown }) {
  switch (category) {
    case 'Commits': {
      const [{ author, commitSha, createdAt, repositoryKey, repositoryName, diffedCommitCount }] =
        breakdown;
      return (
        <>
          <Breakdown vertical>
            <Breakdown.Cell>Total analyzed commits: {abbreviate(diffedCommitCount)}</Breakdown.Cell>
            <Breakdown.Cell>
              Last commit analyzed on <DateTime date={createdAt} format="MMM d, Y" /> at{' '}
              <DateTime date={createdAt} format="p" />
            </Breakdown.Cell>
            <Breakdown.Cell>
              Commit {commitSha.substring(0, 7)} in {repositoryName} {author ? `by ${author}` : ''}
            </Breakdown.Cell>
          </Breakdown>
          <Breakdown as={NavLink} to={`/profiles/repositories/${repositoryKey}/timeline`}>
            View risky commits <SvgArrow width={16} height={8} />
          </Breakdown>
        </>
      );
    }

    case 'LinesOfCode': {
      return breakdown.map(({ language, linesOfCode, filesCount }) => (
        <Breakdown key={language}>
          <Tooltip content={language}>
            <LanguageIcon name={language} />
          </Tooltip>
          <Breakdown.Cell>{abbreviate(linesOfCode)} Lines</Breakdown.Cell>
          <Breakdown.Cell>{abbreviate(filesCount)} Files</Breakdown.Cell>
        </Breakdown>
      ));
    }

    case 'Issues': {
      return breakdown.map(({ projectName, count }) => (
        <Breakdown key={projectName}>
          <Breakdown.Cell>{projectName}</Breakdown.Cell>
          <Breakdown.Cell>{abbreviate(count)} tickets</Breakdown.Cell>
        </Breakdown>
      ));
    }

    default:
      console.warn(`Learning statistics breakdown template is missing for "${category}"`);
      return (
        <Breakdown>
          <Breakdown.Cell>Additional data coming soon...</Breakdown.Cell>
        </Breakdown>
      );
  }
}

const Breakdown = styled.div`
  display: flex;
  margin-bottom: 3rem;
  flex-direction: ${props => (props.vertical ? 'column' : 'row')};
  align-items: ${props => (props.vertical ? 'flex-start' : 'center')};
  gap: ${props => (props.vertical ? '0' : '2rem')};

  &:last-child {
    margin-bottom: 5rem;
  }

  ${BaseIcon} {
    flex: 0;
    width: 5rem;
    height: 5rem;
  }

  ${Paragraph} {
    margin-bottom: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  ${NavLink},
  &${NavLink} {
    width: fit-content;
    font-size: var(--font-size-s);
    font-weight: 700;

    &:hover {
      text-decoration: underline;
    }
  }
`;

Breakdown.Cell = styled.div`
  flex-grow: 1;

  &:last-child {
    flex-grow: 0;
    color: var(--color-blue-gray-45);
  }
`;
