import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { setInputValue } from '@src-v2/utils/input-setter';
import { InChartHeaderControls } from '@src/cluster-map-work/components/charts/graph-utils';

const searchTermParseRegex = /^\s*(?:([a-z-]+):)?(?:"((?:\\.|[^"])*)"|(\S+))\s*/;

export function useGraphSearchState() {
  const [searchTerm, setSearchTerm] = useState('');

  const parsedSearchTerm = useMemo(() => {
    let termToParse = searchTerm;
    const retOverlays = [];

    searchTermParseRegex.lastIndex = 0;

    let regexResult;
    while ((regexResult = searchTermParseRegex.exec(termToParse)) && regexResult[0]) {
      retOverlays.push({
        overlay: regexResult[1],
        searchValue: regexResult[2] || regexResult[3],
        parsedRepresentation: regexResult[0],
      });

      termToParse = termToParse.substring(regexResult[0].length);
    }

    return {
      parsedOverlays: retOverlays,
      unparsedSuffix: termToParse.length ? termToParse : null,
    };
  }, [searchTerm]);

  const toggleSearchOverlayValue = useCallback(
    (overlay, value) => {
      const parsedOverlaysClone = parsedSearchTerm?.parsedOverlays.concat() || [];

      const existingIndex = parsedOverlaysClone.findIndex(
        overlayTerm => overlayTerm.overlay === overlay && overlayTerm.searchValue === value
      );

      if (existingIndex < 0) {
        const escapedValue = value.replace(/\\/g, '\\\\').replace(/"/g, '\\"');
        setSearchTerm(`${searchTerm} ${overlay}:"${escapedValue}"`);
      } else {
        parsedOverlaysClone.splice(existingIndex, 1);
        setSearchTerm(
          parsedOverlaysClone.map(t => t.parsedRepresentation).join('') +
            (parsedSearchTerm?.unparsedSuffix || '')
        );
      }
    },
    [parsedSearchTerm, setSearchTerm, searchTerm]
  );

  return useMemo(
    () => ({
      searchTerm,
      setSearchTerm,
      parsedSearchTerm,
      toggleSearchOverlayValue,
    }),
    [searchTerm, setSearchTerm, parsedSearchTerm, toggleSearchOverlayValue]
  );
}

export function GraphSearchInput({ graphSearchState, ...props }) {
  const ref = useRef();

  useEffect(() => {
    if (ref.current) {
      setInputValue(ref.current, graphSearchState.searchTerm);
    }
  }, [ref.current, graphSearchState.searchTerm]);

  const handleSearchChange = useCallback(
    ({ target }) => graphSearchState.setSearchTerm(target?.value),
    [graphSearchState.setSearchTerm]
  );

  return <InChartHeaderControls.SearchBox onChange={handleSearchChange} ref={ref} {...props} />;
}
