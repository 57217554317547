import _ from 'lodash';
import styled from 'styled-components';
import { ControlledCard, ControlledCardProps } from '@src-v2/components/cards/controlled-card';
import { ClampText } from '@src-v2/components/clamp-text';
import { ComplianceFrameworkReferences } from '@src-v2/components/entity-pane/evidence/evidence-compliance-framework-references';
import { EvidenceLine } from '@src-v2/components/entity-pane/evidence/evidence-line';
import { SeverityTag } from '@src-v2/components/tags';
import { InfoTooltip } from '@src-v2/components/tooltips/icon-tooltips';
import { ExternalLink } from '@src-v2/components/typography';
import { ExpandableDescription } from '@src-v2/containers/entity-pane/sast/expandable-description';
import { EpssScore } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-likelihood-card';
import { CveLink, CweLink } from '@src-v2/containers/entity-pane/sca/vulnerability-link';
import { CardContentWrapper } from '@src-v2/containers/pages/artifacts/artifact-pane/components/common';
import { DependencyTree } from '@src-v2/containers/pages/artifacts/artifact-pane/components/dependency-tree';
import { Finding } from '@src-v2/types/inventory-elements/lightweight-finding-response';
import { LeanConsumableProfile } from '@src-v2/types/profiles/lean-consumable-profile';

export const AboutVulnerabilityCard = ({
  finding,
  artifactKey,
  relatedEntity,
  ...props
}: {
  finding: Partial<Finding>;
  artifactKey: string;
  relatedEntity: LeanConsumableProfile;
} & ControlledCardProps) => {
  const {
    description,
    exploitMaturity,
    referencedBy,
    cvssInfo,
    complianceFrameworkReferences,
    globalIdentifiers,
    severity,
    links,
    epss,
    epssSeverity,
    dependencyInfoPathsSummary,
  } = finding;
  const noExploitMaturity = exploitMaturity === 'No exploit maturity data';
  return (
    <ControlledCard {...props} title="About this vulnerability">
      <CardContentWrapper>
        {globalIdentifiers.length > 0 && (
          <EvidenceLine isExtendedWidth label="Identifiers">
            <LinksWrapper>
              {globalIdentifiers.map((identifier, index) => {
                if (identifier.identifierType.toLowerCase() === 'cve') {
                  return (
                    <CveLink
                      key={`${identifier.identifierType}-${index}`}
                      cve={identifier.identifier}
                    />
                  );
                }

                if (identifier.identifierType.toLowerCase() === 'cwe') {
                  return (
                    <CweLink
                      key={`${identifier.identifierType}-${index}`}
                      cwe={identifier.identifier}
                    />
                  );
                }

                return _.isNil(identifier.link) ? (
                  <ClampText
                    key={`${identifier.identifierType}-${index}`}>{`${identifier.identifier}-${identifier.identifierType}`}</ClampText>
                ) : (
                  <ExternalLink
                    key={`${identifier.identifierType}-${index}`}
                    href={identifier.link}>
                    <ClampText>{`${identifier.identifier}-${identifier.identifierType}`}</ClampText>
                  </ExternalLink>
                );
              })}
            </LinksWrapper>
          </EvidenceLine>
        )}
        {Boolean(description) && (
          <EvidenceLine isExtendedWidth label="Description">
            <ExpandableDescription maxPreviewChars={250}>{description}</ExpandableDescription>
          </EvidenceLine>
        )}
        {cvssInfo && cvssInfo.score !== -1 && (
          <EvidenceLine isExtendedWidth label={`CVSS v${parseFloat(cvssInfo.version).toFixed(1)}`}>
            <SeverityTag riskLevel={severity}>{cvssInfo.score}</SeverityTag>
          </EvidenceLine>
        )}
        {Boolean(complianceFrameworkReferences) && (
          <ComplianceFrameworkReferences references={complianceFrameworkReferences} />
        )}
        <EvidenceLine isExtendedWidth label="Exploit maturity">
          <ExploitMaturityText data-disabled={noExploitMaturity}>
            {noExploitMaturity ? (
              exploitMaturity
            ) : (
              <SeverityTag riskLevel="high">{exploitMaturity}</SeverityTag>
            )}
          </ExploitMaturityText>
        </EvidenceLine>
        {links.length > 0 && (
          <EvidenceLine isExtendedWidth label="Additional links">
            <LinksWrapper>
              {links.map((link, index) => (
                <ExternalLink key={`${link}-${index}`} href={link.url}>
                  <ClampText>{link.url}</ClampText>
                </ExternalLink>
              ))}
            </LinksWrapper>
          </EvidenceLine>
        )}
        {epssSeverity && epss?.epssScore && (
          <EvidenceLine
            isExtendedWidth
            label={
              <>
                EPSS score
                <InfoTooltip
                  content={
                    <>
                      The Exploit Prediction Scoring System (EPSS) is a tool for estimating <br />{' '}
                      the likelihood that a vulnerability will be exploited in the wild during the
                      next 30 days.
                      <br /> A higher EPSS score indicates a higher chance of exploitation.
                      <br />
                      Percentile indicates how likely a vulnerability is to be exploited compared to
                      other vulnerabilities.
                      <br /> A vulnerability with an EPSS percentile of 90% means it has a higher
                      probability score than 90% of all other vulnerabilities.
                    </>
                  }
                />
              </>
            }>
            <EpssScore>
              <SeverityTag riskLevel={epssSeverity}>{epss.epssScore}</SeverityTag>
              {epssSeverity}
            </EpssScore>
          </EvidenceLine>
        )}
        {Boolean(epss?.percentile) && (
          <EvidenceLine
            isExtendedWidth
            label={
              <>
                EPSS percentile
                <InfoTooltip
                  content={
                    <>
                      Indicates how likely a vulnerability is to be exploited compared to other
                      vulnerabilities.
                      <br />A vulnerability with an EPSS percentile of 90% means it has a higher
                      probability <br />
                      score than 90% of all other vulnerabilities
                    </>
                  }
                />{' '}
              </>
            }>
            {(epss.percentile * 100).toFixed(2)}%
          </EvidenceLine>
        )}
        {referencedBy && (
          <EvidenceLine isExtendedWidth label="Referenced by">
            {referencedBy}
          </EvidenceLine>
        )}
        {dependencyInfoPathsSummary.totalInfoPathsCount > 0 && (
          <DependencyTree
            relatedEntity={relatedEntity}
            dependencyInfoPathsSummary={dependencyInfoPathsSummary}
          />
        )}
      </CardContentWrapper>
    </ControlledCard>
  );
};

const LinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  overflow: hidden;

  ${ExternalLink} {
    max-width: 100%;
  }
`;

export const ExploitMaturityText = styled.span`
  &[data-disabled] {
    color: var(--color-blue-gray-35);
  }
`;
