import apiService from '@src/services/apiService';

export default {
  state: {
    developerCount: null,
    activeDeveloperCount: null,
    assetCollectionCount: null,
    issueTypes: [],
  },
  reducers: {
    setOrganizationProfile: (state, organizationProfile) => ({
      developerCount: organizationProfile.statistics?.developerCount ?? 0,
      activeDeveloperCount: organizationProfile.statistics?.activeDeveloperCount ?? 0,
      assetCollectionCount: organizationProfile.statistics?.assetCollectionCount ?? 0,
      issueTypes: organizationProfile.issueTypes,
    }),
  },
  selectors: slice => ({
    developerCount: () => slice(state => state.developerCount),
    activeDeveloperCount: () => slice(state => state.activeDeveloperCount),
    assetCollectionCount: () => slice(state => state.assetCollectionCount),
    issueTypes: () => slice(state => state.issueTypes),
  }),
  effects: () => ({
    async getOrganizationProfileAsync(clearCacheEntry) {
      const { data } = await apiService.get('/api/organization', { clearCacheEntry });
      this.setOrganizationProfile(data);
    },
  }),
};
