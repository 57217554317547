import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { getProjectForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { openIssuePaneWithRiskActions } from '@src/components/Panes/IssuePane';
import { EllipsisDiv } from '@src/style/common';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getOpenIssuesTriggerDisplay = ({ trigger, profile, ...options }) => {
  const project = getProjectForInventoryPropertyFromProfileByKey(trigger.elementEntityKey, profile);
  return [
    {
      weight: 3,
      content: (
        <PaneLocationLink
          id={trigger.key}
          onClick={args =>
            openIssuePaneWithRiskActions({ ...args, trigger, project, profile, ...options })
          }>
          <TriggerInfoContainer
            title={
              trigger.id.startsWith(project.name)
                ? addInterpunctSeparator(trigger.type, trigger.id)
                : addInterpunctSeparator(project.name, trigger.type, trigger.id)
            }
            insights={trigger.insights}>
            <EllipsisDiv>{trigger.title}</EllipsisDiv>
          </TriggerInfoContainer>
        </PaneLocationLink>
      ),
    },
  ];
};

export const getIssuesMessageFormat = issue => issue.title;
