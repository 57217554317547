import React, { useMemo } from 'react';
import styled from 'styled-components';
import NoResultsFlashlight from '@src-v2/assets/images/empty-state/no-results-flashlight.svg';
import { AnalyticsDataField, AnalyticsLayer } from '@src-v2/components/analytics-layer';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { Card } from '@src-v2/components/cards';
import { Page } from '@src-v2/components/layout/page';
import { CustomReportsTable } from '@src-v2/containers/pages/reporting/components/custom-reports-table';
import { ReportingTilesHeader } from '@src-v2/containers/pages/reporting/reporting-landing-page/landing-page-header';
import { ReportTile } from '@src-v2/containers/pages/reporting/reporting-landing-page/reporting-tile';
import { reportsPreviewData } from '@src-v2/containers/pages/reporting/reporting-landing-page/reports-preview-data';
import { ReportPreviewData } from '@src-v2/containers/pages/reporting/reporting-landing-page/types';
import { useReportingCardsGrid } from '@src-v2/containers/pages/reporting/reporting-landing-page/use-reporting-cards-grid';
import { useInject } from '@src-v2/hooks';
import { customScrollbar } from '@src-v2/style/mixins';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';

interface CardsContainerProps {
  cards: ReportPreviewData[];
}

const CardsContainer: React.FC<CardsContainerProps> = ({ cards }) => {
  const { application } = useInject();

  const filteredCards = useMemo(
    () => cards.filter(card => !card.featureFlag || application.isFeatureEnabled(card.featureFlag)),
    [cards, application]
  );

  const { isExpanded, visibleCards, cardsPerRow, containerRef, handleSearchChange, toggleExpand } =
    useReportingCardsGrid(filteredCards);

  const gridRef = React.useRef<HTMLDivElement>(null);
  const headerRef = React.useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = React.useState<number | null>(null);

  React.useLayoutEffect(() => {
    if (gridRef.current && headerRef.current) {
      const headerHeight = headerRef.current.offsetHeight;
      const gridHeight = gridRef.current.scrollHeight;
      const totalPadding = 32;
      setContentHeight(headerHeight + gridHeight + totalPadding);
    }
  }, [visibleCards, isExpanded]);

  return (
    <>
      <ReportingTilesContainer
        ref={containerRef}
        style={{ height: contentHeight ? `${contentHeight}px` : 'auto' }}>
        <div ref={headerRef}>
          <ReportingTilesHeader
            totalReports={filteredCards.length}
            visibleReports={visibleCards.length}
            onSearchTermChange={handleSearchChange}
            isExpanded={isExpanded}
            onExpandToggle={toggleExpand}
          />
        </div>
        {visibleCards.length === 0 && (
          <NoResults>
            <NoResultsFlashlight />
          </NoResults>
        )}
        <CardsGrid ref={gridRef} style={{ '--cards-per-row': cardsPerRow } as React.CSSProperties}>
          {visibleCards.map(card => (
            <ReportTile key={card.reportName} {...card} />
          ))}
        </CardsGrid>
      </ReportingTilesContainer>
      {application.isFeatureEnabled(FeatureFlag.CustomReportsV2) && (
        <AsyncBoundary>
          <CustomReportsTable />
        </AsyncBoundary>
      )}
    </>
  );
};

const ReportingPage: React.FC = () => {
  return (
    <Page>
      <AnalyticsLayer analyticsData={{ [AnalyticsDataField.Context]: 'Reporting' }}>
        <CardsContainer cards={reportsPreviewData} />
      </AnalyticsLayer>
    </Page>
  );
};

const ReportingTilesContainer = styled(Card)`
  width: calc(100% - 20rem);
  margin-top: 8rem;
  min-height: 105rem;
  margin-left: 9.5rem;
  transition: height 0.4s ease-out;
  max-height: 200rem;
  overflow: scroll;
  padding: 6rem 0 6rem 6rem;

  ${customScrollbar}
`;

const CardsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  overflow: visible;
`;

const NoResults = styled(props => (
  <div {...props}>
    <NoResultsFlashlight />
    No results found
  </div>
))`
  padding-top: 20rem;
  height: 100%;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export default ReportingPage;
