import { connect } from 'react-redux';
import { select } from '@src/store';
import ProcessTagSelector from './view';

const mapState = select(
  ({
    processTagSelector: { error, loading, selectedProcessTag, processTypes, newProcess, editMode },
  }) => ({
    error,
    loading,
    selectedProcessTag,
    processTypes,
    newProcess,
    editMode,
  })
);

const mapDispatch = ({
  processTagSelector: {
    fetchProcessTypes,
    setSelectedProcess,
    createProcessTag,
    getFilteredProcesses,
    setNewProcess,
    setEditMode,
    clearData,
  },
}) => ({
  fetchProcessTypes,
  setSelectedProcess,
  createProcessTag,
  getFilteredProcesses,
  setNewProcess,
  setEditMode,
  clearData,
});

export default connect(mapState, mapDispatch)(ProcessTagSelector);
