import orderBy from 'lodash/orderBy';
import toPairs from 'lodash/toPairs';

export const riskTitles = ['Low', 'Medium', 'High', 'Critical'];

export const riskOrder = {
  None: 0,
  AutoIgnored: 1,
  Ignored: 2,
  Accepted: 3,
  Low: 4,
  Medium: 5,
  High: 6,
  Critical: 7,
};

export const riskDisplay = {
  AutoIgnored: 'Auto Ignored',
};

export const riskToCountPairs = countByLevel =>
  orderBy(toPairs(countByLevel), ([riskLevel]) => riskOrder[riskLevel], ['desc']);
