import { SvgIcon } from '@src-v2/components/icons';

export const NO_PERMISSION = 'NoPermission';

export const permissionTypes = [
  { value: NO_PERMISSION, label: 'No Permission', icon: <SvgIcon name="Block" /> },
  {
    value: 'Write',
    label: 'Read/Write',
    icon: <SvgIcon name="ReadWrite" />,
  },
  { value: 'Read', label: 'Read', icon: <SvgIcon name="Visible" /> },
];
