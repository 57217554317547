import _ from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { getShortSha, isValidGitSha } from '@src/utils/gitShaUtils';

const refTypeToApiSearchType = {
  branch: 'branches',
  tag: 'tags',
  commit: 'commit',
};

export const Nav = styled.ul`
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #dee2e6;
`;

const StyledAsyncSelect = styled(AsyncCreatableSelect)`
  && > * {
    ${props => (props.invalid ? `border-color: #dc3545;` : '')}
    ${props =>
      props.isDisabled
        ? `
    background-color: var(--color-blue-gray-15);
    border-color: var(--color-blue-gray-35);
  `
        : ''}
  }
`;

const StyledNavLink = styled.a`
  display: block;
  padding: 8px 16px;
  color: var(--color-blue-gray-45);

  &:hover {
    color: #00947a;
  }

  &.active {
    color: var(--default-text-color);
  }
`;

const Loading = styled(LogoSpinner)`
  height: 7rem;
  margin: 5rem auto;
`;

const TypeTab = ({ options, setType, type, currType, handleInputChange }) => (
  <li>
    <StyledNavLink
      onClick={() => {
        setType(type);
        handleInputChange(options.selectProps.inputValue);
      }}
      className={currType.toLowerCase() === type ? 'active' : ''}>
      {_.upperFirst(type)}
    </StyledNavLink>
  </li>
);

const GitReferencesAutoComplete = ({
  repositoryKey,
  searchCommitish,
  gitReference,
  disabled,
  onChange,
  invalid,
  ...props
}) => {
  const [type, setType] = useState(gitReference?.refType.toLowerCase() ?? 'branch');
  const reactSelectRef = useRef(null);
  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      reactSelectRef.current.clearValue();
    }

    didMount.current = true;
  }, [repositoryKey]);

  return (
    <StyledAsyncSelect
      isCreatable
      isClearable
      ref={reactSelectRef}
      placeholder=""
      isDisabled={disabled}
      invalid={invalid}
      onKeyDown={event => event.stopPropagation()}
      onChange={(inputValue, action) =>
        ['select-option', 'clear'].includes(action.action)
          ? onChange(inputValue)
          : onChange({
              label: getShortSha(inputValue.value),
              value: {
                name: inputValue.value,
                headSha: inputValue.value,
                refType: 'commit',
              },
            })
      }
      noOptionsMessage={({ inputValue }) =>
        type === 'commit'
          ? 'Please enter a valid commit hash'
          : _.isEmpty(inputValue)
            ? `Please enter ${type} name`
            : `No matching ${type} found`
      }
      defaultValue={
        gitReference
          ? {
              label:
                type === 'commit' ? getShortSha(gitReference.identifier) : gitReference.identifier,
              value: gitReference,
            }
          : null
      }
      loadOptions={async inputValue =>
        type === 'commit'
          ? null
          : (
              await searchCommitish({
                repositoryKey,
                type: refTypeToApiSearchType[type],
                query: inputValue,
              })
            ).map(commitish => ({
              label: commitish.name,
              value: {
                ...commitish,
                refType: type,
              },
            }))
      }
      isValidNewOption={input => type === 'commit' && isValidGitSha(input)}
      formatCreateLabel={input => `Use Commit ${getShortSha(input)}`}
      components={{
        LoadingIndicator: () => null,
        DropdownIndicator: () => null,
        IndicatorSeparator: () => null,
        MenuList: options => (
          <>
            <Nav>
              <TypeTab
                options={options}
                setType={setType}
                currType={type}
                type="branch"
                handleInputChange={reactSelectRef.current.handleInputChange}
              />
              <TypeTab
                options={options}
                setType={setType}
                currType={type}
                type="tag"
                handleInputChange={reactSelectRef.current.handleInputChange}
              />
              <TypeTab
                options={options}
                setType={setType}
                currType={type}
                type="commit"
                handleInputChange={reactSelectRef.current.handleInputChange}
              />
            </Nav>
            {options.isLoading ? <Loading /> : <components.MenuList {...options} />}
          </>
        ),
      }}
      {...props}
    />
  );
};

export default GitReferencesAutoComplete;
