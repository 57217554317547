import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { openDataModelPaneWithRiskActions } from '@src/components/Panes/DataModelPane';
import { EllipsisDiv } from '@src/style/common';
import { getTrimmedPath } from '@src/utils/stringDisplayUtils';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getDataModelTriggerDisplay = ({ trigger, ...options }) => [
  {
    weight: 3,
    content: (
      <PaneLocationLink
        id={trigger.key}
        onClick={args => openDataModelPaneWithRiskActions({ ...args, trigger, ...options })}>
        <TriggerInfoContainer
          insights={trigger.insights}
          title={getTrimmedPath(trigger.codeReference.relativeFilePath)}>
          <HorizontalStack withSeparator>
            <EllipsisDiv>{trigger.codeReference.name}</EllipsisDiv>
            <Number value={trigger.fieldCount} one="field" other="fields" />
          </HorizontalStack>
        </TriggerInfoContainer>
      </PaneLocationLink>
    ),
  },
];

export const getDataModelMessageFormat = element => element.codeReference.relativeFilePath;
