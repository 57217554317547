import { connect } from 'react-redux';
import { select } from '@src/store';
import { TimelineInfiniteScroll } from './view';

const mapState = select(
  ({
    timelineInfiniteScroll: { timeline, timelineFilterOptions, defaultFilter },
    developerProfiles: { developerProfileByIdentityKey },
    projectProfiles: { profilesByKey: projectProfilesByKey },
    repositoryProfiles: { profilesByKey: repositoryProfilesByKey, providerRepositoriesByKey },
  }) => ({
    timeline,
    timelineFilterOptions,
    defaultFilter,
    developerProfileByIdentityKey,
    projectProfilesByKey,
    repositoryProfilesByKey,
    providerRepositoriesByKey,
  })
);

const mapDispatch = ({
  timelineInfiniteScroll: {
    fetchData,
    fetchTimelineFilterOptions,
    fetchTimelineDataPage,
    onFilterChanged,
    clearData,
  },
}) => ({
  fetchData,
  fetchTimelineFilterOptions,
  fetchTimelineDataPage,
  onFilterChanged,
  clearData,
});

export default connect(mapState, mapDispatch)(TimelineInfiniteScroll);
