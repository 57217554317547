import _ from 'lodash';
import { useMemo } from 'react';

export function useUniqueId(prefix) {
  return useMemo(() => _.uniqueId(prefix), [prefix]);
}

export function useThrottle(func, { wait = 0, ...options } = {}, dependencies = []) {
  return useMemo(() => _.throttle(func, wait, options), dependencies);
}

export function useDebounce(func, { wait = 0, ...options } = {}, dependencies = []) {
  return useMemo(() => _.debounce(func, wait, options), dependencies);
}
