import { HorizontalStack } from '@src/components/HorizontalStack';
import { openServerlessFunctionPaneWithRiskActions } from '@src/components/Panes/ServerlessFunctionPane';
import { EllipsisDiv } from '@src/style/common';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getServerlessFunctionTriggerDisplay = ({ trigger, ...options }) => {
  const servicePart = trigger.serviceKey ? `${trigger.serviceKey} / ` : '';
  return [
    {
      weight: 3,
      content: (
        <PaneLocationLink
          id={trigger.key}
          onClick={args =>
            openServerlessFunctionPaneWithRiskActions({ ...args, trigger, ...options })
          }>
          <TriggerInfoContainer
            title={
              <HorizontalStack withSeparator>
                {trigger.provider && <span>{trigger.provider}</span>}
                {trigger.region && <span>{trigger.region}</span>}
                {trigger.runtime && <span>{trigger.runtime}</span>}
              </HorizontalStack>
            }
            insights={trigger.insights}>
            <HorizontalStack withSeparator>
              <EllipsisDiv>
                {servicePart}
                {trigger.functionKey}
              </EllipsisDiv>
            </HorizontalStack>
          </TriggerInfoContainer>
        </PaneLocationLink>
      ),
    },
  ];
};

export const getServerlessFunctionFormat = element =>
  element.serviceKey ? `${element.serviceKey} / ${element.functionKey}` : element.functionKey;
