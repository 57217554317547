export class Commits {
  #client;

  constructor({ apiClient }) {
    this.#client = apiClient;
  }

  async getMaterialChanges({ repositoryKey, commitSha, moduleKey }) {
    const path = repositoryKey
      ? `materialChanges/commit/${repositoryKey}/${commitSha}`
      : `materialChanges/commit/deprecated/${commitSha}`;

    return await this.#client.get(path, {
      params: moduleKey,
    });
  }
}
