export default {
  hljs: {
    display: 'block',
    overflowX: 'auto',
    padding: '0.5em',
    color: '#333',
    background: 'transparent',
  },
  'hljs-comment': {
    color: '#998',
    fontStyle: 'italic',
  },
  'hljs-quote': {
    color: '#998',
    fontStyle: 'italic',
  },
  'hljs-keyword': {
    color: '#008080',
    fontWeight: 'bold',
  },
  'hljs-selector-tag': {
    color: '#333',
    fontWeight: 'bold',
  },
  'hljs-subst': {
    color: '#333',
    fontWeight: 'normal',
  },
  'hljs-number': {
    color: '#008080',
  },
  'hljs-literal': {
    color: '#008080',
  },
  'hljs-variable': {
    color: '#008080',
  },
  'hljs-template-variable': {
    color: '#008080',
  },
  'hljs-tag .hljs-attr': {
    color: '#008080',
  },
  'hljs-string': {
    color: '#48b86d',
  },
  'hljs-doctag': {
    color: '#48b86d',
  },
  'hljs-title .hljs-type, .hljs-class': {
    color: '#48b86d',
    fontWeight: 'bold',
  },
  'hljs-section': {
    color: '#48b86d',
    fontWeight: 'bold',
  },
  'hljs-selector-id': {
    color: '#48b86d',
    fontWeight: 'bold',
  },
  'hljs-tag': {
    color: '#000080',
    fontWeight: 'normal',
  },
  'hljs-name': {
    color: '#000080',
    fontWeight: 'normal',
  },
  'hljs-attribute': {
    color: '#000080',
    fontWeight: 'normal',
  },
  'hljs-regexp': {
    color: '#009926',
  },
  'hljs-link': {
    color: '#009926',
  },
  'hljs-symbol': {
    color: '#990073',
  },
  'hljs-bullet': {
    color: '#990073',
  },
  'hljs-built_in': {
    color: '#0086b3',
  },
  'hljs-builtin-name': {
    color: '#0086b3',
  },
  'hljs-meta': {
    color: '#008080',
    fontWeight: 'bold',
  },
  'hljs-deletion': {
    background: '#fdd',
  },
  'hljs-addition': {
    background: '#dfd',
  },
  'hljs-emphasis': {
    fontStyle: 'italic',
  },
  'hljs-strong': {
    fontWeight: 'bold',
  },
};
