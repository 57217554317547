import { isObservableProp, runInAction } from 'mobx';
import { fromPromise as originalFromPromise } from 'mobx-utils';
import { suspendPromise } from '@src-v2/utils/react-suspend';

export function modify(observable, keyOrMap, value) {
  if (typeof keyOrMap === 'string') {
    keyOrMap = { [keyOrMap]: value };
  }
  if (typeof keyOrMap !== 'object') {
    throw Error('modify second parameter must be a string or an object');
  }
  runInAction(() => {
    for (const [key, value] of Object.entries(keyOrMap)) {
      observable[key] = value;
    }
  });
}

export function fromPromise(promise, prevPromise) {
  return Object.assign(originalFromPromise(promise, prevPromise), suspendPromise(promise));
}

export function autoModifyObervables(object) {
  return new Proxy(object, {
    set(target, prop, value, receiver) {
      return isObservableProp(target, prop)
        ? modify(target, prop, value) || true
        : Reflect.set(target, prop, value, receiver);
    },
  });
}
