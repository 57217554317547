import styled from 'styled-components';
import { Input } from '@src-v2/components/forms';

export const widgetBaseWidth = 300;

export const StyledInput = styled(Input)`
  ::placeholder {
    color: var(--color-blue-gray-45);
  }

  &:disabled {
    background: var(--color-blue-gray-15);
  }

  width: ${widgetBaseWidth}px;
  border: 0.25rem solid var(--color-blue-gray-35);
  border-radius: 18px;
`;
