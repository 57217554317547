import { useLayoutEffect } from 'react';

export function useDetectClickOutside(refs, handler, condition = true) {
  refs = [].concat(refs);
  useLayoutEffect(() => {
    function listener(event) {
      const isContained = ref => ref?.current?.contains(event.target);
      if (
        event.target.isConnected && // event target is connected to the DOM
        document.contains(refs[0]?.current) && // main ref is connected to the DOM
        !refs.some(isContained) && // click is not inside given refs
        !document.body.classList.contains('dragging') // there's no global dragging
      ) {
        handler(event);
      }
    }

    if (typeof handler === 'function' && condition) {
      document.addEventListener('click', listener, true);
      return () => document.removeEventListener('click', listener, true);
    }
  }, [...refs, handler, condition]);
}
