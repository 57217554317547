import styled from 'styled-components';
import { getLanguageDisplayName, isIgnoredLanguage } from '@src-v2/data/languages';
import { LanguageIconWithTooltip } from './LanguageIcon';
import { Tiles } from './Tiles';

const StyledLanguageIcon = styled(LanguageIconWithTooltip)`
  display: inline-block;
  height: 6rem;
  width: 6rem;
`;

const renderLanguages = (languagePercentages, excludePercentages) =>
  languagePercentages?.map(languagePercentage => (
    <StyledLanguageIcon
      key={languagePercentage.key}
      tip={`${getLanguageDisplayName(languagePercentage.key)}${
        excludePercentages ? '' : `: ${languagePercentage.value}% of commits`
      }`}
      name={languagePercentage.key}
    />
  ));

export const Languages = ({
  className,
  small,
  languagePercentages,
  excludePercentages,
  emptyMessage = 'No results found',
}) => {
  const languages = languagePercentages.filter(language => !isIgnoredLanguage(language.key));
  return languages.length ? (
    <Tiles
      className={className}
      small={small}
      title="Languages"
      single="language"
      plural="languages"
      tilesKeys={languages}
      renderFunction={key => renderLanguages(key, excludePercentages)}
    />
  ) : (
    <span>{emptyMessage}</span>
  );
};
