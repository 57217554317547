import { ControlledCard, ControlledCardProps } from '@src-v2/components/cards/controlled-card';
import { Feedback } from '@src-v2/components/feedback';
import { HtmlMarkdown } from '@src-v2/components/html-markdown';
import { useCveEntityContext } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/cve-entity-context-provider';
import { useCveFeedbackContext } from '@src-v2/containers/entity-pane/sca/cve-entity-pane/use-cve-feedback-context';

export function CveEntityExploitHintCard(props: ControlledCardProps) {
  const { cveEntity } = useCveEntityContext();
  const feedbackContext = useCveFeedbackContext(cveEntity.triage.exploitExample);

  return (
    <ControlledCard {...props} title="Exploit hint" footer={<Feedback context={feedbackContext} />}>
      <HtmlMarkdown>{cveEntity.triage.exploitExample.toString()}</HtmlMarkdown>
    </ControlledCard>
  );
}
