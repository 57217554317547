import { observer } from 'mobx-react';
import { useCallback } from 'react';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { ProfileCard } from '@src-v2/components/cards/profile-card';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Dropdown } from '@src-v2/components/dropdown';
import { DropdownMenu } from '@src-v2/components/dropdown-menu';
import { SvgIcon } from '@src-v2/components/icons';
import { Paragraph, Subtitle, Title } from '@src-v2/components/typography';
import { resourceTypes } from '@src-v2/data/rbac-types';
import { useInject } from '@src-v2/hooks';
import { useModalState } from '@src-v2/hooks/use-modal-state';
import { preventDefault } from '@src-v2/utils/dom-utils';
import { makeUrl } from '@src-v2/utils/history-utils';
import {
  ActiveContributorsWidget,
  ContributorsWidget,
} from '@src/blocks/ConsumableInfiniteScroll/blocks/ActiveContributorsWidget';
import { ProductsCountWidget } from '@src/blocks/ConsumableInfiniteScroll/blocks/ProductsCountWidget';
import { BusinessImpactBadge } from '@src/blocks/ConsumableInfiniteScroll/blocks/ProfileRiskBadge';
import RiskyChangesWidget from '@src/blocks/ConsumableInfiniteScroll/blocks/RiskyChangesWidget';
import TopLanguagesWidget from '@src/blocks/ConsumableInfiniteScroll/blocks/TopLanguagesWidget';

export const TeamCard = observer(({ profile }) => {
  const { rbac, toaster, contributors, history } = useInject();

  const {
    name,
    teamType,
    languagePercentages,
    contributorCount,
    activeContributorCount,
    sampleContributorData,
    productsCount,
    repositoriesCount,
    riskyChanges,
    highlightedContributor,
    profileCalculationComplete,
    key,
    teamSyncType,
  } = profile;

  const [modalElement, setModal, closeModel] = useModalState();

  const handleTeamEdit = useCallback(async () => {
    const { defaultValues } = await contributors.getTeam({ key });
    history.push(`/users/teams/${key}/edit`, { defaultValues, teamSyncType });
  }, [key, teamSyncType]);

  const handleTeamRemove = useCallback(
    () =>
      setModal(
        <ConfirmationModal
          title={`Delete ${name}?`}
          submitStatus="failure"
          submitText="Delete"
          onClose={closeModel}
          onSubmit={() => {
            contributors
              .deleteTeam({ key })
              .catch(() => toaster.error(`Failed to delete ${name}`))
              .finally(closeModel);
          }}>
          Are you sure you want to delete this team?
        </ConfirmationModal>
      ),
    [setModal, closeModel]
  );

  return (
    <>
      <ProfileCard
        to={makeUrl(productsCount ? '/profiles/applications' : '/profiles/repositories', {
          fl: { TeamKeys: { values: [key] } },
        })}>
        <Header>
          <Description>
            <Title>{name}</Title>
            <Subtitle>{teamType}</Subtitle>
          </Description>
          {rbac.canEdit(resourceTypes.Teams) && (
            <DropdownMenu
              data-name="team-actions"
              onClick={preventDefault}
              onItemClick={preventDefault}>
              <Dropdown.Item disabled={!profileCalculationComplete} onClick={handleTeamEdit}>
                <SvgIcon name="Edit" /> Edit
              </Dropdown.Item>
              <Dropdown.Item onClick={handleTeamRemove}>
                <SvgIcon name="Trash" /> Delete
              </Dropdown.Item>
            </DropdownMenu>
          )}
        </Header>
        {profileCalculationComplete ? (
          <Widgets>
            <ProductsCountWidget
              productsCount={productsCount}
              repositoriesCount={repositoriesCount}
            />
            <TopLanguagesWidget languagePercentages={languagePercentages} />
            <ActiveContributorsWidget
              activeContributorCount={activeContributorCount}
              contributorCount={contributorCount}
              contributorData={sampleContributorData}
            />
            <RiskyChangesWidget riskyChanges={riskyChanges} />
            {highlightedContributor && (
              <ContributorsWidget
                tip={highlightedContributor.displayName}
                label={highlightedContributor.pointOfContactTitle}
                contributorData={[highlightedContributor]}
              />
            )}
            <BusinessImpactBadge profile={profile} />
          </Widgets>
        ) : (
          <CalculatingTeam>
            <LogoSpinner />
            <Paragraph>Creating team...</Paragraph>
          </CalculatingTeam>
        )}
      </ProfileCard>
      {modalElement}
    </>
  );
});

const Header = styled.header`
  display: flex;
  justify-content: space-between;
  line-height: 1;
`;

const Description = styled.div``;

const Widgets = styled.div`
  height: 18rem;
  display: flex;
  gap: 12rem;
`;

const CalculatingTeam = styled.div`
  align-self: center;
  text-align: center;
  height: 18rem;

  ${Paragraph} {
    margin-top: 3rem;
  }
`;
