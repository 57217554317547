import { TimelineEvent } from '@src/blocks/TimelineInfiniteScroll/components/TimelineEvent';

export const TimelineBranchForcePush = ({
  branchForcePushRuleTimelineEvent,
  previousTimelineEvent,
  repositoryProfilesByKey,
  ...props
}) => {
  let title = 'Branch protection force push modified';
  if (
    branchForcePushRuleTimelineEvent.materialChangesLabels !== null &&
    branchForcePushRuleTimelineEvent.materialChangesLabels.length > 0
  ) {
    [title] = branchForcePushRuleTimelineEvent.materialChangesLabels;
  }

  const { repository } =
    repositoryProfilesByKey[branchForcePushRuleTimelineEvent.repositoryKeys[0]];

  return (
    <TimelineEvent
      currentTimelineEvent={branchForcePushRuleTimelineEvent}
      previousTimelineEvent={previousTimelineEvent}
      {...props}
      titleContent={title}
      body={
        <div>
          <div>
            Force push policy for branch '{branchForcePushRuleTimelineEvent.branchName}' in
            repository '{repository.name}' (server URL: '{repository.serverUrl}
            ') was modified and is now set to{' '}
            {branchForcePushRuleTimelineEvent.governanceThenSubType === 'Discovered'
              ? 'allowed'
              : 'not allowed'}
          </div>
        </div>
      }
    />
  );
};
