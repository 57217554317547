import _ from 'lodash';
import cloneDeep from 'lodash/cloneDeep';
import ioc from '@src-v2/ioc';
import apiService from '@src/services/apiService';

const newRoleConfiguration = () => ({
  key: crypto.randomUUID(),
  name: '',
  description: '',
  identifiers: [],
  apiiroGroupKeys: [],
  permissions: [],
  userScope: { isGlobal: true },
});

const clearData = () => ({
  roleConfigurations: null,
  supportedPermissions: [],
  roleToEdit: null,
});

export default {
  state: clearData(),
  reducers: {
    clearData,
    setRoleConfigurations: (state, roleConfigurations) => ({
      ...state,
      roleConfigurations,
    }),
    setServers: (state, servers) => ({
      ...state,
      servers,
    }),
    createNew: state => ({
      ...state,
      isModalOpen: true,
      isEdit: false,
      roleToEdit: newRoleConfiguration(),
    }),
    onRoleEdited: (state, editedRole) => ({
      ...state,
      roleToEdit: {
        ...editedRole,
      },
    }),
    setSupportedPermissions: (state, supportedPermissions) => ({
      ...state,
      supportedPermissions,
    }),
    closeEditModal: state => ({
      ...state,
      roleToEdit: null,
      isModalOpen: false,
    }),
    setEditedRole: (state, roleToEdit) => ({
      ...state,
      isModalOpen: true,
      roleToEdit: cloneDeep(roleToEdit),
      isEdit: true,
    }),
  },
  selectors: slice => ({
    roleConfigurations: () => slice(state => state.roleConfigurations),
    servers: () => slice(state => state.servers),
    isModalOpen: () => slice(state => state.isModalOpen),
    roleToEdit: () => slice(state => state.roleToEdit),
    isRoleValid: () => slice(state => state.isRoleValid),
    isEdit: () => slice(state => state.isEdit),
    supportedPermissions: () => slice(state => state.supportedPermissions),
  }),
  effects: dispatch => ({
    async fetchData() {
      const [allRoleConfigurations, supportedPermissions, servers] = await Promise.all([
        apiService.get('/api/roles', { clearCacheEntry: true }),
        apiService.get('/api/roles/supportedPermissions', { clearCacheEntry: true }),
        ioc.connectors.getProviderGroups().then(groups =>
          _.uniqBy(
            groups.flatMap(group => group.servers),
            'url'
          ).filter(Boolean)
        ),

        dispatch.assetCollectionProfiles.fetchAssetConfigurationsAsync(),
        dispatch.applicationGroupProfiles.fetchApplicationGroupAssetConfigurationsAsync(),
      ]);
      this.setRoleConfigurations(allRoleConfigurations.data);
      this.setSupportedPermissions(supportedPermissions.data);
      this.setServers(servers);
    },
    async saveRole(role) {
      await apiService.put('/api/roles', role);
    },
    async deleteRole(role) {
      await apiService.delete(`/api/roles/${encodeURIComponent(role.key)}`);
    },
  }),
};
