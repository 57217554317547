import { useCallback } from 'react';
import styled from 'styled-components';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { useInject } from '@src-v2/hooks';

export function SupportModal({ shouldCloseOnSubmit = true, ...props }) {
  const { application, toaster } = useInject();
  const handleRequest = useCallback(
    async data => {
      try {
        const description = [data.comment, data.description].filter(Boolean).join('\n');
        await application.requestSupport({
          ...data,
          description,
          type: data.type?.key ?? 'Bug',
          severity: data.type?.key === 'ReportAnIssue' ? data.severity?.key : '',
          file: data.file ?? null,
        });
        props.onSubmit?.();

        if (shouldCloseOnSubmit) {
          toaster.success('Thank you for reaching out!');
          props.onClose?.();
        }
      } catch (error) {
        toaster.error('Something went wrong');
      }
    },
    [application, toaster, shouldCloseOnSubmit, props.onClose, props.onSubmit]
  );

  return <Modal {...props} submitText="Send" onSubmit={handleRequest} />;
}

const Modal = styled(ConfirmationModal)`
  width: 225rem;
`;
