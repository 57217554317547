import _ from 'lodash';
import { useCallback, useMemo, useRef } from 'react';
import { Divider } from '@src-v2/components/divider';
import { Checkbox } from '@src-v2/components/forms/checkbox';
import { LabelToggle } from '@src-v2/components/forms/label-toggle';
import { SvgIcon } from '@src-v2/components/icons';
import { OptionalTitle } from '@src-v2/components/optional-title';
import { useDetectClickOutside } from '@src-v2/hooks';
import { useDetectEscapePress } from '@src-v2/hooks/dom-events/use-detect-escape-press';
import Collapsible from '@src/components/Collapsible';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { VerticalStack } from '@src/components/VerticalStack';
import filtersService from '@src/services/filtersService';
import SelectedFiltersBox from './Blocks/SelectedFiltersBox';
import {
  FilterGroupCount,
  FilterGroupHeader,
  FiltersBehaviorContainer,
  StyledActiveFilterCount,
  StyledClosedNonEmptyFilter,
  StyledDescription,
  StyledFilterIconClosed,
  StyledFilterIconClosedButton,
  StyledFilterIconOpen,
  StyledFilterIconOpenButton,
  StyledFilterOption,
  StyledFilterOptionCard,
  StyledFiltersCard,
  StyledHeaderHorizontalStack,
  StyledOptionName,
  StyledSearch,
} from './Blocks/Styles';

const getFiltersCount = filterOptions =>
  _.sum(
    filterOptions.map(filterGroup => filterGroup.options.filter(option => option.isSelected).length)
  );

export const MultiSelectFilter = ({
  dropdownOpen,
  searchTerm,
  setDropdownOpen,
  setSearchTerm,

  filterType,
  filterOptions,
  clearFilters,
  updateFilterType,
  handleFilterToggle,
  supportFilterTypeToggle,
  inlineFilters,
  description,

  maxOpenCategories = 3,
  closedStyle,
}) => {
  const ref = useRef();
  const filterTypes = useMemo(() => Object.values(filtersService.filterTypes), []);

  const toggleDropdown = newDropdownState => {
    setDropdownOpen(Boolean(newDropdownState) || !dropdownOpen);
    setSearchTerm('');
  };

  useDetectEscapePress(() => toggleDropdown(false), dropdownOpen);
  useDetectClickOutside(ref, () => toggleDropdown(false), dropdownOpen);

  const shouldDisplayFilter = useCallback(
    filterName =>
      !_.isEmpty(filterName) && filterName.toLowerCase().includes(searchTerm.toLowerCase()),
    [searchTerm]
  );

  const getGroupSize = filterGroup =>
    filterGroup.options.filter(option => shouldDisplayFilter(option.displayName)).length;

  const filterGroupsToDisplay = filterOptions.filter(filterGroup => getGroupSize(filterGroup) > 0);
  const displayFilterOptions = _.orderBy(filterGroupsToDisplay, [
    'sortOrder',
    group => group.componentDisplayName ?? group.component,
  ]).map((filterGroup, index) => (
    <div key={filterGroup.component}>
      <Collapsible
        spread
        expanded={index < maxOpenCategories}
        title={
          <HorizontalStack withSeparator spacing={0.3}>
            <FilterGroupHeader>
              {filterGroup.componentDisplayName ?? filterGroup.component}
            </FilterGroupHeader>
            <FilterGroupCount>{getGroupSize(filterGroup)}</FilterGroupCount>
          </HorizontalStack>
        }>
        {_.orderBy(filterGroup.options, ['sortOrder', 'displayName']).map(
          filterOption =>
            shouldDisplayFilter(filterOption.displayName) && (
              <StyledFilterOption key={filterGroup.component + filterOption.name}>
                <Checkbox
                  checked={filterOption.isSelected}
                  onChange={() => handleFilterToggle(filterOption.name, filterGroup.component)}
                />
                <OptionalTitle as={StyledOptionName} title={filterOption.displayName}>
                  {filterOption.displayName.replace(/^https?:\/\//, '')}
                </OptionalTitle>
              </StyledFilterOption>
            )
        )}
      </Collapsible>
    </div>
  ));

  const filterCount = getFiltersCount(filterOptions);
  return dropdownOpen ? (
    <StyledFiltersCard ref={ref} $inline={inlineFilters} className="MultiSelectFilter">
      <StyledHeaderHorizontalStack spacing="0">
        <StyledSearch
          autoFocus
          autoComplete="off"
          name="search"
          placeholder="Search filters"
          onChange={event => setSearchTerm(event.target.value)}
        />
        <SvgIcon name="Search" />
        <StyledFilterIconOpenButton onClick={() => toggleDropdown(false)}>
          <StyledFilterIconOpen open />
        </StyledFilterIconOpenButton>
      </StyledHeaderHorizontalStack>

      {supportFilterTypeToggle && (
        <FiltersBehaviorContainer>
          <FilterGroupHeader>Filters Operator</FilterGroupHeader>
          <LabelToggle labels={filterTypes} defaultLabel={filterType} onChange={updateFilterType} />
        </FiltersBehaviorContainer>
      )}

      <StyledFilterOptionCard ref={ref} className="MultiSelectFilter">
        {displayFilterOptions}
      </StyledFilterOptionCard>

      <SelectedFiltersBox
        filterCount={filterCount}
        clearFilters={clearFilters}
        filterOptions={filterOptions}
        handleFilterToggle={handleFilterToggle}
      />
      {description && (
        <VerticalStack>
          <Divider />
          <StyledDescription>{description}</StyledDescription>
        </VerticalStack>
      )}
    </StyledFiltersCard>
  ) : filterCount === 0 ? (
    <StyledFilterIconClosedButton style={closedStyle} onClick={toggleDropdown}>
      <StyledFilterIconClosed size={inlineFilters ? 'small' : 'large'} $inline={inlineFilters} />
    </StyledFilterIconClosedButton>
  ) : (
    <StyledClosedNonEmptyFilter style={closedStyle} spacing="0" $inline={inlineFilters}>
      <StyledActiveFilterCount $inline={inlineFilters}>
        <Number value={filterCount} one="active filter" other="active filters" />
      </StyledActiveFilterCount>
      <StyledFilterIconOpenButton onClick={toggleDropdown}>
        <StyledFilterIconOpen $inline={inlineFilters} />
      </StyledFilterIconOpenButton>
    </StyledClosedNonEmptyFilter>
  );
};
