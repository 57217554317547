import mixpanel from 'mixpanel-browser';
import { autorun, makeObservable, observable, when } from 'mobx';
import { modify } from '@src-v2/utils/mobx-utils';

export class Analytics {
  static automationIds = ['automation@test.com', 'apiiroautomation@gmail.com'];
  static matchInternalUser = /(@apiiro.com)$/i;

  #mixpanelToken;
  #application;
  #session;
  #sessionProperties;

  initialized = false;

  constructor({ application, session, config: { MIXPANEL_ID } }) {
    this.#mixpanelToken = MIXPANEL_ID;
    this.#application = application;
    this.#session = session;

    makeObservable(this, {
      initialized: observable,
    });

    when(
      () => application.initialized,
      () => this.initialize()
    );
  }

  get canReport() {
    const userId = this.#session.data?.consoleUser.id;
    return this.initialized && !Analytics.automationIds.includes(userId);
  }

  initialize() {
    if (!this.#application.configuration.isAnalyticsEnabled) {
      return false;
    }

    const environmentID = this.#session.data?.environmentId;
    const environmentName = this.#session.data?.environmentName;

    mixpanel.init(this.#mixpanelToken, { persistence: 'localStorage' });

    autorun(() => {
      this.#sessionProperties = {
        'Application Version': this.#application.version,
        'Environment ID': environmentID,
        'Environment Name': environmentName,
        'User ID': this.#session.data?.consoleUser?.id,
        'User Name': this.#session.data?.consoleUser?.name,
        'Feature Flags': this.#session.data?.betaFeatures,
        Demo: this.#session.data?.isDemo,
        Trial: this.#session.data?.isTrial,
        Internal: Analytics.matchInternalUser.test(this.#session.data?.consoleUser.id),
        SaaS: this.#session.data?.isSaas,
      };

      if (this.#session.data?.consoleUser.id) {
        mixpanel.identify(this.#session.data.consoleUser.id);
      }

      mixpanel.people.set_once({
        'First Seen': new Date().toISOString(),
      });

      mixpanel.people.set({
        Name: this.#session.username,
        Email: this.#session.data?.userAccount?.email,
      });

      mixpanel.people.union('Environments', this.#session.data?.environmentId);
    });

    modify(this, 'initialized', true);
  }

  track(eventName, properties = null, options = null, callback = null) {
    if (this.canReport) {
      mixpanel.track(eventName, { ...this.#sessionProperties, ...properties }, options, callback);
    }
  }
}
