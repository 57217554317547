import _ from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useRouteMatch } from 'react-router-dom';
import styled from 'styled-components';
import { useInject } from '@src-v2/hooks';
import ClientTableInfiniteScroll from '@src/blocks/ClientTableInfiniteScroll';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';
import DetailsPaneHeader from '@src/blocks/RiskPosture/blocks/DetailsPane/DetailsPaneHeader';
import {
  getTriggerContext,
  getTriggerType,
  getTriggersElementsSorter,
} from '@src/blocks/RiskPosture/blocks/DetailsPane/TriggerDataUtils';
import {
  getTriggerDisplay,
  getTriggerMessage,
  openRiskTriggerPane,
} from '@src/blocks/RiskPosture/blocks/DetailsPane/TriggerDisplayUtils';
import OpenPaneIndicator from '@src/blocks/RiskPosture/blocks/OpenPaneIndicator';
import {
  onPaneClose,
  riskPanePrefix,
  triggerPanePrefix,
} from '@src/blocks/RiskPosture/blocks/TriggersDisplay/Common';
import {
  DismissActionsDropdown,
  TakeActionsDropdown,
  createActionsData,
} from '@src/blocks/RiskRuleTriggers/blocks/RiskTriggerActionsMenu';
import { ExploreActionsMenu } from '@src/components/CodeAggregation/Common';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import elementsFilterProvider from '@src/services/ElementsFilters/elementsFilterProvider';
import { riskOrder } from '@src/services/riskService';
import { ActionResults } from './blocks/ActionResults';
import TriggerStatus from './blocks/TriggerStatus';

const RiskRuleTriggers = observer(
  ({
    profile,
    previousProfile,
    profileTriggers,
    ruleKey,
    profileType,
    ruleTriggers,
    clearData,
    fetchData,
    governanceRulesByKey,
    secondaryPane,
    isRelease,
  }) => {
    const { application, toaster } = useInject();
    const { pathname } = useLocation();
    const firstTrigger = ruleTriggers?.triggers?.[0];
    const governanceRule = governanceRulesByKey[ruleKey];
    const [mounted, setMounted] = useState(false);
    const [openedMenuTriggerKey, setOpenedMenuTriggerKey] = useState();

    useEffect(() => {
      fetchData({ profileTriggers, ruleKey, profile });
      return clearData;
    }, [fetchData, clearData, profileTriggers, ruleKey, profile]);

    const [triggerType, filterItemsFunction] = useMemo(() => {
      const triggerType = ruleTriggers?.triggers.every(
        trigger => getTriggerType(trigger) === getTriggerType(firstTrigger)
      )
        ? getTriggerType(firstTrigger)
        : null;
      return [triggerType, elementsFilterProvider.getFilterFunction(triggerType, true)];
    }, [ruleTriggers, firstTrigger]);

    const triggers = useMemo(() => {
      if (_.isNil(firstTrigger)) {
        return null;
      }
      const triggerElementSorter =
        triggerType !== null ? getTriggersElementsSorter(firstTrigger) : _.identity;
      return _.orderBy(
        triggerElementSorter(ruleTriggers.triggers),
        [trigger => riskOrder[trigger.riskLevel], 'riskOrder'],
        ['desc', 'desc']
      );
    }, [firstTrigger, governanceRule, profile, ruleTriggers, triggerType]);

    const [basePath] = pathname.split(`/${riskPanePrefix}/`);
    const triggerRouteMatch = useRouteMatch(
      `${basePath}/${riskPanePrefix}/:ruleKey/${triggerPanePrefix}/:triggerKey`
    );

    useEffect(() => {
      if (_.isNil(triggers) || mounted) {
        return;
      }

      setMounted(true);

      if (!triggerRouteMatch?.params.triggerKey) {
        return;
      }

      const activeTrigger = triggers.find(t => t.key === triggerRouteMatch?.params.triggerKey);

      if (_.isNil(activeTrigger)) {
        toaster.success({ message: "Congrats! This risk doesn't exist anymore." });
      } else {
        openRiskTriggerPane({
          ruleKey,
          profileType,
          trigger: activeTrigger,
          level: secondaryPaneLevel,
          relevantPath: riskPanePrefix,
          externalRiskTriggerPane: true,
          profile: getTriggerContext(activeTrigger, profile, previousProfile),
          onClose: () => onPaneClose(triggerPanePrefix),
        });
      }
    }, [
      triggers,
      pathname,
      mounted,
      profile,
      ruleKey,
      profileType,
      triggerRouteMatch?.params.triggerKey,
    ]);

    if (_.isEmpty(ruleTriggers)) {
      return null;
    }

    return (
      <>
        <DetailsPaneHeader ruleTriggers={ruleTriggers} />
        <ClientTableInfiniteScroll
          small
          cellsBackground
          allowSearchAndFilter
          scrollParent="parentNode"
          gridColor="var(--color-blue-gray-15)"
          tableScope={`riskTriggers-${triggerType}`}
          allItems={triggers}
          resultName="Component"
          resultNamePlural="Components"
          filterItemsFunc={filterItemsFunction}
          searchPlaceholder={elementsFilterProvider.getSearchTerm(triggerType)}
          initialFilterOptions={elementsFilterProvider.getFilterOptions({
            profile,
            elements: triggers,
            elementsType: triggerType,
            filterByRisk: true,
            hasExternalConnectivity: application.externalConnectivity,
            integrations: application.integrations,
          })}
          headers={[
            { name: '', weight: 1 },
            { name: '', fixedWidth: 80, requiresNonEmpty: true, align: 'center' },
            { name: '', fixedWidth: 51, requiresNonEmpty: true, align: 'center' },
          ]}
          rowProvider={trigger => {
            trigger.riskLevel ??= ruleTriggers.riskLevel;
            const currentElementProfile = getTriggerContext(trigger, profile, previousProfile);
            const riskLevel =
              isRelease && trigger.riskChange === 'Resolved'
                ? 'Compliant'
                : trigger.riskLevel ?? 'None';
            const columns = getTriggerDisplay(trigger)({
              ruleKey,
              trigger,
              profile: currentElementProfile,
              profileType,
              profileTriggers,
              messageContent: getTriggerMessage(trigger)(trigger),
            });
            columns[0].getSideElement = hovered => (
              <OpenPaneIndicator
                paneLevel={secondaryPaneLevel}
                riskLevel={riskLevel}
                selected={
                  hovered ||
                  openedMenuTriggerKey === trigger.key ||
                  secondaryPane.id === trigger.key
                }
              />
            );

            const messageContent = getTriggerMessage(trigger)(trigger);
            const elementRepository = getRepositoryForInventoryPropertyFromProfileByKey(
              trigger.elementEntityKey,
              currentElementProfile
            );

            const actionsData = createActionsData(
              trigger,
              ruleTriggers.rule,
              profile,
              elementRepository
                ? {
                    key: elementRepository.key,
                    name: elementRepository.uniqueName ?? elementRepository.name,
                    type: 'RepositoryProfile',
                  }
                : undefined,
              profile.profileType === 'ApplicationProfile'
                ? [{ key: profile.key, type: profile.profileType }]
                : [],
              messageContent
            );

            _.last(columns).menu = [
              <ExploreActionsMenu
                codeReference={trigger.codeReference}
                annotatedRepositoryAndModuleReferences={trigger}
                repository={elementRepository}
                entityTitle={messageContent}
                profile={profile}
                defaultPaneOpenOptions={{ level: secondaryPaneLevel }}
              />,
              !isRelease && <DismissActionsDropdown data={actionsData} />,
              !isRelease && (
                <TakeActionsDropdown data={actionsData} onClose={setOpenedMenuTriggerKey} />
              ),
            ];
            _.last(columns).end = <ActionResultsPopover actionsTaken={trigger} />;

            return {
              key: trigger.key + trigger.elementKey + trigger.elementEntityKey + trigger.entityId,
              cells: [
                ...columns,
                { content: trigger.riskChange },
                {
                  content: (
                    <TriggerStatus
                      riskLevel={riskLevel}
                      triggerRiskOverride={trigger.triggerRiskOverride}
                    />
                  ),
                },
              ],
            };
          }}
        />
      </>
    );
  }
);

export default RiskRuleTriggers;

const ActionResultsPopover = styled(ActionResults)`
  padding-right: 1rem;
`;
