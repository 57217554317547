import _ from 'lodash';
import { RiskBadge } from '@src-v2/components/risk/risk-badge';
import { noRisk } from '@src-v2/data/risk-data';

export default function OldRiskBadge({ riskLevel, ...props }) {
  riskLevel = _.camelCase(riskLevel);
  return (
    riskLevel !== noRisk && (
      <RiskBadge.RiskLevel {...props} riskLevel={riskLevel}>
        {riskLevel}
      </RiskBadge.RiskLevel>
    )
  );
}
