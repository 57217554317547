import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { DateTime } from '@src-v2/components/time';
import { dateFormats } from '@src-v2/data/datetime';
import { pluralFormat } from '@src-v2/utils/number-utils';
import Bold from '@src/components/Bold';
import { Number } from '@src/components/Number';
import { Ellipsis, FontBody } from '@src/style/common';
import { StyledInsightsSubTitle } from './CodeAggregation/Insights/InsightStyles';
import Collapsible from './Collapsible';
import { GatewayTypeIcon } from './GatewayTypeIcon';
import { HorizontalStack } from './HorizontalStack';
import { Link } from './Link';
import { Tooltip } from './Tooltip';
import { TrimmedVerticalStack } from './TrimmedVerticalStack';
import { VerticalStack } from './VerticalStack';

export const StyledCollapsibleBody = styled(VerticalStack)`
  ${FontBody};
  margin-top: 18px;
  margin-left: 30px;
`;

const StyledEndpointBody = styled.div`
  ${Ellipsis};
  opacity: 0.7;
`;

export const ApiGatewayReferenceDescription = ({ apiGatewayReference }) => (
  <Collapsible
    title={
      <Link url={apiGatewayReference.gatewayUrl} tip="Open Gateway Settings" external>
        <StyledInsightsSubTitle>
          <HorizontalStack>
            <span>API is exposed through</span>
            <GatewayTypeIcon provider={apiGatewayReference.provider} />
            <span>{apiGatewayReference.displayName}</span>
          </HorizontalStack>
        </StyledInsightsSubTitle>
      </Link>
    }>
    <StyledCollapsibleBody>
      {apiGatewayReference.routeExposureTime ? (
        <span>
          Route
          <Bold>{apiGatewayReference.exposingRoute}</Bold> exposed at{' '}
          <Bold>
            <DateTime date={apiGatewayReference.routeExposureTime} format={dateFormats.longDate} />
          </Bold>
        </span>
      ) : null}
      <HorizontalStack>
        <span>Group includes</span>
        <Tooltip
          tip={
            <TrimmedVerticalStack
              items={apiGatewayReference.operations.map(operation => (
                <HorizontalStack>
                  {operation.method && <span>{operation.method}</span>}
                  <span>{operation.urls.join(', ')}</span>
                </HorizontalStack>
              ))}
              maxChildren={10}
              nameSingle="route"
              namePlural="routes"
            />
          }>
          <Number value={apiGatewayReference.operations.length} one="API" other="APIs" underline />
        </Tooltip>
      </HorizontalStack>
      {!isEmpty(apiGatewayReference.serviceEndpointUrls) && (
        <ApiGatewayInfoLine
          title={`HTTP(s) 
              ${pluralFormat(
                apiGatewayReference.serviceEndpointUrls.length,
                'endpoint',
                'endpoints'
              )}`}
          value={apiGatewayReference.serviceEndpointUrls.join(', ')}
        />
      )}
      {!isEmpty(apiGatewayReference.listenPath) && (
        <ApiGatewayInfoLine title="Listen Path:" value={apiGatewayReference.listenPath} />
      )}
      {!isEmpty(apiGatewayReference.listenPort) && (
        <ApiGatewayInfoLine title="Listen Port:" value={apiGatewayReference.listenPort} />
      )}
      {!isEmpty(apiGatewayReference.forwardUrl) && (
        <ApiGatewayInfoLine title="Forward URL:" value={apiGatewayReference.forwardUrl} />
      )}
      {!isEmpty(apiGatewayReference.forwardPath) && (
        <ApiGatewayInfoLine title="Forward Path:" value={apiGatewayReference.forwardPath} />
      )}
      {!isEmpty(apiGatewayReference.apiSecurityControls) && (
        <VerticalStack>
          <StyledInsightsSubTitle>API security controls:</StyledInsightsSubTitle>
          <div>{apiGatewayReference.apiSecurityControls.join(', ')}</div>
        </VerticalStack>
      )}
    </StyledCollapsibleBody>
  </Collapsible>
);

const ApiGatewayInfoLine = ({ title, value }) => (
  <HorizontalStack>
    <span>{title}</span>
    <StyledEndpointBody>{value}</StyledEndpointBody>
  </HorizontalStack>
);
