import styled, { css } from 'styled-components';

export const Col = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%;
`;

export const FormGroup = styled.div`
  ${props =>
    props.check
      ? css`
          position: relative;
          display: block;
          padding-left: 20px;
        `
      : css`
          margin-bottom: 16px;
        `}
`;

export const Label = styled.label`
  display: inline-block;
  margin-bottom: ${props => (props.check ? '0' : '8px')};
`;

export const formControlStyle = css`
  display: block;
  width: 100%;
  height: calc(1.5em + 12px + 2px);
  padding: 6px 12px;
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 4px;
  transition:
    border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;

  &::-ms-expand {
    background-color: transparent;
    border: 0;
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 #495057;
  }

  &:focus {
    color: #495057;
    background-color: #fff;
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 3.2px rgba(0, 123, 255, 0.25);
  }

  &::placeholder {
    color: #6c757d;
    opacity: 1;
  }

  &:disabled {
    background-color: #e9ecef;
    opacity: 1;
  }

  &.is-invalid {
    border-color: #dc3545;
    padding-right: calc(1.5em + 12px);
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23dc3545' viewBox='0 0 12 12'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 3px) center;
    background-size: calc(0.75em + 6px) calc(0.75em + 6px);
  }
`;

const formCheckInputStyle = css`
  position: relative;
  margin-top: 2px;
  margin-left: -20px;
  margin-right: -3px;
`;

const StyledInput = styled.input`
  ${props =>
    ['radio', 'checkbox'].indexOf(props.type) > -1 ? formCheckInputStyle : formControlStyle};

  &::placeholder {
    font-size: var(--font-size-xs);
    font-weight: 400;
    color: var(--color-blue-gray-50);
  }

  &:disabled {
    background-color: var(--color-blue-gray-15);
  }
`;

export const Input = ({ invalid, className = '', ...props }) => (
  <StyledInput {...props} className={`${className} ${invalid ? 'is-invalid' : ''}`} />
);
