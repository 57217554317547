import _ from 'lodash';
import styled from 'styled-components';
import { DateTime, TimeTooltip } from '@src-v2/components/time';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink } from '@src-v2/components/typography';
import { dateFormats } from '@src-v2/data/datetime';
import { CodeFindingDescription } from '@src/components/CodeFindingDescription';
import { Number } from '@src/components/Number';
import { StyledItem, TitledList } from '@src/components/TitledList';
import { FontBodyLargeHeader } from '@src/style/common';
import { HorizontalStack } from './HorizontalStack';
import { VerticalStack } from './VerticalStack';

export const StyledInsightsTitleBar = styled(HorizontalStack)`
  margin-top: 9rem;
`;

const StyledInsightsTitle = styled(HorizontalStack)`
  ${FontBodyLargeHeader};

  ${StyledItem} {
    padding-left: 0;
  }
`;

const FindingsCountByType = findings => {
  const counters = {};
  findings.forEach(finding => {
    counters[finding.type] ??= 0;
    counters[finding.type]++;
  });
  return Object.entries(counters);
};

export const FindingsByProviderDescription = ({ provider, findings, repository }) => {
  const scanDescription = [];
  if (findings[0].scanProjectName) {
    scanDescription.push(findings[0].scanProjectName);
  }
  if (findings[0].time) {
    if (!scanDescription.length) {
      scanDescription.push(', ');
    }
    scanDescription.push(
      <TimeTooltip date={findings[0].time} key={findings[0].time}>
        <DateTime date={findings[0].time} format={dateFormats.longDate} />
      </TimeTooltip>
    );
  }
  return (
    <VerticalStack>
      <StyledInsightsTitleBar>
        <StyledInsightsTitle>
          <span>Latest {provider} scan</span>
          {scanDescription.length > 0 && <span>({scanDescription.slice(0, 3)})</span>}
        </StyledInsightsTitle>
        {(findings[0].reportUrl || findings[0].url) && (
          <Tooltip content="Open Scan">
            <ExternalLink href={findings[0].reportUrl || findings[0].url}>View Scan</ExternalLink>
          </Tooltip>
        )}
      </StyledInsightsTitleBar>
      {FindingsCountByType(findings).map(([type, count]) => (
        <div key={type}>
          Found{' '}
          <Number one={`${type} vulnerability`} other={`${type} vulnerabilities`} value={count} />{' '}
          involving this API
        </div>
      ))}
      <TitledList
        list={_.map(findings, finding => (
          <VerticalStack>
            <CodeFindingDescription finding={finding} repository={repository} />
            <span>----</span>
          </VerticalStack>
        ))}
      />
    </VerticalStack>
  );
};
