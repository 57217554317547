import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { ConfirmationModal } from '@src-v2/components/confirmation-modal';
import { Modal } from '@src-v2/components/modals';

export const ActionModal = styled(props => <ConfirmationModal {...props} />)`
  position: absolute;
  inset: 0 0 auto 0;
  width: 135rem;
  border-radius: 3rem;

  ${Modal.Header} {
    padding-bottom: 0;
    border-bottom: none;
  }

  ${Modal.Title} {
    flex-direction: column;
    align-items: center;
    align-items: center;
    font-size: var(--font-size-xl);
    gap: 1rem;
  }

  ${Modal.Content} {
    max-height: 60vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    font-size: var(--font-size-s);
    gap: 4rem;

    ${LogoSpinner} {
      height: 6rem;
      width: 6rem;
    }
  }

  ${Modal.Footer} {
    height: 18rem;
    padding: 0 8rem;
    border-top: none;
  }
`;
