import _ from 'lodash';
import { Link } from 'react-router-dom';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';

export function getModule(profile, relativeFilePath) {
  const [matchingModule = null] = _.orderBy(
    profile.modules.filter(module => relativeFilePath.startsWith(module.root)),
    root => root.length
  ).slice(-1);
  return matchingModule;
}

export function getModuleLinkByPath(profile, relativeFilePath, StyledContent, subRoute) {
  const module = getModule(profile, relativeFilePath);
  return module ? (
    <Tooltip content={module.root}>
      <Link
        to={`/module/${module.repositoryKey ?? profile.key}/${encodeURIComponent(
          module.root
        )}/inventory/${subRoute}`}>
        <StyledContent>{module.root}</StyledContent>
      </Link>
    </Tooltip>
  ) : null;
}
