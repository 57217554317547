import { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { InlineButton } from '@src-v2/components/buttons';
import { BaseIcon } from '@src-v2/components/icons';
import { RiskIcon } from '@src-v2/components/risk/risk-icon';
import { MaterialChanges } from '@src-v2/containers/commit/material-changes/material-changes';
import { useMaterialChangesFilters } from '@src-v2/containers/pages/commit-page';
import { toggleValues } from '@src-v2/utils/collection-utils';
import { dataAttr } from '@src-v2/utils/dom-utils';

export const FiltersContextProvider = ({
  context,
  activeFilters,
  updateFilters,
  filters,
  materialChangesCount,
  ...props
}) => (
  <context.Provider value={{ activeFilters, updateFilters }}>
    <MaterialChangesFilters filters={filters} materialChangesCount={materialChangesCount} />
    <MaterialChanges {...props} />
  </context.Provider>
);

export const MaterialChangesFilters = ({ filters, materialChangesCount }) => {
  const { activeFilters, updateFilters } = useMaterialChangesFilters();
  const defaultFilter = { name: 'All', count: materialChangesCount };

  const handleFilterClick = useCallback(
    filter => {
      if (filter === defaultFilter.name) {
        updateFilters([]);
        return;
      }
      updateFilters(toggleValues(activeFilters, filter));
    },
    [activeFilters]
  );

  const selectedFiltersNames = useMemo(
    () => (activeFilters.length ? activeFilters : ['All']),
    [activeFilters]
  );

  return (
    <FiltersContainer data-test-marker="mc-filter-container">
      {[defaultFilter, ...filters].map(({ icon, name, count }) => (
        <FilterButton
          key={name}
          data-selected={dataAttr(selectedFiltersNames?.includes(name))}
          onClick={() => handleFilterClick(name)}
          data-test-marker="mc-filter-button"
          data-test-id={`filter-${name}`}>
          {icon && <RiskIcon riskLevel={icon} />}
          {name} &middot;&nbsp; {count}
        </FilterButton>
      ))}
    </FiltersContainer>
  );
};

const FiltersContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: 3rem;
  margin: 3rem 0;
  gap: 2rem;
`;

const FilterButton = styled(InlineButton)`
  color: var(--default-text-color);
  display: flex;
  align-items: center;
  border: 1px solid var(--color-blue-gray-30);
  margin: 0 2rem 0 0;

  &[data-selected] {
    background-color: var(--color-blue-25);
    border: 0.25rem solid var(--color-blue-60);
    font-weight: 500;
  }

  &:hover {
    background-color: var(--color-blue-25);
  }

  ${BaseIcon} {
    margin: 0 2rem 1rem 0rem;
  }
`;
