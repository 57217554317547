import { isNil } from 'lodash';
import isEmpty from 'lodash/isEmpty';
import partition from 'lodash/partition';
import { useMemo } from 'react';
import styled from 'styled-components';
import { DoubleCollapsibleCard } from '@src-v2/components/cards';
import { SvgIcon, VendorIcon } from '@src-v2/components/icons';
import { InsightTag } from '@src-v2/components/tags';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink } from '@src-v2/components/typography';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import { pluralFormat } from '@src-v2/utils/number-utils';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { StyledRiskIcon } from '@src/blocks/RiskPosture/blocks/styles';
import Vulnerabilities from '@src/components/CodeAggregation/Dependency/Vulnerabilities';
import { StyledTableContent } from '@src/components/CodeAggregation/Insights/InsightStyles';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { ElementPane } from '@src/components/ElementPane';
import { FilterButtons } from '@src/components/FilterButtons';
import { Link } from '@src/components/Link';
import { VerticalStack } from '@src/components/VerticalStack';

const CVE_URL = 'https://cve.mitre.org/cgi-bin/cvename.cgi?name=';
const GHSA_URL = 'https://github.com/advisories/';
const CWE_URL = 'https://cwe.mitre.org/data/definitions/';

const PACKAGE_LABEL = { FIXABLE: 'Fixable', NOT_FIXABLE: 'Not Fixable' };

const VulnerabilitySummary = ({ summary, cveIdentifiers, cweIdentifiers }) => (
  <>
    <span>{summary}</span>
    {cveIdentifiers.map(cve => (
      <Link
        key={cve}
        url={cve.startsWith('CVE') ? `${CVE_URL}${cve}` : `${GHSA_URL}${cve}`}
        underline
        openInNewTab>
        {cve}
      </Link>
    ))}
    {cweIdentifiers.map(cwe => (
      <Link
        key={cwe}
        url={`${CWE_URL}${cwe.split('-').length > 1 && cwe.split('-')[1]}.html`}
        underline
        openInNewTab>
        {cwe}
      </Link>
    ))}
  </>
);

const SuggestedVersionVulnerabilitiesSummary = ({ suggestedVersionDependencyFindings }) => {
  return (
    <VulnerabilitiesSummaryPanel>
      {suggestedVersionDependencyFindings.map(finding => (
        <VulnerabilitiesSummaryRow key={finding.summary}>
          <StyledRiskIcon riskLevel={finding.severity} />
          <VulnerabilitySummary
            summary={finding.summary}
            cveIdentifiers={finding.cveIdentifiers}
            cweIdentifiers={finding.cweIdentifiers}
          />
        </VulnerabilitiesSummaryRow>
      ))}
    </VulnerabilitiesSummaryPanel>
  );
};

const VersionRow = ({ dependency }) => {
  const version = isEmpty(dependency.resolvedVersion)
    ? dependency.version
    : dependency.resolvedVersion;
  return (
    <CurrentVersionPanel>
      <SimplePackageDetail title="Version:" value={version} />
      {!isEmpty(dependency.resolvedVersion) && (
        <Tooltip content="Estimated version by Apiiro analysis">
          <InfoIcon name="Info" />
        </Tooltip>
      )}
    </CurrentVersionPanel>
  );
};

const CodeLink = ({ codeReference, repositoryForInventory }) => (
  <Tooltip content="View code">
    <StyledExternalLink href={generateCodeReferenceUrl(repositoryForInventory, codeReference)}>
      {codeReference.relativeFilePath}
      {Boolean(codeReference.lineNumber) && ` line: ${codeReference.lineNumber}`}
    </StyledExternalLink>
  </Tooltip>
);

const SubDependencyRow = ({ dependency, profile }) => {
  const repositoryForInventoryProperty = getRepositoryForInventoryPropertyFromProfileByKey(
    dependency.repositoryKeys[0],
    profile
  );

  if (dependency.isSubDependency) {
    return (
      <span>
        <PackageInfoTitle>Sub-dependency </PackageInfoTitle>
        {dependency.topLevelDependencyInfo?.length > 0 ? (
          <span>
            <PackageInfoTitle>
              introduced through the following top-level dependencies:{' '}
            </PackageInfoTitle>
            <PackageInfoValue>
              {dependency.topLevelDependencyInfo.map(topLevelDependency => (
                <p>
                  {topLevelDependency.name}{' '}
                  {topLevelDependency.codeReference && (
                    <>
                      <span>(Dependency declared in </span>
                      <CodeLink
                        codeReference={topLevelDependency.codeReference}
                        repositoryForInventory={repositoryForInventoryProperty}
                      />
                      <span>)</span>
                    </>
                  )}
                </p>
              ))}
            </PackageInfoValue>
          </span>
        ) : (
          ''
        )}
      </span>
    );
  }
  if (dependency.codeReference?.relativeFilePath) {
    return (
      <span>
        <PackageInfoTitle>Dependency declared in </PackageInfoTitle>{' '}
        <PackageInfoValue>
          <CodeLink
            codeReference={dependency.codeReference}
            repositoryForInventory={repositoryForInventoryProperty}
          />
        </PackageInfoValue>
      </span>
    );
  }
};

const PackageContent = ({ dependency, profile }) => {
  const [fixableVulnerabilities, notFixableVulnerabilities] = partition(
    dependency.dependencyFindings,
    dependencyFinding => dependencyFinding.isFixable
  );
  const getDependencyFindingsByFilterLabel = label =>
    label === PACKAGE_LABEL.FIXABLE ? fixableVulnerabilities : notFixableVulnerabilities;

  const repositoryForInventoryProperty = getRepositoryForInventoryPropertyFromProfileByKey(
    dependency.repositoryKeys[0],
    profile
  );

  const { suggestedVersion } = dependency;
  const dependencyFindingsLength = dependency.dependencyFindings?.length;

  const fixedVulnerabilitiesCount = useMemo(
    () =>
      dependencyFindingsLength -
      dependency.dependencyFindings
        ?.map(finding => finding.type)
        .filter(value =>
          dependency.suggestedVersionDependencyFindings
            ?.map(finding => finding.summary)
            .includes(value)
        ).length,
    [dependencyFindingsLength, dependency]
  );

  const suggestedVersionVulnerable = dependency.suggestedVersionDependencyFindings?.length > 0;

  return (
    <ContainerVerticalStack>
      <>
        <VersionRow dependency={dependency} />
        <SubDependencyRow dependency={dependency} profile={profile} />
      </>
      {suggestedVersion && (
        <BannerContainer>
          <BoldSpan>Recommended fix version: {suggestedVersion}</BoldSpan>
          <span>
            Upgrading will fix{' '}
            {fixedVulnerabilitiesCount === dependencyFindingsLength
              ? 'all'
              : `${fixedVulnerabilitiesCount} out of ${dependencyFindingsLength}`}{' '}
            current vulnerabilities.
          </span>
          {suggestedVersionVulnerable && (
            <VulnerabilitiesLinkRow>
              <StyledRiskIcon riskLevel={dependency.suggestedVersionSeverity} />
              <span>
                There{' '}
                {pluralFormat(dependency.suggestedVersionDependencyFindings.length, 'is', 'are')}{' '}
              </span>
              <Tooltip
                placement="bottom"
                interactive
                content={
                  <SuggestedVersionVulnerabilitiesSummary
                    suggestedVersionDependencyFindings={
                      dependency.suggestedVersionDependencyFindings
                    }
                  />
                }>
                <UnderlineSpan>
                  {dependency.suggestedVersionDependencyFindings.length} known{' '}
                  {pluralFormat(
                    dependency.suggestedVersionDependencyFindings.length,
                    'vulnerability',
                    'vulnerabilities'
                  )}
                </UnderlineSpan>
              </Tooltip>
              <span>for the recommended version.</span>
            </VulnerabilitiesLinkRow>
          )}
          {!suggestedVersionVulnerable && (
            <VulnerabilitiesLinkRow>
              <SvgIcon name="Accept" />
              No known vulnerabilities for the recommended version.
            </VulnerabilitiesLinkRow>
          )}
          {dependency.isSubDependency && (
            <>
              <BoldSpan>This is a sub-dependency.</BoldSpan>
              {!dependency.topLevelDependencyInfo?.length ? (
                <span>
                  In order to update its version, you may need to upgrade top-level dependencies.
                </span>
              ) : (
                <>
                  <span>
                    In order to update its version, you may need to upgrade the following top-level
                    top-level dependencies:
                  </span>
                  {dependency.topLevelDependencyInfo.map(topLevelDependency => (
                    <p>
                      {topLevelDependency.name}{' '}
                      {topLevelDependency.codeReference && (
                        <>
                          <span>(Dependency declared in </span>
                          <CodeLink
                            codeReference={topLevelDependency.codeReference}
                            repositoryForInventory={repositoryForInventoryProperty}
                          />
                          <span>)</span>
                        </>
                      )}
                    </p>
                  ))}
                </>
              )}
            </>
          )}
        </BannerContainer>
      )}
      <DoubleCollapsibleCard
        title={<PackageInfoHeadline>About this package</PackageInfoHeadline>}
        content={<PackageInformation dependency={dependency} />}
        nestedContent={
          !isNil(dependency.packageDigest) && <ExtendedPackageInformation dependency={dependency} />
        }
        defaultOpen
      />
      {dependency.dependencyFindings && !isEmpty(dependency.dependencyFindings) && (
        <DoubleCollapsibleCard
          showchevron
          title={
            <PackageInfoHeadline>
              Vulnerabilities ({dependency.dependencyFindings.length})
            </PackageInfoHeadline>
          }
          content={
            <VerticalStack>
              <PackageInfoTitle>
                {fixableVulnerabilities.length}/{dependency.dependencyFindings.length} introduced
                vulnerabilities are fixable
              </PackageInfoTitle>
              <StyledTableContent>
                <VulnerabilitiesFilterButtons
                  filterToCountMapping={[
                    { name: PACKAGE_LABEL.FIXABLE, count: fixableVulnerabilities.length },
                    { name: PACKAGE_LABEL.NOT_FIXABLE, count: notFixableVulnerabilities.length },
                  ]}>
                  {selectedGroup => (
                    <Vulnerabilities
                      dependencyFindings={getDependencyFindingsByFilterLabel(selectedGroup)}
                    />
                  )}
                </VulnerabilitiesFilterButtons>
              </StyledTableContent>
            </VerticalStack>
          }
          defaultOpen
        />
      )}
    </ContainerVerticalStack>
  );
};

const PackageInformation = ({ dependency }) => {
  const trimmedPackageDescription = useMemo(
    () => trimPackageDescription(dependency.packageDigest?.description),
    [dependency.packageDigest?.description]
  );

  return (
    <PackageDetailVerticalStack spacing="4rem" bottom="4rem">
      {dependency.isExternal && (
        <PackageDetailVerticalStack spacing="0rem">
          <PackageInformationHorizontalStack>
            <PackageInfoTitle>External dependency:</PackageInfoTitle>
            <LogoTag>
              <LogoIcon name={dependency.dependencyType} />
            </LogoTag>
            <Tooltip content="View package">
              <StyledExternalLink
                href={dependency.packageDigest?.homePage || dependency.homePage}
                disabled={!dependency.packageDigest?.homePage || dependency.homePage}>
                {dependency.name}
              </StyledExternalLink>
            </Tooltip>
          </PackageInformationHorizontalStack>
        </PackageDetailVerticalStack>
      )}
      {!isNil(dependency.packageDigest?.description) && (
        <SimplePackageDetail title="Package details:" value={trimmedPackageDescription} />
      )}
      {!isNil(dependency.packageDigest?.lastVersion) && (
        <SimplePackageDetail title="Latest version:" value={dependency.packageDigest.lastVersion} />
      )}
      {!isNil(dependency.insights) && <PackageInsights insights={dependency.insights} />}
    </PackageDetailVerticalStack>
  );
};

const ExtendedPackageInformation = ({ dependency }) => {
  const publicRegistry = !dependency.insights.some(
    insight => insight.packageInsight === 'MissingFromRegistry'
  );
  return (
    dependency.packageDigest && (
      <PackageDetailVerticalStack spacing="4rem" top="4rem">
        {publicRegistry && (
          <SimplePackageDetail title="Presence on public registry:" value="Present" />
        )}
        {Boolean(dependency.packageDigest?.licenses?.length) && (
          <SimplePackageDetail
            title="License"
            value={dependency.packageDigest.licenses.join(',')}
          />
        )}
      </PackageDetailVerticalStack>
    )
  );
};

const trimPackageDescription = description => {
  if (isNil(description)) {
    return null;
  }
  const sentences = description.split('. ', 4);
  const words = description.split(' ', 100);
  return words.length >
    sentences.reduce((sum_sentences, s) => sum_sentences + s.split(' ').length, 0)
    ? sentences.join('. ').concat('.')
    : words.join(' ');
};

function SimplePackageDetail({ title, value }) {
  return (
    <span>
      <PackageInfoTitle>{title}</PackageInfoTitle> <PackageInfoValue>{value}</PackageInfoValue>
    </span>
  );
}

function PackageInsights({ insights }) {
  const negativeInsights = insights.filter(insight => insight.sentiment === 'Negative');
  const positiveInsights = insights.filter(insight => insight.sentiment === 'Positive');
  const neutralInsights = insights.filter(insight => insight.sentiment === 'Neutral');
  return (
    <VerticalStack spacing="4rem">
      {negativeInsights.length > 0 && (
        <PackageInformationHorizontalStack>
          {negativeInsights.map(insight => (
            <InsightTag key={insight.badge} sentiment={insight.sentiment} insight={insight} />
          ))}
        </PackageInformationHorizontalStack>
      )}
      {positiveInsights.length > 0 && (
        <PackageInformationHorizontalStack>
          {positiveInsights.map(insight => (
            <InsightTag key={insight.badge} sentiment={insight.sentiment} insight={insight} />
          ))}
        </PackageInformationHorizontalStack>
      )}
      {neutralInsights.length > 0 && (
        <PackageInformationHorizontalStack>
          {neutralInsights.map(insight => (
            <InsightTag key={insight.badge} sentiment={insight.sentiment} insight={insight} />
          ))}
        </PackageInformationHorizontalStack>
      )}
    </VerticalStack>
  );
}

export const DependencyPane = ({
  trigger,
  profile,
  dependency,
  ruleTriggers,
  profileType,
  messageContent,
}) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    dependency.repositoryKeys[0],
    profile
  );
  return (
    <ElementPane
      element={dependency}
      repository={repository}
      title={
        <InsightsPaneTitle
          trigger={trigger}
          element={dependency}
          title={dependency.name}
          profile={profile}
          profileType={profileType}
          ruleTriggers={ruleTriggers}
          messageContent={messageContent}
          repository={repository}
          codeReference={dependency.codeReference}
        />
      }
      profileBody={<PackageContent dependency={dependency} profile={profile} />}
    />
  );
};

const StyledExternalLink = styled(ExternalLink)`
  width: fit-content;
  font-size: var(--font-size-s);
`;

const ContainerVerticalStack = styled(VerticalStack)`
  padding-bottom: 6rem;
  gap: 4rem;
`;

const BoldSpan = styled.span`
  color: var(--default-text-color);
  font-size: var(--font-size-m);
  line-height: 6rem;
  font-weight: 700;
`;

const PackageInfoHeadline = styled.span`
  display: flex;
  justify-content: space-between;
  gap: 4rem;
  font-size: var(--font-size-l);
`;

const PackageInfoTitle = styled.span`
  font-style: normal;
  font-weight: 300;
  font-size: var(--font-size-s);
  line-height: 6rem;
`;

const PackageInfoValue = styled.span`
  font-size: var(--font-size-s);
  line-height: 6rem;
`;

const UnderlineSpan = styled.span`
  text-decoration: underline;
`;

const BannerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 3rem;
  border-width: 0.25rem;
  padding: 4rem;
  font-size: var(--font-size-s);
  background-color: var(--color-blue-25);
  border-color: var(--color-blue-60);
  border-style: solid;
`;

const CurrentVersionPanel = styled.div`
  position: relative;
  display: flex;
  max-width: 100%;
  flex-direction: row;
  gap: 0;
`;

const VulnerabilitiesSummaryPanel = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  gap: 1rem;
`;

const VulnerabilitiesSummaryRow = styled.div`
  display: flex;
  text-align: left;
  align-items: center;
  gap: 3rem;
`;

const VulnerabilitiesLinkRow = styled(VulnerabilitiesSummaryRow)`
  gap: 1.5rem;
`;

const InfoIcon = styled(SvgIcon)`
  position: relative;
  bottom: 1rem;
  padding: 1rem 1.5rem 1rem 0.5rem;
`;

const LogoTag = styled.span`
  padding: 1rem 3rem;
  border: 0.25rem solid var(--color-blue-gray-30);
  border-radius: 100vmax;
`;

const LogoIcon = styled(VendorIcon)`
  height: unset;
  width: unset;
`;

const VulnerabilitiesFilterButtons = styled(FilterButtons)`
  margin-top: 3rem;
  padding: 0 0 6rem 0;
`;

const PackageDetailVerticalStack = styled.div`
  display: flex;
  max-width: 100%;
  flex-direction: column;
  font-size: var(--font-size-m);
  gap: ${props => props.spacing ?? '0rem'};
  margin-top: ${props => props.top ?? '0rem'};
  margin-bottom: ${props => props.bottom ?? '0rem'};
`;

const PackageInformationHorizontalStack = styled.div`
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  gap: 2.5rem;
`;
