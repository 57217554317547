import isNil from 'lodash/isNil';
import apiService from '@src/services/apiService';

const clearData = state => ({ ...state, disableRefreshOnPushNotification: true });

export default {
  state: clearData(),
  reducers: {
    setHasFetchedData: (state, hasFetchedData) => ({
      ...state,
      hasFetchedData,
    }),
    setData: (state, { commitMaterialChanges, hasFetchedData }) => ({
      ...state,
      commitMaterialChanges,
      hasFetchedData,
    }),
    clearData,
  },
  selectors: slice => ({
    hasFetchedData: () => slice(state => state.hasFetchedData),
    commitMaterialChanges: () => slice(state => state.commitMaterialChanges),
    commitSha: () => (rootState, props) => props.match.params.commitSha,
    repositoryKey: () => (rootState, props) =>
      slice(
        state =>
          props.match.params.repositoryKey ?? state.commitMaterialChanges?.commitData?.repositoryKey
      )(rootState),
    isDemoCommit: () => (rootState, props) => props.match.params.demo === 'demo',
    demoBlockIndex: () => (rootState, props) => props.match.params.demo_block_index,
    demoCommitIndex: () => (rootState, props) => props.match.params.demo_commit_index,
  }),
  effects: dispatch => ({
    async fetchData({ commitSha, repositoryKey, invalidateCache, moduleKey }) {
      this.setHasFetchedData(false);
      const url = repositoryKey
        ? `/api/materialChanges/commit/${repositoryKey}/${commitSha}`
        : `/api/materialChanges/commit/deprecated/${commitSha}`;
      const commitMaterialChanges = (
        await apiService.get(url, {
          clearCacheEntry: invalidateCache,
          params: !isNil(moduleKey) ? { moduleKey } : {},
        })
      ).data;

      repositoryKey ??= commitMaterialChanges?.commitData.repositoryKey;
      const { authorIdentityKey, committerIdentityKey } = commitMaterialChanges.commitData;
      const developerProfilesPromise =
        dispatch.developerProfiles.getDevelopersByIdentitiesKeysAsync({
          keys: [authorIdentityKey, committerIdentityKey],
          invalidateCache,
        });
      const repositoryProfilesPromise =
        dispatch.repositoryProfiles.getRepositoryProfilesByKeysAsync({
          keys: [repositoryKey],
          invalidateCache,
        });
      const governanceOptionsPromise = dispatch.governanceRules.fetchOptions({ invalidateCache });
      await Promise.all([
        developerProfilesPromise,
        repositoryProfilesPromise,
        governanceOptionsPromise,
      ]);

      this.setData({ commitMaterialChanges, hasFetchedData: true });
    },
  }),
};
