import assign from 'lodash/assign';
import filter from 'lodash/filter';
import keyBy from 'lodash/keyBy';
import map from 'lodash/map';
import reduce from 'lodash/reduce';
import some from 'lodash/some';
import { riskOrder } from '@src/services/riskService';

const demoCommitsBlocks = [
  {
    commits: [
      {
        governanceRules: [
          {
            rule: {
              key: 'demo-14',
              when: [{ type: 'Pii', value: 'Exposed' }],
              then: [{ type: 'Label', subType: 'Added', value: 'PII exposed in API' }],
            },
            materialChange: {
              fieldsCodeReference: {
                name: 'Account',
                relativeFilePath: 'src/main/java/com/demo/payments/models/Account.java',
                lineNumber: 17,
              },
              fieldNames: ['ssn'],
              codeReference: {
                className: 'AccountsController',
                relativeFilePath:
                  'src/main/java/com/demo/payments/controllers/AccountsController.java',
                methodName: 'getSSN',
              },
              keys: ['mc-4'],
              orderByValue: 'Account',
            },
          },
        ],
      },
    ],
  },
  {
    commits: [
      {
        governanceRules: [
          {
            rule: {
              key: 'demo-13',
              when: [{ type: 'ExposedToInternet', value: 'any' }],
              then: [{ type: 'Label', subType: 'Added', value: 'Service exposed to the internet' }],
            },
            materialChange: {
              serviceReference: {
                name: 'Identity Service',
              },
              keys: ['mc-3'],
              orderByValue: 'Foo',
            },
          },
        ],
      },
      {
        governanceRules: [
          {
            rule: {
              key: 'demo-14',
              when: [{ type: 'Api', value: 'Any' }],
              then: [
                {
                  type: 'Label',
                  subType: 'Vulnerable',
                  value: 'Internet-Facing API with SQL injection',
                },
                { type: 'Risk', subType: 'Present', value: 'High' },
              ],
            },
            materialChange: {
              codeReference: {
                className: 'GroupService',
                relativeFilePath: 'src/main/java/com/dchealth/service/rare/GroupService.java',
                apiName: 'getYunDeptUserList',
                name: 'GroupService',
              },
              descriptions: ['SQL injection', 'Internet-Facing API'],
              keys: ['mc-3-2'],
              orderByValue: 'Account',
            },
          },
        ],
      },
      {
        governanceRules: [
          {
            rule: {
              key: 'demo-12-1',
              when: [{ type: 'Pii', value: 'Exposed' }],
              then: [{ type: 'Label', subType: 'Added', value: 'PII exposed in API' }],
            },
            materialChange: {
              fieldsCodeReference: {
                name: 'Parent',
                relativeFilePath: 'src/main/java/com/acme/models/Parent.java',
                lineNumber: 17,
              },
              fieldNames: ['address'],
              codeReference: {
                className: 'UsersController',
                relativeFilePath: 'src/main/java/com/acme/UsersController.java',
                methodName: 'getParent',
              },
              keys: ['mc-2-1'],
              orderByValue: 'Parent',
            },
          },
          {
            rule: {
              key: 'demo-12-2',
              when: [{ type: 'Authentication', value: 'any' }],
              then: [{ type: 'Label', subType: 'Added', value: 'API missing authentication' }],
            },
            materialChange: {
              codeReference: {
                httpMethod: 'GET',
                url: 'users/:id/parent',
                name: 'UsersController',
                relativeFilePath: 'src/main/java/com/acme/UsersController.java',
                lineNumber: 15,
                apiName: 'getParent',
              },
              descriptions: ['authentication was added'],
              keys: ['mc-2-2'],
              orderByValue: 'Parent',
            },
          },
        ],
      },
      {
        governanceRules: [],
      },
      {
        governanceRules: [
          {
            rule: {
              key: 'demo-11',
              when: [{ type: 'Pii', value: 'any' }],
              then: [{ type: 'Label', subType: 'Added', value: 'PII added' }],
            },
            materialChange: {
              codeReference: {
                name: 'Parent',
                relativeFilePath: 'src/main/java/com/acme/Parent.java',
                lineNumber: 17,
                fieldNames: ['name', 'address'],
              },
              keys: ['mc-1'],
              orderByValue: 'Parent',
            },
          },
        ],
      },
    ],
  },
];

const demoApplications = [
  {
    name: 'Lim',
    services: [
      {
        name: 'Lim.API',
        type: 'Ingress',
        version: '1.2.2',
        build: {
          icons: ['Docker', 'Jenkins'],
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml',
          steps: [
            {
              type: 'Code',
              description: 'Code built from repository module',
              items: [
                {
                  repository: {
                    key: 'https___github_com_apiiro__lim__lim',
                    type: 'Repository',
                    name: 'lim',
                    url: 'https://github.com/apiiro/lim',
                    server: { provider: 'Github' },
                    isMonitored: true,
                  },
                  value: '> src/Lim.API/Lim.API.csproj',
                  url: 'https://github.com/apiiro/lim/tree/main/src/Lim.API',
                },
              ],
            },
            {
              type: 'Build',
              description: 'Built using Docker and deployed using Jenkins',
              items: [
                {
                  value: 'Lim.API.Dockerfile',
                  url: 'https://github.com/apiiro/lim/blob/dev/docker/Lim.API.Dockerfile',
                },
                {
                  value: 'cloudbuild.yaml',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L31',
                },
              ],
            },
            {
              type: 'Image',
              description: 'Container image uploaded to Google Container Registry',
              items: [
                {
                  value: 'gcr.io/$PROJECT_ID/lim/api',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L31',
                },
              ],
            },
          ],
        },
        runtime: {
          language: 'CSharp',
          description: 'Running a C# process',
          execution: 'ENTRYPOINT ["dotnet", "./Lim.API.dll"]',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/docker/Lim.API.Dockerfile',
        },
        deployment: {
          technology: 'Kubernetes',
          technologyDescription: 'Deployed using Kubernetes',
          icon: 'GoogleKubernetesEngine',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-api.yaml',
          environment: [
            {
              type: 'Exposed Ports',
              description: 'Ports opened through Ingress',
              items: [
                {
                  value: 'Ingress port 80 > Container port 80',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-api.yaml#L42',
                },
              ],
            },
            {
              type: 'Ports',
              description: 'Ports open to cluster internal network',
              items: [
                {
                  value: 'http 80',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-api.yaml#L206',
                },
                {
                  value: 'https 443',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-api.yaml#L208',
                },
              ],
            },
            {
              type: 'Secrets',
              description: 'Secrets loaded to environment',
              items: [
                {
                  value: 'MONGO_PASSWORD',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-api.yaml#L159',
                },
                {
                  value: 'ADMIN_PASSWORD',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-api.yaml#L164',
                },
                {
                  value: 'OKTA_CLIENT_SECRET',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-api.yaml#L179',
                },
              ],
            },
          ],
        },
      },
      {
        name: 'Lim.GitListener',
        type: 'Worker',
        version: '1.2.1',
        build: {
          icons: ['Docker', 'Jenkins'],
          technologyDescription: 'Built using Docker and deployed using Jenkins',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml',
          steps: [
            {
              type: 'Code',
              description: 'Code built from repository module',
              items: [
                {
                  repository: {
                    key: 'https___github_com_apiiro__lim__lim',
                    type: 'Repository',
                    name: 'lim',
                    url: 'https://github.com/apiiro/lim',
                    server: { provider: 'Github' },
                    isMonitored: true,
                  },
                  value: '> src/Lim.GitListener/Lim.GitListener.csproj',
                  url: 'https://github.com/apiiro/lim/tree/dev/src/Lim.GitListener',
                },
              ],
            },
            {
              type: 'Build',
              description: 'Built using Docker and deployed using Jenkins',
              items: [
                {
                  value: 'Lim.GitListener.Dockerfile',
                  url: 'https://github.com/apiiro/lim/blob/dev/docker/Lim.GitListener.Dockerfile',
                },
                {
                  value: 'cloudbuild.yaml',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L43',
                },
              ],
            },
            {
              type: 'Image',
              description: 'Container image uploaded to Google Container Registry',
              items: [
                {
                  value: 'gcr.io/$PROJECT_ID/lim/git-listener',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L43',
                },
              ],
            },
          ],
        },
        runtime: {
          language: 'CSharp',
          description: 'Running a C# process',
          execution: 'ENTRYPOINT ["dotnet", "./Lim.GitListener.dll"]',
          configurationUrl:
            'https://github.com/apiiro/lim/blob/dev/docker/Lim.GitListener.Dockerfile',
        },
        deployment: {
          technology: 'Kubernetes',
          technologyDescription: 'Deployed using Kubernetes',
          icon: 'GoogleKubernetesEngine',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-git-listener.yaml',
          environment: [
            {
              type: 'Secrets',
              description: 'Secrets loaded to environment',
              items: [
                {
                  value: 'MONGO_PASSWORD',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-git-listener.yaml#L51',
                },
              ],
            },
          ],
        },
      },
      {
        name: 'Lim.FeaturesExtractor',
        type: 'Worker',
        version: '1.2.1',
        build: {
          icons: ['Docker', 'Jenkins'],
          technologyDescription: 'Built using Docker and deployed using Jenkins',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml',
          steps: [
            {
              type: 'Code',
              description: 'Code built from repository module',
              items: [
                {
                  repository: {
                    key: 'https___github_com_apiiro__lim__lim',
                    type: 'Repository',
                    name: 'lim',
                    url: 'https://github.com/apiiro/lim',
                    server: { provider: 'Github' },
                    isMonitored: true,
                  },
                  value: '> src/Lim.FeaturesExtractor/Lim.FeaturesExtractor.csproj',
                  url: 'https://github.com/apiiro/lim/tree/dev/src/Lim.FeaturesExtractor',
                },
              ],
            },
            {
              type: 'Build',
              description: 'Built using Docker and deployed using Jenkins',
              items: [
                {
                  value: 'Lim.FeaturesExtractor.Dockerfile',
                  url: 'https://github.com/apiiro/lim/blob/dev/docker/Lim.FeaturesExtractor.Dockerfile',
                },
                {
                  value: 'cloudbuild.yaml',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L55',
                },
              ],
            },
            {
              type: 'Image',
              description: 'Container image uploaded to Google Container Registry',
              items: [
                {
                  value: 'gcr.io/$PROJECT_ID/lim/features-extractor',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L55',
                },
              ],
            },
          ],
        },
        runtime: {
          language: 'CSharp',
          description: 'Running a C# process',
          execution: 'ENTRYPOINT ["dotnet", "./Lim.FeaturesExtractor.dll"]',
          configurationUrl:
            'https://github.com/apiiro/lim/blob/dev/docker/Lim.FeaturesExtractor.Dockerfile',
        },
        deployment: {
          technology: 'Kubernetes',
          technologyDescription: 'Deployed using Kubernetes',
          icon: 'GoogleKubernetesEngine',
          configurationUrl:
            'https://github.com/apiiro/lim/blob/dev/k8s/lim-features-extractor.yaml',
          environment: [
            {
              type: 'Secrets',
              description: 'Secrets loaded to environment',
              items: [
                {
                  value: 'MONGO_PASSWORD',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-features-extractor.yaml#L39',
                },
              ],
            },
          ],
        },
      },
      {
        name: 'Lim.FeaturesExtractor.Java/Parser',
        type: 'Worker',
        version: '1.2.1',
        build: {
          icons: ['Docker', 'Jenkins'],
          technologyDescription: 'Built using Docker and deployed using Jenkins',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml',
          steps: [
            {
              type: 'Code',
              description: 'Code built from repository module',
              items: [
                {
                  repository: {
                    key: 'https___github_com_apiiro__lim__lim',
                    type: 'Repository',
                    name: 'lim',
                    url: 'https://github.com/apiiro/lim',
                    server: { provider: 'Github' },
                    isMonitored: true,
                  },
                  value: '> src/Lim.FeaturesExtractor.Java/pom.xml',
                  url: 'https://github.com/apiiro/lim/tree/dev/src/Lim.FeaturesExtractor.Java',
                },
              ],
            },
            {
              type: 'Build',
              description: 'Built using Docker and deployed using Jenkins',
              items: [
                {
                  value: 'Lim.FeaturesExtractor.Java.Dockerfile',
                  url: 'https://github.com/apiiro/lim/blob/dev/docker/Lim.FeaturesExtractor.Java.Dockerfile',
                },
                {
                  value: 'cloudbuild.yaml',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L136',
                },
              ],
            },
            {
              type: 'Image',
              description: 'Container image uploaded to Google Container Registry',
              items: [
                {
                  value: 'gcr.io/$PROJECT_ID/lim/features-extractor-java',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L136',
                },
              ],
            },
          ],
        },
        runtime: {
          language: 'Java',
          description: 'Running a Java process',
          execution:
            'java -Djdk.attach.allowAttachSelf -Djol.tryWithSudo=true -Djava.security.egd=file:/dev/./urandom -jar /FeaturesExtractorService.jar',
          configurationUrl:
            'https://github.com/apiiro/lim/blob/dev/docker/Lim.FeaturesExtractor.Java.Dockerfile',
        },
        deployment: {
          technology: 'Kubernetes',
          technologyDescription: 'Deployed using Kubernetes',
          icon: 'GoogleKubernetesEngine',
          configurationUrl:
            'https://github.com/apiiro/lim/blob/dev/k8s/lim-features-extractor-java.yaml',
          environment: [
            {
              type: 'Ports',
              description: 'Ports open to cluster internal network',
              items: [
                {
                  value: 'http 8090',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-features-extractor-java.yaml#L10',
                },
              ],
            },
          ],
        },
      },
      {
        name: 'Lim.FeaturesExtractor.Node/Parser',
        type: 'Worker',
        version: '1.2.1',
        build: {
          icons: ['Docker', 'Jenkins'],
          technologyDescription: 'Built using Docker and deployed using Jenkins',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml',
          steps: [
            {
              type: 'Code',
              description: 'Code built from repository module',
              items: [
                {
                  repository: {
                    key: 'https___github_com_apiiro__lim__lim',
                    type: 'Repository',
                    name: 'lim',
                    url: 'https://github.com/apiiro/lim',
                    server: { provider: 'Github' },
                    isMonitored: true,
                  },
                  value: '> src/Lim.FeaturesExtractor.Node/package.json',
                  url: 'https://github.com/apiiro/lim/tree/dev/src/Lim.FeaturesExtractor.Node',
                },
              ],
            },
            {
              type: 'Build',
              description: 'Built using Docker and deployed using Jenkins',
              items: [
                {
                  value: 'Lim.FeaturesExtractor.Node.Dockerfile',
                  url: 'https://github.com/apiiro/lim/blob/dev/docker/Lim.FeaturesExtractor.Node.Dockerfile',
                },
                {
                  value: 'cloudbuild.yaml',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L148',
                },
              ],
            },
            {
              type: 'Image',
              description: 'Container image uploaded to Google Container Registry',
              items: [
                {
                  value: 'gcr.io/$PROJECT_ID/lim/features-extractor-node',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L148',
                },
              ],
            },
          ],
        },
        runtime: {
          language: 'TypeScript',
          description: 'Running a TypeScript process',
          execution: 'ENTRYPOINT ["npm", "run", "start"]',
          configurationUrl:
            'https://github.com/apiiro/lim/blob/dev/docker/Lim.FeaturesExtractor.Node.Dockerfile',
        },
        deployment: {
          technology: 'Kubernetes',
          technologyDescription: 'Deployed using Kubernetes',
          icon: 'GoogleKubernetesEngine',
          configurationUrl:
            'https://github.com/apiiro/lim/blob/dev/k8s/lim-features-extractor-node.yaml',
          environment: [
            {
              type: 'Ports',
              description: 'Ports open to cluster internal network',
              items: [
                {
                  value: 'http 8093',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-features-extractor-node.yaml#L10',
                },
              ],
            },
          ],
        },
      },
      {
        name: 'Lim.Predictor',
        type: 'Worker',
        version: '1.2.1',
        build: {
          icons: ['Docker', 'Jenkins'],
          technologyDescription: 'Built using Docker and deployed using Jenkins',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml',
          steps: [
            {
              type: 'Code',
              description: 'Code built from repository module',
              items: [
                {
                  repository: {
                    key: 'https___github_com_apiiro__lim__lim',
                    type: 'Repository',
                    name: 'lim',
                    url: 'https://github.com/apiiro/lim',
                    server: { provider: 'Github' },
                    isMonitored: true,
                  },
                  value: '> src/Lim.Predictor',
                  url: 'https://github.com/apiiro/lim/tree/dev/src/Lim.Predictor',
                },
              ],
            },
            {
              type: 'Build',
              description: 'Built using Docker and deployed using Jenkins',
              items: [
                {
                  value: 'Lim.Predictor.Dockerfile',
                  url: 'https://github.com/apiiro/lim/blob/dev/docker/Lim.Predictor.Dockerfile',
                },
                {
                  value: 'cloudbuild.yaml',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L160',
                },
              ],
            },
            {
              type: 'Image',
              description: 'Container image uploaded to Google Container Registry',
              items: [
                {
                  value: 'gcr.io/$PROJECT_ID/lim/predictor',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L160',
                },
              ],
            },
          ],
        },
        runtime: {
          language: 'Python',
          description: 'Running a Python process',
          configurationUrl:
            'https://github.com/apiiro/lim/blob/dev/docker/Lim.Predictor.Dockerfile',
        },
        deployment: {
          technology: 'Kubernetes',
          technologyDescription: 'Deployed using Kubernetes',
          icon: 'GoogleKubernetesEngine',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-predictor.yaml',
          environment: [
            {
              type: 'Ports',
              description: 'Ports open to cluster internal network',
              items: [
                {
                  value: 'http 80',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-predictor.yaml#L10',
                },
              ],
            },
          ],
        },
      },
      {
        name: 'Lim.FeaturesDiffer',
        type: 'Worker',
        version: '1.2.1',
        build: {
          icons: ['Docker', 'Jenkins'],
          technologyDescription: 'Built using Docker and deployed using Jenkins',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml',
          steps: [
            {
              type: 'Code',
              description: 'Code built from repository module',
              items: [
                {
                  repository: {
                    key: 'https___github_com_apiiro__lim__lim',
                    type: 'Repository',
                    name: 'lim',
                    url: 'https://github.com/apiiro/lim',
                    server: { provider: 'Github' },
                    isMonitored: true,
                  },
                  value: '> src/Lim.FeaturesDiffer/Lim.FeaturesDiffer.csproj',
                  url: 'https://github.com/apiiro/lim/tree/dev/src/Lim.FeaturesDiffer',
                },
              ],
            },
            {
              type: 'Build',
              description: 'Built using Docker and deployed using Jenkins',
              items: [
                {
                  value: 'Lim.FeaturesDiffer.Dockerfile',
                  url: 'https://github.com/apiiro/lim/blob/dev/docker/Lim.FeaturesDiffer.Dockerfile',
                },
                {
                  value: 'cloudbuild.yaml',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L67',
                },
              ],
            },
            {
              type: 'Image',
              description: 'Container image uploaded to Google Container Registry',
              items: [
                {
                  value: 'gcr.io/$PROJECT_ID/lim/features-differ',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L67',
                },
              ],
            },
          ],
        },
        runtime: {
          language: 'CSharp',
          description: 'Running a C# process',
          execution: 'ENTRYPOINT ["dotnet", "./Lim.FeaturesDiffer.dll"]',
          configurationUrl:
            'https://github.com/apiiro/lim/blob/dev/docker/Lim.FeaturesDiffer.Dockerfile',
        },
        deployment: {
          technology: 'Kubernetes',
          technologyDescription: 'Deployed using Kubernetes',
          icon: 'GoogleKubernetesEngine',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-features-differ.yaml',
          environment: [
            {
              type: 'Secrets',
              description: 'Secrets loaded to environment',
              items: [
                {
                  value: 'MONGO_PASSWORD',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-features-differ.yaml#L38',
                },
              ],
            },
          ],
        },
      },
      {
        name: 'Lim.LicenseParser',
        type: 'Worker',
        version: '1.2.1',
        build: {
          icons: ['Docker', 'Jenkins'],
          technologyDescription: 'Built using Docker and deployed using Jenkins',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml',
          steps: [
            {
              type: 'Code',
              description: 'Code built from repository module',
              items: [
                {
                  repository: {
                    key: 'https___github_com_apiiro__lim__lim',
                    type: 'Repository',
                    name: 'lim',
                    url: 'https://github.com/apiiro/lim',
                    server: { provider: 'Github' },
                    isMonitored: true,
                  },
                  value: '> src/Lim.LicenseParser/Cargo.toml',
                  url: 'https://github.com/apiiro/lim/tree/dev/src/Lim.LicenseParser',
                },
              ],
            },
            {
              type: 'Build',
              description: 'Built using Docker and deployed using Jenkins',
              items: [
                {
                  value: 'Lim.LicenseParser.Dockerfile',
                  url: 'https://github.com/apiiro/lim/blob/dev/docker/Lim.LicenseParser.Dockerfile',
                },
                {
                  value: 'cloudbuild.yaml',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L79',
                },
              ],
            },
            {
              type: 'Image',
              description: 'Container image uploaded to Google Container Registry',
              items: [
                {
                  value: 'gcr.io/$PROJECT_ID/lim/license-parser',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L79',
                },
              ],
            },
          ],
        },
        runtime: {
          language: 'Rust',
          description: 'Running a Rust process',
          execution: 'ENTRYPOINT ["./lim-license-parser"]',
          configurationUrl:
            'https://github.com/apiiro/lim/blob/dev/docker/Lim.LicenseParser.Dockerfile',
        },
        deployment: {
          technology: 'Kubernetes',
          technologyDescription: 'Deployed using Kubernetes',
          icon: 'GoogleKubernetesEngine',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-license-parser.yaml',
          environment: [
            {
              type: 'Ports',
              description: 'Ports open to cluster internal network',
              items: [
                {
                  value: 'http 8092',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-license-parser.yaml#L10',
                },
              ],
            },
          ],
        },
      },
      {
        name: 'Lim.Mongo',
        type: 'Database',
        version: '1.2.1',
        build: {
          icons: ['Docker', 'Jenkins'],
          technology: 'Docker',
          technologyDescription: 'Built using Docker',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml',
          steps: [
            {
              type: 'Code',
              description: 'Code built from repository module',
              items: [
                {
                  repository: {
                    key: 'https___github_com_apiiro__lim__lim',
                    type: 'Repository',
                    name: 'lim',
                    url: 'https://github.com/apiiro/lim',
                    server: { provider: 'Github' },
                    isMonitored: true,
                  },
                  value: '> src/Lim.Mongo',
                  url: 'https://github.com/apiiro/lim/tree/dev/src/Lim.Mongo',
                },
              ],
            },
            {
              type: 'Build',
              description: 'Image built using Docker',
              items: [
                {
                  value: 'Lim.Mongo.Dockerfile',
                  url: 'https://github.com/apiiro/lim/blob/dev/docker/Lim.Mongo.Dockerfile',
                },
              ],
            },
          ],
        },
        runtime: {
          technology: 'MongoDB',
          description: 'Running MongoDB instance',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/docker/Lim.Mongo.Dockerfile',
        },
        deployment: {
          technology: 'Kubernetes',
          technologyDescription: 'Deployed using Kubernetes',
          icon: 'GoogleKubernetesEngine',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-mongo.yaml',
          environment: [
            {
              type: 'Ports',
              description: 'Ports open to cluster internal network',
              items: [
                {
                  value: '27017',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/lim-mongo.yaml#L10',
                },
              ],
            },
          ],
        },
      },
      {
        name: 'Billing Service',
        type: 'Ingress',
        version: '1.2.2',
        build: {
          icons: ['Docker', 'Jenkins'],
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml',
          steps: [
            {
              type: 'Code',
              description: 'Code built from repository module',
              items: [
                {
                  repository: {
                    key: 'https___github_com_apiiro__lim__lim',
                    type: 'Repository',
                    name: 'lim',
                    url: 'https://github.com/apiiro/lim',
                    server: { provider: 'Github' },
                    isMonitored: true,
                  },
                  value: '> src/Billing/Billing.csproj',
                  url: 'https://github.com/apiiro/lim/tree/main/src/Billing',
                },
              ],
            },
            {
              type: 'Build',
              description: 'Built using Docker and deployed using Jenkins',
              items: [
                {
                  value: 'Billing.Dockerfile',
                  url: 'https://github.com/apiiro/lim/blob/dev/docker/Billing.Dockerfile',
                },
                {
                  value: 'cloudbuild.yaml',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L31',
                },
              ],
            },
            {
              type: 'Image',
              description: 'Container image uploaded to Google Container Registry',
              items: [
                {
                  value: 'gcr.io/$PROJECT_ID/lim/billing',
                  url: 'https://github.com/apiiro/lim/blob/dev/cloudbuild.dev.yaml#L31',
                },
              ],
            },
          ],
        },
        runtime: {
          language: 'CSharp',
          description: 'Running a C# process',
          execution: 'ENTRYPOINT ["dotnet", "./Billing.dll"]',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/docker/Billing.Dockerfile',
        },
        deployment: {
          technology: 'Kubernetes',
          technologyDescription: 'Deployed using Kubernetes',
          icon: 'GoogleKubernetesEngine',
          configurationUrl: 'https://github.com/apiiro/lim/blob/dev/k8s/billing.yaml',
          environment: [
            {
              type: 'Exposed Ports',
              description: 'Ports opened through Ingress',
              items: [
                {
                  value: 'Ingress port 80 > Container port 80',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/billing.yaml#L42',
                },
              ],
            },
            {
              type: 'Ports',
              description: 'Ports open to cluster internal network',
              items: [
                {
                  value: 'http 80',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/billing.yaml#L206',
                },
                {
                  value: 'https 443',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/billing.yaml#L208',
                },
              ],
            },
            {
              type: 'Secrets',
              description: 'Secrets loaded to environment',
              items: [
                {
                  value: 'MONGO_PASSWORD',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/billing.yaml#L159',
                },
                {
                  value: 'ADMIN_PASSWORD',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/billing.yaml#L164',
                },
                {
                  value: 'OKTA_CLIENT_SECRET',
                  url: 'https://github.com/apiiro/lim/blob/dev/k8s/billing.yaml#L179',
                },
              ],
            },
          ],
        },
      },
    ],
  },
];

const demoComponents = [
  {
    name: 'Docker',
    type: 'Infrastructure',
    version: '19.03.11',
  },
  {
    name: 'HashiCorp Terraform',
    type: 'IaC',
    version: '0.12.28',
  },
  {
    name: 'HashiCorp Vault',
    type: 'Secrets Management',
    version: '1.4.3',
  },
  {
    name: 'mongodb',
    type: 'DB',
    version: '4.2.2',
  },
  {
    name: 'Logstash',
    type: 'Log',
    version: '7.6',
  },
  {
    name: 'react',
    type: 'UI',
    version: '16.12.0',
  },
  {
    name: 'Apache Tomcat',
    type: 'Web Server',
    version: '9.0.30',
  },
  {
    name: 'Spring',
    type: 'APIs',
    version: '5.2.1',
  },
];

const demoControls = [
  {
    name: 'Spring Security',
    type: 'Authentication, Authorization, Input Validation',
    version: '5.2.1',
  },
  {
    name: 'Oval',
    type: 'Input Validation',
    version: '2.x',
  },
  {
    name: 'Transmit Security',
    type: 'Authentication',
    version: '3.5',
  },
];

const demoProductionAdditionalRiskTriggers = [
  {
    riskLevel: 'High',
    count: 1,
    demoElementType: 'build',
    productLifecyclePhase: 'Development',
    triggers: [{ sentMessages: [] }],
    ruleKey: 'demo-rule-build',
    rule: {
      ordinalId: 999,
      risk: 'High',
      name: 'Suspicious Code Changes During Build',
      whenTypes: ['Build'],
      when: [],
      processTags: [
        {
          key: '11111',
          name: 'Security Code Review',
        },
      ],
    },
  },
  {
    riskLevel: 'High',
    count: 1,
    demoElementType: 'Finding',
    triggers: [{}, {}, {}, {}, {}, {}, {}],
    demoTriggersSelector: triggers => triggers.slice(0, 7),
    ruleKey: 'api-finding',
    rule: {
      ordinalId: 998,
      risk: 'High',
      name: 'OWASP TOP 10 Finding',
      whenTypes: [
        'LegacySastFindings',
        'CheckmarxVulnerability',
        'SnykVulnerability',
        'SemgrepVulnerability',
      ],
      when: [],
      processTags: [
        {
          key: '11111',
          name: 'Security Code Review',
        },
      ],
    },
  },
  {
    riskLevel: 'High',
    count: 1,
    demoElementType: 'Api',
    triggers: [
      {
        exposedPiis: [],
        involvedPiis: [],
        exposedPayments: [],
        involvedPayments: [],
        hasAuthorizationViolation: false,
        accessesDatabase: true,
        apiGatewayReference: null,
        relatedCodeFindingsByProvider: {
          Apiiro: [
            {
              url: null,
              relatedApis: ['GET|/users/search|service.UsersService.getUsersList(String)'],
              relatedApiMethodIds: [],
              filePath: 'UsersService.cs',
              lineNumber: 17,
              endLineNumber: null,
              confidence: null,
              exploitability: null,
              codeReference: {
                relativeFilePath: 'UsersService.cs',
                lineNumber: 0,
                lastLineInFile: 0,
              },
              entityId: 'Code__RepoCloneUrl__Apiiro__Sast__0',
              entityType: 'Finding',
              repositoryCloneUrl: null,
              repositoryKey: null,
              scanProjectName: 'Identity service',
              reportUrl: '/repositories/7/scans/17',
              commitSha: null,
              branchName: null,
              scanType: 'Sast',
              time: '2022-01-03T22:00:00Z',
              provider: 'Apiiro',
              reportTags: {},
              id: '0',
              FirstOccurenceTimeParsed: '2022-01-01T02:20:00+02:20',
              type: 'SQL Injection',
              cwe: null,
              severity: null,
              standards: [],
              description: null,
              tags: {},
              repositoryKeys: [],
              attackSurfaceCount: 1,
              FirstOccurenceTime: '2022-01-01T00:00:00',
            },
          ],
        },
      },
    ],
    demoTriggersSelector: triggers => triggers.slice(0, 3),
    ruleKey: 'demo-sql-injection-in-db',
    rule: {
      ordinalId: 1001,
      risk: 'Critical',
      name: 'SQL Injection in API accessing a database contains PII',
      whenTypes: ['ApiiroSCAVulnerability'],
      when: [],
      processTags: [
        {
          key: '11111',
          name: 'Security Code Review',
        },
      ],
    },
  },
  {
    riskLevel: 'Critical',
    count: 1,
    demoElementType: 'Api',
    triggers: [
      {
        hasAuthorizationViolation: false,
        exposedPiis: [],
        involvedPiis: [],
        exposedPayments: [],
        involvedPayments: [],
        relatedCodeFindingsByProvider: {},
        writesToStorageBucket: [
          {
            framework: 'AWS',
            externalMethodCall: '???.client.copyObject',
            reference: {
              relativeFilePath: 'src/service/StorageService.java',
              lineNumber: 142,
              lastLineInFile: 142,
            },
            type: 'ApiWritesToStorageBucket',
            distance: 0,
            neighborMethodFullName: 'service.StorageService.storeUserDetails(String)',
            neighborClassFullName: 'service.TemplateService',
          },
        ],
        writesPiiToStorageBucket: true,
        storageBucketUnencrypted: true,
        apiGatewayReference: {
          displayName: 'Payment Service Gateway',
          serviceEndpointUrls: [],
          apiSecurityControls: ['Throttling'],
          operations: [
            {
              id: null,
              name: null,
              method: null,
              urls: [],
              policiesXml: null,
              apiSecurityControls: ['Throttling'],
              addedPrefix: '/api',
              removePrefixParts: 0,
              endpointUrls: [],
              urlRewrites: [],
              possibleRoutes: [],
            },
          ],
          providerName: 'Azure API Management',
          provider: 'AzureCloud',
          gatewayServiceName: 'Payment Service Gateway',
          gatewayUrl: 'https://api.azureapimanagement.com',
          exposingRoute: null,
          routeExposureTime: null,
        },
      },
    ],
    demoTriggersSelector: triggers => triggers.slice(0, 2),
    ruleKey: 'demo-gateway-missing-encryption',
    rule: {
      ordinalId: 1002,
      risk: 'Critical',
      name: 'API behind API gateway writes PII to a S3 bucket that is missing encryption',
      whenTypes: ['BehindApiGateway', 'ExitPoint', 'AccessingStorageBucket'],
      when: [],
      processTags: [
        {
          key: '11111',
          name: 'Security Code Review',
        },
      ],
    },
  },
  {
    riskLevel: 'Critical',
    count: 1,
    demoElementType: 'Api',
    triggers: [
      {
        apiClassification: 'internetfacing',
        exposedPiis: [],
        involvedPiis: [],
        exposedPayments: ['com.payment.CreditCard'],
        involvedPayments: [],
        relatedCodeFindingsByProvider: {},
        apiGatewayReference: {
          displayName: 'Payment Service Gateway',
          serviceEndpointUrls: [],
          apiSecurityControls: ['Throttling'],
          operations: [
            {
              id: null,
              name: null,
              method: null,
              urls: [],
              policiesXml: null,
              apiSecurityControls: ['Throttling'],
              addedPrefix: '/api',
              removePrefixParts: 0,
              endpointUrls: [],
              urlRewrites: [],
              possibleRoutes: [],
            },
          ],
          providerName: 'Azure API Management',
          provider: 'AzureCloud',
          gatewayServiceName: 'Payment Service Gateway',
          gatewayUrl: 'https://api.azureapimanagement.com',
          exposingRoute: null,
          routeExposureTime: null,
        },
      },
    ],
    demoTriggersSelector: triggers => triggers.slice(0, 1),
    ruleKey: 'demo-internet-payment',
    rule: {
      ordinalId: 1003,
      risk: 'Critical',
      name: 'Internet-facing API exposes payment data missing authorization on API Gateway',
      whenTypes: ['Api', 'PaymentsData', 'BehindApiGateway'],
      when: [],
      processTags: [
        {
          key: '11111',
          name: 'Security Code Review',
        },
      ],
    },
  },
  {
    riskLevel: 'Critical',
    count: 1,
    demoElementType: 'Dependency',
    triggers: [
      {
        name: 'Billing service : log4j-api',
        usedByInternetFacingService: true,
        vulnerabilityReferences: [
          {
            vulnerabilityProvider: 'ApiiroSca',
            vulnerability: {
              vulnerabilityId: 'CVE-2021-44228',
              isFixable: true,
              exploitMaturity: null,
              description: 'Log4J2 Vulnerability',
              cvssScore: 10,
              cveIdentifiers: ['CVE-2021-44228'],
              cweIdentifiers: [],
              isIgnored: false,
              isPatched: false,
              nearestFixedVersion: '2.15.0',
              urlsToProjectsMap: {},
              isSubDependencyOnlyByRepositoryKey: {},
              correlationId: null,
              type: 'CodeInjection',
              title: 'CVE-2021-44228',
              displayName: 'CVE-2021-44228',
              relatedRepositoryKeys: [],
              url: 'https://nvd.nist.gov/vuln/detail/CVE-2021-44228',
              language: 'Java',
              severity: 'Critical',
              packageName: 'log4j:log4j',
              packageVersions: ['<2.14.1'],
            },
            displayName: 'CVE-2021-44228',
          },
        ],
      },
    ],
    demoTriggersSelector: triggers => [
      triggers.find(trigger => trigger.elementKey.includes('log4j-core')) ?? triggers[0],
    ],
    ruleKey: 'demo-billing-service-dependency',
    rule: {
      ordinalId: 1004,
      risk: 'Critical',
      name: 'Internet exposed billing service uses a dependency with vulnerabilities',
      whenTypes: ['ApiiroSCAVulnerability'],
      when: [],
      processTags: [
        {
          key: '11111',
          name: 'Security Code Review',
        },
      ],
    },
  },
];

const demoDevelopmentRiskTriggers = [
  {
    riskLevel: 'High',
    count: 3,
    pullRequest: '9080',
    rule: {
      ordinalId: 1,
      name: 'Internet-Facing APIs exposing PII missing authorization',
      when: [
        {
          type: 'Apis',
        },
      ],
      processTags: [
        {
          key: '1',
          name: 'Security Code Review',
        },
      ],
    },
  },
  {
    riskLevel: 'High',
    count: 1,
    pullRequest: '8980',
    rule: {
      ordinalId: 2,
      name: 'Package with license GPL 3.0 added',
      when: [
        {
          type: 'Apis',
        },
      ],
      processTags: [
        {
          key: '1',
          name: 'Compliance Review',
        },
      ],
    },
  },
  {
    riskLevel: 'High',
    count: 2,
    pullRequest: '9060',
    rule: {
      ordinalId: 3,
      name: 'Checkmarx SQL Injection in Internet-Facing APIs missing input validation',
      when: [
        {
          type: 'Apis',
        },
      ],
      processTags: [
        {
          key: '1',
          name: 'Security Code Review',
        },
      ],
    },
  },
  {
    riskLevel: 'High',
    count: 5,
    pullRequest: '9060',
    rule: {
      ordinalId: 4,
      name: 'Exposed Secrets in IaC and source code added by a new developer',
      when: [
        {
          type: 'ExposedSecrets',
        },
      ],
      processTags: [
        {
          key: '1',
          name: 'Security Code Review',
        },
      ],
    },
  },
  {
    riskLevel: 'High',
    count: 1,
    pullRequest: '9061',
    rule: {
      ordinalId: 5,
      name: 'PII Added to a sensitive API',
      when: [
        {
          type: 'Apis',
        },
      ],
      processTags: [
        {
          key: '1',
          name: 'Security Design Review',
        },
      ],
    },
  },
  {
    riskLevel: 'Medium',
    count: 4,
    pullRequest: '9060',
    rule: {
      ordinalId: 6,
      name: 'Exposed secrets in test code',
      when: [
        {
          type: 'ExposedSecrets',
        },
      ],
      processTags: [
        {
          key: '1',
          name: 'Security Code Review',
        },
        {
          key: '2',
          name: 'Security Design Review',
        },
      ],
    },
  },
  {
    riskLevel: 'Medium',
    count: 4,
    pullRequest: '9061',
    rule: {
      ordinalId: 7,
      name: 'Payment Processing module was significantly changed',
      when: [
        {
          type: 'Payments',
        },
      ],
      processTags: [
        {
          key: '1',
          name: 'Security Design Review',
        },
      ],
    },
  },
];

const abnormalCommit = {
  id: '261bdf4',
  risk: 'High',
  behavioralFactors: [
    'Committed on irregular hours',
    'Frontend developer adding backend code',
    'Sensitive files modified',
  ],
};

export const demoService = {
  blockLength: blockIndex => demoCommitsBlocks[blockIndex].commits.length,
  labelsForCommit: (blockIndex, demoCommitIndex) =>
    demoCommitsBlocks[blockIndex].commits[demoCommitIndex].governanceRules.flatMap(rule =>
      filter(rule.rule.then, action => action.type === 'Label').map(action => action.value)
    ),
  enrichMaterialChanges: (materialChanges, blockIndex, demoCommitIndex) => ({
    ...materialChanges,
    governanceRules: [
      ...materialChanges.governanceRules,
      ...map(
        demoCommitsBlocks[blockIndex].commits[demoCommitIndex].governanceRules,
        rule => rule.rule
      ),
    ],
    materialChangesByRuleKey: assign(
      materialChanges.materialChangesByRuleKey,
      reduce(
        demoCommitsBlocks[blockIndex].commits[demoCommitIndex].governanceRules,
        (result, rule) => {
          result[rule.rule.key] = [
            {
              0: rule.materialChange,
            },
          ];
          return result;
        },
        {}
      )
    ),
  }),
  applications: demoApplications,
  developmentRiskTriggers: demoDevelopmentRiskTriggers,
  productionAdditionalRiskTriggers: demoProductionAdditionalRiskTriggers,
  abnormalCommit,
  components: demoComponents,
  controls: demoControls,
  createDemoRiskTriggers(profileTriggers) {
    const enrichedRiskTriggers = [...profileTriggers];
    for (const demoRule of demoProductionAdditionalRiskTriggers) {
      const demoRuleRiskOrder = riskOrder[demoRule.riskLevel];
      let pushIndex = 0;
      while (
        pushIndex < enrichedRiskTriggers.length &&
        riskOrder[enrichedRiskTriggers[pushIndex].riskLevel] > demoRuleRiskOrder
      ) {
        pushIndex++;
      }

      const elementType = demoRule.demoElementType;
      if (elementType === 'build') {
        enrichedRiskTriggers.splice(pushIndex, 0, { ...demoRule });
        continue;
      }

      const similarRuleTriggers = Object.values(profileTriggers).find(ruleTriggers =>
        some(ruleTriggers.triggers, trigger => trigger.elementType === elementType)
      );
      if (!similarRuleTriggers) {
        continue;
      }

      const triggers = demoRule
        .demoTriggersSelector(similarRuleTriggers.triggers)
        .map((trigger, index) => ({ ...trigger, elementOverrides: demoRule.triggers[index] }));
      enrichedRiskTriggers.splice(pushIndex, 0, {
        ...demoRule,
        attackSurfaceCount: similarRuleTriggers.attackSurfaceCount,
        triggers,
        triggersByKey: keyBy(triggers, 'key'),
        productLifecyclePhase: 'Development',
      });
    }
    return enrichedRiskTriggers;
  },
  enrichRiskTriggers(profileTriggers, ruleKey) {
    const demoRule = demoProductionAdditionalRiskTriggers.find(rule => rule.ruleKey === ruleKey);
    if (demoRule) {
      const elementType = demoRule.demoElementType;
      const similarRuleTriggers = Object.values(profileTriggers).find(ruleTriggers =>
        some(ruleTriggers.triggers, trigger => trigger.elementType === elementType)
      );

      if (similarRuleTriggers) {
        const triggers = demoRule
          .demoTriggersSelector(similarRuleTriggers.triggers, similarRuleTriggers)
          .map((trigger, index) => ({ ...trigger, elementOverrides: demoRule.triggers[index] }));
        profileTriggers[ruleKey] = {
          ...demoRule,
          triggers,
          triggersByKey: keyBy(triggers, 'key'),
        };
      }
    }
  },
  enrichElementWithDemoInformation(element, trigger) {
    return { ...element, ...trigger.elementOverrides };
  },
};
