import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { EllipsisDiv } from '@src/style/common';
import { TriggerBoxSmallTitle } from './Common';

export const getElementMissingTriggerDisplay = ({ trigger }) => {
  const message = trigger.missingTechnology
    ? `no ${trigger.missingTechnology.toLowerCase()} technologies identified`
    : 'No elements matched the specified condition';

  return [
    {
      weight: 3,
      content: (
        <EllipsisDiv>
          <TriggerBoxSmallTitle>{trigger.consumableDisplayName}</TriggerBoxSmallTitle>
          <EllipsisDiv>{message}</EllipsisDiv>
        </EllipsisDiv>
      ),
    },
  ];
};

export const getElementMissingMessageFormat = ({ name, type, message }) =>
  addInterpunctSeparator(`${name} - ${type}`, message);
