import _ from 'lodash';
import filtersService from '@src/services/filtersService';

export const deployKeyToAccessType = deployKey => (deployKey.readOnly ? 'Read' : 'Read & Write');

export const getDeployKeysFilterOptions = ({ elements: deployKeys }) => {
  const filters = [];

  const createdByValues = _.uniq(
    deployKeys
      .filter(deployKey => !_.isEmpty(deployKey.createdByProfile))
      .map(deployKey => deployKey.createdByProfile.displayName)
  );
  if (createdByValues.length > 1) {
    filters.push({
      name: 'CreatedBy',
      displayName: 'Created By',
      filterOptions: filtersService.getListFilterOptions(createdByValues),
    });
  }

  const accessTypeValues = _.uniq(deployKeys.map(deployKeyToAccessType));
  if (accessTypeValues.length > 1) {
    filters.push({
      name: 'AccessType',
      displayName: 'Access Type',
      filterOptions: filtersService.getListFilterOptions(accessTypeValues),
    });
  }

  return filters;
};

export const filterDeployKeys = (deployKeys, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(deployKeys, searchTerm, deployKey => [
    deployKey.title,
    deployKey.createdByProfile.displayName,
  ]);

  const createdByFilters = filters.listFilters?.CreatedBy;
  if (!_.isEmpty(createdByFilters)) {
    filtered = filtered.filter(deployKey =>
      filterOperator(
        createdByFilters.map(
          createdByFilter => deployKey.createdByProfile.displayName === createdByFilter
        )
      )
    );
  }

  const accessTypeFilters = filters.listFilters?.AccessType;
  if (!_.isEmpty(accessTypeFilters)) {
    filtered = filtered.filter(deployKey =>
      filterOperator(
        accessTypeFilters.map(
          accessTypeFilter => deployKeyToAccessType(deployKey) === accessTypeFilter
        )
      )
    );
  }

  return filtered;
};
