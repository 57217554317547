import { noop } from 'lodash';
import { useCallback } from 'react';
import styled from 'styled-components';
import { ErrorBoundary } from '@src-v2/components/error-boundary';
import { Paragraph } from '@src-v2/components/typography';
import { StyledRemainingChars } from '@src/components/Forms/RemainingChars';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { RuleNameStyle } from '@src/components/Rule/RuleHeader';
import rulesService from '@src/services/rulesService';
import OptionGroup from './OptionGroup';

export default function Rule({
  rule,
  isReadOnly,
  options,
  addOption = noop,
  removeOption = noop,
  setOption = noop,
  setRuleDescription = noop,
  addProperty,
  removeProperty,
  setProperty,
  hideGroupPrefix,
  className,
  validationErrors,
  filterReadOnlyGivenOptions = options => options,
}) {
  const getFilteredOptions = useCallback(
    () => filterReadOnlyGivenOptions(options.given),
    [filterReadOnlyGivenOptions, options.given]
  );

  if (!rule) {
    return null;
  }

  const filteredWhenOptions = rulesService.filterWhenTypes(options, rule.given);
  if (!rule.when.find(when => when.type === 'EntitySatisfiesExpression')) {
    delete filteredWhenOptions.EntitySatisfiesExpression;
  }

  return (
    <ErrorBoundary
      extraInfo={rule}
      fallback={
        <Paragraph>
          We encountered an issue displaying this rule. A report was already sent to our team.
        </Paragraph>
      }>
      <OptionBox className={className}>
        <Portion className="ruleGivenTestMarker">
          {!hideGroupPrefix && <StyledTitle>Given</StyledTitle>}
          <OptionGroup
            isReadOnly={isReadOnly}
            selected={rule.given}
            options={getFilteredOptions()}
            validationErrors={validationErrors?.filter(
              validationError => validationError.rulePortionType === 'Given'
            )}
            addOption={modifications => addOption({ ...modifications, portion: 'given' })}
            removeOption={modifications => removeOption({ ...modifications, portion: 'given' })}
            setSelection={modifications => setOption({ ...modifications, portion: 'given' })}
            addProperty={modifications => addProperty({ ...modifications, portion: 'given' })}
            removeProperty={modifications => removeProperty({ ...modifications, portion: 'given' })}
            setProperty={modifications => setProperty({ ...modifications, portion: 'given' })}
          />
        </Portion>
        <Portion className="ruleWhenTestMarker">
          {!hideGroupPrefix && <StyledTitle>When</StyledTitle>}
          <OptionGroup
            isReadOnly={isReadOnly}
            selected={rule.when}
            options={filteredWhenOptions}
            validationErrors={validationErrors?.filter(
              validationError => validationError.rulePortionType === 'When'
            )}
            addOption={modifications => addOption({ ...modifications, portion: 'when' })}
            removeOption={modifications => removeOption({ ...modifications, portion: 'when' })}
            setSelection={modifications => setOption({ ...modifications, portion: 'when' })}
            addProperty={modifications => addProperty({ ...modifications, portion: 'when' })}
            removeProperty={modifications => removeProperty({ ...modifications, portion: 'when' })}
            setProperty={modifications => setProperty({ ...modifications, portion: 'when' })}
            preventFirstItemRemoval
          />
        </Portion>
        <Portion className="ruleThenTestMarker">
          {!hideGroupPrefix && <StyledTitle>Then</StyledTitle>}
          <OptionGroup
            isReadOnly={isReadOnly}
            selected={rule.then}
            validationErrors={validationErrors?.filter(
              validationError => validationError.rulePortionType === 'Then'
            )}
            options={rulesService.filterThenSubTypes(options, rule.when, rule.given)}
            setSelection={modifications => setOption({ ...modifications, portion: 'then' })}
            addOption={modifications => addOption({ ...modifications, portion: 'then' })}
            removeOption={modifications => removeOption({ ...modifications, portion: 'then' })}
          />
        </Portion>
        {isReadOnly ? (
          Boolean(rule.description) && <Paragraph>{rule.description}</Paragraph>
        ) : (
          <RuleDescriptionContainer>
            <RuleNameStyle
              value={rule.description ?? ''}
              maxLength={250}
              placeholder="Why is it risky?"
              onChange={event => setRuleDescription(event.target?.value)}
            />
            <StyledRemainingChars>{rule?.description?.length ?? 0}/250</StyledRemainingChars>
          </RuleDescriptionContainer>
        )}
      </OptionBox>
    </ErrorBoundary>
  );
}

const Portion = styled(HorizontalStack)`
  align-items: start;
`;

export const OptionBox = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  margin-top: 3rem;
  color: var(--default-text-color);
  font-size: var(--font-size-s);
  line-height: 5rem;
  font-weight: 300;
  gap: 2rem;

  &:last-child {
    padding-bottom: 6rem;
  }

  > :last-child:not(${Portion}) {
    margin-top: 4rem;

    &${Paragraph} {
      padding-right: 4rem;
    }
  }
`;

export const StyledTitle = styled.span`
  width: 12rem;
  flex-shrink: 0;
  line-height: 9rem;
`;

export const RuleDescriptionContainer = styled.div`
  display: flex;
  gap: 2rem;
  align-items: center;

  ${RuleNameStyle} {
    flex-grow: 1;
    border: 1px solid var(--color-green-50);
    background-color: transparent;
    font-size: var(--font-size-s);
  }
`;
