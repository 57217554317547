import { connect } from 'react-redux';
import { select } from '@src/store';
import AssetCollectionPage from './view';

const mapState = select(
  ({ assetCollectionPage: { assetCollectionProfile, hasFetchedData, unmonitored } }) => ({
    assetCollectionProfile,
    hasFetchedData,
    unmonitored,
  })
);

const mapDispatch = ({
  organization: { getOrganizationProfileAsync },
  assetCollectionPage: { fetchData, clearData },
  assetCollectionProfiles: { monitorAllAssetCollectionConsumables },
}) => ({
  fetchData,
  clearData,
  monitorAllAssetCollectionConsumables,
  getOrganizationProfileAsync,
});

export default connect(mapState, mapDispatch)(AssetCollectionPage);
