import _ from 'lodash';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { Dropdown } from '@src-v2/components/dropdown';
import { SelectMenu } from '@src-v2/components/select-menu';
import { Variant } from '@src-v2/components/types/enums/variant-enum';

export const SimpleSelect = observer(
  ({ title, options = [], identity = _.identity, defaultValue, onSelect, ...props }) => {
    const [selected, setSelected] = useState(defaultValue ?? options[0]);

    useEffect(() => {
      setSelected(defaultValue ?? options[0]);
    }, [options, defaultValue]);

    return (
      <SelectMenu placeholder={identity(selected)} {...props} variant={Variant.FILTER}>
        <MaybeGroup title={title}>
          {options?.map((option, index) => (
            <Dropdown.Item
              key={identity(option)}
              selected={option === selected}
              onClick={event => {
                onSelect?.(option, index, event);
                setSelected(option);
              }}>
              {identity(option)}
            </Dropdown.Item>
          ))}
        </MaybeGroup>
      </SelectMenu>
    );
  }
);

function MaybeGroup(props) {
  return props.title ? <Dropdown.Group {...props} /> : props.children;
}
