const defaultLocale = 'en-GB';

interface NumberAbbreviation {
  value: number;
  symbol: string;
}

const numberAbbreviations: NumberAbbreviation[] = [
  { value: 1, symbol: '' },
  { value: 1e3, symbol: 'K' },
  { value: 1e6, symbol: 'M' },
  { value: 1e9, symbol: 'G' },
  { value: 1e12, symbol: 'T' },
  { value: 1e15, symbol: 'P' },
  { value: 1e18, symbol: 'E' },
];

export function abbreviate(number: number, fraction: number = 1): string {
  const { value, symbol } = numberAbbreviations.reduce((result, item) =>
    number >= item.value ? item : result
  );
  return (
    (number / value).toLocaleString(defaultLocale, {
      maximumFractionDigits: fraction,
    }) + symbol
  );
}

export function formatNumber(number: number, fraction: number = 2): string {
  return Number(number).toLocaleString(defaultLocale, {
    maximumFractionDigits: fraction,
  });
}

export function toPercent(
  number: number,
  fraction: number = 1,
  plusSymbol: boolean = false
): string {
  const percent = `${formatNumber(number * 100, fraction)}%`;
  return plusSymbol && number > 0 ? `+${percent}` : percent;
}

export function pluralFormat(
  number: number,
  single: string,
  plural?: string,
  withNumber: boolean = false
): string {
  const stringResult = number === 1 ? single : plural ?? `${single}s`;
  return withNumber ? `${number} ${stringResult}` : stringResult;
}

export function parseAbbreviated(abbreviatedValue: string): number {
  if (!abbreviatedValue || typeof abbreviatedValue !== 'string') {
    throw new Error(`Invalid input: ${abbreviatedValue}`);
  }
  const cleanValue = abbreviatedValue.trim().toUpperCase();

  const abbreviation = numberAbbreviations.slice(1).find(item => cleanValue.endsWith(item.symbol));

  const numericPart = cleanValue
    .slice(0, abbreviation ? -abbreviation.symbol.length : undefined)
    .replace(/,/g, '');

  const baseValue = parseFloat(numericPart);

  if (isNaN(baseValue)) {
    throw new Error(`Invalid number format: ${abbreviatedValue}`);
  }

  return abbreviation ? baseValue * abbreviation.value : baseValue;
}
