import ioc from '@src-v2/ioc';
import apiService from '@src/services/apiService';
import { demoService } from '@src/services/demoService';

const clearData = () => ({
  ruleTriggers: null,
  ruleKey: null,
  element: null,
});

export default {
  state: clearData(),
  reducers: {
    clearData,
    setRuleKey: (state, ruleKey) => ({
      ...state,
      ruleKey,
    }),
    setElement: (state, element) => ({
      ...state,
      element,
    }),
  },
  selectors: slice => ({
    ruleKey: () => slice(state => state?.ruleKey),
    element: () => slice(state => state?.element),
  }),
  effects: dispatch => ({
    async fetchData({ profile, developerIdentityKeys, trigger }) {
      const element = await this.getInventoryElement({ trigger, profile });
      if (ioc.application.isDemo) {
        demoService.enrichElementWithDemoInformation(element, trigger);
      }
      this.setElement(element);
      await dispatch.developerProfiles.getDevelopersByIdentitiesKeysAsync({
        keys: developerIdentityKeys,
      });
    },
    async getInventoryElement({ trigger, profile }) {
      const [, page, key] = window.location.pathname.split('/');

      if (page === 'releases') {
        return await getReleaseInventoryElement(key, trigger);
      }

      return await getProfileInventoryElement(profile.key, profile.profileType, trigger);
    },
  }),
};

const getProfileInventoryElement = async (profileKey, profileType, trigger) =>
  (
    await apiService.post(
      `/api/inventory/elements/getElementFromReference`,
      trigger.diffableDataModelReference
    )
  ).data;

const getReleaseInventoryElement = async (releaseKey, trigger) =>
  (
    await apiService.get(`/api/inventory/elements/release`, {
      params: {
        releaseKey,
        elementKey: trigger.elementKey,
        elementType: trigger.elementType,
        riskChange: trigger.riskChange,
      },
    })
  ).data;
