export const plansChecklists = {
  trial: [
    { label: '50 Repositories', checked: true },
    { label: '3 Applications limit', checked: true },
    { label: 'Customizable governance policies', checked: false },
    { label: 'Risk-based workflows', checked: false },
    { label: 'Security tools integrations', checked: false },
    { label: 'Multi factor authentication', checked: false },
  ],
  premium: [
    { label: 'Unlimited repositories scan', checked: true },
    { label: 'Unlimited number of applications', checked: true },
    { label: 'Customizable governance policies', checked: true },
    { label: 'Unlimited risk-based workflows', checked: true },
    { label: 'Security tools integrations', checked: true },
    { label: 'Multi factor authentication', checked: true },
  ],
};

export const connectorsMapper = {
  Security: 'securityScanners',
  Gateway: 'apiGateways',
  Identity: 'aadIdentity',
};
