import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { Ellipsis, FontBodySmall, FontBodySmallBold } from '@src/style/common';
import Bold from './Bold';
import CodeReferenceLink from './CodeReferenceLink';
import HighlightedCode from './HighlightedCode';
import { HorizontalStack } from './HorizontalStack';

const StyledDiv = styled.div`
  &:hover {
    background-color: var(--color-blue-gray-15);
  }
`;

const SmallSpan = styled.span`
  ${FontBodySmall};
`;

const StyledHighlightedCode = styled(HighlightedCode)`
  ${Ellipsis};
`;
const StyledCodeReferenceLink = styled(CodeReferenceLink)`
  margin-top: 3rem;
  margin-left: 6rem;
`;

const IndentedBold = styled.span`
  ${FontBodySmallBold};
  padding-left: 6rem;
`;

const BoldWithExtraLargeIndentation = styled.span`
  ${FontBodySmallBold};
  padding-left: 12rem;
`;

const StyledHorizontalStack = styled(HorizontalStack)`
  padding-bottom: 6px;
`;

const ParametersUnvalidated = ({ parameterNames }) =>
  parameterNames.map(parameterName => (
    <StyledHorizontalStack key={parameterName} wrapItems>
      <IndentedBold small>{parameterName}</IndentedBold>
    </StyledHorizontalStack>
  ));

const ParameterTypeUnvalidated = ({ repository, unvalidatedParameter }) => {
  const { parameterName, selfValid, codeReference, unvalidatedTypeFieldsNames } =
    unvalidatedParameter;
  if (!selfValid) {
    return <ParametersUnvalidated parameterNames={[parameterName]} />;
  }
  const allFieldsUnvalidated = isEmpty(unvalidatedTypeFieldsNames);
  return (
    <>
      <HorizontalStack wrapItems>
        <IndentedBold small>{parameterName}</IndentedBold>
        <SmallSpan>-</SmallSpan>
        <SmallSpan>validated on method level, but its type</SmallSpan>
        <CodeReferenceLink
          repository={repository}
          relativeFilePath={codeReference?.relativeFilePath}
          lineNumber={codeReference?.lineNumber}>
          <SmallSpan>{codeReference?.name}</SmallSpan>
        </CodeReferenceLink>
        <div>
          <SmallSpan>is not validating </SmallSpan>
          {allFieldsUnvalidated ? (
            <Bold small>any of its fields</Bold>
          ) : (
            <>
              <Bold small>{unvalidatedTypeFieldsNames.length}</Bold>
              <SmallSpan> of its fields:</SmallSpan>
            </>
          )}
        </div>
      </HorizontalStack>
      {!allFieldsUnvalidated &&
        unvalidatedTypeFieldsNames.map(field => (
          <div key={field}>
            <BoldWithExtraLargeIndentation>{field}</BoldWithExtraLargeIndentation>
          </div>
        ))}
    </>
  );
};

export const InputValidationDescriptionBody = ({
  repository,
  parameterNames,
  unvalidatedParameters,
}) =>
  isEmpty(unvalidatedParameters) ? (
    <ParametersUnvalidated parameterNames={parameterNames} />
  ) : (
    unvalidatedParameters.map(unvalidatedParameter => (
      <ParameterTypeUnvalidated
        key={unvalidatedParameter.parameterName}
        repository={repository}
        unvalidatedParameter={unvalidatedParameter}
      />
    ))
  );

const InputValidationDescription = ({
  className,
  repository,
  relativeFilePath,
  lineNumber,
  methodDisplayString,
  parameterNames,
  unvalidatedParameters,
}) => (
  <StyledDiv className={className}>
    {isEmpty(unvalidatedParameters) ? (
      <ParametersUnvalidated parameterNames={parameterNames} />
    ) : (
      unvalidatedParameters.map(unvalidatedParameter => (
        <ParameterTypeUnvalidated
          key={unvalidatedParameter.parameterName}
          repository={repository}
          unvalidatedParameter={unvalidatedParameter}
        />
      ))
    )}
    <StyledCodeReferenceLink
      repository={repository}
      relativeFilePath={relativeFilePath}
      lineNumber={lineNumber}>
      <StyledHighlightedCode code={methodDisplayString} language="java" />
    </StyledCodeReferenceLink>
  </StyledDiv>
);

export default InputValidationDescription;
