import styled from 'styled-components';
import HighlightedCode from '@src/components/HighlightedCode';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { TitledList } from '@src/components/TitledList';
import { VerticalStack } from '@src/components/VerticalStack';
import { Ellipsis, FontBody } from '@src/style/common';

export const StyledDiv = styled.div`
  padding-left: 18px;
`;

const StyledTitledList = styled(TitledList)`
  ${FontBody};
`;

export const StyledWrappedTitledList = props => <StyledTitledList wrap {...props} />;

export const StyledHorizontalStack = styled(HorizontalStack)`
  align-items: start;
  padding: 6px;
`;

export const StyledVerticalStack = styled(VerticalStack)`
  align-items: start;
  padding: 3rem;
`;

export const StyledHighlightedCode = styled(HighlightedCode)`
  ${Ellipsis};
`;
