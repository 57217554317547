import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { Page } from '@src-v2/components/layout/page';
import ApplicationGroupCreate from '@src-v2/containers/application-groups/application-group-create';
import { ApplicationForm, FORM_SECTIONS } from '@src-v2/containers/applications/application-form';
import {
  ApplicationTypeSelection,
  SelectionLayout,
} from '@src-v2/containers/applications/application-selection';
import { AppCreationForm } from '@src-v2/containers/applications/creation-form/app-creation-form';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import AssetCollectionPage from '@src/blocks/AssetCollectionPage';
import RepositoryPage from '@src/blocks/RepositoryPage';
import { SearchPage } from '@src/blocks/RiskProfilesPage/blocks/SearchPage';
import { dispatch } from '@src/store';

function ApplicationSelection(props) {
  const { state } = useLocation();
  const selectionScreen = !state || state.activeSection === FORM_SECTIONS.SELECTION;
  if (selectionScreen) {
    return (
      <AsyncBoundary>
        <SelectionLayout>
          <ApplicationTypeSelection {...props} />
        </SelectionLayout>
      </AsyncBoundary>
    );
  }
  return <ApplicationFormConnector {...props} />;
}

export function RiskProfilesPage(legacyProps) {
  const { application } = useInject();
  const useFormV2 = application.isFeatureEnabled(FeatureFlag.ApplicationCreationForm);
  return (
    <Switch>
      {/* Applications */}
      <Route
        path="/profiles/applications/create"
        component={useFormV2 ? ApplicationFormV2Connector : ApplicationSelection}
        exact
      />
      <Route
        path={['/profiles/applications/create/multiple', '/profiles/applications/create/mono']}
        component={ApplicationFormConnector}
        exact
      />
      <Route
        path="/profiles/applications/:key/edit"
        component={useFormV2 ? ApplicationFormV2Connector : ApplicationFormConnector}
      />
      <Route path="/profiles/applications/:key" component={AssetCollectionPage} />

      {/* Application Groups */}
      <Route path="/profiles/groups" exact>
        <SearchPage {...legacyProps} profileType="applicationGroups" title="application groups" />
      </Route>
      <Route path="/profiles/groups/create" exact>
        <Page title="Create Group">
          <ApplicationGroupCreate />
        </Page>
      </Route>
      <Route path="/profiles/groups/:key/edit" exact>
        <Page title="Edit Group">
          <ApplicationGroupCreate editMode />
        </Page>
      </Route>

      {/* Repositories */}
      <Route path="/profiles/repositories/:key" component={RepositoryPage} />

      {/* SearchPage */}
      <Route path="/profiles/applications" exact>
        <SearchPage {...legacyProps} profileType="assetCollections" />
      </Route>
      <Route path="/profiles/repositories" exact>
        <SearchPage {...legacyProps} title="Repositories" profileType="repositories" />
      </Route>

      <Redirect to="/profiles/applications" />
    </Switch>
  );
}

const ApplicationFormV2Connector = () => {
  const { key } = useParams();

  return (
    <Page title={key ? 'Edit application' : 'Create application'}>
      <AppCreationForm
        onSubmit={key => dispatch.assetCollectionProfiles.resyncAssetCollection({ key })}
      />
    </Page>
  );
};

const ApplicationFormConnector = () => (
  <ApplicationForm
    onCreate={data => dispatch.assetCollectionProfiles.resyncAssetCollection(data)}
  />
);
