import { range } from 'lodash';
import { useLayoutEffect } from 'react';

export function useIntersectionObserver(ref, handler) {
  useLayoutEffect(() => {
    const observer = new IntersectionObserver(handler, { threshold: range(0, 1, 0.0005) });
    observer.observe(ref.current);
    return () => observer.disconnect();
  }, [ref.current, handler]);
}
