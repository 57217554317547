import _ from 'lodash';
import { useState } from 'react';
import styled from 'styled-components';
import ElementTimelineSelector from '@src/blocks/ElementTimelineSelector';
import { PaneBody } from '@src/blocks/Pane/view';

const TabsList = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

const Tab = styled.span`
  padding: 5px 14px 6px 12px;
  border-radius: 100vmax;
  cursor: pointer;
  text-shadow: ${props => (props.active ? '0.5px 0 var(--color-black)' : 'none')};
  background-color: ${props => (props.active ? 'var(--color-white)' : 'none')};
`;

const Tabs = ({ tabs, activeTabIndex, setActiveTabIndex }) => (
  <TabsList>
    {tabs.map((tabLabel, index) => (
      <Tab key={index} onClick={() => setActiveTabIndex(index)} active={activeTabIndex === index}>
        {tabLabel}
      </Tab>
    ))}
  </TabsList>
);

export const ElementPane = ({ element, repository, title, profileBody }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const tabNames = [`Profile`, 'Timeline'];

  const body = (
    <>
      <Tabs tabs={tabNames} activeTabIndex={activeTabIndex} setActiveTabIndex={setActiveTabIndex} />
      {activeTabIndex === 0 ? (
        profileBody
      ) : (
        <ElementTimelineSelector
          repositoryKeys={!_.isEmpty(repository?.key) ? [repository.key] : element.repositoryKeys}
          entityType={element.entityType}
          entityId={element.entityId}
        />
      )}
    </>
  );

  return <PaneBody title={title} body={body} />;
};
