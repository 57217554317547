import { observer } from 'mobx-react';
import { Button } from '@src-v2/components/button-v2';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Variant } from '@src-v2/components/types/enums/variant-enum';
import { StubAny } from '@src-v2/types/stub-any';

export const BulkMonitorButton = observer(
  ({
    data,
    onClick,
    searchState,
  }: {
    data: StubAny[];
    onClick: StubAny;
    searchState: StubAny; // Replace with actual search state type when available.
  }) => {
    const isAllMonitored = data.every(item => item.isMonitored);

    return (
      <Tooltip
        content={
          isAllMonitored ? 'Un-Monitor Selected Repositories' : 'Monitor Selected Repositories'
        }>
        <Button loading={searchState.loading} onClick={onClick} variant={Variant.SECONDARY}>
          {isAllMonitored ? 'Unmonitor' : 'Monitor'}
        </Button>
      </Tooltip>
    );
  }
);
