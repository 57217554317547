import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { VendorIcon } from '@src-v2/components/icons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink, Link } from '@src-v2/components/typography';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import { stopPropagation } from '@src-v2/utils/dom-utils';
import Bold from '@src/components/Bold';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Number } from '@src/components/Number';
import { TitledList } from '@src/components/TitledList';
import { VerticalStack } from '@src/components/VerticalStack';

const StyledExternalLink = styled(ExternalLink)`
  width: fit-content;
  font-size: var(--font-size-s);
`;

export const CodeFindingDescription = ({ finding, repository }) => {
  return (
    <VerticalStack>
      {!isEmpty(finding.remediation) && (
        <CustomVerticalStack>
          <Bold>Remediation</Bold>
          <div>{finding.remediation}</div>
        </CustomVerticalStack>
      )}
      {Boolean(repository) && finding.filePath && !isEmpty(finding.filePath) && (
        <Tooltip content="View finding code">
          <StyledExternalLink
            href={generateCodeReferenceUrl(repository, {
              relativeFilePath: finding.filePath,
              lineNumber: finding.lineNumber,
            })}>
            {finding.filePath}
            {Boolean(finding.lineNumber) && ` line: ${finding.lineNumber}`}
          </StyledExternalLink>
        </Tooltip>
      )}
      {!isEmpty(Object.keys(finding.tags)) && (
        <CustomVerticalStack>
          <Bold>Tags</Bold>
          <TitledList
            list={Object.entries(finding.tags)}
            listItemRender={([tagName, tagValue]) => (
              <span>
                {tagName}: {tagValue}
              </span>
            )}
          />
        </CustomVerticalStack>
      )}
      {!isEmpty(finding.cweIdentifiers) && (
        <CustomVerticalStack>
          <Bold>CWE</Bold>
          <div>{finding.cweIdentifiers.join(', ')}</div>
        </CustomVerticalStack>
      )}
      {!isEmpty(finding.cicdPipelineDeclarationReferences) && (
        <HorizontalStack>
          <Number
            value={finding.cicdPipelineDeclarationReferences.length}
            one="Related pipeline:"
            other="Related pipelines:"
            showNumber={false}
          />
          <VerticalStack>
            {finding.cicdPipelineDeclarationReferences.map(item => (
              <CustomHorizontalStack>
                <VendorIcon name={item.cicdTechnology} />
                <Link
                  to={`/profiles/repositories/${repository.key}/inventory/supplyChain/pipelines`}
                  onClick={stopPropagation}>
                  {item.cicdPipelineDeclarationId}
                </Link>
              </CustomHorizontalStack>
            ))}
          </VerticalStack>
        </HorizontalStack>
      )}
    </VerticalStack>
  );
};

export const CustomHorizontalStack = styled(HorizontalStack)`
  gap: 1rem;
`;

export const CustomVerticalStack = styled(VerticalStack)`
  margin-top: 8rem;
  gap: 1rem;

  h2 {
    font-size: var(--font-size-m);
  }

  pre {
    font-size: var(--font-size-xs);
  }
`;
