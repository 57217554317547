import _ from 'lodash';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Heading, Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  HighlightedCode,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';
import { getMethodName } from '@src-v2/containers/commit/material-changes-utils';

const getAccessingMethodDescription = (api, repository, commitSha, action) => {
  return (
    <MaterialChangesRow>
      <Paragraph>Method</Paragraph>
      <CommitCodeReference
        repository={repository}
        commitSha={commitSha}
        relativeFilePath={api.codeReference?.relativeFilePath}
        lineNumber={api.codeReference?.lineNumber ?? 0}>
        {api.codeReference?.methodName && getMethodName(api.codeReference.methodName, true)}
      </CommitCodeReference>
      <Paragraph>{action === 'read' ? 'reads from' : 'writes to'}</Paragraph>
      <Paragraph>{_.first(api.readsFromStorageBucket)?.framework}</Paragraph>
      <Paragraph>storage bucket</Paragraph>
    </MaterialChangesRow>
  );
};

const getAccessingMethodsList = (materialChange, repository, commitSha) => {
  const reads = materialChange.readsFromStorageBucket.map(api =>
    getAccessingMethodDescription(api, repository, commitSha, 'read')
  );
  const writes = materialChange.writesToStorageBucket.map(api =>
    getAccessingMethodDescription(api, repository, commitSha, 'write')
  );
  return reads.concat(writes);
};

export const StorageBucketAccessMaterialChange = ({
  commitSha,
  repository,
  materialChange,
  thenSubType,
}) => {
  const { relativeFilePath, lineNumber, apiName, methodName, className, methodSignature } =
    materialChange.codeReference;
  return (
    <>
      <Heading>
        <Paragraph>API</Paragraph>
        <Tooltip content={<HighlightedCode language="java">{methodSignature}</HighlightedCode>}>
          <Paragraph>{apiName ?? methodName}</Paragraph>
        </Tooltip>
        <Paragraph>from class</Paragraph>
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}
          lineNumber={lineNumber}>
          {className ?? relativeFilePath}
        </CommitCodeReference>
        <Paragraph>storage bucket access was</Paragraph>
        <Strong>{thenSubType}</Strong>
      </Heading>
      {getAccessingMethodsList(materialChange, repository, commitSha)}
    </>
  );
};
