import { resourceTypes } from '@src-v2/data/rbac-types';

export const roleHasPermission = (role, permission) =>
  role.permissions.some(existingPermission => permissionEquals(permission, existingPermission));

export const permissionEquals = (permission, other) =>
  permission.resourceName === other.resourceName && permission.accessType === other.accessType;

export const globalWritePermission = { resourceName: resourceTypes.Global, accessType: 'Write' };

// eslint-disable-next-line no-undef
export const globalReadPermission = { resourceName: resourceTypes.Global, accessType: 'Read' };
