import DefinitionConsts from '@src/blocks/GovernancePage/blocks/Definitions/DefinitionConsts';

const riskLevels = {
  low: 'Low',
  medium: 'Medium',
  high: 'High',
  critical: 'Critical',
};

export const apiClassificationDescription = classification => {
  switch (classification.toLowerCase()) {
    case 'internetfacing':
      return 'internet facing';
    case 'internal':
      return 'internal';
    default:
      return 'unknown';
  }
};

export const transformDefinitionToRule = (type, definition) => {
  switch (type) {
    case DefinitionConsts.types.API:
      return apiClassificationRuleTransformer(definition);
    case DefinitionConsts.types.Issue:
      return buildDefinitionToRuleTransformer(type, definition);
    case DefinitionConsts.types.UserStory:
      return buildDefinitionToRuleTransformer(type, definition);
    case DefinitionConsts.types.InternalFramework:
      return internalFrameworkToRuleTransformer(type, definition);
    case DefinitionConsts.types.CustomSensitiveData:
      return customSensitiveDataToRuleTransformer(type, definition);
    default:
      return null;
  }
};

export const buildDefinitionToRuleTransformer = (type, definition) => {
  const isIssueType = type === DefinitionConsts.types.Issue;
  const riskLevel = isIssueType ? riskLevels.high : riskLevels.medium;

  return {
    type: 'legacyRule',
    key: crypto.randomUUID(),
    definitionKey: definition.key,
    definitionName: definition.displayName,
    definitionType: isIssueType ? 'Tracked Issues' : 'Feature Requests',
    name: '',
    given: [
      {
        type: DefinitionConsts.typeDefinitionToRule[type].given,
        value: definition.displayName,
        values: [definition.displayName],
      },
    ],
    when: [
      {
        type: DefinitionConsts.typeDefinitionToRule[type].when,
        value: '',
        values: [''],
      },
    ],
    then: [
      {
        type: 'Risk',
        subType: 'Present',
        values: [riskLevel],
      },
    ],
  };
};

const apiClassificationRuleTransformer = definition => {
  const riskLevel =
    definition.then[0].values[0] === 'internal' ? riskLevels.medium : riskLevels.high;

  return {
    type: 'legacyRule',
    key: crypto.randomUUID(),
    definitionKey: definition.key,
    definitionName: definition.name,
    definitionType: 'Api Routes',
    name: '',
    given: [...definition.given],
    when: [
      {
        type: DefinitionConsts.typeDefinitionToRule[definition.then[0].type],
        value: apiClassificationDescription(definition.then[0].values[0]),
        values: definition.then[0].values.map(value => apiClassificationDescription(value)),
      },
    ],
    then: [
      {
        type: 'Risk',
        subType: 'Present',
        values: [riskLevel],
      },
    ],
  };
};

const internalFrameworkToRuleTransformer = (type, definition) => ({
  type: 'legacyRule',
  key: crypto.randomUUID(),
  definitionKey: definition.key,
  definitionName: definition.frameworkName,
  definitionType: 'Custom Framework',
  name: '',
  given: [
    {
      type: DefinitionConsts.typeDefinitionToRule[type].given,
      values: ['any'],
    },
  ],
  when: [
    {
      type: DefinitionConsts.typeDefinitionToRule[type].when,
      subType: definition.frameworkType,
      values: [definition.frameworkName],
    },
  ],
  then: [
    {
      type: 'Risk',
      subType: 'Missing',
      values: [riskLevels.high],
    },
  ],
});

const customSensitiveDataToRuleTransformer = (type, definition) => {
  return definition.shouldOpenRuleBanner
    ? {
        type: 'legacyRule',
        key: crypto.randomUUID(),
        definitionKey: definition.key,
        definitionName: definition.name,
        definitionType: 'Custom Sensitive Data',
        name: '',
        when: [
          {
            type: DefinitionConsts.typeDefinitionToRule[type].when,
            subType: 'Custom',
            values: ['any'],
          },
        ],
      }
    : null;
};
