import _ from 'lodash';
import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { LogoSpinner } from '@src-v2/components/animations/spinner';
import { ProcessTag } from '@src-v2/components/tags';
import { AddElementPlaceholder } from '@src/components/AddElementPlaceholder';
import { Button } from '@src/components/Button';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { VerticalStack } from '@src/components/VerticalStack';
import { FontBodyBold } from '@src/style/common';

const StyledButton = styled(Button)`
  padding-left: 6px;
  padding-right: 6px;
`;

const CreateButton = styled(StyledButton)`
  color: var(--color-green-55);

  &:hover {
    color: var(--color-green-50);
  }

  &.disabled {
    color: var(--color-blue-gray-40);
    opacity: 1;
  }
`;

const Container = styled.div`
  position: relative;
  height: 38px;
`;

const StyledCreateProcess = styled(VerticalStack)`
  background-color: var(--color-white);
  position: absolute;
  width: 210px;
  border: 0.25rem solid var(--color-blue-gray-35);
  box-shadow: 0 2px 9px 0 rgba(186, 202, 213, 0.3);
  padding: 6px;
  border-radius: 1rem;
  z-index: 1000;
`;

const StyledCreateButtons = styled(HorizontalStack)`
  border-top: 0.25rem solid var(--color-blue-gray-35);
  padding-top: 2rem;

  ${LogoSpinner} {
    height: 7rem;
    margin: 1rem auto;
  }
`;

const StyledHeader = styled.div`
  ${FontBodyBold};
`;

const StyledErrorMessage = styled.div`
  color: var(--color-red-20);
`;

const CreateProcess = ({
  fetchProcessTypes,
  newProcess,
  onCancel,
  error,
  loading,
  createProcessTag,
  processTypes,
}) => {
  const [selectedType, setType] = useState(null);

  useEffect(() => fetchProcessTypes(), [fetchProcessTypes]);

  return (
    <Container>
      <StyledCreateProcess>
        <AddElementPlaceholder text={newProcess} showAddIcon={false} />

        {!_.isEmpty(error) && <StyledErrorMessage>{error}</StyledErrorMessage>}

        <StyledHeader>Create as part of</StyledHeader>

        <VerticalStack>
          {processTypes?.map(type => (
            <ProcessTag
              key={type.key}
              selected={selectedType?.key === type.key}
              onClick={() => setType(type)}>
              {type.name}
            </ProcessTag>
          ))}
        </VerticalStack>
        <StyledCreateButtons align="right" spacing="0">
          {loading ? (
            <LogoSpinner />
          ) : (
            <>
              <StyledButton onClick={onCancel}>Cancel</StyledButton>
              <CreateButton
                disabled={_.isNil(selectedType)}
                onClick={() =>
                  createProcessTag({
                    name: newProcess,
                    type: selectedType.key,
                  })
                }>
                Create
              </CreateButton>
            </>
          )}
        </StyledCreateButtons>
      </StyledCreateProcess>
    </Container>
  );
};

export default CreateProcess;
