import { HorizontalStack } from '@src/components/HorizontalStack';
import { openGqlOperationPaneWithRiskActions } from '@src/components/Panes/GqlOperationPane';
import { EllipsisDiv } from '@src/style/common';
import { getTrimmedPath } from '@src/utils/stringDisplayUtils';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getGqlOperationTriggerDisplay = ({ trigger, ...options }) => [
  {
    weight: 3,
    content: (
      <PaneLocationLink
        id={trigger.key}
        onClick={args => openGqlOperationPaneWithRiskActions({ ...args, trigger, ...options })}>
        <TriggerInfoContainer
          title={getTrimmedPath(trigger.codeReference.relativeFilePath)}
          insights={trigger.insights}>
          <HorizontalStack withSeparator>
            <EllipsisDiv>{trigger.displayName}</EllipsisDiv>
            <span>{trigger.operationType}</span>
          </HorizontalStack>
        </TriggerInfoContainer>
      </PaneLocationLink>
    ),
  },
];

export const getGqlOperationMessageFormat = element => element.codeReference.relativeFilePath;
