import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { IconButton } from '@src-v2/components/buttons';
import { BaseIcon } from '@src-v2/components/icons';
import { useInject } from '@src-v2/hooks';
import { FeatureFlag } from '@src-v2/types/enums/feature-flag';
import { dataAttr } from '@src-v2/utils/dom-utils';

export const JwtCopyBanner = ({ jwt }) => {
  const { toaster, application } = useInject();
  const { pathname } = useLocation();

  const handleCopy = async () => {
    await navigator.clipboard.writeText(jwt);
    toaster.success('Token copied to clipboard!');
  };

  return application.isFeatureEnabled(FeatureFlag.AccessTokenForm) ? (
    <TokenCopyBannerV2 data-edit={dataAttr(pathname.includes('edit'))}>
      New access token created, quickly copy to clipboard{' '}
      <IconButton name="Copy" onClick={handleCopy} />
    </TokenCopyBannerV2>
  ) : (
    <TokenCopyBanner>
      New access token created, quickly copy to clipboard{' '}
      <IconButton name="Copy" onClick={handleCopy} />
    </TokenCopyBanner>
  );
};

const TokenCopyBanner = styled.div`
  display: flex;
  margin-top: 6rem;
  padding-top: 6rem;
  align-items: center;
  border-top: 0.25rem solid var(--color-blue-gray-20);
  gap: 2rem;

  > ${BaseIcon} {
    width: 8rem;
    height: 8rem;
  }
`;

const TokenCopyBannerV2 = styled.div`
  display: flex;
  align-items: center;
  font-size: var(--font-size-s);
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 0.25rem solid var(--color-blue-gray-20);
  gap: 2rem;

  &[data-edit] {
    margin-top: 0;
  }
`;
