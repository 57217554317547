import styled from 'styled-components';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { secondaryPaneLevel } from '@src/blocks/Pane/model';
import { getGqlObjectFieldCounts } from '@src/blocks/RiskPosture/blocks/TriggersDisplay/GqlObject';
import { LegacyPaneStylingContainer } from '@src/blocks/RiskPosture/legacy-pane-styling-container';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import { Highlights } from '@src/components/CodeAggregation/Insights/Highlights';
import { StyledInsightsTitle } from '@src/components/CodeAggregation/Insights/InsightStyles';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { ElementPane } from '@src/components/ElementPane';
import { Number } from '@src/components/Number';
import { VerticalStack } from '@src/components/VerticalStack';
import ViewCode from '@src/components/ViewCode';
import { dispatch } from '@src/store';

export const openGqlObjectPaneWithRiskActions = ({
  ruleKey,
  trigger,
  profile,
  profileType,
  onClose,
  relevantPath,
  messageContent,
  externalRiskTriggerPane,
  level = secondaryPaneLevel,
}) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  dispatch.pane.openPane({
    level,
    onClose,
    relevantPath,
    id: trigger.key,
    content: (
      <RiskRuleTrigger
        trigger={trigger}
        profile={profile}
        ruleKey={ruleKey}
        externalRiskTriggerPane={externalRiskTriggerPane}
        getPane={({ element, ruleTriggers }) =>
          getGqlObjectPane({
            trigger,
            gqlObject: element,
            repository,
            profile,
            profileType,
            ruleTriggers,
            messageContent,
          })
        }
      />
    ),
  });
};

const getGqlObjectPane = ({
  trigger,
  gqlObject,
  repository,
  profile,
  profileType,
  ruleTriggers,
  messageContent,
}) => {
  return (
    <ElementPane
      element={gqlObject}
      repository={repository}
      title={
        <InsightsPaneTitle
          trigger={trigger}
          element={gqlObject}
          title={`${gqlObject.name} GraphQL Object`}
          codeReference={gqlObject.codeReference}
          repository={repository}
          profile={profile}
          profileType={profileType}
          ruleTriggers={ruleTriggers}
          messageContent={messageContent}
        />
      }
      profileBody={getInsightsBody(gqlObject, repository)}
    />
  );
};

export function LegacyGqlObjectPane({ gqlObject, repository }) {
  return (
    <GqlObjectStylingContainer>{getInsightsBody(gqlObject, repository)}</GqlObjectStylingContainer>
  );
}

const GqlObjectStylingContainer = styled(LegacyPaneStylingContainer)`
  display: flex;
  flex-direction: column;
  gap: 2rem;
`;

const getHighlights = gqlObject => {
  const { plainFieldsCount, resolverFieldsCount } = getGqlObjectFieldCounts(gqlObject);
  const highlights = [];
  if (plainFieldsCount > 0) {
    highlights.push(<Number one="field" other="fields" value={plainFieldsCount} />);
  }

  if (resolverFieldsCount > 0) {
    highlights.push(<Number one="resolver" other="resolvers" value={resolverFieldsCount} />);
  }

  return highlights;
};

const getInsightsBody = (gqlObject, repository) => (
  <>
    <Highlights highlights={getHighlights(gqlObject)} />
    <VerticalStack>
      <StyledInsightsTitle>Fields</StyledInsightsTitle>
      {gqlObject.fields.map(({ name, type, resolverReference }) => (
        <div>
          <span>{name}</span>: <StyledFieldType>{type}</StyledFieldType>
          {resolverReference && (
            <StyledViewCode
              url={generateCodeReferenceUrl(repository, resolverReference)}
              tip="View resolver code"
              inline={true}
            />
          )}
        </div>
      ))}
    </VerticalStack>
  </>
);

const StyledFieldType = styled.span`
  color: var(--color-blue-gray-45);
`;

const StyledViewCode = styled(ViewCode)`
  margin-left: 3px;
  transform: scale(0.75);
`;
