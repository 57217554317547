import { ProviderGroup } from '@src-v2/types/enums/provider-group';

export const TicketingProviders = {
  Github: ProviderGroup.Github,
  Jira: ProviderGroup.Jira,
  AzureDevops: ProviderGroup.AzureDevops,
};

export const IssueSchemaByProvider = {
  [TicketingProviders.Github]: [
    { key: 'description', type: 'string' },
    { key: 'assignee', type: 'user' },
    { key: 'labels', type: 'array' },
  ],
};

export const SummaryFieldPathToProvider = {
  [TicketingProviders.Jira]: ['fieldsValues', 'summary'],
  [TicketingProviders.AzureDevops]: ['fieldsValues', 'System.Title'],
};

export function createIssueSummary(riskData) {
  return `[Apiiro] ${riskData.ruleName} · ${riskData.riskLevel} Risk`;
}

export function stringifyIssueId(id) {
  return isNaN(Number(id)) ? id : `#${id}`;
}
