import _ from 'lodash';
import styled from 'styled-components';
import { AsyncBoundary } from '@src-v2/components/async-boundary';
import { IconButton } from '@src-v2/components/buttons';
import { CheckboxToggle } from '@src-v2/components/forms';
import { Pane } from '@src-v2/components/panes/pane';
import { usePaneState } from '@src-v2/components/panes/pane-context-provider';
import { generateCodeReferenceUrl } from '@src-v2/data/connectors';
import { useInject, useQueryParams, useSuspense, useToggle } from '@src-v2/hooks';
import { humanize } from '@src-v2/utils/string-utils';
import InsightsPaneTitle from '@src/blocks/InsightsPaneTitle';
import { primaryPaneLevel } from '@src/blocks/Pane/model';
import { PaneBody } from '@src/blocks/Pane/view';
import { LegacyPaneStylingContainer } from '@src/blocks/RiskPosture/legacy-pane-styling-container';
import RiskRuleTrigger from '@src/blocks/RiskRuleTrigger';
import { ArchitectureGraph, elementTypeToProperties } from '@src/components/ArchitectureGraph';
import Bold from '@src/components/Bold';
import { getViewCodeColumn } from '@src/components/CodeAggregation/Common';
import { Highlights } from '@src/components/CodeAggregation/Insights/Highlights';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import Collapsible from '@src/components/Collapsible';
import { ElementPane } from '@src/components/ElementPane';
import { HorizontalStack } from '@src/components/HorizontalStack';
import { Link } from '@src/components/Link';
import { CommitCodeReference } from '@src/components/MaterialChange/MaterialChangeUtils';
import { Number } from '@src/components/Number';
import { Table } from '@src/components/Table';
import { VerticalStack } from '@src/components/VerticalStack';
import { dispatch } from '@src/store';
import { Ellipsis, FontBodyExtraSmall } from '@src/style/common';

const StyledVerticalStack = styled(VerticalStack)`
  ${Ellipsis};
  padding-left: 6rem;
`;
const SmallFontSpan = styled.span`
  ${FontBodyExtraSmall};
  padding-left: 18px;
`;

const StyledSpan = styled.span`
  padding-left: 6rem;
`;

const StyledArchitectureGraph = styled(ArchitectureGraph)`
  position: relative;
  min-width: 680px;
  min-height: 520px;
  flex: 1 0 auto;
  background-color: var(--color-white);

  > svg {
    position: absolute;
  }
`;

export const GraphHeader = styled.div`
  display: flex;
  padding: 9rem 0 ${props => (props.collapsed ? '0' : '8rem')};
  align-items: center;
  justify-content: space-between;
  font-size: var(--font-size-xl);
  font-weight: 600;
`;

const GraphContainer = styled.div`
  display: flex;
  min-width: 225rem;
  padding: 5rem;
  background-color: var(--color-blue-gray-15);
  gap: 4rem;

  > h2 {
    font-size: var(--font-size-xl);
    font-weight: 600;
  }
`;

const GraphLegend = styled.div`
  display: flex;
  width: 188px;
  flex: 0 0 auto;
  font-size: 14px;
  line-height: 24px;
  flex-direction: column;
  justify-content: flex-end;
`;

const GraphLegendRow = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const GraphLegendColor = styled.div`
  width: 12px;
  height: 12px;
  flex: 0 0 auto;
  border-radius: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  background-color: ${props => props.color ?? 'white'};
`;

const resourcesByCategory = resources => _.groupBy(resources, resource => resource.iacCategory);

export const MisconfigurationDisplay = ({
  misconfigurations,
  repository,
  resource,
  commitSha = null,
  graphEnabled = true,
}) => {
  const paneState = usePaneState();
  const { updateQueryParams } = useQueryParams();
  const [showGraph, toggleGraph] = useToggle(true);
  const { inventory } = useInject();
  const terraformModuleHighlights = useSuspense(inventory.getTerraformHighlights, {
    repositoryKey: repository.key,
    modulePath: resource.modulePath,
  });

  const groupedMisconfigurations = _.groupBy(misconfigurations, _ => _.description);
  const aggregatedMisconfigurationsByDisplay = _.map(
    Object.keys(groupedMisconfigurations),
    key => ({
      description: key,
      lineInFile: groupedMisconfigurations[key][0].lineInFile,
      checkCategory: groupedMisconfigurations[key][0].checkCategories[0],
      fields: groupedMisconfigurations[key].flatMap(
        misconfiguration => misconfiguration.misconfiguredProperties
      ),
    })
  );
  const aggregatedMisconfigurationsByCategory = _.groupBy(
    aggregatedMisconfigurationsByDisplay,
    misconfiguration => misconfiguration.checkCategory
  );
  const nodes =
    graphEnabled &&
    terraformModuleHighlights.resourcesSummary.map(resource => ({
      id: `${resource.type}.${resource.key}`,
      type: resource.iacCategory,
      name: resource.name,
      violating: resource.misconfigurations.length,
      resource,
    }));
  const edges =
    graphEnabled &&
    terraformModuleHighlights.resourcesGraphEdges.map(edge => ({
      source: edge.source,
      target: edge.destination,
    }));
  return (
    <VerticalStack>
      {graphEnabled && (
        <GraphHeader collapsed={!showGraph}>
          View Connections Graph
          <CheckboxToggle checked={showGraph} onChange={toggleGraph} />
        </GraphHeader>
      )}
      {graphEnabled && showGraph && (
        <GraphContainer>
          <StyledArchitectureGraph
            nodes={nodes}
            edges={edges}
            onNodeClick={(event, { resource, violating }) => {
              event.stopPropagation();
              if (violating) {
                updateQueryParams({ trigger: null });
                openResourcePane(
                  {
                    id: `${resource.type}${resource.key}`,
                    resource,
                    repository,
                    terraformModuleHighlights,
                  },
                  paneState
                );
              }
            }}
          />
          <GraphLegend>
            {legendItems(nodes).map(type => (
              <GraphLegendRow key={type}>
                <GraphLegendColor color={elementTypeToProperties[type]?.color} />
                {type}
              </GraphLegendRow>
            ))}
          </GraphLegend>
        </GraphContainer>
      )}
      {Object.keys(aggregatedMisconfigurationsByCategory).map(checkCategory => (
        <VerticalStack key={checkCategory}>
          <span>
            <Bold>{checkCategory !== 'undefined' ? humanize(checkCategory) : 'General'}</Bold>{' '}
            misconfigurations:
          </span>
          <StyledVerticalStack>
            {_.map(aggregatedMisconfigurationsByCategory[checkCategory], misconfiguration => (
              <VerticalStack key={checkCategory + misconfiguration.description}>
                {_.isNil(commitSha) ? (
                  <Link
                    url={generateCodeReferenceUrl(repository, {
                      relativeFilePath: resource.path,
                      lineNumber: misconfiguration.lineInFile,
                    })}
                    external>
                    {misconfiguration.description}
                  </Link>
                ) : (
                  <CommitCodeReference
                    repository={repository}
                    commitSha={commitSha}
                    relativeFilePath={resource.path}>
                    {misconfiguration.description}
                  </CommitCodeReference>
                )}
                {_.some(misconfiguration.fields) && (
                  <SmallFontSpan>
                    Relevant resource fields: {misconfiguration.fields.join(', ')}
                  </SmallFontSpan>
                )}
              </VerticalStack>
            ))}
          </StyledVerticalStack>
        </VerticalStack>
      ))}
    </VerticalStack>
  );
};

const TerraformResourcePane = ({
  trigger,
  element,
  repository,
  profile,
  profileType,
  ruleTriggers,
  messageContent,
}) => {
  const resource = element.resource || element;
  return (
    <PaneBody
      title={
        <InsightsPaneTitle
          title={`${resource.typeDescription} - ${resource.key}`}
          trigger={trigger}
          element={element}
          profile={profile}
          profileType={profileType}
          ruleTriggers={ruleTriggers}
          messageContent={messageContent}
          repository={repository}
        />
      }
      overview={
        <StyledVerticalStack>
          <div>{resource.iacCategory}</div>
          <Number
            value={resource.misconfigurations.length}
            one="misconfiguration"
            other="misconfigurations"
          />
        </StyledVerticalStack>
      }
      body={
        <AsyncBoundary>
          <MisconfigurationDisplay
            misconfigurations={resource.misconfigurations}
            repository={repository}
            resource={resource}
          />
        </AsyncBoundary>
      }
    />
  );
};

export function LegacyTerraformModuleHighlightsPane({ resource, repository }) {
  return (
    <TerraformStylingContainer>
      <StyledVerticalStack>
        <div>{resource.iacCategory}</div>
        <Number
          value={resource.misconfigurations.length}
          one="misconfiguration"
          other="misconfigurations"
        />
      </StyledVerticalStack>
      <MisconfigurationDisplay
        misconfigurations={resource.misconfigurations}
        repository={repository}
        resource={resource}
        graphEnabled={false}
      />
    </TerraformStylingContainer>
  );
}

const TerraformStylingContainer = styled(LegacyPaneStylingContainer)`
  > ${VerticalStack} {
    &:not(:first-child) {
      margin-top: 4rem;
    }

    ${GraphHeader} {
      font-size: var(--font-size-s);
      margin-bottom: 2rem;
      padding: 0;
    }

    > ${VerticalStack}:not(:first-child) {
      margin-top: 4rem;
    }
  }
`;

const openResourcePane = ({ id, repository, resource }, paneState = {}) => {
  paneState
    ? paneState.pushPane(
        <Pane>
          <TerraformResourcePane element={resource} repository={repository} />
        </Pane>
      )
    : dispatch.pane.openPane({
        id,
        content: <TerraformResourcePane element={resource} repository={repository} />,
        level: primaryPaneLevel,
      });
};

export const openTerraformModuleHighlightsPane = (terraformModuleHighlights, profile) => {
  const repository =
    profile.repository ||
    getRepositoryForInventoryPropertyFromProfileByKey(
      terraformModuleHighlights.repositoryKeys[0],
      profile
    );

  const highlights = getHighlights(terraformModuleHighlights);
  dispatch.pane.openPane({
    content: (
      <ElementPane
        element={terraformModuleHighlights}
        repository={repository}
        title={terraformModuleHighlights.modulePath}
        profileBody={
          <TerraformModuleHighlightsPaneBody
            terraformModuleHighlights={terraformModuleHighlights}
            repository={repository}
            highlights={highlights}
          />
        }
      />
    ),
  });
};

const getHighlights = terraformModuleHighlight => {
  const resourceByCategory = resourcesByCategory(terraformModuleHighlight.resourcesSummary);
  const highlights = [];
  highlights.push(
    <HorizontalStack withSeparator>
      {Object.keys(resourceByCategory)
        .filter(category => category !== 'Other')
        .map(category => (
          <div key={category}>{category}</div>
        ))}
    </HorizontalStack>
  );

  return highlights;
};

const legendItems = nodes => {
  const types = _.uniq(nodes.map(n => n.type)).sort();
  if (types.includes('Other')) {
    types.splice(types.indexOf('Other'), 1);
    types.push('Other');
  }
  return types;
};

const TerraformModuleHighlightsPaneBody = ({
  terraformModuleHighlights,
  repository,
  highlights,
}) => {
  const { updateQueryParams } = useQueryParams();
  const paneState = usePaneState();

  const [showGraph, toggleGraph] = useToggle(true);
  const orderedResources = _.orderBy(terraformModuleHighlights.resourcesSummary, [
    resource => resource.iacCategory,
    resource => resource.iacCategory !== 'Other',
    resource => resource.type,
  ]);

  const nodes = terraformModuleHighlights.resourcesSummary.map(resource => ({
    id: `${resource.type}.${resource.key}`,
    type: resource.iacCategory,
    name: resource.name,
    violating: resource.misconfigurations.length,
    resource,
  }));
  const edges = terraformModuleHighlights.resourcesGraphEdges.map(edge => ({
    source: edge.source,
    target: edge.destination,
  }));

  return (
    <VerticalStack>
      <Highlights highlights={highlights} />
      <GraphHeader collapsed={!showGraph}>
        View Connections Graph
        <CheckboxToggle checked={showGraph} onChange={toggleGraph} />
      </GraphHeader>
      {showGraph && (
        <GraphContainer>
          <StyledArchitectureGraph
            nodes={nodes}
            edges={edges}
            onNodeClick={(event, { resource, violating }) => {
              event.stopPropagation();
              if (violating) {
                updateQueryParams({ trigger: null });
                openResourcePane(
                  {
                    id: `${resource.type}${resource.key}`,
                    resource,
                    repository,
                    terraformModuleHighlights,
                  },
                  paneState
                );
              }
            }}
          />
          <GraphLegend>
            {legendItems(nodes).map(type => (
              <GraphLegendRow key={type}>
                <GraphLegendColor color={elementTypeToProperties[type]?.color} />
                {type}
              </GraphLegendRow>
            ))}
          </GraphLegend>
        </GraphContainer>
      )}
      <Table
        headers={[
          { name: 'Category', weight: 2 },
          { name: 'Type', weight: 3 },
          { name: 'Key', weight: 2 },
          { name: 'Insights', weight: 1 },
        ]}
        rows={_.map(orderedResources, resource => {
          return {
            key: resource.path + resource.type + resource.key,
            cells: [
              { text: resource.iacCategory },
              { text: resource.type },
              { text: resource.key },
              {
                content: _.some(resource.misconfigurations) && (
                  <IconButton
                    name="Insights"
                    onClick={event => {
                      event.stopPropagation();
                      updateQueryParams({ trigger: null });
                      openResourcePane(
                        {
                          id: `${resource.type}${resource.key}`,
                          resource,
                          repository,
                        },
                        paneState
                      );
                    }}
                  />
                ),
                menu: [
                  getViewCodeColumn({ relativeFilePath: resource.path, lineNumber: 0 }, repository),
                ],
              },
            ],
          };
        })}
      />
      <VerticalStack>
        {_.some(terraformModuleHighlights.modules) && (
          <Collapsible title={<Bold>Local Modules References</Bold>}>
            <VerticalStack>
              {_.map(terraformModuleHighlights.modules, module => (
                <StyledSpan>{module.module_directory_path}</StyledSpan>
              ))}
            </VerticalStack>
          </Collapsible>
        )}
        {_.some(terraformModuleHighlights.externalModulesReferences) && (
          <Collapsible title={<Bold>External Modules References</Bold>}>
            <VerticalStack>
              {_.map(terraformModuleHighlights.externalModulesReferences, module => (
                <StyledSpan>{module}</StyledSpan>
              ))}
            </VerticalStack>
          </Collapsible>
        )}
      </VerticalStack>
    </VerticalStack>
  );
};

export const openTerraformModuleHighlightsWithRiskActions = ({
  ruleKey,
  trigger,
  profile,
  profileType,
  relevantPath,
  onClose,
  messageContent,
  externalRiskTriggerPane,
  level = primaryPaneLevel,
}) => {
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.repositoryKeys?.[0],
    profile
  );

  dispatch.pane.openPane({
    level,
    onClose,
    relevantPath,
    id: trigger.key,
    content: (
      <RiskRuleTrigger
        profile={profile}
        ruleKey={ruleKey}
        trigger={trigger}
        externalRiskTriggerPane={externalRiskTriggerPane}
        getPane={({ element, ruleTriggers }) => (
          <TerraformResourcePane
            trigger={trigger}
            element={element}
            repository={repository}
            profile={profile}
            profileType={profileType}
            ruleTriggers={ruleTriggers}
            messageContent={messageContent}
          />
        )}
      />
    ),
  });
};
