import _ from 'lodash';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { RiskIcon } from '@src-v2/components/risk/risk-icon';
import { Popover } from '@src-v2/components/tooltips/popover';
import { riskOrder } from '@src-v2/data/risk-data';
import { customScrollbar } from '@src-v2/style/mixins';
import { DevPhase } from '@src-v2/types/enums/dev-phase';
import { makeUrl } from '@src-v2/utils/history-utils';

export function RiskFactorPopover({ profile, maxItems = 8, title = 'Top Findings', ...props }) {
  return (
    <Popover
      {...props}
      content={popover => (
        <>
          <Header>{title}</Header>
          <PopoverContent>
            {profile.riskFactors.slice(0, maxItems).map(({ title, riskLevel, devPhase }) => {
              const categoryUrl =
                devPhase === DevPhase.Design || devPhase === DevPhase.SupplyChain
                  ? _.camelCase(devPhase)
                  : 'development';

              return (
                <RiskItem
                  key={title}
                  riskLevel={riskLevel}
                  onClick={props.onClickOutside ?? popover.hide}
                  to={
                    profile.profileType &&
                    makeUrl(`/profiles/${profile.profileType}/${profile.key}/risk/${categoryUrl}`, {
                      fl: { searchTerm: title },
                    })
                  }>
                  {title}
                </RiskItem>
              );
            })}

            {profile.riskFactors.length > maxItems && (
              <ViewLink
                to={`/profiles/${profile.profileType}/${profile.key}/risk`}
                onClick={props.onClickOutside ?? popover.hide}>
                +{profile.riskFactors.length - maxItems} Risk Factors
              </ViewLink>
            )}
          </PopoverContent>
        </>
      )}
    />
  );
}

export function BusinessImpactPopover({ profile, ...props }) {
  const [configuredBusinessImpact, businessImpactFactors] = useMemo(
    () => _.partition(profile.businessImpactFactors, 'isConfigurable'),
    [profile.businessImpactFactors]
  );

  const showHeading = configuredBusinessImpact.length > 0 && businessImpactFactors.length > 0;
  return (
    <Popover
      {...props}
      content={
        <>
          <Header>{profile.businessImpact} Business Impact</Header>
          <PopoverContent>
            {configuredBusinessImpact.length > 0 && (
              <>
                {showHeading && <Heading>Manual configurations</Heading>}
                {configuredBusinessImpact.map(({ title, riskLevel }) => (
                  <RiskItem key={title} riskLevel={riskLevel}>
                    {title}
                  </RiskItem>
                ))}
              </>
            )}

            {businessImpactFactors.length > 0 && (
              <>
                {showHeading && <Heading>Apiiro Insights</Heading>}
                {businessImpactFactors.map(({ title, riskLevel }) => (
                  <RiskItem key={title} riskLevel={riskLevel}>
                    {title}
                  </RiskItem>
                ))}
              </>
            )}
          </PopoverContent>
        </>
      }
    />
  );
}

const PopoverContent = styled.div`
  max-height: 90rem;
  padding: 0;
  margin: 0;
  ${customScrollbar}
`;

const RiskItem = styled(({ riskLevel, children, ...props }) => {
  const Container = props.to ? Link : 'div';
  return (
    <Container {...props}>
      {children}
      {riskOrder.includes(riskLevel) && <RiskIcon riskLevel={riskLevel} />}
    </Container>
  );
})`
  display: flex;
  padding: 0 2rem 2rem;
  justify-content: space-between;
  align-items: center;
  font-size: var(--font-size-s);
  gap: 5rem;

  &[href]:hover {
    text-decoration: underline;
  }

  --risk-color-none: var(--color-blue-gray-20);
`;

const ViewLink = styled(Link)`
  padding: 0 2rem 2rem;
  font-size: var(--font-size-s);
  font-weight: 700;

  &:hover {
    text-decoration: underline;
  }
`;

const Header = styled(Popover.Head)`
  padding: 4rem 5rem 3rem;
  font-size: var(--font-size-m);
`;

const Heading = styled.h4`
  padding: 0 2rem 2rem;
  color: var(--color-blue-gray-45);
  font-size: var(--font-size-xs);
  text-transform: uppercase;
  line-height: 2;

  ${RiskItem} + &,
  ${ViewLink} + & {
    padding-top: 4rem;
  }
`;
