import styled from 'styled-components';
import { BaseIcon, SvgIcon } from '@src-v2/components/icons';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { ExternalLink } from '@src-v2/components/typography';
import { HorizontalStack } from './HorizontalStack';

const ViewCode = ({ url, tip, inline, withText, className }) => {
  let linkContent = <SvgIcon name="Code" />;
  if (withText) {
    linkContent = (
      <HorizontalStack>
        {linkContent}
        <span>View Code</span>
      </HorizontalStack>
    );
  } else if (!inline) {
    linkContent = <ViewCodeIconContainer>{linkContent}</ViewCodeIconContainer>;
  }
  return (
    <Tooltip content={tip ?? 'View code'}>
      <ExternalLink href={url} className={className}>
        {linkContent}
      </ExternalLink>
    </Tooltip>
  );
};

export default ViewCode;

const ViewCodeIconContainer = styled.div`
  ${BaseIcon} {
    width: 10rem;
    height: 10rem;
    color: var(--color-white);
    background: var(--color-blue-gray-70);
    border-radius: 100vmax;

    &:hover {
      background-color: var(--color-blue-gray-60);
    }
  }
`;
