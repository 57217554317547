import { animated, useSpring } from '@react-spring/web';
import { easeQuadInOut } from 'd3-ease';
import { scaleLinear } from 'd3-scale';
import { useMemo } from 'react';
import { useUniqueId } from '@src-v2/hooks/use-lodash';

export function useD3Scale({ width, height, minX = 0, maxX, minY = 0, maxY }) {
  return [
    useMemo(() => scaleLinear().domain([minX, maxX]).range([0, width]), [minX, maxX, width]),
    useMemo(() => scaleLinear().domain([minY, maxY]).range([height, 0]), [minY, maxY, height]),
  ];
}

export function useClipEntrance(width, height, easing = easeQuadInOut) {
  const clipId = useUniqueId('animationClipPath');
  const styles = useSpring({
    config: { tension: 120, friction: 60, easing },
    from: { width: 0 },
    to: { width },
  });
  return [
    `url(#${clipId})`,
    <clipPath id={clipId}>
      <animated.rect height={height} {...styles} />
    </clipPath>,
  ];
}
