import { Children, isValidElement } from 'react';
import { components } from 'react-select';
import styled from 'styled-components';
import { SvgIcon } from '@src-v2/components/icons';
import { InfoTooltip } from '@src-v2/components/tooltips/icon-tooltips';
import { HorizontalStack } from '@src/components/HorizontalStack';
import RiskIcon from '@src/components/RiskIcon';
import ServerIcon from '@src/components/ServerIcon';
import { Tooltip } from '@src/components/Tooltip';
import { Ellipsis, FontBodySmall } from '@src/style/common';
import { Link } from '../Link';

export const StyledMenu = styled(components.Menu)`
  && {
    max-width: 320px;
    width: auto;
  }
`;

const StyledTooltip = styled(Tooltip)`
  width: 100%;
`;

const StyledOption = styled(components.Option)`
  && {
    padding: 6px 3rem 6px 6px;
  }

  span {
    ${FontBodySmall};
    ${Ellipsis};
  }
`;

const TooltipWithLink = styled.div`
  display: flex;
  gap: 2rem;
`;

const TooltipLink = styled(Link)`
  a:hover {
    text-decoration: underline;
    opacity: 1;
  }
`;

const StyledSingleValue = styled(components.SingleValue)`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TipContent = styled.div`
  a {
    text-decoration: underline;
  }
`;
const MultiValueTooltip = styled(Tooltip)`
  display: flex;
`;

const Required = styled.span`
  color: var(--color-red-50);
`;

export const StyledMultiValue = styled(components.MultiValue)`
  #main &,
  #modal & {
    border-radius: 3rem;
  }

  &.select__multi-value {
    font-size: var(--font-size-m);
    font-weight: 300;
    line-height: 6rem;
    background-color: var(--color-blue-gray-20);
  }

  .select__multi-value__label {
    padding: 1rem 2rem;
    max-width: 100rem;
  }

  .select__multi-value__remove:hover {
    background-color: unset;
    color: unset;
    cursor: pointer;
  }
`;

export const MultiValueLabel = styled.span`
  max-width: 60rem;
  ${Ellipsis};
`;

export function renderTipContent(tip, linkText, navigateTo) {
  const isValidTip = typeof tip === 'string' || isValidElement(tip);

  if (navigateTo && isValidTip) {
    return (
      <TooltipWithLink>
        {tip}
        <TooltipLink url={navigateTo}>{linkText}</TooltipLink>
      </TooltipWithLink>
    );
  }
  if (isValidTip || !tip) {
    return tip;
  }

  if (!Array.isArray(tip)) {
    console.warn("This type of tip isn't supported: ", typeof tip);
    return tip;
  }

  const [template] = tip;
  if (isValidElement(template)) {
    return tip;
  }

  const args = [...template.matchAll(/{{([^}]+)}}/g)];
  if (args.length !== tip.length - 1) {
    console.error(`Expected ${args.length} args but got ${tip.length - 1}`);
  }

  const children = [];
  let cursor = 0;
  for (let i = 0; i < args.length; i++) {
    const argument = args[i];
    const segment = template.substring(cursor, argument.index);
    children.push(segment);

    const configuration = tip[i + 1];
    if (configuration.type === 'externalLink') {
      children.push(
        <Link key={i} url={configuration.href} external stopPropagation>
          {argument[1]}
        </Link>
      );
    } else {
      console.error(`Unexpected argument of type ${configuration.type}`);
    }
    cursor = argument.index + argument[0].length;
  }
  return <TipContent>{children}</TipContent>;
}

const FormattedOption = ({ data: { provider, icon, risk, label, required } }) => (
  <HorizontalStack spacing="0.2">
    {provider && <ServerIcon provider={provider} size="small" />}
    {icon && <SvgIcon name={icon} />}
    {risk && <RiskIcon riskLevel={risk} />}
    <MultiValueLabel title={label}>{label}</MultiValueLabel>
    {required && <Required>*</Required>}
  </HorizontalStack>
);

const FormattedTooltip = ({ shouldDisplay, prefix, linkText, navigateTo, children }) => (
  <StyledTooltip
    interactive
    appendTo={document.body}
    tip={shouldDisplay && renderTipContent(prefix, linkText, navigateTo)}>
    {children}
  </StyledTooltip>
);

const Components = props => ({
  DropdownIndicator: () => null,
  IndicatorsContainer: () => null,
  MenuList: ({ maxSize = 100, children, ...props }) => (
    <components.MenuList {...props}>
      {Children.toArray(children).slice(0, maxSize)}
    </components.MenuList>
  ),
  Menu: componentProps => <StyledMenu {...componentProps} />,
  Option: componentProps => (
    <FormattedTooltip
      shouldDisplay={componentProps.isDisabled || componentProps.data?.tip}
      prefix={componentProps.data?.tooltipPrefix || componentProps.data?.tip}
      linkText={componentProps.data?.tooltipLinkText}
      navigateTo={componentProps.data?.tooltipLinkUrl}>
      <StyledOption
        {...componentProps}
        innerProps={{
          ...componentProps.innerProps,
          'data-test-marker': 'select-option',
          'data-value': componentProps.data.value,
        }}>
        <FormattedOption data={componentProps.data} />
      </StyledOption>
    </FormattedTooltip>
  ),
  SingleValue: componentProps => (
    <StyledSingleValue
      {...componentProps}
      innerProps={{
        ...componentProps.innerProps,
        'data-test-marker': props['data-test-marker'],
        'data-value': props['data-value'],
      }}>
      <FormattedOption data={componentProps.data} />
      {componentProps.data.tip && !componentProps.isDisabled && (
        <InfoTooltip interactive appendTo={document.body} content={componentProps.data.tip} />
      )}
    </StyledSingleValue>
  ),
  MultiValue: componentProps => (
    <StyledMultiValue {...componentProps}>
      <MultiValueTooltip tip={componentProps.data.tip}>
        <FormattedOption data={componentProps.data} />
      </MultiValueTooltip>
    </StyledMultiValue>
  ),
});

export default Components;
