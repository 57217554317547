import assign from 'lodash/assign';
import keyBy from 'lodash/keyBy';
import valuesIn from 'lodash/valuesIn';
import apiService from '@src/services/apiService';
import { processCodeProfileRelatedEntity } from '@src/utils/inventoryUtils';

const baseRoute = '/api/applicationGroups/';

const clearData = () => ({
  profiles: {},
  inventories: {},
  riskTriggers: {},
});

export default {
  state: clearData(),
  reducers: {
    setApplicationGroupProfiles: (state, groupProfiles) => ({
      ...state,
      profiles: assign(
        { ...state.profiles },
        keyBy(groupProfiles, profile => profile.key)
      ),
    }),
    setApplicationGroupRiskTriggers: (state, groupRIskTriggers) => ({
      ...state,
      riskTriggers: assign(
        { ...state.riskTriggers },
        keyBy([groupRIskTriggers], riskTriggers => riskTriggers.key)
      ),
    }),
    setApplicationGroupAssetConfigurations: (state, applicationGroupAssetConfigurations) => ({
      ...state,
      applicationGroupAssetConfigurations,
    }),
  },

  selectors: slice => ({
    applicationGroupProfiles: () => slice(applicationGroups => valuesIn(applicationGroups)),
    profilesByKey: () => slice(state => state?.profiles ?? {}),
    riskTriggersByKey: () => slice(state => state?.riskTriggers ?? {}),
    inventoriesByKey: () => slice(state => state?.inventories ?? {}),
    applicationGroupAssetConfigurations: () =>
      slice(state => state.applicationGroupAssetConfigurations),
  }),

  effects: () => ({
    async getApplicationGroupProfileAsync({ key, invalidateCache }) {
      const repositoryProfile = (
        await apiService.get(`${baseRoute}${key}/profile`, {
          clearCacheEntry: invalidateCache,
        })
      ).data;
      if (repositoryProfile) {
        this.setApplicationGroupProfiles([repositoryProfile]);
      }
      return repositoryProfile;
    },

    async getApplicationGroupRiskTriggersAsync({ key, invalidateCache }) {
      const repositoryRiskTriggers = (
        await apiService.get(`${baseRoute}${key}/riskTriggers`, {
          clearCacheEntry: invalidateCache,
        })
      ).data;
      if (repositoryRiskTriggers) {
        processCodeProfileRelatedEntity(repositoryRiskTriggers);
        this.setApplicationGroupRiskTriggers(repositoryRiskTriggers);
      }
      return repositoryRiskTriggers;
    },

    async fetchApplicationGroupAssetConfigurationsAsync() {
      const options = await apiService.get(`${baseRoute}configurations`);
      this.setApplicationGroupAssetConfigurations(options.data);
    },
  }),
};
