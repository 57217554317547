import { makeObservable, when } from 'mobx';
import { beamerInitialize } from 'react-beamer';
import { Analytics } from '@src-v2/services/analytics';

export class Notifications {
  #beamerToken;
  #application;
  #session;

  #initialized = false;

  constructor({ application, session, config: { BEAMER_ID } }) {
    this.#beamerToken = BEAMER_ID;
    this.#application = application;
    this.#session = session;

    makeObservable(this, {});
    when(
      () => Boolean(application.version),
      () => this.#initialize()
    );
  }

  #initialize() {
    if (this.#initialized && !this.#application.version) {
      return;
    }

    const beamerArgs = {
      selector: 'beamerButton',
      user_firstname: this.#session.data?.consoleUser?.name,
      user_lastname: '',
      user_id: this.#session.data?.consoleUser?.id,
      user_email: this.#session.data?.userAccount?.email,
      application_version: this.#application.version,
      environment_id: this.#session.data?.environmentId,
      environment_name: this.#session.data?.environmentName,
      is_internal: Analytics.matchInternalUser.test(this.#session.data?.consoleUser.id),
      is_saas: this.#session.data?.isSaas,
    };

    this.#session.data?.betaFeatures?.forEach(
      betaFeature => (beamerArgs[`feature_flag_${betaFeature}`] = true)
    );

    if (!this.#session.unauthorizedEmptyScope) {
      beamerInitialize(this.#beamerToken, false, beamerArgs);
      this.#initialized = true;
    }
  }
}
