import styled from 'styled-components';
import { Statistics } from '@src/components/Statistics';
import { VerticalStack } from '@src/components/VerticalStack';

const StyledVerticalStack = styled(VerticalStack)`
  padding-top: 30px;
`;

const Profile = ({ statistics }) => (
  <StyledVerticalStack spacing="90px">
    <Statistics items={statistics} maxItems={10} />
  </StyledVerticalStack>
);

export default Profile;
