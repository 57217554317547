import _ from 'lodash';
import filtersService from '@src/services/filtersService';

export const getDependenciesFilterOptions = ({ elements, hasExternalConnectivity }) => {
  const licenses = _.uniq(_.map(elements, element => element.licensesNames ?? []).flat());

  const filterOptions = [];
  if (hasExternalConnectivity) {
    filterOptions.push({
      name: 'Type',
      sortOrder: 1,
      filterOptions: [
        {
          name: 'External',
          displayName: 'External',
        },
        {
          name: 'Internal',
          displayName: 'Internal',
        },
        {
          name: 'Sensitive',
          displayName: 'Sensitive',
        },
        {
          name: 'DirectDependency',
          displayName: 'Direct Dependency',
        },
        {
          name: 'SubDependency',
          displayName: 'Sub Dependency',
        },
      ],
    });
  }

  filterOptions.push({
    name: 'Licenses',
    filterOptions: filtersService.getListFilterOptions(licenses),
  });

  filterOptions.push({
    name: 'Security',
    sortOrder: 2,
    filterOptions: [
      {
        name: 'DependencyVulnerabilities',
        displayName: 'Vulnerable dependencies',
      },
    ],
  });

  return filterOptions;
};

export const filterDependenciesToCondition = {
  External: dependency => dependency.isExternal,
  Internal: dependency => !dependency.isExternal,
  Sensitive: dependency => dependency.isSensitive,
  DirectDependency: dependency => !dependency.isSubDependency,
  SubDependency: dependency => dependency.isSubDependency,
  DependencyVulnerabilities: dependency =>
    !_.isEmpty(dependency.dependencyFindings) || dependency.totalVulnerabilitiesCount > 0,
};

export const filterDependencies = (allItems, searchTerm, filters, filterOperator) => {
  let filtered = filtersService.filterItemsBySearchTerm(allItems, searchTerm, item => [item.name]);
  const licenseFilters = filters.listFilters?.Licenses;

  if (!_.isEmpty(licenseFilters)) {
    filtered = filtered.filter(dependency =>
      filterOperator(
        _.map(licenseFilters, license => _.includes(dependency.licensesNames, license))
      )
    );
  }

  const filterConditions = filters.booleanFilters
    .map(filter => filterDependenciesToCondition[filter])
    .filter(Boolean);

  if (!_.isEmpty(filterConditions)) {
    filtered = filtered.filter(dependency =>
      filterOperator(filterConditions, condition => condition(dependency))
    );
  }

  return filtered;
};
