import { pluralFormat } from '@src-v2/utils/number-utils';

export function getRepositoryBannerText({ repositoriesMaxCount, repositoriesMaxSizeInGb }) {
  const [countText, sizeText] = [
    repositoriesMaxCount &&
      `${repositoriesMaxCount} ${pluralFormat(repositoriesMaxCount, 'repository', 'repositories')}`,
    repositoriesMaxSizeInGb && `${repositoriesMaxSizeInGb}GB`,
  ];
  return `In the free trial you can set up to ${
    countText ^ sizeText ? countText || sizeText : `${countText} (and up to ${sizeText})`
  }`;
}
