import { forwardRef } from 'react';
import styled from 'styled-components';
import { classNames } from '@src-v2/utils/style-utils';
import { FontBodyHeader } from '@src/style/common';

export const Button = forwardRef(
  ({ className, disabled, onClick, noStopPropagation, ...props }, ref) => (
    <StyledButton
      ref={ref}
      {...props}
      className={classNames(className, { disabled })}
      onClick={event => {
        if (onClick && !disabled) {
          onClick(event);
        }
        if (!noStopPropagation || disabled) {
          event.preventDefault();
          event.stopPropagation();
        }
      }}
    />
  )
);

const StyledButton = styled.button`
  ${FontBodyHeader};
  font-weight: 500;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
  user-select: none;
  background-color: ${props =>
    props.primary
      ? 'var(--color-green-50)'
      : props.secondary
        ? 'var(--color-blue-gray-75)'
        : 'transparent'};
  border: 1px solid transparent;
  padding: 6px 3rem;
  border-radius: 1rem;
  color: ${props =>
    props.primary || props.secondary ? 'var(--color-blue-gray-15)' : 'var(--default-text-color)'};
  cursor: pointer;

  &:hover {
    opacity: 0.7;
  }

  &.disabled {
    opacity: 0.7;
    cursor: default;
  }
`;
