import styled from 'styled-components';

export const InputLabel = styled.label``;

export const InputClickableLabel = styled(InputLabel)`
  cursor: pointer;

  &[data-disabled] {
    cursor: default;
  }
`;
