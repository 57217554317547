import { addInterpunctSeparator } from '@src-v2/utils/string-utils';
import { getRepositoryForInventoryPropertyFromProfileByKey } from '@src/components/CodeAggregation/InventoryUtils';
import { openApiPaneWithRiskActions } from '@src/components/Panes/ApiPane';
import { EllipsisDiv } from '@src/style/common';
import { getApiText } from '@src/utils/apiUtils';
import { getTrimmedPath } from '@src/utils/stringDisplayUtils';
import { PaneLocationLink, TriggerInfoContainer } from './Common';

export const getApisTriggerDisplay = ({ trigger, profile, ...options }) => {
  const { codeReference, httpMethod, httpRoute, methodName, classification } = trigger;
  const repository = getRepositoryForInventoryPropertyFromProfileByKey(
    trigger.elementEntityKey,
    profile
  );
  const path = getTrimmedPath(codeReference.relativeFilePath);
  const title = addInterpunctSeparator(
    profile.profileType === 'ApplicationProfile' && repository.name,
    path
  );

  return [
    {
      weight: 3,
      content: (
        <PaneLocationLink
          id={trigger.key}
          onClick={args =>
            openApiPaneWithRiskActions({ ...args, trigger, profile, repository, ...options })
          }>
          <TriggerInfoContainer title={title} insights={trigger.insights}>
            <EllipsisDiv>
              {getApiText(httpMethod, httpRoute, methodName, classification)}
            </EllipsisDiv>
          </TriggerInfoContainer>
        </PaneLocationLink>
      ),
    },
  ];
};

export const getApisMessageFormat = ({ httpMethod, httpRoute, methodName, classification }) =>
  getApiText(httpMethod, httpRoute, methodName, classification);
