import { SendBulkMessageModal } from '@src-v2/containers/modals/messaging/send-bulk-message-modal';
import { Messaging } from '@src-v2/services';

export function SlackBulkMessageModal(props) {
  return (
    <SendBulkMessageModal
      {...props}
      allowCustomChannel
      messageType={Messaging.TYPE_SLACK}
      previewTags={['APP']}
    />
  );
}

export function GoogleChatBulkMessageModal(props) {
  return <SendBulkMessageModal {...props} messageType={Messaging.TYPE_GOOGLE_CHAT} />;
}

export function TeamsBulkMessageModal(props) {
  return <SendBulkMessageModal {...props} messageType={Messaging.TYPE_TEAMS} />;
}
