import map from 'lodash/map';
import { Tooltip } from '@src-v2/components/tooltips/tooltip';
import { Heading, Paragraph } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  HighlightedCode,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';

export const ApiSecurityPolicyMaterialChange = ({
  commitSha,
  repository,
  relativeFilePath,
  materialChange,
}) => {
  const { methodSignature, apiName, methodName } = materialChange.codeReference;
  return (
    <>
      <Heading>
        {materialChange.securityPolicies && (
          <>
            <Paragraph>API security policies are:</Paragraph>
            {map(materialChange.securityPolicies, policy => ({ policy }))}
          </>
        )}
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={relativeFilePath}>
          {apiName ?? methodName}
        </CommitCodeReference>
      </Heading>
      <MaterialChangesRow>
        <Tooltip content={<HighlightedCode language="java">{methodSignature}</HighlightedCode>}>
          <Paragraph>{apiName ?? methodName} </Paragraph>
        </Tooltip>
      </MaterialChangesRow>
    </>
  );
};
