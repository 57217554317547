import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import { NavLink } from 'react-router-dom';
import styled from 'styled-components';
import { makeUrl } from '@src-v2/utils/history-utils';
import { FontBodyBold, FontBodySmall, FontBodySpan } from '@src/style/common';
import Collapsible from './Collapsible';
import { VerticalStack } from './VerticalStack';

const StyledComponentType = styled.span`
  ${FontBodyBold};
  display: inline-flex;
`;

const StyledTechnologyType = styled(FontBodySpan)`
  opacity: 0.5;
`;

const StyledFramework = styled(NavLink)`
  ${FontBodySmall};
  width: fit-content;
  padding-left: 6px;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledVerticalStack = styled(VerticalStack)`
  padding: 3rem 0;
`;

const TechnologiesBreakdown = ({ technologiesByComponentType, frameworksByType }) =>
  !isEmpty(technologiesByComponentType) && !isEmpty(frameworksByType) ? (
    <VerticalStack spacing="30px">
      {sortBy(Object.keys(technologiesByComponentType)).map(componentType => (
        <VerticalStack key={componentType} spacing="6px">
          <StyledComponentType>{componentType}</StyledComponentType>
          {technologiesByComponentType[componentType]
            .filter(value => value !== componentType)
            .map(technology => (
              <Collapsible
                spread
                key={technology + componentType}
                title={<StyledTechnologyType>{technology}</StyledTechnologyType>}>
                <StyledVerticalStack spacing="6px">
                  {sortBy(frameworksByType[technology]).map(framework => (
                    <StyledFramework
                      key={framework}
                      to={makeUrl('/profiles/repositories', {
                        fl: { Technologies: { values: [framework] } },
                      })}>
                      {framework}
                    </StyledFramework>
                  ))}
                </StyledVerticalStack>
              </Collapsible>
            ))}
        </VerticalStack>
      ))}
    </VerticalStack>
  ) : (
    'No technologies identified'
  );

export default TechnologiesBreakdown;
