import _ from 'lodash';
import apiService from '@src/services/apiService';

const clearData = () => ({
  tableScope: null,
  items: [],
  itemsCount: 0,
});

const addItemsPage = (currentItems, itemsPage) => {
  if (_.isEmpty(itemsPage)) {
    return currentItems;
  }

  const currentKeys = new Set(currentItems.map(item => item.key));
  if (currentKeys.has(undefined)) {
    return [...currentItems, ...itemsPage];
  }

  itemsPage = itemsPage.filter(item => !currentKeys.has(item.key));
  return _.isEmpty(itemsPage) ? currentItems : [...currentItems, ...itemsPage];
};

export default {
  state: clearData(),
  selectors: slice => ({
    items: () => slice(state => state?.items),
    itemsCount: () => slice(state => state?.itemsCount),
    currentTableScope: () => slice(state => state?.tableScope),
  }),
  reducers: {
    setItems: (state, { itemsPage, currentPage, tableScope }) =>
      state.tableScope === tableScope
        ? {
            ...state,
            items: currentPage > 0 ? addItemsPage(state.items, itemsPage) : itemsPage,
          }
        : state,
    setItemsCount: (state, itemsCount) => ({
      ...state,
      itemsCount,
    }),
    setItem: (state, item) => ({
      ...state,
      items: state.items.map(existingItem => (existingItem.key === item.key ? item : existingItem)),
    }),
    updateItem: (state, { itemSelector, fieldName, fieldValue }) => ({
      ...state,
      items: state.items.map(existingItem =>
        itemSelector(existingItem)
          ? {
              ...existingItem,
              [fieldName]: fieldValue,
            }
          : existingItem
      ),
    }),
    setTableScope: (state, tableScope) => ({
      ...state,
      tableScope,
    }),
    onFilterChange: state => ({
      ...state,
      items: [],
      itemsCount: 0,
    }),
    clearData,
  },
  effects: () => ({
    fetchData({ tableScope }) {
      this.setTableScope(tableScope);
    },

    async fetchPage(
      { tableScope, currentPage, filters, searchTerm, postFetchPage, ...searchParams },
      state
    ) {
      filters = processFiltersForFetch(filters);
      const itemsAndCount = await apiService.searchTable({
        tableScope,
        currentPage,
        searchTerm,
        filters,
        ...searchParams,
      });

      if (_.isNil(itemsAndCount)) {
        return null;
      }

      const { items, count } = itemsAndCount;
      this.setItemsCount(count);

      if (postFetchPage) {
        await postFetchPage(items);
      }

      this.setItems({
        itemsPage: items ?? [],
        currentPage,
        tableScope: state.serverTableInfiniteScroll.tableScope,
      });
      return items;
    },

    async reloadData(options, state) {
      await this.fetchPage(
        {
          tableScope: state.serverTableInfiniteScroll.tableScope,
          currentPage: 0,
          searchTerm: '',
          postFetchPage: options?.postFetchPage,
        },
        state
      );
    },
  }),
};

function processFiltersForFetch(filters) {
  if (!filters) {
    return {};
  }

  const processedFilters = {};
  _.forEach(filters.booleanFilters, key => (processedFilters[key] = ['true']));
  _.forEach(filters.listFilters, (value, key) => (processedFilters[key] = value));
  return processedFilters;
}
