import { Heading, Paragraph, Strong } from '@src-v2/components/typography';
import {
  CommitCodeReference,
  MaterialChangesRow,
} from '@src-v2/containers/commit/common-componnets';
import { thenSubTypeToVerb } from '@src-v2/containers/commit/material-changes-utils';

export const ProtobufServiceMaterialChange = ({
  commitSha,
  repository,
  materialChange,
  thenSubType,
}) => {
  const { descriptions, codeReference } = materialChange;
  return (
    <>
      <Heading>
        Protobuf
        <CommitCodeReference
          repository={repository}
          commitSha={commitSha}
          relativeFilePath={codeReference.relativeFilePath}>
          <Strong>{codeReference.name}</Strong>
        </CommitCodeReference>
        <Paragraph>was</Paragraph>
        <Strong>{thenSubTypeToVerb(thenSubType)}</Strong>
      </Heading>
      <MaterialChangesRow>
        {descriptions.map(({ name, type }) => (
          <Paragraph key={name}>
            {name}
            {type && <Strong>: {type}</Strong>}
          </Paragraph>
        ))}
      </MaterialChangesRow>
    </>
  );
};
