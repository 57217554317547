import styled from 'styled-components';
import RiskIcon from '@src/components/RiskIcon';

export const StyledRiskIcon = styled(RiskIcon)`
  align-self: center;
`;

export const CircledIcon = styled.span`
  width: 7.5rem;
  height: 7.5rem;
  padding: 0.75rem;
  color: var(--color-green-45);
  background-color: var(--color-white);
  border-radius: 100vmax;
  flex-shrink: 0;
`;
