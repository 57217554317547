import { connect } from 'react-redux';
import { select } from '@src/store';
import { Pane } from './view';

const mapState = select(({ pane: { primaryPane, secondaryPane } }) => ({
  primaryPane,
  secondaryPane,
}));

const mapDispatch = ({
  pane: {
    openPrimaryPane,
    openSecondaryPane,
    closePrimaryPane,
    closeSecondaryPane,
    setPrimaryWidthPercentage,
    setSecondaryWidthPercentage,
  },
}) => ({
  openPrimaryPane,
  openSecondaryPane,
  closePrimaryPane,
  closeSecondaryPane,
  setPrimaryWidthPercentage,
  setSecondaryWidthPercentage,
});

// @deprecated, please use `Pane.tsx` instead
export default connect(mapState, mapDispatch)(Pane);
